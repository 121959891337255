import clsx from "clsx"
import React from "react"

import { useLanguage } from "../../contexts/language.context"

type IApplyCouponInputProps = {
	status: "idle" | "loading" | "success" | "error"
	coupon: string
	onCouponChange: (coupon: string) => void
	onSubmit: () => void
}

export function ApplyCouponInput({ status, coupon, onCouponChange, onSubmit }: IApplyCouponInputProps) {
	const isDisabled = status === "loading" || status === "success"
	const { t } = useLanguage()

	return (
		<div className="p-4 bg-gray-100 border rounded-md">
			<span
				className={clsx(
					"block text-sm font-semibold text-gray-900",
					status === "success" && "text-green-600",
					status === "error" && "text-red-600",
				)}
			>
				{status === "success"
					? t("Coupon applied successfully!")
					: status === "error"
					? t("Invalid coupon")
					: t("Have a coupon? Apply it here")}
			</span>
			<div className="mt-2 flex rounded-md shadow-sm">
				<div className="relative flex flex-grow items-stretch focus-within:z-10">
					<input
						id="apply-coupon"
						name="coupon"
						type="text"
						placeholder="Coupon"
						disabled={isDisabled}
						value={coupon}
						onChange={(e) => onCouponChange(e.target.value)}
						className={clsx(
							"block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
							"disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200",
						)}
					/>
				</div>
				<button
					type="button"
					disabled={isDisabled || coupon === ""}
					className={clsx(
						"relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
						"disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500 disabled:ring-gray-200 disabled:hover:bg-gray-100",
					)}
					onClick={onSubmit}
				>
					{t("Apply")}
				</button>
			</div>
		</div>
	)
}
