import assertNever from "assert-never"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { NEW_TRAINING_PROGRAM_ID } from "../../../../config"
import type { TrainingProgramEditViewModel } from "../../../../core/application/gateways/training.gateway/schemas/training-program-edit-view-model.schema"
import { useDependencies } from "../../../contexts/dependencies.context"
import { TrainingProgramProvider } from "./contexts/training-program.context"
import { EditContentPage } from "./EditTrainingProgram/EditContent.page"
import { EditNamePage } from "./EditTrainingProgram/EditName.page"
import { EditTeamMembersPage } from "./EditTrainingProgram/EditTeamMembers.page"
import { editTrainingProgramsURLParamsSchema } from "./EditTrainingProgram/params"

export function EditTrainingProgramPage() {
	const params = editTrainingProgramsURLParamsSchema.parse(useParams())
	const { trainingGateway } = useDependencies()
	const isNew = params.programId === NEW_TRAINING_PROGRAM_ID
	const [trainingProgram, setTrainingProgram] = useState<TrainingProgramEditViewModel | null>(null)

	useEffect(() => {
		if (isNew) {
			return
		}

		trainingGateway.getTrainingProgram(params.programId).then((program) => {
			setTrainingProgram(program)
		})
	}, [isNew, params.programId, trainingGateway, params.stepName])

	let component
	switch (params.stepName) {
		case "name":
			component = <EditNamePage />
			break

		case "content":
			component = <EditContentPage />
			break

		case "enrolments":
			component = <EditTeamMembersPage />
			break

		default:
			assertNever(params.stepName)
	}

	return (
		<TrainingProgramProvider trainingProgram={trainingProgram} programId={params.programId}>
			{component}
		</TrainingProgramProvider>
	)
}
