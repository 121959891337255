import { z } from "zod"

export const TrainingProgramSchema = z.object({
	id: z.string(),
	workspaceId: z.string(),
	name: z.string(),
	description: z.string(),
	creatorId: z.string(),
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date(),
	totalItems: z.number(),
})
export type TrainingProgram = z.infer<typeof TrainingProgramSchema>
