import { useState } from "react"
import { toast } from "react-toastify"

import type { UploadedFile } from "../../../../../core/application/gateways/storage.gateway"
import type { WorkspaceProperties } from "../../../../../core/domain/Workspace.entity"
import { ApiError } from "../../../../../core/infra/gateways/api-errors"
import type { WorkspaceLanguage } from "../../../../../core/infra/gateways/http.authentication.gateway/response-schemas"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { sleep } from "../../../../utils/time"

type WorkspacePreferencesFormState =
	| { status: "idle" }
	| { status: "submitting" }
	| { status: "success" }
	| { status: "error"; error: ApiError }

const meddicSections = [
	"Metrics",
	"Economic Buyer",
	"Decision Criteria",
	"Decision Process",
	"Identify Pain",
	"Champion",
] as const

export function useWorkspacePreferencesSettings() {
	const { workspace, refetchMe } = useAuthenticatedSession()
	const { workspacesGateway } = useDependencies()
	const [language, setLanguage] = useState<WorkspaceLanguage>(workspace.props.language)
	const [companyName, setCompanyName] = useState(workspace.props.details?.companyName || "")
	const [formStatus, setFormStatus] = useState<WorkspacePreferencesFormState>({ status: "idle" })
	const [companyLogoImageId, setCompanyLogoImageId] = useState<string | null>(workspace.props.companyLogoId ?? null)
	const [isMeddicScoringEnabled, setIsMeddicScoringEnabled] = useState(
		!workspace.props.preferences?.hideMeddicScoring,
	)
	const [meddicSectionsToHide, setMeddicSectionsToHide] = useState(
		workspace.props.preferences?.meddicSectionsToHide ?? [],
	)
	const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
	const { t } = useLanguage()

	const handleLanguageChange = (newLanguage: WorkspaceProperties["language"]) => {
		setHasUnsavedChanges(true)
		setLanguage(newLanguage)
	}

	const handleCompanyNameChange = (newCompanyName: string) => {
		setHasUnsavedChanges(true)
		setCompanyName(newCompanyName)
	}

	const handleCompanyLogoUploaded = (file: UploadedFile): void => {
		setHasUnsavedChanges(true)
		setCompanyLogoImageId(file.id)
	}

	const handleCompanyLogoDelete = (): void => {
		setHasUnsavedChanges(true)
		setCompanyLogoImageId(null)
	}

	const handleMeddicScoringToggle = (newValue: boolean) => {
		setHasUnsavedChanges(true)
		setIsMeddicScoringEnabled(newValue)
	}

	const handleHideMeddicSectionToggle = (section: string) => {
		setHasUnsavedChanges(true)
		setMeddicSectionsToHide((prev) =>
			prev.includes(section) ? prev.filter((s) => s !== section) : [...prev, section],
		)
	}

	const handleSubmit = async () => {
		try {
			setFormStatus({ status: "submitting" })
			await Promise.all([
				workspacesGateway.updatePreferences({
					language,
					companyLogoId: companyLogoImageId,
					companyDetails: { companyName },
					workspacePreferences: {
						hideMeddicScoring: !isMeddicScoringEnabled,
						meddicSectionsToHide,
					},
				}),
				sleep(600), // Artificial delay to avoid UI glitch because of the spinner that would appear and disappear too quickly
			])
			await refetchMe()
			setFormStatus({ status: "success" })
			setHasUnsavedChanges(false)
			toast(t("Workspace preferences updated successfully"), { type: "success" })
		} catch (error) {
			if (error instanceof ApiError) {
				setFormStatus({
					status: "error",
					error,
				})
				return
			}
			console.error(error)
		}
	}

	return {
		language,
		handleLanguageChange,

		companyName,
		handleCompanyNameChange,

		companyLogoImageId,
		handleCompanyLogoUploaded,
		handleCompanyLogoDelete,

		formStatus,
		handleSubmit,

		isMeddicScoringEnabled,
		handleMeddicScoringToggle,
		meddicSectionsToHide,
		handleHideMeddicSectionToggle,

		meddicSections,
		hasUnsavedChanges,
	}
}
