import "chartjs-plugin-datalabels"
import "chartjs-plugin-annotation"

import {
	CategoryScale,
	Chart as ChartJS,
	type ChartData,
	type ChartOptions,
	Filler,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import zoomPlugin from "chartjs-plugin-zoom"
import React from "react"
import { Line } from "react-chartjs-2"

import { SectionCard } from "../../../../../components/shared/GraphSection/SectionCard.component"
import { useLanguage } from "../../../../../contexts/language.context"

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	ChartDataLabels,
	zoomPlugin,
)

export type FunnelGroupData = {
	step: string
	numberOfDaysInStage: number
	percentOfDeals: number
}

type SalesMotionFunnelProps = {
	groupA: FunnelGroupData[]
	groupB: FunnelGroupData[]
}

function makeAnnotationsForGroup(
	groupData: FunnelGroupData[],
	label: string,
	totalNumberOfDays: number,
	invert = false,
) {
	return groupData.reduce((acc, v, i) => {
		const cumulativeNumberOfDays = groupData.slice(0, i + 1).reduce((acc, v) => acc + v.numberOfDaysInStage, 0)
		const previousCumulativeNumberOfDays = i === 0 ? 0 : cumulativeNumberOfDays - v.numberOfDaysInStage
		const previousPercentOfDeals = i === 0 ? 0 : groupData[i - 1].percentOfDeals
		const diff = v.percentOfDeals - previousPercentOfDeals

		if (v.numberOfDaysInStage === 0) {
			return acc
		}

		const isTooSmallToDisplayLabel = v.numberOfDaysInStage / totalNumberOfDays < 0.05

		return {
			...acc,
			[`stage-${v.step}-${label}-line`]: {
				type: "line",
				xMin: cumulativeNumberOfDays - v.numberOfDaysInStage,
				xMax: cumulativeNumberOfDays - v.numberOfDaysInStage,
				yMin: invert ? -120 : 0,
				yMax: invert ? 0 : 120,
				label: {
					display: true,
					content: `${diff}%`,
					position: invert ? "start" : "end",
				},
				borderColor: "#000",
				borderWidth: 1,
				font: {
					size: 10,
				},
			},
			...(isTooSmallToDisplayLabel
				? {}
				: {
						[`stage-${v.step}-${label}`]: {
							type: "label",
							xMax: cumulativeNumberOfDays,
							xMin: previousCumulativeNumberOfDays,
							yMax: invert ? 0 : 100,
							yMin: invert ? -100 : 0,
							content: `${v.step}: ${v.percentOfDeals}%`,
							position: {
								x: "center",
								y: invert ? "end" : "start",
							},
							yAdjust: invert ? -15 : 15,
							font: {
								size: 10,
							},
							color: "#ffffff",
							borderColor: "#000",
							backgroundColor: "rgba(0, 0, 0, 0.7)",
							drawTime: "afterDraw",
						},
				  }),
		}
	}, {})
}

function makeData(groupData: FunnelGroupData[], invert = false) {
	return groupData
		.map((v) => {
			const diffDays = v.numberOfDaysInStage
			// compute value for each day in the stage
			const values: number[] = []

			for (let i = 0; i < diffDays; i++) {
				values.push(invert ? -v.percentOfDeals : v.percentOfDeals)
			}

			return values
		})
		.flat()
}

export function SalesMotionFunnel({ groupA, groupB }: SalesMotionFunnelProps) {
	const { t } = useLanguage()
	const maxTotalDays = Math.max(
		groupA.reduce((acc, d) => acc + d.numberOfDaysInStage, 0),
		groupB.reduce((acc, d) => acc + d.numberOfDaysInStage, 0),
	)

	const labels = React.useMemo(() => Array.from({ length: maxTotalDays }, (_, i) => i), [maxTotalDays])

	const options: ChartOptions<"line"> = React.useMemo(
		() => ({
			plugins: {
				zoom: {
					zoom: {
						drag: {
							enabled: true,
						},
						wheel: {
							enabled: true,
						},
						mode: "x",
					},
				},
				tooltip: {
					callbacks: {
						title: function () {
							return ""
						},
						label: function (context) {
							const label = context.dataset.label || ""
							const group = label === "Group A" ? groupA : groupB
							const cumulative = group.map((g, idx) => {
								const sum =
									idx === 0
										? group[0].numberOfDaysInStage
										: group.slice(0, idx + 1).reduce((acc, g) => acc + g.numberOfDaysInStage, 0)
								return {
									...g,
									sum,
								}
							})

							const stage = cumulative.find((g) => g.sum > context.dataIndex)
							if (!stage) {
								return ""
							}

							const numberOfDaysInStage = stage.numberOfDaysInStage
							return [
								label,
								`${stage.step} - ${Math.abs(Number(context.raw))}%`,
								t("{{count}} days", { count: numberOfDaysInStage }),
							]
						},
					},
					mode: "nearest",
					enabled: true,
					axis: "xy",
					intersect: false,
				},
				legend: {
					display: true,
					position: "bottom" as const,
				},
				datalabels: {
					display: false,
				},
				annotation: {
					common: {
						drawTime: "beforeDraw",
					},
					annotations: {
						...makeAnnotationsForGroup(groupA, "a", maxTotalDays),
						...makeAnnotationsForGroup(groupB, "b", maxTotalDays, true),
					},
				},
			},
			responsive: true,
			maintainAspectRatio: false,
			scales: {
				y: {
					beginAtZero: true,
					ticks: {
						callback: function (value: number | string) {
							if (typeof value === "number" && (value > 100 || value < -100)) {
								return null
							}
							return `${typeof value === "number" ? Math.abs(value) : value}%`
						},
					},
				},
				x: {
					beginAtZero: true,
				},
			},
			elements: {
				point: {
					radius: 0, // This will remove the dots on the line
				},
			},
		}),
		[groupA, groupB, maxTotalDays, t],
	)

	const data: ChartData<"line"> = React.useMemo(
		() => ({
			labels,
			datasets: [
				{
					label: "Group A",
					data: makeData(groupA),
					backgroundColor: "rgba(38,38,86,0.8)",
					borderColor: "#262656",
					fill: true,
				},
				{
					label: "Group B",
					data: makeData(groupB, true),
					backgroundColor: "rgba(234,151,175,0.8)",
					borderColor: "rgba(234,151,175,1)",
					fill: true,
				},
			],
		}),
		[groupA, groupB, labels],
	)

	return (
		<SectionCard className="h-full gap-6 flex flex-col">
			<div>
				<h3 className="text-lg font-semibold text-gray-900">{t("Pipeline timeline")}</h3>

				<span className="text-md font-medium text-gray-500">
					{t("Conversion of deals at each stage of the pipeline over time")}
				</span>
			</div>

			<div className="w-full h-[350px]">
				<Line data={data} options={options} />
			</div>
		</SectionCard>
	)
}
