import clsx from "clsx"
import md5 from "md5"

import type { User } from "../../../../core/domain/User.entity"

export type UserAvatarProps = {
	user: User
	className?: string
}

export function UserAvatar({ user, className = "h-8 w-8" }: UserAvatarProps) {
	return (
		<img
			className={clsx("rounded-full bg-gray-50", className)}
			src={`https://www.gravatar.com/avatar/${md5(user.email.toLowerCase().trim())}?s=50&d=identicon`}
		/>
	)
}
