import React from "react"
import { Navigate, useParams } from "react-router-dom"

import { SpinnerIcon } from "../../components/design-system/SpinnerIcon.component"
import { BriefRenderer } from "../../components/shared/BriefRenderer.component"
import { Layout } from "../../components/shared/Layout/Layout.component"
import { PublicLayout } from "../../components/shared/PublicLayout/PublicLayout.component"
import { useSession } from "../../contexts/authentication.context"
import { useLanguage } from "../../contexts/language.context"
import { useInviteToken } from "../../hooks/useInviteToken"
import { usePublicCalendarEvent } from "../../hooks/usePublicCalendarEvent"
import { makeCalendarEventBriefPath, makeDashboardPath, makeLoginPath } from "../../router/Router"
import { DateFormatter, DateProvider } from "../../utils/time"

export function PublicSharedCalendarEvent() {
	const { t, language } = useLanguage()
	const { calendarEventId = "should never happen - checked at router level" } = useParams<{
		calendarEventId: string
	}>()
	const { user } = useSession()
	const inviteToken = useInviteToken()
	const { publicCalendarEvent, isLoading, error } = usePublicCalendarEvent(calendarEventId, inviteToken)
	const dateProvider = new DateProvider()
	const dateFormatter = new DateFormatter(dateProvider, t)
	const isEventInPast = Boolean(publicCalendarEvent && publicCalendarEvent.endTime < dateProvider.now())

	if (user && publicCalendarEvent) {
		return (
			<Navigate
				to={
					publicCalendarEvent.briefing && !isEventInPast
						? makeCalendarEventBriefPath(publicCalendarEvent.id)
						: makeDashboardPath()
				}
				replace={true}
			/>
		)
	}

	if (!inviteToken) {
		return <Navigate to={makeLoginPath()} replace={true} />
	}

	if (error) {
		return (
			<PublicLayout
				pageName="Calendar event not found"
				isFluid
				PageTitle={<Layout.PageTitle title={t("Calendar event not found")} className="text-center" />}
				className="text-center"
				isSignupAllowed
			>
				<p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
					{t("Sorry, we couldn’t find the calendar event you were looking for.")}
				</p>
			</PublicLayout>
		)
	}

	if (!publicCalendarEvent || isLoading) {
		return (
			<PublicLayout pageName="Public calendar event" isFluid className="text-center" isSignupAllowed>
				<SpinnerIcon className={"h-8 w-8 mx-auto"} />
			</PublicLayout>
		)
	}

	const { timeUntilStartLabel, startDateLabel, endDateLabel } = dateFormatter.getFormattedDateRange(
		publicCalendarEvent.startTime,
		publicCalendarEvent.endTime,
		language,
	)

	const formattedDate = dateFormatter.getFormattedDate(publicCalendarEvent.startTime)

	return (
		<PublicLayout
			pageName="Public calendar event"
			isFluid
			PageTitle={<Layout.PageTitle title={publicCalendarEvent.title} />}
			isSignupAllowed
		>
			<div className="flex flex-col mt-6">
				<div className="flex rounded-md bg-gray-50 border p-4 gap-4">
					<span className="font-medium">
						{startDateLabel} - {endDateLabel}
					</span>
					<span>&middot;</span>
					<span>{isEventInPast ? `${formattedDate.day} : ${formattedDate.time}` : timeUntilStartLabel}</span>
				</div>

				{publicCalendarEvent.briefing ? (
					<BriefRenderer briefing={publicCalendarEvent.briefing} className="mt-2" />
				) : (
					<p className="mt-4 text-base text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
						{t("No briefing available yet for this event. Check back later.")}
					</p>
				)}
			</div>
		</PublicLayout>
	)
}
