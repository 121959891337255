import type { IDealsGateway } from "../../application/gateways/deals.gateway/deals.gateway"
import {
	type GetDealsForecastsAggregateRouteParams,
	type GetDealsForecastsAggregateRouteResponse,
	GetDealsForecastsAggregateRouteResponseSchema,
} from "../../application/gateways/deals.gateway/schemas/forecast"
import {
	GetDealsOwnersResponseSchema,
	type GetHistoricalWinRateResponse,
	GetHistoricalWinRateResponseSchema,
	type GetSalesMotionPerformanceStatsQueryParams,
	GetSalesMotionPerformanceStatsResponseSchema,
	type ListAllDealsQueryParams,
	type ListAllDealsResponse,
	ListAllDealsResponseSchema,
} from "../../application/gateways/deals.gateway/schemas/listDeals"
import {
	type GetDealsReportPayload,
	GetDealsReportResponseSchema,
	type GetDealsReportSuccessResponse,
} from "../../application/gateways/deals.gateway/schemas/report"
import { PaymentRequiredError } from "./http.authentication.gateway/http.authentication.gateway"

export class HttpDealsGateway implements IDealsGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async getSalesMotionPerformanceStats(params: GetSalesMotionPerformanceStatsQueryParams) {
		const queryParams = new URLSearchParams({
			closingPeriod: params.closingPeriod,
			changeComputationPeriod: params.changeComputationPeriod,
		})

		if (params.ownerId) {
			queryParams.append("ownerId", params.ownerId)
		}

		const endpointUrl = `${this.baseApiUrl}/deals/sales-motion-performance?${queryParams.toString()}`

		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		const json = await res.json()
		return GetSalesMotionPerformanceStatsResponseSchema.parse(json)
	}

	public async listAllDeals(params: ListAllDealsQueryParams): Promise<ListAllDealsResponse> {
		const queryParams = new URLSearchParams({
			includeClosedDealsFromLastNDays: params.includeClosedDealsFromLastNDays.toString(),
		})
		if (params.ownerId) queryParams.append("ownerId", params.ownerId)
		if (params.closingDateStart) queryParams.append("closingDateStart", params.closingDateStart.toISOString())
		if (params.closingDateEnd) queryParams.append("closingDateEnd", params.closingDateEnd.toISOString())

		const endpointUrl = `${this.baseApiUrl}/deals?${queryParams.toString()}`

		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		const json = await res.json()
		return ListAllDealsResponseSchema.parse(json)
	}

	public async getDealsReport(payload: GetDealsReportPayload): Promise<GetDealsReportSuccessResponse> {
		const endpointUrl = `${this.baseApiUrl}/deals/report`
		const res = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload),
		})

		const json = await res.json()
		const parsed = GetDealsReportResponseSchema.parse(json)
		if ("pending" in parsed) {
			return new Promise((resolve) => setTimeout(() => resolve(this.getDealsReport(payload)), 1000))
		}

		return parsed
	}

	public async getForecastsAggregate(
		params: GetDealsForecastsAggregateRouteParams,
	): Promise<GetDealsForecastsAggregateRouteResponse> {
		// return _FORECAST_PLACEHOLDER
		// return _FORECAST_ERROR_PLACEHOLDER_ERROR
		const queryParams = new URLSearchParams(params)

		const endpointUrl = `${this.baseApiUrl}/deals/forecasts/aggregate?${queryParams.toString()}`

		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		if (res.status === 402) {
			throw new PaymentRequiredError()
		}
		if (!res.ok) {
			throw new Error("Failed to fetch forecasts")
		}

		const json = await res.json()
		return GetDealsForecastsAggregateRouteResponseSchema.parse(json)
	}

	public async getHistoricalWinRate(): Promise<GetHistoricalWinRateResponse> {
		const endpointUrl = `${this.baseApiUrl}/deals/win-rate`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		const json = await res.json()
		return GetHistoricalWinRateResponseSchema.parse(json)
	}

	public async getOwners() {
		const endpointUrl = `${this.baseApiUrl}/deals/owners`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		const json = await res.json()
		return GetDealsOwnersResponseSchema.parse(json)
	}
}

const _FORECAST_PLACEHOLDER: GetDealsForecastsAggregateRouteResponse = {
	data: [
		{
			opportunityOwner: { crmId: "0052o00000DQuJrAAL", firstName: "Victor", lastName: "Arfi" },
			aggregate: { amountsInCents: { closedAndWon: 0, willClose: 0, commit: 0, mostLikely: 0, bestCase: 0 } },
		},
		{
			opportunityOwner: { crmId: "0052o00000DRinBAAT", firstName: "Georges", lastName: "Cosson" },
			aggregate: { amountsInCents: { closedAndWon: 0, willClose: 0, commit: 0, mostLikely: 0, bestCase: 0 } },
		},
		{
			opportunityOwner: { crmId: "0052o00000DQr1OAAT", firstName: "Patrick", lastName: "Joubert" },
			aggregate: { amountsInCents: { closedAndWon: 0, willClose: 0, commit: 0, mostLikely: 0, bestCase: 0 } },
		},
		{
			opportunityOwner: { crmId: "0052o00000DQuKBAA1", firstName: "Gregory", lastName: "Ruotolo" },
			aggregate: { amountsInCents: { closedAndWon: 0, willClose: 0, commit: 0, mostLikely: 0, bestCase: 0 } },
		},
	],
	error: undefined,
}

const _FORECAST_ERROR_PLACEHOLDER_ERROR: GetDealsForecastsAggregateRouteResponse = {
	data: [],
	error: { type: "requires-forecasting-feature" },
}
