import { Dialog } from "@headlessui/react"
import clsx from "clsx"
import { t } from "i18next"
import React from "react"

import type { Team } from "../../../../../core/domain/Team.entity"
import type { User } from "../../../../../core/domain/User.entity"
import { Input } from "../../../../components/design-system/Input"
import { Modal } from "../../../../components/design-system/Modal.component"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useDependencies } from "../../../../contexts/dependencies.context"

type IEditTeamModalProps = {
	open: boolean
	onClose: () => void
	onEditSuccess: () => void
	team: Team
	currentWorkspaceUsers: User[]
	type: "edit" | "create"
}

type EditionState =
	| {
			status: "loading" | "done" | "idle"
	  }
	| {
			status: "error"
			error: Error
	  }

export function EditTeamModal({
	type,
	open,
	onClose,
	onEditSuccess,
	currentWorkspaceUsers,
	team,
}: IEditTeamModalProps) {
	const { teamsGateway } = useDependencies()
	const [editionState, setEditionState] = React.useState<EditionState>({ status: "idle" })
	const [teamCopy, setTeamCopy] = React.useState<Team>(team)

	const handleTeamNameChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setTeamCopy((prevTeam) => ({ ...prevTeam, name: event.target.value }))
		setEditionState({ status: "idle" })
	}, [])

	const handleMemberChange = React.useCallback((userId: string) => {
		setTeamCopy((prevTeam) => {
			const memberIds = prevTeam.memberIds.includes(userId)
				? prevTeam.memberIds.filter((id) => id !== userId)
				: [...prevTeam.memberIds, userId]
			return { ...prevTeam, memberIds }
		})
		setEditionState({ status: "idle" })
	}, [])

	const handleClose = React.useCallback(() => {
		setEditionState({ status: "idle" })
		onClose()
	}, [onClose])

	const handleSubmit = React.useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault()
			try {
				setEditionState({
					status: "loading",
				})
				if (type === "create") {
					await teamsGateway.create(teamCopy)
				} else {
					await teamsGateway.update(teamCopy)
				}

				setEditionState({
					status: "done",
				})
				onEditSuccess()
			} catch (e) {
				if (e instanceof Error) {
					setEditionState({
						status: "error",
						error: e,
					})
				}
				console.error("Error:", e)
			}
		},
		[onEditSuccess, teamCopy, teamsGateway, type],
	)

	const isFormDisabled = Boolean(editionState.status === "loading" || !teamCopy.name)

	const sortedWorkspaceUsers = React.useMemo(
		() =>
			currentWorkspaceUsers?.sort((a, b) => {
				const aFullName = a.getFullName()
				const bFullName = b.getFullName()
				return aFullName.localeCompare(bFullName)
			}),
		[currentWorkspaceUsers],
	)

	return (
		<Modal open={open} onClose={handleClose} className="min-h-[440px]">
			<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
				{type === "edit" ? t("Update '{{teamName}}'", { teamName: team.name }) : t("Create a new team")}
			</Dialog.Title>
			<form className="flex flex-col" onSubmit={handleSubmit}>
				<Input.Group>
					<Input.Label htmlFor="teamName" placeholder="My sales team">
						{t("Team name")}
					</Input.Label>
					<Input.Input id="teamName" name="teamName" onChange={handleTeamNameChange} value={teamCopy.name} />
				</Input.Group>
				<Input.Group>
					<Input.Label>
						{t("Team members ({{count}})", {
							count: teamCopy.memberIds.length,
						})}
					</Input.Label>
					<div className="max-h-[350px] overflow-y-auto px-1">
						{sortedWorkspaceUsers.map((user) => {
							return (
								<Input.CheckboxGroup
									key={user.id}
									mainText={user.getFullName()}
									subText={user.email}
									checked={teamCopy?.memberIds.includes(user.id)}
									onChange={() => handleMemberChange(user.id)}
									id={`checkbox-${user.id}`}
								/>
							)
						})}
					</div>
				</Input.Group>

				<div className="mt-4 flex justify-end">
					<TrackingButton
						disabled={isFormDisabled}
						eventName="Edit team modal submit"
						eventProperties={{
							teamId: team.id,
						}}
						type="submit"
						className={clsx(
							"flex items-center justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-navy-500 rounded-full bg-gradient-to-r  transition duration-500 ease-in-out",
							isFormDisabled ? "opacity-50 cursor-not-allowed" : "hover:from-navy-500 hover:to-pink",
						)}
					>
						<span className="flex gap-2 items-center">
							{editionState.status === "loading" && <SpinnerIcon className={"h-5 w-5 mx-auto"} />}
							{type === "edit"
								? t("Update '{{teamName}}'", { teamName: team.name })
								: t("Create a new team")}
						</span>
					</TrackingButton>
				</div>
			</form>
		</Modal>
	)
}
