import clsx from "clsx"
import React from "react"

type ISkeletonProps = {
	size: "S" | "M" | "L" | "XL"
	className?: string
}

export function Skeleton({ size, className }: ISkeletonProps) {
	return (
		<div role="status" className={clsx("max-w-sm animate-pulse", className)}>
			<div
				className={clsx(
					"h-[10px] bg-slate-200 rounded-full dark:bg-slate-500",
					size === "S" && "max-w-[60px]",
					size === "M" && "max-w-[120px]",
					size === "L" && "max-w-[240px]",
					size === "XL" && "max-w-[360px]",
				)}
			/>
		</div>
	)
}
