import clsx from "clsx"

import { TrackingLink } from "../TrackingLink.component"

export type TabProps = {
	label: string
	eventName: string
	eventProperties?: Record<string, unknown>
	linkTo: string
	Icon?: React.ElementType
	isActive: boolean
}
export function Tab({ label: name, linkTo, Icon, isActive, eventName, eventProperties }: TabProps) {
	return (
		<TrackingLink
			key={name}
			to={linkTo}
			eventName={eventName}
			eventProperties={eventProperties}
			className={clsx(
				isActive
					? "border-indigo-500 text-indigo-600"
					: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
				"group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium mx-1",
			)}
		>
			{Icon && (
				<Icon
					className={clsx(
						isActive ? "text-indigo-500" : "text-gray-400 group-hover:text-gray-500",
						"-ml-0.5 mr-2 h-5 w-5",
					)}
					aria-hidden="true"
				/>
			)}
			<span>{name}</span>
		</TrackingLink>
	)
}
