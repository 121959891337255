import { upperFirst } from "lodash"

export function KeyValueList({
	entries,
	prefix,
}: {
	prefix?: string
	entries: Record<string, string | number | Date | null | string[]>
}) {
	return (
		<>
			{Object.entries(entries).map(([key, value]) => (
				<KeyValueItem key={key} label={makeLabel(key, prefix)} value={value} />
			))}
		</>
	)
}

function makeLabel(key: string, prefix?: string) {
	// "camelCase" => "camel case"
	const cleanedKey = key.replace(/([A-Z])/g, " $1").toLowerCase()
	const lowerLabel = [prefix, cleanedKey].join(" ").toLowerCase().trim()
	return lowerLabel.charAt(0).toUpperCase() + lowerLabel.slice(1)
}

function KeyValueItem({ label, value }: { label: string; value: string | number | Date | null | string[] }) {
	if (value === null || value === "") {
		return null
	}
	return (
		<div className="mt-2">
			<p className="text-base font-semibold text-gray-900 leading-7">{label}:</p>
			{Array.isArray(value) ? (
				<ul>
					{value.map((item) => (
						<li key={item} className="ml-2 list-disc list-inside">
							{upperFirst(item)}
						</li>
					))}
				</ul>
			) : (
				<p>{upperFirst(value.toString())}</p>
			)}
		</div>
	)
}
