import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid"
import { Fragment } from "react"

import type { CoachingAnalysis } from "../../../../../../core/domain/Call.entity"

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ")
}

type SelectModelProps = {
	alternatives: CoachingAnalysis
	selected: number
	setSelected: (index: number) => void
}

export default function SelectModel({ alternatives, selected, setSelected }: SelectModelProps) {
	const _alternatives = alternatives.map((alternative) => {
		if (!alternative.metadata) throw new Error("missing metadata")
		return alternative.metadata
	})

	return (
		<Listbox value={selected} onChange={setSelected}>
			{({ open }) => (
				<>
					<Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
						Select Model for Coaching Analysis (only visible by Rippletide employees)
					</Listbox.Label>
					<div className="relative mt-2">
						<Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
							<span className="inline-flex w-full truncate">
								<span className="truncate">{_alternatives[selected].model}</span>
								<span className="ml-2 truncate text-gray-500">
									prompt {_alternatives[selected].promptVersion} - {_alternatives[selected].comment}
								</span>
							</span>
							<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
								<ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								{_alternatives.map((alternative, i) => (
									<Listbox.Option
										key={i}
										className={({ active }) =>
											classNames(
												active ? "bg-indigo-600 text-white" : "text-gray-900",
												"relative cursor-default select-none py-2 pl-3 pr-9",
											)
										}
										value={i}
									>
										{({ selected, active }) => (
											<>
												<div className="flex">
													<span
														className={classNames(
															selected ? "font-semibold" : "font-normal",
															"truncate",
														)}
													>
														{alternative.model}
													</span>
													<span
														className={classNames(
															active ? "text-indigo-200" : "text-gray-500",
															"ml-2 truncate",
														)}
													>
														prompt {alternative.promptVersion} - {alternative.comment}
													</span>
												</div>

												{selected ? (
													<span
														className={classNames(
															active ? "text-white" : "text-indigo-600",
															"absolute inset-y-0 right-0 flex items-center pr-4",
														)}
													>
														<CheckIcon className="h-5 w-5" aria-hidden="true" />
													</span>
												) : null}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
					<br />
				</>
			)}
		</Listbox>
	)
}
