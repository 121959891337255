import clsx from "clsx"
import React from "react"
import { Trans } from "react-i18next"
import { useSearchParams } from "react-router-dom"

import type { SignupParams } from "../../../../core/application/gateways/authentication.gateway"
import { useDependencies } from "../../../contexts/dependencies.context"
import { useLanguage } from "../../../contexts/language.context"
import { makePublicWebsiteUrl } from "../../../public-website"
import { DateProvider } from "../../../utils/time"
import { Input } from "../../design-system/Input"
import { SpinnerIcon } from "../../design-system/SpinnerIcon.component"
import { TrackingButton } from "../../design-system/TrackingButton.component"
import { TrackingExternalHref } from "../../design-system/TrackingExternalHref.component"
import { ApplyCouponInput } from "../ApplyCouponInput.component"
import { getPasswordValidationErrors, type PasswordValidationError } from "./passwordValidationError"

type IClaimAccountFormProps = {
	handleSubmit: (params: SignupParams) => void
	/** Whether to disable the submit button, when a request is pending */
	isBusy: boolean
	email?: string

	formType: "signup" | "claim"
}

export function getCouponFromUrl(): string {
	return new URLSearchParams(window.location.search).get("coupon") ?? ""
}

export function ClaimAccountOrSignupForm({ handleSubmit, isBusy, email, formType }: IClaimAccountFormProps) {
	const { t } = useLanguage()
	const [password, setPassword] = React.useState("")
	const [passwordConfirm, setPasswordConfirm] = React.useState("")
	const [isFormTouched, setIsFormTouched] = React.useState(false)
	const initialCoupon = getCouponFromUrl()
	const [coupon, setCoupon] = React.useState(initialCoupon)
	const [couponStatus, setCouponStatus] = React.useState<"idle" | "loading" | "success" | "error">("idle")
	const { couponsGateway } = useDependencies()
	const [searchParams, setSearchParams] = useSearchParams()
	const [isPasswordTouched, setIsPasswordTouched] = React.useState(false)
	const [areTOSAccepted, setTOSAccepted] = React.useState(false)

	const handleCouponChange = React.useCallback((coupon: string) => {
		setCoupon(coupon)
	}, [])

	const handleCouponSubmit = React.useCallback(async () => {
		try {
			setCouponStatus("loading")
			const isValid = await couponsGateway.checkCouponValidity(coupon)
			if (!isValid) {
				setCouponStatus("error")
				return
			}

			setCouponStatus("success")
		} catch (error) {
			console.error("Failed to check coupon validity", error)
			setCouponStatus("error")
		}
	}, [coupon, couponsGateway])

	React.useEffect(() => {
		async function checkCouponValidity(coupon: string) {
			try {
				const isValid = await couponsGateway.checkCouponValidity(coupon)
				if (isValid) {
					setCouponStatus("success")
					return
				} else {
					setCoupon("")
				}
			} catch (e) {
				console.error("Failed to check coupon validity from URL", e)
			}
		}

		if (initialCoupon) {
			const newUrlSearchParams = new URLSearchParams(searchParams)
			newUrlSearchParams.delete("coupon")
			setSearchParams(newUrlSearchParams)
			checkCouponValidity(initialCoupon).catch(console.error)
		}
	}, [couponsGateway, handleCouponSubmit, initialCoupon, searchParams, setSearchParams])

	const hideTOSLabel = formType === "signup"
	const canEditEmail = formType === "signup"

	const handlePasswordChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value)
	}, [])

	const handlePasswordConfirmChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setPasswordConfirm(event.target.value)
	}, [])

	const passwordValidationErrors = React.useMemo(
		() => (!isPasswordTouched ? [] : getPasswordValidationErrors(password, passwordConfirm)),
		[isPasswordTouched, password, passwordConfirm],
	)

	const getValidationErrorClassNames = React.useCallback(
		(validationError: PasswordValidationError) => {
			if (passwordValidationErrors.includes(validationError)) {
				return "text-red-500"
			}

			if (password.length === 0) {
				return ""
			}

			return "text-green-700"
		},
		[password.length, passwordValidationErrors],
	)

	const onFormSubmit = React.useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault()
			const dateProvider = new DateProvider()

			if (passwordValidationErrors.length) {
				return
			}

			const email = event.currentTarget.email ? event.currentTarget.email.value || "" : ""
			const firstName = event.currentTarget.firstName.value
			const lastName = event.currentTarget.lastName.value
			const plainPassword = event.currentTarget.password.value

			if (typeof firstName !== "string" || typeof lastName !== "string" || typeof plainPassword !== "string") {
				alert("Internal error")
				return
			}

			if (!areTOSAccepted) {
				alert(t("You must accept the terms of service to create an account"))
				return
			}

			handleSubmit({
				email,
				firstName,
				lastName,
				plainPassword,
				timezone: dateProvider.getCurrentTimezone(),
				language: dateProvider.getLanguageCode(),
				coupon: coupon && couponStatus !== "error" ? coupon : null,
			})
		},
		[areTOSAccepted, coupon, couponStatus, handleSubmit, passwordValidationErrors.length, t],
	)

	const handleFormChange = React.useCallback((e: React.ChangeEvent<HTMLFormElement>) => {
		setIsFormTouched(true)
		if (e.target.name === "password" || e.target.name === "password-confirm") {
			setIsPasswordTouched(true)
		}
	}, [])

	const isFormDisabled = isBusy || passwordValidationErrors.length > 0 || !isFormTouched || !areTOSAccepted

	return (
		<form className="flex mt-4 flex-col gap-4" onSubmit={onFormSubmit} onChange={handleFormChange}>
			{(email || canEditEmail) && (
				<div className="flex-1">
					<label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
						{t("Email")}
					</label>
					<div className="mt-2">
						<input
							id="email"
							name="email"
							type="email"
							readOnly={!canEditEmail}
							value={email}
							className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 read-only:bg-gray-50 read-only:text-gray-500 read-only:ring-gray-200 sm:text-sm sm:leading-6"
							minLength={2}
						/>
					</div>
				</div>
			)}
			<div className="flex gap-2">
				<div className="flex-1">
					<label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">
						{t("First name")}
					</label>
					<div className="mt-2">
						<input
							id="firstName"
							name="firstName"
							type="text"
							autoComplete="firstName"
							required
							className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							minLength={2}
						/>
					</div>
				</div>

				<div className="flex-1">
					<label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">
						{t("Last name")}
					</label>
					<div className="mt-2">
						<input
							id="lastName"
							name="lastName"
							type="text"
							autoComplete="lastName"
							required
							className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							minLength={2}
						/>
					</div>
				</div>
			</div>

			<div>
				<div className="flex items-center justify-between">
					<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
						{t("Password")}
					</label>
				</div>
				<div className="mt-2">
					<input
						id="password"
						name="password"
						type="password"
						autoComplete="current-password"
						required
						className={clsx(
							"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
							passwordValidationErrors.filter((err) => err !== "passwords-dont-match").length &&
								"!ring-red-500",
						)}
						onChange={handlePasswordChange}
					/>
					<ul className="mt-2 text-sm text-gray-600 list-disc ml-6">
						<li className={clsx(getValidationErrorClassNames("password-too-short"))}>
							{t("At least 8 characters")}
						</li>
						<li className={clsx(getValidationErrorClassNames("missing-lowercase"))}>
							{t("At least one lowercase letter")}
						</li>
						<li className={clsx(getValidationErrorClassNames("missing-uppercase"))}>
							{t("At least one uppercase letter")}
						</li>
						<li className={clsx(getValidationErrorClassNames("missing-digit"))}>
							{t("At least one digit")}
						</li>
						<li className={clsx(getValidationErrorClassNames("missing-special-char"))}>
							{t("At least one special character")}
						</li>
					</ul>
				</div>
			</div>

			<div>
				<div className="flex items-center justify-between">
					<label htmlFor="password-confirm" className="block text-sm font-medium leading-6 text-gray-900">
						{t("Confirm password")}
					</label>
				</div>
				<div className="mt-2">
					<input
						id="password-confirm"
						name="password-confirm"
						type="password"
						required
						className={clsx(
							"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
							passwordValidationErrors.includes("passwords-dont-match") && "!ring-red-500",
						)}
						onChange={handlePasswordConfirmChange}
					/>
					{passwordValidationErrors.includes("passwords-dont-match") && (
						<ul className="mt-2 text-sm text-gray-600 list-disc ml-6">
							<li className="text-red-500">{t("Passwords don't match")}</li>
						</ul>
					)}
				</div>
			</div>

			<ApplyCouponInput
				coupon={coupon}
				onSubmit={handleCouponSubmit}
				onCouponChange={handleCouponChange}
				status={couponStatus}
			/>
			<Input.Group>
				<div className="max-h-[350px] overflow-y-auto px-1">
					<Input.CheckboxGroup
						id="tos"
						mainText={
							<div className="flex flex-col md:flex-row justify-center">
								<Trans
									i18nKey="I agree to the <1>Terms of Service</1>"
									components={{
										1: (
											<TrackingExternalHref
												href={makePublicWebsiteUrl("/terms-of-service")}
												target="_blank"
												className="text-center md:text-right whitespace-nowrap ml-1"
												eventName="Signup: see terms of service"
											/>
										),
									}}
								/>
							</div>
						}
						checked={areTOSAccepted}
						onChange={(event) => setTOSAccepted(event.target.checked)}
					/>
				</div>
			</Input.Group>
			<div>
				<TrackingButton
					disabled={isFormDisabled}
					eventName="Claim account modal: sign up clicked"
					type="submit"
					className={clsx(
						"flex w-full justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-navy-500 rounded-full bg-gradient-to-r transition duration-500 ease-in-out",
						!isFormDisabled && "hover:from-navy-500 hover:to-pink",
						isFormDisabled && "opacity-50 cursor-not-allowed hover:bg-navy-500",
					)}
				>
					{isBusy ? <SpinnerIcon className="h-5 w-5 mx-auto" /> : t("Sign up")}
				</TrackingButton>
				{!hideTOSLabel && (
					<div className="mt-4 text-sm text-center md:text-right flex flex-col md:flex-row">
						<Trans
							i18nKey="By signing up, you agree to our <1>Terms of Service</1>"
							components={{
								1: (
									<TrackingExternalHref
										href={makePublicWebsiteUrl("/terms-of-service")}
										target="_blank"
										className="text-center md:text-right font-semibold text-indigo-600 hover:text-indigo-500 whitespace-nowrap ml-1"
										eventName="Signup: see terms of service"
									/>
								),
							}}
						/>
					</div>
				)}
			</div>
		</form>
	)
}
