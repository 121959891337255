import type { LeadKnowledgeV1 } from "../../../../../../core/domain/Call.entity"
import { type TFunction, type TranslationKey } from "../../../../../contexts/language.context"

type LeadKnowledgeV1WithoutVersion = Omit<LeadKnowledgeV1, "version">

export function formatLeadKnowledgeV1(
	leadKnowledge: LeadKnowledgeV1WithoutVersion,
	t: TFunction,
): Record<string, string[]> | null {
	const formattedLeadKnowledge: Record<string, string[]> = Object.entries(leadKnowledge).reduce(
		(acc, [aspect, knowledge]) => {
			const formattedKnowledge = Array.isArray(knowledge) ? knowledge : [knowledge]
			const sanitizedKnowledge = formattedKnowledge.filter((item) => !!item)
			if (!sanitizedKnowledge?.length) {
				return acc
			}
			const label = t(leadKnowledgeV1KeyToTranslationKeyMap[aspect as keyof LeadKnowledgeV1WithoutVersion])
			acc[label] = sanitizedKnowledge
			return acc
		},
		{} as Record<string, string[]>,
	)
	if (Object.keys(formattedLeadKnowledge).length === 0) {
		return null
	}
	return formattedLeadKnowledge
}

// exported for i18n key usage check
export const leadKnowledgeV1KeyToTranslationKeyMap: Record<keyof LeadKnowledgeV1WithoutVersion, TranslationKey> = {
	specificNeeds: "Specific needs",
	shortTermGoals: "Short term goals",
	longTermGoals: "Long term goals",
	challenges: "Challenges",
	commentsOnProducts: "Comments on products",
	suggestionsOrRequests: "Suggestions or requests",
	critiquesAndPraises: "Critiques and praises",
	preferredCommunicationStyle: "Preferred communication style",
	reactionsToOffers: "Reactions to offers",
	negotiationStyle: "Negotiation style",
	salesOpportunities: "Sales opportunities",
	concernsAndObjections: "Concerns and objections",
	potentialRisks: "Potential risks",
	insightsDiscoveryType: "Insights discovery personality",
	insightsDiscoveryJustifications: "Insights discovery justifications",
}
