import React from "react"
import { Navigate, useSearchParams } from "react-router-dom"

import { useAnalytics } from "../../contexts/analytics.context"
import { useSession } from "../../contexts/authentication.context"
import { makeLoginPath } from "../../router/Router"
import { makePlanPurchaseUrl } from "../Settings/config"

export function PurchasePlanRedirectPage() {
	const { workspace } = useSession()
	const [params] = useSearchParams()
	const { setupEventTracking } = useAnalytics()

	const desiredPlanToPurchase = params.get("plan")
	const billingPeriodQueryParam = params.get("period")
	const desiredBillingPeriod = billingPeriodQueryParam === "monthly" ? "monthly" : "yearly"

	const correctedPlanIdToPurchase = desiredPlanToPurchase?.includes("-")
		? desiredPlanToPurchase
		: `${desiredPlanToPurchase}-1` // Default to the first plan if the plan ID is not in the correct format

	React.useEffect(() => {
		setupEventTracking({
			eventName: "Plan purchase requested",
			eventProperties: {
				plan: correctedPlanIdToPurchase,
				billingPeriod: desiredBillingPeriod,
			},
		}).trackEvent()
	}, [correctedPlanIdToPurchase, desiredBillingPeriod, setupEventTracking])

	if (!workspace) {
		return (
			<Navigate
				to={makeLoginPath({
					redirectTo: makePlanPurchaseUrl(correctedPlanIdToPurchase, desiredBillingPeriod),
				})}
			/>
		)
	}

	return <Navigate to={makePlanPurchaseUrl(correctedPlanIdToPurchase, desiredBillingPeriod)} />
}
