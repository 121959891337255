import { CheckIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import { Link } from "react-router-dom"

export type Step = {
	name: string
	description: string
	linkTo: string
	status: "complete" | "current" | "upcoming"
}

type VerticalStepperProps = {
	steps: Step[]
	stepClassNames?: {
		stepContainer?: string
		stepIconComplete?: string
		stepIconCurrent?: string
		stepIconUpcoming?: string
		stepLine?: string
		stepTextComplete?: string
		stepTextCurrent?: string
		stepTextUpcoming?: string
		descriptionText?: string
	}
}

export default function VerticalStepper({ steps, stepClassNames = {} }: VerticalStepperProps) {
	return (
		<nav aria-label="Progress">
			<ol role="list" className="overflow-hidden">
				{steps.map((step, stepIdx) => (
					<li
						key={step.name}
						className={clsx(
							stepIdx !== steps.length - 1 ? "pb-10" : "",
							"relative",
							stepClassNames.stepContainer,
						)}
					>
						{step.status === "complete" ? (
							<>
								{stepIdx !== steps.length - 1 ? (
									<div
										aria-hidden="true"
										className={clsx(
											"absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5",
											stepClassNames.stepLine || "bg-indigo-600",
										)}
									/>
								) : null}
								<Link to={step.linkTo} className="group relative flex items-start">
									<span className="flex h-9 items-center">
										<span
											className={clsx(
												"relative z-10 flex h-8 w-8 items-center justify-center rounded-full",
												stepClassNames.stepIconComplete ||
													"bg-indigo-600 group-hover:bg-indigo-800",
											)}
										>
											<CheckIcon aria-hidden="true" className="h-5 w-5 text-white" />
										</span>
									</span>
									<span className="ml-4 flex min-w-0 flex-col">
										<span
											className={clsx(
												"text-sm font-medium",
												stepClassNames.stepTextComplete || "",
											)}
										>
											{step.name}
										</span>
										<span
											className={clsx(
												"text-sm",
												stepClassNames.descriptionText || "text-gray-500",
											)}
										>
											{step.description}
										</span>
									</span>
								</Link>
							</>
						) : step.status === "current" ? (
							<>
								{stepIdx !== steps.length - 1 ? (
									<div
										aria-hidden="true"
										className={clsx(
											"absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5",
											stepClassNames.stepLine || "bg-gray-300",
										)}
									/>
								) : null}
								<Link to={step.linkTo} aria-current="step" className="group relative flex items-start">
									<span aria-hidden="true" className="flex h-9 items-center">
										<span
											className={clsx(
												"relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 bg-white",
												stepClassNames.stepIconCurrent || "border-indigo-600",
											)}
										>
											<span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
										</span>
									</span>
									<span className="ml-4 flex min-w-0 flex-col">
										<span
											className={clsx(
												"text-sm font-medium",
												stepClassNames.stepTextCurrent || "text-indigo-600",
											)}
										>
											{step.name}
										</span>
										<span
											className={clsx(
												"text-sm",
												stepClassNames.descriptionText || "text-gray-500",
											)}
										>
											{step.description}
										</span>
									</span>
								</Link>
							</>
						) : (
							<>
								{stepIdx !== steps.length - 1 ? (
									<div
										aria-hidden="true"
										className={clsx(
											"absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5",
											stepClassNames.stepLine || "bg-gray-300",
										)}
									/>
								) : null}
								<Link to={step.linkTo} className="group relative flex items-start">
									<span aria-hidden="true" className="flex h-9 items-center">
										<span
											className={clsx(
												"relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 bg-white",
												stepClassNames.stepIconUpcoming ||
													"border-gray-300 group-hover:border-gray-400",
											)}
										>
											<span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
										</span>
									</span>
									<span className="ml-4 flex min-w-0 flex-col">
										<span
											className={clsx(
												"text-sm font-medium",
												stepClassNames.stepTextUpcoming || "text-gray-500",
											)}
										>
											{step.name}
										</span>
										<span
											className={clsx(
												"text-sm",
												stepClassNames.descriptionText || "text-gray-500",
											)}
										>
											{step.description}
										</span>
									</span>
								</Link>
							</>
						)}
					</li>
				))}
			</ol>
		</nav>
	)
}
