import type { UploadedFile } from "../../../core/application/gateways/storage.gateway"
import { replaceNewlineByBr } from "../../utils/string"
import { Modal } from "../design-system/Modal.component"
import { FileUploadForm } from "./FileUploadForm.component"

type FileUploadModalProps = {
	open: boolean
	title: string
	mimeType: string
	onClose: () => void
	onFileUploaded: (file: UploadedFile) => void
	comment?: string
}

export function FileUploadModal({ open, title, mimeType, onClose, onFileUploaded, comment }: FileUploadModalProps) {
	return (
		<Modal open={open} onClose={onClose} showCloseButton={false}>
			<p className="text-lg font-semibold text-navy-900 mb-6">{title}</p>

			<div className="h-full flex flex-col">
				<FileUploadForm onFileUploaded={onFileUploaded} mimeType={mimeType} />
			</div>

			{comment && <p className="text-[#6B7280] text-sm pt-2">{replaceNewlineByBr(comment)}</p>}
		</Modal>
	)
}
