import React from "react"

export function DragHandle() {
	return (
		<div className="grid grid-cols-2 gap-0.5 w-3 h-3 mr-2">
			<div className="w-[3px] h-[3px] bg-gray-400 group-hover:bg-gray-600 rounded-full"></div>
			<div className="w-[3px] h-[3px] bg-gray-400 group-hover:bg-gray-600 rounded-full"></div>
			<div className="w-[3px] h-[3px] bg-gray-400 group-hover:bg-gray-600 rounded-full"></div>
			<div className="w-[3px] h-[3px] bg-gray-400 group-hover:bg-gray-600 rounded-full"></div>
			<div className="w-[3px] h-[3px] bg-gray-400 group-hover:bg-gray-600 rounded-full"></div>
			<div className="w-[3px] h-[3px] bg-gray-400 group-hover:bg-gray-600 rounded-full"></div>
		</div>
	)
}
