import type {
	GetHistoricalWinRateResponse,
	GetSalesMotionPerformanceStatsResponse,
} from "../../../../../core/application/gateways/deals.gateway/schemas/listDeals"

export const fakePerformanceStats: GetSalesMotionPerformanceStatsResponse = {
	currentActiveDeals: 512,
	previousActiveDeals: 658,
	currentTotalDealValue: 1322456.75,
	currentAverageDealValue: 2582.12,
	previousTotalDealValue: 1673421.89,
	previousAverageDealValue: 2543.21,
	byStage: [
		{
			currentTotalDealValue: 25345.0,
			currentActiveDeals: 14,
			previousActiveDeals: 8,
			previousTotalDealValue: 16000.0,
			stage: "Prospecting",
		},
		{
			currentTotalDealValue: 398721.45,
			currentActiveDeals: 160,
			previousActiveDeals: 190,
			previousTotalDealValue: 440932.12,
			stage: "Qualification",
		},
		{
			currentTotalDealValue: 595312.0,
			currentActiveDeals: 223,
			previousActiveDeals: 205,
			previousTotalDealValue: 575000.0,
			stage: "Needs analysis",
		},
		{
			currentTotalDealValue: 310859.25,
			currentActiveDeals: 149,
			previousActiveDeals: 140,
			previousTotalDealValue: 365982.32,
			stage: "Decision makers",
		},
		{
			currentTotalDealValue: 59236.05,
			currentActiveDeals: 23,
			previousActiveDeals: 29,
			previousTotalDealValue: 68000.0,
			stage: "Proposal",
		},
		{
			currentTotalDealValue: 48956.73,
			currentActiveDeals: 38,
			previousActiveDeals: 0,
			previousTotalDealValue: 0,
			stage: "Closed won",
			isClosedStage: true,
			isLostStage: false,
			isWonStage: true,
		},
		{
			currentTotalDealValue: 22785.67,
			currentActiveDeals: 14,
			previousActiveDeals: 0,
			previousTotalDealValue: 0,
			stage: "Closed lost",
			isClosedStage: true,
			isLostStage: true,
			isWonStage: false,
		},
	],
	wonDealCount: 38,
	lostDealCount: 14,
	wonDealAmount: 48956.73,
	lostDealAmount: 22785.67,
	winRatePercentage: 30.9,
	previousAveragePipelineDuration: 0,
	currentAveragePipelineDuration: 127.56,
}

export function makeFakeWinRateEvolution(): GetHistoricalWinRateResponse {
	const history = []
	const currentDate = new Date()

	for (let i = 0; i < 12; i++) {
		const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1)

		// Random total deals between 100 and 600
		const totalDeals = Math.floor(Math.random() * 500) + 100

		// Random won deals between 10% and 60% of total deals
		const wonDeals = Math.floor(totalDeals * (Math.random() * 0.5 + 0.1))

		history.push({
			date,
			wonDeals,
			totalDeals,
		})
	}

	// Return in reverse chronological order (most recent first)
	return { history: history.reverse() }
}
