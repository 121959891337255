import React from "react"
import { Navigate, useSearchParams } from "react-router-dom"

import { useSession } from "../contexts/authentication.context"

type IPublicRouteProps = React.PropsWithChildren<{
	makeLoggedInPath?: (searchParams: URLSearchParams) => string
}>

export function PublicRoute({ children, makeLoggedInPath }: IPublicRouteProps) {
	const { user } = useSession()
	const [searchParams] = useSearchParams()

	if (user && makeLoggedInPath) {
		return <Navigate to={makeLoggedInPath(searchParams)} />
	}

	return children
}
