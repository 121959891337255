import clsx from "clsx"
import React from "react"

import type { Deal, DealRisk } from "../../../../core/application/gateways/deals.gateway/schemas/listDeals"
import { Tooltip } from "../../../components/design-system/Tooltip.component"
import { TrackingExternalHref } from "../../../components/design-system/TrackingExternalHref.component"
import { useLanguage } from "../../../contexts/language.context"
import { DateProvider } from "../../../utils/time"
import { computeDealLastStageMovementAt, computeDealUpdateInLastNDays, formatCurrency } from "../utils"

function hasRisk(deal: Deal, risk: DealRisk) {
	return deal.risks?.some((r) => r.reason === risk)
}

export function DealCard({
	deal,
	instanceUrl,
	highlightChangesInTheLastNDays,
}: {
	deal: Deal
	instanceUrl: string
	highlightChangesInTheLastNDays: number
}) {
	const { t } = useLanguage()
	const lastStageMovementAt = computeDealLastStageMovementAt(deal)
	const lastDealUpdate = computeDealUpdateInLastNDays(deal, new DateProvider(), highlightChangesInTheLastNDays)
	const hasDealBeenCreatedInTheLastNDays = Boolean(
		deal.createdAt &&
			deal.createdAt.getTime() > new Date().getTime() - 1000 * 60 * 60 * 24 * highlightChangesInTheLastNDays,
	)

	const isHighlighted = Boolean(lastDealUpdate || hasDealBeenCreatedInTheLastNDays)
	const dealAmountIncrease =
		deal.amount && lastDealUpdate?.updatedProperty === "amount"
			? deal.amount - (lastDealUpdate.previousValue ?? 0)
			: null

	const hasCloseDateInPast = hasRisk(deal, "close-date-in-past")
	const hasNotMovedFromStage = hasRisk(deal, "not-moved-from-stage")

	return (
		<div
			className={clsx(
				"border-2 border-gray-200 p-2 rounded-lg flex flex-col shadow-sm max-w-[180px]",
				isHighlighted && "bg-indigo-100 border-indigo-500",
			)}
		>
			{deal.name && (
				<TrackingExternalHref
					href={`${instanceUrl}/${deal.id}`}
					eventName="Deals: deal title clicked"
					className="text-sm !font-bold text-navy-700 max-h-[40px] overflow-hidden text-ellipsis whitespace-pre-wrap"
					displayExternalIcon={false}
				>
					<span style={{ display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 2 }}>
						{deal.name}
					</span>
				</TrackingExternalHref>
			)}

			{deal.accountName && deal.accountId && (
				<TrackingExternalHref
					href={`${instanceUrl}/${deal.accountId}`}
					eventName="Deals: account name clicked"
					className="text-xs font-medium text-navy-700 max-h-[40px] overflow-hidden text-ellipsis whitespace-pre-wrap"
				>
					<span style={{ display: "-webkit-box", WebkitBoxOrient: "vertical", WebkitLineClamp: 2 }}>
						{deal.accountName}
					</span>
				</TrackingExternalHref>
			)}
			{deal.amount && (
				<span className={clsx("text-xs", dealAmountIncrease && "text-indigo-500")}>
					{formatCurrency(deal.amount)}
					{dealAmountIncrease &&
						` (${dealAmountIncrease > 0 ? "+ " : ""}${formatCurrency(dealAmountIncrease)})`}
				</span>
			)}
			{deal.closeDate && (
				<Tooltip
					content={hasCloseDateInPast ? t("Close date in past") : null}
					tooltipClassName="!max-w-[60px] !w-[60px] !min-w-[120px]"
				>
					<span className={clsx("text-xs", hasCloseDateInPast && "text-red-500 font-medium")}>
						{deal.closeDate.toLocaleDateString()}
					</span>
				</Tooltip>
			)}
			<div className="flex justify-between items-center">
				{deal.closedAt ? (
					<span
						className={clsx(
							"text-xs italic text-gray-600",
							hasNotMovedFromStage && "text-red-500 font-medium",
							lastDealUpdate?.updatedProperty === "stage" && "text-indigo-500",
						)}
					>
						{deal.isWon ? t("Won") : t("Lost")}: {deal.closedAt.toLocaleDateString()}
					</span>
				) : lastStageMovementAt ? (
					<span
						className={clsx(
							"text-xs italic text-gray-600",
							hasNotMovedFromStage && "text-red-500 font-medium",
							lastDealUpdate?.updatedProperty === "stage" && "text-indigo-500",
						)}
					>
						{t("Moved")}: {lastStageMovementAt.toLocaleDateString()}
					</span>
				) : deal.createdAt ? (
					<span
						className={clsx(
							"text-xs italic text-gray-600",
							hasDealBeenCreatedInTheLastNDays && "text-indigo-500",
							hasNotMovedFromStage && "text-red-500 font-medium",
						)}
					>
						{t("Created")}: {deal.createdAt.toLocaleDateString()}
					</span>
				) : null}
				{deal.ownerFirstName && deal.ownerLastName && (
					<Tooltip content={`${deal.ownerFirstName} ${deal.ownerLastName}`} tooltipClassName="max-w-[140px]">
						<span className="w-6 h-6 rounded-full border border-navy-700 text-xs ml-2 p-0.5 flex items-center justify-center font-medium uppercase">
							{deal.ownerFirstName[0]}
							{deal.ownerLastName[0]}
						</span>
					</Tooltip>
				)}
			</div>
		</div>
	)
}
