import { SectionCard } from "../../../../components/shared/GraphSection/SectionCard.component"
import { useLanguage } from "../../../../contexts/language.context"

export function ForecastVersusActual() {
	const { t } = useLanguage()

	return (
		<SectionCard className="h-full gap-6 flex flex-col bg-gray-200">
			<div className="flex">
				<div className="flex-1">
					<h3 className="text-lg font-semibold text-gray-500">
						{t("Forecast vs actual")} <i>{t("(coming soon)")}</i>
					</h3>
					<span className="text-md font-medium text-gray-500">
						{t("Amount forecasted mid-month vs actual amount won")}
					</span>
				</div>
				<div className="w-[170px]">
					<div className="h-full">
						<Legend />
					</div>
				</div>
			</div>
			<div className="flex-1">
				<div className="h-full">{/*tbd*/}</div>
			</div>
		</SectionCard>
	)
}

function Legend() {
	const { t } = useLanguage()

	return (
		<div className="flex flex-col">
			<div className="flex items-center">
				<div className="w-8 h-4 bg-[#D55731] mr-2"></div>
				<span className="text-gray-600">{t("Best case")}</span>
			</div>
			<div className="flex items-center">
				<div className="w-8 h-4 bg-[#272744] mr-2"></div>
				<span className="text-gray-600">{t("Most likely")}</span>
			</div>
			<div className="flex items-center">
				<div className="w-8 h-4 bg-[#E597BB] mr-2"></div>
				<span className="text-gray-600">{t("Commit")}</span>
			</div>
		</div>
	)
}
