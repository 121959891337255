import { CheckIcon } from "@heroicons/react/24/outline"
import React from "react"
import { useSearchParams } from "react-router-dom"

import { Modal } from "../../../../components/design-system/Modal.component"
import { useLanguage } from "../../../../contexts/language.context"

type IPlanUpdateSuccessModalProps = {
	subscriptionEndAt: Date
	planName: string
}

export function PlanUpdateSuccessModal({ planName, subscriptionEndAt }: IPlanUpdateSuccessModalProps) {
	const { t } = useLanguage()
	const [searchParams, setSearchParams] = useSearchParams()
	const isModalOpen = searchParams.get("success") === "plan-update"

	const handleCloseModal = React.useCallback(() => {
		const url = new URL(window.location.toString())
		url.searchParams.delete("success")
		setSearchParams(url.searchParams)
	}, [setSearchParams])

	return (
		<Modal open={isModalOpen} showCloseButton={false} onClose={handleCloseModal} className="!max-w-md">
			<div>
				<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
					<CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
				</div>
				<div className="mt-3 text-center sm:mt-5">
					<h3 className="text-base font-semibold leading-6 text-gray-900">
						{t("Your {{planName}} plan is now active", {
							planName,
						})}
					</h3>
					<div className="mt-2">
						<p className="text-sm text-gray-500">
							{t("Your subscription will be automatically renewed on {{subscriptionEndAt}}", {
								subscriptionEndAt: subscriptionEndAt.toLocaleDateString(),
							})}
						</p>
					</div>
				</div>
			</div>
		</Modal>
	)
}
