import { Transition } from "@headlessui/react"
import React from "react"

import { BottomControls } from "./controls/BottomControls.component"
import { CenterControls } from "./controls/CenterControls.component"

export type IControlsProps = {
	isPlaying: boolean
	onPlayPauseClick: () => void
	onBackwardClick: () => void
	onForwardClick: () => void
	onVolumeChange: React.ChangeEventHandler<HTMLInputElement>
	currentTimeSeconds: number
	totalDurationSeconds: number
	showControls: boolean
	volumePercentage: number
	onPlayBackSpeedChange: (newSpeed: number) => void
	playBackSpeed: number
	onFullScreenToggle: () => void
}

export function Controls({
	onPlayPauseClick,
	isPlaying,
	onBackwardClick,
	onForwardClick,
	onVolumeChange,
	currentTimeSeconds,
	totalDurationSeconds,
	showControls,
	volumePercentage,
	playBackSpeed,
	onPlayBackSpeedChange,
	onFullScreenToggle,
}: IControlsProps) {
	return (
		<Transition
			show={showControls}
			enter="transition-opacity duration-300"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition-opacity duration-300"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			<div>
				<OverlayBackground />
				<BottomControls
					onPlayPauseClick={onPlayPauseClick}
					isPlaying={isPlaying}
					currentTimeSeconds={currentTimeSeconds}
					totalDurationSeconds={totalDurationSeconds}
					volumePercentage={volumePercentage}
					onVolumeChange={onVolumeChange}
					playBackSpeed={playBackSpeed}
					onPlayBackSpeedChange={onPlayBackSpeedChange}
					onFullScreenToggle={onFullScreenToggle}
				/>
				<CenterControls
					onPlayPauseClick={onPlayPauseClick}
					isPlaying={isPlaying}
					onBackwardClick={onBackwardClick}
					onForwardClick={onForwardClick}
				/>
			</div>
		</Transition>
	)
}

function OverlayBackground() {
	return <div className="absolute top-0 left-0 right-0 bottom-0 bg-navy-700 rounded-t-lg bg-opacity-50" />
}
