import _ from "lodash"

import type {
	GetDealsForecastsAggregateRouteResponse,
	OpportunityOwner,
	PersonOpportunitiesAggregate,
} from "../../../../../core/application/gateways/deals.gateway/schemas/forecast"
import { SectionCard } from "../../../../components/shared/GraphSection/SectionCard.component"
import { useLanguage } from "../../../../contexts/language.context"
import { ForecastTableHeaderChip } from "./components/ForecastTableHeaderChip.component"
import { MoneyAmount } from "./components/MoneyAmount"

type ForecastTableProps = {
	data: GetDealsForecastsAggregateRouteResponse["data"]
}

export function ForecastTable(props: ForecastTableProps) {
	const { t } = useLanguage()
	void t

	return (
		<SectionCard className="gap-6 flex flex-col">
			<CustomTable data={props.data} />
		</SectionCard>
	)
}

type CustomTableProps = {
	data: GetDealsForecastsAggregateRouteResponse["data"]
}

function CustomTable({ data }: CustomTableProps) {
	const { t } = useLanguage()

	data = _.sortBy(data, [(o) => o.opportunityOwner.firstName, (o) => o.opportunityOwner.lastName])

	const headerData = getHeaderData(data)

	return (
		<div className="overflow-x-auto px-3 py-6">
			<table className="min-w-full border-collapse">
				<thead>
					<tr className="h-[7rem]">
						<th className="w-[16%] px-4 py-2 text-left"></th>
						<th className="w-[21%] border-l px-4 py-2 text-left">
							<ForecastTableHeaderChip
								title={t("Closed")}
								tooltip={t("Amount already closed out of all deals with closing dates this quarter")}
								amountsInCents={{
									current: headerData.amountsInCents.closedAndWon,
									total: headerData.amountsInCents.willClose,
								}}
								decoration={{ type: "progress-bar" }}
							/>
						</th>
						<th className="w-[21%] border-l px-4 py-2 text-left">
							<ForecastTableHeaderChip
								title={t("Commit")}
								tooltip={t("Deals with a closing probability of {{pct}}% or more", { pct: 90 })}
								amountsInCents={{
									current: headerData.amountsInCents.commit,
								}}
								decoration={{ type: "evolution", deltaComparedToSevenDaysAgo: 0 }}
							/>
						</th>
						<th className="w-[21%] border-l px-4 py-2 text-left bg-[#ebf0fe]">
							<ForecastTableHeaderChip
								title={t("Most likely")}
								tooltip={t("Deals with a closing probability of {{pct}}% or more", { pct: 70 })}
								amountsInCents={{
									current: headerData.amountsInCents.mostLikely,
								}}
							/>
						</th>
						<th className="w-[21%] border-l px-4 py-2 text-left">
							<ForecastTableHeaderChip
								title={t("Best case")}
								tooltip={t("Deals with a closing probability of {{pct}}% or more", { pct: 50 })}
								amountsInCents={{
									current: headerData.amountsInCents.bestCase,
								}}
							/>
						</th>
					</tr>
				</thead>
				<tbody className="text-sm">
					{data.map(({ opportunityOwner, aggregate: { amountsInCents } }, index) => (
						<tr key={index} className="h-[5rem] hover:bg-gray-100 group">
							<td className="border-r border-t px-4 py-2 text-left text-[#363675]">
								{formatName(opportunityOwner)}
							</td>
							<td className="border-r border-t px-4 py-2 text-left">
								{MoneyAmount(amountsInCents.closedAndWon)}
							</td>
							<td className="border-r border-t px-4 py-2 text-left">
								{MoneyAmount(amountsInCents.commit)}
							</td>
							<td className="border-r border-t px-4 py-2 text-left bg-[#ebf0fe] group-hover:bg-[#e7ebf7]">
								{MoneyAmount(amountsInCents.mostLikely)}
							</td>
							<td className="border-t px-4 py-2 text-left">{MoneyAmount(amountsInCents.bestCase)}</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	)
}

function formatName(opportunityOwner: OpportunityOwner): string {
	return `${opportunityOwner.firstName ?? ""} ${opportunityOwner.lastName ?? ""}`.trim() || "<no name>"
}

function getHeaderData(data: GetDealsForecastsAggregateRouteResponse["data"]) {
	const aggregate: PersonOpportunitiesAggregate = {
		amountsInCents: {
			closedAndWon: _(data)
				.map((row) => row.aggregate.amountsInCents.closedAndWon)
				.sum(),
			willClose: _(data)
				.map((row) => row.aggregate.amountsInCents.willClose)
				.sum(),
			commit: _(data)
				.map((row) => row.aggregate.amountsInCents.commit)
				.sum(),
			mostLikely: _(data)
				.map((row) => row.aggregate.amountsInCents.mostLikely)
				.sum(),
			bestCase: _(data)
				.map((row) => row.aggregate.amountsInCents.bestCase)
				.sum(),
		},
	}

	return aggregate
}
