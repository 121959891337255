import { useMount } from "react-use"

import { useAnalytics } from "../../../contexts/analytics.context"
import { useLanguage } from "../../../contexts/language.context"
import { makePublicWebsiteUrl } from "../../../public-website"
import { TrackingExternalHref } from "../../design-system/TrackingExternalHref.component"

export type LoginSignupLayoutProps = {
	sourcePageName: "Login" | "Signup" | "ForgotPassword"
	children: React.ReactNode
}
export function LoginSignupLayout({ children, sourcePageName }: LoginSignupLayoutProps) {
	const { t } = useLanguage()
	const { trackPageView } = useAnalytics()

	useMount(() => {
		trackPageView(sourcePageName, window.location.pathname)
	})

	return (
		<div
			className="flex min-h-screen flex-1 flex-col justify-center bg-sand-500 bg-repeat bg-cover-[50px]"
			style={{
				backgroundImage: `url('${process.env.PUBLIC_URL}board_tail_pattern_1.svg')`,
			}}
		>
			<div className="flex-grow flex items-center justify-center px-6 lg:px-8 my-12 flex-col">
				<div className="bg-white sm:mx-auto sm:w-full sm:max-w-xl p-10 rounded-2xl">
					<div className="flex flex-col items-center justify-center mt-1">
						<TrackingExternalHref
							eventName={`${sourcePageName} logo: go to rippletide.com`}
							href={makePublicWebsiteUrl()}
							className="text-center text-gray-500"
							displayExternalIcon={false}
							title={t("Explore Rippletide! Click to know more.")}
						>
							<img
								className="mx-auto h-24 w-auto rounded-xl"
								src={process.env.PUBLIC_URL + "/rippletide_logo_text_navy_background.svg"}
								alt="Rippletide's logo"
							/>
						</TrackingExternalHref>
					</div>
					{children}
				</div>
				<div className="mt-8 mx-auto max-w-7xl sm:px-6 lg:px-8 flex flex-col md:flex-row items-center justify-between space-x-8 flex-wrap bg-sand-500 rounded-md p-2">
					<p className="font-semibold text-navy-700">© Rippletide {new Date().getFullYear()}</p>
					<TrackingExternalHref
						eventName="Login: navigate to /about"
						href={makePublicWebsiteUrl("/about")}
						className="text-center underline text-sm"
					>
						{t("About us")}
					</TrackingExternalHref>
					<TrackingExternalHref
						eventName="Login: navigate to /contact"
						href={makePublicWebsiteUrl("/contact")}
						className="text-center underline text-sm"
					>
						{t("Contact us")}
					</TrackingExternalHref>
					<TrackingExternalHref
						eventName="Login: navigate to /blog"
						href={makePublicWebsiteUrl("/blog")}
						className="text-center underline text-sm"
					>
						{t("Blog")}
					</TrackingExternalHref>
					<TrackingExternalHref
						eventName="Login: navigate to /privacy-policy"
						href={makePublicWebsiteUrl("/privacy-policy")}
						className="text-center underline text-sm"
					>
						{t("Privacy policy")}
					</TrackingExternalHref>
					<TrackingExternalHref
						eventName="Login: navigate to terms-of-service"
						href={makePublicWebsiteUrl("/terms-of-service")}
						className="text-center underline text-sm"
					>
						{t("Terms of service")}
					</TrackingExternalHref>
				</div>
			</div>
		</div>
	)
}
