import type { LinkProps } from "react-router-dom"

import { TrackingButton } from "./TrackingButton.component"
import { TrackingLink } from "./TrackingLink.component"

type TrackingLinkOrButtonProps_Base = {
	className?: string
	eventName: string
	eventProperties?: Record<string, unknown>
	children: React.ReactNode
}

type TrackingLinkOrButtonProps_Button = TrackingLinkOrButtonProps_Base & {
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
	to?: never
}

type TrackingLinkOrButtonProps_Link = TrackingLinkOrButtonProps_Base & {
	onClick?: never
	to: LinkProps["to"]
}

type TrackingLinkOrButtonProps = TrackingLinkOrButtonProps_Button | TrackingLinkOrButtonProps_Link

export function TrackingLinkOrButton(props: TrackingLinkOrButtonProps) {
	if (props.to) {
		return <TrackingLink {...props} children={props.children} />
	} else {
		return <TrackingButton {...props} children={props.children} />
	}
}
