import React, { useEffect, useState } from "react"

import type { Call } from "../../../../../core/domain/Call.entity"
import type { CallHighlight } from "../../../../../core/domain/Library.entity"
import { CallVideoPlayerProvider } from "../../../../contexts/callVideoPlayer.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { Call_MainPanel } from "../../../Call/components/_MainPanel/_MainPanel.component"

type HighlightFloatingVideoPreviewProps = {
	highlight: CallHighlight
}

function FloatingVideoPreview({ highlight, call }: HighlightFloatingVideoPreviewProps & { call: Call }) {
	const allowedTimeRanges = [{ startSec: highlight.startsAtMs / 1000, endSec: highlight.endsAtMs / 1000 }]

	return (
		<CallVideoPlayerProvider call={call} allowedTimeRanges={allowedTimeRanges}>
			<Call_MainPanel call={call} highlights={[highlight]} timeline={false} />
		</CallVideoPlayerProvider>
	)
}

export function HighlightFloatingVideoPreview({ highlight }: HighlightFloatingVideoPreviewProps) {
	const [currentCall, setCurrentCall] = useState<Call | null>(null)
	const { callsGateway } = useDependencies()

	useEffect(() => {
		setCurrentCall(null)
		callsGateway
			.get(highlight.callId)
			.then((call) => setCurrentCall(call))
			.catch((err) => console.error("Failed to get call", err))
	}, [callsGateway, highlight])

	if (!currentCall) {
		return null
	}

	return <FloatingVideoPreview highlight={highlight} call={currentCall} />
}
