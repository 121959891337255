import { Trans } from "react-i18next"

import { Button } from "../../components/design-system/Button.component"
import { TrackingExternalHref } from "../../components/design-system/TrackingExternalHref.component"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useDependencies } from "../../contexts/dependencies.context"
import { useLanguage } from "../../contexts/language.context"
import { makePublicWebsiteUrl } from "../../public-website"

export function AcceptTermsPage() {
	const { t } = useLanguage()
	const { refetchMe } = useAuthenticatedSession()
	const { workspacesGateway } = useDependencies()

	const handleTermsAccept = async () => {
		await workspacesGateway.acceptTerms()
		await refetchMe()
	}

	return (
		<div
			className="flex min-h-screen flex-1 flex-col justify-center bg-sand-500 bg-repeat bg-cover-[50px]"
			style={{
				backgroundImage: `url('${process.env.PUBLIC_URL}board_tail_pattern_1.svg')`,
			}}
		>
			<div className="flex-grow flex items-center justify-center px-6 lg:px-8 my-12 flex-col">
				<div className="flex flex-col items-center justify-center mt-1">
					<TrackingExternalHref
						eventName="Accept terms logo: go to rippletide.com"
						href={makePublicWebsiteUrl()}
						className="text-center text-gray-500"
						displayExternalIcon={false}
						title={t("Explore Rippletide! Click to know more.")}
					>
						<img
							className="mx-auto h-24 w-auto rounded-xl"
							src={process.env.PUBLIC_URL + "/rippletide_logo_text_navy_background.svg"}
							alt="Rippletide's logo"
						/>
					</TrackingExternalHref>
				</div>
				<div className="flex flex-col items-center justify-center p-6 space-y-4 bg-gray-50 rounded-md shadow-md mt-6">
					<p className="flex text-center text-sm text-gray-500">
						<Trans
							i18nKey="You must accept our <1>Terms of Service</1> to use our service."
							components={{
								1: (
									<TrackingExternalHref
										href={makePublicWebsiteUrl("/terms-of-service")}
										target="_blank"
										className="text-center md:text-right whitespace-nowrap mx-1"
										eventName="Accept terms: see terms of service"
									/>
								),
							}}
						/>
					</p>
					<Button type="button" variant="primary" onClick={handleTermsAccept} eventName="Terms accepted">
						{t("I accept Rippletide's Terms of Service")}
					</Button>
				</div>
			</div>
		</div>
	)
}
