import "react-medium-image-zoom/dist/styles.css"

import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import { ArrowPathIcon, EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import { Fragment, useCallback } from "react"
import Zoom from "react-medium-image-zoom"

import type { Call } from "../../../../core/domain/Call.entity"
import type { TagType } from "../../../../core/domain/TagType.entity"
import type { User } from "../../../../core/domain/User.entity"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { useLanguage } from "../../../contexts/language.context"
import { useRelationshipsContext } from "../../../contexts/relationships.context"
import { useCallFeaturesVisibility } from "../../../hooks/useCallFeaturesVisibility"
import { makeCallPath } from "../../../router/Router"
import { getRelationshipAccountDisplayName } from "../../../utils/relationship"
import { Badge } from "../../design-system/Badge.component"
import { TextAvatar } from "../../design-system/TextAvatar.component"
import { TrackingButton } from "../../design-system/TrackingButton.component"
import { TrackingLink } from "../../design-system/TrackingLink.component"
import { CallTagList } from "../CallTagList.component"
import { _AnalysisCell } from "./_AnalysisCell.component"

export type _CallRowProps = {
	refreshCalls?: (reason: string) => Promise<void>
	call: Call
	displayAssignedUser: boolean
	displayScore: "overall" | "MEDDIC" | undefined
	users?: User[]
	availableTags: TagType[] | "loading"
	displayTags?: boolean
	nameRowClassName?: string
}

function getInitials(name: string) {
	return name
		.split(" ")
		.map((part) => part.charAt(0))
		.join("")
		.toUpperCase()
}

export function _CallRow({
	call,
	availableTags,
	displayAssignedUser,
	displayScore,
	users,
	refreshCalls,
	nameRowClassName,
	displayTags = true,
}: _CallRowProps) {
	const { t } = useLanguage()
	const { callsGateway, platformAdminGateway } = useDependencies()
	const { user, isPlatformAdmin } = useAuthenticatedSession()
	const callFeaturesVisibility = useCallFeaturesVisibility(call)
	const { showRelationshipDetailsById } = useRelationshipsContext()

	const durationLabel = call.getFormattedDuration()

	const assignedUser = users?.find((user) => user.id === call.props.assignedUserId)

	const { day, time } = call.getCreatedAtDayAndTime(t)
	const createdAt = `${day} ${time}`

	const handleCallDelete = async () => {
		if (
			!window.confirm(
				t(`Are you sure you want to delete "{{name}}"?`, {
					name: call.props.name,
				}),
			)
		) {
			return
		}
		await callsGateway.delete(call)
		await refreshCalls?.("call deleted")
	}

	const handleTagUpdateSuccess = useCallback(async () => {
		if (refreshCalls) {
			await refreshCalls("tags updated")
		}
	}, [refreshCalls])

	const handleAdminCallReprocessingRequested = async () => {
		const messages = [
			`Are you sure you want to request reprocessing of "${call.props.name}"?`,
			"This admin action cannot be undone and will trigger a reprocessing of the call.",
			"The transcription, analysis, and insights will be recalculated.",
			"The creation date of the call will be updated to the current date so that it can be shared again.",
		]
		if (!window.confirm(messages.join("\n\n"))) {
			return
		}
		await platformAdminGateway.requestCallReprocessing({ callId: call.props.id })
		await refreshCalls?.("call reprocessed")
	}

	const handleRelationshipAccountClick = useCallback(
		(call: Call) => {
			if (!call.props.relationship) {
				return
			}

			showRelationshipDetailsById(call.props.relationship.id)
		},
		[showRelationshipDetailsById],
	)

	const thumbnailUrl =
		call.props.video?.thumbnail?.url ?? process.env.PUBLIC_URL + "/rippletide_logo_navy_background.svg"

	const ZoomComponent = call.props.video?.thumbnail?.url ? Zoom : Fragment

	return (
		<tr key={call.props.id}>
			<td className={clsx("whitespace-nowrap py-2 pl-2 pr-3 items-center ", !call.isProcessable && "opacity-60")}>
				<div className="flex items-center gap-x-2 flex-shrink-0">
					<div className="flex-shrink-0 relative">
						<ZoomComponent>
							<img
								src={thumbnailUrl}
								alt={call.props.name}
								className="w-12 h-12 min-w-12 lg:max-w-12 rounded-md object-cover flex-shrink-0"
								width="48"
								height="48"
								loading="lazy"
							/>
						</ZoomComponent>
						<span className="absolute bottom-0 right-0 p-0.5 bg-gray-800 text-white text-2xs rounded-md bg-opacity-60">
							{durationLabel}
						</span>
					</div>
					<div className="flex flex-col gap-1">
						<TrackingLink
							to={makeCallPath(call.props.id, undefined)}
							eventName="View call details"
							eventProperties={{ callId: call.props.id }}
							className="flex items-center gap-x-2 text-sm font-medium text-indigo-600 hover:text-indigo-900 cursor-pointer"
							title={call.props.name}
						>
							<span className={clsx("truncate max-w-[16rem]", nameRowClassName)}>
								{call.isDemoCall() && (
									<Badge label={t("Example call")} variant="info" className="mr-1" />
								)}
								{call.props.name}
							</span>
						</TrackingLink>
						<p className="text-xs">{createdAt}</p>
					</div>
				</div>
			</td>
			<td className="whitespace-nowrap px-3 py-2 text-sm text-gray-700">
				{call.props.relationship ? (
					<TrackingButton
						eventName="Call table: relationship clicked"
						eventProperties={{ relationshipId: call.props.relationship.id }}
						onClick={() => handleRelationshipAccountClick(call)}
						className="text-indigo-600 hover:text-indigo-900 font-semibold !text-sm max-w-[180px] truncate"
						title={getRelationshipAccountDisplayName(call.props.relationship, t)}
					>
						{getRelationshipAccountDisplayName(call.props.relationship, t)}
					</TrackingButton>
				) : call.isDemoCall() ? (
					"Rippletide"
				) : (
					t("Unknown")
				)}
			</td>
			{displayScore && !call.isDemoCall() && (
				<td className="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
					{callFeaturesVisibility.scoring ? <_AnalysisCell call={call} className="mr-2" /> : null}
				</td>
			)}
			{displayTags && (
				<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
					<CallTagList
						call={call}
						availableTags={availableTags}
						onSaveSuccess={handleTagUpdateSuccess}
						badgeCollectionVariant="expanded"
						className="max-w-[14rem]"
					/>
				</td>
			)}

			{displayAssignedUser && (
				// TODO: clicking on this icon should filter the calls by this user
				<td
					className={clsx(
						assignedUser ? "text-gray-900 font-medium" : "text-gray-700",
						"whitespace-nowrap py-2 pl-4 pr-3 text-sm  sm:pl-6",
					)}
				>
					{call.isDemoCall() ? (
						<TextAvatar value="RT" title="Rippletide" />
					) : assignedUser ? (
						<TextAvatar
							value={getInitials(assignedUser.getFullName() ?? "")}
							title={assignedUser.getFullName()}
						/>
					) : (
						"-"
					)}
				</td>
			)}
			{user.isWorkspaceManager() && !call.isDemoCall() && (
				<td className="relative whitespace-nowrap py-2 pl-4 pr-2 text-right text-sm font-medium">
					<ManagerDropdown
						call={call}
						isPlatformAdmin={isPlatformAdmin}
						onAdminCallReprocessingRequested={handleAdminCallReprocessingRequested}
						onCallDelete={handleCallDelete}
					/>
				</td>
			)}
		</tr>
	)
}

type ManagerDropdownProps = {
	isPlatformAdmin: boolean
	call: Call
	onAdminCallReprocessingRequested: () => void
	onCallDelete: () => void
}

function ManagerDropdown({
	isPlatformAdmin,
	call,
	onAdminCallReprocessingRequested,
	onCallDelete,
}: ManagerDropdownProps) {
	const { t } = useLanguage()
	return (
		<Menu as="div" className="relative inline-block text-left">
			<div>
				<MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
					<EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
				</MenuButton>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<MenuItems
					anchor={{
						to: "bottom start",
					}}
					className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
				>
					<div className="py-1">
						{isPlatformAdmin && (
							<MenuItem>
								<TrackingButton
									eventName="Call reprocessing requested"
									eventProperties={{
										callId: call.props.id,
									}}
									onClick={onAdminCallReprocessingRequested}
									type="button"
									className="text-yellow-600 hover:text-yellow-500 hover:bg-gray-50 px-4 py-2 group w-full flex"
									title="Admin: request reprocessing of call"
								>
									<ArrowPathIcon className="h-5 w-5 mr-1" />
									{/* Admin feature, no need to i18n */}
									<span className="text-medium">Reprocess…</span>
								</TrackingButton>
							</MenuItem>
						)}
						<MenuItem>
							<TrackingButton
								eventName="Call deleted"
								eventProperties={{
									callId: call.props.id,
								}}
								onClick={onCallDelete}
								type="button"
								className="text-red-600 hover:text-red-500 hover:bg-gray-50 px-4 py-2 group w-full flex"
							>
								<TrashIcon className="h-5 w-5 mr-1" />
								<span className="text-medium">{t("Delete…")}</span>
							</TrackingButton>
						</MenuItem>
					</div>
				</MenuItems>
			</Transition>
		</Menu>
	)
}
