import { PlusIcon } from "@heroicons/react/20/solid"
import { ArrowLeftIcon } from "@heroicons/react/24/outline"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import type {
	MeetingNotesTemplateSection,
	MeetingNotesTemplateWithSections,
} from "../../../../../core/domain/MeetingNotesTemplate.entity"
import { Card } from "../../../../components/design-system/Card.component"
import { DropdownButton } from "../../../../components/design-system/DropdownButton.component"
import { LinkButton } from "../../../../components/design-system/LinkButton.component"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { makeCreateMeetingNotesTemplatePath } from "../../../../router/Router"
import { makeWorkspaceSettingsPath, type SettingsPageParams } from "../../config"
import { PreviewTemplateModal } from "./PreviewTemplateModal.component"
import { TemplateEditor } from "./TemplateEditor.component"
import { TemplateList } from "./TemplateList.component"

type CreateOrEditTemplateCardProps = {
	existingTemplate?: MeetingNotesTemplateWithSections
	isLoading?: boolean
}

function CreateOrEditTemplateCard({ existingTemplate, isLoading }: CreateOrEditTemplateCardProps) {
	const { t } = useLanguage()
	const [sections, setSections] = React.useState<MeetingNotesTemplateSection[]>(existingTemplate?.sections ?? [])
	const [title, setTitle] = React.useState(existingTemplate?.title ?? "")
	const [isSaving, setIsSaving] = React.useState(false)
	const { meetingNotesTemplatesGateway } = useDependencies()
	const navigate = useNavigate()

	React.useEffect(() => {
		if (existingTemplate) {
			setSections(existingTemplate.sections)
			setTitle(existingTemplate.title)
		}
	}, [existingTemplate])

	const handleSave = React.useCallback(
		async (applyDefault?: boolean) => {
			if (applyDefault && !window.confirm(t("Are you sure you want to apply this template?"))) {
				return
			}

			try {
				setIsSaving(true)
				if (existingTemplate) {
					await meetingNotesTemplatesGateway.updateMeetingNotesTemplate({
						templateId: existingTemplate.id,
						title,
						sections,
						makeDefault: applyDefault,
					})
					navigate(makeWorkspaceSettingsPath("templates"))
					toast(t("Template updated successfully"), {
						type: "success",
					})
				} else {
					await meetingNotesTemplatesGateway.createMeetingNotesTemplate({
						title,
						sections,
						makeDefault: applyDefault,
					})
					navigate(makeWorkspaceSettingsPath("templates"))
					toast(t("Template created successfully"), {
						type: "success",
					})
				}
			} catch (e) {
				toast(t("Something went wrong"), {
					type: "error",
				})
			} finally {
				setIsSaving(false)
			}
		},
		[existingTemplate, meetingNotesTemplatesGateway, navigate, sections, t, title],
	)

	const canSave = Boolean(
		sections.length > 0 && title && sections.every((section) => section.title && section.description) && !isSaving,
	)

	return (
		<Card
			title={t(existingTemplate ? "Edit template" : "Create template")}
			CTA={
				<div className="flex gap-2">
					<LinkButton
						to={makeWorkspaceSettingsPath("templates")}
						eventName="Back to templates button clicked"
						variant="default"
					>
						<ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />
						{t("Back to templates")}
					</LinkButton>
					<DropdownButton
						disabled={!canSave}
						eventName="Save template clicked"
						onClick={handleSave}
						variant="default"
						label={isSaving ? t("Saving...") : t("Save")}
						items={[
							{
								type: "button",
								eventName: "Save and apply template clicked",
								eventProperties: {
									templateTitle: title,
								},
								onClick: () => handleSave(true),
								name: t("Save and set as workspace template"),
							},
						]}
					/>
				</div>
			}
		>
			{isLoading ? (
				<div className="flex items-center justify-center p-4">
					<SpinnerIcon className="h-6 w-6 animate-spin" />
				</div>
			) : (
				<TemplateEditor
					onSectionsChange={setSections}
					onTitleChange={setTitle}
					sections={sections}
					title={title}
				/>
			)}
		</Card>
	)
}

function ListTemplatesCard() {
	const { meetingNotesTemplatesGateway } = useDependencies()
	const [templates, setTemplates] = React.useState<MeetingNotesTemplateWithSections[]>([])
	const { t } = useLanguage()
	const { user } = useAuthenticatedSession()
	const [isLoading, setIsLoading] = React.useState(true)
	const navigate = useNavigate()
	const [meetingNotesTemplateToPreview, setMeetingNotesTemplateToPreview] =
		React.useState<MeetingNotesTemplateWithSections | null>(null)

	React.useEffect(() => {
		setIsLoading(true)
		meetingNotesTemplatesGateway
			.listMeetingNotesTemplates()
			.then(setTemplates)
			.then(() => setIsLoading(false))
	}, [meetingNotesTemplatesGateway])

	const handleOpenPreviewModal = React.useCallback((template: MeetingNotesTemplateWithSections) => {
		setMeetingNotesTemplateToPreview(template)
	}, [])

	const handleClosePreviewModal = React.useCallback(() => {
		setMeetingNotesTemplateToPreview(null)
	}, [])

	const handleDeleteTemplate = React.useCallback(
		async (templateId: string) => {
			if (!window.confirm(t("Are you sure you want to delete this item?"))) {
				return
			}

			await meetingNotesTemplatesGateway.deleteMeetingNotesTemplate(templateId)
			const updatedTemplates = await meetingNotesTemplatesGateway.listMeetingNotesTemplates()
			setTemplates(updatedTemplates)
		},
		[meetingNotesTemplatesGateway, t],
	)

	const handleApplyTemplate = React.useCallback(
		async (template: MeetingNotesTemplateWithSections) => {
			if (!window.confirm(t("Are you sure you want to apply this template?"))) {
				return
			}

			await meetingNotesTemplatesGateway.setCurrentMeetingNotesTemplate(template.id)
			const updatedTemplates = await meetingNotesTemplatesGateway.listMeetingNotesTemplates()
			setTemplates(updatedTemplates)
		},
		[meetingNotesTemplatesGateway, t],
	)

	const handleDuplicateTemplate = React.useCallback(
		async (template: MeetingNotesTemplateWithSections) => {
			const createdTemplate = await meetingNotesTemplatesGateway.createMeetingNotesTemplate({
				title: `${template.title} (copy)`,
				sections: template.sections,
			})
			navigate(makeWorkspaceSettingsPath("templates", createdTemplate.id))
		},
		[meetingNotesTemplatesGateway, navigate],
	)

	const handleCreateTemplate = React.useCallback(() => {
		navigate(makeCreateMeetingNotesTemplatePath())
	}, [navigate])

	const [currentTemplates, otherTemplates] = templates.reduce(
		(acc, template) => {
			if (template.isCurrentWorkspaceTemplate) {
				acc[0].push(template)
			} else {
				acc[1].push(template)
			}
			return acc
		},
		[[] as MeetingNotesTemplateWithSections[], [] as MeetingNotesTemplateWithSections[]],
	)

	const sortedOtherTemplates = otherTemplates.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())
	const templatesToDisplay = [...currentTemplates, ...sortedOtherTemplates]

	return (
		<Card
			title={t("Meeting notes templates")}
			CTA={
				user.isWorkspaceManager() ? (
					<LinkButton
						to={makeCreateMeetingNotesTemplatePath()}
						eventName="Create new meeting note template clicked"
						variant="primary"
					>
						<PlusIcon className="h-5 w-5" aria-hidden="true" />
						{t("Create a new template")}
					</LinkButton>
				) : undefined
			}
		>
			{meetingNotesTemplateToPreview && (
				<PreviewTemplateModal
					meetingNoteTemplate={meetingNotesTemplateToPreview}
					onClose={handleClosePreviewModal}
				/>
			)}

			<div className="shadow-md sm:rounded-md text-gray-600 mx-8 my-4 p-4 text-sm">
				<h3 className="py-3 text-md font-medium leading-4 text-gray-900">
					{t("What are meeting notes templates?")}
				</h3>
				<p>
					{t(
						"Templates allow you to define the structure and content of your meeting notes, so you can decide what information you would like to be extracted from each call.",
					)}
				</p>
				<p className="mt-2">
					{t(
						"When you set a template as your “workspace template”, it will apply for all members of your workspace.",
					)}
				</p>
			</div>
			{isLoading ? (
				<div className="flex items-center justify-center p-4">
					<SpinnerIcon className="h-6 w-6 animate-spin" />
				</div>
			) : templatesToDisplay.length > 0 ? (
				<TemplateList
					templates={templatesToDisplay}
					onDeleteTemplate={handleDeleteTemplate}
					onApplyTemplate={handleApplyTemplate}
					onDuplicateTemplate={handleDuplicateTemplate}
					onPreviewTemplate={handleOpenPreviewModal}
				/>
			) : (
				<div className="p-6">
					<TrackingButton
						eventName="Create a new template from empty state clicked"
						type="button"
						onClick={handleCreateTemplate}
						className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
					>
						<PlusIcon className="h-12 w-12 mx-auto text-gray-400" aria-hidden="true" />
						<span className="mt-2 block text-sm font-semibold text-gray-900">
							{t("Create a new template")}
						</span>
					</TrackingButton>
				</div>
			)}
		</Card>
	)
}

function EditTemplateCard({ templateId }: { templateId: string }) {
	const { meetingNotesTemplatesGateway } = useDependencies()
	const [template, setTemplate] = React.useState<MeetingNotesTemplateWithSections | null>(null)
	const navigate = useNavigate()
	React.useEffect(() => {
		async function getTemplate() {
			const templates = await meetingNotesTemplatesGateway.listMeetingNotesTemplates()
			const selectedTemplate = templates.find((t) => t.id === templateId)
			if (selectedTemplate) {
				setTemplate(selectedTemplate)
			} else {
				navigate(makeWorkspaceSettingsPath("templates"))
			}
		}

		getTemplate()
	}, [meetingNotesTemplatesGateway, navigate, templateId])

	return <CreateOrEditTemplateCard existingTemplate={template ?? undefined} isLoading={!template} />
}

export function TemplateSettings() {
	const { action } = useParams<SettingsPageParams>()
	const isCreateAction = action === "create"

	if (action) {
		return isCreateAction ? <CreateOrEditTemplateCard /> : <EditTemplateCard templateId={action} />
	}

	return <ListTemplatesCard />
}
