import { Dialog } from "@headlessui/react"
import clsx from "clsx"
import React from "react"

import type { User } from "../../../../../core/domain/User.entity"
import { Modal } from "../../../../components/design-system/Modal.component"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { type InviteState, InviteUserForm } from "../../../../components/shared/InviteUserForm.component"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"

type IInviteUserModalProps = {
	open: boolean
	onClose: () => void
	onInviteSuccess: () => void
	currentWorkspaceUsers: User[]
}

const defaultInvitedUserRole: User["workspaceRole"] = "user"

export function InviteUserModal({ open, onClose, onInviteSuccess, currentWorkspaceUsers }: IInviteUserModalProps) {
	const { t } = useLanguage()
	const { usersGateway } = useDependencies()
	const [inviteState, setInviteState] = React.useState<InviteState>({ status: "idle" })
	const [inviteList, setInviteList] = React.useState([{ email: "", role: defaultInvitedUserRole }])

	const handleClose = React.useCallback(() => {
		setInviteState({ status: "idle" })
		setInviteList([{ email: "", role: defaultInvitedUserRole }])
		onClose()
	}, [onClose])

	const handleSubmit = React.useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault()
			try {
				setInviteState({
					status: "loading",
				})
				const uniqueInvites = inviteList.filter(
					(invite, index) => inviteList.findIndex((x) => x.email === invite.email) === index,
				)

				await Promise.all(
					uniqueInvites.map(({ email, role }) => usersGateway.inviteUserToWorkspace(email, role)),
				)

				setInviteState({
					status: "done",
				})
				setInviteList([{ email: "", role: defaultInvitedUserRole }])
				onInviteSuccess()
			} catch (e) {
				if (e instanceof Error) {
					setInviteState({
						status: "error",
						error: e,
					})
				}
				console.error("Error:", e)
			}
		},
		[inviteList, onInviteSuccess, usersGateway],
	)

	const existingUsers = inviteList.map(({ email }) => currentWorkspaceUsers.find((x) => x.email === email))

	const isFormDisabled = inviteList.some(
		({ email }, index) => !email || inviteState.status === "loading" || existingUsers[index],
	)

	return (
		<Modal open={open} onClose={handleClose} className="min-h-[440px]">
			<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
				{t("Invite users to join your workspace")}
			</Dialog.Title>
			<Dialog.Description as="p" className="text-sm text-gray-500 mt-2">
				{t("After you invite users, they will receive an email with a link to join your workspace.")}
			</Dialog.Description>

			<form className="flex mt-4 flex-col gap-2" onSubmit={handleSubmit}>
				<InviteUserForm
					inviteList={inviteList}
					onInviteListChange={setInviteList}
					currentWorkspaceUsers={currentWorkspaceUsers}
					inviteState={inviteState}
					onInviteStateChange={setInviteState}
				/>

				<div className="mt-4">
					<TrackingButton
						disabled={isFormDisabled}
						eventName="Invite user modal: invite clicked"
						type="submit"
						className={clsx(
							"flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
							isFormDisabled && "opacity-50 cursor-not-allowed hover:bg-indigo-600",
						)}
					>
						<span className="flex gap-2 items-center">
							{inviteState.status === "loading" && <SpinnerIcon className={"h-5 w-5 mx-auto"} />}
							{t("Invite users to workspace")}
						</span>
					</TrackingButton>
				</div>
			</form>
		</Modal>
	)
}
