import mixpanel from "mixpanel-browser"
import React, { createContext, useContext } from "react"

import config from "../../config"
import type { User } from "../../core/domain/User.entity"
import type { Workspace } from "../../core/domain/Workspace.entity"

/**
 * /!\ IMPORTANT /!\
 * React's StrictMode causes duplicate events while developing
 */

const DNT = Boolean(parseInt(navigator.doNotTrack || ""))

if (DNT) {
	// If DNT is set, do not initialize mixpanel
	// Avoid cluttering console with dozens of useless mixpanel warnings
	console.warn("Not initializing mixpanel because Do-Not-Track is set")
} else {
	mixpanel.init(config.mixpanel.token, {
		debug: config.env === "development",
		track_pageview: false, // doesn't work with SPAs
		persistence: "localStorage",
		// EU data residency
		api_host: "https://api-eu.mixpanel.com",
	})
}

export type AnalyticsProps = {
	eventName: string
	eventProperties?: Record<string, unknown>
}

type AnalyticsProvider = {
	identifyUser: (user: User, workspace: Workspace) => void
	logOut: () => void
	trackEvent: (eventName: string, properties?: Record<string, unknown>) => void
	trackPageView: (pageName: string, pagePath: string) => void
}

class MixpanelAnalyticsProvider implements AnalyticsProvider {
	private user: User | undefined = undefined

	public constructor() {
		// context binding, otherwise `this` is undefined
		this.identifyUser = this.identifyUser.bind(this)
		this.trackEvent = this.trackEvent.bind(this)
		this.trackPageView = this.trackPageView.bind(this)
		this.logOut = this.logOut.bind(this)
		this.shouldTrack = this.shouldTrack.bind(this)
	}

	public identifyUser(user: User, workspace: Workspace) {
		console.log("Identifying user", user, workspace)
		mixpanel.identify(user.id)
		mixpanel.people.set({
			workspaceId: workspace.id,
			$email: user.email,
			$name: `${user.firstName} ${user.lastName}`,
			firstName: user.firstName,
			lastName: user.lastName,
			workspaceRole: user.workspaceRole,
			calendarStatus: user.calendarStatus,
		})
		mixpanel.set_group("workspaceId", workspace.id)
		this.user = user
	}

	public trackEvent(eventName: string, properties?: Record<string, unknown>) {
		if (!this.shouldTrack()) return

		const userProperties = mixpanel.get_property("workspaceId")
		const eventProperties = {
			...properties,
			...userProperties,
		}
		console.log("Tracking event", eventName, eventProperties)
		mixpanel.track(eventName, eventProperties)
	}

	public trackPageView(pageName: string, pagePath: string) {
		if (!this.shouldTrack()) return

		console.log("Tracking page view", pageName, pagePath)
		mixpanel.track("Page View", {
			pageName: pageName,
			pagePath: pagePath,
		})
	}

	public logOut() {
		console.log("Logging out")
		mixpanel.reset()
	}

	private shouldTrack() {
		return config.env === "development" || !this.user?.isRippletideEmployee()
	}
}

class DummyAnalyticsProvider implements AnalyticsProvider {
	identifyUser(_user: User, _workspace: Workspace): void {
		//
	}
	logOut(): void {
		//
	}
	trackEvent(_eventName: string, _properties?: Record<string, unknown> | undefined): void {
		//
	}
	trackPageView(_pageName: string, _pagePath: string): void {
		//
	}
}

const analyticsProvider = DNT ? new DummyAnalyticsProvider() : new MixpanelAnalyticsProvider()

export const AnalyticsContext = createContext<AnalyticsProvider>(analyticsProvider)

export function AnalyticsProvider({ children }: React.PropsWithChildren) {
	return <AnalyticsContext.Provider value={analyticsProvider}>{children}</AnalyticsContext.Provider>
}

export function useAnalytics() {
	const context = useContext(AnalyticsContext)
	if (!context) {
		throw new Error("useAnalytics must be used within appropriate context")
	}

	const setupEventTracking = (eventProps: AnalyticsProps) => {
		return {
			trackEvent: () => context.trackEvent(eventProps.eventName, eventProps.eventProperties),
			/**
			 * These data attributes are supposed to be spread on the element that triggers the event
			 * They are useful for us (devs or PMs) to associate a given event with a specific element in the DOM
			 * and understand the properties that were sent with the event.
			 */
			debugDOMProps: {
				"data-event-name": eventProps.eventName,
				"data-event-properties": JSON.stringify(eventProps.eventProperties),
			},
		}
	}

	return {
		identifyUser: context.identifyUser,
		logOut: context.logOut,
		trackPageView: context.trackPageView,
		setupEventTracking,
	}
}
