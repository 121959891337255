import "react-pdf/dist/Page/AnnotationLayer.css"
import "react-pdf/dist/Page/TextLayer.css"

import { useEffect, useRef, useState } from "react"
import { Document, Page } from "react-pdf"

import { TrackingExternalHref } from "../../../../../components/design-system/TrackingExternalHref.component"
import { useLanguage } from "../../../../../contexts/language.context"

type PdfViewerProps = {
	pdfUrl: string
}

export function PdfViewer({ pdfUrl }: PdfViewerProps) {
	const { t } = useLanguage()
	const [numPages, setNumPages] = useState<number | null>(null)
	const [pageNumber, setPageNumber] = useState(1)
	const [width, setWidth] = useState<number>(window.innerWidth) // Set initial width
	const containerRef = useRef<HTMLDivElement>(null) // Ref for the container

	const onDocumentLoadSuccess = (document: { numPages: number }) => {
		setNumPages(document.numPages)
	}

	useEffect(() => {
		// Update width on window resize
		const handleResize = () => {
			if (containerRef.current) {
				setWidth(containerRef.current.offsetWidth) // Get the container's width
			}
		}
		window.addEventListener("resize", handleResize)
		handleResize() // Initial call

		return () => window.removeEventListener("resize", handleResize)
	}, [])

	const eightyPercent = width * 0.9

	return (
		<div ref={containerRef} className="flex flex-col items-center p-4 w-full">
			<Document
				file={pdfUrl}
				onLoadSuccess={onDocumentLoadSuccess}
				className="border p-4 bg-white shadow-lg w-full"
			>
				<Page pageNumber={pageNumber} width={eightyPercent} className="flex justify-center" />
			</Document>
			<span className="text-sm mt-4 text-gray-600">
				{t("Page")} {pageNumber} {t("of")} {numPages}
			</span>
			<div className="flex space-x-2 mt-4">
				<button
					className="px-4 py-2 bg-navy-500 text-white rounded hover:bg-navy-700 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
					disabled={pageNumber <= 1}
					onClick={() => setPageNumber(pageNumber - 1)}
				>
					{t("Previous")}
				</button>
				<button
					className="px-4 py-2 bg-navy-500 text-white rounded hover:bg-navy-700 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
					disabled={pageNumber >= (numPages ?? 1)}
					onClick={() => setPageNumber(pageNumber + 1)}
				>
					{t("Next")}
				</button>
			</div>
			<TrackingExternalHref
				href={pdfUrl}
				target={"_blank"}
				eventName="Download PDF"
				eventProperties={{ pdfUrl }}
				download
				displayExternalIcon={false}
				className="mt-4 mb-4 px-4 py-2 bg-navy-500 text-white rounded hover:bg-navy-700 hover:!text-gray-100 text-xs"
			>
				{t("Download PDF")}
			</TrackingExternalHref>
		</div>
	)
}
