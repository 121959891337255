import { LibraryFolder } from "../../../../core/domain/Library.entity"
import type { TFunction } from "../../../contexts/language.context"

export const COACHING_SESSIONS_FOLDER_ID = "coaching-sessions"

export function getCoachingSessionFolder(t: TFunction) {
	return new LibraryFolder(
		{
			id: COACHING_SESSIONS_FOLDER_ID,
			isUserRoot: false,
			isWorkspaceRoot: false,
			name: t("My coaching sessions"),
			tags: ["readonly", "no-children"],
			callHighlightCount: 0,
		},
		undefined,
	)
}
