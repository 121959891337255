import { DocumentTextIcon, VideoCameraIcon } from "@heroicons/react/24/outline"
import React, { useCallback } from "react"

import type { CalendarEvent } from "../../../../../core/domain/CalendarEvent.entity/CalendarEvent.entity"
import { Badge } from "../../../../components/design-system/Badge.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingExternalHref } from "../../../../components/design-system/TrackingExternalHref.component"
import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { useLanguage } from "../../../../contexts/language.context"
import { useRelationshipsContext } from "../../../../contexts/relationships.context"
import { makeDemoCallPath } from "../../../../router/Router"
import { getRelationshipAccountDisplayName, isUnknownRelationship } from "../../../../utils/relationship"
import { DateFormatter, DateProvider } from "../../../../utils/time"
import { EventBriefStatus } from "./EventBriefStatus.component"
import { EventMenu } from "./EventMenu.component"
import { EventRecordingStatus } from "./EventRecordingStatus.component"

type EventRowProps = {
	calendarEvent: CalendarEvent
	handleCalendarEventAction: (
		calendarEventId: string,
		eventAction: "send-bot-to-meeting-now" | "remove-bot-from-call",
	) => void
	handleOpenBriefingModal: (calendarEvent: CalendarEvent) => void
}

export function EventRow({ calendarEvent, handleCalendarEventAction, handleOpenBriefingModal }: EventRowProps) {
	const { t, language } = useLanguage()
	const dateProvider = new DateProvider()
	const dateFormatter = new DateFormatter(dateProvider, t)
	const { startDateLabel, endDateLabel } = dateFormatter.getFormattedDateRange(
		calendarEvent.startTime,
		calendarEvent.endTime,
		language,
	)
	const { showRelationshipDetailsById } = useRelationshipsContext()

	const handleRelationshipAccountClick = useCallback(
		(event: CalendarEvent) => {
			if (!event.relationship) {
				return
			}

			showRelationshipDetailsById(event.relationship.id)
		},
		[showRelationshipDetailsById],
	)

	return (
		<div className="grid grid-cols-12 p-2">
			<p className="col-span-2 flex flex-col text-gray-600 items-start justify-between text-xs">
				<span>{startDateLabel}</span>
				<span>{endDateLabel}</span>
			</p>
			<div className="col-span-7 flex flex-col items-start justify-between px-1">
				{(() => {
					if (calendarEvent.isDemo) {
						return (
							<TrackingLink
								to={makeDemoCallPath()}
								eventName="Dashboard: demo event meeting clicked"
								className="text-navy-500 hover:text-navy-700"
							>
								<span className="flex items-center justify-center">
									<Badge label={t("Example call")} className="mr-2" variant="info" />
									<h2 className="text-sm font-medium">{calendarEvent.title}</h2>
								</span>
							</TrackingLink>
						)
					}

					if (calendarEvent.meetingUrl) {
						return (
							<TrackingExternalHref
								href={calendarEvent.meetingUrl}
								eventName="Dashboard: open meeting URL"
								className="max-w-full"
							>
								<h2 className="text-sm font-medium truncate">{calendarEvent.title}</h2>
							</TrackingExternalHref>
						)
					}

					return <h2 className="text-sm font-medium max-w-[200px] truncate">{calendarEvent.title}</h2>
				})()}
				{calendarEvent.relationship && !isUnknownRelationship(calendarEvent.relationship) && (
					<>
						<TrackingButton
							eventName="Calendar event card: relationship clicked"
							eventProperties={{ relationshipId: calendarEvent.relationship.id }}
							onClick={() => handleRelationshipAccountClick(calendarEvent)}
							className="text-indigo-600 hover:text-indigo-900 font-semibold text-sm max-w-[180px] truncate"
							title={getRelationshipAccountDisplayName(calendarEvent.relationship, t)}
						>
							{getRelationshipAccountDisplayName(calendarEvent.relationship, t)}
						</TrackingButton>
					</>
				)}
			</div>
			<div className="col-span-3 flex justify-between">
				<div className="flex flex-col gap-1 text-gray-900">
					<div className="flex gap-2 items-center">
						<VideoCameraIcon className="h-5 w-5 text-gray-600" />
						<EventRecordingStatus calendarEvent={calendarEvent} />
					</div>
					<div className="flex gap-2 items-center">
						<DocumentTextIcon className="h-5 w-5 text-gray-600" />
						<EventBriefStatus
							calendarEvent={calendarEvent}
							onOpenBriefingModal={() => handleOpenBriefingModal(calendarEvent)}
						/>
					</div>
				</div>
				<div className="flex items-center">
					<EventMenu calendarEvent={calendarEvent} handleCalendarEventAction={handleCalendarEventAction} />
				</div>
			</div>
		</div>
	)
}
