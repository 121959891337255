import { z } from "zod"

import { DocElementSchema } from "../../../../app/utils/taggedDoc"
import { ISODateTimeSchema } from "../../../infra/gateways/ISODateTime"

export type CreateMeetingNotesTemplateParams = {
	title: string
	sections: Array<{ title: string; description: string }>
	makeDefault?: boolean
}

export type UpdateMeetingNotesTemplateParams = {
	templateId: string
	title: string
	sections: Array<{ title: string; description: string }>
	makeDefault?: boolean
}

const MeetingNotesTemplateSectionSchema = z.object({
	title: z.string(),
	description: z.string(),
})

export const MeetingNotesTemplateWithSectionsSchema = z.object({
	id: z.string(),
	title: z.string(),
	sections: z.array(MeetingNotesTemplateSectionSchema),
	isCurrentWorkspaceTemplate: z.boolean(),
	isDefault: z.boolean(),
	createdAt: ISODateTimeSchema,
	updatedAt: ISODateTimeSchema,
	createdByUserId: z.string().nullable(),
})

export const MeetingNotesPreviewSchema = z.object({
	meetingNotes: DocElementSchema,
})

export type MeetingNotesPreviewResponse = z.infer<typeof MeetingNotesPreviewSchema>
