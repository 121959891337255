export type _PageHeadingProps = {
	BackToLink?: React.ReactNode
	PageTitle?: React.ReactNode
	PageSubtitle?: React.ReactNode
	PageMeta?: React.ReactNode
	PageSummary?: React.ReactNode
	TopRightChildren?: React.ReactNode
}

export function _PageHeading({
	BackToLink,
	PageTitle,
	PageSubtitle,
	PageMeta,
	PageSummary,
	TopRightChildren,
}: _PageHeadingProps) {
	return (
		<>
			<div className="flex items-top justify-between">
				<div className="min-w-0 flex-shrink">
					{BackToLink}
					{PageTitle}
					{PageMeta}
				</div>
				<div className="">{PageSummary}</div>
				{TopRightChildren && <div className="flex-grow flex justify-end">{TopRightChildren}</div>}
			</div>
			{PageSubtitle}
		</>
	)
}
