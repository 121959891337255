import { DragDropContext, Droppable, type DropResult } from "@hello-pangea/dnd"
import { PlusIcon } from "@heroicons/react/20/solid"
import React from "react"

import type { MeetingNotesTemplateSection } from "../../../../../core/domain/MeetingNotesTemplate.entity"
import { Button } from "../../../../components/design-system/Button.component"
import { Input } from "../../../../components/design-system/Input"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../../contexts/language.context"
import { reorder } from "../../../../utils/array"
import { TemplateSectionCard } from "./TemplateSectionCard.component"

type TemplateEmptyStateProps = {
	onCreateSection: () => void
}

export function TemplateEmptyState({ onCreateSection }: TemplateEmptyStateProps) {
	const { t } = useLanguage()
	return (
		<TrackingButton
			eventName="Create new meeting note template section clicked"
			type="button"
			onClick={onCreateSection}
			className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
		>
			<PlusIcon className="h-12 w-12 mx-auto text-gray-400" aria-hidden="true" />
			<span className="mt-2 block text-sm font-semibold text-gray-900">{t("Add a section to get started")}</span>
		</TrackingButton>
	)
}

type TemplateEditorProps = {
	sections: MeetingNotesTemplateSection[]
	onSectionsChange: (sections: MeetingNotesTemplateSection[]) => void
	title: string
	onTitleChange: (title: string) => void
}

export function TemplateEditor({ sections, onSectionsChange, title, onTitleChange }: TemplateEditorProps) {
	const { t } = useLanguage()

	const handleDescriptionChange = React.useCallback(
		(index: number, description: string) => {
			const newSections = [...sections]
			newSections[index].description = description
			onSectionsChange(newSections)
		},
		[onSectionsChange, sections],
	)

	const handleTitleChange = React.useCallback(
		(index: number, title: string) => {
			const newSections = [...sections]
			newSections[index].title = title
			onSectionsChange(newSections)
		},
		[onSectionsChange, sections],
	)

	const handleDelete = React.useCallback(
		(index: number) => {
			const newSections = [...sections]
			newSections.splice(index, 1)
			onSectionsChange(newSections)
		},
		[onSectionsChange, sections],
	)

	const handleDragEnd = React.useCallback(
		(result: DropResult) => {
			if (!result.destination) {
				return
			}

			const resultDestinationIndex = result.destination.index
			onSectionsChange(reorder([...sections], result.source.index, resultDestinationIndex))
		},
		[onSectionsChange, sections],
	)

	const handleAddSection = React.useCallback(() => {
		onSectionsChange([...sections, { title: `Section ${sections.length + 1}`, description: "" }])
	}, [onSectionsChange, sections])

	const canAddSection = sections.every(
		(section) => section.description.trim().length > 0 && section.title.trim().length > 0,
	)

	return (
		<div className="px-4 py-2">
			<Input.Group className="mb-6">
				<Input.Label htmlFor="template-title">{t("Template title")}</Input.Label>
				<Input.Input
					type="text"
					id="template-title"
					className="mt-1"
					placeholder={t("Enter template title")}
					required
					value={title}
					onChange={(event) => onTitleChange(event.target.value)}
				/>
			</Input.Group>
			{sections.length ? (
				<>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId="droppable" type="group">
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{sections.map((section, index) => (
										<TemplateSectionCard
											key={`section-${index}`}
											index={index}
											title={section.title}
											description={section.description}
											onDelete={() => handleDelete(index)}
											onDescriptionChange={(description) =>
												handleDescriptionChange(index, description)
											}
											onTitleChange={(title) => handleTitleChange(index, title)}
										/>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					<div className="flex items-center justify-center">
						<Button
							disabled={!canAddSection}
							type="button"
							onClick={handleAddSection}
							eventName="Add template section button clicked"
						>
							<PlusIcon className="h-5 w-5 text-gray-5600" aria-hidden="true" />
							{t("Add section")}
						</Button>
					</div>
				</>
			) : (
				<div className="p-8">
					<TemplateEmptyState
						onCreateSection={() => onSectionsChange([{ title: "Section 1", description: "" }])}
					/>
				</div>
			)}
		</div>
	)
}
