import { z } from "zod"

import {
	leadKnowledgeSchema,
	leadKnowledgeV2Contact,
	leadKnowledgeV2OpportunitySchema,
} from "../../domain/LeadKnowledge.entity"
import { enrichedAccountSchema } from "./platform-admin.gateway"

export const salesMotionBlockerSlugSchema = z.enum([
	"INTERNAL_VALIDATION_REQUIRED",
	"CLARIFICATIONS_REQUIRED",
	"NO_DECISION_MAKER_INVOLVED",
	"NO_NEXT_STEP_PLANNED",
	"BLOCKING_TASK_NOT_ADDRESSED",
])

export type SalesMotionBlockerSlug = z.infer<typeof salesMotionBlockerSlugSchema>

export const detectedSalesMotionBlockerPropertiesSchema = z.object({
	slug: salesMotionBlockerSlugSchema,
	activationDate: z.coerce.date().nullish(),
	activeIfNoInteractionAfter: z.coerce.date().nullish(),
	rationale: z.string(),
	actions: z.array(z.string()),
})

export type DetectedSalesMotionBlockerProperties = z.infer<typeof detectedSalesMotionBlockerPropertiesSchema>

export const salesMotionBlockerPropertiesSchema = z.union([
	z.object({
		id: z.string(),
		relationshipId: z.string(),
		detectedAt: z.coerce.date(),
		isActive: z.boolean(),
		...detectedSalesMotionBlockerPropertiesSchema.shape,
	}),
	z.enum(["close-date-in-past", "not-moved-from-stage"]),
])

export type SalesMotionBlockerProperties = z.infer<typeof salesMotionBlockerPropertiesSchema>

const enrichedContactSchema = z.object({
	linkedin: z
		.object({
			companyName: z.string().optional(),
			profile: z
				.object({
					headline: z.string().nullish(),
					publicIdentifier: z.string().nullish(),
				})
				.nullish(),
		})
		.nullish(),
	email: z.string().nullish(),
})

const RelationshipAccountPropertiesSchema = z.object({
	id: z.string().nullish(),
	relationshipId: z.string(),
	crmId: z.string().nullish(),
	name: z.string().nullish(),
	enrichment: enrichedAccountSchema.nullish(),
})

const RelationshipOpportunityPropertiesSchema = z.object({
	id: z.string(),
	relationshipId: z.string(),
	crmId: z.string().nullish(),
	name: z.string().nullish(),
	amount: z.number().nullish(),
	probability: z.number().nullish(),
	stageName: z.string().nullish(),
	closeDate: z.coerce.date().nullish(),
	creationDate: z.coerce.date().nullish(),
	ownerCrmId: z.string().nullish(),
	ownerFirstName: z.string().nullish(),
	ownerLastName: z.string().nullish(),
	leadKnowledge: leadKnowledgeV2OpportunitySchema.nullish(),
})

const RelationshipContactPropertiesSchema = z.object({
	id: z.string(),
	relationshipId: z.string(),
	email: z.string(),
	crmId: z.string().nullish(),
	firstName: z.string().nullish(),
	lastName: z.string().nullish(),
	enrichment: enrichedContactSchema.nullish(),
	leadKnowledge: leadKnowledgeV2Contact.nullish(),
})

export type RelationshipContact = z.infer<typeof RelationshipContactPropertiesSchema>

const RelationshipCallActivitySchema = z.object({
	id: z.string(),
	createdAt: z.coerce.date(),
	name: z.string(),
	attendees: z.array(
		z.object({
			id: z.string(),
			email: z.string().nullish(),
			fullName: z.string().nullish(),
			invitationStatus: z.string(),
		}),
	),
	keyPoints: z.array(z.string()),
	leadKnowledge: leadKnowledgeSchema,
	thumbnailUrl: z.string().nullish(),
})

export type RelationshipCallActivity = z.infer<typeof RelationshipCallActivitySchema>

export const RelationshipSchema = z.object({
	id: z.string(),
	workspaceId: z.string(),
	accountDomains: z.array(z.string()),
	personalEmails: z.array(z.string()),
	salesMotionBlockers: z.array(salesMotionBlockerPropertiesSchema).nullish(),
	account: RelationshipAccountPropertiesSchema.nullish(),
	opportunity: RelationshipOpportunityPropertiesSchema.nullish(),
	contacts: z.array(RelationshipContactPropertiesSchema).nullish(),
	risks: z.array(z.enum(["close-date-in-past", "not-moved-from-stage"])).nullish(),
	lastActivityDate: z.coerce.date().nullish(),
	users: z
		.array(
			z.object({
				id: z.string(),
				email: z.string(),
				firstName: z.string(),
				lastName: z.string(),
			}),
		)
		.nullish(),
	activities: z
		.object({
			calls: z.array(RelationshipCallActivitySchema).nullish(),
			calendarEvents: z
				.array(
					z.object({
						id: z.string(),
						startTime: z.coerce.date(),
						endTime: z.coerce.date(),
						title: z.string().nullish(),
						attendees: z.array(
							z.object({
								email: z.string(),
								firstName: z.string().nullish(),
								lastName: z.string().nullish(),
							}),
						),
					}),
				)
				.nullish(),
		})
		.nullish(),
})

export const RelationshipOwnerSchema = z.object({
	crmId: z.string(),
	firstName: z.string().nullish(),
	lastName: z.string().nullish(),
})

export type Relationship = z.infer<typeof RelationshipSchema>

export const GetRelationshipByOpportunityIdResponseSchema = RelationshipSchema
export const GetRelationshipByIdResponseSchema = RelationshipSchema

export const GetSalesRelationshipsResponseSchema = z.object({
	relationships: z.array(RelationshipSchema),
	owners: z.array(RelationshipOwnerSchema),
})

export const GetRelationshipsResponseSchema = z.object({
	relationships: z.array(RelationshipSchema),
	total: z.number(),
})

export type GetSalesRelationshipsResponse = z.infer<typeof GetSalesRelationshipsResponseSchema>

export type GetRelationshipsResponse = z.infer<typeof GetRelationshipsResponseSchema>

export type IGetSalesRelationshipsFilter = {
	ownerId?: string | null
	closingDateStart?: Date | null
	closingDateEnd?: Date | null
}

export type IGetRelationshipsFilter = {
	ownerUserId?: string | null
	closingDateStart?: Date | null
	closingDateEnd?: Date | null
	pageSize: number
	pageNumber: number
	onlyShowAtRisk?: boolean
}

export type IRelationshipsGateway = {
	getSalesRelationships: (filter: IGetSalesRelationshipsFilter) => Promise<GetSalesRelationshipsResponse>
	getRelationships: (filter: IGetRelationshipsFilter) => Promise<GetRelationshipsResponse>
	getRelationshipByOpportunityId: (opportunityId: string) => Promise<Relationship>
	getRelationshipById: (relationshipId: string) => Promise<Relationship>
}
