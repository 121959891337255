import { z } from "zod"

import { apiLibraryFolderSchema } from "./apiLibraryFolder.schema"

export const listLibraryFoldersResponseSchema = z.object({
	workspaceRoot: apiLibraryFolderSchema,
	workspaceFolders: z.array(apiLibraryFolderSchema),
	userRoot: apiLibraryFolderSchema,
	userFolders: z.array(apiLibraryFolderSchema),
})
export type ApiListLibraryFoldersResponse = z.infer<typeof listLibraryFoldersResponseSchema>
