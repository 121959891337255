import { BookmarkSlashIcon } from "@heroicons/react/20/solid"
import { useEffect, useState } from "react"

import type { CallHighlight, LibraryFolder } from "../../../../../core/domain/Library.entity"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { useLanguage } from "../../../../contexts/language.context"
import type { CallHighlightActions } from "../types"
import { CurrentLibraryFolderHighlightsGrid } from "./CurrentLibraryFolderHighlightsGrid.component"
import { CurrentLibraryFolderPlayerPanel } from "./CurrentLibraryFolderPlayerPanel.component"
import { sortHighlights } from "./utils"

export type CurrentLibraryFolderProps = {
	currentFolder?: LibraryFolder
	highlights: CallHighlight[]
	highlightActions: CallHighlightActions
	newHighlightsAreLoading?: boolean
}

export function CurrentLibraryFolder({
	currentFolder,
	highlights,
	highlightActions,
	newHighlightsAreLoading,
}: CurrentLibraryFolderProps) {
	const { t } = useLanguage()

	const [currentHighlightIndex, setCurrentHighlightIndex] = useState(0)

	useEffect(() => {
		setCurrentHighlightIndex(0)
	}, [currentFolder])

	const goToPreviousHighlight = () => {
		if (currentHighlightIndex > 0) {
			setCurrentHighlightIndex((prev) => prev - 1)
		}
	}

	const goToNextHighlight = () => {
		if (currentHighlightIndex < highlights.length - 1) {
			setCurrentHighlightIndex((prev) => prev + 1)
		}
	}

	highlights = sortHighlights(highlights)

	if (highlights.length === 0) {
		if (currentFolder?.children.length) {
			return null // No need to display an empty state if there are children folders
		}

		return (
			<div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
				<BookmarkSlashIcon className="mx-auto h-12 w-12 text-gray-400" />
				<span className="mt-2 block text-sm font-semibold text-gray-900">{t("This folder is empty")}</span>
			</div>
		)
	}

	const currentHighlight = highlights[currentHighlightIndex]

	return (
		<>
			<div className="flex space-x-6">
				<div className="w-1/2">
					{newHighlightsAreLoading && (
						<div className="flex items-center justify-center h-full">
							<SpinnerIcon className="w-10 h-10 text-gray-400 animate-spin" />
						</div>
					)}
					{!newHighlightsAreLoading && (
						<CurrentLibraryFolderHighlightsGrid
							highlights={highlights}
							currentHighlightIndex={currentHighlightIndex}
							setCurrentHighlightIndex={setCurrentHighlightIndex}
							highlightActions={highlightActions}
						/>
					)}
				</div>
				<div className="w-1/2">
					<CurrentLibraryFolderPlayerPanel
						highlightsOfCurrentCall={highlights.filter((highlight) => {
							// Weird bug happens randomly when moving a highlight from private library to workspace one, or vice versa
							if (!highlight) console.log("Highlight is undefined", { highlights })

							return highlight.callId === currentHighlight.callId
						})}
						callHighlight={currentHighlight}
						canGoToPreviousHighlight={currentHighlightIndex !== 0}
						canGoToNextHighlight={currentHighlightIndex !== highlights.length - 1}
						goToPreviousHighlightLabel="Previous"
						goToNextHighlightLabel="Next"
						goToPreviousHighlight={goToPreviousHighlight}
						goToNextHighlight={goToNextHighlight}
					/>
				</div>
			</div>
		</>
	)
}
