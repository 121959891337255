import { z } from "zod"

import { ISODateTimeSchema } from "../../../../infra/gateways/ISODateTime"

export type ListAllDealsQueryParams = {
	ownerId?: string
	closingDateStart?: Date
	closingDateEnd?: Date
	includeClosedDealsFromLastNDays: number
}

const stageChangeSchema = z.object({
	updatedAt: ISODateTimeSchema,
	updatedProperty: z.literal("stage"),
	previousValue: z.string().nullish(),
	newValue: z.string().nullish(),
})

const amountOrProbabilityChangeSchema = z.object({
	updatedAt: ISODateTimeSchema,
	updatedProperty: z.union([z.literal("amount"), z.literal("probability")]),
	previousValue: z.number().nullish(),
	newValue: z.number().nullish(),
})

export const crmIntegrationOpportunityChangeSchema = z.union([stageChangeSchema, amountOrProbabilityChangeSchema])

export type CrmIntegrationOpportunityChange = z.infer<typeof crmIntegrationOpportunityChangeSchema>

const dealRiskSchema = z.object({
	reason: z.enum(["close-date-in-past", "not-moved-from-stage"]),
})

const dealSchema = z.object({
	id: z.string(),
	accountId: z.string().nullish(),
	name: z.string().nullish(),
	description: z.string().nullish(),
	probability: z.number().nullish(),
	stageName: z.string().nullish(),
	amount: z.number().nullish(),
	closeDate: ISODateTimeSchema.nullish(), // estimated close date
	nextStep: z.string().nullish(),
	LeadSource: z.string().nullish(),
	createdAt: ISODateTimeSchema.nullish(),
	ownerId: z.string().nullish(),
	ownerFirstName: z.string().nullish(),
	ownerLastName: z.string().nullish(),
	accountName: z.string().nullish(),
	closedAt: ISODateTimeSchema.nullish(), // actual close date
	history: z.array(crmIntegrationOpportunityChangeSchema).nullish(),
	risks: z.array(dealRiskSchema).nullish(),
	isWon: z.boolean(),
	isLost: z.boolean(),
})

export type Deal = z.infer<typeof dealSchema>

export type DealRisk = z.infer<typeof dealRiskSchema>["reason"]

export const dealOwnerSchema = z.object({
	id: z.string(),
	username: z.string(),
	fullName: z.string().nullish(),
	email: z.string(),
})

const DealStageResponseSchema = z.object({
	stage: z.string(),
	totalAmount: z.number(),
	dealCount: z.number(),
	averageDealAmount: z.number(),
	percentageAmountRelativeToAllOpenDeals: z.number(),
	isClosed: z.boolean(),
	isWon: z.boolean(),
	isLost: z.boolean(),
	atRiskDealCount: z.number(),
	atRiskAmount: z.number(),
})

export const ListAllDealsResponseSchema = z.object({
	deals: z.array(dealSchema),
	stages: z.array(DealStageResponseSchema),
	instanceUrl: z.string(),
	owners: z.array(dealOwnerSchema),
})

export type ListAllDealsResponse = z.infer<typeof ListAllDealsResponseSchema>

export const HistoryWinRateSchema = z.object({
	date: ISODateTimeSchema,
	wonDeals: z.number(),
	totalDeals: z.number(),
})

export const GetHistoricalWinRateResponseSchema = z.object({
	history: z.array(HistoryWinRateSchema),
})

export type GetHistoricalWinRateResponse = z.infer<typeof GetHistoricalWinRateResponseSchema>

export const GetSalesMotionPerformanceStatsResponseSchema = z.object({
	currentActiveDeals: z.number(),
	previousActiveDeals: z.number(),

	currentTotalDealValue: z.number(),
	previousTotalDealValue: z.number(),

	currentAverageDealValue: z.number(),
	previousAverageDealValue: z.number(),

	wonDealCount: z.number(),
	lostDealCount: z.number(),
	wonDealAmount: z.number(),
	lostDealAmount: z.number(),

	winRatePercentage: z.number(),
	currentAveragePipelineDuration: z.number(),
	previousAveragePipelineDuration: z.number(),

	byStage: z.array(
		z.object({
			stage: z.string(),
			currentActiveDeals: z.number(),
			previousActiveDeals: z.number(),
			currentTotalDealValue: z.number(),
			previousTotalDealValue: z.number(),
			isClosedStage: z.boolean().nullish(),
			isWonStage: z.boolean().nullish(),
			isLostStage: z.boolean().nullish(),
		}),
	),
})

export type GetSalesMotionPerformanceStatsResponse = z.infer<typeof GetSalesMotionPerformanceStatsResponseSchema>

export const GetSalesMotionPerformanceStatsQueryParamsSchema = z.object({
	closingPeriod: z.union([z.literal("current-quarter"), z.literal("current-month")]),
	changeComputationPeriod: z.union([z.literal("last-7-days"), z.literal("last-30-days"), z.literal("last-90-days")]),
	ownerId: z.string().nullish(),
})

export type SalesMotionClosingPeriodQueryParams = z.infer<
	typeof GetSalesMotionPerformanceStatsQueryParamsSchema
>["closingPeriod"]
export type SalesMotionChangeComputationPeriodQueryParams = z.infer<
	typeof GetSalesMotionPerformanceStatsQueryParamsSchema
>["changeComputationPeriod"]

export type GetSalesMotionPerformanceStatsQueryParams = z.infer<typeof GetSalesMotionPerformanceStatsQueryParamsSchema>

export const GetDealsOwnersResponseSchema = z.object({
	users: z.array(
		z.object({
			crmId: z.string(),
			email: z.string(),
			username: z.string(),
			fullName: z.string().nullish(),
		}),
	),
})

export type GetDealsOwnersResponse = z.infer<typeof GetDealsOwnersResponseSchema>
