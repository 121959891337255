import type { CallAssignationFilter } from "../../../core/application/gateways/calls.gateway"
import type { Team } from "../../../core/domain/Team.entity"
import type { User } from "../../../core/domain/User.entity"

export type ActiveFilters = CallAssignationFilter

export function computeActiveFilters(
	user: User,
	teams: Team[],
	selectedTeamId: string | null,
	selectedAssignedUserId: string | null,
	onlyCurrentUser: boolean | undefined,
): ActiveFilters {
	if (onlyCurrentUser) {
		return { type: "assigned", userIds: [user.id] }
	}

	const _selectedAssignedUserId = selectedAssignedUserId ?? "#any#"
	const _selectedTeamId = selectedTeamId ?? "#any#"

	// unassigned is a special case
	if (_selectedAssignedUserId === "#unassigned#") {
		return { type: "unassigned" }
	}

	// filtering on a given user
	if (_selectedAssignedUserId !== "#any#") {
		return { type: "assigned", userIds: [_selectedAssignedUserId] }
	}

	// both filters are any => any
	if (_selectedAssignedUserId === "#any#" && _selectedTeamId === "#any#") {
		// an owner can filter on any member
		if (user.isWorkspaceOwner()) {
			return { type: "any" }
		}
		// others can only filter on their teammates
		// add themselves when part of no team
		const userTeammateIds = Array.from(
			new Set(teams.filter((team) => team.memberIds.includes(user.id)).flatMap((team) => team.memberIds)),
		).concat(user.id)
		return { type: "assigned", userIds: userTeammateIds }
	}

	// filter active on team
	const selectedTeam = teams.find((team) => team.id === _selectedTeamId)
	if (!selectedTeam) {
		throw new Error()
	}
	return { type: "assigned", userIds: selectedTeam.memberIds }
}
