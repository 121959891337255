import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import React from "react"

import { type TranslationKey, useLanguage } from "../../../contexts/language.context"

export type RelationshipMenuTab = "info" | "call-activity" | "risks" | "opportunity"

const tabs: Array<{
	value: RelationshipMenuTab
	label: TranslationKey
}> = [
	{ value: "info", label: "Info" },
	{ value: "opportunity", label: "Opportunity" },
	{ value: "call-activity", label: "Call activity" },
	{ value: "risks", label: "Risks" },
]

type RelationshipPanelMenuProps = {
	currentTab: RelationshipMenuTab
	setCurrentTab: (tab: RelationshipMenuTab) => void
	salesMotionBlockersCount: number
	shouldDisplayOpportunityTab?: boolean
}

export default function RelationshipPanelMenu({
	setCurrentTab,
	currentTab,
	salesMotionBlockersCount: risksCount,
	shouldDisplayOpportunityTab,
}: RelationshipPanelMenuProps) {
	const { t } = useLanguage()
	const tabsToDisplay = shouldDisplayOpportunityTab ? tabs : tabs.filter((tab) => tab.value !== "opportunity")
	return (
		<div>
			<div className="sm:hidden">
				<label htmlFor="tabs" className="sr-only">
					Select a tab
				</label>
				<select
					id="tabs"
					name="tabs"
					value={currentTab}
					className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
					onChange={(event) => setCurrentTab(event.target.value as RelationshipMenuTab)}
				>
					{tabs.map((tab) => (
						<option key={tab.value} value={tab.value}>
							{t(tab.label)}
						</option>
					))}
				</select>
			</div>
			<div className="hidden sm:block">
				<div className="border-b border-gray-200">
					<nav aria-label="Tabs" className="-mb-px flex">
						{tabsToDisplay.map((tab) => (
							<button
								key={tab.value}
								className={clsx(
									tab.value === currentTab
										? "border-indigo-500 text-indigo-600"
										: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
									"mx-1 border-b-2 px-1 py-4 text-center text-sm font-medium flex flex-row gap-2 items-center",
									risksCount > 0 && tab.value === "risks" && "text-red-600",
								)}
								onClick={() => setCurrentTab(tab.value)}
							>
								{t(tab.label)} {tab.value === "risks" && risksCount > 0 && `(${risksCount})`}
								{tab.value === "risks" && risksCount > 0 && (
									<div className="flex flex-row text-red-600 items-center gap-1">
										<ExclamationTriangleIcon className="h-4 w-4" aria-hidden="true" />
									</div>
								)}
							</button>
						))}
					</nav>
				</div>
			</div>
		</div>
	)
}
