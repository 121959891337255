import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"

import { Button } from "../../components/design-system/Button.component"
import { LinkButton } from "../../components/design-system/LinkButton.component"
import { LoginSignupLayout } from "../../components/shared/Layout/LoginSignupLayout.component"
import { useAuthentication } from "../../contexts/authentication.context"
import { useLanguage } from "../../contexts/language.context"
import { makeLoginPath } from "../../router/Router"

export function ForgotPasswordPage() {
	const { requestPasswordReset } = useAuthentication()
	const { t } = useLanguage()
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const [email, setEmail] = React.useState(searchParams.get("email") || "")

	const handleResetPasswordFormSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		try {
			await requestPasswordReset(email)
			navigate(makeLoginPath())
			toast(
				t("If an account with this email exists, a password reset link has been sent to your email address"),
				{
					type: "success",
				},
			)
		} catch (error) {
			// Possible improvement: proper error management (not just a try/catch but a switch on the expected errors, if any) with translations
			alert("Password reset request failed") // not supposed to happen
			console.error("Error:", error)
		}
	}

	return (
		<LoginSignupLayout sourcePageName="ForgotPassword">
			<h2 className="mt-8 text-center text-2xl font-bold leading-9 tracking-tight text-navy">
				{t("Reset your password")}
			</h2>
			<div className="mt-10 ">
				<form className="space-y-6" onSubmit={handleResetPasswordFormSubmit}>
					<div>
						<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
							{t("Email address")}
						</label>
						<div className="mt-2">
							<input
								id="email"
								name="email"
								type="email"
								autoComplete="email"
								value={email}
								onChange={(event) => setEmail(event.currentTarget.value)}
								required
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-navy-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div>
						<Button
							eventName="Password reset form submitted"
							type="submit"
							variant="primary"
							className="w-full items-center justify-center"
						>
							{t("Reset password")}
						</Button>
					</div>
				</form>
			</div>
			<div className="mt-4 text-sm text-center text-gray-500 md:text-right flex flex-col md:flex-row justify-center">
				<LinkButton
					to={makeLoginPath()}
					className="text-center md:text-right whitespace-nowrap ml-1"
					eventName="Login: see terms of service"
					variant="text"
				>
					{t("Back to login")}
				</LinkButton>
			</div>
		</LoginSignupLayout>
	)
}
