import { z } from "zod"

import { apiCallHighlightSchema } from "../../../../infra/gateways/http.library.gateway/apiCallHighlights.schema"

const TrainingProgramTextItemContentSchema = z.object({
	type: z.literal("text"),
	text: z.string(),
})

export const TrainingProgramHighlightItemContentSchema = z.object({
	type: z.literal("highlight"),
	highlight: apiCallHighlightSchema,
})

export const TrainingProgramVideoItemContentSchema = z.object({
	type: z.literal("video"),
	fileName: z.string(),
	fileId: z.string(),
	fileUrl: z.string(),
})

export const TrainingProgramPdfItemContentSchema = z.object({
	type: z.literal("pdf"),
	fileId: z.string(),
	fileName: z.string(),
	fileUrl: z.string(),
})

export const TrainingProgramItemContentSchema = z.discriminatedUnion("type", [
	TrainingProgramTextItemContentSchema,
	TrainingProgramHighlightItemContentSchema,
	TrainingProgramPdfItemContentSchema,
	TrainingProgramVideoItemContentSchema,
])

export type TrainingProgramItemContent = z.infer<typeof TrainingProgramItemContentSchema>
