import { z } from "zod"

const DocElementListStrSchema = z.string()

export const DocElementSchema: z.ZodType<DocElement> = z.lazy(() =>
	z.union([DocElementListNamedSectionSchema, DocElementListUnnamedSectionSchema]),
)

export const DocElementListSchema: z.ZodType<DocElement[]> = z.lazy(() => z.array(DocElementSchema))

const DocElementListNamedSectionSchema = z
	.object({
		name: z.string(),
		content: DocElementListSchema,
		metas: z.record(z.any()),
	})
	.passthrough()

const DocElementListUnnamedSectionSchema = z
	.object({
		content: z.union([DocElementListSchema, DocElementListStrSchema]),
		metas: z.record(z.any()),
	})
	.passthrough()

type DocElementListStr = string

type DocElementListNamedSection = {
	name: string
	content: DocElementList
	metas: Record<string, unknown>
}

type DocElementListUnnamedSection = {
	content: DocElementList | DocElementListStr
	metas: Record<string, unknown>
}

// DocElement can be either a named or unnamed section
export type DocElement = DocElementListNamedSection | DocElementListUnnamedSection

// DocElementList is an array of DocElements
type DocElementList = DocElement[]
