export type _PageSubtitleProps = {
	subtitle: string
	description?: string
}

export function _PageSubtitle({ subtitle, description }: _PageSubtitleProps) {
	return (
		<div className="border-b border-gray-200 py-5">
			<h3 className="text-base font-semibold leading-6 text-gray-900">{subtitle}</h3>
			{description && <p className="mt-2 max-w-4xl text-sm text-gray-500">{description}</p>}
		</div>
	)
}
