import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import { ArrowRightOnRectangleIcon, EllipsisVerticalIcon, PaperAirplaneIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import { Fragment } from "react"

import type { CalendarEvent } from "../../../../../core/domain/CalendarEvent.entity/CalendarEvent.entity"
import { canRemoveBotFromCall } from "../../../../../core/domain/CalendarEvent.entity/RecordingStatus"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../../contexts/language.context"

type EventMenuProps = {
	calendarEvent: CalendarEvent
	handleCalendarEventAction: (
		calendarEventId: string,
		eventAction: "send-bot-to-meeting-now" | "remove-bot-from-call",
	) => void
}

export function EventMenu({ calendarEvent, handleCalendarEventAction }: EventMenuProps) {
	const { t } = useLanguage()

	return (
		<div className="flex items-center space-x-4 ml-1">
			<div className="flex flex-shrink-0 self-center">
				{(calendarEvent.botStatus.canSendBotToMeetingNow ||
					canRemoveBotFromCall(calendarEvent.botStatus.code)) && (
					<Menu as="div" className="relative inline-block text-left">
						<div>
							<MenuButton className="relative flex items-center rounded-full text-gray-400 hover:text-gray-600">
								<span className="absolute" />
								<span className="sr-only">Open options</span>
								<EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
							</MenuButton>
						</div>
						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
								<div className="py-1">
									{calendarEvent.botStatus.canSendBotToMeetingNow && (
										<MenuItem>
											{({ active }) => (
												<TrackingButton
													type="button"
													className={clsx(
														active ? "bg-gray-100 text-gray-900" : "text-gray-700",
														"flex px-4 py-2 text-sm w-full",
													)}
													onClick={() =>
														handleCalendarEventAction(
															calendarEvent.id,
															"send-bot-to-meeting-now",
														)
													}
													eventName="Send bot to meeting now"
												>
													<PaperAirplaneIcon
														className="mr-3 h-5 w-5 text-gray-400"
														aria-hidden="true"
													/>
													<span>{t("Send recorder now")}</span>
												</TrackingButton>
											)}
										</MenuItem>
									)}
									{canRemoveBotFromCall(calendarEvent.botStatus.code) && (
										<MenuItem>
											{({ active }) => (
												<TrackingButton
													type="button"
													className={clsx(
														active ? "bg-gray-100 text-gray-900" : "text-gray-700",
														"flex px-4 py-2 text-sm w-full",
													)}
													onClick={() =>
														handleCalendarEventAction(
															calendarEvent.id,
															"remove-bot-from-call",
														)
													}
													eventName="Send bot to meeting now"
												>
													<ArrowRightOnRectangleIcon
														className="mr-3 h-5 w-5 text-gray-400"
														aria-hidden="true"
													/>
													<span>{t("Remove recorder from call")}</span>
												</TrackingButton>
											)}
										</MenuItem>
									)}
								</div>
							</MenuItems>
						</Transition>
					</Menu>
				)}
			</div>
		</div>
	)
}
