import clsx from "clsx"
import React from "react"
import { findDOMNode } from "react-dom"
import ReactPlayer from "react-player/file"
import screenfull from "screenfull"

import { Controls } from "../../../../components/design-system/VideoPlayer/_Controls.component"
import { useCallVideoPlayer } from "../../../../contexts/callVideoPlayer.context"

export type CallVideoPlayerProps = React.PropsWithChildren<{
	url: string
	thumbnailImageUrl?: string
}>

export function CallVideoPlayer({ children, url, thumbnailImageUrl }: CallVideoPlayerProps) {
	const {
		transcription,
		playerRef,
		handleTotalDurationSecondsChange,
		handleVolumeChange,
		handleForward,
		handlePlayPause,
		handleProgress,
		handlePause,
		handlePlay,
		handleRewind,
		handleMouseOver,
		handleMouseLeave,
		handleEnded,
		isMuted,
		volumePercentage,
		isPlaying,
		controls,
		currentTimeSeconds,
		totalDurationSeconds,
		showControls,
		playBackSpeed,
		handlePlayBackSpeedChange,
		handlePlayerReady,
		hasNeverPlayed,
	} = useCallVideoPlayer()

	const setRef = (player: ReactPlayer) => {
		playerRef.current = player
	}

	const handleFullScreenToggle = React.useCallback(() => {
		const player = playerRef.current
		if (!player) {
			return
		}

		const playerDomNode = findDOMNode(player)?.parentElement
		if (!playerDomNode || !(playerDomNode instanceof Element)) {
			return
		}

		if (screenfull.isFullscreen) {
			screenfull.exit()
		} else {
			screenfull.request(playerDomNode)
		}
	}, [playerRef])

	return (
		<>
			<div
				onMouseLeave={handleMouseLeave}
				onMouseMove={handleMouseOver}
				className={clsx("relative rounded-t-lg", !transcription && "rounded-b-lg")}
			>
				<ReactPlayer
					ref={setRef}
					className="react-player"
					width="100%"
					height="100%"
					url={url}
					playing={isPlaying}
					controls={controls}
					loop={false}
					volume={volumePercentage}
					muted={isMuted}
					onPlay={handlePlay}
					onPause={handlePause}
					onEnded={handleEnded}
					onProgress={handleProgress}
					onDuration={handleTotalDurationSecondsChange}
					playbackRate={playBackSpeed}
					onReady={handlePlayerReady}
				/>
				{hasNeverPlayed && thumbnailImageUrl && (
					<div className="absolute top-0 left-0 right-0 bottom-0 bg-navy-700 rounded-t-lg bg-opacity-50">
						<img alt="thumbnail for call" src={thumbnailImageUrl} className="w-full h-full" />
					</div>
				)}

				<Controls
					isPlaying={isPlaying}
					onBackwardClick={handleRewind}
					onForwardClick={handleForward}
					onPlayPauseClick={handlePlayPause}
					onVolumeChange={handleVolumeChange}
					volumePercentage={volumePercentage}
					currentTimeSeconds={currentTimeSeconds}
					totalDurationSeconds={totalDurationSeconds}
					showControls={showControls}
					onPlayBackSpeedChange={handlePlayBackSpeedChange}
					playBackSpeed={playBackSpeed}
					onFullScreenToggle={handleFullScreenToggle}
				/>
			</div>
			{children}
		</>
	)
}
