import React from "react"

import type { BillingInfo } from "../../../../../core/application/gateways/billing.gateway"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useLanguage } from "../../../../contexts/language.context"
import { formatPrice } from "../../../../utils/currency"
import { DateFormatter, DateProvider } from "../../../../utils/time"
import { PlanCard } from "./PlanCard.component"
import { SubscriptionManagementButton } from "./SubscriptionManagementButton.component"

type NextBillingCardProps = {
	billing: BillingInfo
}

export function NextBillingCard({ billing }: NextBillingCardProps) {
	const { t, language } = useLanguage()
	const { user } = useAuthenticatedSession()
	const isWorkspaceManager = user.isWorkspaceManager()
	const dateFormatter = new DateFormatter(new DateProvider(), t)
	const isTrial = billing.subscription?.isTrial

	if (!billing.invoice?.upcoming || (!billing.subscription?.hasPaymentMethod && isTrial) || !isWorkspaceManager) {
		return null
	}

	const hasPaymentError = billing.subscription?.status === "past_due"

	return (
		<PlanCard
			title={t("Next billing")}
			subtitle={formatPrice((billing.invoice.upcoming.total / 100).toFixed(2), language)}
			state={hasPaymentError ? "error" : undefined}
			subtitleDetails={
				<div className="flex flex-col">
					{billing.invoice.upcoming.nextPaymentAttempt ? (
						<span className="text-opacity-50 mt-1">
							{t("Billed on {{date}}", {
								date: dateFormatter.getFormattedDate(billing.invoice.upcoming.nextPaymentAttempt).day,
							})}
						</span>
					) : undefined}
				</div>
			}
		>
			{billing.subscription?.cardExpiryMonth &&
				billing.subscription?.cardExpiryYear &&
				billing.subscription.last4Digits && (
					<div className="font-bold mt-1 flex flex-col">
						<span>{t("Card details")}</span>
						<span>**** **** **** {billing.subscription.last4Digits}</span>
						<span>
							{t("Expires {{date}}", {
								date: `${billing.subscription.cardExpiryMonth}/${billing.subscription.cardExpiryYear}`,
							})}
						</span>
					</div>
				)}

			{hasPaymentError && (
				<div className="flex flex-col">
					<span className="mt-0.5 mb-1 font-medium">
						{t(
							"There was a problem with your payment. Update your payment method to keep your plan active.",
						)}
					</span>
				</div>
			)}

			{billing.actionUrls?.customerPortalUrl && (
				<SubscriptionManagementButton
					url={billing.actionUrls.customerPortalUrl}
					analyticsEventName="Billing settings: manage payment clicked"
					CTA={t("Manage payment and invoices")}
				/>
			)}
		</PlanCard>
	)
}
