import { useLanguage } from "../../contexts/language.context"
import { makeWorkspaceSettingsPath } from "../../pages/Settings/config"
import { Badge } from "../design-system/Badge.component"
import { Tooltip } from "../design-system/Tooltip.component"
import { TrackingLink } from "../design-system/TrackingLink.component"

export type PlanBadgeProps = {
	source:
		| "settings: crm sidebar"
		| "settings: crm settings"
		| "settings: calendar sidebar"
		| "settings: calendar settings"
		| "library: upgrade plan overlay"
		| "deals: upgrade plan overlay"
		| "call: create highlight"
		| "sales motion: upgrade plan overlay"

	minimumPlanName: string
	makeTooltipMessage?: (minimumPlanName: string) => string
}

export function PlanBadge({ source, minimumPlanName, makeTooltipMessage }: PlanBadgeProps) {
	const { t } = useLanguage()
	const badge = (
		<Badge
			label={t("{{planName}} plan", {
				planName: minimumPlanName,
			})}
			variant="info"
			className="whitespace-nowrap"
		/>
	)
	const shouldBeALink = !!makeTooltipMessage

	if (!shouldBeALink) {
		return badge
	}

	return (
		<TrackingLink
			to={makeWorkspaceSettingsPath("billing")}
			eventName="Plan badge clicked"
			eventProperties={{
				source,
				minimumPlanName,
			}}
		>
			<Tooltip content={makeTooltipMessage?.(minimumPlanName)}>{badge}</Tooltip>
		</TrackingLink>
	)
}
