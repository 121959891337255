export type DurationProps = {
	className?: string
	seconds: number
	style?: React.CSSProperties
} & React.HTMLAttributes<HTMLElement>

export function Duration({ className, seconds, style, ...props }: DurationProps) {
	return (
		<time {...props} dateTime={`P${Math.round(seconds)}S`} className={className} style={style}>
			{formatDuration(seconds)}
		</time>
	)
}

export function formatDuration(seconds: number) {
	const date = new Date(seconds * 1000)
	const hh = date.getUTCHours()
	const mm = date.getUTCMinutes()
	const ss = pad(date.getUTCSeconds())
	if (hh) {
		return `${hh}:${pad(mm)}:${ss}`
	}
	return `${mm}:${ss}`
}

function pad(string: number) {
	return ("0" + string).slice(-2)
}
