import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import { Fragment } from "react"
import React from "react"

export type ModalProps = {
	open: boolean
	onClose?: () => void
	children: React.ReactNode
	showCloseButton?: boolean
	className?: string
}

export function Modal({ open, onClose, children, className, showCloseButton = true }: ModalProps) {
	const handleOnClose = React.useCallback(() => {
		onClose?.()
	}, [onClose])

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-30" onClose={handleOnClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-2 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel
								className={clsx(
									"relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6",
									className,
								)}
							>
								{showCloseButton && (
									<div className="absolute right-0 top-0 pr-4 pt-4">
										<button
											type="button"
											className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
											onClick={onClose}
										>
											<XMarkIcon className="h-6 w-6" aria-hidden="true" />
										</button>
									</div>
								)}

								<>{children}</>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
