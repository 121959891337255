import type {
	IWorkspacesGateway,
	UpdateWorkspacePreferencesPayload,
} from "../../application/gateways/workspaces.gateway"
import type { SupportedWorkspaceLanguageCode } from "../../domain/Workspace.entity"

export class HttpWorkspacesGateway implements IWorkspacesGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async acceptTerms() {
		const endpointUrl = `${this.baseApiUrl}/workspaces/accept-terms`
		const res = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
		})
		if (!res.ok) {
			throw new Error("Failed to accept terms")
		}
	}

	public async updatePreferences(preferences: UpdateWorkspacePreferencesPayload): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/workspaces/manager/update-preferences`
		const res = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(preferences),
		})
		if (!res.ok) {
			throw new Error("Failed to update workspace preferences")
		}
	}

	public makeBotBackgroundImagePreviewURL(
		companyLogoId: string | undefined,
		lang: SupportedWorkspaceLanguageCode,
	): string {
		const imageIdParam = companyLogoId ? `companyLogoId=${companyLogoId}&` : ""
		return `${this.baseApiUrl}/workspaces/manager/preview-bot-background-image?${imageIdParam}lang=${lang}`
	}
}
