import { z } from "zod"

import { ISODateTimeSchema } from "../../../../infra/gateways/ISODateTime"

const GetDealsReportPayloadSchema = z.object({
	dealGroups: z.array(
		z.object({
			category: z.enum(["all", "won", "lost"]),
			identifier: z.string(),
			ownerId: z.string().nullish(),
			periodStart: ISODateTimeSchema,
			periodEnd: ISODateTimeSchema.nullish(),
		}),
	),
})

export type GetDealsReportPayload = z.infer<typeof GetDealsReportPayloadSchema>

export const GetDealsReportSuccessResponseSchema = z.object({
	dealGroups: z.array(
		z.object({
			identifier: z.string(),
			totalDeals: z.number(),
			totalWonDeals: z.number(),
			averageDealAmount: z.number(),
			dealStages: z.array(
				z.object({
					order: z.number(),
					stage: z.string(),
					totalDealsReachedStage: z.number(),
					averageDurationDaysPerDeal: z.number(),
					isLostStage: z.boolean(),
					isWonStage: z.boolean(),
				}),
			),
		}),
	),
})

export const GetDealsReportResponseSchema = z.union([
	z.object({
		pending: z.literal(true),
	}),
	GetDealsReportSuccessResponseSchema,
])

export type GetDealsReportSuccessResponse = z.infer<typeof GetDealsReportSuccessResponseSchema>
