import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useMount } from "react-use"

import { SpinnerIcon } from "../../components/design-system/SpinnerIcon.component"
import { Layout } from "../../components/shared/Layout/Layout.component"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useDependencies } from "../../contexts/dependencies.context"
import { useLanguage } from "../../contexts/language.context"
import { sleep } from "../../utils/time"
import { makeWorkspaceBillingPath } from "../Settings/config"

export function ApplyCouponPage() {
	const { t } = useLanguage()
	const { user, refetchMe } = useAuthenticatedSession()
	const { couponId = "should_never_happen" } = useParams<{ couponId: string }>()
	const { couponsGateway } = useDependencies()
	const navigate = useNavigate()

	useMount(() => {
		const redeemCoupon = async () => {
			try {
				await couponsGateway.redeemCoupon(couponId)
			} catch (e) {
				console.error("Failed to redeem coupon", e)
			} finally {
				await sleep(2000) // Wait for the coupon to be redeemed and the subscription to be updated
				await refetchMe()
				navigate(makeWorkspaceBillingPath())
			}
		}

		if (!user.isWorkspaceOwner()) {
			navigate(makeWorkspaceBillingPath())
			return
		}

		redeemCoupon().catch(console.error)
	})

	return (
		<Layout pageName={t("Apply coupon")} isFluid className="!p-0 flex">
			<div className="flex flex-col items-center justify-center w-full">
				<div className="flex flex-row items-center gap-2">
					<h1 className="text-xl font-semibold text-gray-800">{t("Applying coupon...")}</h1>
					<SpinnerIcon className="w-4 h-4 text-gray-800 mt-2" />
				</div>
			</div>
		</Layout>
	)
}
