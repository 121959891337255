import { useState } from "react"

import type { Call } from "../../../../../core/domain/Call.entity"
import type { Workspace } from "../../../../../core/domain/Workspace.entity"
import { Alert } from "../../../../components/design-system/Alert.component"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { TranscriptionQuote } from "../../../../components/shared/TranscriptionQuote.component"
import { useLanguage } from "../../../../contexts/language.context"
import { makeWorkspaceBillingPath } from "../../../Settings/config"
import { CallTooShortDisclaimer } from "./_CallTooShortDisclaimer.component"
import SelectModel from "./CoachingPanel/SelectModel"

export type CoachingPanelProps = {
	workspace?: Workspace
	call: Call
}

export function _CoachingPanel({ workspace, call }: CoachingPanelProps) {
	const { t } = useLanguage()
	const isCoachingEnabled = workspace?.isPlanFeatureEnabled("calendar-integration")

	const [selected, setSelected] = useState(0)

	const coachingAnalyses = call.props.coachingAnalysis
	if (!coachingAnalyses) {
		if (!isCoachingEnabled) {
			return (
				<>
					<Alert
						variant="info"
						description={t(
							"Upgrade to a Scaler plan now for tailored feedback on every call, to help you improve your technique and maximise your chances of closing.",
						)}
					/>
					<TrackingLink
						to={makeWorkspaceBillingPath()}
						eventName="call coach tab: upgrade plan"
						className="mt-4 flex justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-navy-500 rounded-full bg-gradient-to-r hover:from-navy-500 hover:to-pink transition duration-500 ease-in-out"
					>
						{t("Upgrade my plan")}
					</TrackingLink>
				</>
			)
		}
		return <SpinnerIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
	}

	const { generalComments, strengths, areasForImprovement } = coachingAnalyses[selected]
	return (
		<>
			{coachingAnalyses.length > 1 && workspace?.props.featureFlags.displayFeatureAlternatives && (
				<>
					<Alert
						variant="info"
						description={
							<SelectModel
								alternatives={coachingAnalyses}
								selected={selected}
								setSelected={setSelected}
							/>
						}
					/>
					<br />
				</>
			)}
			{call.isShortCall() && (
				<div className="mb-4">
					<CallTooShortDisclaimer />
				</div>
			)}
			{generalComments && (
				<div>
					<h3 className="text-lg leading-6 font-medium text-gray-900">{t("General comment")}</h3>
					<div className="mt-2">
						<p className="text-sm text-gray-500">{generalComments}</p>
					</div>
				</div>
			)}
			{strengths && (
				<div className="mt-10">
					<h3 className="text-lg leading-6 font-medium text-gray-900">{t("Strengths")}</h3>

					{strengths.map(({ aspect, suggestion, quote }) => (
						<div key={aspect} className="mt-2">
							<h4 className="whitespace-nowrap py-2 pl-4 pr-3 font-semibold text-gray-900 sm:pl-0">
								{aspect}
							</h4>
							<div className="text-sm text-gray-600">
								{suggestion}
								<br />
								<TranscriptionQuote
									key={quote}
									rawQuote={quote}
									call={call}
									trackingComponent="suggestions"
								/>
							</div>
						</div>
					))}
				</div>
			)}
			{areasForImprovement && (
				<div className="mt-10">
					<h3 className="text-lg leading-6 font-medium text-gray-900">{t("Areas for improvement")}</h3>

					{areasForImprovement.map(({ aspect, suggestion, quote }) => (
						<div key={aspect} className="mt-2">
							<h4 className="whitespace-nowrap py-2 pl-4 pr-3 font-semibold text-gray-900 sm:pl-0">
								{aspect}
							</h4>
							<div className="text-sm text-gray-600">
								{suggestion}
								<br />
								<TranscriptionQuote
									key={quote}
									rawQuote={quote}
									call={call}
									trackingComponent="suggestions"
								/>
							</div>
						</div>
					))}
				</div>
			)}
		</>
	)
}
