import { InformationCircleIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import React from "react"

import { Tooltip } from "../../../components/design-system/Tooltip.component"
import { TrackingButton } from "../../../components/design-system/TrackingButton.component"
import { SectionCard } from "../../../components/shared/GraphSection/SectionCard.component"

type IInteractionCardProps = {
	title: string
	values: Array<{
		value: string
		label: string
	}>
	onClick: () => void
	isSelected: boolean
	tooltip?: string
}

export function InteractionCard({ values, title, isSelected, onClick, tooltip }: IInteractionCardProps) {
	return (
		<TrackingButton eventName={`Call insight: ${title} clicked`} onClick={onClick}>
			<SectionCard className={clsx("group", isSelected && "shadow-md")}>
				<h3
					className={clsx(
						"md:text-lg text-sm font-semibold text-gray-500 flex items-center",
						isSelected && "text-indigo-500",
					)}
				>
					{title}
					{tooltip && (
						<Tooltip content={tooltip}>
							<InformationCircleIcon className="h-4 w-4 ml-1 text-gray-600" />
						</Tooltip>
					)}
				</h3>

				<div className="mt-2 flex gap-4">
					{values.map((value) => (
						<div key={value.label} className="flex flex-row gap-1.5 items-end">
							<span className="md:text-lg text-md font-bold text-gray-800">{value.value}</span>
							<span className="text-sm md:text-md font-medium text-gray-500 mb-0.5">{value.label}</span>
						</div>
					))}
				</div>
			</SectionCard>
		</TrackingButton>
	)
}
