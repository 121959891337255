import React from "react"

import type { TrainingSectionContentType } from "../../../../../../core/application/gateways/training.gateway/schemas/training-program-edit-view-model.schema"
import { TrackingButton } from "../../../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../../../contexts/language.context"
import { ContentTypeIcon } from "../../components/ContentTypeIcon.component"

type AddContentSectionProps = {
	onContentSelect: (contentType: TrainingSectionContentType) => void
}

export function AddContentSection({ onContentSelect }: AddContentSectionProps) {
	const { t } = useLanguage()
	return (
		<div className="grid grid-cols-2 gap-4">
			<TrackingButton
				type="button"
				onClick={() => onContentSelect("highlight")}
				className="inline-flex items-center gap-x-1.5 rounded-md bg-navy-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-navy-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-700"
				eventName="Section edit form: Add library highlights"
			>
				<ContentTypeIcon contentType="highlight" className="-ml-0.5 h-5 w-5" />
				{t("Choose library highlights")}
			</TrackingButton>

			<TrackingButton
				type="button"
				onClick={() => onContentSelect("text")}
				className="inline-flex items-center gap-x-1.5 rounded-md bg-navy-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-navy-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-700"
				eventName="Section edit form: Add text"
			>
				<ContentTypeIcon contentType="text" className="-ml-0.5 h-5 w-5" />
				{t("Write text content")}
			</TrackingButton>

			<TrackingButton
				type="button"
				onClick={() => onContentSelect("video")}
				className="inline-flex items-center gap-x-1.5 rounded-md bg-navy-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-navy-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-700"
				eventName="Section edit form: Add video"
			>
				<ContentTypeIcon contentType="video" className={"-ml-0.5 h-5 w-5"} />
				{t("Upload a video")}
			</TrackingButton>

			<TrackingButton
				type="button"
				onClick={() => onContentSelect("pdf")}
				className="inline-flex items-center gap-x-1.5 rounded-md bg-navy-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-navy-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-700"
				eventName="Section edit form: Add pdf"
			>
				<ContentTypeIcon contentType="pdf" className="-ml-0.5 h-5 w-5" />
				{t("Upload a PDF")}
			</TrackingButton>
		</div>
	)
}
