import { Listbox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import * as React from "react"
import { Fragment } from "react"

import type { UserProperties } from "../../../../core/domain/User.entity"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { type TranslationKey, useLanguage } from "../../../contexts/language.context"

export const preferenceTranslationKeyByRole: Record<UserProperties["recordingPreference"], TranslationKey> = {
	external_only: "External events (default)",
	internal_only: "Internal events",
	all: "Both External and Internal events",
}

const preferenceOptions: Array<{
	value: UserProperties["recordingPreference"]
	title: TranslationKey
	description: TranslationKey | TranslationKey[]
}> = [
	{
		value: "external_only",
		title: "External events (default)",
		description: "Events with at least one attendee outside your organization.",
	},
	{
		value: "internal_only",
		title: "Internal events",
		description: "Events with only attendees from your organization.",
	},
	{
		value: "all",
		title: "Both External and Internal events",
		description: "All events will be recorded.",
	},
]

type IRecordingSettingsDropdownProps = {
	recordingPreferences: UserProperties["recordingPreference"]
	onChange: (preference: UserProperties["recordingPreference"]) => void
}

export function RecordingSettingsDropdown({ recordingPreferences, onChange }: IRecordingSettingsDropdownProps) {
	const { t } = useLanguage()
	const handleChange = React.useCallback(
		(newPreference: string) => {
			onChange?.(newPreference as UserProperties["recordingPreference"])
		},
		[onChange],
	)

	return (
		<Listbox value={recordingPreferences} onChange={handleChange}>
			{({ open }) => (
				<>
					<div className="relative">
						<div className="inline-flex divide-x rounded-md shadow-sm border border-gray-300">
							<div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-2 text-gray-900 shadow-sm">
								<p className="text-xs font-semibold">
									{t(preferenceTranslationKeyByRole[recordingPreferences])}
								</p>
							</div>
							<Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-white p-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-gray-50">
								<ChevronDownIcon className="h-5 w-5 text-gray-900" aria-hidden="true" />
							</Listbox.Button>
						</div>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options className="group absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
								{preferenceOptions.map((option) => (
									<Listbox.Option
										key={option.title}
										className={({ active }) =>
											clsx(
												active ? "bg-indigo-600 text-white" : "text-gray-900",
												"cursor-default select-none p-2 text-sm",
											)
										}
										value={option.value}
									>
										{({ selected, active }) => (
											<div className="flex flex-col">
												<div className="flex justify-between">
													<p
														className={clsx(
															selected
																? "font-semibold"
																: "font-normal [&>*]:text-gray-300",
															"flex gap-2",
														)}
													>
														{t(option.title)}
													</p>
													{selected && (
														<span className={active ? "text-white" : "text-indigo-600"}>
															<CheckIcon className="h-5 w-5" aria-hidden="true" />
														</span>
													)}
												</div>
												{typeof option.description === "string" ? (
													<p
														className={clsx(
															active ? "text-indigo-200" : "text-gray-500",
															"mt-2 text-xs",
														)}
													>
														{t(option.description)}
													</p>
												) : (
													<div className="mt-2 text-xs">
														{option.description.map((description) => (
															<p
																key={description}
																className={clsx(
																	active ? "text-indigo-200" : "text-gray-500",
																)}
															>
																{t(description)}
															</p>
														))}
													</div>
												)}
											</div>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	)
}

export function UserRecordingSettings() {
	const { t } = useLanguage()
	const { user, refetchMe } = useAuthenticatedSession()
	const { usersGateway } = useDependencies()

	const onPreferenceChange = React.useCallback(
		async (newPreference: UserProperties["recordingPreference"]) => {
			try {
				await usersGateway.updatePreferences({
					recordingPreference: newPreference,
				})
				await refetchMe()
			} catch (e) {
				console.error("Error updating user preferences:", e)
			}
		},
		[refetchMe, usersGateway],
	)

	return (
		<>
			<dl className="px-6 py-4 space-y-6 divide-y divide-gray-100 text-sm leading-6">
				<div className="pt-6 sm:flex">
					<dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
						{t("Meetings to record")}
					</dt>
					<dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
						<div className="flex flex-col gap-x-2">
							<RecordingSettingsDropdown
								recordingPreferences={user.recordingPreference}
								onChange={onPreferenceChange}
							/>
							<span className="mt-2 text-sm text-gray-500">
								{t("Note: All events with coach@rippletide.com will be recorded")}
							</span>
						</div>
					</dd>
				</div>
			</dl>
		</>
	)
}
