import React from "react"

import type { GetDealsOwnersResponse } from "../../../../core/application/gateways/deals.gateway/schemas/listDeals"
import { useDependencies } from "../../../contexts/dependencies.context"

export function useDealOwners(useFakeData: boolean) {
	const [owners, setOwners] = React.useState<GetDealsOwnersResponse | null>(null)
	const [isLoading, setIsLoading] = React.useState(!useFakeData)

	const { dealsGateway } = useDependencies()
	React.useEffect(() => {
		if (useFakeData) {
			setOwners({
				users: [],
			})
			return
		}

		setIsLoading(true)
		dealsGateway
			.getOwners()
			.then((response) => setOwners(response))
			.catch((e) => console.error(e))
			.finally(() => setIsLoading(false))
	}, [dealsGateway, useFakeData])

	return {
		isLoading,
		owners,
	}
}
