import type { IStorageGateway, UploadedFile } from "../../application/gateways/storage.gateway"

export class HttpStorageGateway implements IStorageGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async uploadFile(file: File): Promise<UploadedFile> {
		const endpointUrl = `${this.baseApiUrl}/storage/upload`

		const formData = new FormData()
		formData.append("file", file)

		const res = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			body: formData,
		})

		if (!res.ok) {
			throw new Error(`Failed to upload file. Status: ${res.status}`)
		}

		// Handle success or additional response processing if needed
		console.log("File uploaded successfully", res)

		// TODO: validate response
		return res.json() as Promise<UploadedFile>
	}
}
