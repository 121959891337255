import type { LeadKnowledgeV2 } from "../../../../../../../core/domain/Call.entity"
import { useLanguage } from "../../../../../../contexts/language.context"
import { KeyValueList } from "./KeyValueList.component"

export type IReactionsSectionProps = {
	reactions: NonNullable<LeadKnowledgeV2["reactions"]>
}

export function ReactionsSection({ reactions }: IReactionsSectionProps) {
	const { t } = useLanguage()
	return (
		<div>
			<h4 className="whitespace-nowrap mb-2 pl-4 pr-3 text-lg font-bold text-gray-900 sm:pl-0">
				{t("Reactions")}
			</h4>
			<div className="flex-auto">
				<KeyValueList entries={reactions} />
			</div>
		</div>
	)
}
