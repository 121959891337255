import { useMount } from "react-use"

import type { Call } from "../../../../../../core/domain/Call.entity"
import type { CallHighlight } from "../../../../../../core/domain/Library.entity"
import { useCallVideoPlayer } from "../../../../../contexts/callVideoPlayer.context"
import { Call_MainPanel } from "../../../../Call/components/_MainPanel/_MainPanel.component"

type PlayerComponentProps = {
	call: Call
	highlight: CallHighlight
}

export function VideoPlayer({ call, highlight }: PlayerComponentProps) {
	const { handlePlay } = useCallVideoPlayer()

	useMount(handlePlay)

	return <Call_MainPanel call={call} highlights={[highlight]} timeline={false} />
}
