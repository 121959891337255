import assertNever from "assert-never"
import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player/file"

import type { MyTrainingProgramItem } from "../../../../../../core/application/gateways/training.gateway/schemas/my-training-program-content.schema"
import type { Call } from "../../../../../../core/domain/Call.entity"
import { SpinnerIcon } from "../../../../../components/design-system/SpinnerIcon.component"
import { CallVideoPlayerProvider } from "../../../../../contexts/callVideoPlayer.context"
import { useDependencies } from "../../../../../contexts/dependencies.context"
import { CallHighlightCardContents } from "../../../../Library/components/CurrentLibraryFolder/CurrentLibraryFolderHighlightsGrid.component"
import { PdfViewer } from "./PdfViewer.component"
import { VideoPlayer } from "./VideoPlayer.component"

type TrainingSectionContentViewerProps = {
	item: MyTrainingProgramItem
}

export function TrainingSectionContentViewer({ item }: TrainingSectionContentViewerProps) {
	const { callsGateway } = useDependencies()
	const [currentCall, setCurrentCall] = useState<Call | null>(null)

	useEffect(() => {
		if (item.type !== "highlight") {
			return
		}

		setCurrentCall(null)
		callsGateway
			.get(item.highlight.callId)
			.then((call) => setCurrentCall(call))
			.catch((err) => console.error("Failed to get call", err))
	}, [callsGateway, item])

	if (item.type === "highlight") {
		const allowedTimeRanges = [
			{
				startSec: Math.round(item.highlight.startsAtMs / 1000),
				endSec: Math.round(item.highlight.endsAtMs / 1000),
			},
		]

		return (
			<div className="flex flex-col gap-4">
				{currentCall ? (
					<div>
						<CallVideoPlayerProvider call={currentCall} allowedTimeRanges={allowedTimeRanges}>
							<VideoPlayer call={currentCall} highlight={item.highlight} />
						</CallVideoPlayerProvider>

						<div className="mt-4 border hover:border-gray-200 p-2 mb-4 rounded-md group border-gray-100 shadow-sm bg-white flex items-center justify-center">
							<CallHighlightCardContents
								contents={{
									highlight: item.highlight,
									isSelected: false,
								}}
							/>
						</div>
					</div>
				) : (
					<SpinnerIcon className="w-8 h-8 animate-spin" />
				)}
			</div>
		)
	}

	if (item.type === "text") {
		return (
			<div
				className="border hover:border-gray-200 p-2 mb-4 rounded-md group border-gray-100 shadow-sm bg-white"
				dangerouslySetInnerHTML={{
					__html: item.text,
				}}
			/>
		)
	}

	if (item.type === "pdf") {
		return <PdfViewer pdfUrl={item.fileUrl} />
	}

	if (item.type === "video") {
		return <ReactPlayer controls={true} className="react-player" url={item.fileUrl} width="100%" height="100%" />
	}

	assertNever(item)
}
