import { CheckBadgeIcon } from "@heroicons/react/20/solid"
import React from "react"

import { Button } from "../../../components/design-system/Button.component"
import { useLanguage } from "../../../contexts/language.context"

type ReadyStepProps = {
	onReady: () => void
}

export function ReadyStep({ onReady }: ReadyStepProps) {
	const { t } = useLanguage()
	return (
		<div className="overflow-hidden bg-white rounded-lg shadow-md">
			<div className="px-4 py-5 sm:p-6">
				<CheckBadgeIcon className="h-10 w-10 mx-auto text-navy-700" />
				<p className="text-center text-2xl text-gray-900 font-semibold">{t("You’re all set!")}</p>

				<div className="my-4">
					<p className="text-gray-900">{t("To start recording your calls:")}</p>
					<ul className="list-disc list-inside">
						<li className="text-gray-900">
							{t(
								"With your calendar synced, Rippletide will automatically request to join your meetings.",
							)}
						</li>
						<li className="text-gray-900">
							{t("Simply admit Rippletide to the meeting and we’ll handle the rest!")}
						</li>
					</ul>
				</div>

				<p className="text-gray-900 my-4">
					{t("We can't wait to transform how you navigate the sales waves together.")}
				</p>

				<div className="flex items-center justify-center">
					<Button eventName={"Onboarding step: ready clicked"} onClick={onReady} variant="primary" size="lg">
						{t("Get started")}
					</Button>
				</div>
			</div>
		</div>
	)
}
