import { CheckCircleIcon, EllipsisHorizontalCircleIcon } from "@heroicons/react/24/solid"
import assertNever from "assert-never"

import { Button } from "../../../components/design-system/Button.component"
import { CalendarSyncButtons } from "../../../components/shared/CalendarSyncButtons.component"
import { useCalendarContext } from "../../../contexts/calendar.context"
import { useLanguage } from "../../../contexts/language.context"
import { getCalendarPlatformDisplayName } from "../../../utils/calendarName"
import { CalendarEmptyState } from "./components/CalendarEmptyState.component"

function UserCalendarSettingsInner() {
	const { t } = useLanguage()
	const { calendarAndEvents, deleteWorkCalendar } = useCalendarContext()

	if (!calendarAndEvents.data) {
		return null
	}

	const { calendar } = calendarAndEvents.data

	const removeCalendarButton = (
		<div className="mt-2">
			<Button
				type="button"
				onClick={deleteWorkCalendar}
				variant="primary"
				eventName="Remove calendar integration"
				eventProperties={{
					calendarPlatform: calendar.status !== "not-found" ? calendar.platform : undefined,
				}}
			>
				{t("Remove calendar integration")}
			</Button>
		</div>
	)

	switch (calendar.status) {
		case "not-found":
			return <CalendarEmptyState calendar={calendar} />

		case "disconnected":
			return (
				<>
					<p>
						{t("Your {{calendarPlatform}} integration is disconnected", {
							calendarPlatform: getCalendarPlatformDisplayName(calendar.platform),
						})}
					</p>
					<CalendarSyncButtons calendar={calendar} reconnect={calendar.platform} />
					{removeCalendarButton}
				</>
			)

		case "connecting":
		case "connected":
			return (
				<>
					<label className="flex items-center text-sm font-medium leading-6 text-gray-900">
						{t("Calendar integration") + ` (${getCalendarPlatformDisplayName(calendar.platform)})`}
						{calendar.status === "connected" && (
							<CheckCircleIcon className="ml-2 h-5 w-5 text-green-500" aria-hidden="true" />
						)}
						{calendar.status === "connecting" && (
							<EllipsisHorizontalCircleIcon className="ml-2 h-5 w-5 text-yellow-500" aria-hidden="true" />
						)}
					</label>
					{removeCalendarButton}
				</>
			)

		default:
			assertNever(calendar, true) as void
			return null
	}
}

export function UserCalendarSettings() {
	return (
		<div className="px-6 py-4">
			<UserCalendarSettingsInner />
		</div>
	)
}
