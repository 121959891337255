import { useLocalStorage } from "react-use"

export const DEMO_MODE_KEY = "isDemoModeEnabled"

export function useDemoModeEnabled() {
	const stateManager = useLocalStorage<boolean>(DEMO_MODE_KEY, false)
	return stateManager
}

export const WORKSPACE_ROLE_OVERRIDE_KEY = "isWorkspaceRoleOveriddenWithMember"

export function useWorkspaceRoleOverride() {
	const stateManager = useLocalStorage<boolean>(WORKSPACE_ROLE_OVERRIDE_KEY, false)
	return stateManager
}
