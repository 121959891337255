import clsx from "clsx"
import React from "react"

export function Card({ children, className, title }: React.PropsWithChildren<{ className?: string; title?: string }>) {
	return (
		<div className={clsx("bg-white rounded-md shadow-sm", className)}>
			{title && (
				<h2 className="text-2xl font-semibold text-gray-900 border-b border-gray-200 pb-2 mb-4 p-4">{title}</h2>
			)}
			<div>{children}</div>
		</div>
	)
}
