import React from "react"

import type { User } from "../../../../core/domain/User.entity"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useLanguage } from "../../../contexts/language.context"

export type TimePeriod = "last-7-days" | "last-30-days" | "last-90-days"

type InsightsFiltersProps = {
	workspaceUsers: User[] | null
	userId: string | null
	onSelectUser: (userId: string) => void
	timePeriod: TimePeriod
	onTimePeriodChange: (timePeriod: TimePeriod) => void
}

export function InsightsFilters({
	workspaceUsers,
	userId,
	onSelectUser,
	timePeriod,
	onTimePeriodChange,
}: InsightsFiltersProps) {
	const { user } = useAuthenticatedSession()
	const sortedUsers = user.isWorkspaceManager()
		? workspaceUsers
				?.sort((a, b) => (a.firstName || a.email).localeCompare(b.firstName || b.email))
				.filter((u) => !u.isDisabled())
		: [user]

	const { t } = useLanguage()

	return (
		<div className="flex flex-row gap-4 items-end mb-2">
			{sortedUsers && sortedUsers.length > 0 && (
				<div>
					<label htmlFor="user" className="block text-sm font-medium leading-6 text-gray-900">
						User
					</label>
					<select
						id="user"
						name="user"
						value={userId || ""}
						onChange={(e) => onSelectUser(e.target.value)}
						className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
					>
						<option value="">All users</option>
						{sortedUsers.map((user) => (
							<option key={user.id} value={user.id}>
								{user.getFullName().trim() ? user.getFullName() : user.email}
							</option>
						))}
					</select>
				</div>
			)}
			<div className="mt-4">
				<label htmlFor="time-period" className="block text-sm font-medium leading-6 text-gray-900">
					{t("Time period")}
				</label>
				<select
					id="time-period"
					name="time-period"
					value={timePeriod}
					onChange={(e) => onTimePeriodChange(e.target.value as TimePeriod)}
					className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
				>
					<option value="last-7-days">{t("Last 7 days")}</option>
					<option value="last-30-days">{t("Last 30 days")}</option>
					<option value="last-90-days">{t("Last 90 days")}</option>
				</select>
			</div>
		</div>
	)
}
