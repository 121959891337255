import React from "react"

import type { Relationship } from "../../../../../core/application/gateways/relationships.gateway"
import type { LeadKnowledgeV2Opportunity } from "../../../../../core/domain/LeadKnowledge.entity"
import { type TranslationKey, useLanguage } from "../../../../contexts/language.context"
import { PanelEntry } from "./PanelEntry.component"

type RelationshipInfoPanelProps = {
	relationship: Relationship
}

function displayLeadKnowledgeV2(leadKnowledge: LeadKnowledgeV2Opportunity) {
	const translationMap: { [key: string]: TranslationKey } = {
		"currentProvider.name": "Current provider name",
		"currentProvider.satisfactionLevel": "Satisfaction level",
		"currentProvider.satisfactionComment": "Satisfaction comment",
		"currentProvider.monetaryValue": "Current provider monetary value",
		"currentProvider.monetaryCurrency": "Current provider currency",
		"currentProvider.renewalDate": "Renewal date",
		competitiveConsiderationSummary: "Competitive consideration summary",
		budgetApprovalProcess: "Budget approval process",
		buyingContext: "Buying context",
		specificNeeds: "Specific needs",
		shortTermGoals: "Short-term goals",
		longTermGoals: "Long-term goals",
		decisionCriteria: "Decision criteria",
		"deal.monetaryValue": "Deal monetary value",
		"deal.monetaryCurrency": "Deal currency",
		"deal.estimatedClosingProbability": "Estimated deal closing probability",
		"deal.estimatedCloseDate": "Estimated close date",
		"deal.engagementScope": "Engagement scope",
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function getNestedEntries(obj: any, path: string[] = []): JSX.Element[] {
		return Object.keys(obj).reduce<JSX.Element[]>((entries, key) => {
			const value = obj[key]
			const currentPath = [...path, key].join(".")
			const label: TranslationKey | undefined = translationMap[currentPath]

			if (!label || !value) {
				return entries
			}

			if (Array.isArray(value)) {
				entries.push(<PanelEntry key={currentPath} title={label} value={value} />)
				return entries
			}

			if (typeof value === "object") {
				entries.push(...getNestedEntries(value, [...path, key]))
				return entries
			}

			entries.push(<PanelEntry key={currentPath} title={label} value={String(value)} />)
			return entries
		}, [])
	}

	return getNestedEntries(leadKnowledge)
}

export function RelationshipOpportunityPanel({ relationship }: RelationshipInfoPanelProps) {
	const { t } = useLanguage()
	const opportunity = relationship?.opportunity?.leadKnowledge
	const entries = opportunity ? displayLeadKnowledgeV2(opportunity) : []

	return (
		<div>
			<div className="mt-4">
				<h2 className="text-xl mb-2 font-semibold text-gray-900">{t("Opportunity")}</h2>
				<div className="flex gap-4 flex-col">{entries}</div>
			</div>
		</div>
	)
}
