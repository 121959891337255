import { z } from "zod"

export const MyTrainingProgramSummarySchema = z.object({
	trainingProgram: z.object({
		id: z.string(),
		name: z.string(),
	}),
	deadline: z.coerce.date().optional(),
	items: z.object({
		seen: z.number(),
		total: z.number(),
	}),
	wasOpened: z.boolean(),
	enrolledAt: z.coerce.date(),
})
export type MyTrainingProgramSummary = z.infer<typeof MyTrainingProgramSummarySchema>

export const ListMyTrainingProgramsResponseSchema = z.array(MyTrainingProgramSummarySchema)
