import { TrackingLink } from "../../../components/design-system/TrackingLink.component"
import { useLanguage } from "../../../contexts/language.context"
import { makeWorkspaceSettingsPath } from "../../Settings/config"

export function SalesMotionConnectYourCrmOverlay() {
	const { t } = useLanguage()
	return (
		<>
			<div className="absolute inset-0 bg-navy-700 opacity-30 z-10 pointer-events-none" />
			<div className="absolute inset-0 z-20 flex items-center justify-center overflow-y-auto">
				<div className="relative bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
					<div className="flex items-center justify-start gap-x-4 mb-4">
						<h3 className="text-lg font-semibold leading-6 text-gray-900">
							{t("Connect your CRM to activate your sales performance")}
						</h3>
					</div>
					<p className="text-gray-600 mb-2">
						{t(
							"Track your win rate over time and visualise the dynamic of your pipeline to get a clear indication of how your deals are progressing and where there are bottlenecks in your pipeline, so you can intervene and optimise your strategy.",
						)}
					</p>
					<TrackingLink
						to={makeWorkspaceSettingsPath("crm")}
						eventName="deals: go to CRM settings"
						className="mt-4 flex justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-navy-500 rounded-full bg-gradient-to-r hover:from-navy-500 hover:to-pink transition duration-500 ease-in-out"
					>
						{t("Go to CRM settings")}
					</TrackingLink>
				</div>
			</div>
		</>
	)
}
