import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import { Fragment } from "react"
import { useNavigate } from "react-router-dom"

import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useLanguage } from "../../../contexts/language.context"
import { useDemoModeEnabled } from "../../../hooks/useLocalStorage"
import { makeSettingsPath } from "../../../pages/Settings/config"
import { makeAdminUsersListPath } from "../../../router/Router"
import { TrackingButton } from "../../design-system/TrackingButton.component"
import { UserAvatar } from "../User/UserAvatar.component"

export function _ProfileDropdown() {
	const { t } = useLanguage()
	const navigate = useNavigate()
	const { logout: _handleLogout, user, isPlatformAdmin } = useAuthenticatedSession()

	const [isDemoModeEnabled] = useDemoModeEnabled()

	const handleLogout = () => {
		_handleLogout()
	}

	const redirectToUserSettings = () => {
		navigate(makeSettingsPath())
	}

	const handleNavigateToBackOffice = () => {
		navigate(makeAdminUsersListPath())
	}

	const userNavigation = [
		{
			name: t("Settings"),
			onClick: redirectToUserSettings,
			analyticsEventName: "Navbar profile dropdown: navigation",
			analyticsEventProperties: { destination: "Settings" },
		},
		...(isPlatformAdmin && !isDemoModeEnabled // hide platform admin features when in demo mode
			? [
					{
						name: t("Back office"),
						onClick: handleNavigateToBackOffice,
						analyticsEventName: "Navbar profile dropdown: navigation",
						analyticsEventProperties: { destination: "Back office" },
						className: "text-coral font-semibold",
					},
			  ]
			: []),
		{
			name: t("Log out"),
			onClick: handleLogout,
			analyticsEventName: "Navbar profile dropdown: navigation",
			analyticsEventProperties: { destination: "Log out" },
		},
	]

	return (
		<Menu as="div" className="relative ml-3">
			<div>
				<MenuButton className="-m-1.5 flex items-center p-1.5">
					<span className="sr-only">{t("Open user menu")}</span>
					<UserAvatar user={user} className="h-8 w-8" />
					<span className="hidden lg:flex lg:items-center">
						<span className="ml-4 text-sm font-semibold leading-6 text-gray-300" aria-hidden="true">
							{user.firstName ?? user.email}
						</span>
						<ChevronDownIcon className="ml-2 h-5 w-5 text-gray-300" aria-hidden="true" />
					</span>
				</MenuButton>
			</div>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-200"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<MenuItems className="absolute right-0 z-20 mt-2 min-w-[192px] max-w-[224px] whitespace-nowrap origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					{userNavigation.map((item) => (
						<MenuItem key={item.name}>
							{({ active }) => (
								<TrackingButton
									onClick={item.onClick}
									className={clsx(
										active ? "bg-gray-100" : "",
										"block px-3 py-1 text-sm leading-6 text-gray-900 w-full text-left",
										item.className,
									)}
									eventName={item.analyticsEventName}
									eventProperties={item.analyticsEventProperties}
								>
									{item.name}
								</TrackingButton>
							)}
						</MenuItem>
					))}
				</MenuItems>
			</Transition>
		</Menu>
	)
}
