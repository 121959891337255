import { Dialog } from "@headlessui/react"
import { ShareIcon } from "@heroicons/react/20/solid"
import React from "react"

import type { Call } from "../../../../core/domain/Call.entity"
import { Modal } from "../../../components/design-system/Modal.component"
import { TrackingButton } from "../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../contexts/language.context"
import { ShareCallModalContent } from "./ShareCallModalContent"

type IShareCallButtonProps = {
	call: Call
}

export function ShareCallButton({ call }: IShareCallButtonProps) {
	const { t } = useLanguage()
	const [isShareModalOpen, setIsShareModalOpen] = React.useState(false)

	const handleCloseModal = React.useCallback(() => {
		setIsShareModalOpen(false)
	}, [])

	const handleShareClick = React.useCallback(() => {
		setIsShareModalOpen(true)
	}, [])

	const publicAccessToken = call.props.publicAccessToken

	if (!publicAccessToken) {
		return null
	}

	return (
		<>
			<Modal open={isShareModalOpen} onClose={handleCloseModal}>
				<div className="items-start flex">
					<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
						<Dialog.Title className="text-base font-semibold leading-6 text-gray-900" as="h3">
							{t("Share call")}
						</Dialog.Title>
						<ShareCallModalContent callId={call.props.id} publicAccessToken={publicAccessToken} />
					</div>
				</div>
			</Modal>
			<TrackingButton
				eventName="Share call clicked"
				eventProperties={{
					callId: call.props.id,
				}}
				onClick={handleShareClick}
				type="button"
				className="flex items-center rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
			>
				<ShareIcon className="h-4 w-4 mr-1" />
				{t("Share")}
			</TrackingButton>
		</>
	)
}
