import { CheckBadgeIcon } from "@heroicons/react/20/solid"
import React, { useEffect } from "react"

import type { CrmIntegration } from "../../../../core/domain/Workspace.entity"
import { Alert } from "../../../components/design-system/Alert.component"
import { Button } from "../../../components/design-system/Button.component"
import { Input } from "../../../components/design-system/Input"
import { SpinnerIcon } from "../../../components/design-system/SpinnerIcon.component"
import { Tooltip } from "../../../components/design-system/Tooltip.component"
import { HubspotSetupFormComponent } from "../../../components/shared/Crm/HubspotSetupForm.component"
import { SalesforceSetupFormComponent } from "../../../components/shared/Crm/SalesforceSetupForm.component"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { useLanguage } from "../../../contexts/language.context"
import { CrmProviderPicker } from "../../Settings/Workspace/CrmSettings/CrmProviderPicker.component"
import { useCrmSettings } from "../../Settings/Workspace/CrmSettings/useCrmSettings"
import { StepCard } from "../components/StepCard.component"

type CalendarSyncStepProps = {
	onComplete: () => void
}

export function CrmSyncStep({ onComplete }: CalendarSyncStepProps) {
	const { t } = useLanguage()
	const {
		crmIntegration,
		onSelectedProviderChange,
		providers,
		salesforceCredentialTypes,
		onCredentialsChange,
		onInstanceUrlChange,
		formStatus,
		isFormSubmittable,
		onSubmit,
	} = useCrmSettings()
	const { workspace, refetchMe } = useAuthenticatedSession()
	const isCrmIntegrationEnabled = workspace.isPlanFeatureEnabled("crm-integration")
	const [isPristine, setIsPristine] = React.useState(true)
	const [isUsingDifferentCrm, setIsUsingDifferentCrm] = React.useState(false)
	const [customCrm, setCustomCrm] = React.useState("")
	const [crmValue, setCrmValue] = React.useState("")
	const { workspacesGateway } = useDependencies()

	useEffect(() => {
		refetchMe()
	}, [refetchMe])

	const providersToDisplay = providers.filter((provider) => provider.value)

	const handleProviderChange = (provider: CrmIntegration["provider"]) => {
		onSelectedProviderChange(provider)
		setIsPristine(false)
	}

	const handleDifferentCrmClick = () => {
		setIsUsingDifferentCrm(true)
	}

	const handleCrmChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setCrmValue(event.target.value)
	}

	const handleCustomCrmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCustomCrm(event.target.value)
	}

	const handleContinue = async () => {
		if (isUsingDifferentCrm) {
			await workspacesGateway.updatePreferences({
				companyDetails: {
					currentCrm: crmValue === "other" ? customCrm : crmValue,
				},
			})
		}

		onComplete()
	}

	const canContinue = isUsingDifferentCrm
		? crmValue !== "" && (crmValue === "other" ? customCrm !== "" : true)
		: formStatus.status === "success"

	return (
		<StepCard
			title={t("Connect your CRM")}
			onContinue={handleContinue}
			canContinue={canContinue}
			onSkip={onComplete}
			stepName="crm-integration"
		>
			<p className="flex flex-col gap-2">
				<span className="text-gray-900">
					<CheckBadgeIcon className="h-5 w-5 text-green-500 inline-block mr-2" />
					{t("Enrich and update your records instantly.")}
				</span>
				<span className="text-gray-900">
					<CheckBadgeIcon className="h-5 w-5 text-green-500 inline-block mr-2" />

					{t("Manage and identify risks in your ongoing deals.")}
				</span>
				<span className="text-gray-900">
					<CheckBadgeIcon className="h-5 w-5 text-green-500 inline-block mr-2" />

					{t("Monitor and analyse your pipeline & sales motion.")}
				</span>
			</p>

			<div className="my-6">
				{!isUsingDifferentCrm && (
					<div className="flex items-center justify-center">
						<div className="flex flex-col gap-2 justify-center items-center">
							<CrmProviderPicker
								providers={providersToDisplay}
								crmIntegration={isPristine ? null : crmIntegration}
								onSelectedProviderChange={handleProviderChange}
								disabled={!isCrmIntegrationEnabled}
							/>
							<Button
								variant="text"
								size="xs"
								className="!text-gray-400"
								eventName="Onboarding crm: Using a different CRM click"
								onClick={handleDifferentCrmClick}
							>
								{t("I’m using a different CRM")}
							</Button>
						</div>
					</div>
				)}

				{isUsingDifferentCrm && (
					<>
						<Input.Group>
							<Input.Label htmlFor="crm">{t("Which CRM are you using?")}</Input.Label>
							<Input.Select id="crm" name="crm" value={crmValue} onChange={handleCrmChange}>
								<Input.SelectOption value="">{t("Select your CRM")}</Input.SelectOption>
								<Input.SelectOption value="pipedrive">Pipedrive</Input.SelectOption>
								<Input.SelectOption value="sellsy">Sellsy</Input.SelectOption>
								<Input.SelectOption value="sugarcrm">SugarCRM</Input.SelectOption>
								<Input.SelectOption value="microsoft-dynamics">Microsoft Dynamics</Input.SelectOption>
								<Input.SelectOption value="odoo">Odoo</Input.SelectOption>
								<Input.SelectOption value="zoho">Zoho</Input.SelectOption>
								<Input.SelectOption value="other">{t("Other")}</Input.SelectOption>
							</Input.Select>
						</Input.Group>
						{crmValue === "other" && (
							<Input.Group>
								<Input.Label htmlFor="other-crm">{t("Enter the name of your CRM")}</Input.Label>
								<Input.Input
									id="other-crm"
									name="other-crm"
									type="text"
									value={customCrm}
									onChange={handleCustomCrmChange}
									required
								/>
							</Input.Group>
						)}
					</>
				)}

				{!isPristine && !isUsingDifferentCrm && (
					<form onSubmit={onSubmit}>
						{crmIntegration.provider === "salesforce" && (
							<SalesforceSetupFormComponent
								crmIntegration={crmIntegration}
								onInstanceUrlChange={onInstanceUrlChange}
								onCredentialsChange={onCredentialsChange}
								isCrmIntegrationEnabled={isCrmIntegrationEnabled}
								salesforceCredentialTypes={salesforceCredentialTypes}
							/>
						)}
						{crmIntegration.provider === "hubspot" && (
							<HubspotSetupFormComponent
								crmIntegration={crmIntegration}
								onCredentialsChange={onCredentialsChange}
								isCrmIntegrationEnabled={isCrmIntegrationEnabled}
							/>
						)}

						<div className="mt-8 flex justify-end">
							<Tooltip
								content={
									!isCrmIntegrationEnabled
										? t("Upgrade to a Flex plan now to connect your CRM.")
										: undefined
								}
							>
								<Button
									eventName="CRM setup form submitted"
									type="submit"
									variant="primary"
									disabled={!isFormSubmittable || !isCrmIntegrationEnabled}
								>
									<span className="flex gap-2 items-center">
										{formStatus.status === "submitting" && (
											<SpinnerIcon className="h-5 w-5 mx-auto" />
										)}
										{formStatus.status === "submitting"
											? t("Testing CRM connection…")
											: t("Test and save my CRM integration")}
									</span>
								</Button>
							</Tooltip>
						</div>
						{formStatus.status === "error" && (
							<div className="mt-4">
								<Alert
									title={t("An error occurred")}
									description={formStatus.error.message}
									variant="error"
									className="mt-4"
								/>
							</div>
						)}
						{formStatus.status === "success" && (
							<div className="mt-4">
								<Alert
									title={t("Your CRM integration is set!")}
									description={t(
										"The connection has been successfully established and is working properly.",
									)}
									variant="success"
									className="mt-4"
								/>
							</div>
						)}
					</form>
				)}
			</div>
		</StepCard>
	)
}
