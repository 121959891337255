import { z } from "zod"

const OpportunityOwnerSchema = z.object({
	crmId: z.string(),
	firstName: z.string().optional(),
	lastName: z.string().optional(),
})
export type OpportunityOwner = z.infer<typeof OpportunityOwnerSchema>

const PersonOpportunitiesAggregateSchema = z.object({
	amountsInCents: z.object({
		closedAndWon: z.number(),
		willClose: z.number(),

		commit: z.number(),
		mostLikely: z.number(),
		bestCase: z.number(),
	}),
})
export type PersonOpportunitiesAggregate = z.infer<typeof PersonOpportunitiesAggregateSchema>

export const GetDealsForecastsAggregateRouteResponseSchema = z.object({
	data: z.array(
		z.object({
			opportunityOwner: OpportunityOwnerSchema,
			aggregate: PersonOpportunitiesAggregateSchema,
		}),
	),
	error: z
		.discriminatedUnion("type", [
			z.object({
				type: z.literal("requires-forecasting-feature"),
			}),
		])
		.optional(),
})
export type GetDealsForecastsAggregateRouteResponse = z.infer<typeof GetDealsForecastsAggregateRouteResponseSchema>
export type GetDealsForecastsAggregateRouteResponseError = NonNullable<
	z.infer<typeof GetDealsForecastsAggregateRouteResponseSchema>["error"]
>

export const GetDealsForecastsAggregateRouteParamsSchema = z.object({
	timePeriod: z.enum(["this-quarter", "this-month"]),
})
export type GetDealsForecastsAggregateRouteParams = z.infer<typeof GetDealsForecastsAggregateRouteParamsSchema>

export type ForecastTimePeriod = GetDealsForecastsAggregateRouteParams["timePeriod"]
