import React from "react"

import { Modal } from "../../../../components/design-system/Modal.component"
import { ViewTrainingProgram } from "../ViewTrainingProgram/ViewTrainingProgram.page"

type PreviewTrainingProgramModalProps = {
	open: boolean
	handleClose: () => void
	programId: string
}

export function PreviewTrainingProgramModal({ handleClose, open, programId }: PreviewTrainingProgramModalProps) {
	return (
		<Modal
			open={open}
			onClose={handleClose}
			showCloseButton={false}
			className="!max-w-screen-2xl h-[calc(100vh-4rem)] m-12 !overflow-y-auto !pt-0"
		>
			<div className="h-full flex flex-col mt-6">
				<ViewTrainingProgram programId={programId} isPreview={true} />
			</div>
		</Modal>
	)
}
