import { Dialog } from "@headlessui/react"
import React from "react"
import { Trans } from "react-i18next"
import { useMatch } from "react-router-dom"

import type { SignupParams } from "../../../core/application/gateways/authentication.gateway"
import { useDependencies } from "../../contexts/dependencies.context"
import { useLanguage } from "../../contexts/language.context"
import { makeLoginPath } from "../../router/Router"
import { DateProvider } from "../../utils/time"
import { Modal } from "../design-system/Modal.component"
import { TrackingButton } from "../design-system/TrackingButton.component"
import { TrackingLink } from "../design-system/TrackingLink.component"
import { ClaimAccountOrSignupForm } from "./ClaimAccountOrSignupForm/ClaimAccountOrSignupForm.component"

type IClaimAccountModalProps = {
	open: boolean
	onClose: () => void
	inviteToken: string
	onClaimSuccess: () => void
}

type ClaimState =
	| {
			status: "claiming" | "claimed" | "idle"
	  }
	| {
			status: "error"
			error: Error
	  }

export function ClaimAccountModal({ open, onClose, inviteToken, onClaimSuccess }: IClaimAccountModalProps) {
	const { t } = useLanguage()
	const { authenticationGateway } = useDependencies()
	const [claimState, setClaimState] = React.useState<ClaimState>({ status: "idle" })
	const isCallPage = Boolean(useMatch("/calls/:callId"))
	const isPublicCallPage = Boolean(useMatch("/share/calls/:callId"))

	const handleSubmit = React.useCallback(
		async ({ firstName, lastName, plainPassword, coupon }: SignupParams) => {
			const dateProvider = new DateProvider()

			try {
				setClaimState({
					status: "claiming",
				})
				await authenticationGateway.claimUserAccount({
					inviteToken,
					firstName,
					lastName,
					plainPassword,
					timezone: dateProvider.getCurrentTimezone(),
					coupon,
				})

				setClaimState({
					status: "claimed",
				})
			} catch (e) {
				if (e instanceof Error) {
					setClaimState({
						status: "error",
						error: e,
					})
				}
				console.error("Error:", e)
			}
		},
		[authenticationGateway, inviteToken],
	)

	const handleClose = React.useCallback(() => {
		if (claimState.status === "claimed") {
			onClaimSuccess()
		} else {
			onClose()
		}
	}, [claimState.status, onClaimSuccess, onClose])

	let modalContent
	if (claimState.status === "claimed") {
		modalContent = (
			<div className="mx-auto max-w-2xl text-center">
				<h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
					{t("Welcome to Rippletide!")}
				</h2>
				<p className="mx-auto mt-6 max-w-xl text-md leading-6 text-gray-600">
					{t("Your account has been successfully created.")}
				</p>
				{isPublicCallPage || isCallPage ? (
					<>
						<p className="mx-auto max-w-xl text-md leading-6 text-gray-600">
							{t(
								"Access your full call analysis now and start surfing the wave of sales success with Rippletide!",
							)}
						</p>
						<div className="mt-8 flex items-center justify-center gap-x-6">
							<TrackingButton
								onClick={handleClose}
								eventName="Claim account modal: view my call clicked"
								className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								{t("View my call")}
							</TrackingButton>
						</div>
					</>
				) : (
					<>
						<p className="mx-auto max-w-xl text-md leading-6 text-gray-600">
							{t("Log in now and start surfing the wave of sales success with Rippletide!")}
						</p>
						<div className="mt-8 flex items-center justify-center gap-x-6">
							<TrackingLink
								to={makeLoginPath()}
								eventName="Claim account modal: login now clicked"
								className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								{t("Log in now")}
							</TrackingLink>
						</div>
					</>
				)}
			</div>
		)
	} else if (claimState.status === "error") {
		modalContent = (
			<div className="mx-auto max-w-2xl text-center">
				<h2 className="text-2xl font-bold tracking-tight text-gray-900">{t("Something went wrong")}</h2>

				<p className="mt-2 mx-auto max-w-xl text-md leading-6 text-gray-600">
					<Trans
						i18nKey="An error occurred while trying to setup your account. Please try again later or contact support at <1>{{email}}</1>"
						components={{
							1: (
								<a className=" text-sky font-semibold" href="mailto:support@rippletide.com">
									support@rippletide.com
								</a>
							),
						}}
						values={{ email: "support@rippletide.com" }}
					/>
				</p>
			</div>
		)
	} else {
		modalContent = (
			<>
				<Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
					{t("Claim your free Rippletide account now!")}
				</Dialog.Title>
				<Dialog.Description as="p" className="text-sm text-gray-500 mt-2">
					{t("Create an account to unlock the full value of Rippletide.")}
				</Dialog.Description>
				<Dialog.Description as="p" className="text-sm text-gray-500">
					{t(
						"You’ll have access to personalised materials for each call to help you optimise your technique, preparation and follow-up, as well as full integration with your CRM.",
					)}
				</Dialog.Description>
				<ClaimAccountOrSignupForm
					handleSubmit={handleSubmit}
					isBusy={claimState.status === "claiming"}
					formType="claim"
				/>
			</>
		)
	}

	return (
		<Modal open={open} onClose={handleClose}>
			{modalContent}
		</Modal>
	)
}
