import { z } from "zod"

import { subscriptionStatusSchema } from "../application/gateways/billing.gateway"
import { ISODateTimeSchema } from "../infra/gateways/ISODateTime"

export const subscriptionPropertiesSchema = z.object({
	id: z.string(),
	startedAt: ISODateTimeSchema,
	endAt: ISODateTimeSchema,
	status: subscriptionStatusSchema,
})

export type SubscriptionProperties = z.infer<typeof subscriptionPropertiesSchema>

export class Subscription {
	private constructor(public properties: SubscriptionProperties) {}

	public static fromProperties(properties: SubscriptionProperties) {
		return new Subscription(properties)
	}

	public isTrial() {
		return this.properties.status === "trialing"
	}

	public isPastDue() {
		return this.properties.status === "past_due"
	}
}
