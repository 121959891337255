import {
	CategoryScale,
	type ChartData,
	type ChartOptions,
	Filler,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from "chart.js"
import { Chart as ChartJS } from "chart.js"
import annotationPlugin from "chartjs-plugin-annotation"
import React from "react"
import { Line } from "react-chartjs-2"

import type { CallInsightsResponse } from "../../../../core/application/gateways/insights.gateway"
import { SectionCard } from "../../../components/shared/GraphSection/SectionCard.component"
import { type TranslationKey, useLanguage } from "../../../contexts/language.context"
import { getChartAnnotationForType } from "../getChartAnnotationForType"
import type { InteractionChartType } from "../types"
import { roundToTwoDecimals, secondsToMinutes } from "../utils"

export type AverageCallsProgressionChartProps = {
	insights: CallInsightsResponse
	selectedUserId?: string
	type: InteractionChartType
}

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	annotationPlugin,
)

type DailyCallStats = CallInsightsResponse["callStats"][0]

type WeeklyCallStats = {
	weekStarting: string
} & Omit<DailyCallStats, "date">

function getWeekStartDate(dateStr: string): string {
	const date = new Date(dateStr)
	const day = date.getUTCDay()
	const diff = date.getUTCDate() - day + (day === 0 ? -6 : 1) // adjust when day is Sunday
	date.setUTCDate(diff)
	// Return ISO string date format
	return date.toISOString().slice(0, 10) // format as "YYYY-MM-DD"
}

function aggregateToWeeklyStats(callStats: DailyCallStats[], numberOfUsers: number): WeeklyCallStats[] {
	const weeklyStatsMap = new Map<string, WeeklyCallStats>()

	for (const daily of callStats) {
		const weekStart = getWeekStartDate(daily.date)
		if (!weeklyStatsMap.has(weekStart)) {
			weeklyStatsMap.set(weekStart, {
				weekStarting: weekStart,
				totalCalls: 0,
				averageCallsPerUser: 0,
				averageCallDurationSec: 0,
				averageCallRecordingRatePercent: 0,
				byUser: [],
				averageCallScore: 0,
				averageLongestMonologueSec: 0,
				averageSpeakingRatioPercent: 0,
			})
		}

		const weeklyStats = weeklyStatsMap.get(weekStart)
		if (!weeklyStats) {
			throw new Error("Weekly stats not found") // This can never happen
		}

		weeklyStats.totalCalls += daily.totalCalls
		weeklyStats.averageCallsPerUser += daily.averageCallsPerUser
		weeklyStats.averageCallDurationSec += daily.averageCallDurationSec
		weeklyStats.averageCallRecordingRatePercent += daily.averageCallRecordingRatePercent
		weeklyStats.averageCallScore += daily.averageCallScore
		weeklyStats.averageLongestMonologueSec += daily.averageLongestMonologueSec
		weeklyStats.averageSpeakingRatioPercent += daily.averageSpeakingRatioPercent

		// Aggregate User Stats
		daily.byUser.forEach((user) => {
			const existingUser = weeklyStats.byUser.find((u) => u.userId === user.userId)
			if (existingUser) {
				existingUser.totalCalls += user.totalCalls
				existingUser.averageCallDurationSec += user.averageCallDurationSec
				existingUser.callRecordingRatePercent += user.callRecordingRatePercent
				existingUser.averageCallScore += user.averageCallScore
				existingUser.averageLongestMonologueSec += user.averageLongestMonologueSec
				existingUser.averageSpeakingRatioPercent += user.averageSpeakingRatioPercent
			} else {
				weeklyStats.byUser.push({ ...user })
			}
		})
	}

	// Calculate averages for weekly stats
	return Array.from(weeklyStatsMap.values()).map((week) => {
		const totalCallsCountThisWeek =
			callStats
				.filter((d) => getWeekStartDate(d.date) === week.weekStarting)
				.reduce((acc, d) => acc + d.totalCalls, 0) || 1

		week.averageCallsPerUser = totalCallsCountThisWeek / numberOfUsers
		week.averageCallDurationSec /= totalCallsCountThisWeek
		week.averageCallRecordingRatePercent /= totalCallsCountThisWeek
		week.averageCallScore /= totalCallsCountThisWeek
		week.averageLongestMonologueSec /= totalCallsCountThisWeek
		week.averageSpeakingRatioPercent /= totalCallsCountThisWeek

		week.byUser.forEach((user) => {
			const totalCallsCountThisWeekForUser =
				callStats
					.filter((d) => getWeekStartDate(d.date) === week.weekStarting)
					.reduce((acc, d) => {
						const userStats = d.byUser.find((u) => u.userId === user.userId)
						return acc + (userStats ? userStats.totalCalls : 0)
					}, 0) || 1

			user.averageCallDurationSec /= totalCallsCountThisWeekForUser
			user.callRecordingRatePercent /= totalCallsCountThisWeekForUser
			user.averageCallScore /= totalCallsCountThisWeekForUser
			user.averageLongestMonologueSec /= totalCallsCountThisWeekForUser
			user.averageSpeakingRatioPercent /= totalCallsCountThisWeekForUser
		})

		return week
	})
}

export function CallProgressionChart({ insights, selectedUserId, type }: AverageCallsProgressionChartProps) {
	const { t } = useLanguage()

	const isAggregatingWeeklyData = insights.callStats.length >= 20
	const shouldShowWeeklyLabels = insights.callStats.length >= 10 && insights.callStats.length <= 20
	const callStats = isAggregatingWeeklyData
		? aggregateToWeeklyStats(insights.callStats, insights.workspaceUsers.length)
		: insights.callStats

	const data: ChartData<"line"> = React.useMemo(() => {
		const labels = callStats.map((stat) => {
			if ("weekStarting" in stat) {
				return isAggregatingWeeklyData
					? `${new Date(stat.weekStarting).toLocaleDateString().slice(0, 5)} - ${new Date(
							new Date(stat.weekStarting).setDate(new Date(stat.weekStarting).getDate() + 6),
					  )
							.toLocaleDateString()
							.slice(0, 5)}`
					: new Date(stat.weekStarting).toLocaleDateString()
			}

			return new Date(stat.date).toLocaleDateString()
		})

		const typeConfig: Record<
			InteractionChartType,
			{
				labelKey: TranslationKey
				dataSelector: (
					stat: Omit<CallInsightsResponse["callStats"][0], "date">,
					userCallStats?: CallInsightsResponse["callStats"][0]["byUser"][0],
				) => number
			}
		> = {
			"average-calls": {
				labelKey: "Average calls per user",
				dataSelector: (stat, userCallStats) =>
					userCallStats ? userCallStats.totalCalls : roundToTwoDecimals(stat.averageCallsPerUser),
			},
			"average-call-duration": {
				labelKey: "Average call duration per user (minutes)",
				dataSelector: (stat, userCallStats) =>
					roundToTwoDecimals(
						(userCallStats ? userCallStats.averageCallDurationSec : stat.averageCallDurationSec) / 60,
					),
			},
			"average-call-recording-rate": {
				labelKey: "Average call recording rate % per user",
				dataSelector: (stat, user) =>
					roundToTwoDecimals(user ? user.callRecordingRatePercent : stat.averageCallRecordingRatePercent),
			},
			"average-call-score": {
				labelKey: "Average call score per user",
				dataSelector: (stat, user) => roundToTwoDecimals(user ? user.averageCallScore : stat.averageCallScore),
			},
			"average-speaking-ratio": {
				labelKey: "Average speaking ratio % per user",
				dataSelector: (stat, user) =>
					roundToTwoDecimals(user ? user.averageSpeakingRatioPercent : stat.averageSpeakingRatioPercent),
			},
			"average-longest-monologue": {
				labelKey: "Average longest monologue per user",
				dataSelector: (stat, user) => {
					const valueSeconds = user ? user.averageLongestMonologueSec : stat.averageLongestMonologueSec
					const { remainingSeconds, minutes } = secondsToMinutes(valueSeconds)
					return roundToTwoDecimals(minutes + remainingSeconds / 60)
				},
			},
		}

		const config = typeConfig[type]

		// Helper function to create dataset
		const createDataset = (
			label: string,
			dataSelector: (
				stat: Omit<CallInsightsResponse["callStats"][0], "date">,
				userCallStats?: CallInsightsResponse["callStats"][0]["byUser"][0],
			) => number,
			color: string,
			borderColor: string,
			userId: string | undefined,
			shouldFill: boolean,
		) => ({
			label,
			data: callStats.map((stat) => {
				const user = userId ? stat.byUser.find((user) => user.userId === userId) : undefined
				return dataSelector(stat, user)
			}),
			fill: shouldFill,
			backgroundColor: `rgba(${color}, 0.5)`,
			borderColor,
			cubicInterpolationMode: "monotone" as const,
			pointRadius: 0,
		})

		const datasets = [
			createDataset(
				t(config.labelKey),
				config.dataSelector,
				"37, 99, 235",
				"#2563eb",
				undefined,
				Boolean(!selectedUserId),
			),
		]

		if (selectedUserId) {
			const targetUser = insights.workspaceUsers.find((x) => x.userId === selectedUserId)
			const userLabel = targetUser ? `${targetUser.userFirstName} ${targetUser.userLastName}` : "User"

			datasets.push(createDataset(userLabel, config.dataSelector, "5, 150, 105", "#059669", selectedUserId, true))
		}

		return { labels, datasets }
	}, [callStats, insights.workspaceUsers, isAggregatingWeeklyData, selectedUserId, t, type])

	const chartOptions: ChartOptions<"line"> = React.useMemo(() => {
		return {
			scales: {
				x: {
					grid: {
						display: false,
					},
					...(shouldShowWeeklyLabels
						? {
								ticks: {
									callback: function (val, index) {
										// Show label only for weekly data
										return index % 7 === 0 ? this.getLabelForValue(val as number) : ""
									},
								},
						  }
						: {}),
				},
				y: {
					beginAtZero: true,
					grid: {
						color: "lightgrey",
					},
				},
			},
			elements: {
				point: {
					radius: 3,
				},
			},
			interaction: {
				mode: "index",
				intersect: false,
			},
			plugins: {
				legend: {
					display: true,
					position: "top",
				},
				tooltip: {
					enabled: true,
					mode: "index",
					position: "nearest",
				},
				annotation: {
					annotations: {
						...getChartAnnotationForType(type, t, "horizontal"),
					},
				},
			},
			responsive: true,
			maintainAspectRatio: false,
		}
	}, [shouldShowWeeklyLabels, t, type])

	return (
		<SectionCard className="h-full">
			<div className="mb-4">
				<h3 className="text-lg font-semibold text-gray-900">{t("Progression")}</h3>

				{type === "average-calls" && (
					<>
						<span className="text-md font-medium text-gray-500">{t("Progression of calls recorded")}</span>
					</>
				)}

				{type === "average-call-recording-rate" && (
					<>
						<span className="text-md font-medium text-gray-500">
							{t("Progression of call recording rate %")}
						</span>
					</>
				)}

				{type === "average-call-duration" && (
					<>
						<span className="text-md font-medium text-gray-500">
							{t("Progression of call duration (minutes)")}
						</span>
					</>
				)}

				{type === "average-call-score" && (
					<>
						<span className="text-md font-medium text-gray-500">{t("Progression of call score")}</span>
					</>
				)}

				{type === "average-speaking-ratio" && (
					<>
						<span className="text-md font-medium text-gray-500">
							{t("Progression of speaking ratio %")}
						</span>
					</>
				)}

				{type === "average-longest-monologue" && (
					<>
						<span className="text-md font-medium text-gray-500">
							{t("Progression of longest monologue (minutes)")}
						</span>
					</>
				)}
			</div>
			<div className="h-[400px]">
				<Line data={data} options={chartOptions} />
			</div>
		</SectionCard>
	)
}
