import { Crisp } from "crisp-sdk-web"
import { useEffect } from "react"

import { isAuthenticationAuthenticatedState, useAuthentication } from "./contexts/authentication.context"

/**
 * Docs: https://docs.crisp.chat/guides/chatbox-sdks/web-sdk/npm/
 */
const CRISP_WEBSITE_ID = "a95bbdac-c421-4abc-89c3-e3b8eacf4bc0"

Crisp.configure(CRISP_WEBSITE_ID, {
	sessionMerge: true,
})

export function SupportWidget() {
	const { authenticationState } = useAuthentication()

	useEffect(() => {
		if (isAuthenticationAuthenticatedState(authenticationState)) {
			const { user, workspace } = authenticationState
			Crisp.user.setEmail(user.email)
			Crisp.user.setNickname(user.firstName)
			Crisp.setTokenId(user.id)
			Crisp.session.setData({
				user_id: user.id,
				workspace_id: workspace.id,
				first_name: user.firstName,
				last_name: user.lastName,
			})
		} else {
			Crisp.setTokenId()
			Crisp.session.reset()
		}
		$crisp.push(["config", "container:index", [3]]) // Make it appear behind ToastContainer
	}, [authenticationState])

	return null
}
