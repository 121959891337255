import React from "react"
import { useMount } from "react-use"

import type { PublicCalendarEvent } from "../../core/domain/PublicCalendarEvent.entity"
import { useDependencies } from "../contexts/dependencies.context"

export function usePublicCalendarEvent(
	calendarEventId: string,
	inviteToken: string | null,
): {
	publicCalendarEvent: PublicCalendarEvent | null
	isLoading: boolean
	error: Error | null
} {
	const { calendarEventsGateway } = useDependencies()
	const [publicCalendarEvent, setPublicCalendarEvent] = React.useState<PublicCalendarEvent | null>(null)
	const [isLoading, setIsLoading] = React.useState(false)
	const [error, setError] = React.useState<Error | null>(null)

	const getPublicCalendarEvent = React.useCallback(async () => {
		if (!inviteToken) {
			return
		}

		try {
			setIsLoading(true)
			setError(null)
			setPublicCalendarEvent(await calendarEventsGateway.getPublicCalendarEvent(calendarEventId, inviteToken))
		} catch (e) {
			setPublicCalendarEvent(null)
			if (e instanceof Error) {
				setError(e)
			} else {
				throw e
			}
		} finally {
			setIsLoading(false)
		}
	}, [inviteToken, calendarEventsGateway, calendarEventId])

	useMount(getPublicCalendarEvent)

	return { publicCalendarEvent, isLoading, error }
}
