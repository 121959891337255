import React from "react"
import { Trans } from "react-i18next"

import { TrackingExternalHref } from "../../../components/design-system/TrackingExternalHref.component"
import { SectionCard } from "../../../components/shared/GraphSection/SectionCard.component"
import { useLanguage } from "../../../contexts/language.context"
import type { InteractionChartType } from "../types"

type IMarketPracticeTipsProps = {
	type: InteractionChartType
}

type MarketTips = {
	title: string
	subtitle: React.ReactNode
	tips: string[]
}

export function MarketPracticeTips({ type }: IMarketPracticeTipsProps) {
	const { t } = useLanguage()
	const marketTipsByType: Partial<Record<InteractionChartType, MarketTips | null>> = {
		"average-call-duration": {
			title: t("30-45 minutes"),
			subtitle: (
				<>
					<Trans
						i18nKey="As a general rule, meetings should not be longer than 45 minutes to be sure to maintain your prospect’s attention, whilst the optimum demo length to increase your likelihood of closing a sale is around <1>9 minutes.</1>"
						components={{
							1: (
								<TrackingExternalHref
									href="https://telecrm.in/blog/b2b-sales-pitch-checklist/"
									target="_blank"
									className="inline-flex text-center md:text-right font-semibold text-indigo-600 hover:text-indigo-500 whitespace-nowrap ml-1"
									eventName="Insights: call duration tip link clicked"
								/>
							),
						}}
					/>
				</>
			),
			tips: [
				t(
					"Prepare a call agenda in advance to help you to avoid rambling and ensure you are concise and refined in what you say.",
				),
				t(
					"Schedule enough time for your meeting to allow yourself to overrun slightly, and factor this into your agenda so that it runs shorter than the allotted time slot.",
				),
			],
		},
		"average-speaking-ratio": {
			title: "43%",
			subtitle: (
				<Trans
					i18nKey="Research shows that a talk-listen ratio of 43:57 is associated with a <1>better win rate</1>, and no matter the type of call, you should always speak less than 65% of the time."
					components={{
						1: (
							<TrackingExternalHref
								href="https://blog.thecenterforsalesstrategy.com/the-golden-talk-listen-ratio-and-how-it-will-help-close-more-sales"
								target="_blank"
								className="inline-flex text-center md:text-right font-semibold text-indigo-600 hover:text-indigo-500 whitespace-nowrap ml-1"
								eventName="Insights: speaking ratio tip link clicked"
							/>
						),
					}}
				/>
			),
			tips: [
				t(
					"Let your prospect speak more — when they pause, try not to jump in immediately, but wait and see if they will them share more.",
				),
				t(
					"Ask questions often, in a way that demonstrates that you already understand the answer. Expressing empathy this way forges a strong connection and helps your prospect to share more valuable information.",
				),
			],
		},
		"average-longest-monologue": {
			title: t("2 minutes 30 seconds max"),
			subtitle: (
				<Trans
					i18nKey="Your prospect’s attention is at its peak when you start talking, and drops by half after 5 minutes, so try to keep your response length at an average of <1>40 seconds</1>, and a maximum of 2.5 minutes."
					components={{
						1: (
							<TrackingExternalHref
								href="https://www.feelingstream.com/engage-your-customers-and-increase-sales-by-reducing-agent-monologues-in-phone-calls/"
								target="_blank"
								className="inline-flex text-center md:text-right font-semibold text-indigo-600 hover:text-indigo-500 whitespace-nowrap ml-1"
								eventName="Insights: longest monologue tip link clicked"
							/>
						),
					}}
				/>
			),
			tips: [
				t(
					"Maintain a dialogue as much as possible by breaking arguments into smaller chunks, asking questions during presentations, and interacting with your prospect often.",
				),
				t(
					"Guide your prospect to ask you more questions or for more information, rather than overloading them with too much at once.",
				),
			],
		},
	}

	const marketTips = marketTipsByType[type]
	if (!marketTips) {
		return null
	}

	return (
		<SectionCard className="flex flex-col gap-2">
			<h3 className="text-xl font-semibold">
				{t("Market practice:")}
				<span className="text-indigo-500 ml-2">{marketTips.title}</span>
			</h3>
			<p className="text-gray-600 text-md">{marketTips.subtitle}</p>
			<div className="text-gray-600">
				<p className="text-md font-medium">{t("Tips")}:</p>
				<ul className="list-disc list-inside ml-4">
					{marketTips.tips.map((tip, index) => (
						<li key={index}>{tip}</li>
					))}
				</ul>
			</div>
		</SectionCard>
	)
}
