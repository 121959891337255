import { useLanguage } from "../../contexts/language.context"

type AudioPlayerProps = {
	audioFileUrl: string
	mimeType: string
}

export function AudioPlayer({ audioFileUrl, mimeType }: AudioPlayerProps) {
	const { t } = useLanguage()
	return (
		<audio controls>
			<source src={audioFileUrl} type={mimeType} />
			{t("Your browser does not support the audio element.")}
		</audio>
	)
}
