import { SideNavigationItem, type SideNavigationItemProps } from "./SideNavigationItem.component"

export type SideNavigationSectionProps = {
	title: string
	items: SideNavigationItemProps[]
}

export function SideNavigationSection({ title, items }: SideNavigationSectionProps) {
	return (
		<>
			<div className="text-sm font-semibold leading-6 text-gray-400 hidden lg:block mt-2">{title}</div>
			<ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
				{items.map((item, i) => (
					<SideNavigationItem key={i} {...item} />
				))}
			</ul>
		</>
	)
}
