export function replaceNewlineByBr(text: string) {
	return text.split("\n").map((item, i) => {
		return (
			<span key={i}>
				{item}
				<br />
			</span>
		)
	})
}
