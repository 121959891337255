import { CheckBadgeIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"

import type { UserProperties } from "../../../core/domain/User.entity"
import { useLanguage } from "../../contexts/language.context"
import { roleTranslationKeyByRole } from "../../pages/Settings/Workspace/UsersSettings/UserWorkspaceRoleDropdown.component"

const badgeColorByRole: Record<UserProperties["workspaceRole"], string> = {
	admin: "text-yellow-400",
	manager: "text-green-400",
	user: "text-blue-400",
}

export function UserWorkspaceRoleBadge({ workspaceRole }: { workspaceRole: UserProperties["workspaceRole"] }) {
	const { t } = useLanguage()

	return (
		<CheckBadgeIcon
			className={clsx("h-5 w-5", badgeColorByRole[workspaceRole])}
			aria-hidden="true"
			title={t(roleTranslationKeyByRole[workspaceRole])}
		/>
	)
}
