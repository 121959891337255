import React from "react"
import { Navigate } from "react-router-dom"

import { useAuthentication } from "../contexts/authentication.context"
import { CalendarProvider } from "../contexts/calendar.context"
import { makeDashboardPath, makeLoginPath, makeRecentCallsPath } from "./Router"

type AuthenticatedRouteProps = React.PropsWithChildren<{
	isPlatformAdminRoute?: boolean
	allowWithQueryParam?: string
}>

export function AuthenticatedRoute({ isPlatformAdminRoute, allowWithQueryParam, children }: AuthenticatedRouteProps) {
	const { authenticationState } = useAuthentication()
	const queryParams = new URL(window.location.href).searchParams
	const isAuthenticated = authenticationState !== "loading" && authenticationState !== "logged out"
	const canAccessRoute = isAuthenticated || (allowWithQueryParam && queryParams.has(allowWithQueryParam))

	if (!canAccessRoute) {
		return (
			<Navigate
				to={makeLoginPath({
					redirectTo: window.location.pathname,
				})}
			/>
		)
	}

	if (
		authenticationState !== "loading" &&
		authenticationState !== "logged out" &&
		isPlatformAdminRoute &&
		!authenticationState.isPlatformAdmin
	) {
		return (
			<Navigate
				to={authenticationState.user.isWorkspaceManager() ? makeRecentCallsPath() : makeDashboardPath()}
			/>
		)
	}

	return <CalendarProvider>{children}</CalendarProvider>
}
