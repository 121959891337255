import "chartjs-plugin-datalabels"
import "chartjs-plugin-annotation"

import {
	CategoryScale,
	Chart as ChartJS,
	type ChartOptions,
	Filler,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from "chart.js"
import React from "react"
import { Line } from "react-chartjs-2"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)
import type { GetHistoricalWinRateResponse } from "../../../../../../core/application/gateways/deals.gateway/schemas/listDeals"
import { useLanguage } from "../../../../../contexts/language.context"
import { DateFormatter, DateProvider } from "../../../../../utils/time"

type WinRateEvolutionGraphProps = {
	historicalWinRate: GetHistoricalWinRateResponse
	mode: "overall" | "month-over-month"
}

function computeWinRate(winRateHistoryEntry: GetHistoricalWinRateResponse["history"][number]) {
	if (!winRateHistoryEntry.totalDeals) {
		return 0
	}

	return (winRateHistoryEntry.wonDeals / winRateHistoryEntry.totalDeals) * 100
}

function computeMoMChange(
	winRateHistoryEntry: GetHistoricalWinRateResponse["history"][number],
	historicalWinRate: GetHistoricalWinRateResponse,
	index: number,
) {
	const previousEntry = historicalWinRate.history[index - 1]
	const currentWinRate = computeWinRate(winRateHistoryEntry)

	if (!previousEntry) {
		return {
			date: winRateHistoryEntry.date,
			previousWinRate: 0,
			currentWinRate,
			MoMChange: 0,
		}
	}

	const previousWinRate = computeWinRate(previousEntry)
	const MoMChange = previousWinRate ? ((currentWinRate - previousWinRate) / previousWinRate) * 100 : 0

	return {
		date: winRateHistoryEntry.date,
		previousWinRate,
		currentWinRate,
		MoMChange, // Format the change to two decimal places
	}
}

function computeData(historicalWinRate: GetHistoricalWinRateResponse, mode: "overall" | "month-over-month") {
	if (mode === "overall") {
		return historicalWinRate.history.map(({ wonDeals, totalDeals }) => {
			const winRate = (wonDeals / totalDeals) * 100
			return totalDeals ? Math.floor(winRate) : 0
		})
	}

	return historicalWinRate.history.map((entry, index) => {
		return computeMoMChange(entry, historicalWinRate, index).MoMChange
	})
}

export function WinRateEvolutionGraph({ historicalWinRate, mode }: WinRateEvolutionGraphProps) {
	const dateProvider = new DateProvider()
	const { t, language } = useLanguage()
	const dateFormatter = new DateFormatter(dateProvider, t)
	const allMonths = historicalWinRate.history.map(({ date }) =>
		dateFormatter.formatDate(date, language, { month: "short" }),
	)

	const data = {
		labels: allMonths,
		datasets: [
			{
				label: "% win rate",
				data: computeData(historicalWinRate, mode),
				borderColor: "rgba(255, 99, 132, 0.5)",
				backgroundColor: "rgba(255, 99, 132, 0.5)",
				pointBackgroundColor: "rgba(255, 99, 132, 0.8)",
				pointBorderColor: "#fff",
				pointHoverRadius: 8,
				pointRadius: 8,
				tension: 0.4, // Adds curve to the line
			},
		],
	}

	const options: ChartOptions<"line"> = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			annotation: {
				annotations:
					mode === "month-over-month"
						? {
								line1: {
									type: "line",
									yMin: 0,
									yMax: 0,
									borderColor: "rgba(0, 0, 0, 0.4)",
									borderWidth: 2,
									drawTime: "beforeDatasetsDraw",
								},
						  }
						: {},
			},
			legend: {
				display: false,
			},
			tooltip: {
				displayColors: false,
				callbacks: {
					label: (context) => {
						if (mode === "month-over-month") {
							const entry = historicalWinRate.history[context.dataIndex]
							const MoMChange = computeMoMChange(entry, historicalWinRate, context.dataIndex)
							return [
								`${MoMChange.MoMChange > 0 ? "+" : ""}${MoMChange.MoMChange.toFixed(0)}%`,
								t("Current: {{current}}", { current: `${MoMChange.currentWinRate.toFixed(0)}%` }),
								t("Previous: {{previous}}", { previous: `${MoMChange.previousWinRate.toFixed(0)}%` }),
							]
						}

						return `${context.raw}%`
					},
				},
				mode: "nearest",
				enabled: true,
				axis: "xy",
				intersect: false,
			},
			datalabels: {
				display: false,
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				title: {
					display: true,
					text: mode === "overall" ? t("Win rate (%)") : t("MoM change (%)"),
				},
			},
		},
	}

	return <Line data={data} options={options} />
}
