import clsx from "clsx"
import React from "react"

import type { Relationship } from "../../../../core/application/gateways/relationships.gateway"
import type { Call } from "../../../../core/domain/Call.entity"
import { useAnalytics } from "../../../contexts/analytics.context"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useLanguage } from "../../../contexts/language.context"
import { CallActivityTimeline } from "../../../pages/Deals/components/CallActivityTimeline.component"
import { formatCurrency } from "../../../pages/Deals/utils"
import { makeAccountUrlForRelationship, makeOpportunityUrlForRelationship } from "../../../utils/crm"
import { getRelationshipAccountDisplayName } from "../../../utils/relationship"
import { DateFormatter, DateProvider } from "../../../utils/time"
import { Badge } from "../../design-system/Badge.component"
import { Tooltip } from "../../design-system/Tooltip.component"
import { TrackingExternalHref } from "../../design-system/TrackingExternalHref.component"
import { RelationshipCallActivityPanel } from "./panels/RelationshipCallActivityPanel.component"
import { RelationshipInfoPanel } from "./panels/RelationshipInfoPanel.component"
import { RelationshipOpportunityPanel } from "./panels/RelationshipOpportunityPanel.component"
import { RelationshipRisksPanel } from "./panels/RelationshipRisksPanel.component"
import RelationshipPanelMenu, { type RelationshipMenuTab } from "./RelationshipPanelMenu.component"

type RelationshipPanelContentProps = {
	relationship: Relationship
	defaultTabToOpen?: "risks" | "call-activity" | null
	currentCall?: Call
	shouldOpenTimelineCallInNewTab?: boolean
	originPage: "call" | "deal"
	className?: string
}

export function RelationshipPanelContent({
	relationship,
	defaultTabToOpen,
	currentCall,
	shouldOpenTimelineCallInNewTab,
	originPage,
	className,
}: RelationshipPanelContentProps) {
	const { t } = useLanguage()
	const [currentTab, setCurrentTab] = React.useState<RelationshipMenuTab>(defaultTabToOpen ?? "info")
	const { workspace } = useAuthenticatedSession()
	const opportunityUrl = makeOpportunityUrlForRelationship(workspace, relationship)
	const accountUrl = makeAccountUrlForRelationship(workspace, relationship)
	const { setupEventTracking } = useAnalytics()

	const dateFormatter = new DateFormatter(new DateProvider(), t)

	const handleSetCurrentTab = React.useCallback(
		(tab: RelationshipMenuTab) => {
			setCurrentTab(tab)
			setupEventTracking({
				eventName: "Relationship tab clicked",
				eventProperties: {
					relationshipId: relationship.id,
					tab,
				},
			}).trackEvent()
		},
		[relationship.id, setupEventTracking],
	)

	const divider = (
		<div className="flex flex-col items-center justify-center">
			<span className="h-1 w-1 bg-gray-300 rounded-full" />
		</div>
	)

	const relationshipAccountName = getRelationshipAccountDisplayName(relationship, t)

	return (
		<div className={clsx("max-h-full", className)}>
			<>
				{relationship.opportunity ? (
					<div className="flex items-center justify-between">
						<div>
							{opportunityUrl ? (
								<TrackingExternalHref
									href={opportunityUrl}
									eventName="Relationship side panel: opportunity click"
									className={clsx(
										"!whitespace-normal",
										originPage === "call" ? "text-lg" : "text-xl",
									)}
								>
									{relationship.opportunity.name}
								</TrackingExternalHref>
							) : (
								<h2 className="text-xl text-navy-700">{relationship.opportunity.name}</h2>
							)}
						</div>
						<div className="flex items-center space-x-1">
							{relationship.opportunity?.stageName && (
								<Badge label={relationship.opportunity.stageName} variant="neutral" />
							)}
							{relationship.opportunity?.ownerFirstName && relationship.opportunity?.ownerLastName && (
								<Tooltip
									content={`${relationship.opportunity.ownerFirstName} ${relationship.opportunity.ownerLastName}`}
									tooltipClassName="!min-w-[140px] max-w-[140px] z-50"
								>
									<span className="w-6 h-6 rounded-full border border-navy-700 text-xs ml-2 p-0.5 flex items-center justify-center font-medium uppercase">
										{relationship.opportunity.ownerFirstName[0]}
										{relationship.opportunity.ownerLastName[0]}
									</span>
								</Tooltip>
							)}
						</div>
					</div>
				) : (
					<>
						{accountUrl ? (
							<TrackingExternalHref
								href={accountUrl}
								eventName="Relationship side panel: account click"
								className="text-xl text-navy-700 font-semibold"
							>
								{relationshipAccountName}
							</TrackingExternalHref>
						) : (
							<h2 className="text-xl text-navy-700 font-semibold">{relationshipAccountName}</h2>
						)}
					</>
				)}

				<div className="flex gap-2 mt-2">
					{relationship.opportunity && (
						<>
							{accountUrl ? (
								<TrackingExternalHref
									href={accountUrl}
									eventName="Relationship side panel: account click"
									className="text-md text-gray-900"
								>
									{relationshipAccountName}
								</TrackingExternalHref>
							) : (
								<h2 className="text-md font-semibold text-gray-900">{relationshipAccountName}</h2>
							)}
							{divider}
						</>
					)}
					{typeof relationship.opportunity?.amount === "number" && (
						<>
							<span className="text-md text-gray-900">
								{formatCurrency(relationship.opportunity.amount)}
							</span>
							{divider}
						</>
					)}
					{relationship.opportunity?.closeDate && (
						<>
							<span className="text-md text-gray-900">
								{relationship.opportunity.closeDate &&
									dateFormatter.getFormattedDate(relationship.opportunity.closeDate).day}
							</span>
							{divider}
						</>
					)}
					{typeof relationship.opportunity?.probability === "number" && (
						<span className="text-md text-gray-900">{relationship.opportunity.probability}%</span>
					)}
				</div>
			</>

			<div className="mt-4 flex items-center justify-center">
				<CallActivityTimeline
					activities={relationship.activities}
					heightPx={80}
					currentCall={currentCall}
					shouldOpenCallInNewTab={shouldOpenTimelineCallInNewTab}
					onSeePreviousCallsClick={() => handleSetCurrentTab("call-activity")}
				/>
			</div>
			<RelationshipPanelMenu
				setCurrentTab={handleSetCurrentTab}
				currentTab={currentTab}
				salesMotionBlockersCount={relationship.salesMotionBlockers?.length ?? 0}
				shouldDisplayOpportunityTab={Boolean(relationship.opportunity)}
			/>
			<div className={clsx("overflow-y-auto")}>
				{currentTab === "info" && <RelationshipInfoPanel relationship={relationship} />}
				{currentTab === "opportunity" && <RelationshipOpportunityPanel relationship={relationship} />}
				{currentTab === "call-activity" && (
					<RelationshipCallActivityPanel relationship={relationship} currentCall={currentCall} />
				)}
				{currentTab === "risks" && <RelationshipRisksPanel relationship={relationship} />}
			</div>
		</div>
	)
}
