import clsx from "clsx"
import React from "react"

import { Tooltip } from "../design-system/Tooltip.component"
import { TrackingLink } from "../design-system/TrackingLink.component"

type Step = {
	label: string
	completed: boolean
	disabled?: boolean
} & (
	| {
			type: "link"
			to: string
			eventName: string
			eventProperties?: Record<string, unknown>
	  }
	| {
			type: "button"
			onClick: () => void
	  }
)

type HorizontalStepperProps = {
	steps: Step[]
	className?: string
}

export function HorizontalStepper({ steps, className }: HorizontalStepperProps) {
	return (
		<div className={clsx("relative flex items-center", className)}>
			<div className="h-[1px] bg-navy-500 opacity-60 absolute top-1/2 left-0 right-0 transform -translate-y-1/2" />
			<div className="flex flex-1 justify-between z-20">
				{steps.map((step, index) => (
					<Tooltip content={step.disabled ? null : step.label} key={step.label}>
						<div className="flex items-center">
							{/* Conditional rendering based on step type */}
							{step.type === "link" && !step.disabled ? (
								<TrackingLink
									to={step.to}
									eventName={step.eventName}
									eventProperties={step.eventProperties}
									className={`w-5 h-5 rounded-full flex items-center justify-center ${
										step.completed
											? "bg-navy-700 text-white"
											: "border-2 border-navy text-navy-700 bg-white"
									}`}
								/>
							) : (
								<button
									onClick={step.type === "button" ? step.onClick : undefined}
									className={`w-5 h-5 rounded-full flex items-center justify-center disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-gray-500 ${
										step.completed
											? "bg-navy-700 text-white"
											: "border-2 border-navy text-navy-700 bg-white"
									}`}
									disabled={step.disabled}
								/>
							)}

							{/* Connector line between steps */}
							{index < steps.length - 1 && <div className="flex-1 h-1 bg-gray-500 mx-2"></div>}
						</div>
					</Tooltip>
				))}
			</div>
		</div>
	)
}
