import {
	type CrmIntegrationOpportunityChange,
	type ListAllDealsQueryParams,
	type ListAllDealsResponse,
	ListAllDealsResponseSchema,
} from "../../../core/application/gateways/deals.gateway/schemas/listDeals"
import type {
	GetRelationshipsResponse,
	RelationshipCallActivity,
} from "../../../core/application/gateways/relationships.gateway"

function addDaysToDate(date: Date, days: number): Date {
	const newDate = new Date(date)
	newDate.setDate(newDate.getDate() + days)
	return newDate
}

const unfilteredDemoData: Omit<ListAllDealsResponse, "stages" | "deals"> & {
	deals: Array<
		Omit<ListAllDealsResponse["deals"][0], "closeDate" | "createdAt" | "closedAt" | "history"> & {
			closeDate: string
			createdAt: string | undefined
			closedAt: string | undefined
			history: Array<Omit<CrmIntegrationOpportunityChange, "updatedAt"> & { updatedAt: string }>
		}
	>
} = {
	instanceUrl: "https://www.salesforce.com",
	deals: [
		{
			id: "1074e317-f777-448a-8e6c-2a716fc8adaf",
			accountId: null,
			name: "ContentSquare",
			description: "Optimisation de l'expérience utilisateur pour les sites e-commerce.",
			probability: 97,
			stageName: "Proposal",
			amount: 46741,
			closeDate: "2024-08-15T00:00:00.000Z",
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2023-11-01T00:00:00.000Z",
			ownerId: "34b949df-3d50-49a8-a371-ed68b1299293",
			ownerFirstName: "Antoine",
			ownerLastName: "Dupont",
			accountName: "ContentSquare",
			closedAt: undefined,
			history: [
				{
					updatedAt: "2024-05-20T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: "Value Proposition",
					newValue: "Proposal",
				},
			],
			risks: [
				{
					reason: "not-moved-from-stage",
				},
			],
			isWon: false,
			isLost: false,
		},
		{
			id: "f585bf90-e7b7-428f-8083-9e139c35f28b",
			accountId: null,
			name: "Dataiku",
			description: "Plateforme de science des données et de machine learning.",
			probability: 59,
			stageName: "Negotiation",
			amount: 74621,
			closeDate: "2024-08-21T00:00:00.000Z",
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2024-02-22T00:00:00.000Z",
			ownerId: "bad8ae15-a6c3-4402-9dc3-747742b61b2e",
			ownerFirstName: "Benoît",
			ownerLastName: "Martinez",
			accountName: "Dataiku",
			closedAt: undefined,
			history: [
				{
					updatedAt: "2024-06-25T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: "Perception Analysis",
					newValue: "Negotiation",
				},
			],
			risks: [
				{
					reason: "not-moved-from-stage",
				},
			],
			isWon: false,
			isLost: false,
		},
		{
			id: "c284bf26-cf01-4777-9a90-f9cb9fbbbef4",
			accountId: null,
			name: "Mirakl",
			description: "Plateforme de marketplace pour les entreprises.",
			probability: 82,
			stageName: "Proposal",
			amount: 98087,
			closeDate: "2024-10-04T00:00:00.000Z",
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2023-11-01T00:00:00.000Z",
			ownerId: "fe5dc403-5af9-41b2-b8bf-ad085ab373a6",
			ownerFirstName: "Julien",
			ownerLastName: "Lefevre",
			accountName: "Mirakl",
			closedAt: undefined,
			history: [
				{
					updatedAt: "2024-07-12T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: "Value Proposition",
					newValue: "Proposal",
				},
			],
			risks: [],
			isWon: false,
			isLost: false,
		},
		{
			id: "d019e6bd-4f9e-44ad-87c1-6d3c9a3b903f",
			accountId: null,
			name: "OVHcloud",
			description: "Hébergement cloud et services de serveurs.",
			probability: 5,
			stageName: "Closed: Won",
			amount: 31911,
			closeDate: addDaysToDate(new Date(), 45).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2023-11-01T00:00:00.000Z",
			ownerId: "fe5dc403-5af9-41b2-b8bf-ad085ab373a6",
			ownerFirstName: "Julien",
			ownerLastName: "Lefevre",
			accountName: "OVHcloud",
			closedAt: addDaysToDate(new Date(), -10).toISOString(),
			history: [
				{
					updatedAt: "2023-11-14T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Perception Analysis",
				},
				{
					updatedAt: "2023-11-28T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Prospecting",
				},
			],
			risks: [],
			isWon: true,
			isLost: false,
		},
		{
			id: "feb5d36f-12dd-430f-9e2a-0897e254b7e8",
			accountId: null,
			name: "PayFit",
			description: "Solution de gestion de la paie et des ressources humaines.",
			probability: 10,
			stageName: "Closed: Lost",
			amount: 72312,
			closeDate: addDaysToDate(new Date(), 22).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2023-11-01T00:00:00.000Z",
			ownerId: "34b949df-3d50-49a8-a371-ed68b1299293",
			ownerFirstName: "Antoine",
			ownerLastName: "Dupont",
			accountName: "PayFit",
			closedAt: addDaysToDate(new Date(), -35).toISOString(),
			history: [
				{
					updatedAt: "2023-11-30T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Needs Analysis",
				},
				{
					updatedAt: "2023-12-27T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Prospecting",
				},
				{
					updatedAt: "2023-12-11T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Proposal",
				},
				{
					updatedAt: "2023-12-23T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Value Proposition",
				},
			],
			risks: [
				{
					reason: "not-moved-from-stage",
				},
			],
			isWon: false,
			isLost: true,
		},
		{
			id: "dc3bf355-abc7-4fd2-8e06-593400da1cb9",
			accountId: null,
			name: "PeopleDoc",
			description: "Plateforme de gestion des ressources humaines.",
			probability: 67,
			stageName: "Value Proposition",
			amount: 65206,
			closeDate: addDaysToDate(new Date(), 32).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2023-11-01T00:00:00.000Z",
			ownerId: "34b949df-3d50-49a8-a371-ed68b1299293",
			ownerFirstName: "Antoine",
			ownerLastName: "Dupont",
			accountName: "PeopleDoc",
			closedAt: undefined,
			history: [
				{
					updatedAt: "2024-05-16T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Needs Analysis",
				},
			],
			risks: [],
			isWon: false,
			isLost: false,
		},
		{
			id: "1467de3b-f78c-47ae-ac54-db800aba1d2e",
			accountId: null,
			name: "Shift Technology",
			description: "Solutions de détection de fraude et de gestion des réclamations.",
			probability: 22,
			stageName: "Qualification",
			amount: 82798,
			closeDate: addDaysToDate(new Date(), -28).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2023-11-01T00:00:00.000Z",
			ownerId: "bad8ae15-a6c3-4402-9dc3-747742b61b2e",
			ownerFirstName: "Benoît",
			ownerLastName: "Martinez",
			accountName: "Shift Technology",
			closedAt: undefined,
			history: [
				{
					updatedAt: "2023-11-12T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Qualification",
				},
				{
					updatedAt: "2023-11-23T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Needs Analysis",
				},
			],
			risks: [
				{
					reason: "close-date-in-past",
				},
			],
			isWon: false,
			isLost: false,
		},
		{
			id: "a70c4958-58a9-4a09-9b5a-06cac05063f1",
			accountId: null,
			name: "Beekast",
			description: "Plateforme de création de présentations interactives.",
			probability: 71,
			stageName: "Value Proposition",
			amount: 71809,
			closeDate: addDaysToDate(new Date(), 65).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2023-11-01T00:00:00.000Z",
			ownerId: "34b949df-3d50-49a8-a371-ed68b1299293",
			ownerFirstName: "Antoine",
			ownerLastName: "Dupont",
			accountName: "Beekast",
			closedAt: undefined,
			history: [
				{
					updatedAt: "2024-05-26T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Negotiation",
				},
			],
			risks: [],
			isWon: false,
			isLost: false,
		},
		{
			id: "74f9893a-a81c-4e9b-9d18-f27725960657",
			accountId: null,
			name: "Botify",
			description: "Analyse de site web et optimisation SEO.",
			probability: 26,
			stageName: "Closed: Won",
			amount: 31607,
			closeDate: addDaysToDate(new Date(), 26).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2024-02-01T00:00:00.000Z",
			ownerId: "fe5dc403-5af9-41b2-b8bf-ad085ab373a6",
			ownerFirstName: "Julien",
			ownerLastName: "Lefevre",
			accountName: "Botify",
			closedAt: addDaysToDate(new Date(), -5).toISOString(),
			history: [],
			isWon: true,
			isLost: false,
		},
		{
			id: "84ee128f-1d65-49b8-b54d-2e20b7a6d118",
			accountId: null,
			name: "Mention",
			description: "Outil de surveillance des médias et de la réputation en ligne.",
			probability: 30,
			stageName: "Qualification",
			amount: 16065,
			closeDate: addDaysToDate(new Date(), 30).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2024-03-05T00:00:00.000Z",
			ownerId: "fe5dc403-5af9-41b2-b8bf-ad085ab373a6",
			ownerFirstName: "Julien",
			ownerLastName: "Lefevre",
			accountName: "Mention",
			closedAt: undefined,
			history: [],
			risks: [],
			isWon: false,
			isLost: false,
		},
		{
			id: "29961482-41f5-4b5b-957a-b0a157078e94",
			accountId: null,
			name: "Kolet",
			description: "Fournisseur de cartes SIM virtuelles pour les voyageurs.",
			probability: 29,
			stageName: "Qualification",
			amount: 40242,
			closeDate: addDaysToDate(new Date(), 20).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2024-06-01T00:00:00.000Z",
			ownerId: "fe5dc403-5af9-41b2-b8bf-ad085ab373a6",
			ownerFirstName: "Julien",
			ownerLastName: "Lefevre",
			accountName: "Kolet",
			closedAt: undefined,
			history: [],
			risks: [],
			isWon: false,
			isLost: false,
		},
		{
			id: "1fc9e712-8485-4080-b102-0dce6a563633",
			accountId: null,
			name: "Pennylane",
			description: "Plateforme de gestion financière et comptable.",
			probability: 91,
			stageName: "Proposal",
			amount: 25639,
			closeDate: addDaysToDate(new Date(), 45).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2024-03-12T00:00:00.000Z",
			ownerId: "34b949df-3d50-49a8-a371-ed68b1299293",
			ownerFirstName: "Antoine",
			ownerLastName: "Dupont",
			accountName: "Pennylane",
			closedAt: undefined,
			history: [
				{
					updatedAt: "2023-11-27T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Qualification",
				},
				{
					updatedAt: "2023-12-18T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Prospecting",
				},
				{
					updatedAt: "2024-01-16T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Value Proposition",
				},
				{
					updatedAt: "2024-02-15T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Decision Makers",
				},
				{
					updatedAt: "2024-03-03T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Proposal",
				},
			],
			risks: [
				{
					reason: "not-moved-from-stage",
				},
			],
			isWon: false,
			isLost: false,
		},
		{
			id: "211ec231-3cd3-4606-ab71-65f32129dc62",
			accountId: null,
			name: "Lago",
			description: "Plateforme de facturation open source.",
			probability: 64,
			stageName: "Negotiation",
			amount: 11702,
			closeDate: addDaysToDate(new Date(), 5).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2023-11-01T00:00:00.000Z",
			ownerId: "34b949df-3d50-49a8-a371-ed68b1299293",
			ownerFirstName: "Antoine",
			ownerLastName: "Dupont",
			accountName: "Lago",
			closedAt: undefined,
			history: [
				{
					updatedAt: "2024-06-15T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Negotiation",
				},
			],
			risks: [],
			isWon: false,
			isLost: false,
		},
		{
			id: "a1be324e-da88-413f-ad32-c66fbd5c2819",
			accountId: null,
			name: "Pivot",
			description: "Plateforme d'automatisation des achats.",
			probability: 18,
			stageName: "Closed: Won",
			amount: 49024,
			closeDate: addDaysToDate(new Date(), 15).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2023-11-01T00:00:00.000Z",
			ownerId: "fe5dc403-5af9-41b2-b8bf-ad085ab373a6",
			ownerFirstName: "Julien",
			ownerLastName: "Lefevre",
			accountName: "Pivot",
			closedAt: addDaysToDate(new Date(), -45).toISOString(),
			history: [],
			risks: [],
			isWon: true,
			isLost: false,
		},
		{
			id: "fd8b46af-65f6-4b25-be56-bfab71bfec7a",
			accountId: null,
			name: "Scalinx",
			description: "Fabricant de composants électroniques pour la conversion de données.",
			probability: 33,
			stageName: "Qualification",
			amount: 39696,
			closeDate: addDaysToDate(new Date(), 24).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2023-11-01T00:00:00.000Z",
			ownerId: "34b949df-3d50-49a8-a371-ed68b1299293",
			ownerFirstName: "Antoine",
			ownerLastName: "Dupont",
			accountName: "Scalinx",
			closedAt: undefined,
			history: [],
			risks: [
				{
					reason: "not-moved-from-stage",
				},
			],
			isWon: false,
			isLost: false,
		},
		{
			id: "71bc231d-ae2f-481d-a18d-fb6f407cf96e",
			accountId: null,
			name: "Algolia",
			description: "Moteur de recherche pour les développeurs.",
			probability: 40,
			stageName: "Closed: Won",
			amount: 70893,
			closeDate: addDaysToDate(new Date(), 19).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2023-11-01T00:00:00.000Z",
			ownerId: "34b949df-3d50-49a8-a371-ed68b1299293",
			ownerFirstName: "Antoine",
			ownerLastName: "Dupont",
			accountName: "Algolia",
			closedAt: addDaysToDate(new Date(), -3).toISOString(),
			history: [],
			risks: [],
			isWon: true,
			isLost: false,
		},
		{
			id: "96d4709e-2274-420c-a534-27eff97b4496",
			accountId: null,
			name: "Qonto",
			description: "Néobanque pour les entreprises et les indépendants.",
			probability: 28,
			stageName: "Decision Makers",
			amount: 75830,
			closeDate: addDaysToDate(new Date(), 23).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2024-06-03T00:00:00.000Z",
			ownerId: "bad8ae15-a6c3-4402-9dc3-747742b61b2e",
			ownerFirstName: "Benoît",
			ownerLastName: "Martinez",
			accountName: "Qonto",
			closedAt: undefined,
			history: [
				{
					updatedAt: "2023-11-12T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Needs Analysis",
				},
				{
					updatedAt: "2023-12-03T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Prospecting",
				},
				{
					updatedAt: "2023-12-18T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Decision Makers",
				},
				{
					updatedAt: "2024-01-09T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Perception Analysis",
				},
				{
					updatedAt: "2024-07-31T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Value Proposition",
				},
			],
			risks: [],
			isWon: false,
			isLost: false,
		},
		{
			id: "68b2bf77-7c71-462b-a490-965e1bd7e4f7",
			accountId: null,
			name: "Back Market",
			description: "Marketplace pour les produits électroniques reconditionnés.",
			probability: 10,
			stageName: "Negotiation",
			amount: 87580,
			closeDate: addDaysToDate(new Date(), 12).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2024-04-21T00:00:00.000Z",
			ownerId: "fe5dc403-5af9-41b2-b8bf-ad085ab373a6",
			ownerFirstName: "Julien",
			ownerLastName: "Lefevre",
			accountName: "Back Market",
			closedAt: undefined,
			history: [
				{
					updatedAt: "2023-11-31T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Proposal",
				},
				{
					updatedAt: "2023-12-25T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Decision Makers",
				},
				{
					updatedAt: "2023-12-22T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Perception Analysis",
				},
				{
					updatedAt: "2024-06-19T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Qualification",
				},
			],
			risks: [],
			isWon: false,
			isLost: false,
		},
		{
			id: "05f0d0e3-4c1c-4d32-9a73-03deff3a379b",
			accountId: null,
			name: "Zendesk",
			description: "Logiciel de gestion de la relation client.",
			probability: 85,
			stageName: "Proposal",
			amount: 79665,
			closeDate: addDaysToDate(new Date(), 15).toISOString(),
			nextStep: null,
			LeadSource: "Website",
			createdAt: "2023-11-01T00:00:00.000Z",
			ownerId: "34b949df-3d50-49a8-a371-ed68b1299293",
			ownerFirstName: "Antoine",
			ownerLastName: "Dupont",
			accountName: "Zendesk",
			closedAt: undefined,
			history: [
				{
					updatedAt: "2023-11-31T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Negotiation",
				},
				{
					updatedAt: "2024-06-29T00:00:00.000Z",
					updatedProperty: "stage",
					previousValue: null,
					newValue: "Qualification",
				},
			],
			risks: [],
			isWon: false,
			isLost: false,
		},
	],
	owners: [
		{
			id: "fe5dc403-5af9-41b2-b8bf-ad085ab373a6",
			username: "jlefevre",
			fullName: "Julien Lefevre",
			email: "julien.lefevre@example.com",
		},
		{
			id: "34b949df-3d50-49a8-a371-ed68b1299293",
			username: "adupont",
			fullName: "Antoine Dupont",
			email: "antoine.dupont@example.com",
		},
		{
			id: "bad8ae15-a6c3-4402-9dc3-747742b61b2e",
			username: "bmartinez",
			fullName: "Benoît Martinez",
			email: "benoit.martinez@example.com",
		},
	],
}

export function makeBoardViewDemoData(filter: ListAllDealsQueryParams): ListAllDealsResponse {
	const dataWithoutStages = unfilteredDemoData
	const allOpenDealsAmount = dataWithoutStages.deals
		.filter((x) => !x.closedAt)
		.reduce((acc, deal) => acc + (deal.amount ?? 0), 0)

	const filteredDeals = dataWithoutStages.deals.filter((deal) => {
		const dealClosedAtDaysAgo = deal.closedAt
			? Math.floor((new Date().getTime() - new Date(deal.closedAt).getTime()) / (1000 * 60 * 60 * 24))
			: 0

		const matchesClosedDealsFilter =
			(!deal.isWon && !deal.isLost) ||
			(deal.createdAt && dealClosedAtDaysAgo <= filter.includeClosedDealsFromLastNDays)
		const matchesOwnerFilter = !filter.ownerId || deal.ownerId === filter.ownerId
		const matchesClosingDateFilter =
			!filter.closingDateEnd ||
			(filter.closingDateEnd && new Date(deal.closeDate) <= new Date(filter.closingDateEnd))

		return Boolean(matchesClosedDealsFilter && matchesOwnerFilter && matchesClosingDateFilter)
	})

	const stages = filteredDeals.reduce(
		(acc, deal) => {
			const stage = acc.find((stage) => stage.stage === deal.stageName)
			if (!deal.stageName) {
				return acc
			}

			const dealAmount = deal.amount ?? 0
			if (stage) {
				stage.totalAmount += dealAmount
				stage.dealCount++
				stage.averageDealAmount = stage.totalAmount / stage.dealCount
				if (deal.isWon) {
					stage.isWon = true
				} else if (deal.isLost) {
					stage.isLost = true
				}
				if (deal.isWon || deal.isLost) {
					stage.isClosed = true
				}
				if (deal.isLost) {
					stage.atRiskDealCount++
					stage.atRiskAmount += dealAmount
				}
				const percentageAmountRelativeToAllOpenDeals = Math.floor(
					(stage.totalAmount / allOpenDealsAmount) * 100,
				)
				stage.percentageAmountRelativeToAllOpenDeals = percentageAmountRelativeToAllOpenDeals
			} else {
				const percentageAmountRelativeToAllOpenDeals = Math.floor((dealAmount / allOpenDealsAmount) * 100)
				acc.push({
					stage: deal.stageName,
					totalAmount: dealAmount,
					dealCount: 1,
					averageDealAmount: dealAmount,
					percentageAmountRelativeToAllOpenDeals: percentageAmountRelativeToAllOpenDeals,
					isClosed: deal.isWon || deal.isLost,
					isWon: deal.isWon,
					isLost: deal.isLost,
					atRiskDealCount: deal.risks?.length ? 1 : 0,
					atRiskAmount: deal.risks?.length ? dealAmount : 0,
				})
			}
			return acc
		},
		[] as ListAllDealsResponse["stages"],
	)

	const stagesOrder = [
		"Prospecting",
		"Qualification",
		"Needs Analysis",
		"Value Proposition",
		"Perception Analysis",
		"Proposal",
		"Negotiation",
		"Decision Makers",
		"Closed: Won",
		"Closed: Lost",
	]

	stages.sort((a, b) => {
		const aIndex = stagesOrder.indexOf(a.stage)
		const bIndex = stagesOrder.indexOf(b.stage)
		return aIndex - bIndex
	})

	return ListAllDealsResponseSchema.parse({
		...dataWithoutStages,
		deals: filteredDeals,
		stages,
	})
}

export function makeListViewDemoData(): GetRelationshipsResponse {
	const deals = makeBoardViewDemoData({
		includeClosedDealsFromLastNDays: 365,
	})
	const randomInRange = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min)

	function makeFakeCallActivities(): RelationshipCallActivity[] {
		const numberOfCallsTogenerate = randomInRange(2, 10)
		const callActivities: RelationshipCallActivity[] = []
		const twoMonthsAgo = new Date()
		twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2)

		for (let i = 0; i < numberOfCallsTogenerate; i++) {
			const callDate = randomInRange(twoMonthsAgo.getTime(), new Date().getTime())

			callActivities.push({
				id: `call-${i}`,
				createdAt: new Date(callDate),
				name: `Call ${i + 1}`,
				attendees: [],
				keyPoints: ["Call key point 1", "Call key point 2"],
				thumbnailUrl: "https://example.com/thumbnail.jpg",
			})
		}

		return callActivities
	}

	return {
		relationships: deals.deals
			.map((deal): GetRelationshipsResponse["relationships"][0] => {
				const callActivities = makeFakeCallActivities()
				const lastCallActivityDate = callActivities.reduce(
					(acc, callActivity) => (callActivity.createdAt > acc ? callActivity.createdAt : acc),
					new Date(0),
				)
				return {
					id: deal.id,
					workspaceId: "salesforce-workspace", // Assuming a fixed value for this
					accountDomains: deal.accountName ? [`${deal.accountName.toLowerCase()}.com`] : [],
					personalEmails: deal.ownerId
						? [`${deal.ownerFirstName?.toLowerCase()}.${deal.ownerLastName?.toLowerCase()}@example.com`]
						: [],
					salesMotionBlockers: deal.risks?.map((risk) => risk.reason) ?? null,
					account: deal.accountName
						? {
								relationshipId: deal.id,
								id: `${deal.accountName.toLowerCase()}-acc-001`,
								name: deal.accountName,
						  }
						: null,
					opportunity: {
						id: deal.id,
						closeDate: deal.closeDate,
						stageName: deal.stageName,
						relationshipId: deal.id,
						probability: deal.probability,
						amount: deal.amount,
					},
					risks: deal.risks?.map((risk) => risk.reason) ?? null,
					lastActivityDate: lastCallActivityDate,
					activities: {
						calls: callActivities,
						calendarEvents: null, // Assuming no calendar events from the input
					},
				}
			})
			.sort((a, b) => {
				return (a.lastActivityDate?.getTime() ?? 0) - (b.lastActivityDate?.getTime() ?? 0)
			}),
		total: deals.deals.length,
	}
}
