import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import React from "react"
import { Link } from "react-router-dom"

import { type AnalyticsProps, useAnalytics } from "../../contexts/analytics.context"

type DropdownItem =
	| ({
			type: "button"
			name: string
			onClick: () => void
			className?: string
			disabled?: boolean
	  } & AnalyticsProps)
	| { type: "link"; to: string; name: string; className?: string; disabled?: boolean }

type DropdownButtonProps = {
	label: string
	items: DropdownItem[]
	buttonSize?: "sm" | "md" | "lg" | "xs"
	variant?: "default" | "primary" | "danger" | "text" | "success"
	onClick: () => void
	disabled?: boolean
} & AnalyticsProps

export function DropdownButton({
	label,
	items,
	buttonSize = "md",
	variant = "default",
	eventName,
	eventProperties,
	onClick,
	disabled = false,
}: DropdownButtonProps) {
	const { setupEventTracking } = useAnalytics()
	const { trackEvent: trackUsage, debugDOMProps } = setupEventTracking({ eventName, eventProperties })

	const sizeClasses = {
		xs: "px-2 py-1 text-xs",
		sm: "px-2.5 py-1.5 text-sm",
		md: "px-3 py-2 text-sm",
		lg: "px-3.5 py-2.5 text-sm",
	}

	const variantClasses = {
		default: "bg-white text-black hover:bg-gray-100 ring-1 ring-inset ring-gray-300",
		primary: "bg-navy-700 text-white hover:bg-navy-500",
		danger: "bg-red-600 text-white hover:bg-red-500",
		text: "bg-transparent text-navy-700 hover:text-navy-500",
		success: "bg-emerald-500 text-white hover:bg-emerald-400",
	}

	const disabledClasses = "opacity-50 cursor-not-allowed"

	const buttonClasses = clsx(
		"inline-flex items-center rounded-l-md font-semibold focus:outline-none min-w-[5rem]",
		sizeClasses[buttonSize],
		variantClasses[variant],
		disabled && disabledClasses,
	)

	const handleItemClick = (item: DropdownItem) => {
		if (item.type === "button" && !item.disabled) {
			const { trackEvent } = setupEventTracking({
				eventName: item.eventName,
				eventProperties: item.eventProperties,
			})
			console.debug("[DropdownButton] Tracking event:", item.eventName, item.eventProperties)
			trackEvent()
			item.onClick()
		}
	}

	const handleActionClick = () => {
		if (!disabled) {
			console.debug("[DropdownButton] Tracking event:", eventName, eventProperties)
			trackUsage()
			onClick()
		}
	}

	return (
		<div className="inline-flex rounded-md shadow-sm">
			<button
				type="button"
				className={buttonClasses}
				{...debugDOMProps}
				onClick={handleActionClick}
				disabled={disabled}
			>
				{label}
			</button>
			<Menu as="div" className="relative -ml-px block">
				<MenuButton
					disabled={disabled}
					className={clsx(
						"relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300",
						disabled ? disabledClasses : "hover:bg-gray-50",
					)}
				>
					<span className="sr-only">Open options</span>
					<ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
				</MenuButton>
				<MenuItems className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						{items.map((item, index) =>
							item.type === "button" ? (
								<MenuItem
									key={index}
									as="button"
									onClick={() => handleItemClick(item)}
									disabled={item.disabled}
									className={clsx(
										"block px-4 py-2 text-sm text-gray-700 w-full text-left",
										item.disabled ? disabledClasses : "hover:bg-gray-100",
										item.className,
									)}
								>
									{item.name}
								</MenuItem>
							) : (
								<MenuItem key={index}>
									<Link
										to={item.to}
										onClick={() => !item.disabled && handleItemClick(item)}
										className={clsx(
											"block px-4 py-2 text-sm text-gray-700 w-full text-left",
											item.disabled ? disabledClasses : "hover:bg-gray-100",
											item.className,
										)}
										{...debugDOMProps}
									>
										{item.name}
									</Link>
								</MenuItem>
							),
						)}
					</div>
				</MenuItems>
			</Menu>
		</div>
	)
}
