import assertNever from "assert-never"
import _ from "lodash"

import type { ApiLibraryFolder } from "../infra/gateways/http.library.gateway/apiLibraryFolder.schema"
import type { ApiListLibraryFoldersResponse } from "../infra/gateways/http.library.gateway/listLibraryFoldersResponse.schema"

export const LibraryFolderTags = [
	"workspace-ai-highlights",
	"user-ai-highlights",
	"user-bad-ai-highlights",
	"hidden",
] as const

type FrontOnlyTags = "readonly" | "no-children" | "__unknown__" // Be tolerant to future tags

export type LibraryFolderTag = (typeof LibraryFolderTags)[number] | FrontOnlyTags

type LibraryFolderProperties = {
	id: string
	name: string
	isWorkspaceRoot: boolean
	isUserRoot: boolean
	tags: LibraryFolderTag[]
	callHighlightCount: number
}

export class LibraryFolder {
	private _children: LibraryFolder[] = []
	private _parent: LibraryFolder | undefined

	/** Whether the folder should have a pulse notification in the display */
	public hasBadge = false

	constructor(
		public readonly properties: LibraryFolderProperties,
		parent: LibraryFolder | undefined,
	) {
		this._parent = parent
		if (this.isAIFolder()) {
			this.properties.tags.push("readonly")
		}
	}

	public get id(): string {
		return this.properties.id
	}

	public get name(): string {
		return this.properties.name
	}

	public addChild(child: LibraryFolder): void {
		this._children.push(child)
		child.parent = this
	}

	public removeChild(child: LibraryFolder): void {
		const index = this._children.findIndex((c) => c.id === child.id)
		if (index === -1) {
			console.error(`Child with id ${child.id} not found in parent ${this.id}`)
			return
		}
		this._children.splice(index, 1)
		child.parent = undefined
	}

	public hasChildren(): boolean {
		return this._children.length > 0
	}

	public getFullFolderPath(): LibraryFolder[] {
		const parents: LibraryFolder[] = []
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		let current: LibraryFolder | undefined = this
		while (current) {
			parents.unshift(current)
			current = current.parent
		}
		return parents
	}

	public isFolderOrDescendantOpen(currentFolder: LibraryFolder): boolean {
		const currentFolderPath = currentFolder.getFullFolderPath()
		return currentFolderPath.some((f) => f.id === this.id)
	}

	public rename(newName: string) {
		this.properties.name = newName
	}

	public moveTo(newParent: LibraryFolder) {
		this.parent?.removeChild(this)
		newParent.addChild(this)
	}

	public get children(): LibraryFolder[] {
		return sortFolders(this._children)
	}

	public get parent() {
		return this._parent
	}

	public set parent(parent: LibraryFolder | undefined) {
		this._parent = parent
		if (parent?.isAIFolder()) {
			this.properties.tags.push("readonly")
		}
	}

	public isAIFolder(): boolean {
		return this.properties.tags.some((tag) => {
			switch (tag) {
				case "workspace-ai-highlights":
				case "user-ai-highlights":
				case "user-bad-ai-highlights":
					return true

				case "hidden":
				case "readonly":
				case "no-children":
				case "__unknown__":
					return false

				default:
					assertNever(tag)
			}
		})
	}

	public getRecursiveCallHighlightCount(): number {
		return (
			this.properties.callHighlightCount +
			_.sumBy(this.children, (child) => child.getRecursiveCallHighlightCount())
		)
	}
}

export type ReactionType = "thumbsUp" | "thumbsDown"

export type CallHighlight = {
	id: string
	containingLibraryFolderId: string | undefined
	startsAtMs: number
	endsAtMs: number
	comment: string
	creatorFullName: string | undefined // undefined means deleted user
	creationDate: Date
	callId: string
	callName: string
	callDate: Date
	callDurationSec: number
	reactions: {
		thumbsUpCount: number
		thumbsDownCount: number
		userReaction: ReactionType | undefined
	}
}

export class Library {
	private constructor(
		public readonly workspaceRoot: LibraryFolder,
		public readonly userRoot: LibraryFolder,
	) {}

	// generator that iterates over all children and nested children of a given LibraryFolder
	private *iterateChildren(folder: LibraryFolder): Generator<LibraryFolder> {
		yield folder
		for (const child of folder.children) {
			yield* this.iterateChildren(child)
		}
	}

	public getFolderById(id: string): LibraryFolder | undefined {
		for (const folder of this.iterateChildren(this.workspaceRoot)) {
			if (folder.id === id) {
				return folder
			}
		}
		for (const folder of this.iterateChildren(this.userRoot)) {
			if (folder.id === id) {
				return folder
			}
		}
	}

	public listAllFolders(): LibraryFolder[] {
		return [...this.iterateChildren(this.workspaceRoot), ...this.iterateChildren(this.userRoot)]
	}

	public getTotalCallHighlightCount(): number {
		let count = 0
		for (const folder of this.iterateChildren(this.workspaceRoot)) {
			count += folder.properties.callHighlightCount
		}
		for (const folder of this.iterateChildren(this.userRoot)) {
			count += folder.properties.callHighlightCount
		}

		return count
	}

	public static create(command: ApiListLibraryFoldersResponse): Library {
		const workspaceRoot = new LibraryFolder(command.workspaceRoot, undefined)
		const userRoot = new LibraryFolder(command.userRoot, undefined)

		function buildTree(root: LibraryFolder, allChildren: ApiLibraryFolder[]) {
			const foldersById: Map<string, LibraryFolder> = new Map()

			foldersById.set(root.id, root)
			allChildren.forEach((apiFolder) => {
				const folder = new LibraryFolder(apiFolder, undefined)
				foldersById.set(apiFolder.id, folder)
			})

			foldersById.forEach((folder, id) => {
				if (id === root.id) return // nothing to do

				const apiFolder = allChildren.find((f) => f.id === id)
				if (!apiFolder) {
					console.error(`Folder with id ${id} not found`)
					return
				}
				if (!apiFolder.parentLibraryFolderId) {
					console.error(`Stale folder with id ${id}`)
					return
				}

				const parentFolder = foldersById.get(apiFolder.parentLibraryFolderId)
				if (!parentFolder) {
					/**
					 * Might happen, because the backend hides some folders, but still returns hidden folder's children
					 */
					// console.error(`Parent folder with id ${apiFolder.parentLibraryFolderId} not found`)
					return
				}

				parentFolder.addChild(folder)
			})
		}

		buildTree(workspaceRoot, command.workspaceFolders)
		buildTree(userRoot, command.userFolders)

		return new Library(workspaceRoot, userRoot)
	}
}

export function sortFolders(folders: LibraryFolder[]): LibraryFolder[] {
	return folders.sort((a, b) => {
		if (isAIGeneratedFolder(a)) {
			return -1
		}
		if (isAIGeneratedFolder(b)) {
			return 1
		}

		if (a.parent?.isAIFolder() && b.parent?.isAIFolder()) {
			return getFolderIndex(a) - getFolderIndex(b)
		}

		return a.name.localeCompare(b.name)
	})
}

export function isAIGeneratedFolder(folder: LibraryFolder): boolean {
	return folder.properties.tags.some(
		(tag) => tag === "workspace-ai-highlights" || tag === "user-ai-highlights" || tag === "user-bad-ai-highlights",
	)
}

const THEME_FOLDERS = [
	{
		name: {
			fr: "Introduction",
			en: "Introduction",
			es: "Introducción",
		},
	},
	{
		name: {
			fr: "Présentation de l'entreprise",
			en: "Company presentation",
			es: "Presentación de la empresa",
		},
	},
	{
		name: {
			fr: "Identification des besoins",
			en: "Needs identification",
			es: "Identificación de necesidades",
		},
	},
	{
		name: {
			fr: "Découverte des concurrents",
			en: "Competition discovery",
			es: "Descubrimiento de la competencia",
		},
	},
	{
		name: {
			fr: "Présentation du produit/service",
			en: "Product/service presentation",
			es: "Presentación del producto/servicio",
		},
	},
	{
		name: {
			fr: "Études de cas & témoignages",
			en: "Case studies & testimonials",
			es: "Estudios de caso y testimonios",
		},
	},
	{
		name: {
			fr: "Gestion des objections",
			en: "Objection handling",
			es: "Manejo de objeciones",
		},
	},
	{
		name: {
			fr: "Questions ouvertes & extraction de feedback",
			en: "Open questions & feedback extraction",
			es: "Preguntas abiertas y extracción de comentarios",
		},
	},
	{
		name: {
			fr: "Identification des critères/processus de décision",
			en: "Decision criteria/process identification",
			es: "Identificación de criterios/procesos de decisión",
		},
	},
	{
		name: {
			fr: "Planification de la mise en œuvre & définition du succès",
			en: "Implementation planning & success definition",
			es: "Planificación de la implementación y definición del éxito",
		},
	},
	{
		name: {
			fr: "Offre & négociation",
			en: "Offer & negotiation",
			es: "Oferta y negociación",
		},
	},
	{
		name: {
			fr: "Conclusion & prochaines étapes",
			en: "Conclusion & next steps",
			es: "Conclusión y próximos pasos",
		},
	},
] as const

function getFolderIndex(folder: LibraryFolder): number {
	const index = THEME_FOLDERS.findIndex(
		(theme) => theme.name.en === folder.name || theme.name.fr === folder.name || theme.name.es === folder.name,
	)
	if (index === -1) {
		return -1 // weird but don't care
	}
	return index
}
