import React from "react"
import { Trans } from "react-i18next"

import type { CrmIntegration, SalesforceIntegration } from "../../../../core/domain/Workspace.entity"
import { useLanguage } from "../../../contexts/language.context"
import { CrmCredentialsForm } from "../../../pages/Settings/Workspace/CrmSettings/CrmCredentialsForm.component"
import { Input } from "../../design-system/Input"

type SalesforceSetupFormProps = {
	crmIntegration: SalesforceIntegration
	onInstanceUrlChange: (instanceUrl: string) => void
	onCredentialsChange: (credentials: CrmIntegration["credentials"]) => void
	isCrmIntegrationEnabled: boolean
	salesforceCredentialTypes: Array<{
		label: string
		type: SalesforceIntegration["credentials"]["type"]
		select: () => void
	}>
}

export function SalesforceSetupFormComponent({
	crmIntegration,
	onInstanceUrlChange,
	onCredentialsChange,
	isCrmIntegrationEnabled,
	salesforceCredentialTypes,
}: SalesforceSetupFormProps) {
	const { t } = useLanguage()
	return (
		<>
			<Input.Group>
				<Input.Label htmlFor="instanceUrl">{t("Instance URL")}</Input.Label>
				<Input.Input
					id="instanceUrl"
					name="instanceUrl"
					type="text"
					value={crmIntegration.instanceUrl}
					onChange={(event) => onInstanceUrlChange(event.target.value)}
					placeholder="https://yourcompany.lightning.force.com"
					required
					disabled={!isCrmIntegrationEnabled}
				/>
				<Input.Helper>
					<Trans
						i18nKey="Copy the first part of your Salesforce URL after logging in. It usually looks like <1>https://yourcompany.lightning.force.com</1> or <2>https://yourcompany.my.salesforce.com</2>"
						components={{
							1: <span className="font-medium" />,
							2: <span className="font-medium" />,
						}}
					/>
				</Input.Helper>
			</Input.Group>
			<CrmCredentialsForm
				credentialTypes={salesforceCredentialTypes}
				crmIntegration={crmIntegration}
				onCredentialsChange={onCredentialsChange}
				disabled={!isCrmIntegrationEnabled}
			/>
		</>
	)
}
