import _ from "lodash"

function isNullishOrEmpty(value: unknown): boolean {
	return (
		value == null || // Checks for null or undefined
		value === "" || // Checks for an empty string
		(_.isArray(value) && _.isEmpty(value)) // Checks for an empty array
	)
}

// Check if all properties of an object are empty (null, undefined, empty string, or empty array)
export function areAllPropertiesNullishOrEmpty(obj: Record<string, unknown>): boolean {
	return _.every(obj, isNullishOrEmpty)
}
