import clsx from "clsx"
import React from "react"

import type { UploadedFile } from "../../../core/application/gateways/storage.gateway"
import { useDependencies } from "../../contexts/dependencies.context"
import { useLanguage } from "../../contexts/language.context"

type FileUploadFormProps = {
	onFileUploaded: (file: UploadedFile) => void
	mimeType?: string
}

export function FileUploadForm({ onFileUploaded, mimeType }: FileUploadFormProps) {
	const { storageGateway } = useDependencies()
	const [isUploading, setIsUploading] = React.useState(false)
	const { t } = useLanguage()

	const handleChange = React.useCallback(
		async (event: React.ChangeEvent<HTMLInputElement>) => {
			const file = event.target.files?.[0]
			if (!file) return

			setIsUploading(true)
			const uploadedFile = await storageGateway.uploadFile(file)

			onFileUploaded(uploadedFile)
		},
		[onFileUploaded, storageGateway],
	)

	return (
		<div>
			<label
				className={clsx(
					"flex items-center justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-700 cursor-pointer",
					isUploading
						? "cursor-not-allowed bg-gray-200 text-gray-500 ring-gray-200"
						: "bg-navy-700 hover:bg-navy-500",
				)}
				htmlFor="file_input"
			>
				{t(isUploading ? "Uploading..." : "Choose a file")}
			</label>
			<input className="hidden" id="file_input" type="file" onChange={handleChange} accept={mimeType} />
		</div>
	)
}
