import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import React from "react"

import type { TagType } from "../../../../../core/domain/TagType.entity"
import { Button } from "../../../../components/design-system/Button.component"
import { Duration } from "../../../../components/design-system/Duration.component"
import { Modal } from "../../../../components/design-system/Modal.component"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TagTypeBadge } from "../../../../components/shared/TagTypeBadge.component"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { useAvailableCallTags } from "../../../../hooks/useAvailableCallTags"

type CreateCallExcerptModalProps = {
	callId: string
	onClose: () => void
	open: boolean
	callExceptStartTimeSeconds: number
	callExceptEndTimeSeconds: number
	refreshHighlights?: () => void
}

export function CreateCallExcerptModal({
	open,
	onClose,
	callExceptStartTimeSeconds,
	callExceptEndTimeSeconds,
	callId,
	refreshHighlights,
}: CreateCallExcerptModalProps) {
	const { availableTags } = useAvailableCallTags()
	const { callExcerptsGateway } = useDependencies()
	const { t } = useLanguage()
	const excerptTags = availableTags === "loading" ? "loading" : availableTags.filter((tag) => tag.isExcerptTag())
	const [selectedTag, setSelectedTag] = React.useState<TagType | null>(null)
	const [isCreating, setIsCreating] = React.useState(false)

	const handleCreateCallTag = React.useCallback(async () => {
		if (!selectedTag) return

		setIsCreating(true)
		try {
			await callExcerptsGateway.create({
				callId,
				startsAtMs: callExceptStartTimeSeconds * 1000,
				endsAtMs: callExceptEndTimeSeconds * 1000,
				tagTypeId: selectedTag.properties.id,
			})
			refreshHighlights?.()
			onClose()
		} catch (error) {
			console.error(error)
		} finally {
			setIsCreating(false)
		}
	}, [
		callExceptEndTimeSeconds,
		callExceptStartTimeSeconds,
		callExcerptsGateway,
		callId,
		onClose,
		refreshHighlights,
		selectedTag,
	])

	return (
		<Modal open={open} onClose={onClose}>
			<div className="text-lg font-semibold">
				{t("Create a call tag from")} <Duration seconds={callExceptStartTimeSeconds} /> {t("to")}{" "}
				<Duration seconds={callExceptEndTimeSeconds} />
			</div>
			<div className="flex items-center justify-center mt-4">
				<Menu as="div" className="relative inline-block text-left">
					<div>
						<MenuButton
							disabled={isCreating}
							className="inline-flex whitespace-nowrap w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						>
							{selectedTag ? <TagTypeBadge tagType={selectedTag} /> : t("Select a tag")}
							<ChevronDownIcon aria-hidden="true" className="-mr-1 w-5 h-5 text-gray-400" />
						</MenuButton>
					</div>

					<MenuItems
						transition
						className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
						anchor={{
							to: "bottom start",
						}}
					>
						<div className="p-2 flex flex-col gap-1">
							{excerptTags !== "loading" &&
								excerptTags.map((tag) => {
									return (
										<MenuItem key={tag.properties.id}>
											<div className="flex gap-1 items-center">
												<TagTypeBadge onClick={() => setSelectedTag(tag)} tagType={tag} />
											</div>
										</MenuItem>
									)
								})}
						</div>
					</MenuItems>
				</Menu>
			</div>
			{selectedTag && (
				<div
					className="border-l-4 shadow-md mt-2 text-sm text-gray-600 p-1 rounded-md"
					style={{
						borderColor: selectedTag.properties.color,
					}}
				>
					{selectedTag.properties.description}
				</div>
			)}

			<div className="flex items-center justify-end">
				<Button
					eventName="Create call tag clicked"
					className="mt-4"
					onClick={handleCreateCallTag}
					variant="primary"
					disabled={!selectedTag || isCreating}
				>
					{isCreating && <SpinnerIcon className="h-4 w-4" />}
					{t("Create call tag")}
				</Button>
			</div>
		</Modal>
	)
}
