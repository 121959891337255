import assertNever from "assert-never"

import { type TranslationKey, useLanguage } from "../../../../../contexts/language.context"

export type HighlightCoachingSessionBadgeProps = {
	type: "top-moment" | "to-improve" | "best-practice"
}

export function HighlightCoachingSessionBadge({ type }: HighlightCoachingSessionBadgeProps) {
	const { t } = useLanguage()

	const { label, classes } = getLabelAndClasses(type)

	return (
		<div
			className={`flex items-center justify-center w-40 h-10 px-4 py-2 mb-1 rounded-full border font-semibold ${classes}`}
		>
			{t(label)}
		</div>
	)
}

function getLabelAndClasses(type: HighlightCoachingSessionBadgeProps["type"]): {
	label: TranslationKey
	classes: string
} {
	switch (type) {
		case "top-moment":
			return {
				label: "Top moment",
				classes: "border-green-500 bg-green-100 text-green-500",
			}

		case "to-improve":
			return {
				label: "To improve",
				classes: "border-yellow-500 bg-yellow-100 text-yellow-500",
			}

		case "best-practice":
			return {
				label: "Best practice",
				classes: "border-indigo-500 bg-indigo-100 text-indigo-500",
			}

		default:
			assertNever(type)
	}
}
