import { XMarkIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import React from "react"

import { TrackingButton } from "./TrackingButton.component"

export type BannerProps = {
	variant: "info" | "warning" | "error"
	children: React.ReactNode
	onDismiss?: () => void
	className?: string
}

export default function Banner({ children, onDismiss, variant, className }: BannerProps) {
	const containerClassName = clsx(
		className,
		"flex items-center gap-x-6 px-6 py-2.5 sm:px-3.5 rounded-md justify-center",
		{
			"bg-indigo-600": variant === "info",
			"bg-yellow-500": variant === "warning",
			"bg-red-600": variant === "error",
		},
	)

	const textColor = clsx({
		"text-white": variant === "info" || variant === "warning",
		"text-black": variant === "warning",
	})

	return (
		<div className={containerClassName}>
			<p className={clsx("text-sm leading-6", textColor)}>{children}</p>
			{onDismiss && (
				<div className="flex flex-1 justify-end">
					<TrackingButton
						eventName="Banner: dismiss clicked"
						onClick={onDismiss}
						className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
					>
						<span className="sr-only">Dismiss</span>
						<XMarkIcon aria-hidden="true" className="h-5 w-5 text-white" />
					</TrackingButton>
				</div>
			)}
		</div>
	)
}
