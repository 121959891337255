import clsx from "clsx"
import React from "react"

import { Layout } from "../../components/shared/Layout/Layout.component"
import { useLanguage } from "../../contexts/language.context"
import { useMyPrograms } from "../Training/Programs/TrainingPrograms.page"
import { MyTraining } from "./modules/MyTraining.component"
import { ProspectingSchedule } from "./modules/ProspectingSchedule.component"
import { RecentCalls } from "./modules/RecentCalls.component"

export function DashboardPage() {
	const { t } = useLanguage()
	const { state: programState } = useMyPrograms()
	const shouldHideMyTraining =
		programState.status === "loading" ||
		(programState.status === "success" &&
			programState.data.filter((p) => p.items.seen !== p.items.total).length === 0)

	return (
		<div className="bg-gray-100">
			<Layout pageName={t("Dashboard")} className="!p-4 !m-0" isFluid>
				<div className="grid grid-cols-6 gap-4 flex-1">
					<div className="col-span-2 h-full">
						<ProspectingSchedule />
					</div>
					<div className="col-span-4 gap-4 flex-col flex-grow flex justify-between">
						{!shouldHideMyTraining && (
							<MyTraining programState={programState} className="min-h-[22vh]" listClassName="h-[16vh]" />
						)}
						<RecentCalls
							className={clsx(shouldHideMyTraining ? "h-[calc(100vh-6rem)]" : "h-[66vh]")}
							tableContainerClassName={shouldHideMyTraining ? "h-[calc(100vh-18rem)]" : "h-[44vh]"}
						/>
					</div>
				</div>
			</Layout>
		</div>
	)
}
