import React from "react"
import { Trans } from "react-i18next"

import type { CrmIntegration, HubspotIntegration } from "../../../../core/domain/Workspace.entity"
import { useLanguage } from "../../../contexts/language.context"
import { Input } from "../../design-system/Input"
import { TrackingExternalHref } from "../../design-system/TrackingExternalHref.component"

type HubspotSetupFormProps = {
	crmIntegration: HubspotIntegration
	onCredentialsChange: (credentials: CrmIntegration["credentials"]) => void
	isCrmIntegrationEnabled: boolean
}

export function HubspotSetupFormComponent({
	crmIntegration,
	onCredentialsChange,
	isCrmIntegrationEnabled,
}: HubspotSetupFormProps) {
	const { t } = useLanguage()
	return (
		<Input.Group>
			<Input.Label htmlFor="accessToken">{t("Access token")}</Input.Label>
			<Input.Input
				id="accessToken"
				name="accessToken"
				type="text"
				value={crmIntegration.credentials.accessToken ?? ""}
				onChange={(event) =>
					onCredentialsChange({
						accessToken: event.target.value,
						type: "accessToken",
					})
				}
				placeholder={t("paste your Hubspot access token here")}
				required
				disabled={!isCrmIntegrationEnabled}
			/>
			<Input.Helper>
				<Trans
					i18nKey="After creating your Hubspot private app, copy the access token and paste it here. If you do not have a Hubspot private app yet, follow the steps on the <1>Hubspot documentation</1> to create one."
					components={{
						1: (
							<TrackingExternalHref
								href="https://developers.hubspot.com/docs/api/private-apps"
								eventName="Hubspot documentation link clicked"
								className="inline-flex"
							/>
						),
					}}
				/>
			</Input.Helper>
		</Input.Group>
	)
}
