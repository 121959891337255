import { XMarkIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import _ from "lodash"
import React from "react"
import Datepicker from "react-tailwindcss-datepicker"

import type { User } from "../../../../../core/domain/User.entity"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../../contexts/language.context"

type EnrolmentListProps = {
	users: Array<{ user: User; deadline: Date | null; completionRatePercent: number }>
	onRemoveEnrolment: (user: { user: User; deadline: Date | null; completionRatePercent: number }) => void
	onDeadlineChange: (user: User, deadline: Date | null) => void
}

export function EnrolmentList({ users, onRemoveEnrolment, onDeadlineChange }: EnrolmentListProps) {
	const { t } = useLanguage()
	const sortedUsers = _.sortBy(
		users,
		(user) => user.completionRatePercent === 100,
		(user) => user.deadline,
	)

	return (
		<div className="px-4 sm:px-6 lg:px-8">
			<div className="mt-8 flow-root">
				<div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<table className="min-w-full divide-y divide-gray-300">
							<thead>
								<tr>
									<th
										scope="col"
										className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
									>
										{t("Name")}
									</th>
									<th
										scope="col"
										className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
									>
										{t("Completion rate")}
									</th>
									<th
										scope="col"
										className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
									>
										{t("Deadline")}
									</th>
									<th
										scope="col"
										className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
									>
										{t("Deadline status")}
									</th>
									<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
										<span className="sr-only">{t("Edit…")}</span>
									</th>
								</tr>
							</thead>
							<tbody className="divide-y divide-gray-200">
								{sortedUsers.map(({ user, deadline, completionRatePercent }) => {
									const deadlineDaysLeft = deadline
										? Math.ceil((deadline.getTime() - Date.now()) / (1000 * 60 * 60 * 24))
										: 0
									let deadlineStatus = deadlineDaysLeft
										? deadlineDaysLeft > 0
											? t("daysLeft", { count: deadlineDaysLeft })
											: t("daysLate", { count: Math.abs(deadlineDaysLeft) })
										: null

									if (completionRatePercent === 100) {
										deadlineStatus = t("Completed")
									}

									return (
										<tr key={user.id}>
											<td
												className={clsx(
													"whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-0",
													completionRatePercent === 100 ? "text-gray-300" : "text-gray-900",
												)}
											>
												{user.getFullName()}
											</td>
											<td
												className={clsx(
													"whitespace-nowrap px-3 py-4 text-sm",
													completionRatePercent === 100 ? "text-gray-300" : "text-gray-500",
												)}
											>
												{completionRatePercent}%
											</td>
											<td
												className={clsx(
													"whitespace-nowrap px-3 py-4 text-sm flex gap-2 items-center",
												)}
											>
												<Datepicker
													asSingle={true}
													popoverDirection="up"
													value={{ startDate: deadline, endDate: deadline }}
													onChange={(newValue) =>
														onDeadlineChange(
															user,
															newValue?.startDate ? new Date(newValue.startDate) : null,
														)
													}
													inputClassName={(className) =>
														clsx(
															className,
															completionRatePercent === 100
																? "text-gray-300"
																: "text-gray-500",
														)
													}
													containerClassName={(className) =>
														clsx(className, "lg:max-w-[180px] z-50")
													}
												/>
											</td>
											<td
												className={clsx(
													"whitespace-nowrap px-3 py-4 text-sm",
													completionRatePercent === 100 ? "text-gray-300" : "text-gray-500",
												)}
											>
												{deadlineStatus ?? " - "}
											</td>
											<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
												<TrackingButton
													onClick={() =>
														onRemoveEnrolment({ user, deadline, completionRatePercent })
													}
													eventName="Enrolment list: remove enrolment button clicked"
													title="Remove enrolment"
												>
													<XMarkIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
												</TrackingButton>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}
