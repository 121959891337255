import { omit } from "lodash"

import type { LeadKnowledgeV1 } from "../../../../../../core/domain/Call.entity"
import { useLanguage } from "../../../../../contexts/language.context"
import { formatLeadKnowledgeV1 } from "./formatLeadKnowledge"

export type _LeadKnowledgeV1PanelProps = {
	leadKnowledgeV1: LeadKnowledgeV1
}

export function _LeadKnowledgeV1Panel({ leadKnowledgeV1 }: _LeadKnowledgeV1PanelProps) {
	const { t } = useLanguage()

	const formattedLeadKnowledge = formatLeadKnowledgeV1(omit(leadKnowledgeV1, "version"), t)
	if (!formattedLeadKnowledge) {
		return null
	}

	return (
		<>
			{Object.entries(formattedLeadKnowledge).map(([label, knowledge]) => {
				return (
					<div key={label} className="mb-4">
						<h4 className="whitespace-nowrap mb-2 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-0">
							{label}
						</h4>
						<ul className="text-sm text-gray-600">
							{knowledge.map((knowledgeItem) => {
								return (
									<li key={knowledgeItem} className="ml-2 list-disc list-inside">
										{knowledgeItem}
									</li>
								)
							})}
						</ul>
					</div>
				)
			})}
		</>
	)
}
