import { Dialog, DialogPanel } from "@headlessui/react"
import { ChevronRightIcon } from "@heroicons/react/24/outline"
import React from "react"

import { useRelationshipsContext } from "../../../contexts/relationships.context"
import { SpinnerIcon } from "../../design-system/SpinnerIcon.component"
import { TrackingButton } from "../../design-system/TrackingButton.component"
import { RelationshipPanelContent } from "./RelationshipPanelContent.component"

export function RelationshipSidePanel() {
	const { currentRelationship, isLoading, hideRelationshipDetails, defaultTabToOpen } = useRelationshipsContext()

	return (
		<Dialog
			open={Boolean(currentRelationship || isLoading)}
			onClose={hideRelationshipDetails}
			className="relative z-50"
		>
			<div className="fixed inset-0 bg-black bg-opacity-10" />

			<div className="fixed inset-0 overflow-hidden">
				<div className="absolute inset-0 overflow-hidden">
					<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
						<DialogPanel
							transition
							className="pointer-events-auto w-screen max-w-2xl transform transition duration-200 ease-in-out data-[closed]:translate-x-full"
						>
							<div className="flex h-full flex-col bg-white py-6 shadow-xl">
								<div className="relative flex-1 pl-4 max-h-full">
									<TrackingButton
										eventName="Relationship side panel: close button clicked"
										className="absolute top-16 -left-3 bg-white border border-gray-300 rounded-md hover:bg-gray-100"
									>
										<ChevronRightIcon
											className="h-6 w-6 text-gray-500 cursor-pointer"
											onClick={hideRelationshipDetails}
										/>
									</TrackingButton>
									{currentRelationship ? (
										<RelationshipPanelContent
											relationship={currentRelationship}
											defaultTabToOpen={defaultTabToOpen}
											originPage="deal"
											className="overflow-y-auto overflow-x-hidden pr-4"
										/>
									) : (
										<div className="flex items-center justify-center h-full">
											<SpinnerIcon className="h-8 max-h-8 max-w-8 w-8 mr-2 flex-initial" />
										</div>
									)}
								</div>
							</div>
						</DialogPanel>
					</div>
				</div>
			</div>
		</Dialog>
	)
}
