import "chartjs-plugin-datalabels"
import "chartjs-plugin-annotation"

import {
	CategoryScale,
	Chart as ChartJS,
	type ChartData,
	type ChartOptions,
	Filler,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from "chart.js"
import React from "react"
import { Bar } from "react-chartjs-2"

import type { GetSalesMotionPerformanceStatsResponse } from "../../../../../../core/application/gateways/deals.gateway/schemas/listDeals"
import { useLanguage } from "../../../../../contexts/language.context"
import { getBarChartOptionsForMode } from "./utils/graphUtils"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)

type IActiveDealValueGraphProps = {
	stats: GetSalesMotionPerformanceStatsResponse
	mode: "absolute" | "relative"
}

export function ActiveDealValueGraph({ stats, mode }: IActiveDealValueGraphProps) {
	const { t } = useLanguage()

	const chartOptions: ChartOptions<"bar"> = getBarChartOptionsForMode(mode, "deal-value", stats, t)

	const allStages = stats.byStage.map((stage) => stage.stage)
	const data: ChartData<"bar"> = {
		labels: allStages,
		datasets: [
			{
				data: stats.byStage.map((stage) => {
					if (mode === "absolute") {
						return Math.floor(stage.currentTotalDealValue - stage.previousTotalDealValue)
					}

					const diffPercent = stage.previousTotalDealValue
						? Math.floor(
								((stage.currentTotalDealValue - stage.previousTotalDealValue) /
									stage.previousTotalDealValue) *
									100,
						  )
						: 0
					return diffPercent
				}),
				borderColor: (context) => {
					const value = context.dataset.data[context.dataIndex]
					const entry: GetSalesMotionPerformanceStatsResponse["byStage"][0] | undefined =
						stats.byStage[context.dataIndex]

					if (entry?.isLostStage) {
						return "rgba(239,68,68,1)"
					}

					if (typeof value === "number" && value > 0) {
						return "rgba(54,54,117,1)"
					}

					return "rgba(239,68,68,1)"
				},
				borderWidth: 2,
				borderRadius: 5,
				backgroundColor: (context) => {
					const value = context.dataset.data[context.dataIndex]
					const entry: GetSalesMotionPerformanceStatsResponse["byStage"][0] | undefined =
						stats.byStage[context.dataIndex]

					if (entry?.isLostStage) {
						return "rgba(239,68,68,0.3)"
					}

					if (typeof value === "number" && value > 0) {
						return "rgba(54,54,117,0.3)"
					}

					return "rgba(239,68,68,0.3)"
				},
			},
		],
	}

	return <Bar data={data} options={chartOptions} />
}
