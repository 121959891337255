import type { ICouponsGateway } from "../../application/gateways/coupons.gateway"

export class HttpCouponsGateway implements ICouponsGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async checkCouponValidity(couponCode: string): Promise<boolean> {
		const endpointUrl = `${this.baseApiUrl}/coupons/${couponCode}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		if (![404, 200].includes(res.status)) {
			throw new Error("Failed to check coupon validity")
		}

		return res.status === 200
	}

	public async redeemCoupon(couponCode: string): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/coupons/redeem`
		const res = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ couponId: couponCode }),
			headers: {
				"Content-Type": "application/json",
			},
		})

		if (res.status !== 200) {
			throw new Error("Failed to redeem coupon")
		}
	}
}
