import React from "react"

import type { DocElement } from "../../utils/taggedDoc"

type DocElementRendererProps = {
	docElement: DocElement
	isRoot?: boolean
}

export function DocElementRenderer({ docElement, isRoot = true }: DocElementRendererProps) {
	if (typeof docElement.content === "string") {
		if (isRoot) {
			return <h1 className="text-md text-gray-600">{docElement.content}</h1>
		}

		return <li className="mt-2 text-sm text-gray-600">{docElement.content}</li>
	}

	if ("name" in docElement && docElement.name.startsWith("_")) {
		return null
	}

	const childrenElements = docElement.content.map((element, index) => (
		<DocElementRenderer key={index} docElement={element} isRoot={false} />
	))

	const Wrapper = ({ children }: React.PropsWithChildren) =>
		isRoot ? (
			<div className="gap-6 flex flex-col">{children}</div>
		) : (
			<ul className="list-disc list-inside">{children}</ul>
		)

	return (
		<div>
			{"name" in docElement && (
				<>
					<h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">{docElement.name}</h3>
				</>
			)}
			<Wrapper>{childrenElements}</Wrapper>
		</div>
	)
}
