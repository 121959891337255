import type { Relationship } from "../../core/application/gateways/relationships.gateway"
import type { Workspace } from "../../core/domain/Workspace.entity"

export function makeOpportunityUrlForRelationship(workspace: Workspace, relationship: Relationship): string | null {
	if (!workspace.crmIntegration?.instanceUrl || !relationship.opportunity?.crmId) {
		return null
	}

	const paths =
		workspace.crmIntegration.provider === "hubspot"
			? [workspace.crmIntegration.instanceUrl, "deal", relationship.opportunity.crmId]
			: [workspace.crmIntegration.instanceUrl, relationship.opportunity.crmId]

	return workspace.crmIntegration.instanceUrl.endsWith("/") ? paths.join("") : paths.join("/")
}

export function makeAccountUrlForRelationship(workspace: Workspace, relationship: Relationship): string | null {
	if (!workspace.crmIntegration?.instanceUrl || !relationship.account?.crmId) {
		return null
	}

	const paths =
		workspace.crmIntegration.provider === "hubspot"
			? [workspace.crmIntegration.instanceUrl, "company", relationship.account.crmId]
			: [workspace.crmIntegration.instanceUrl, relationship.account.crmId]
	return workspace.crmIntegration.instanceUrl.endsWith("/") ? paths.join("") : paths.join("/")
}
