import { PlusIcon } from "@heroicons/react/20/solid"
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline"
import React from "react"

import type { Team } from "../../../../../core/domain/Team.entity"
import { Button } from "../../../../components/design-system/Button.component"
import { Card } from "../../../../components/design-system/Card.component"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { useWorkspaceUsers } from "../../../../contexts/workspace-users.context"
import { EditTeamModal } from "./EditTeamModal.component"

type TeamEditionState = { type: "create" } | { type: "edit"; team: Team } | null

export function WorkspaceTeamsSettings() {
	const { t } = useLanguage()
	const { workspaceUsers, isLoading } = useWorkspaceUsers()
	const { teams, refetchMe } = useAuthenticatedSession()
	const [teamEditionState, setTeamEditionState] = React.useState<TeamEditionState>(null)
	const { teamsGateway } = useDependencies()

	const handleTeamDelete = async (team: Team) => {
		if (!window.confirm(t("Are you sure you want to delete the team {{teamName}}?", { teamName: team.name }))) {
			return
		}
		await teamsGateway.delete(team)
		await refetchMe()
	}

	if (isLoading || !workspaceUsers) {
		return (
			<div className="p-4 text-center text-gray-500">
				<SpinnerIcon className="h-8 w-8 mx-auto" />
			</div>
		)
	}

	return (
		<>
			<Card
				title={t("Teams ({{count}})", { count: teams.length })}
				CTA={
					<Button
						type="button"
						variant="primary"
						onClick={() => setTeamEditionState({ type: "create" })}
						eventName="Create new team clicked"
					>
						<PlusIcon className="h-5 w-5" aria-hidden="true" />
						{t("Create a new team")}
					</Button>
				}
			>
				<div className="px-6 pb-4">
					{teams.map((team) => {
						const teamMembers = workspaceUsers.filter((user) => team.memberIds.includes(user.id)).toSorted()
						const displayableMembers = teamMembers.map((member) => member.firstName).slice(0, 3)
						const displayableMembersCount = teamMembers.length - displayableMembers.length
						const hasMore = displayableMembersCount > 0
						return (
							<div className="mt-4 flex justify-between">
								<div className="">
									<TrackingButton
										className="flex items-center font-medium leading-6 text-gray-900 hover:text-navy-500"
										eventName="Edit team"
										onClick={() => setTeamEditionState({ type: "edit", team })}
									>
										{team.name}
										<PencilSquareIcon className="h-4 w-4 ml-1" />
									</TrackingButton>
									<p className="text-gray-500 text-sm">
										{teamMembers.length === 0 ? (
											t("No members.")
										) : (
											<>
												{displayableMembers.join(", ")}
												{hasMore
													? t(" and {{count}} other members.", {
															count: displayableMembersCount,
													  })
													: "."}
											</>
										)}
									</p>
								</div>
								<div>
									<TrackingButton
										eventName="Delete team"
										eventProperties={{
											teamId: team.id,
										}}
										onClick={() => handleTeamDelete(team)}
										type="button"
										className="text-red-600 hover:text-red-500 hover:bg-gray-50 px-4 py-2 group w-full flex rounded-lg"
									>
										<TrashIcon className="h-5 w-5" />
									</TrackingButton>
								</div>
							</div>
						)
					})}
					{teamEditionState?.type && (
						<EditTeamModal
							open={!!teamEditionState}
							type={teamEditionState.type}
							team={
								teamEditionState?.type === "create"
									? {
											id: "new",
											name: "",
											memberIds: [],
									  }
									: teamEditionState.team
							}
							onClose={() => setTeamEditionState(null)}
							onEditSuccess={() => {
								setTeamEditionState(null)
								refetchMe()
							}}
							currentWorkspaceUsers={workspaceUsers}
						/>
					)}
				</div>
			</Card>
		</>
	)
}
