import { z } from "zod"

// copied from https://www.npmjs.com/package/is-iso-dat
const isoDateRegExp = new RegExp(
	/(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/,
)
function isISODate(str: string): boolean {
	return isoDateRegExp.test(str)
}

export const ISODateTimeSchema = z
	.string()
	.refine(isISODate, { message: "Not a valid ISO string date" })
	.transform((date) => new Date(date))
