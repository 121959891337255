import "react-quill/dist/quill.snow.css" // import styles

import React, { type FormEvent } from "react"
import ReactQuill from "react-quill"

import { Modal } from "../../../../../components/design-system/Modal.component"
import { useLanguage } from "../../../../../contexts/language.context"

type TextContentModalProps = {
	open: boolean
	onClose: () => void
	onTextSubmit: (text: string) => void
}

export function TextContentModal({ open, onClose, onTextSubmit }: TextContentModalProps) {
	const [text, setText] = React.useState("")
	const { t } = useLanguage()

	const handleSubmit = React.useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			onTextSubmit(text)
		},
		[onTextSubmit, text],
	)

	React.useEffect(() => {
		return () => {
			setText("")
		}
	}, [open])

	return (
		<Modal open={open} onClose={onClose} showCloseButton={false}>
			<p className="text-lg font-semibold text-navy-900 mb-6">{t("Write your text content")}</p>

			<div className="h-full flex flex-col">
				<form onSubmit={handleSubmit}>
					<ReactQuill
						value={text}
						onChange={setText}
						className="resize-y overflow-auto h-64 min-h-[150px] max-h-[800px] border border-gray-100" // Tailwind classes for resizing
						theme="snow"
					/>

					<button
						type="submit"
						className="mt-4 bg-navy-700 hover:bg-navy-500 text-white font-semibold rounded-md px-3 py-1.5"
					>
						{t("Save text")}
					</button>
				</form>
			</div>
		</Modal>
	)
}
