import { useEffect, useState } from "react"

import type { TagType } from "../../core/domain/TagType.entity"
import { useAuthenticatedSession } from "../contexts/authentication.context"
import { useDependencies } from "../contexts/dependencies.context"

export function useAvailableCallTags() {
	const { tagTypesGateway } = useDependencies()
	const { workspace } = useAuthenticatedSession()
	const tagIdsToHide = workspace.props.preferences?.tagIdsToHide ?? []
	const [availableTags, setAvailableTags] = useState<TagType[] | "loading">("loading")

	useEffect(() => {
		tagTypesGateway.listTagTypes().then(setAvailableTags)
	}, [tagTypesGateway])

	return {
		availableTags:
			availableTags === "loading"
				? availableTags
				: availableTags.filter((tag) => !tagIdsToHide.includes(tag.properties.id)),
	}
}
