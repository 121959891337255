import { PlusIcon } from "@heroicons/react/20/solid"
import React from "react"
import { Trans } from "react-i18next"

import type { IUserUpdatePayload } from "../../../../../core/application/gateways/users.gateway/users.gateway"
import type { User, UserProperties } from "../../../../../core/domain/User.entity"
import { Alert } from "../../../../components/design-system/Alert.component"
import { Button } from "../../../../components/design-system/Button.component"
import { Card } from "../../../../components/design-system/Card.component"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { useAnalytics } from "../../../../contexts/analytics.context"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { type TranslationKey, useLanguage } from "../../../../contexts/language.context"
import { useWorkspaceUsers } from "../../../../contexts/workspace-users.context"
import { AdminUsersList } from "./AdminUsersList.component"
import { InviteUserModal } from "./InviteUserModal.component"

type UpdateState =
	| {
			status: "idle" | "error"
	  }
	| {
			status: "success"
			newRole: User["workspaceRole"]
			user: User
	  }

const roleTranslationKeyByRole: Record<UserProperties["workspaceRole"], TranslationKey> = {
	admin: "owner",
	manager: "manager",
	user: "member",
}

export function WorkspaceUsersSettings() {
	const { t } = useLanguage()
	const { workspaceUsers, isLoading, listWorkspaceUsers } = useWorkspaceUsers()
	const { user, workspace } = useAuthenticatedSession()
	const [isInviteUserModalOpen, setIsInviteUserModalOpen] = React.useState(false)
	const { usersGateway } = useDependencies()
	const { setupEventTracking } = useAnalytics()
	const [updateState, setUpdateState] = React.useState<UpdateState>({
		status: "idle",
	})

	const canInviteUser = user.isWorkspaceManager() && workspace.isAllowedToInviteUsers()

	const handleUpdateUser = React.useCallback(
		async (user: User, updatePayload: IUserUpdatePayload) => {
			try {
				setUpdateState({
					status: "idle",
				})
				const { trackEvent: trackWorkspaceUserUpdated } = setupEventTracking({
					eventName: "Workspace settings: user updated",
					eventProperties: {
						updatePayload,
						emailOfUserToUpdate: user.email,
					},
				})
				await usersGateway.updateWorkspaceUser(user.id, updatePayload)
				trackWorkspaceUserUpdated()
				await listWorkspaceUsers()
				setUpdateState({
					status: "success",
					user,
					newRole: updatePayload.role,
				})
			} catch (e) {
				setUpdateState({
					status: "error",
				})
				console.error(e)
			}
		},
		[listWorkspaceUsers, setupEventTracking, usersGateway],
	)

	if (isLoading || !workspaceUsers) {
		return (
			<div className="p-4 text-center text-gray-500">
				<SpinnerIcon className="h-8 w-8 mx-auto" />
			</div>
		)
	}

	return (
		<Card
			title={t("Users ({{count}})", { count: workspaceUsers.length })}
			CTA={
				canInviteUser ? (
					<Button
						type="button"
						variant="primary"
						onClick={() => setIsInviteUserModalOpen(true)}
						eventName="Invite user to workspace clicked"
					>
						<PlusIcon className="h-5 w-5" aria-hidden="true" />
						{t("Invite user")}
					</Button>
				) : undefined
			}
		>
			<div className="px-6 py-4">
				<InviteUserModal
					open={isInviteUserModalOpen}
					onClose={() => setIsInviteUserModalOpen(false)}
					onInviteSuccess={() => console.log("Invite success")}
					currentWorkspaceUsers={workspaceUsers}
				/>

				{updateState.status === "error" && (
					<div className="col-span-3">
						<Alert
							description={
								<Trans
									i18nKey="An error occurred while trying to update user. Please retry later or contact support at <1>{{email}}</1>."
									components={{
										1: (
											<a className=" text-sky font-semibold" href="mailto:support@rippletide.com">
												support@rippletide.com
											</a>
										),
									}}
									values={{ email: "support@rippletide.com" }}
								/>
							}
							variant="error"
						/>
					</div>
				)}

				{updateState.status === "success" && (
					<div className="col-span-3">
						<Alert
							description={t("{{fullName}} is now {{role}} of the workspace.", {
								fullName: updateState.user.getFullName(),
								role: t(roleTranslationKeyByRole[updateState.newRole]),
							})}
							variant="success"
						/>
					</div>
				)}

				<div className="mt-4">
					<AdminUsersList users={workspaceUsers} onUserUpdate={handleUpdateUser} />
				</div>
			</div>
		</Card>
	)
}
