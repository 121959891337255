import { Draggable } from "@hello-pangea/dnd"
import { TrashIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import React from "react"

import { Input } from "../../../../components/design-system/Input"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../../contexts/language.context"
import { DragHandle } from "../../../Training/Programs/components/DragHandle.component"

type SectionContentCardProps = {
	title: string
	description: string
	onDescriptionChange: (description: string) => void
	onTitleChange: (newTitle: string) => void
	onDelete: () => void
	index: number
}

function SectionEditMenu({ onDelete }: { onDelete: () => void }) {
	const { t } = useLanguage()

	const handleDelete = () => {
		if (confirm(t("Are you sure you want to delete this item?"))) {
			onDelete()
		}
	}

	return (
		<div className="relative inline-block text-left">
			<TrackingButton
				type="button"
				eventName="Template section edit: delete clicked"
				className="w-full text-center block text-sm text-red-500 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
				onClick={handleDelete}
			>
				<TrashIcon className="h-4 w-4 text-red-500" />
			</TrackingButton>
		</div>
	)
}

export function TemplateSectionCard({
	description,
	title,
	onDescriptionChange,
	onDelete,
	index,
	onTitleChange,
}: SectionContentCardProps) {
	const key = `section-${index}`
	const { t } = useLanguage()
	const [isTouched, setIsTouched] = React.useState(false)
	const [hasLeftFocus, setHasLeftFocus] = React.useState(false)

	return (
		<Draggable key={key} draggableId={key} index={index}>
			{(provided, snapshot) => (
				<div
					className={clsx(
						"border p-4 rounded-md group bg-white flex hover:border-gray-300 hover:shadow-sm mb-4",
						snapshot.isDragging ? "shadow-sm border-gray-300" : "border-gray-200",
					)}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={provided.draggableProps.style}
				>
					<DragHandle />
					<div className="flex flex-row flex-1 gap-2 items-center">
						<div className="flex flex-col flex-1 ml-2">
							<>
								<Input.Label htmlFor="title" className="block text-sm text-gray-600">
									{t("Title")}
								</Input.Label>
								<Input.Input
									name="title"
									id="title"
									onFocus={() => setIsTouched(true)}
									onBlur={() => setHasLeftFocus(true)}
									type="text"
									value={title}
									onChange={(event) => onTitleChange(event.target.value)}
									className={clsx(!title && isTouched && hasLeftFocus && "!ring-red-500", "!w-1/3")}
								/>
								<Input.Helper>{t("This title will be displayed in your meeting notes.")}</Input.Helper>
							</>

							<Input.Group>
								<Input.Label
									htmlFor="title"
									className={clsx(
										"block text-sm",
										!description && isTouched && hasLeftFocus ? "!text-red-500" : "text-gray-600",
									)}
								>
									{t("Description")}
								</Input.Label>
								<Input.TextArea
									id="description"
									name="description"
									onFocus={() => setIsTouched(true)}
									onBlur={() => setHasLeftFocus(true)}
									value={description}
									onChange={(event) => onDescriptionChange(event.target.value)}
									className={clsx(!description && isTouched && hasLeftFocus && "!ring-red-500")}
									placeholder={t(
										"E.g., 'Identify all the action items from the call and specify who each one should be assigned to. List them in bullet point form.'",
									)}
								/>
								<Input.Helper>
									{t(
										"Provide a brief description that explains what information should be provided in this section. This helps our AI identify the relevant information from your calls.",
									)}
								</Input.Helper>
							</Input.Group>
						</div>
						<SectionEditMenu onDelete={onDelete} />
					</div>
				</div>
			)}
		</Draggable>
	)
}
