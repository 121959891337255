import { z } from "zod"

import { subscriptionPropertiesSchema } from "../../../domain/Subscription.entity"
import { userPropertiesSchema } from "../../../domain/User.entity"
import {
	crmIntegrationSchema,
	type SupportedWorkspaceLanguageCode,
	workspaceDetailsSchema,
	workspacePreferencesSchema,
} from "../../../domain/Workspace.entity"
import { ISODateTimeSchema } from "../ISODateTime"

export const SUPPORTED_WORKSPACE_LANGUAGES = ["en", "fr", "es", "it"] as const
export type WorkspaceLanguage = (typeof SUPPORTED_WORKSPACE_LANGUAGES)[number]

type _StaticAssert1 = AssertAssignable<WorkspaceLanguage, SupportedWorkspaceLanguageCode>
type _StaticAssert2 = AssertAssignable<SupportedWorkspaceLanguageCode, WorkspaceLanguage>

const workspaceSchema = z.object({
	id: z.string(),
	name: z.string().optional(),
	crmIntegration: crmIntegrationSchema.optional(),
	featureFlags: z.object({
		callScoringAnalysis: z.boolean().optional(),
		displayFeatureAlternatives: z.boolean().optional(),
		forecasting: z.boolean().optional(),
	}),
	language: z.enum(SUPPORTED_WORKSPACE_LANGUAGES),
	plan: z
		.object({
			name: z.string(),
			features: z.record(z.boolean()),
		})
		.optional(),
	couponId: z.string().optional(),
	couponRedeemedAt: ISODateTimeSchema.optional(),
	details: workspaceDetailsSchema.optional(),
	preferences: workspacePreferencesSchema.optional(),
	companyLogoId: z.string().optional(),
	termsAcceptedAt: ISODateTimeSchema.optional(),
	termsAcceptedByUserWithEmail: z.string().optional(),
	defaultMeetingNotesTemplateId: z.string().optional(),
})

export const fetchMyWorkspaceSchema = z.object({
	workspace: workspaceSchema,
	user: userPropertiesSchema,
	subscription: subscriptionPropertiesSchema.nullish(),
	teams: z.array(
		z.object({
			id: z.string(),
			name: z.string(),
			memberIds: z.array(z.string()),
		}),
	),
})
