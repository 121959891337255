/**
 * @param currentPage >= 1
 * @param totalPages >= 0
 * @returns list of buttons to show
 */
export function generatePagination(currentPage: number, totalPages: number): (number | "...")[] {
	const maxButtons = 7
	const paginationList: (number | "...")[] = []

	if (totalPages <= maxButtons) {
		for (let i = 1; i <= totalPages; i++) {
			paginationList.push(i)
		}
		return paginationList
	}

	const halfWindow = Math.floor((maxButtons - 3) / 2)
	let startPage: number
	let endPage: number

	if (currentPage <= halfWindow + 1) {
		// Near the start
		startPage = 1
		endPage = maxButtons - 2 // Reserve for the last page and one ellipsis
		for (let i = startPage; i <= endPage; i++) {
			paginationList.push(i)
		}
		paginationList.push("...", totalPages)
	} else if (currentPage >= totalPages - halfWindow - 1) {
		// Near the end
		startPage = totalPages - maxButtons + 2 // Reserve for the first page and one ellipsis
		paginationList.push(1, "...")
		for (let i = startPage; i <= totalPages; i++) {
			paginationList.push(i)
		}
	} else {
		// In the middle
		startPage = currentPage - halfWindow
		endPage = currentPage + halfWindow
		paginationList.push(1, "...")
		for (let i = startPage; i <= endPage; i++) {
			paginationList.push(i)
		}
		if (endPage < totalPages) {
			paginationList.push("...", totalPages)
		}
	}

	return paginationList
}
