import React from "react"
import screenfull from "screenfull"

export function useFullscreenStatus() {
	const [isFullscreen, setIsFullscreen] = React.useState(false)

	const handleFullscreenChange = () => {
		setIsFullscreen(screenfull.isFullscreen)
	}

	React.useEffect(() => {
		if (!screenfull.isEnabled) {
			return
		}

		screenfull.on("change", handleFullscreenChange)

		return () => {
			screenfull.off("change", handleFullscreenChange)
		}
	}, [])

	return isFullscreen
}
