import type { LeadKnowledgeV2 } from "../../../../../../../core/domain/Call.entity"
import { AccountSection } from "./AccountSection.component"
import { ContactsSection } from "./ContactsSection.component"
import { OpportunitySection } from "./OpportunitySection.component"
import { ReactionsSection } from "./ReactionsSection.component"

export type _LeadKnowledgeV2PanelProps = {
	leadKnowledgeV2: LeadKnowledgeV2
}

export function _LeadKnowledgeV2Panel({ leadKnowledgeV2 }: _LeadKnowledgeV2PanelProps) {
	return (
		<div className="flex flex-col space-y-4">
			{leadKnowledgeV2.account && <AccountSection account={leadKnowledgeV2.account} />}
			{leadKnowledgeV2.contacts && <ContactsSection contacts={leadKnowledgeV2.contacts} />}
			{leadKnowledgeV2.opportunity && <OpportunitySection opportunity={leadKnowledgeV2.opportunity} />}
			{leadKnowledgeV2.reactions && <ReactionsSection reactions={leadKnowledgeV2.reactions} />}
		</div>
	)
}
