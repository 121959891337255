import React from "react"

import { useCalendarIntegration } from "../hooks/useCalendarIntegration"

type ICalendarContextProps = ReturnType<typeof useCalendarIntegration>

const CalendarContext = React.createContext<ICalendarContextProps | undefined>(undefined)

export function CalendarProvider({ children }: { children: React.ReactNode }) {
	const calendarIntegration = useCalendarIntegration()

	return <CalendarContext.Provider value={calendarIntegration}>{children}</CalendarContext.Provider>
}

export function useCalendarContext() {
	const context = React.useContext(CalendarContext)
	if (!context) {
		throw new Error("useCalendarContext must be used within a CalendarProvider")
	}
	return context
}
