import { DragDropContext, Droppable, type DropResult } from "@hello-pangea/dnd"
import { PlusIcon } from "@heroicons/react/20/solid"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import type { TrainingProgramItemContent } from "../../../../../core/application/gateways/training.gateway/schemas/training-program-items.schema"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { makeEditTrainingProgramPath } from "../../../../router/Router"
import { reorder } from "../../../../utils/array"
import { EditTrainingProgramLayout } from "../components/EditTrainingProgramLayout.component"
import { useTrainingProgram } from "../contexts/training-program.context"
import { CurrentSectionEditForm } from "./components/CurrentSectionEditForm.component"
import { TrainingSectionCard } from "./components/TrainingSectionCard.component"

type FormStatus = { status: "idle" } | { status: "loading" } | { status: "success" } | { status: "error" }

export type DraftTrainingSection = {
	name: string
	description: string
	id: string | undefined
	items: Array<
		TrainingProgramItemContent & {
			description?: string
		}
	>
}

function useTrainingSections() {
	const { t } = useLanguage()
	const { programId } = useTrainingProgram()
	const [currentTrainingSectionIndex, setCurrentTrainingSectionIndex] = useState(0)
	const [trainingSections, setTrainingSections] = useState<DraftTrainingSection[]>([
		{
			name: t("Draft section {{count}}", {
				count: 1,
			}),
			description: "",
			items: [],
			id: undefined,
		},
	])

	const { trainingGateway } = useDependencies()

	useEffect(() => {
		trainingGateway.getTrainingProgram(programId).then((program) => {
			setTrainingSections((prevSections) => {
				if (program.sections.length === 0) {
					return prevSections
				}

				return program.sections.map((section) => ({
					id: section.id,
					name: section.name,
					description: section.description,
					items: section.items.map((item) => ({
						...item.content,
						description: item.description,
					})),
				}))
			})
		})
	}, [programId, trainingGateway])

	const currentTrainingSection = trainingSections[currentTrainingSectionIndex]

	const onSectionChange = useCallback(
		(section: DraftTrainingSection) => {
			setTrainingSections((prev) => {
				const newSections = [...prev]
				newSections[currentTrainingSectionIndex] = section
				return newSections
			})
		},
		[currentTrainingSectionIndex],
	)

	const onAddSection = useCallback(() => {
		const nextSectionIndex = trainingSections.length
		setTrainingSections((prev) => [
			...prev,
			{
				name: t("Draft section {{count}}", {
					count: nextSectionIndex + 1,
				}),
				description: "",
				items: [],
				id: undefined,
			},
		])
		setCurrentTrainingSectionIndex(nextSectionIndex)
	}, [t, trainingSections.length])

	const onRemoveSection = useCallback(
		(index: number) => {
			if (!confirm(t("Are you sure you want to delete this item?"))) {
				return
			}

			if (trainingSections.length === 1) {
				return
			}

			if (index === currentTrainingSectionIndex) {
				setCurrentTrainingSectionIndex(0)
			}

			if (currentTrainingSectionIndex > index) {
				setCurrentTrainingSectionIndex((prev) => prev - 1)
			}

			setTrainingSections((prev) => {
				const newSections = [...prev]
				newSections.splice(index, 1)
				return newSections
			})
		},
		[currentTrainingSectionIndex, t, trainingSections.length],
	)

	const onSelectSection = useCallback((index: number) => {
		setCurrentTrainingSectionIndex(index)
	}, [])

	const onReorderSections = useCallback(
		(sourceIndex: number, destinationIndex: number) => {
			const currentSection = trainingSections[currentTrainingSectionIndex]
			const reordered = reorder(trainingSections, sourceIndex, destinationIndex)
			const newCurrentIndex = reordered.findIndex((x) => x === currentSection)
			setCurrentTrainingSectionIndex(newCurrentIndex)
			setTrainingSections(reordered)
		},
		[currentTrainingSectionIndex, trainingSections],
	)

	return {
		trainingSections,
		currentTrainingSection,
		onSectionChange,
		onAddSection,
		onRemoveSection,
		onSelectSection,
		onReorderSections,
	}
}

export function EditContentPage() {
	const {
		currentTrainingSection,
		onSectionChange,
		trainingSections,
		onAddSection,
		onSelectSection,
		onRemoveSection,
		onReorderSections,
	} = useTrainingSections()
	const { t } = useLanguage()
	const { trainingGateway } = useDependencies()
	const { programId } = useTrainingProgram()
	const navigate = useNavigate()

	const [formStatus, setFormStatus] = useState<FormStatus>({ status: "idle" })

	const onSubmit = async () => {
		try {
			setFormStatus({ status: "loading" })
			await trainingGateway.updateTrainingProgram(programId, {
				type: "content",
				sections: trainingSections
					.filter((x) => x.items.length)
					.map((section) => ({
						id: section.id,
						name: section.name,
						description: section.description,
						items: section.items.map((item) => {
							if (item.type === "highlight") {
								return {
									description: item.description ?? "",
									content: {
										type: "highlight",
										highlightId: item.highlight.id,
									},
								}
							}

							return {
								description: item.description ?? "",
								content: item,
							}
						}),
					})),
			})
			navigate(makeEditTrainingProgramPath(programId, "enrolments"))
		} catch (e) {
			setFormStatus({ status: "error" })
			alert("Failed to save")
		}
	}

	const handleDragEnd = React.useCallback(
		(result: DropResult) => {
			if (!result.destination) {
				return
			}

			onReorderSections(result.source.index, result.destination.index)
		},
		[onReorderSections],
	)

	const isFormDisabled = formStatus.status === "loading" || formStatus.status === "success"

	return (
		<EditTrainingProgramLayout currentStep="content" onSaveClick={onSubmit} isSaveDisabled={isFormDisabled}>
			<div className="mt-6 flex gap-6">
				<div className="flex-1 border border-gray-200 shadow-sm rounded-md p-6 h-[75vh] overflow-y-auto bg-white">
					<p className="text-lg font-semibold text-navy-900 mb-6">{t("Your training content")}</p>

					<div className="flex flex-col gap-4">
						<DragDropContext onDragEnd={handleDragEnd}>
							<Droppable droppableId="droppable" type="group">
								{(provided) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										{trainingSections.map((section, idx) => (
											<TrainingSectionCard
												key={section.name}
												trainingSection={section}
												trainingIdx={idx}
												isSelected={currentTrainingSection === section}
												onTrainingSectionEdit={() => onSelectSection(idx)}
												onTrainingSectionDelete={() => onRemoveSection(idx)}
											/>
										))}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</div>

					<div className="flex items-center justify-center mt-4">
						<TrackingButton
							type="button"
							onClick={onAddSection}
							eventName="Add section button clicked"
							className="border border-gray-400 rounded-full p-2 hover:bg-indigo-50 hover:text-indigo-500"
						>
							<PlusIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
						</TrackingButton>
					</div>
				</div>
				<div className="flex-[2] border border-gray-200 shadow-sm rounded-md p-6 h-[75vh] overflow-y-auto bg-white">
					<CurrentSectionEditForm trainingSection={currentTrainingSection} onChange={onSectionChange} />
				</div>
			</div>
		</EditTrainingProgramLayout>
	)
}
