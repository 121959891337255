const labelByDecisionMakingAuthority: Record<"high" | "medium" | "low", string> = {
	high: "Decision Maker",
	medium: "Influencer",
	low: "Not a Decision Maker",
}

export function DecisionMakingAuthorityBadge({
	decisionMakingAuthority,
}: {
	decisionMakingAuthority: "high" | "medium" | "low"
}) {
	const classNameByDecisionMakingAuthority: Record<"high" | "medium" | "low", string> = {
		high: "bg-green-50 text-green-700 ring-green-600/20",
		medium: "bg-yellow-50 text-yellow-700 ring-yellow-600/20",
		low: "bg-gray-50 text-gray-700 ring-gray-600/20",
	}
	return (
		<span
			className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ${classNameByDecisionMakingAuthority[decisionMakingAuthority]}`}
		>
			{labelByDecisionMakingAuthority[decisionMakingAuthority]}
		</span>
	)
}
