import React from "react"

export function ForwardIcon(props: { className?: string }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			stroke="currentColor"
			strokeWidth={3}
			viewBox="0 0 64 64"
			{...props}
		>
			<path strokeLinecap="round" d="M23.93 41.41V23a.09.09 0 0 0-.16-.07s-2.58 3.69-4.17 4.78" />
			<rect width={11.41} height={18.89} x={29.19} y={22.52} rx={5.7} />
			<path strokeLinecap="round" d="m54.43 15.41-2.6 8.64-8.64-2.61" />
			<path strokeLinecap="round" d="M51.86 23.94a21.91 21.91 0 1 0 .91 13.25" />
		</svg>
	)
}
