import { VideoCameraSlashIcon } from "@heroicons/react/24/outline"

import type { Call } from "../../../../../core/domain/Call.entity"
import { AudioPlayer } from "../../../../components/design-system/AudioPlayer.component"
import { useLanguage } from "../../../../contexts/language.context"

export type _AudioFallbackProps = {
	call: Call
}

export function _AudioFallback({ call }: _AudioFallbackProps) {
	const { t } = useLanguage()
	return (
		<div className="flex flex-col items-center w-full rounded-lg border border-gray-200 p-12 text-center">
			<VideoCameraSlashIcon className="mt-4 mx-auto h-12 w-12 text-gray-400" aria-hidden="true" />
			<span className="mt-2 block text-sm font-semibold text-gray-900">{t("No video found for this call.")}</span>
			<div className="mt-10">
				<AudioPlayer audioFileUrl={call.props.audioFileUrl} mimeType={call.props.audioMimeType} />
			</div>
		</div>
	)
}
