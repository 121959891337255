import {
	HandThumbDownIcon as HandThumbDownIconOutline,
	HandThumbUpIcon as HandThumbUpOutline,
} from "@heroicons/react/24/outline"
import {
	HandThumbDownIcon as HandThumbDownIconSolid,
	HandThumbUpIcon as HandThumbUpSolid,
} from "@heroicons/react/24/solid"
import { useState } from "react"

import type { CallHighlight } from "../../../../../../core/domain/Library.entity"
import { TrackingButton } from "../../../../../components/design-system/TrackingButton.component"
import { useDependencies } from "../../../../../contexts/dependencies.context"

type ReactionButtonsProps = {
	highlightId: string
	reactions: CallHighlight["reactions"]
}

export function HighlightReactionButtons({ highlightId, reactions }: ReactionButtonsProps) {
	const [isThumbsUpAnimating, setIsThumbsUpAnimating] = useState(false)
	const [isThumbsDownAnimating, setIsThumbsDownAnimating] = useState(false)
	const [reactionType, setReactionType] = useState(reactions.userReaction)

	const { libraryFoldersGateway } = useDependencies()

	const handleReaction = async (type: "thumbsUp" | "thumbsDown") => {
		let newReactionType: "thumbsUp" | "thumbsDown" | undefined

		if (type === reactionType) {
			newReactionType = undefined
		} else if (type === "thumbsUp") {
			setIsThumbsUpAnimating(true) // Trigger animation
			setTimeout(() => setIsThumbsUpAnimating(false), 150) // Reset animation state
			newReactionType = "thumbsUp"
		} else {
			setIsThumbsDownAnimating(true) // Trigger animation
			setTimeout(() => setIsThumbsDownAnimating(false), 150) // Reset animation state
			newReactionType = "thumbsDown"
		}

		setReactionType(newReactionType)

		await libraryFoldersGateway.reactToHighlight({
			highlightId,
			reactionType: newReactionType,
		})
	}

	const ThumbsUpIcon = reactionType === "thumbsUp" ? HandThumbUpSolid : HandThumbUpOutline
	const ThumbsDownIcon = reactionType === "thumbsDown" ? HandThumbDownIconSolid : HandThumbDownIconOutline

	return (
		<div className="flex space-x-2">
			<TrackingButton
				eventName="Thumbs up clicked"
				className={`p-2 rounded-full ${isThumbsUpAnimating ? "scale-125" : "scale-100"} transition-transform`}
				onClick={() => handleReaction("thumbsUp")}
			>
				<ThumbsUpIcon
					className={`h-6 w-6 ${reactionType === "thumbsUp" ? "text-blue-500" : "text-gray-400"}`}
				/>
			</TrackingButton>
			<TrackingButton
				eventName="Thumbs down clicked"
				onClick={() => handleReaction("thumbsDown")}
				className={`p-2 rounded-full ${isThumbsDownAnimating ? "scale-125" : "scale-100"} transition-transform`}
			>
				<ThumbsDownIcon
					className={`h-6 w-6 ${reactionType === "thumbsDown" ? "text-blue-500" : "text-gray-400"}`}
				/>
			</TrackingButton>
		</div>
	)
}
