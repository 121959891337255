import { Radio, RadioGroup } from "@headlessui/react"
import { CheckIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import _ from "lodash"
import React from "react"
import { useSearchParams } from "react-router-dom"

import type { BillingPeriod, PlanDetails } from "../../../../../core/application/gateways/billing.gateway"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingExternalHref } from "../../../../components/design-system/TrackingExternalHref.component"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { formatPrice } from "../../../../utils/currency"
import { billingPeriodQueryParam, purchasePlanQueryParam } from "../../config"

type PricingTableProps = {
	currentPlanId: string | null
	plans: PlanDetails[]
	isInTrial: boolean
	hasPaymentMethod: boolean
}

function isPlanFeatured(plan: PlanDetails) {
	return plan.isEnterprisePlan
}

const enterprisePlanMarketingFeatures = [
	"Unlimited call recording & transcription",
	"Call summary & insights",
	"Call preparation briefs",
	"Calendar integration (Google or Microsoft Teams)",
	"CRM integration (Salesforce)",
	"Enhanced prospect insights",
	"Sales training library",
	"Sales coaching",
	"Smart insights into your prospects & performance",
	"Enterprise features",
]

export function PricingTable({ plans, currentPlanId, isInTrial, hasPaymentMethod }: PricingTableProps) {
	const { billingGateway } = useDependencies()
	const [searchParams, setSearchParams] = useSearchParams()
	const planToPurchaseFromUrl = searchParams.get(purchasePlanQueryParam)
	const billingPeriodFromUrl = searchParams.get(billingPeriodQueryParam)

	const [isPurchasingPlan, setIsPurchasingPlan] = React.useState(false)
	const { t, language } = useLanguage()

	const [billingPeriod, setBillingPeriod] = React.useState<BillingPeriod>("yearly")

	const handleBuyPlan = React.useCallback(
		async (planId: string, period: BillingPeriod) => {
			try {
				setIsPurchasingPlan(true)
				const billing = await billingGateway.getBillingInfo()
				const isUpgradingFromTrialToPaid = isInTrial && planId === billing.plan?.id

				if (planId === billing.plan?.id && !isUpgradingFromTrialToPaid) {
					setIsPurchasingPlan(false)
					return
				}

				if (!billing || !billing.plan) {
					const checkoutSession = await billingGateway.createCheckoutSession(planId, period)
					window.location.href = checkoutSession.sessionUrl
					return
				}

				const hasPaymentMethod = billing.subscription?.hasPaymentMethod
				if (!hasPaymentMethod) {
					const paymentMethodUpdateSession = await billingGateway.createPaymentMethodUpdatePortalSession(
						planId,
						period,
					)
					window.location.href = paymentMethodUpdateSession.sessionUrl
					return
				}

				if (isUpgradingFromTrialToPaid) {
					throw new Error(
						"User has set up a payment method, is still in trial and is trying to purchase the same plan that they already have. This should never happen",
					)
				}

				const subscriptionUpdateSession = await billingGateway.createSubscriptionUpdateConfirmPortalSession(
					planId,
					period,
				)
				window.location.href = subscriptionUpdateSession.sessionUrl
			} catch (e) {
				console.error(e)
				setIsPurchasingPlan(false)
			}
		},
		[billingGateway, isInTrial],
	)

	React.useEffect(() => {
		if (planToPurchaseFromUrl) {
			const desiredBillingPeriod = billingPeriodFromUrl === "monthly" ? "monthly" : "yearly"
			handleBuyPlan(planToPurchaseFromUrl, desiredBillingPeriod).catch(console.error)
			const newSearchParams = new URLSearchParams(searchParams)
			newSearchParams.delete(purchasePlanQueryParam)
			newSearchParams.delete(billingPeriodQueryParam)
			setSearchParams(newSearchParams)
		}
	}, [billingPeriodFromUrl, handleBuyPlan, planToPurchaseFromUrl, searchParams, setSearchParams])

	if (planToPurchaseFromUrl) {
		return (
			<div className="flex items-center justify-center">
				<SpinnerIcon className="w-10 h-10 text-gray-400 animate-spin" />
			</div>
		)
	}

	const sortedPlans = _.sortBy(plans, ["yearlyPrice.priceUnit", "isEnterprise"])

	return (
		<>
			<div className="mt-16 flex justify-center">
				<fieldset aria-label="Payment frequency">
					<RadioGroup
						value={billingPeriod}
						onChange={setBillingPeriod}
						className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-md font-semibold leading-5 ring-1 ring-inset ring-gray-200"
					>
						<Radio
							value="yearly"
							className="cursor-pointer rounded-full px-2.5 py-1 text-gray-500 data-[checked]:bg-navy-700 data-[checked]:text-white"
						>
							{t("Annually")}
						</Radio>
						<Radio
							value="monthly"
							className="cursor-pointer rounded-full px-2.5 py-1 text-gray-500 data-[checked]:bg-navy-700 data-[checked]:text-white"
						>
							{t("Monthly")}
						</Radio>
					</RadioGroup>
				</fieldset>
			</div>
			<div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-4">
				{sortedPlans.map((plan) => {
					// If a user is in trial and have the same plan as the one they are in trial with, we show it as the current plan if they have a payment method
					// Because this means that at the end of the trial, they will be charged for this plan.
					const isCurrentPlan = currentPlanId === plan.id && (!isInTrial || hasPaymentMethod)
					const selectedPrice = billingPeriod === "yearly" ? plan.yearlyPrice : plan.monthlyPrice
					const unitPricePerMonth =
						billingPeriod === "yearly" && selectedPrice?.priceUnit
							? selectedPrice.priceUnit / 12
							: selectedPrice?.priceUnit

					const comparisonPrice = billingPeriod === "yearly" ? plan.monthlyPrice : null

					return (
						<div
							key={plan.id}
							className={clsx(
								isPlanFeatured(plan) ? "bg-gray-900 ring-gray-900" : "ring-gray-200",
								"rounded-3xl p-4 ring-1 xl:p-4",
							)}
						>
							<h3
								id={plan.id}
								className={clsx(
									isPlanFeatured(plan) ? "text-white" : "text-gray-900",
									"text-xl font-semibold leading-8",
								)}
							>
								{plan.name}
							</h3>
							<p className="mt-6 flex items-baseline gap-x-1">
								{comparisonPrice?.priceUnit && (
									<span className="text-md font-semibold leading-6 text-gray-600 line-through">
										{`${formatPrice(Math.round(comparisonPrice.priceUnit / 100), language)}`}
									</span>
								)}
								<span
									className={clsx(
										isPlanFeatured(plan) ? "text-white" : "text-gray-900",
										"text-2xl font-bold tracking-tight",
									)}
								>
									{unitPricePerMonth
										? `${formatPrice(Math.round(unitPricePerMonth / 100), language)}`
										: t("Contact us")}
								</span>
								{!plan.isEnterprisePlan && (
									<div className="flex flex-col">
										<span className="text-xs font-semibold leading-6 text-gray-600">
											{t("/user /month")}
										</span>
									</div>
								)}
							</p>

							{billingPeriod === "yearly" && !plan.isEnterprisePlan && (
								<span className="text-sm leading-6 text-gray-400">{t("billed annually")}</span>
							)}
							{plan.isEnterprisePlan ? (
								<TrackingExternalHref
									eventName="Pricing table: contact us clicked"
									eventProperties={{
										planId: plan.id,
										planName: plan.name,
									}}
									aria-describedby={plan.id}
									href="https://www.rippletide.com/contact"
									className={clsx(
										isPlanFeatured(plan)
											? "bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white"
											: "bg-navy-700 text-white shadow-sm hover:bg-navy-500 focus-visible:outline-indigo-600",
										"mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
										"disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-300 disabled:cursor-not-allowed",
									)}
								>
									{t("Contact us")}
								</TrackingExternalHref>
							) : (
								<TrackingButton
									eventName="Pricing table: buy plan clicked"
									eventProperties={{
										planId: plan.id,
										planName: plan.name,
										priceUnit: plan.yearlyPrice?.priceUnit, // TODO: change depending on the selected billing cycle
									}}
									onClick={() => handleBuyPlan(plan.id, billingPeriod)}
									aria-describedby={plan.id}
									disabled={isCurrentPlan || isPurchasingPlan}
									className={clsx(
										isPlanFeatured(plan)
											? "bg-white/10 text-white hover:bg-white/20 focus-visible:outline-white"
											: "bg-navy-700 text-white shadow-sm hover:bg-navy-500 focus-visible:outline-indigo-600",
										"mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
										"disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-300 disabled:cursor-not-allowed",
									)}
								>
									{isPurchasingPlan
										? t("Processing…")
										: isCurrentPlan
										? t("Your plan")
										: t("Select plan")}
								</TrackingButton>
							)}
							<ul
								role="list"
								className={clsx(
									isPlanFeatured(plan) ? "text-gray-300" : "text-gray-600",
									"mt-8 space-y-3 text-sm leading-6 xl:mt-10",
								)}
							>
								{(plan.isEnterprisePlan
									? enterprisePlanMarketingFeatures
									: plan.marketingFeatures
								)?.map((feature) => (
									<li key={feature} className="flex gap-x-3">
										<CheckIcon
											className={clsx(
												isPlanFeatured(plan) ? "text-white" : "text-indigo-600",
												"h-6 w-5 flex-none",
											)}
											aria-hidden="true"
										/>
										{feature}
									</li>
								))}
							</ul>
						</div>
					)
				})}
			</div>
		</>
	)
}
