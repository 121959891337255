import clsx from "clsx"
import React from "react"

import type { CallInsightsResponse } from "../../../core/application/gateways/insights.gateway"
import type { User } from "../../../core/domain/User.entity"
import { Skeleton } from "../../components/design-system/Skeleton.component"
import { SectionCard } from "../../components/shared/GraphSection/SectionCard.component"
import { Layout } from "../../components/shared/Layout/Layout.component"
import { useAnalytics } from "../../contexts/analytics.context"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useDependencies } from "../../contexts/dependencies.context"
import { type TranslationKey, useLanguage } from "../../contexts/language.context"
import { useWorkspaceUsers } from "../../contexts/workspace-users.context"
import { CallActivityStatsSection } from "./components/CallActivityStatsSection.component"
import { CallProgressionChart } from "./components/CallProgressionChart.component"
import { InsightsFilters, type TimePeriod } from "./components/InsightsFilters.component"
import { MarketPracticeTips } from "./components/MarketPracticeTips.component"
import { TeamCallsChart } from "./components/TeamCallsChart.component"
import type { InteractionChartType } from "./types"

type InsightTab = "call-activity" | "interaction"

const tabs: Array<{ name: TranslationKey; id: InsightTab; disabled?: boolean }> = [
	{ name: "Call activity", id: "call-activity" },
	{ name: "Interaction", id: "interaction" },
]

type IInsightsTabMenuProps = {
	currentTab: InsightTab
	handleTabChange: (tab: InsightTab) => void
	workspaceUsers: User[] | null
	userId: string | null
	onSelectUser: (userId: string) => void
	timePeriod: TimePeriod
	onTimePeriodChange: (timePeriod: TimePeriod) => void
}

function InsightsTabMenu({
	currentTab,
	handleTabChange,
	onTimePeriodChange,
	timePeriod,
	workspaceUsers,
	userId,
	onSelectUser,
}: IInsightsTabMenuProps) {
	const { t } = useLanguage()
	return (
		<div>
			<div className="sm:hidden">
				<label htmlFor="tabs" className="sr-only">
					Select a tab
				</label>
				<select
					id="tabs"
					name="tabs"
					defaultValue={tabs[0].name}
					className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-navy-500 focus:outline-none focus:ring-navy-500 sm:text-sm"
					onChange={(e) => handleTabChange(e.target.value as InsightTab)}
				>
					{tabs.map((tab) => (
						<option key={tab.name} disabled={tab.disabled}>
							{t(tab.name)} {tab.disabled ? t("(coming soon)") : ""}
						</option>
					))}
				</select>
				<InsightsFilters
					workspaceUsers={workspaceUsers}
					userId={userId}
					onSelectUser={onSelectUser}
					timePeriod={timePeriod}
					onTimePeriodChange={onTimePeriodChange}
				/>
			</div>
			<div className="hidden sm:block">
				<div className="border-b border-gray-200 flex justify-between items-end">
					<nav aria-label="Tabs" className="-mb-px flex space-x-8">
						{tabs.map((tab) => (
							<button
								key={tab.name}
								aria-current={currentTab === tab.id ? "page" : undefined}
								className={clsx(
									currentTab === tab.id
										? "border-navy-500 text-navy-600"
										: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
									"whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium",
									"disabled:opacity-50 disabled:cursor-not-allowed",
								)}
								disabled={tab.disabled}
								onClick={() => handleTabChange(tab.id)}
							>
								{t(tab.name)} {tab.disabled ? t("(coming soon)") : ""}
							</button>
						))}
					</nav>
					<InsightsFilters
						workspaceUsers={workspaceUsers}
						userId={userId}
						onSelectUser={onSelectUser}
						timePeriod={timePeriod}
						onTimePeriodChange={onTimePeriodChange}
					/>
				</div>
			</div>
		</div>
	)
}

export function InsightsDashboard() {
	const { t } = useLanguage()
	const { insightsGateway } = useDependencies()
	const { workspace } = useAuthenticatedSession()
	const [currentTab, setCurrentTab] = React.useState<InsightTab>("call-activity")
	const [insights, setInsights] = React.useState<CallInsightsResponse | null>(null)
	const { workspaceUsers } = useWorkspaceUsers()
	const [selectedUserId, setSelectedUserId] = React.useState<string | null>(null)
	const [selectedTimePeriod, setSelectedTimePeriod] = React.useState<TimePeriod>("last-30-days")
	const [isLoading, setIsLoading] = React.useState(true)
	const [interactionChartType, setInteractionChartType] = React.useState<InteractionChartType>("average-calls")
	const { setupEventTracking } = useAnalytics()

	React.useEffect(() => {
		async function fetchInsights() {
			const insightsResponse = await insightsGateway.getCallsInsights({
				timeRange: selectedTimePeriod,
			})

			setInsights(insightsResponse)
		}

		setIsLoading(true)
		fetchInsights().finally(() => setIsLoading(false))
	}, [insightsGateway, selectedTimePeriod])

	const handleTabChange = React.useCallback(
		(tab: InsightTab) => {
			setupEventTracking({
				eventName: "Insights: Tab Change",
				eventProperties: {
					tab,
				},
			}).trackEvent()

			setCurrentTab(tab)
			if (tab === "interaction") {
				setInteractionChartType(
					workspace.isIntesciaWorkspace() ? "average-call-score" : "average-speaking-ratio",
				)
			} else {
				setInteractionChartType("average-calls")
			}
		},
		[setupEventTracking, workspace],
	)

	const handleSelectUser = React.useCallback(
		(userId: string) => {
			setupEventTracking({
				eventName: "Insights: user filter updated",
				eventProperties: {
					userId,
				},
			}).trackEvent()

			setSelectedUserId(userId)
		},
		[setupEventTracking],
	)

	const handleSelectTimePeriod = React.useCallback(
		(timePeriod: TimePeriod) => {
			setupEventTracking({
				eventName: "Insights: time filter period updated",
				eventProperties: {
					timePeriod,
				},
			}).trackEvent()

			setSelectedTimePeriod(timePeriod)
		},
		[setupEventTracking],
	)

	return (
		<Layout pageName={t("Analytics")} isFluid className="!pt-2">
			<InsightsTabMenu
				currentTab={currentTab}
				handleTabChange={handleTabChange}
				workspaceUsers={workspaceUsers}
				userId={selectedUserId}
				onSelectUser={handleSelectUser}
				timePeriod={selectedTimePeriod}
				onTimePeriodChange={handleSelectTimePeriod}
			/>
			{!insights ||
				(isLoading && (
					<div className="flex flex-row gap-4 items-end mt-6">
						<div className="w-[220px] group gap-4 flex flex-col">
							<Skeleton size="S" />
							<Skeleton size="M" />
						</div>
						<div className="w-[220px] group gap-4 flex flex-col">
							<Skeleton size="S" />
							<Skeleton size="M" />
						</div>
					</div>
				))}

			<div className="mt-6">
				{insights && !isLoading ? (
					<>
						<>
							<div className="grid grid-cols-5 gap-4">
								<div className="col-span-5 lg:col-span-5">
									<CallActivityStatsSection
										callInsights={insights}
										selectedUserId={selectedUserId ?? undefined}
										onInteractionTypeChange={(type) => setInteractionChartType(type)}
										selectedInsightType={interactionChartType}
										selectedTab={currentTab}
									/>
								</div>
								<div className="col-span-5 lg:col-span-5">
									<MarketPracticeTips type={interactionChartType} />
								</div>
							</div>
							<div className="grid grid-cols-5 gap-4 mt-4">
								<div className="col-span-5 lg:col-span-3">
									<CallProgressionChart
										insights={insights}
										selectedUserId={selectedUserId ?? undefined}
										type={interactionChartType}
									/>
								</div>
								<div className="col-span-5 lg:col-span-2">
									<TeamCallsChart
										insights={insights}
										selectedUserId={selectedUserId ?? undefined}
										type={interactionChartType}
									/>
								</div>
							</div>
						</>
					</>
				) : (
					<div>
						<div className="flex flex-row gap-4">
							<SectionCard className="w-[220px] group gap-10 flex flex-col">
								<Skeleton size="M" />
								<Skeleton size="S" />
							</SectionCard>
							<SectionCard className="w-[220px] group gap-10 flex flex-col">
								<Skeleton size="M" />
								<Skeleton size="S" />
							</SectionCard>
							<SectionCard className="w-[220px] group gap-10 flex flex-col">
								<Skeleton size="M" />
								<Skeleton size="S" />
							</SectionCard>
						</div>
						<div className="flex gap-4 mt-4">
							<div className="flex-[3]">
								<SectionCard className="h-[450px] gap-6 flex flex-col">
									<Skeleton size="M" />
									<Skeleton size="XL" />
								</SectionCard>
							</div>
							<div className="flex-[2]">
								<SectionCard className="h-[450px] gap-6 flex flex-col">
									<Skeleton size="M" />
									<Skeleton size="XL" />
								</SectionCard>
							</div>
						</div>
					</div>
				)}
			</div>
		</Layout>
	)
}
