import { z } from "zod"

import { LibraryFolderTags } from "../../../domain/Library.entity"

const libraryFolderTagSchema = z.enum(LibraryFolderTags)
const libraryFolderTagTolerantSchema = z.string().transform((tag) => {
	try {
		return libraryFolderTagSchema.parse(tag)
	} catch {
		// Be tolerant to future tags
		return "__unknown__"
	}
})

export const apiLibraryFolderSchema = z
	.object({
		id: z.string(),
		name: z.string(),
		parentLibraryFolderId: z.string().optional(),
		userId: z.string().optional(),
		isWorkspaceRoot: z.boolean(),
		isUserRoot: z.boolean(),
		tags: z.array(libraryFolderTagTolerantSchema),
		callHighlightCount: z.number().int().nonnegative(),
	})
	// https://stackoverflow.com/questions/71477015/specify-a-zod-schema-with-a-non-optional-but-possibly-undefined-field
	.transform((data) => ({
		...data,
		parentLibraryFolderId: data.parentLibraryFolderId,
	}))
export type ApiLibraryFolder = z.infer<typeof apiLibraryFolderSchema>
