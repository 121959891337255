import { UsersIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"

import type { Call } from "../../../core/domain/Call.entity"
import { TrackingExternalHref } from "../design-system/TrackingExternalHref.component"

export type CrmAccountHrefProps = {
	account: NonNullable<Call["props"]["account"]>
	className?: string
}

export function CrmAccountHref({ account, className }: CrmAccountHrefProps) {
	return (
		<TrackingExternalHref
			eventName="See account in CRM"
			href={account.crmUrl}
			className={clsx("group hover:text-indigo-400", className)}
		>
			<UsersIcon
				className="mr-1.5 h-4 w-4 font-normal flex-shrink-0 text-indigo-600 group-hover:text-indigo-400"
				aria-hidden="true"
			/>
			{account.name}
		</TrackingExternalHref>
	)
}
