import { CheckIcon } from "@heroicons/react/24/outline"
import React from "react"
import { Trans } from "react-i18next"

import type { Library } from "../../../../../core/domain/Library.entity"
import { Alert } from "../../../../components/design-system/Alert.component"
import { Duration } from "../../../../components/design-system/Duration.component"
import { Modal } from "../../../../components/design-system/Modal.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { LibraryFolderSelect } from "../../../../components/shared/LibraryFolderSelect.component"
import { useLanguage } from "../../../../contexts/language.context"
import { makeLibraryPath } from "../../../../router/Router"

type ICreateCallHighlightModalProps = {
	open: boolean
	onClose: () => void
	callHighlightStartTimeSeconds: number
	callHighlightEndTimeSeconds: number
	library: "loading" | Library
	onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
	onReset: () => void
}

export function CreateCallHighlightModal({
	open,
	onClose,
	callHighlightStartTimeSeconds,
	callHighlightEndTimeSeconds,
	library,
	onSubmit,
	onReset,
}: ICreateCallHighlightModalProps) {
	const { t } = useLanguage()
	const [submitState, setSubmitState] = React.useState<"idle" | "loading" | "success" | "error">("idle")

	const handleSubmit = React.useCallback(
		async (event: React.FormEvent<HTMLFormElement>) => {
			try {
				setSubmitState("loading")
				await onSubmit(event)
				setSubmitState("success")
			} catch (error) {
				console.error(error)
				setSubmitState("error")
			}
		},
		[onSubmit],
	)

	if (submitState === "success") {
		return (
			<Modal open={open} onClose={onClose}>
				<>
					<div>
						<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
							<CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
						</div>
						<div className="mt-3 text-center sm:mt-5">
							<h3 className="text-base font-semibold leading-6 text-gray-900">
								{t("The call highlight was created successfully")}
							</h3>
							<div className="mt-2">
								<p className="text-sm text-gray-500">
									<Trans
										i18nKey="You can access your call highlights in the <1>library.</1>"
										components={{
											1: (
												<TrackingLink
													to={makeLibraryPath()}
													className="text-center md:text-right font-semibold text-indigo-600 hover:text-indigo-500 whitespace-nowrap"
													eventName="Call highlights: library link clicked"
												/>
											),
										}}
									/>
								</p>
							</div>
						</div>
					</div>
					<div className="mt-5 sm:mt-6 flex justify-end">
						<TrackingButton
							type="button"
							onClick={onClose}
							className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
							eventName="Close call highlight modal after success"
						>
							{t("Close")}
						</TrackingButton>
					</div>
				</>
			</Modal>
		)
	}

	return (
		<Modal open={open} onClose={onClose}>
			<div className="p-4">
				<div className="text-lg font-semibold">
					{t("Create a call highlight from")} <Duration seconds={callHighlightStartTimeSeconds} /> {t("to")}{" "}
					<Duration seconds={callHighlightEndTimeSeconds} />
				</div>
				{submitState === "error" && (
					<Alert
						className="mt-2"
						description={t("An error occurred while creating the call highlight. Please try again later.")}
						variant="error"
					/>
				)}
				<form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
					{library !== "loading" && (
						<LibraryFolderSelect
							library={library}
							htmlName="containingLibraryFolderId"
							showAIFolders={false}
						/>
					)}
					<div>
						<label htmlFor="comment" className="block text-sm font-medium leading-6 text-gray-900">
							{t("Add a comment")}
						</label>
						<div className="mt-2">
							<textarea
								rows={4}
								name="comment"
								id="comment"
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
								placeholder={t("What's interesting about this part of the call?")}
								defaultValue=""
								required
							/>
						</div>
					</div>
					<div className="flex items-center justify-end space-x-4 mt-4">
						<TrackingButton
							type="button"
							onClick={onReset}
							className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
							eventName="Cancel call highlight creation"
							disabled={submitState === "loading"}
						>
							{t("Cancel")}
						</TrackingButton>
						<TrackingButton
							type="submit"
							className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							eventName="Create call highlight"
							disabled={submitState === "loading"}
						>
							{t("Create highlight")}
						</TrackingButton>
					</div>
				</form>
			</div>
		</Modal>
	)
}
