import React from "react"

import type { CallInsightsResponse } from "../../../../core/application/gateways/insights.gateway"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useLanguage } from "../../../contexts/language.context"
import type { InteractionChartType } from "../types"
import { roundToTwoDecimals, secondsToMinutes } from "../utils"
import { InteractionCard } from "./InteractionCard.component"

type ICallsActivityStatsSectionProps = {
	callInsights: CallInsightsResponse
	selectedUserId?: string
	onInteractionTypeChange: (type: InteractionChartType) => void
	selectedInsightType: InteractionChartType
	selectedTab: "call-activity" | "interaction"
}

export function CallActivityStatsSection({
	callInsights,
	selectedUserId,
	selectedInsightType,
	onInteractionTypeChange,
	selectedTab,
}: ICallsActivityStatsSectionProps) {
	const { t } = useLanguage()
	const { workspace } = useAuthenticatedSession()
	const callsRecordedValues = React.useMemo(() => {
		if (selectedUserId) {
			const targetUser = callInsights.workspaceUsers.find((x) => x.userId === selectedUserId)
			return [{ value: (targetUser?.totalCalls ?? 0).toString(), label: "total" }]
		}

		return [
			{ value: callInsights.totalCalls.toString(), label: "total" },
			{ value: roundToTwoDecimals(callInsights.averageCallsPerUser).toString(), label: t("avg") },
		]
	}, [callInsights, selectedUserId, t])

	const callRecordingRateValues = React.useMemo(() => {
		if (selectedUserId) {
			const targetUser = callInsights.workspaceUsers.find((x) => x.userId === selectedUserId)
			return [{ value: `${targetUser ? targetUser.callRecordingRatePercent : 0}%`, label: t("avg") }]
		}

		return [{ value: `${callInsights.averageCallRecordingRatePercent}%`, label: t("avg") }]
	}, [callInsights, selectedUserId, t])

	const callDurationValues = React.useMemo(() => {
		if (selectedUserId) {
			const targetUser = callInsights.workspaceUsers.find((x) => x.userId === selectedUserId)
			return [
				{ value: `${roundToTwoDecimals((targetUser?.averageCallDurationSec ?? 0) / 60)} min`, label: t("avg") },
			]
		}
		return [{ value: `${roundToTwoDecimals(callInsights.averageCallDurationMinutes)} min`, label: t("avg") }]
	}, [callInsights.averageCallDurationMinutes, callInsights.workspaceUsers, selectedUserId, t])

	const callScoreValues = React.useMemo(() => {
		if (selectedUserId) {
			const targetUser = callInsights.workspaceUsers.find((x) => x.userId === selectedUserId)
			return [{ value: (targetUser?.averageCallScore ?? 0).toString(), label: "avg" }]
		}

		return [{ value: callInsights.averageCallScore.toString(), label: "avg" }]
	}, [callInsights, selectedUserId])

	const speakingRatioValues = React.useMemo(() => {
		if (selectedUserId) {
			const targetUser = callInsights.workspaceUsers.find((x) => x.userId === selectedUserId)
			return [{ value: `${targetUser ? targetUser.averageSpeakingRatioPercent : 0}%`, label: "avg" }]
		}

		return [{ value: `${callInsights.averageSpeakingRatioPercent}%`, label: "avg" }]
	}, [callInsights, selectedUserId])

	const longestMonologueValues = React.useMemo(() => {
		if (selectedUserId) {
			const targetUser = callInsights.workspaceUsers.find((x) => x.userId === selectedUserId)
			const { remainingSeconds, minutes } = secondsToMinutes(targetUser?.averageLongestMonologueSec ?? 0)
			return [{ value: `${minutes} min ${remainingSeconds} sec`, label: "avg" }]
		}

		const { remainingSeconds, minutes } = secondsToMinutes(callInsights.averageLongestMonologueSec)
		return [{ value: `${minutes} min ${remainingSeconds} sec`, label: "avg" }]
	}, [callInsights, selectedUserId])

	return (
		<div className="grid grid-cols-3 gap-4">
			{selectedTab === "call-activity" && (
				<>
					<InteractionCard
						title={t("Calls recorded")}
						values={callsRecordedValues}
						isSelected={selectedInsightType === "average-calls"}
						onClick={() => onInteractionTypeChange("average-calls")}
					/>
					<InteractionCard
						title={t("Call recording rate")}
						values={callRecordingRateValues}
						isSelected={selectedInsightType === "average-call-recording-rate"}
						onClick={() => onInteractionTypeChange("average-call-recording-rate")}
						tooltip={t("Percentage of calls recorded out of the total calls scheduled to be recorded")}
					/>
					<InteractionCard
						title={t("Call duration")}
						values={callDurationValues}
						isSelected={selectedInsightType === "average-call-duration"}
						onClick={() => onInteractionTypeChange("average-call-duration")}
					/>
				</>
			)}

			{selectedTab === "interaction" && (
				<>
					{workspace.isIntesciaWorkspace() && (
						<InteractionCard
							title={t("Call scores")}
							values={callScoreValues}
							isSelected={selectedInsightType === "average-call-score"}
							onClick={() => onInteractionTypeChange("average-call-score")}
						/>
					)}

					<InteractionCard
						title={t("Speaking ratio")}
						values={speakingRatioValues}
						isSelected={selectedInsightType === "average-speaking-ratio"}
						onClick={() => onInteractionTypeChange("average-speaking-ratio")}
					/>
					<InteractionCard
						title={t("Longest monologue")}
						values={longestMonologueValues}
						isSelected={selectedInsightType === "average-longest-monologue"}
						onClick={() => onInteractionTypeChange("average-longest-monologue")}
					/>
				</>
			)}
		</div>
	)
}
