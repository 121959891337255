import { ArrowLeftIcon } from "@heroicons/react/24/outline"

import { TrackingLink } from "../../design-system/TrackingLink.component"

export type _BackToLinkProps = {
	to: string
	label: string
	eventName: string
}

export function _BackToLink({ to, label, eventName }: _BackToLinkProps) {
	return (
		<div className="mb-4">
			<TrackingLink
				to={to}
				eventName={eventName}
				className="text-sm font-semibold leading-7 text-indigo-600 pb-6"
			>
				<ArrowLeftIcon className="w-4 h-4 inline-block mr-1 -mt-1" />
				{label}
			</TrackingLink>
		</div>
	)
}
