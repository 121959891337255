import clsx from "clsx"
import React from "react"
import { Helmet } from "react-helmet"
import { useMount } from "react-use"

import { useAnalytics } from "../../../contexts/analytics.context"
import { RelationshipSidePanel } from "../RelationshipSidePanel/RelationshipSidePanel.component"
import { _BackToLink } from "./_BackToLink.component"
import { _Navbar } from "./_Navbar.component"
import { _PageHeading } from "./_PageHeading.component"
import { _PageMeta } from "./_PageMeta/_PageMeta.component"
import { _PageMetaItem } from "./_PageMeta/_PageMetaItem.component"
import { _PageSubtitle } from "./_PageSubtitle.component"
import { _PageTitle } from "./_PageTitle.component"

export type LayoutProps = {
	children: React.ReactNode
	BackToLink?: React.ReactNode
	PageTitle?: React.ReactNode
	PageMeta?: React.ReactNode
	PageSubtitle?: React.ReactNode
	PageSummary?: React.ReactNode
	TopRightChildren?: React.ReactNode
	isFluid?: boolean
	pageName: string
	className?: string
}

export function Layout({
	children,
	BackToLink,
	PageTitle,
	PageSubtitle,
	PageSummary,
	PageMeta,
	TopRightChildren,
	isFluid,
	pageName,
	className,
}: LayoutProps) {
	const { trackPageView } = useAnalytics()

	useMount(() => {
		trackPageView(pageName, window.location.pathname)
	})

	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex,nofollow" />
			</Helmet>
			<div className="flex flex-col min-h-screen">
				<_Navbar />
				<RelationshipSidePanel />
				<main
					className={clsx(
						"flex-grow w-full py-4 sm:py-10 px-2 sm:px-6 lg:px-8",
						!isFluid && "max-w-7xl mx-auto",
						className,
						"flex flex-col",
					)}
				>
					<_PageHeading
						BackToLink={BackToLink}
						PageTitle={PageTitle}
						PageSubtitle={PageSubtitle}
						PageMeta={PageMeta}
						PageSummary={PageSummary}
						TopRightChildren={TopRightChildren}
					/>
					{children}
				</main>
			</div>
		</>
	)
}

Layout.BackToLink = _BackToLink
Layout.PageTitle = _PageTitle
Layout.PageSubtitle = _PageSubtitle
Layout.PageMeta = _PageMeta
Layout.PageMetaItem = _PageMetaItem
