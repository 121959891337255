import { useCallback, useState } from "react"

import type { LibraryFolder } from "../../../../../core/domain/Library.entity"

type LibraryFolderEditionState =
	| {
			type: "create"
			section: "user" | "workspace"
	  }
	| {
			type: "create-subfolder"
			parentLibraryFolderId: string
	  }
	| {
			type: "edit-name"
			folder: LibraryFolder
	  }
	| {
			type: "edit-parent-folder"
			folder: LibraryFolder
	  }

export function useLibraryFolderEdition() {
	const [isOpen, setIsOpen] = useState(false)
	const [libraryFolderEdition, setLibraryFolderEdition] = useState<LibraryFolderEditionState | null>(null)

	const closeLibraryFolderModal = useCallback(() => {
		setIsOpen(false)
	}, [])

	const openUserLibraryFolderCreationModal = useCallback(() => {
		setLibraryFolderEdition({ type: "create", section: "user" })
		setIsOpen(true)
	}, [])

	const openWorkspaceLibraryFolderCreationModal = useCallback(() => {
		setLibraryFolderEdition({ type: "create", section: "workspace" })
		setIsOpen(true)
	}, [])

	const openLibrarySubfolderCreationModal = useCallback((parentLibraryFolder: LibraryFolder) => {
		setLibraryFolderEdition({ type: "create-subfolder", parentLibraryFolderId: parentLibraryFolder.id })
		setIsOpen(true)
	}, [])

	const openLibraryFolderNameEditionModal = useCallback((folder: LibraryFolder) => {
		setLibraryFolderEdition({ type: "edit-name", folder })
		setIsOpen(true)
	}, [])

	const openLibraryFolderParentEditionModal = useCallback((folder: LibraryFolder) => {
		setLibraryFolderEdition({ type: "edit-parent-folder", folder })
		setIsOpen(true)
	}, [])

	return {
		isOpen,
		libraryFolderEdition,
		closeLibraryFolderModal,
		openUserLibraryFolderCreationModal,
		openWorkspaceLibraryFolderCreationModal,
		openLibrarySubfolderCreationModal,
		openLibraryFolderNameEditionModal,
		openLibraryFolderParentEditionModal,
	}
}
