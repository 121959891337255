import clsx from "clsx"
import React from "react"

import { TrackingExternalHref } from "../../../../components/design-system/TrackingExternalHref.component"

type ISubscriptionManagementButtonProps = {
	url: string
	analyticsEventName: string
	CTA: string
	background?: "gradient" | "primary"
}

export function SubscriptionManagementButton({
	url,
	analyticsEventName,
	CTA,
	background,
}: ISubscriptionManagementButtonProps) {
	return (
		<TrackingExternalHref
			eventName={analyticsEventName}
			href={url}
			displayExternalIcon={false}
			className={clsx(
				"mt-2.5 justify-center text-md w-full border border-transparent",
				"inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
				!background || background === "primary"
					? "bg-navy-700 text-white hover:!text-gray-50"
					: "bg-gradient-to-r from-pink from-30% to-salmon text-navy-700 hover:border hover:border-navy-700 hover:bg-opacity-10 hover:text-gray-600",
			)}
			target="_self"
		>
			{CTA}
		</TrackingExternalHref>
	)
}
