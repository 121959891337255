import React from "react"
import { Trans } from "react-i18next"

import { makeWorkspaceBillingPath } from "../../pages/Settings/config"
import { TrackingLink } from "../design-system/TrackingLink.component"

export function PaymentDueBanner() {
	return (
		<div className="flex items-center gap-x-6 bg-red-500 px-6 py-2.5 sm:px-3.5 justify-center">
			<p className="text-sm leading-6 text-white flex">
				<Trans
					i18nKey="There was a problem with your payment. Please consult your <1>payment settings</1> to keep your plan active."
					components={{
						1: (
							<TrackingLink
								to={makeWorkspaceBillingPath()}
								eventName="Payment due banner: payment settings clicked"
								className="underline mx-1"
							/>
						),
					}}
				/>
			</p>
		</div>
	)
}
