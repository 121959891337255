import React from "react"

import { type TranslationKey, useLanguage } from "../../../../contexts/language.context"

export function PanelEntry({ title, value }: { title: TranslationKey; value: React.ReactNode }) {
	const { t } = useLanguage()

	if (!value) {
		return null
	}

	return (
		<div>
			<h2 className="text-md font-semibold text-gray-900">{t(title)}</h2>
			{Array.isArray(value) ? (
				<ul className="list-disc list-inside">
					{value.map((item, index) => (
						<li key={index} className="text-sm text-gray-900">
							{item}
						</li>
					))}
				</ul>
			) : (
				<p className="text-sm text-gray-900">{value ?? " - "}</p>
			)}
		</div>
	)
}
