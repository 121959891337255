import clsx from "clsx"
import React from "react"

import { Button } from "../../components/design-system/Button.component"
import { SpinnerIcon } from "../../components/design-system/SpinnerIcon.component"
import {
	getPasswordValidationErrors,
	type PasswordValidationError,
} from "../../components/shared/ClaimAccountOrSignupForm/passwordValidationError"
import { useLanguage } from "../../contexts/language.context"

type IResetPasswordForm = {
	handleSubmit: (plainPassword: string) => void
	/** Whether to disable the submit button, when a request is pending */
	isBusy: boolean
}

export function ResetPasswordForm({ handleSubmit, isBusy }: IResetPasswordForm) {
	const { t } = useLanguage()
	const [password, setPassword] = React.useState("")
	const [passwordConfirm, setPasswordConfirm] = React.useState("")
	const [isFormTouched, setIsFormTouched] = React.useState(false)
	const [isPasswordTouched, setIsPasswordTouched] = React.useState(false)

	const handlePasswordChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value)
	}, [])

	const handlePasswordConfirmChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setPasswordConfirm(event.target.value)
	}, [])

	const passwordValidationErrors = React.useMemo(
		() => (!isPasswordTouched ? [] : getPasswordValidationErrors(password, passwordConfirm)),
		[isPasswordTouched, password, passwordConfirm],
	)

	const getValidationErrorClassNames = React.useCallback(
		(validationError: PasswordValidationError) => {
			if (passwordValidationErrors.includes(validationError)) {
				return "text-red-500"
			}

			if (password.length === 0) {
				return ""
			}

			return "text-green-700"
		},
		[password.length, passwordValidationErrors],
	)

	const onFormSubmit = React.useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault()

			if (passwordValidationErrors.length) {
				return
			}

			const plainPassword = event.currentTarget.password.value

			if (typeof plainPassword !== "string") {
				alert("Internal error")
				return
			}

			handleSubmit(plainPassword)
		},
		[handleSubmit, passwordValidationErrors.length],
	)

	const handleFormChange = React.useCallback((e: React.ChangeEvent<HTMLFormElement>) => {
		setIsFormTouched(true)
		if (e.target.name === "password" || e.target.name === "password-confirm") {
			setIsPasswordTouched(true)
		}
	}, [])

	const isFormDisabled = isBusy || passwordValidationErrors.length > 0 || !isFormTouched

	return (
		<form className="flex mt-4 flex-col gap-4" onSubmit={onFormSubmit} onChange={handleFormChange}>
			<div>
				<div className="flex items-center justify-between">
					<label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
						{t("Password")}
					</label>
				</div>
				<div className="mt-2">
					<input
						id="password"
						name="password"
						type="password"
						autoComplete="current-password"
						required
						className={clsx(
							"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
							passwordValidationErrors.filter((err) => err !== "passwords-dont-match").length &&
								"!ring-red-500",
						)}
						onChange={handlePasswordChange}
					/>
					<ul className="mt-2 text-sm text-gray-600 list-disc ml-6">
						<li className={clsx(getValidationErrorClassNames("password-too-short"))}>
							{t("At least 8 characters")}
						</li>
						<li className={clsx(getValidationErrorClassNames("missing-lowercase"))}>
							{t("At least one lowercase letter")}
						</li>
						<li className={clsx(getValidationErrorClassNames("missing-uppercase"))}>
							{t("At least one uppercase letter")}
						</li>
						<li className={clsx(getValidationErrorClassNames("missing-digit"))}>
							{t("At least one digit")}
						</li>
						<li className={clsx(getValidationErrorClassNames("missing-special-char"))}>
							{t("At least one special character")}
						</li>
					</ul>
				</div>
			</div>

			<div>
				<div className="flex items-center justify-between">
					<label htmlFor="password-confirm" className="block text-sm font-medium leading-6 text-gray-900">
						{t("Confirm password")}
					</label>
				</div>
				<div className="mt-2">
					<input
						id="password-confirm"
						name="password-confirm"
						type="password"
						required
						className={clsx(
							"block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
							passwordValidationErrors.includes("passwords-dont-match") && "!ring-red-500",
						)}
						onChange={handlePasswordConfirmChange}
					/>
					{passwordValidationErrors.includes("passwords-dont-match") && (
						<ul className="mt-2 text-sm text-gray-600 list-disc ml-6">
							<li className="text-red-500">{t("Passwords don't match")}</li>
						</ul>
					)}
				</div>
			</div>

			<div>
				<Button
					disabled={isFormDisabled}
					eventName="Reset password form: update password clicked"
					type="submit"
				>
					{isBusy ? <SpinnerIcon className={"h-5 w-5 mx-auto"} /> : t("Update password")}
				</Button>
			</div>
		</form>
	)
}
