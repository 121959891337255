import z from "zod"

import { ISODateTimeSchema } from "../../infra/gateways/ISODateTime"

export const subscriptionStatusSchema = z.enum([
	"incomplete", // The subscription is incomplete, and the customer should be contacted.
	"incomplete_expired", // The subscription expired due to the customer not completing the payment.
	"trialing", // The subscription is in its trial period.
	"active", // The subscription is active and the customer will be billed as per the billing schedule.
	"past_due", // The subscription is past due on payment and will be canceled if not paid within a specific period.
	"canceled", // The subscription is canceled and will not be billed.
	"unpaid", // The subscription is unpaid and will be canceled if not paid within a specific period.
	"paused", // The subscription is paused and will not be billed.
])

export type SubscriptionStatus = z.infer<typeof subscriptionStatusSchema>

export const billingInfoSchema = z.object({
	plan: z
		.object({
			id: z.string(),
			features: z.record(z.boolean()),
			name: z.string(),
		})
		.nullish(),
	subscription: z
		.object({
			startedAt: ISODateTimeSchema,
			endAt: ISODateTimeSchema,
			status: subscriptionStatusSchema,
			provider: z.string(),
			last4Digits: z.string().nullish(),
			isTrial: z.boolean(),
			cardExpiryMonth: z.number().nullish(),
			cardExpiryYear: z.number().nullish(),
			hasPaymentMethod: z.boolean(),
			canceledAt: ISODateTimeSchema.nullish(),
			cancelAtPeriodEnd: z.boolean().nullish(),
			cancelAt: ISODateTimeSchema.nullish(),
			billingPeriod: z.enum(["yearly", "monthly"]).nullish(),
		})
		.nullish(),
	actionUrls: z
		.object({
			customerPortalUrl: z.string(),
		})
		.nullish(),
	invoice: z
		.object({
			upcoming: z
				.object({
					total: z.number(),
					nextPaymentAttempt: ISODateTimeSchema.nullish(),
					currency: z.string(),
				})
				.nullish(),
		})
		.nullish(),
	activeLicenses: z.number(),
})

export const sessionResponseSchema = z.object({
	sessionUrl: z.string(),
})

type CreateSessionResponse = z.infer<typeof sessionResponseSchema>

export type BillingInfo = z.infer<typeof billingInfoSchema>

const planDetailsPriceSchema = z.object({
	priceUnit: z.number().nullable(),
	priceId: z.string().nullable(),
})

export const getPlansResponseSchema = z.array(
	z.object({
		name: z.string(),
		id: z.string(),
		features: z.record(z.boolean()),
		yearlyPrice: planDetailsPriceSchema.nullable(),
		monthlyPrice: planDetailsPriceSchema.nullable(),
		productId: z.string().nullable(),
		marketingFeatures: z.array(z.string()).nullable(),
		isEnterprisePlan: z.boolean(),
	}),
)

export type PlanDetails = z.infer<typeof getPlansResponseSchema>[number]

export type BillingPeriod = "yearly" | "monthly"

export type IBillingGateway = {
	getBillingInfo(): Promise<BillingInfo>
	createCheckoutSession(planId: string, billingPeriod: BillingPeriod): Promise<CreateSessionResponse>
	createPaymentMethodUpdatePortalSession(planId: string, billingPeriod: BillingPeriod): Promise<CreateSessionResponse>
	createSubscriptionUpdateConfirmPortalSession(
		planId: string,
		billingPeriod: BillingPeriod,
	): Promise<CreateSessionResponse>
	getPlans(): Promise<PlanDetails[]>
}
