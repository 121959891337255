import { ChevronRightIcon } from "@heroicons/react/20/solid"
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import React from "react"

import { HELP_CENTER_URLS } from "../../../../config"
import type { Library } from "../../../../core/domain/Library.entity"
import { type LibraryFolder } from "../../../../core/domain/Library.entity"
import { TrackingLink } from "../../../components/design-system/TrackingLink.component"
import { TrackingLinkOrButton } from "../../../components/design-system/TrackingLinkOrButton.component"
import { useLanguage } from "../../../contexts/language.context"
import { makeLibraryFolderPath, makeLibraryPath } from "../../../router/Router"
import { COACHING_SESSIONS_FOLDER_ID } from "./CoachingSession.util"
import { FolderDisplayName } from "./FolderDisplayName.component"

export type LibraryFoldersBreadcrumbProps = {
	currentFolder: LibraryFolder | undefined
	onFolderClick?: (folder: LibraryFolder) => void
	library: Library
	compact?: boolean
}

export function LibraryFoldersBreadcrumb({
	currentFolder,
	onFolderClick,
	library,
	compact,
}: LibraryFoldersBreadcrumbProps) {
	const { t, language } = useLanguage()

	let currentFolderPath = currentFolder?.getFullFolderPath() ?? []
	if (compact) {
		currentFolderPath = currentFolderPath.slice(-1)
	}

	return (
		<div className="flex items-center space-x-1 text-xl font-medium mb-4 text-gray-800">
			{currentFolder?.id !== COACHING_SESSIONS_FOLDER_ID && (
				<>
					<TrackingLinkOrButton
						eventName={"Library: navigate to root folder"}
						className={clsx(currentFolderPath.length > 0 && "hover:text-gray-600")}
						{...(onFolderClick
							? {
									onClick: () => onFolderClick(library.userRoot),
							  }
							: {
									to: makeLibraryPath(),
							  })}
					>
						{t("Library")}
					</TrackingLinkOrButton>
					<TrackingLink
						to={HELP_CENTER_URLS.Library.CreateCallHighlights[language]}
						eventName="Library: open help center"
						target="_blank"
					>
						<QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" />
					</TrackingLink>
					{currentFolderPath.length > 0 && <ChevronRightIcon className="mt-0.5 h-6 w-6 inline-block" />}
				</>
			)}
			{(currentFolderPath ?? []).map((folder, index) => {
				const isLast = index === currentFolderPath.length - 1
				return (
					<React.Fragment key={folder.id}>
						<TrackingLinkOrButton
							className={clsx(!isLast && "hover:text-gray-600")}
							eventName="Library: navigate to folder"
							eventProperties={{ folderId: folder.id }}
							{...(onFolderClick
								? {
										onClick: () => onFolderClick(folder),
								  }
								: {
										to: makeLibraryFolderPath(folder.id),
								  })}
						>
							<FolderDisplayName folder={folder} />
						</TrackingLinkOrButton>

						{!isLast && <ChevronRightIcon className="mt-0.5 h-6 w-6 inline-block" />}
					</React.Fragment>
				)
			})}
		</div>
	)
}
