import _ from "lodash"
import type { ReactNode } from "react"
import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import { useInterval, useMount } from "react-use"

import type { User } from "../../core/domain/User.entity"
import { useSession } from "./authentication.context"
import { useDependencies } from "./dependencies.context"

type IWorkspaceUsersContextProps = {
	workspaceUsers: User[] | null
	isLoading: boolean | null
	listWorkspaceUsers: () => Promise<void>
}

const WorkspaceUsersContext = createContext<IWorkspaceUsersContextProps | undefined>(undefined)

type IWorkspaceUsersProviderProps = {
	children: ReactNode
}

export const WorkspaceUsersProvider: React.FC<IWorkspaceUsersProviderProps> = ({ children }) => {
	const { user, workspace } = useSession()

	const { usersGateway } = useDependencies()
	const [workspaceUsers, setWorkspaceUsers] = useState<User[] | null>(null)
	const [isInitialLoading, setIsInitialLoading] = useState<boolean | null>(null)

	const trylistOwnWorkspaceUsers = useCallback(async () => {
		// no session, no workspace, no need to fetch
		if (!user || !workspace) {
			return
		}

		// If we are already loading, we don't need to fetch again
		if (isInitialLoading) {
			return
		}

		if (isInitialLoading === null) {
			setIsInitialLoading(true)
		}

		try {
			const _workspaceUsers = await usersGateway.listOwnWorkspaceUsers()
			const filteredWorkspaceUsers = _workspaceUsers.filter((user) => {
				// No need to hide any users for ourselves
				if (workspace.isRippletideWorkspace()) {
					return true
				}
				// hide internal employees (admin accounts...) from non-rippletide workspaces
				return !user.isRippletideEmployee() && !user.isDeleted()
			})
			// Prevent unnecessary re-render
			if (!workspaceUsers || !_.isEqual(filteredWorkspaceUsers, workspaceUsers)) {
				setWorkspaceUsers(filteredWorkspaceUsers)
			}
		} finally {
			setIsInitialLoading(false)
		}
	}, [user, workspace, isInitialLoading, usersGateway, workspaceUsers])

	useMount(trylistOwnWorkspaceUsers)
	useInterval(trylistOwnWorkspaceUsers, 30 * 1000) // 30 seconds

	// We need to fetch the users when the user or workspace changes
	useEffect(() => {
		trylistOwnWorkspaceUsers()
	}, [trylistOwnWorkspaceUsers, user, workspace])

	return (
		<WorkspaceUsersContext.Provider
			value={{ workspaceUsers, isLoading: isInitialLoading, listWorkspaceUsers: trylistOwnWorkspaceUsers }}
		>
			{children}
		</WorkspaceUsersContext.Provider>
	)
}

export function useWorkspaceUsers(): IWorkspaceUsersContextProps {
	const context = useContext(WorkspaceUsersContext)
	if (!context) {
		throw new Error("useWorkspaceUsers must be used within an WorkspaceUsersProvider")
	}
	return context
}
