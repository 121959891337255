import React from "react"

import type {
	GetSalesMotionPerformanceStatsQueryParams,
	GetSalesMotionPerformanceStatsResponse,
	SalesMotionChangeComputationPeriodQueryParams,
	SalesMotionClosingPeriodQueryParams,
} from "../../../../../../core/application/gateways/deals.gateway/schemas/listDeals"
import { SpinnerIcon } from "../../../../../components/design-system/SpinnerIcon.component"
import { SectionCard } from "../../../../../components/shared/GraphSection/SectionCard.component"
import { InsightCard } from "../../../../../components/shared/InsightCard.component"
import { useAuthenticatedSession } from "../../../../../contexts/authentication.context"
import { useDependencies } from "../../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../../contexts/language.context"
import { formatCurrency } from "../../../../Deals/utils"
import { useDealOwners } from "../../../hooks/useDealOwners"
import { fakePerformanceStats } from "../fakeData"
import { ActiveDealValueSection } from "./ActiveDealValueSection.component"
import { ActiveDealVolumeSection } from "./ActiveDealVolumeSection.component"
import { SalesMotionFilters } from "./SalesMotionFilters.component"

function useSalesMotionPerformanceStats(filter: GetSalesMotionPerformanceStatsQueryParams, useFakeData: boolean) {
	const [stats, setStats] = React.useState<GetSalesMotionPerformanceStatsResponse | null>(null)
	const [isLoading, setIsLoading] = React.useState(!useFakeData)
	const { dealsGateway } = useDependencies()

	React.useEffect(() => {
		if (useFakeData) {
			setStats(fakePerformanceStats)
			return
		}

		setIsLoading(true)
		dealsGateway
			.getSalesMotionPerformanceStats(filter)
			.then((response) => setStats(response))
			.catch((e) => console.error(e))
			.finally(() => setIsLoading(false))
	}, [dealsGateway, filter, useFakeData])

	return {
		isLoading,
		isInitialLoading: !stats && isLoading,
		stats,
	}
}

function useSalesMotionFilters(useFakeData: boolean) {
	const { isLoading, owners } = useDealOwners(useFakeData)
	const [ownerId, setOwnerId] = React.useState<string | null>(null)
	const [comparisonPeriod, setComparisonPeriod] =
		React.useState<SalesMotionChangeComputationPeriodQueryParams>("last-30-days")
	const [closingPeriod, setClosingPeriod] = React.useState<SalesMotionClosingPeriodQueryParams>("current-quarter")
	const filter: GetSalesMotionPerformanceStatsQueryParams = React.useMemo(
		() => ({
			changeComputationPeriod: comparisonPeriod,
			closingPeriod,
			ownerId,
		}),
		[comparisonPeriod, closingPeriod, ownerId],
	)

	return {
		ownerId,
		setOwnerId,
		comparisonPeriod,
		setComparisonPeriod,
		closingPeriod,
		setClosingPeriod,
		filter,
		owners: owners?.users.map((owner) => ({
			id: owner.crmId,
			name: owner.fullName ?? owner.username ?? owner.email ?? "",
		})),
		areOwnersLoading: isLoading,
	}
}

type SalesMotionSectionProps = {
	useFakeData: boolean
}

export function SalesMotionSection({ useFakeData }: SalesMotionSectionProps) {
	const {
		filter,
		ownerId,
		setOwnerId,
		comparisonPeriod,
		setComparisonPeriod,
		closingPeriod,
		setClosingPeriod,
		owners,
		areOwnersLoading,
	} = useSalesMotionFilters(useFakeData)
	const { user } = useAuthenticatedSession()
	const { isLoading, stats, isInitialLoading } = useSalesMotionPerformanceStats(filter, useFakeData)
	const { t } = useLanguage()
	if (isInitialLoading || !stats || areOwnersLoading || !owners) {
		return (
			<SectionCard className="w-full">
				<SalesMotionFilters
					onClosingPeriodChange={setClosingPeriod}
					onComparisonPeriodChange={setComparisonPeriod}
					onSelectOwner={setOwnerId}
					selectedOwner={ownerId}
					closingPeriod={closingPeriod}
					comparisonPeriod={comparisonPeriod}
					dealOwners={owners ?? []}
					disabled
					canOnlySeeOwnDeals={!user.isWorkspaceManager()}
				/>

				<SpinnerIcon className="w-10 h-10 m-auto" />
			</SectionCard>
		)
	}

	const activeDealsDiff = stats.currentActiveDeals - stats.previousActiveDeals
	const totalDealValueDiff = stats.currentTotalDealValue - stats.previousTotalDealValue
	const averageDealValueDiff = stats.currentAverageDealValue - stats.previousAverageDealValue

	return (
		<SectionCard className="h-full flex flex-col relative">
			<SalesMotionFilters
				onClosingPeriodChange={setClosingPeriod}
				onComparisonPeriodChange={setComparisonPeriod}
				onSelectOwner={setOwnerId}
				selectedOwner={ownerId}
				closingPeriod={closingPeriod}
				comparisonPeriod={comparisonPeriod}
				dealOwners={owners}
				canOnlySeeOwnDeals={!user.isWorkspaceManager()}
				disabled={isLoading}
			/>
			<div className="gap-2 flex flex-col">
				<div className="grid grid-cols-2 gap-2 flex-1">
					<InsightCard
						title={t("Active deals")}
						className="flex-1"
						values={[
							{
								value: `${activeDealsDiff > 0 ? "+" : ""}${activeDealsDiff}`,
								label: stats.currentActiveDeals.toString(),
								valueClassName: activeDealsDiff > 0 ? "text-green-500" : "text-red-500",
								key: "active-deals",
							},
							{
								value: `${totalDealValueDiff > 0 ? "+" : ""}${formatCurrency(totalDealValueDiff)}`,
								label: formatCurrency(stats.currentTotalDealValue),
								valueClassName: totalDealValueDiff > 0 ? "text-green-500" : "text-red-500",
								key: "total-deal-value",
							},
							{
								value: `${averageDealValueDiff > 0 ? "+" : ""}${formatCurrency(averageDealValueDiff)}`,
								label: `${formatCurrency(stats.currentAverageDealValue)} ${t("avg")}`,
								valueClassName: averageDealValueDiff > 0 ? "text-green-500" : "text-red-500",
								key: "average-deal-value",
							},
						]}
						isLoading={isLoading}
						compact={true}
					/>
					<InsightCard
						title={t("Win rate")}
						className="flex-1"
						values={[
							{
								value: `${stats.winRatePercentage.toFixed(2)}%`,
								key: "win-rate",
							},
							{
								value: `${formatCurrency(stats.wonDealAmount)} (${stats.wonDealCount})`,
								valueClassName: stats.wonDealCount > 0 ? "text-green-500" : "text-red-500",
								key: "won-deals",
								label: t("won"),
							},
							{
								value: `${formatCurrency(stats.lostDealAmount)} (${stats.lostDealCount})`,
								valueClassName: stats.lostDealCount === 0 ? "text-green-500" : "text-red-500",
								key: "lost-deals",
								label: t("lost"),
							},
						]}
						isLoading={isLoading}
						compact={true}
					/>
				</div>
			</div>
			<div>
				<div className="grid grid-cols-2 gap-2 mt-4">
					<ActiveDealVolumeSection stats={stats} isLoading={isLoading} />
					<ActiveDealValueSection stats={stats} isLoading={isLoading} />
				</div>
			</div>
		</SectionCard>
	)
}
