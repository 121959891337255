import assertNever from "assert-never"

import type { Library, LibraryFolder } from "../../../../../core/domain/Library.entity"
import { useLanguage } from "../../../../contexts/language.context"
import { EntityAgnosticModalUI, type Module } from "./EntityAgnosticModalUI.component"

export type LibraryFolderEditionModalParams =
	| {
			type: "create"
			section: "user" | "workspace"
			createLibraryFolder: (folderName: string, parentLibraryFolderId: string) => Promise<void>
	  }
	| {
			type: "create-subfolder"
			parentLibraryFolderId: string
			createLibraryFolder: (folderName: string, parentLibraryFolderId: string) => Promise<void>
	  }
	| {
			type: "edit-name"
			libraryFolderUnderEdition: LibraryFolder
			editLibraryFolder: (editedFolder: LibraryFolder) => Promise<void>
	  }
	| {
			type: "edit-parent-folder"
			libraryFolderUnderEdition: LibraryFolder
			editLibraryFolder: (editedFolder: LibraryFolder) => Promise<void>
	  }

export type LibraryFolderEditionModalProps = {
	isOpen: boolean
	onClose: () => void
	library: "loading" | Library
	params: LibraryFolderEditionModalParams | undefined
}

export function LibraryFolderEditionModal({ isOpen, onClose, library, params }: LibraryFolderEditionModalProps) {
	const { t } = useLanguage()

	if (!params) return

	const HTML_NAMES = {
		folderName: "folderName",
		containingLibraryFolderId: "containingLibraryFolderId",
	}

	const handleLibraryFolderCreation = async (moduleData: Partial<Record<keyof typeof HTML_NAMES, string>>) => {
		if (library === "loading") return

		function getFolderName() {
			const folderName = moduleData.folderName?.trim()

			if (folderName === undefined) {
				console.error("Missing folder name")
				return
			}
			if (!folderName) {
				window.alert("Folder name is required")
				return
			}
			return folderName
		}

		function getContainingLibraryFolder() {
			if (library === "loading") return

			const containingLibraryFolderId = moduleData.containingLibraryFolderId?.trim()

			if (typeof containingLibraryFolderId !== "string") {
				window.alert("Invalid folder") // should not happen
				return
			}

			const containingLibraryFolder = library.getFolderById(containingLibraryFolderId.trim())
			if (containingLibraryFolder === undefined) {
				window.alert("Invalid folder") // should not happen
				return
			}

			return containingLibraryFolder
		}

		switch (params.type) {
			case "create": {
				const folderName = getFolderName()
				const containingLibraryFolder = getContainingLibraryFolder()

				if (!folderName) return
				if (!containingLibraryFolder) return

				await params.createLibraryFolder(folderName, containingLibraryFolder.id)
				break
			}

			case "create-subfolder": {
				const folderName = getFolderName()
				if (!folderName) return

				await params.createLibraryFolder(folderName, params.parentLibraryFolderId)
				break
			}

			case "edit-name": {
				const folderName = getFolderName()
				if (!folderName) return

				params.libraryFolderUnderEdition.rename(folderName)
				await params.editLibraryFolder(params.libraryFolderUnderEdition)
				break
			}

			case "edit-parent-folder": {
				const containingLibraryFolder = getContainingLibraryFolder()
				if (!containingLibraryFolder) return

				params.libraryFolderUnderEdition.moveTo(containingLibraryFolder)

				await params.editLibraryFolder(params.libraryFolderUnderEdition)
				break
			}

			default:
				assertNever(params)
		}

		onClose()
	}

	let allowNameEdition: boolean
	let allowParentEdition: boolean
	switch (params.type) {
		case "create":
			allowNameEdition = true
			allowParentEdition = true
			break

		case "create-subfolder":
			allowNameEdition = true
			allowParentEdition = false
			break

		case "edit-name":
			allowNameEdition = true
			allowParentEdition = false
			break

		case "edit-parent-folder":
			allowNameEdition = false
			allowParentEdition = true
			break

		default:
			assertNever(params)
	}

	const title = (() => {
		switch (params.type) {
			case "create":
				switch (params.section) {
					case "user":
						return t("Create private folder")
					case "workspace":
						return t("Create shared folder")
					default:
						assertNever(params.section)
				}
				throw new Error("Unreachable") // eslint bug https://github.com/typescript-eslint/typescript-eslint/issues/3455
			case "create-subfolder":
				return t("Create subfolder")
			case "edit-name":
			case "edit-parent-folder":
				return params.libraryFolderUnderEdition.name
			default:
				assertNever(params)
		}
	})()

	const modules: Module[] = []
	if (library !== "loading" && allowParentEdition) {
		modules.push({
			type: "library-select",
			name: HTML_NAMES.containingLibraryFolderId,
			libraryFolderSelectProps: {
				library,
				defaultValue:
					params.type === "edit-name" || params.type === "edit-parent-folder"
						? params.libraryFolderUnderEdition.parent?.id
						: undefined,
				onlyRoot: params.type === "create" ? params.section : undefined,
				showAIFolders: false,
			},
		})
	}
	if (allowNameEdition) {
		modules.push({
			type: "text",
			name: HTML_NAMES.folderName,
			title: t("Folder name"),
			defaultValue:
				params.type === "edit-name" || params.type === "edit-parent-folder"
					? params.libraryFolderUnderEdition.name
					: undefined,
			placeholder: t("My new folder"),
			required: true,
		})
	}

	return (
		<EntityAgnosticModalUI
			isOpen={isOpen && library !== "loading"}
			title={title}
			submitEventName={
				params.type === "edit-name" || params.type === "edit-parent-folder"
					? "Update library folder"
					: "Create library folder"
			}
			submitLabel={
				params.type === "edit-name" || params.type === "edit-parent-folder" ? t("Update") : t("Create folder")
			}
			onClose={onClose}
			onSubmit={handleLibraryFolderCreation}
			modules={modules}
		/>
	)
}
