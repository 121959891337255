import { useCallback, useState } from "react"

import type { Library } from "../../core/domain/Library.entity"
import { useDependencies } from "../contexts/dependencies.context"

export function useLibrary() {
	const { libraryFoldersGateway } = useDependencies()
	const [library, setLibrary] = useState<Library | "loading">("loading")

	const fetchLibrary = useCallback(async () => {
		setLibrary(await libraryFoldersGateway.get())
	}, [libraryFoldersGateway])

	return {
		fetchLibrary,
		library,
	}
}
