import clsx from "clsx"
import React from "react"
import Datepicker, { type DateValueType } from "react-tailwindcss-datepicker"

import { useLanguage } from "../../../../../contexts/language.context"
import { DateProvider } from "../../../../../utils/time"

export type SalesMotionSelectFilter = "all" | "won" | "lost"

type SalesMotionPipelineFiltersProps = {
	dealOwners: Array<{ id: string; name: string }>
	selectedDeals: SalesMotionSelectFilter
	onSelectDeals: (selectedDeals: SalesMotionSelectFilter) => void
	selectedOwner: string | null
	onSelectOwner: (userId: string) => void
	period: { startDate: Date; endDate: Date }
	onPeriodChange: (period: { startDate: Date; endDate: Date }) => void
	disabled?: boolean
	comparisonLabel: "compare" | "with"
}

export function SalesMotionPipelineFilters({
	dealOwners,
	selectedDeals,
	onSelectDeals,
	onSelectOwner,
	selectedOwner,
	disabled,
	period,
	onPeriodChange,
	comparisonLabel,
}: SalesMotionPipelineFiltersProps) {
	const { t } = useLanguage()
	const dateProvider = new DateProvider()
	const todayEndOfDay = dateProvider.now()
	todayEndOfDay.setHours(23, 59, 59, 999)

	const aYearAgo = dateProvider.now()
	aYearAgo.setFullYear(aYearAgo.getFullYear() - 1)
	aYearAgo.setHours(0, 0, 0, 0)

	const sortedOwners = dealOwners.sort((a, b) => a.name.localeCompare(b.name))

	const handlePeriodChange = (newValue: DateValueType) => {
		if (!newValue) {
			return
		}
		const startDate = typeof newValue.startDate === "string" ? new Date(newValue.startDate) : newValue.startDate
		const endDate = typeof newValue.endDate === "string" ? new Date(newValue.endDate) : newValue.endDate

		if (!startDate || !endDate) {
			return
		}

		onPeriodChange({
			startDate,
			endDate,
		})
	}

	return (
		<div className={clsx("flex flex-row gap-3 items-center mb-2 justify-center sm:justify-normal")}>
			<div className="text-sm block font-medium leading-6 text-gray-900 w-16 lg:w-auto">
				{t(comparisonLabel === "compare" ? "Compare" : "with")}
			</div>
			<div
				className={clsx(
					comparisonLabel === "with" ? "bg-pink border-pink" : "bg-indigo-500 border-navy-700",
					"rounded-xl p-1 flex gap-2 bg-opacity-20 items-center border-2",
				)}
			>
				<span className={clsx("ml-1 font-medium")}>{comparisonLabel === "compare" ? "(A)" : "(B)"}</span>
				<div className="flex flex-row items-center gap-1.5">
					<select
						id="deals"
						name="deals"
						value={selectedDeals}
						onChange={(e) => onSelectDeals(e.target.value as SalesMotionSelectFilter)}
						className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
						disabled={disabled}
					>
						<option value="all">{t("All deals")}</option>
						<option value="won">{t("Won deals")}</option>
						<option value="lost">{t("Lost deals")}</option>
					</select>
				</div>
				<div className="flex flex-row items-center gap-1.5">
					<label htmlFor="team-members" className="block text-sm font-medium leading-6 text-gray-900">
						{t("from")}
					</label>
					<select
						id="team-members"
						name="team-members"
						value={selectedOwner || ""}
						onChange={(e) => onSelectOwner(e.target.value)}
						className="block w-full rounded-md border-0 py-1.5 pl-3 pr-8 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
						disabled={disabled}
					>
						<option value="">{t("All members")}</option>
						{sortedOwners.map((owner) => (
							<option key={owner.id} value={owner.id}>
								{owner.name}
							</option>
						))}
					</select>
				</div>
				<div className="flex flex-row items-center gap-1.5">
					<label htmlFor="team-members" className="block text-sm font-medium leading-6 text-gray-900">
						{t("during")}
					</label>
					<Datepicker
						value={period}
						onChange={handlePeriodChange}
						maxDate={todayEndOfDay}
						minDate={aYearAgo}
						useRange={false}
						inputClassName={(className) => clsx(className, "lg:max-w-[180px]")}
						displayFormat="DD/MM/YY"
					/>
				</div>
			</div>
		</div>
	)
}
