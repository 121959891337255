import "react-horizontal-scrolling-menu/dist/styles.css"

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import React from "react"
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu"
import { Navigate, useParams } from "react-router-dom"
import { useSearchParam } from "react-use"

import type { BaseCall } from "../../../../../core/domain/BaseCall.entity"
import { Call } from "../../../../../core/domain/Call.entity"
import { Tabs } from "../../../../components/design-system/Tabs"
import type { TabProps } from "../../../../components/design-system/Tabs/_Tab.component"
import { useSession } from "../../../../contexts/authentication.context"
import { useLanguage } from "../../../../contexts/language.context"
import { type CallFeaturesVisibility, useCallFeaturesVisibility } from "../../../../hooks/useCallFeaturesVisibility"
import { useInviteToken } from "../../../../hooks/useInviteToken"
import { type CallSearchParams, makeCallPath, makePublicSharedCallPath } from "../../../../router/Router"
import { _CoachingPanel } from "./_CoachingPanel.component"
import { _OverviewPanel } from "./_OverviewPanel.component"
import { _ReviewPanel } from "./_ReviewPanel.component"
import { _TranscriptionPanel } from "./_TranscriptionPanel.component"
import { _LeadKnowledgePanel } from "./LeadKnowledgePanel/_LeadKnowledgePanel.component"

export type _ISidePanelProps = {
	call: BaseCall
	className?: string
	encodedAccessToken?: string // can be either a public access token or a base64 encoded JSON. See: extractTokenFromUrlParam
}

type SidePanelTabSlug = "overview" | "lead-knowledge" | "transcription" | "review" | "coaching"
const allSidePanelTabSlugs: SidePanelTabSlug[] = ["overview", "lead-knowledge", "review", "coaching", "transcription"]
function isSidePanelTabSlug(slug: string): slug is SidePanelTabSlug {
	return allSidePanelTabSlugs.includes(slug as SidePanelTabSlug)
}

function listVisibleTabSlugs(call: BaseCall, callFeaturesVisibility: CallFeaturesVisibility) {
	if (!(call instanceof Call)) {
		const publicCallTabSlugs: SidePanelTabSlug[] = ["overview", "transcription"]
		return publicCallTabSlugs
	}
	if (!call.isProcessable) {
		const unprocessableTabSlugs: SidePanelTabSlug[] = ["transcription"]
		return unprocessableTabSlugs
	}

	let tabs: SidePanelTabSlug[] = allSidePanelTabSlugs

	if (!callFeaturesVisibility.scoring) {
		tabs = tabs.filter((slug) => slug !== "review")
	}

	if (!callFeaturesVisibility.coaching) {
		tabs = tabs.filter((slug) => slug !== "coaching")
	}

	if (!callFeaturesVisibility.leadKnowledge) {
		tabs = tabs.filter((slug) => slug !== "lead-knowledge")
	}

	return tabs
}

const defaultTabSlug: SidePanelTabSlug = "transcription"

function makePathToTab(
	call: BaseCall,
	tabSlug: SidePanelTabSlug,
	callSearchParams: CallSearchParams,
	encodedAccessToken: string | undefined,
) {
	if (call instanceof Call || !encodedAccessToken) {
		return makeCallPath(call.props.id, { sidePanelTab: tabSlug }, callSearchParams)
	}

	return makePublicSharedCallPath(encodedAccessToken, { sidePanelTab: tabSlug }, callSearchParams)
}

type SidePanelTabMenuProps = {
	tabsToDisplay: TabProps[]
}

const LeftArrow = () => {
	const visibility = React.useContext(VisibilityContext)
	const isFirstItemVisible = visibility.useIsVisible("first", true)
	const isLastItemVisible = visibility.useIsVisible("last", true)

	if (isFirstItemVisible && isLastItemVisible) {
		return null
	}

	return (
		<div className="flex-1 flex items-center mr-0.5">
			<ChevronLeftIcon
				className={clsx(
					"h-5 w-5",
					isFirstItemVisible
						? "text-gray-400 cursor-not-allowed"
						: "hover:text-gray-500 cursor-pointer text-gray-800",
				)}
				onClick={() => visibility.scrollPrev()}
			/>
		</div>
	)
}

const RightArrow = () => {
	const visibility = React.useContext(VisibilityContext)
	const isLastItemVisible = visibility.useIsVisible("last", true)
	const isFirstItemVisible = visibility.useIsVisible("first", true)

	if (isFirstItemVisible && isLastItemVisible) {
		return null
	}

	return (
		<div className="flex-1 flex items-center ml-0.5">
			<ChevronRightIcon
				className={clsx(
					"h-5 w-5",
					isLastItemVisible
						? "text-gray-400 cursor-not-allowed"
						: "hover:text-gray-500 cursor-pointer text-gray-800",
				)}
				onClick={() => visibility.scrollNext()}
			/>
		</div>
	)
}

function SidePanelTabMenu({ tabsToDisplay }: SidePanelTabMenuProps) {
	return (
		<div className="border-b border-gray-200 px-2">
			<ScrollMenu
				wrapperClassName="flex justify-end whitespace-nowrap"
				scrollContainerClassName="[&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
				LeftArrow={LeftArrow}
				RightArrow={RightArrow}
			>
				{tabsToDisplay.map((tab) => (
					<Tabs.Tab key={tab.label} {...tab} />
				))}
			</ScrollMenu>
		</div>
	)
}

export function _SidePanel({ call, encodedAccessToken, className }: _ISidePanelProps) {
	const { workspace } = useSession()
	const { t } = useLanguage()
	const { sidePanelTab: _sidePanelTab } = useParams<{ sidePanelTab?: string }>()

	const startVideoAtSecondsStr = useSearchParam("startVideoAtSeconds")
	const startVideoAtSeconds = startVideoAtSecondsStr ? parseInt(startVideoAtSecondsStr) : undefined
	const callInviteToken = useInviteToken()
	const callFeaturesVisibility = useCallFeaturesVisibility(call)

	const callSearchParams: CallSearchParams = {
		inviteToken: callInviteToken ?? undefined,
		startVideoAtSeconds,
	}

	const visibleTabSlugs = listVisibleTabSlugs(call, callFeaturesVisibility)

	const sidePanelTab = _sidePanelTab
		? _sidePanelTab
		: !(call instanceof Call) || call.isProcessable
		? call.props.nextBestActionSuggestion || call.props.meetingNotes
			? "overview"
			: defaultTabSlug
		: defaultTabSlug

	const allTabs: Record<SidePanelTabSlug, TabProps> = {
		overview: {
			label: t("Overview"),
			linkTo: makePathToTab(call, "overview", callSearchParams, encodedAccessToken),
			isActive: sidePanelTab === "overview",
			eventName: "Call page interaction",
			eventProperties: {
				tab: "overview",
				callId: call.props.id,
			},
		},
		review: {
			label: t("Scorecard"),
			linkTo: makePathToTab(call, "review", callSearchParams, encodedAccessToken),
			isActive: sidePanelTab === "review",
			eventName: "Call page interaction",
			eventProperties: {
				tab: "review",
				callId: call.props.id,
			},
		},
		coaching: {
			label: t("Coaching"),
			linkTo: makePathToTab(call, "coaching", callSearchParams, encodedAccessToken),
			isActive: sidePanelTab === "coaching",
			eventName: "Call page interaction",
			eventProperties: {
				tab: "coaching",
				callId: call.props.id,
			},
		},
		"lead-knowledge": {
			label: t("AI deal analysis"),
			linkTo: makePathToTab(call, "lead-knowledge", callSearchParams, encodedAccessToken),
			isActive: sidePanelTab === "lead-knowledge",
			eventName: "Call page interaction",
			eventProperties: {
				tab: "lead-knowledge",
				callId: call.props.id,
			},
		},
		transcription: {
			label: t("Transcription"),
			linkTo: makePathToTab(call, "transcription", callSearchParams, encodedAccessToken),
			isActive: sidePanelTab === "transcription",
			eventName: "Call page interaction",
			eventProperties: {
				tab: "transcription",
				callId: call.props.id,
			},
		},
	}

	const tabsToDisplay = visibleTabSlugs.map((slug) => allTabs[slug])

	const panelByTab: Record<SidePanelTabSlug, React.ReactNode> = React.useMemo(
		() => ({
			overview: <_OverviewPanel call={call} />,
			"lead-knowledge": call instanceof Call ? <_LeadKnowledgePanel call={call} /> : null,
			transcription: <_TranscriptionPanel call={call} />,
			review: call instanceof Call ? <_ReviewPanel call={call} /> : null,
			coaching: call instanceof Call ? <_CoachingPanel workspace={workspace} call={call} /> : null,
		}),
		[call, workspace],
	)

	if (!isSidePanelTabSlug(sidePanelTab) || !visibleTabSlugs.some((slug) => slug === sidePanelTab)) {
		return <Navigate to={makeCallPath(call.props.id, { sidePanelTab: defaultTabSlug })} replace={true} />
	}

	return (
		<div className={clsx("border border-gray-200 rounded-lg py-2 flex-1 bg-white max-h-screen", className)}>
			<SidePanelTabMenu tabsToDisplay={tabsToDisplay} />
			<Tabs.Panel>
				{sidePanelTab === "transcription" ? (
					<div className="px-4">{panelByTab.transcription}</div>
				) : (
					<div className="max-h-[60vh] overflow-y-auto px-4">{panelByTab[sidePanelTab]}</div>
				)}
			</Tabs.Panel>
		</div>
	)
}
