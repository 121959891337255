import { Disclosure, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { Bars3Icon, ChevronDownIcon } from "@heroicons/react/20/solid"
import { XMarkIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import { NavLink } from "react-router-dom"

import { useAnalytics } from "../../../contexts/analytics.context"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useLanguage } from "../../../contexts/language.context"
import { useDemoModeEnabled } from "../../../hooks/useLocalStorage"
import { makeWorkspaceBillingPath } from "../../../pages/Settings/config"
import {
	makeAnalyticsDashboardPath,
	makeDashboardPath,
	makeDealsPath,
	makeLibraryPath,
	makeMyTrainingProgramsPath,
	makeRecentCallsPath,
	makeSalesMotionPath,
	makeTrainingProgramsPath,
} from "../../../router/Router"
import { DateProvider } from "../../../utils/time"
import { Badge } from "../../design-system/Badge.component"
import { TrackingLink } from "../../design-system/TrackingLink.component"
import { PaymentDueBanner } from "../PaymentDueBanner.component"
import { _ProfileDropdown } from "./_ProfileDropdown.component"

type NavigationItem =
	| {
			name: string
			linkTo: string
			analyticsName: string
			hasBadge?: boolean
	  }
	| {
			name: string
			analyticsName: string
			submenu: Array<{
				name: string
				linkTo: string
				analyticsName: string
				isComingSoon?: boolean /* possibly unused */
			}>
	  }

export function _Navbar() {
	const { setupEventTracking } = useAnalytics()
	const { t } = useLanguage()
	const { subscription, user, isPlatformAdmin } = useAuthenticatedSession()
	const [isDemoModeEnabled] = useDemoModeEnabled()
	const dateProvider = new DateProvider()

	const navigation: NavigationItem[] = [
		{ name: t("Dashboard"), linkTo: makeDashboardPath(), analyticsName: "Dashboard" },
		{ name: t("Calls"), linkTo: makeRecentCallsPath(), analyticsName: "Calls" },
		{
			name: t("Deals"),
			linkTo: makeDealsPath(),
			analyticsName: "Deals",
		},
		{
			name: t("Training"),
			analyticsName: "Training",
			submenu: [
				...[
					user.isWorkspaceManager()
						? {
								name: t("Training programs"),
								linkTo: makeTrainingProgramsPath(),
								analyticsName: "Training programs",
						  }
						: {
								name: t("Training programs"),
								linkTo: makeMyTrainingProgramsPath(),
								analyticsName: "Training programs",
						  },
				],
				{
					name: t("Library"),
					linkTo: makeLibraryPath(),
					analyticsName: "Library",
				},
				{ name: t("Call analytics"), linkTo: makeAnalyticsDashboardPath(), analyticsName: "Analytics" },
			],
		},
		...(isPlatformAdmin && isDemoModeEnabled // show Sales motion only in demo mode
			? [
					{
						name: t("Sales motion"),
						linkTo: makeSalesMotionPath(),
						analyticsName: "Sales motion",
					},
			  ]
			: []),
	]

	return (
		<>
			{subscription?.isPastDue() && <PaymentDueBanner />}
			<Disclosure as="nav" className="bg-white shadow">
				{({ open }) => (
					<>
						<nav className="bg-navy-700">
							<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
								<div className="relative flex h-16 justify-between">
									<div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
										{/* Mobile menu button */}
										<Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
											<span className="absolute -inset-0.5" />
											<span className="sr-only">Open main menu</span>
											{open ? (
												<XMarkIcon className="block h-6 w-6" aria-hidden="true" />
											) : (
												<Bars3Icon className="block h-6 w-6" aria-hidden="true" />
											)}
										</Disclosure.Button>
									</div>
									<div className="flex flex-1 items-center justify-start ml-10 sm:ml-0">
										<TrackingLink
											className="flex flex-shrink-0 items-center"
											to={makeDashboardPath()}
											eventName="Navbar: logo clicked"
										>
											<img
												className="sm:h-12 h-8 w-auto"
												src={
													process.env.PUBLIC_URL + "/rippletide_logo_text_transparent_bg.svg"
												}
												alt="Rippletide's logo"
											/>
										</TrackingLink>
										<div className="hidden sm:ml-6 sm:block">
											<div className="flex space-x-1 md:space-x-4">
												{navigation.map((navItem, i) => {
													const { trackEvent: trackNavbarItemClicked, debugDOMProps } =
														setupEventTracking({
															eventName: "Navbar: navigation item clicked",
															eventProperties: {
																itemName: navItem.analyticsName,
															},
														})

													if ("submenu" in navItem) {
														return (
															<Menu
																as="div"
																className="relative inline-block text-left z-2"
																key={`menu${i}`}
															>
																<MenuButton className="inline-flex justify-center w-full rounded-md px-4 py-2 text-sm font-medium text-gray-200 bg-navy-700 hover:bg-navy-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
																	{navItem.name}
																	<ChevronDownIcon
																		className="h-5 w-5 ml-2 -mr-1"
																		aria-hidden="true"
																	/>
																</MenuButton>
																<MenuItems className="z-40 absolute right-0 mt-2 w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
																	{navItem.submenu.map((subItem, index) => (
																		<MenuItem key={subItem.name}>
																			{({ active }) =>
																				// eslint-disable-next-line no-constant-condition
																				subItem.isComingSoon && false ? (
																					<div
																						className={clsx(
																							"opacity-50 cursor-not-allowed",
																							"group flex items-center px-4 py-2 text-sm",
																						)}
																					>
																						{subItem.name} (
																						{t("Coming soon")})
																					</div>
																				) : (
																					<NavLink
																						to={subItem.linkTo}
																						className={clsx(
																							index === 0 &&
																								"rounded-t-md",
																							active && "bg-gray-100",
																							subItem.isComingSoon &&
																								"opacity-50 cursor-not-allowed",
																							"text-gray-900 group flex items-center px-4 py-2 text-sm",
																						)}
																						onClick={trackNavbarItemClicked}
																						{...debugDOMProps}
																					>
																						{subItem.name}
																						{subItem.isComingSoon &&
																							" (" +
																								t("Coming soon") +
																								")"}
																					</NavLink>
																				)
																			}
																		</MenuItem>
																	))}
																</MenuItems>
															</Menu>
														)
													}

													return (
														<NavLink
															key={navItem.name}
															to={navItem.linkTo}
															className={({ isActive }) =>
																clsx(
																	isActive ? "text-white" : "text-gray-200",
																	"rounded-md px-2 py-2 text-sm font-medium flex items-center gap-2",
																	"relative whitespace-nowrap",
																)
															}
															onClick={trackNavbarItemClicked}
															{...debugDOMProps}
														>
															{({ isActive }) => (
																<>
																	{navItem.name}
																	{navItem.hasBadge && !isActive && (
																		<span className="absolute top-2 right-0 flex h-2 w-2">
																			<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-400 opacity-75"></span>
																			<span className="relative inline-flex rounded-full h-2 w-2 bg-emerald-600"></span>
																		</span>
																	)}
																</>
															)}
														</NavLink>
													)
												})}
											</div>
										</div>
									</div>
									<div className="ml-6 flex items-center">
										{subscription?.isTrial() && (
											<>
												<TrackingLink
													to={makeWorkspaceBillingPath()}
													eventName="Navbar: free trial badge clicked"
													className="hidden md:block"
												>
													<Badge
														label={t("freeTrialRemainingDays", {
															count: dateProvider.computeDaysUntil(
																subscription.properties.endAt,
															),
														})}
														variant="neutral"
														className="bg-gradient-to-bl from-pink to-salmon text-navy-700"
													/>
												</TrackingLink>
												<TrackingLink
													to={makeWorkspaceBillingPath()}
													eventName="Navbar: free trial badge clicked"
													className="block md:hidden"
												>
													<Badge
														label={t("Trial")}
														variant="neutral"
														className="bg-gradient-to-bl from-pink to-salmon text-navy-700"
													/>
												</TrackingLink>
											</>
										)}
										<div className="ml-6 flex items-center">
											<_ProfileDropdown />
										</div>
									</div>
								</div>
							</div>
						</nav>
						<Disclosure.Panel className="sm:hidden">
							<div className="space-y-1 px-2 pb-3 pt-2">
								{navigation.map((item) => {
									if ("submenu" in item) {
										return item.submenu
											.filter((x) => !x.isComingSoon)
											.map((subItem) => (
												<NavLink
													key={subItem.name}
													to={subItem.linkTo}
													className={({ isActive }) =>
														clsx(
															isActive
																? "border-navy-500 bg-navy-700 bg-opacity-10 text-navy-700 border-l-4"
																: "text-gray-600 ",
															"block py-2 pl-3 pr-4 text-base font-medium sm:pl-5 sm:pr-6 hover:text-navy-500",
														)
													}
												>
													{subItem.name}
												</NavLink>
											))
									}

									return (
										<NavLink
											key={item.name}
											to={item.linkTo}
											className={({ isActive }) =>
												clsx(
													isActive
														? "border-navy-500 bg-navy-700 bg-opacity-10 text-navy-700 border-l-4"
														: "text-gray-600 ",
													"block py-2 pl-3 pr-4 text-base font-medium sm:pl-5 sm:pr-6 hover:text-navy-500",
												)
											}
										>
											{item.name}
										</NavLink>
									)
								})}
							</div>
						</Disclosure.Panel>
					</>
				)}
			</Disclosure>
		</>
	)
}
