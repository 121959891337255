import assertNever from "assert-never"

import type { CalendarPlatform } from "../../core/application/gateways/calendar-events.gateway"

export function getCalendarPlatformDisplayName(platform: CalendarPlatform): string {
	switch (platform) {
		case "google_calendar":
			return "Google Calendar"

		case "microsoft_outlook":
			return "Microsoft Outlook"

		default:
			assertNever(platform)
	}
}
