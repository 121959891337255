import { useEffect, useRef, useState } from "react"

import type {
	ForecastTimePeriod,
	GetDealsForecastsAggregateRouteResponse,
	GetDealsForecastsAggregateRouteResponseError,
} from "../../../../../core/application/gateways/deals.gateway/schemas/forecast"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useDependencies } from "../../../../contexts/dependencies.context"

type DataState = {
	data: GetDealsForecastsAggregateRouteResponse["data"]
	error?: NoForecastsReason
}

type NoForecastsReason =
	| { type: "fetching" }
	| { type: "fetch-error" }
	| { type: "requires-enterprise-plan" }
	| { type: "requires-crm-connection" }
	| GetDealsForecastsAggregateRouteResponseError

export function useForecast() {
	const { dealsGateway } = useDependencies()
	const { workspace } = useAuthenticatedSession()

	const [timePeriod, setTimePeriod] = useState<ForecastTimePeriod>("this-quarter")
	const [data, setData] = useState<DataState>({
		data: [],
		error: { type: "fetching" },
	})

	const fetchIdRef = useRef(0)

	useEffect(() => {
		const fetchData = async () => {
			const currentFetchId = ++fetchIdRef.current

			setData({ data: [], error: { type: "fetching" } })
			try {
				const result = await dealsGateway.getForecastsAggregate({ timePeriod })

				if (fetchIdRef.current === currentFetchId) {
					if (result.error) {
						setData({ data: [], error: result.error })
					} else {
						setData({ data: result.data })
					}
				}
			} catch (error) {
				if (fetchIdRef.current === currentFetchId) {
					setData({ data: [], error: { type: "fetch-error" } })
				}
			}
		}

		if (!workspace.isPlanFeatureEnabled("crm-integration")) {
			setData({ data: [], error: { type: "requires-enterprise-plan" } })
		}

		if (!workspace.crmIntegration) {
			setData({ data: [], error: { type: "requires-crm-connection" } })
		}

		if (!workspace.props.featureFlags.forecasting) {
			setData({ data: [], error: { type: "requires-forecasting-feature" } })
		}

		fetchData()
	}, [timePeriod, dealsGateway, workspace])

	return {
		data,
		timePeriod,
		setTimePeriod,
	}
}
