import { PopoverButton } from "@headlessui/react"
import { ChatBubbleBottomCenterTextIcon, TagIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import React from "react"

import type { TagType } from "../../../core/domain/TagType.entity"
import { type TranslationKey, useLanguage } from "../../contexts/language.context"
import { BadgeCollection } from "../design-system/BadgeCollection.component"
import { Tooltip } from "../design-system/Tooltip.component"
import { TagSelectionMenu } from "./TagSelectionMenu.component"

type TagFilterProps = {
	selectedTagIds: string[]
	onTagSelectionChange: (selectedTagIds: string[]) => void
	availableTags: TagType[] | "loading"
	hidePreviewTagList?: boolean
}

export function CallTagFilter({
	onTagSelectionChange,
	selectedTagIds,
	availableTags,
	hidePreviewTagList,
}: TagFilterProps) {
	const { t, exists: translationKeyExists } = useLanguage()

	const tagsAvailableToChooseFrom = availableTags === "loading" ? [] : availableTags
	const visibleCallTags = tagsAvailableToChooseFrom.filter((tag) => selectedTagIds.includes(tag.properties.id))

	const tagsToDisplay = visibleCallTags.slice(0, 2)
	const remainingTags = visibleCallTags.slice(2)

	const handleTagToggle = React.useCallback(
		(tag: TagType, selected: boolean) => {
			onTagSelectionChange(
				selected
					? [...selectedTagIds, tag.properties.id]
					: selectedTagIds.filter((id) => id !== tag.properties.id),
			)
		},
		[onTagSelectionChange, selectedTagIds],
	)

	const customPopover = (
		<PopoverButton
			className={clsx(
				"inline-flex items-center gap-x-1.5 rounded-md focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-700 disabled:opacity-50 disabled:cursor-not-allowed",
				" disabled:bg-gray-200 disabled:text-gray-400 ring-1 ring-inset",
				"px-3 py-2 text-sm",
				selectedTagIds.length
					? "bg-indigo-50 text-indigo-500 hover:bg-indigo-100 ring-indigo-300"
					: "bg-white text-black hover:bg-gray-100 ring-gray-300",
			)}
		>
			<TagIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
			Tags{selectedTagIds.length ? ` (${selectedTagIds.length})` : ""}
		</PopoverButton>
	)

	return (
		<div className={clsx("flex items-center gap-3")}>
			<TagSelectionMenu
				tags={tagsAvailableToChooseFrom}
				selectedTagIds={selectedTagIds}
				onTagSelectionChange={handleTagToggle}
				popoverButton={customPopover}
				tooltipContent="Filter by tags"
			/>
			{tagsToDisplay.length > 0 && !hidePreviewTagList && (
				<div className="xl:flex hidden items-center flex-wrap">
					<BadgeCollection
						items={tagsToDisplay.map((tag) => {
							const tagName =
								tag.isDefaultTag() && tag.properties.slug && translationKeyExists(tag.properties.slug)
									? t(tag.properties.slug as TranslationKey)
									: tag.properties.name

							return {
								text: tagName,
								color: tag.properties.color,
								icon: tag.isExcerptTag() ? (
									<ChatBubbleBottomCenterTextIcon className="h-4 w-4" />
								) : undefined,
							}
						})}
						variant="condensed"
						canExpand={false}
					/>
					{remainingTags.length > 0 && (
						<Tooltip
							content={remainingTags
								.map((tag) => {
									return tag.isDefaultTag() &&
										tag.properties.slug &&
										translationKeyExists(tag.properties.slug)
										? t(tag.properties.slug as TranslationKey)
										: tag.properties.name
								})
								.join(", ")}
							wrapperClassName="ml-1.5 flex items-center justify-center h-6 w-6 bg-gray-200 text-gray-500 rounded-full"
						>
							<span className="text-xs">+{remainingTags.length}</span>
						</Tooltip>
					)}
				</div>
			)}
		</div>
	)
}
