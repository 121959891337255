import { z } from "zod"

const reactionTypeSchema = z.enum(["thumbsUp", "thumbsDown"])

export const apiCallHighlightSchema = z
	.object({
		id: z.string(),
		containingLibraryFolderId: z.string().optional(),
		startsAtMs: z.number(),
		endsAtMs: z.number(),
		comment: z.string(),
		creatorFullName: z.string().optional(),
		thumbnailUrl: z.string().optional(),
		creationDate: z.coerce.date(),
		callId: z.string(),
		callName: z.string(),
		callDate: z.coerce.date(),
		callDurationSec: z.number(),
		reactions: z
			.object({
				thumbsUpCount: z.number(),
				thumbsDownCount: z.number(),
				userReaction: reactionTypeSchema.optional(),
			})
			.transform((data) => ({
				...data,
				userReaction: data.userReaction,
			})),
	})
	// https://stackoverflow.com/questions/71477015/specify-a-zod-schema-with-a-non-optional-but-possibly-undefined-field
	.transform((data) => ({
		...data,
		containingLibraryFolderId: data.containingLibraryFolderId,
		creatorFullName: data.creatorFullName,
	}))

export const apiCallHighlightsSchema = z.array(apiCallHighlightSchema)
export type ApiCallHighlights = z.infer<typeof apiCallHighlightsSchema>[number]
