import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import _ from "lodash"
import React from "react"

import type { Call } from "../../../core/domain/Call.entity"
import type { TagType } from "../../../core/domain/TagType.entity"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useDependencies } from "../../contexts/dependencies.context"
import { type TranslationKey, useLanguage } from "../../contexts/language.context"
import { BadgeCollection } from "../design-system/BadgeCollection.component"
import { Tooltip } from "../design-system/Tooltip.component"
import { TagSelectionMenu } from "./TagSelectionMenu.component"

type CallTagListProps = {
	call: Call
	availableTags: TagType[] | "loading"
	onSaveSuccess?: () => Promise<void>
	badgeCollectionVariant?: "condensed" | "expanded"
	className?: string
}

export function CallTagList({
	call,
	availableTags,
	onSaveSuccess,
	badgeCollectionVariant,
	className,
}: CallTagListProps) {
	const { t, exists: translationKeyExists } = useLanguage()
	const [isSaving, setIsSaving] = React.useState(false)
	const { callsGateway } = useDependencies()
	const { workspace } = useAuthenticatedSession()
	const hiddenTagIds = React.useMemo(() => workspace.props.preferences?.tagIdsToHide ?? [], [workspace])

	const visibleCallTags = React.useMemo(
		() => call.props.tags?.filter((tag) => !hiddenTagIds.includes(tag.properties.id)) ?? [],
		[call.props.tags, hiddenTagIds],
	)

	const tagsAvailableToChooseFrom =
		availableTags === "loading"
			? "loading"
			: availableTags.filter(
					(tag) =>
						tag.isCallTag() ||
						visibleCallTags.some((visibleTag) => visibleTag.properties.id === tag.properties.id),
			  )

	const [selectedTagIds, setSelectedTagIds] = React.useState<string[]>(
		visibleCallTags.map((tag) => tag.properties.id),
	)
	React.useEffect(() => {
		setSelectedTagIds(visibleCallTags.map((tag) => tag.properties.id) ?? [])
	}, [visibleCallTags])

	const handleTagToggle = React.useCallback((tag: TagType, selected: boolean) => {
		setSelectedTagIds((prevSelectedTagIds) => {
			if (selected) {
				return [...prevSelectedTagIds, tag.properties.id]
			}
			return prevSelectedTagIds.filter((id) => id !== tag.properties.id)
		})
	}, [])

	const handleSave = React.useCallback(
		async (closeCb: () => void) => {
			const hasRemovedExcerptTag = !selectedTagIds.some(
				(tagId) => visibleCallTags.find((tag) => tag.properties.id === tagId)?.isExcerptTag(),
			)
			if (
				hasRemovedExcerptTag &&
				!window.confirm(
					t(
						"This tag is attached to one or more specific snippets of your call. Are you sure you want to remove all instances of this tag in the call?",
					),
				)
			) {
				return
			}

			setIsSaving(true)
			await callsGateway.setTags(call, selectedTagIds)
			closeCb()
			if (onSaveSuccess) {
				await onSaveSuccess()
			}
			setIsSaving(false)
		},
		[selectedTagIds, t, callsGateway, call, onSaveSuccess, visibleCallTags],
	)

	const hasModifiedTags = !_.isEqual(
		selectedTagIds.sort((a, b) => a.localeCompare(b)),
		visibleCallTags.map((tag) => tag.properties.id).sort((a, b) => a.localeCompare(b)),
	)

	const tagsToDisplay = visibleCallTags.slice(0, 2)
	const remainingTags = visibleCallTags.slice(2)

	return (
		<div className={clsx("flex items-center gap-3", className)}>
			{tagsToDisplay.length > 0 && (
				<div className="flex items-center flex-wrap">
					<BadgeCollection
						items={tagsToDisplay.map((tag) => {
							const tagName =
								tag.isDefaultTag() && tag.properties.slug && translationKeyExists(tag.properties.slug)
									? t(tag.properties.slug as TranslationKey)
									: tag.properties.name

							return {
								text: tagName,
								color: tag.properties.color,
								icon: tag.isExcerptTag() ? (
									<ChatBubbleBottomCenterTextIcon className="h-4 w-4" />
								) : undefined,
							}
						})}
						variant={badgeCollectionVariant}
					/>
					{remainingTags.length > 0 && (
						<Tooltip
							content={remainingTags
								.map((tag) => {
									return tag.isDefaultTag() &&
										tag.properties.slug &&
										translationKeyExists(tag.properties.slug)
										? t(tag.properties.slug as TranslationKey)
										: tag.properties.name
								})
								.join(", ")}
							wrapperClassName="ml-1.5 flex items-center justify-center h-6 w-6 bg-gray-200 text-gray-500 rounded-full"
						>
							<span className="text-xs">+{remainingTags.length}</span>
						</Tooltip>
					)}
				</div>
			)}

			<TagSelectionMenu
				tags={tagsAvailableToChooseFrom}
				selectedTagIds={selectedTagIds}
				onTagSelectionChange={handleTagToggle}
				isSaveDisabled={!hasModifiedTags}
				isSaving={isSaving}
				onSave={handleSave}
			/>
		</div>
	)
}
