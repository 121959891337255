import { useLanguage } from "../../../contexts/language.context"

export function DealListEmptyStateOverlay() {
	const { t } = useLanguage()
	return (
		<>
			<div className="absolute inset-0 bg-gradient-to-t from-white z-50 pointer-events-none" />
			<div className="absolute inset-0 z-50 flex items-center justify-center overflow-y-auto">
				<div className="relative bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
					<h3 className="text-lg font-semibold leading-6 text-gray-900 mb-4">
						{t("Record your prospect calls to view and manage your deals")}
					</h3>
					<p className="text-gray-600">
						{t(
							"Record your first calls to get a clear overview of the current status of your ongoing deals and their movement through each stage of your pipeline, allowing you to quickly identify where there are risks or actions to be taken.",
						)}
					</p>
				</div>
			</div>
		</>
	)
}
