import { z } from "zod"

import {
	EXPLORE_WORKSPACE_ID,
	INTESCIA_WORKSPACE_ID,
	RIPPLETIDE_DEMO_WORKSPACE_ID,
	RIPPLETIDE_WORKSPACE_ID,
} from "../../config"
import type { WorkspaceLanguage } from "../infra/gateways/http.authentication.gateway/response-schemas"
import type { User } from "./User.entity"

export const supportedWorkspaceLanguageCodeSchema = z.enum(["en", "fr", "es", "it"])
export type SupportedWorkspaceLanguageCode = z.infer<typeof supportedWorkspaceLanguageCodeSchema>

export const salesforceIntegrationOAuthCredentialsSchema = z.object({
	type: z.literal("oauth"),
	clientId: z.string(),
	clientSecret: z.string(),
	loginUrl: z.string(),
})

export type SalesforceIntegrationOAuthCredentials = z.infer<typeof salesforceIntegrationOAuthCredentialsSchema>

export const salesforceIntegrationPasswordCredentialsSchema = z.object({
	type: z.literal("password"),
	username: z.string(),
	password: z.string(),
	securityToken: z.string(),
	loginUrl: z.string(),
})

export type SalesforceIntegrationPasswordCredentials = z.infer<typeof salesforceIntegrationPasswordCredentialsSchema>

const salesforceIntegrationSchema = z.object({
	provider: z.literal("salesforce"),
	instanceUrl: z.string(),
	credentials: z.discriminatedUnion("type", [
		salesforceIntegrationOAuthCredentialsSchema,
		salesforceIntegrationPasswordCredentialsSchema,
	]),
})

const hubspotIntegrationSchema = z.object({
	provider: z.literal("hubspot"),
	instanceUrl: z.string(),
	credentials: z.object({
		type: z.literal("accessToken"),
		accessToken: z.string(),
	}),
})

export type SalesforceIntegration = z.infer<typeof salesforceIntegrationSchema>
export type HubspotIntegration = z.infer<typeof hubspotIntegrationSchema>

export const crmIntegrationSchema = z.discriminatedUnion("provider", [
	salesforceIntegrationSchema,
	hubspotIntegrationSchema,
])
export type CrmIntegration = z.infer<typeof crmIntegrationSchema>

type WorkspaceFeatureFlags = {
	callScoringAnalysis?: boolean
	displayFeatureAlternatives?: boolean
	forecasting?: boolean
}

export type PlanFeatureName =
	| "calendar-integration"
	| "crm-integration"
	| "library"
	| "rippletide-coach"
	| "briefing"
	| "coaching"
	| "deals"

export const workspaceDetailsSchema = z.object({
	companyName: z.string().optional(),
	companySize: z.string().optional(),
	role: z.string().optional(),
	goal: z.string().optional(),
})

export const workspacePreferencesSchema = z.object({
	hideMeddicScoring: z.boolean().optional(),
	meddicSectionsToHide: z.array(z.string()).optional(),
	tagIdsToHide: z.array(z.string()).optional(),
})

export type WorkspaceDetails = z.infer<typeof workspaceDetailsSchema>

export type WorkspacePreferences = z.infer<typeof workspacePreferencesSchema>

export type WorkspaceProperties = {
	id: string
	crmIntegration?: CrmIntegration
	featureFlags: WorkspaceFeatureFlags
	language: WorkspaceLanguage
	plan?: {
		name: string
		features: Record<PlanFeatureName, boolean>
	}
	couponId?: string
	details?: WorkspaceDetails
	companyLogoId?: string
	preferences?: WorkspacePreferences
	termsAcceptedAt?: Date | undefined
	termsAcceptedByUserWithEmail?: string | undefined
	defaultMeetingNotesTemplateId?: string
}

export class Workspace {
	private constructor(public props: WorkspaceProperties) {}

	public get id() {
		return this.props.id
	}
	public get hasCrmIntegrationSetup() {
		return !!this.crmIntegration
	}

	public get crmIntegration() {
		return this.props.crmIntegration
	}

	public mustAcceptTerms(workspaceUsers: User[] | null) {
		const isTermsAccepted = Boolean(this.props.termsAcceptedAt)
		if (isTermsAccepted) {
			return false
		}

		return (workspaceUsers?.length ?? 0) >= 5
	}

	public isFreePlan() {
		return !this.props.plan
	}

	public isInTrial() {
		return Boolean(this.props.plan?.name.toLowerCase().includes("trial"))
	}

	public isPlanFeatureEnabled(feature: PlanFeatureName | "call-recording"): boolean {
		if (feature === "call-recording") {
			return this.isPlanFeatureEnabled("calendar-integration") || this.isPlanFeatureEnabled("rippletide-coach")
		}
		return Boolean(this.props.plan?.features[feature]) ?? false
	}

	public hasEnterprisePlan() {
		return this.props.plan?.name.toLowerCase().includes("enterprise")
	}

	public isAllowedToInviteUsers() {
		return Boolean(this.props.plan)
	}

	// this condition is checked in the backend when checking for platform admin permissions
	public isRippletideWorkspace() {
		return [RIPPLETIDE_WORKSPACE_ID, RIPPLETIDE_DEMO_WORKSPACE_ID].includes(this.props.id)
	}

	public isIntesciaWorkspace() {
		return this.props.id === INTESCIA_WORKSPACE_ID
	}

	public isExploreWorkspace() {
		return this.props.id === EXPLORE_WORKSPACE_ID
	}

	public static fromProperties(props: WorkspaceProperties) {
		return new Workspace(props)
	}
}
