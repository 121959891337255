import _ from "lodash"
import React from "react"
import { useSearchParams } from "react-router-dom"

import type { CalendarEvent } from "../../../../../core/domain/CalendarEvent.entity/CalendarEvent.entity"
import { Modal } from "../../../../components/design-system/Modal.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { BriefRenderer } from "../../../../components/shared/BriefRenderer.component"
import { useLanguage } from "../../../../contexts/language.context"
import { DateFormatter, DateProvider } from "../../../../utils/time"
import { useCalendarEventBriefing } from "../TodaysProspectingScheduleCard/useCalendarEventBriefing"
import { EventRow } from "./EventRow.component"

type DailyEventListProps = {
	calendarEvents: CalendarEvent[]
	handleCalendarEventAction: (
		calendarEventId: string,
		eventAction: "send-bot-to-meeting-now" | "remove-bot-from-call",
	) => void
}

function getNext7Days(): Date[] {
	const days: Date[] = []
	const today = new Date()

	for (let i = 0; i < 7; i++) {
		const nextDay = new Date(today)
		nextDay.setDate(today.getDate() + i)
		days.push(nextDay)
	}

	return days
}

function isSameDay(date1: Date, date2: Date): boolean {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	)
}

type BriefModalProps = {
	calendarEvent: CalendarEvent
}

function BriefModal({ calendarEvent }: BriefModalProps) {
	const { isBriefingAlreadyRead, markBriefingAsRead } = useCalendarEventBriefing(calendarEvent)
	const [searchParams, setSearchParams] = useSearchParams()
	const { t } = useLanguage()

	const handleCloseBriefingModal = React.useCallback(() => {
		markBriefingAsRead()

		const newSearchParams = new URLSearchParams(searchParams)
		newSearchParams.delete(briefQueryParamName)
		setSearchParams(newSearchParams)
	}, [markBriefingAsRead, searchParams, setSearchParams])

	if (!calendarEvent.briefing) {
		return null
	}

	return (
		<Modal open={true} onClose={handleCloseBriefingModal}>
			<BriefRenderer briefing={calendarEvent.briefing} />
			{!isBriefingAlreadyRead && (
				<div className="flex justify-end mt-4">
					<TrackingButton
						eventName="Mark briefing as read clicked"
						className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						onClick={handleCloseBriefingModal}
					>
						<span className="text-s text-gray-500">{t("Mark as read")}</span>
					</TrackingButton>
				</div>
			)}
		</Modal>
	)
}

const briefQueryParamName = "openBriefOfEventId"

export function DailyEventList({ calendarEvents, handleCalendarEventAction }: DailyEventListProps) {
	const { t, language } = useLanguage()
	const dateProvider = new DateProvider()
	const dateFormatter = new DateFormatter(dateProvider, t)
	const [searchParams, setSearchParams] = useSearchParams()

	const handleOpenBriefingModal = React.useCallback(
		(calendarEvent: CalendarEvent) => {
			const newSearchParams = new URLSearchParams(searchParams)
			newSearchParams.set(briefQueryParamName, calendarEvent.id)
			setSearchParams(newSearchParams)
		},
		[searchParams, setSearchParams],
	)

	const calendarEventIdToDisplayBrief = searchParams.get(briefQueryParamName)
	const calendarEventToDisplayBrief = calendarEvents.find((event) => event.id === calendarEventIdToDisplayBrief)

	// Get the next 7 days
	const daysInNextWeek = getNext7Days()

	// Map each day to its events
	const daysWithEvents = daysInNextWeek.map((day) => {
		const eventsStartingThatDay = calendarEvents.filter((calendarEvent) =>
			isSameDay(new Date(calendarEvent.startTime), day),
		)

		return { date: day, events: eventsStartingThatDay }
	})

	return (
		<>
			{calendarEventToDisplayBrief && <BriefModal calendarEvent={calendarEventToDisplayBrief} />}
			<div className="divide-y divide-gray-200 h-[84vh] overflow-y-auto">
				{daysWithEvents.map(({ date, events }) => (
					<div className="py-2 px-4" key={date.getTime()}>
						<h3 className="text-md font-semibold text-gray-900 mb-2">
							{_.startCase(
								dateFormatter.formatDate(date, language, {
									weekday: "long",
									day: "2-digit",
									month: "long",
								}),
							)}
						</h3>
						{!events.length ? (
							<p className="text-gray-600 text-sm">{t("No calls scheduled")}</p>
						) : (
							<div className="divide-y divide-gray-200">
								{events.map((event) => (
									<EventRow
										calendarEvent={event}
										key={event.id}
										handleCalendarEventAction={handleCalendarEventAction}
										handleOpenBriefingModal={handleOpenBriefingModal}
									/>
								))}
							</div>
						)}
					</div>
				))}
			</div>
		</>
	)
}
