import { Draggable } from "@hello-pangea/dnd"
import { XMarkIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import React from "react"

import type { CallHighlight } from "../../../../../core/domain/Library.entity"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { CallHighlightCardContents } from "../../../Library/components/CurrentLibraryFolder/CurrentLibraryFolderHighlightsGrid.component"
import { DragHandle } from "./DragHandle.component"

type EditContentCardProps = {
	highlight: CallHighlight
	onRemoveHighlight: (highlight: CallHighlight) => void
	index: number
}

export function EditContentCard({ onRemoveHighlight, highlight, index }: EditContentCardProps) {
	return (
		<Draggable key={highlight.id} draggableId={highlight.id} index={index}>
			{(provided, snapshot) => {
				return (
					<div
						className={clsx(
							"border border-gray-200 bg-white p-4 m-4 flex rounded-md group !left-auto !top-auto",
							snapshot.isDragging && "shadow-lg",
						)}
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={provided.draggableProps.style}
					>
						<DragHandle />
						<CallHighlightCardContents
							key={highlight.id}
							contents={{
								highlight,
								isSelected: false,
							}}
						/>
						<div className="flex-shrink-0 mb-auto mt-2 flex-col items-end flex justify-between h-full">
							<TrackingButton
								onClick={() => onRemoveHighlight(highlight)}
								eventName="Training program edit content: remove content button clicked"
							>
								<XMarkIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
							</TrackingButton>
						</div>
					</div>
				)
			}}
		</Draggable>
	)
}
