import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { makeEditTrainingProgramPath } from "../../../../router/Router"
import { EditTrainingProgramLayout } from "../components/EditTrainingProgramLayout.component"
import { useTrainingProgram } from "../contexts/training-program.context"

/**
 * URLs:
 *   /training/programs/:programId/edit/name
 *   /training/programs/new/edit/name
 */

type FormStatus = { status: "idle" } | { status: "loading" } | { status: "success" } | { status: "error" }

export function EditNamePage() {
	const { trainingGateway } = useDependencies()
	const navigate = useNavigate()
	const { trainingProgram, programId } = useTrainingProgram()

	const [formStatus, setFormStatus] = useState<FormStatus>({ status: "idle" })

	const [programName, setProgramName] = useState("")
	const [programDescription, setProgramDescription] = useState("")

	useEffect(() => {
		if (trainingProgram) {
			setProgramName(trainingProgram.trainingProgram.name)
			setProgramDescription(trainingProgram.trainingProgram.description)
		}
	}, [trainingProgram])

	const onSubmit = async () => {
		try {
			setFormStatus({ status: "loading" })
			const { programId: newProgramId } = await trainingGateway.updateTrainingProgram(programId, {
				type: "name",
				name: programName,
				description: programDescription,
			})
			navigate(makeEditTrainingProgramPath(newProgramId, "content"))
			setFormStatus({ status: "success" })
		} catch (e) {
			setFormStatus({ status: "error" })
			alert("Failed to save")
		}
	}

	const isFormDisabled = formStatus.status === "loading" || formStatus.status === "success" || programName === ""

	return (
		<EditTrainingProgramLayout currentStep="name" onSaveClick={onSubmit} isSaveDisabled={isFormDisabled}>
			<div className="flex-1 border border-gray-200 shadow-sm rounded-md p-6 bg-white mt-6">
				<Form
					{...{
						programName,
						programDescription,
						setProgramName,
						setProgramDescription,
						isFormDisabled,
						formStatus,
					}}
				/>
			</div>
		</EditTrainingProgramLayout>
	)
}

type FormProps = {
	programName: string
	programDescription: string

	setProgramName: (name: string) => void
	setProgramDescription: (description: string) => void
}

function Form({ programName, programDescription, setProgramName, setProgramDescription }: FormProps) {
	const { t } = useLanguage()

	return (
		<form className="space-y-6">
			<div>
				<label htmlFor="programName" className="block text-sm font-medium leading-6 text-gray-900">
					{t("Name")}
				</label>
				<div className="mt-2">
					<input
						value={programName}
						onChange={(event) => setProgramName(event.target.value)}
						name="programName"
						type="text"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					/>
				</div>
			</div>

			<div>
				<label htmlFor="ownerLastName" className="block text-sm font-medium leading-6 text-gray-900">
					{t("Description")}
				</label>
				<div className="mt-2">
					<textarea
						value={programDescription}
						onChange={(event) => setProgramDescription(event.target.value)}
						name="lastName"
						rows={3}
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					/>
				</div>
			</div>
		</form>
	)
}
