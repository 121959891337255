import { z } from "zod"

import type { IMeetingNotesTemplatesGateway } from "../../application/gateways/meeting-notes-templates.gateway"
import {
	type CreateMeetingNotesTemplateParams,
	type MeetingNotesPreviewResponse,
	MeetingNotesPreviewSchema,
	MeetingNotesTemplateWithSectionsSchema,
	type UpdateMeetingNotesTemplateParams,
} from "../../application/gateways/users.gateway/meeting-notes-template"
import type { MeetingNotesTemplateWithSections } from "../../domain/MeetingNotesTemplate.entity"

export class HttpMeetingNotesTemplatesGateway implements IMeetingNotesTemplatesGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async listMeetingNotesTemplates(): Promise<MeetingNotesTemplateWithSections[]> {
		const endpointUrl = `${this.baseApiUrl}/templates`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to list meeting notes templates")
		}

		const json = await res.json()
		const parsed = z.array(MeetingNotesTemplateWithSectionsSchema).safeParse(json)
		if (!parsed.success) {
			console.error(parsed.error.issues)
			throw new Error("Response data did not match the expected structure")
		}

		return parsed.data
	}

	public async updateMeetingNotesTemplate(params: UpdateMeetingNotesTemplateParams): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/templates/${params.templateId}`
		const res = await fetch(endpointUrl, {
			method: "PATCH",
			credentials: "include",
			body: JSON.stringify({
				title: params.title,
				sections: params.sections,
				makeDefault: params.makeDefault,
			}),
			headers: {
				"Content-Type": "application/json",
			},
		})

		if (!res.ok) {
			throw new Error("Failed to update meeting notes template")
		}
	}

	public async deleteMeetingNotesTemplate(templateId: string): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/templates/${templateId}`
		const res = await fetch(endpointUrl, {
			method: "DELETE",
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to delete meeting notes template")
		}
	}

	public async createMeetingNotesTemplate(
		params: CreateMeetingNotesTemplateParams,
	): Promise<MeetingNotesTemplateWithSections> {
		const endpointUrl = `${this.baseApiUrl}/templates`
		const res = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
			},
		})

		if (!res.ok) {
			throw new Error("Failed to create meeting notes template")
		}

		const json = await res.json()
		return MeetingNotesTemplateWithSectionsSchema.parse(json)
	}

	public async previewMeetingNotesTemplate(templateId: string, callId: string): Promise<MeetingNotesPreviewResponse> {
		const endpointUrl = `${this.baseApiUrl}/templates/${templateId}/preview`
		const res = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify({
				callId,
			}),
			headers: {
				"Content-Type": "application/json",
			},
		})

		if (!res.ok) {
			throw new Error("Failed to preview meeting notes template")
		}

		const json = await res.json()
		return MeetingNotesPreviewSchema.parse(json)
	}

	public async setCurrentMeetingNotesTemplate(templateId: string): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/templates/${templateId}/current`
		const res = await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to set current meeting notes template")
		}
	}
}
