import React from "react"

import { useLanguage } from "../../../../../contexts/language.context"

type ISectionEditNameFormProps = {
	sectionName: string
	sectionDescription: string

	onSectionNameChange: (name: string) => void
	onSectionDescriptionChange: (description: string) => void
}

export function SectionEditNameForm({
	sectionName,
	sectionDescription,
	onSectionNameChange,
	onSectionDescriptionChange,
}: ISectionEditNameFormProps) {
	const { t } = useLanguage()

	return (
		<form className="space-y-6">
			<div>
				<label htmlFor="programName" className="block text-sm font-medium leading-6 text-gray-900">
					{t("Section name")}
				</label>
				<div className="mt-2">
					<input
						value={sectionName}
						onChange={(event) => onSectionNameChange(event.target.value)}
						name="sectionName"
						type="text"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					/>
				</div>
			</div>

			<div>
				<label htmlFor="ownerLastName" className="block text-sm font-medium leading-6 text-gray-900">
					{t("Section description (optional)")}
				</label>
				<div className="mt-2">
					<textarea
						value={sectionDescription}
						onChange={(event) => onSectionDescriptionChange(event.target.value)}
						name="sectionDescription"
						rows={3}
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					/>
				</div>
			</div>
		</form>
	)
}
