import React from "react"
import { useMount } from "react-use"

import type { PublicCall } from "../../core/domain/PublicCall.entity"
import { useDependencies } from "../contexts/dependencies.context"

export function usePublicCall(publicAccessToken: string | undefined): {
	publicCall: PublicCall | null
	isLoading: boolean
	error: Error | null
} {
	const { callsGateway } = useDependencies()
	const [publicCall, setPublicCall] = React.useState<PublicCall | null>(null)
	const [isLoading, setIsLoading] = React.useState(false)
	const [error, setError] = React.useState<Error | null>(null)

	const getPublicCall = React.useCallback(async () => {
		if (!publicAccessToken) {
			return
		}

		try {
			setIsLoading(true)
			setError(null)
			setPublicCall(await callsGateway.getByPublicAccessToken(publicAccessToken))
		} catch (e) {
			setPublicCall(null)
			if (e instanceof Error) {
				setError(e)
			} else {
				throw e
			}
		} finally {
			setIsLoading(false)
		}
	}, [publicAccessToken, callsGateway])

	useMount(getPublicCall)

	return { publicCall, isLoading, error }
}
