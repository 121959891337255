import React from "react"

import type { ForecastTimePeriod } from "../../../../../core/application/gateways/deals.gateway/schemas/forecast"
import { useLanguage } from "../../../../contexts/language.context"

export type DealSelectFilter = "all" | "at-risk"

export type DealClosingDateFilter = "1-month" | "3-months" | "6-months" | "12-months"

export type DealChangesFilter = "7-days" | "30-days" | "90-days"

type ForecastFiltersProps = {
	timePeriod: ForecastTimePeriod
	setTimePeriod: (timePeriod: ForecastTimePeriod) => void
}

export function ForecastFilters({ timePeriod, setTimePeriod }: ForecastFiltersProps) {
	const { t } = useLanguage()

	const timePeriodOptions: DropdownProps<ForecastTimePeriod>["options"] = [
		{ value: "this-quarter", label: t("This quarter") },
		{ value: "this-month", label: t("This month") },
	]

	return (
		<div className="flex flex-row gap-3 items-end mb-2 justify-center sm:justify-normal flex-wrap">
			<Dropdown
				title={t("Time period")}
				options={timePeriodOptions}
				selectedValue={timePeriod}
				disabled={false}
				onSelect={setTimePeriod}
			/>
		</div>
	)
}

type DropdownProps<T extends string> = {
	title: string
	options: Array<{ value: T; label: string }>
	selectedValue: T
	disabled: boolean
	onSelect: (value: T) => void
}

function Dropdown<T extends string>({ title, options, selectedValue, disabled, onSelect }: DropdownProps<T>) {
	return (
		<div>
			<label htmlFor="deals" className="block text-sm font-medium leading-6 text-gray-900">
				{title}
			</label>
			<select
				id="deals"
				name="deals"
				value={selectedValue}
				onChange={(e) => onSelect(e.target.value as T)}
				className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
				disabled={disabled}
			>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	)
}
