import assertNever from "assert-never"
import clsx from "clsx"
import _ from "lodash"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useInterval } from "react-use"

import type { MyTrainingProgramSummary } from "../../../../core/application/gateways/training.gateway/schemas/my-training-program-summary.schema"
import type { TrainingProgramWithStats } from "../../../../core/application/gateways/training.gateway/schemas/training-program-with-stats.schema"
import { SpinnerIcon } from "../../../components/design-system/SpinnerIcon.component"
import { TrackingLink } from "../../../components/design-system/TrackingLink.component"
import { EmptyState } from "../../../components/shared/EmptyState.component"
import { Layout } from "../../../components/shared/Layout/Layout.component"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { useLanguage } from "../../../contexts/language.context"
import {
	makeCreateTrainingProgramPath,
	makeMyTrainingProgramsPath,
	makeTrainingProgramsPath,
} from "../../../router/Router"
import { MyTrainingProgramSummaries } from "./components/MyTrainingProgramSummary.component"
import { TrainingProgramList } from "./components/TrainingProgramList.component"
import { TrainingProgramsSideBar } from "./components/TrainingProgramsSideBar.component"
import { myTrainingProgramsURLParamsSchema } from "./params"
import { ViewTrainingProgram } from "./ViewTrainingProgram/ViewTrainingProgram.page"

type TrainingProgramsProps = {
	canManagePrograms: boolean
	view: "training-programs" | "my-training-programs"
}

type ProgramsState =
	| { status: "loading" }
	| { status: "error"; error: string }
	| { status: "success"; data: TrainingProgramWithStats[] }

export const usePrograms = () => {
	const { trainingGateway } = useDependencies()
	const [state, setState] = useState<ProgramsState>({ status: "loading" })
	const { user } = useAuthenticatedSession()

	const refreshPrograms = useCallback(async () => {
		try {
			if (!user.isWorkspaceManager()) {
				return
			}
			const data = await trainingGateway.listTrainingPrograms()
			setState({ status: "success", data })
		} catch (err) {
			console.error("Failed to list training programs", err)
			setState({ status: "error", error: "Failed to fetch programs" })
		}
	}, [trainingGateway, user])

	useEffect(() => {
		refreshPrograms()
	}, [refreshPrograms])

	return { state, refreshPrograms }
}

export function TrainingProgramsPage(props: TrainingProgramsProps) {
	const navigate = useNavigate()
	const { programId } = myTrainingProgramsURLParamsSchema.parse(useParams())
	const { state: myProgramsState } = useMyPrograms()
	const { t } = useLanguage()

	useEffect(() => {
		if (
			props.view === "my-training-programs" &&
			!programId &&
			myProgramsState.status === "success" &&
			myProgramsState.data.length > 0
		) {
			const [completedPrograms, incompletePrograms] = _.partition(
				myProgramsState.data,
				(summary) => summary.items.seen === summary.items.total,
			)
			const sortedIncompletePrograms = _.sortBy(incompletePrograms, (summary) => summary.deadline)
			const sortedSummaries = [...sortedIncompletePrograms, ...completedPrograms]
			const firstProgramId = sortedSummaries[0].trainingProgram.id

			navigate(makeMyTrainingProgramsPath(firstProgramId))
		}
	}, [myProgramsState, myProgramsState.status, navigate, programId, props.view])

	if (myProgramsState.status === "loading") {
		return (
			<Layout pageName={t("Training programs")} isFluid className="!p-0 flex">
				<SpinnerIcon className={"h-8 w-8 mx-auto"} />
			</Layout>
		)
	}

	if (myProgramsState.status === "error") {
		return <div>An error happened</div>
	}

	return (
		<Layout pageName={t("Training programs")} isFluid className="!p-0 flex">
			<div className="flex-1 flex-grow flex min-h-[calc(100vh-theme(space.16))]">
				<div className="min-w-0 flex-1 bg-white flex relative">
					<SideNavigationPanel {...props} myPrograms={myProgramsState.data} selectedProgramId={programId} />

					<div
						className={clsx(
							"flex flex-col flex-1 p-6 bg-gray-50",
							props.view === "training-programs" && "overflow-x-auto",
						)}
					>
						{(() => {
							switch (props.view) {
								case "training-programs":
									return <TrainingProgramList />

								case "my-training-programs": {
									return (
										<>
											{programId && <ViewTrainingProgram key={programId} programId={programId} />}
											{!programId && <MyTrainingProgramEmptyState />}
										</>
									)
								}

								default:
									assertNever(props.view)
							}
						})()}
					</div>
				</div>
			</div>
		</Layout>
	)
}

export type MyProgramsState =
	| { status: "loading" }
	| { status: "error"; error: string }
	| { status: "success"; data: MyTrainingProgramSummary[] }

export const useMyPrograms = () => {
	const { trainingGateway } = useDependencies()
	const [state, setState] = useState<MyProgramsState>({ status: "loading" })

	const refreshPrograms = useCallback(async () => {
		try {
			const data = await trainingGateway.listMyTrainingPrograms()
			setState({ status: "success", data })
		} catch (err) {
			console.error("Failed to list my training programs", err)
			setState({ status: "error", error: "Failed to fetch my programs" })
		}
	}, [trainingGateway])

	useInterval(refreshPrograms, 1000 * 10)

	useEffect(() => {
		refreshPrograms()
	}, [refreshPrograms])

	return { state, refreshPrograms }
}

type SideNavigationPanelProps = TrainingProgramsProps & {
	myPrograms: MyTrainingProgramSummary[]
	selectedProgramId?: string
}

function SideNavigationPanel({ view, canManagePrograms, myPrograms, selectedProgramId }: SideNavigationPanelProps) {
	const { t } = useLanguage()

	return (
		<TrainingProgramsSideBar>
			{canManagePrograms && (
				<TrackingLink
					to={makeTrainingProgramsPath()}
					eventName="Training programs: Training programs clicked"
					className={clsx(
						"font-semibold flex items-center space-x-4 hover:text-indigo-600 hover:bg-indigo-50 p-2",
						view === "training-programs" && "bg-indigo-50",
					)}
				>
					{t("Training programs")}
				</TrackingLink>
			)}

			<TrackingLink
				to={makeMyTrainingProgramsPath()}
				eventName="Training programs: My training programs clicked"
				className={clsx(
					"font-semibold flex items-center space-x-4 hover:text-indigo-600 hover:bg-indigo-50 p-2",
					view === "my-training-programs" && !selectedProgramId && "bg-indigo-50",
				)}
			>
				{t("My training")}
			</TrackingLink>
			<div className="pl-2">
				<MyTrainingProgramSummaries summaries={myPrograms} selectedProgramId={selectedProgramId} />
			</div>
		</TrainingProgramsSideBar>
	)
}

function MyTrainingProgramEmptyState() {
	const { t } = useLanguage()
	const { user } = useAuthenticatedSession()
	if (user.isWorkspaceManager()) {
		return (
			<div className="flex items-center justify-center">
				<EmptyState
					className="max-w-xl mt-16"
					title={t("Create your first training program for your team")}
					description={t(
						"Compile a selection of best practice highlights from your library to offer tailored training to selected members of your team.",
					)}
					cta={{
						type: "link",
						label: t("Create training program"),
						to: makeCreateTrainingProgramPath(),
						state: { from: location.pathname },
						analyticsEventName: "Create training program button clicked",
					}}
				/>
			</div>
		)
	}

	return (
		<div className="flex items-center justify-center">
			<EmptyState
				className="max-w-xl mt-16"
				title={t("You have not yet been enrolled in any training programs")}
				description={t(
					"Once enrolled, you will be notified on your account dashboard, and you will be able to see and complete all of your training here.",
				)}
			/>
		</div>
	)
}
