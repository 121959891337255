import clsx from "clsx"
import React from "react"

type TextToggleProps<A extends string, B extends string> = {
	value: A | B
	a: {
		label: string
		value: A
	}
	b: {
		label: string
		value: B
	}
	onChange: (value: A | B) => void
	disabled?: boolean
}

export function TextToggle<A extends string, B extends string>({
	b,
	a,
	onChange,
	value,
	disabled,
}: TextToggleProps<A, B>) {
	const handleClick = React.useCallback(
		(newValue: A | B) => {
			onChange(newValue)
		},
		[onChange],
	)

	const classNames =
		"font-medium text-xs transition-all rounded-md py-0.5 px-1 border-2 leading-5 hover:border-1 hover:border-indigo-100 text-gray-500 disabled:!cursor-not-allowed disabled:!text-gray-400"
	const selectedClassNames = "!border-indigo-500 !text-gray-900 disabled:!border-gray-200"

	return (
		<div className="flex flex-row border-2 border-gray-200 rounded-md whitespace-nowrap p-0.5 gap-1">
			<button
				className={clsx(classNames, value === a.value ? selectedClassNames : "border-transparent")}
				onClick={() => handleClick(a.value)}
				disabled={disabled}
			>
				{a.label}
			</button>
			<button
				className={clsx(classNames, value === b.value ? selectedClassNames : "border-transparent")}
				onClick={() => handleClick(b.value)}
				disabled={disabled}
			>
				{b.label}
			</button>
		</div>
	)
}
