import type { Call } from "../../../../../../core/domain/Call.entity"
import { Alert } from "../../../../../components/design-system/Alert.component"
import { CrmAccountHref } from "../../../../../components/shared/CrmAccountHref.component"
import { useLanguage } from "../../../../../contexts/language.context"
import { _LeadKnowledgeV1Panel } from "./_LeadKnowledgeV1Panel.component"
import { _LeadKnowledgeV2Panel } from "./_LeadKnowledgeV2Panel/_LeadKnowledgeV2Panel.component"

export type _LeadKnowledgePanelProps = {
	call: Call
}

export function _LeadKnowledgePanel({ call }: _LeadKnowledgePanelProps) {
	const { t } = useLanguage()
	const { leadKnowledge } = call.props ?? {}
	if (!leadKnowledge?.version || !call.hasLeadKnowledge()) {
		return <Alert variant="warning" description={t("No lead information available at this time.")} />
	}

	return (
		<>
			{call.props.account && (
				<div className="mb-4">
					<CrmAccountHref account={call.props.account} />
				</div>
			)}
			{leadKnowledge.version === 1 ? (
				<_LeadKnowledgeV1Panel leadKnowledgeV1={leadKnowledge} />
			) : (
				<_LeadKnowledgeV2Panel leadKnowledgeV2={leadKnowledge} />
			)}
		</>
	)
}
