import React from "react"

import type { Library, LibraryFolder } from "../../../core/domain/Library.entity"
import { useLanguage } from "../../contexts/language.context"
import { getFolderDisplayNameString } from "../../pages/Library/components/FolderDisplayName.component"
import { Input } from "../design-system/Input"

export type LibraryFolderSelectProps = {
	library: Library
	defaultValue?: string
	/** whether to show User Library, Workspace Library, or both */
	onlyRoot?: "user" | "workspace"
	htmlName: string
	showAIFolders: boolean
}

export function LibraryFolderSelect({
	library,
	onlyRoot,
	htmlName,
	showAIFolders,
	defaultValue,
}: LibraryFolderSelectProps) {
	const rootFolders = (() => {
		switch (onlyRoot) {
			case "user":
				return [library.userRoot]
			case "workspace":
				return [library.workspaceRoot]
			default:
				return [library.userRoot, library.workspaceRoot]
		}
	})()
	const defaultFolder = (defaultValue ? library.getFolderById(defaultValue) : undefined) ?? rootFolders[0]
	const [selectedFolders, setSelectedFolders] = React.useState<LibraryFolder[]>(defaultFolder ? [defaultFolder] : [])
	const { t } = useLanguage()

	const handleRootFolderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const folderId = e.target.value
		const folder = rootFolders.find((f) => f.id === folderId)
		if (!folder) return
		setSelectedFolders([folder])
	}

	const handleFolderChange = (parentFolder: LibraryFolder, childFolderId: string, index: number) => {
		setSelectedFolders((prev) => {
			const childFolder = parentFolder.children.find((f) => f.id === childFolderId)
			if (!childFolder) return prev

			const newFolders = [...prev].slice(0, index)
			newFolders[index] = childFolder
			return newFolders
		})
	}

	return (
		<div className="mt-4 gap-2">
			<input type="hidden" name={htmlName} value={selectedFolders[selectedFolders.length - 1]?.id ?? ""} />
			<label htmlFor="root-folder" className="block text-sm font-medium leading-6 text-gray-900">
				{t("Parent folder")}
			</label>
			<Input.Select
				id="root-folder"
				name="root-folder"
				value={selectedFolders[0]?.id ?? ""}
				onChange={handleRootFolderChange}
				required={true}
			>
				<Input.SelectOption disabled={true} className="text-gray-400 hidden" value="">
					{t("Select a folder")}
				</Input.SelectOption>
				{rootFolders.map((folder) => (
					<Input.SelectOption key={folder.id} value={folder.id}>
						{getFolderDisplayNameString(folder, t)}
					</Input.SelectOption>
				))}
			</Input.Select>

			{selectedFolders.map((folder, i) => {
				if (!folder.hasChildren()) return null
				if (folder.isAIFolder() && !showAIFolders) return null
				const children = folder.children.filter((f) => !f.isAIFolder() || showAIFolders)
				if (children.length === 0) return null

				return (
					<Input.Select
						id={folder.id}
						name={folder.name}
						value={selectedFolders[i + 1]?.id ?? ""}
						onChange={(e) => handleFolderChange(folder, e.target.value, i + 1)}
						key={folder.id}
					>
						<Input.SelectOption disabled={true} className="text-gray-400 hidden" value="">
							{t("Select a folder (optional)")}
						</Input.SelectOption>
						{children.map((childFolder) => (
							<Input.SelectOption key={childFolder.id} value={childFolder.id}>
								{getFolderDisplayNameString(childFolder, t)}
							</Input.SelectOption>
						))}
					</Input.Select>
				)
			})}
		</div>
	)
}
