import type { CrmIntegration } from "../../../../../core/domain/Workspace.entity"
import { Button } from "../../../../components/design-system/Button.component"
import { Input } from "../../../../components/design-system/Input"
import { Tooltip } from "../../../../components/design-system/Tooltip.component"
import { useLanguage } from "../../../../contexts/language.context"

export type CrmProviderPickerProps = {
	providers: Array<{
		label: string
		value?: CrmIntegration["provider"]
		icon?: React.ReactNode
	}>
	crmIntegration: CrmIntegration | null
	onSelectedProviderChange: (provider: CrmIntegration["provider"]) => void
	disabled: boolean
}

export function CrmProviderPicker({
	crmIntegration,
	onSelectedProviderChange,
	providers,
	disabled,
}: CrmProviderPickerProps) {
	const { t } = useLanguage()
	return (
		<Input.Group>
			<nav className="mt-2 flex items-center justify-start space-x-4" aria-label="Tabs">
				{providers.map((provider) => {
					const ButtonComponent = (
						<Button
							key={provider.label}
							eventName="crm integration provider selected"
							eventProperties={{ provider: provider.value }}
							onClick={() => {
								if (disabled || !provider.value) {
									return
								}
								onSelectedProviderChange(provider.value)
							}}
							type="button"
							disabled={disabled || !provider.value}
							variant="default"
							className={provider.value === crmIntegration?.provider ? "!bg-gray-200 text-gray-800" : ""}
							icon={provider.icon}
							size="lg"
						>
							{provider.label}
						</Button>
					)
					if (!provider.value) {
						return (
							<Tooltip content={t("Coming soon")} tooltipClassName="top-12 left-4 ">
								{ButtonComponent}
							</Tooltip>
						)
					}

					return ButtonComponent
				})}
			</nav>
		</Input.Group>
	)
}
