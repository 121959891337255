import { INTESCIA_WORKSPACE_ID } from "../../../../../config"
import type { Call, FrameworkName, Metric } from "../../../../../core/domain/Call.entity"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { ScoreBadge } from "../../../../components/shared/ScoreBadge.component"
import { TranscriptionQuote } from "../../../../components/shared/TranscriptionQuote.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { type TranslationKey, useLanguage } from "../../../../contexts/language.context"
import { CallTooShortDisclaimer } from "./_CallTooShortDisclaimer.component"

export type _AnalysisPanelProps = {
	call: Call | null
}

export function _ReviewPanel({ call }: _AnalysisPanelProps) {
	const { t, language } = useLanguage()
	const { workspace } = useAuthenticatedSession()

	if (!call) {
		return <SpinnerIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
	}

	const scores = getMetrics(call)

	if (scores.length === 0) {
		return <SpinnerIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
	}

	const score = scores[0]
	const metricsToDisplay =
		score.label === MEDDIC_SCORE_LABEL && workspace.props.preferences?.meddicSectionsToHide
			? score.metrics.filter(
					(metric) => !workspace.props.preferences?.meddicSectionsToHide?.includes(metric.label),
			  )
			: score.metrics

	return (
		<>
			{call.isShortCall() && (
				<div className="mb-4">
					<CallTooShortDisclaimer />
				</div>
			)}
			<span className="flex items-center">
				<span className="text-lg leading-6 font-medium text-gray-900">{t(score.label)}</span>

				{call.props.score && !workspace.props.preferences?.hideMeddicScoring && (
					<ScoreBadge
						score={call.props.score.totalScore}
						maxScore={call.props.score.totalMaxScore}
						className="ml-2"
					/>
				)}
			</span>

			<table className="min-w-full divide-y divide-gray-300">
				<thead>
					<tr>
						{!workspace.props.preferences?.hideMeddicScoring && (
							<th
								scope="col"
								className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
							>
								{t("Score")}
							</th>
						)}

						<th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
							{t("Phase")}
						</th>
					</tr>
				</thead>
				<tbody className="divide-y divide-gray-200">
					{metricsToDisplay.map((metric) => (
						<tr key={metric.label}>
							{!workspace.props.preferences?.hideMeddicScoring && (
								<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-0">
									<ScoreBadge score={metric.score} maxScore={metric.maxScore} />
								</td>
							)}

							<td className="px-3 py-4 text-sm text-gray-600">
								<span className="font-medium">{temporaryI18nMetricLabel(metric.label, language)}</span>
								<br />
								{metric.rationale}
								<br />
								{metric.quotes.map((quote) => (
									<TranscriptionQuote
										key={quote}
										rawQuote={quote}
										call={call}
										trackingComponent="review"
									/>
								))}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</>
	)
}

/**
 * TODO: remove this and use the proper i18n library
 */
const englishTranslationByFrenchMetricLabel: Record<string, string> = {
	Introduction: "Introduction",
	Présentation: "Presentation",
	Découverte: "Discovery",
	Reformulation: "Reformulation",
	"Argumentaire et démo solutions": "Argumentation and solution demonstration",
	"Sortie d'argumentaire": "Argumentation exit",
	Conclusion: "Conclusion",
}

function temporaryI18nMetricLabel(label: string, language: string) {
	if (language !== "en") return label

	const translation = englishTranslationByFrenchMetricLabel[label]
	return translation ?? label
}

type ScoreList = Array<{ label: TranslationKey; metrics: Metric[] }>

export const MEDDIC_SCORE_LABEL = "MEDDIC score"

function getMetrics(call: Call): ScoreList {
	const frameworkNameToLabel: Record<FrameworkName, TranslationKey> = {
		MEDDIC: MEDDIC_SCORE_LABEL,
		BANT: "BANT score",
		SPICED: "SPICED score",
	}
	const frameworkScorings: ScoreList =
		call.props.frameworkScorings?.map(({ framework, metrics }) => ({
			label: frameworkNameToLabel[framework],
			metrics,
		})) || []

	const overallScore: ScoreList = call.props.scoringAnalysis
		? [
				{
					label: "Overall score",
					metrics: call.props.scoringAnalysis.metrics,
				},
		  ]
		: []

	if (call.props.workspaceId === INTESCIA_WORKSPACE_ID) {
		return [...overallScore, ...frameworkScorings]
	}

	return [...frameworkScorings, ...overallScore]
}
