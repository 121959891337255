import React from "react"
import { useNavigate } from "react-router-dom"

import { useSession } from "../../../contexts/authentication.context"
import { useLanguage } from "../../../contexts/language.context"
import { useInviteToken } from "../../../hooks/useInviteToken"
import { makePublicWebsiteUrl } from "../../../public-website"
import { TrackingButton } from "../../design-system/TrackingButton.component"
import { TrackingExternalHref } from "../../design-system/TrackingExternalHref.component"
import { ClaimAccountModal } from "../ClaimAccountModal.component"

type IPublicNavbarProps = {
	isSignupAllowed: boolean
}

export function _PublicNavbar({ isSignupAllowed }: IPublicNavbarProps) {
	const { user, fetchMe } = useSession()
	const inviteToken = useInviteToken()
	const navigate = useNavigate()
	const canSignup = Boolean(!user && inviteToken && isSignupAllowed)
	const [isClaimModalOpen, setIsClaimModalOpen] = React.useState(false)
	const { t } = useLanguage()

	const handleJoinClick = React.useCallback(() => {
		setIsClaimModalOpen(true)
	}, [])

	const handleClaimModalClose = React.useCallback(() => {
		setIsClaimModalOpen(false)
	}, [])

	const handleClaimSuccess = React.useCallback(() => {
		setIsClaimModalOpen(false)

		const url = new URL(window.location.toString())
		const urlInviteToken = url.searchParams.get("inviteToken")
		if (urlInviteToken) {
			url.searchParams.delete("inviteToken")
			navigate(url.pathname + url.search)
			fetchMe()
		}
	}, [fetchMe, navigate])

	return (
		<>
			{inviteToken && (
				<ClaimAccountModal
					inviteToken={inviteToken}
					open={isClaimModalOpen}
					onClose={handleClaimModalClose}
					onClaimSuccess={handleClaimSuccess}
				/>
			)}
			<nav className="bg-gray-800">
				<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
					<div className="flex h-16 justify-between items-center">
						<div className="flex items-center">
							<div className="flex flex-shrink-0 items-center">
								<TrackingExternalHref
									href={makePublicWebsiteUrl()}
									eventName="Public navbar: logo clicked"
									displayExternalIcon={false}
								>
									<img
										className="h-8 sm:h-12 w-auto"
										src={process.env.PUBLIC_URL + "/rippletide_logo_text_transparent_bg.svg"}
										alt="Rippletide's logo"
									/>
								</TrackingExternalHref>
							</div>
						</div>
						<div className="flex items-center gap-4">
							{canSignup ? (
								<TrackingButton
									type="button"
									className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
									onClick={handleJoinClick}
									eventName="Join now clicked"
									eventProperties={{ inviteToken }}
								>
									{t("Join Rippletide now")}
								</TrackingButton>
							) : (
								<TrackingExternalHref
									href={makePublicWebsiteUrl()}
									eventName="Go to Rippletide website clicked"
									className="rounded-md bg-indigo-50 px-3 py-2 text-xs sm:text-md font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
								>
									{t("Learn more about Rippletide")}
								</TrackingExternalHref>
							)}
						</div>
					</div>
				</div>
			</nav>
		</>
	)
}
