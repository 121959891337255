import { z } from "zod"

import {
	type MyTrainingProgramContent,
	MyTrainingProgramContentSchema,
} from "../../application/gateways/training.gateway/schemas/my-training-program-content.schema"
import {
	ListMyTrainingProgramsResponseSchema,
	type MyTrainingProgramSummary,
} from "../../application/gateways/training.gateway/schemas/my-training-program-summary.schema"
import type { TrainingProgram } from "../../application/gateways/training.gateway/schemas/training-program.schema"
import {
	type TrainingProgramEditViewModel,
	TrainingProgramEditViewModelSchema,
} from "../../application/gateways/training.gateway/schemas/training-program-edit-view-model.schema"
import {
	type TrainingProgramWithStats,
	TrainingProgramWithStatsSchema,
} from "../../application/gateways/training.gateway/schemas/training-program-with-stats.schema"
import {
	type UpdateTrainingProgramPayload,
	type UpdateTrainingProgramResponse,
	UpdateTrainingProgramResponseSchema,
} from "../../application/gateways/training.gateway/schemas/update.schema"
import { type ITrainingGateway } from "../../application/gateways/training.gateway/training.gateway"

export class HttpTrainingGateway implements ITrainingGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async markTrainingItemAsSeen(programId: string, itemId: string): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/training/my-programs/${programId}/seen`
		const res = await fetch(endpointUrl, {
			method: "PUT",
			credentials: "include",
			body: JSON.stringify({
				itemId,
			}),
			headers: {
				"Content-Type": "application/json",
			},
		})

		if (!res.ok) {
			throw new Error("Failed to mark training item as seen")
		}
	}

	public async listTrainingPrograms(): Promise<TrainingProgramWithStats[]> {
		const endpointUrl = `${this.baseApiUrl}/training/programs`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to list training programs")
		}

		const json = await res.json()

		const x = z.array(TrainingProgramWithStatsSchema).safeParse(json)
		if (!x.success) console.error(x.error.issues)

		return z.array(TrainingProgramWithStatsSchema).parse(json)
	}

	public async updateTrainingProgram(
		programId: string,
		params: UpdateTrainingProgramPayload,
	): Promise<UpdateTrainingProgramResponse> {
		const endpointUrl = `${this.baseApiUrl}/training/programs/${programId}`
		const res = await fetch(endpointUrl, {
			method: "PATCH",
			credentials: "include",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
			},
		})

		if (!res.ok) {
			throw new Error("Failed to update training program")
		}

		return UpdateTrainingProgramResponseSchema.parse(await res.json())
	}

	public async getTrainingProgram(programId: string): Promise<TrainingProgramEditViewModel> {
		// return _PLACEHOLDER_TRAINING_PROGRAM_EDIT_VIEW_MODEL
		const endpointUrl = `${this.baseApiUrl}/training/programs/${programId}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to get training program edit view")
		}

		return TrainingProgramEditViewModelSchema.parse(await res.json())
	}

	public async deleteTrainingProgram(programId: string): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/training/programs/${programId}`
		const res = await fetch(endpointUrl, {
			method: "DELETE",
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to delete training program")
		}
	}

	public async listMyTrainingPrograms(): Promise<MyTrainingProgramSummary[]> {
		// return _PLACEHOLDER_MY_TRAINING_PROGRAM_SUMMARIES
		const endpointUrl = `${this.baseApiUrl}/training/my-programs`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to list my trainings")
		}

		const json = await res.json()

		return ListMyTrainingProgramsResponseSchema.parse(json)
	}

	public async getMyTrainingProgram(programId: string): Promise<MyTrainingProgramContent> {
		// return _PLACEHOLDER_MY_TRAINING_PROGRAM_CONTENT
		const endpointUrl = `${this.baseApiUrl}/training/my-programs/${programId}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to get my training program")
		}

		return MyTrainingProgramContentSchema.parse(await res.json())
	}
}

const _PLACEHOLDER_TRAINING_PROGRAM: TrainingProgram = {
	id: "tp_placeholder",
	workspaceId: "ws_placeholder",
	name: "Training Program Placeholder",
	description: "This is a placeholder training program",
	creatorId: "creator_placeholder",
	createdAt: new Date(),
	updatedAt: new Date(),
	totalItems: 10,
}

const _PLACEHOLDER_ITEM_WITH_HIGHLIGHT = {
	id: "highlight_placeholder",
	description: "Description",
	content: {
		type: "highlight",
		highlight: {
			id: "highlight_placeholder_id",
			callDate: new Date(),
			callDurationSec: 1000,
			callId: "call_5fa2fbb9-05a9-4c12-a9ad-b9ca1f28f2cb",
			callName: "Call Placeholder",
			comment: "Comment",
			containingLibraryFolderId: "folder_placeholder",
			creationDate: new Date(),
			creatorFullName: "Creator Placeholder",
			startsAtMs: 0,
			endsAtMs: 10000,
			reactions: {
				thumbsDownCount: 0,
				thumbsUpCount: 0,
				userReaction: undefined,
			},
		},
	},
} as const satisfies Required<TrainingProgramEditViewModel>["sections"][0]["items"][0]

const _PLACEHOLDER_TRAINING_PROGRAM_EDIT_VIEW_MODEL: TrainingProgramEditViewModel = {
	trainingProgram: _PLACEHOLDER_TRAINING_PROGRAM,
	sections: [
		{
			id: "tps_placeholder_1",
			name: "Section Placeholder",
			description: "Section Description",
			items: [_PLACEHOLDER_ITEM_WITH_HIGHLIGHT],
		},
	],
	enrolments: [
		{
			enrolmentId: "enrolment_placeholder",
			userFullName: "User Placeholder",
			deadline: new Date(),
			itemsSeen: 0,
			userId: "user_placeholder",
		},
	],
}

const _PLACEHOLDER_MY_TRAINING_PROGRAM_SUMMARIES: MyTrainingProgramSummary[] = [
	{
		trainingProgram: {
			id: "tp_placeholder1",
			name: "Training July 24",
		},
		deadline: new Date("2024-12-30"),
		wasOpened: false,
		items: {
			seen: 0,
			total: 100,
		},
		enrolledAt: new Date("2024-07-24"),
	},
	{
		trainingProgram: {
			id: "tp_placeholder2",
			name: "Objection handling",
		},
		deadline: new Date("2024-07-24"),
		wasOpened: true,
		items: {
			seen: 0,
			total: 100,
		},
		enrolledAt: new Date("2024-07-24"),
	},
	{
		trainingProgram: {
			id: "tp_placeholder3",
			name: "Training 3",
		},
		deadline: new Date("2024-05-24"),
		wasOpened: true,
		items: {
			seen: 60,
			total: 100,
		},
		enrolledAt: new Date("2024-07-24"),
	},
	{
		trainingProgram: {
			id: "tp_placeholder4",
			name: "Onboarding",
		},
		deadline: undefined,
		wasOpened: true,
		items: {
			seen: 100,
			total: 100,
		},
		enrolledAt: new Date("2024-07-24"),
	},
]

const _PLACEHOLDER_MY_TRAINING_PROGRAM_CONTENT: MyTrainingProgramContent = {
	trainingProgram: _PLACEHOLDER_TRAINING_PROGRAM,
	deadline: new Date(),
	sections: [
		{
			id: "section_1",
			items: [
				{
					type: "text",
					text: "Hello! <br /> This is a placeholder text. <strong>Strong</strong> <em>Emphasis</em> <u>Underline</u>",
					seen: true,
					description:
						"Presenting the benefits of the feature for the prospect helps them to project themselves using it and helps them to visualise the value.",
					id: "id_2",
				},
				{
					type: "highlight",
					highlight: _PLACEHOLDER_ITEM_WITH_HIGHLIGHT.content.highlight,
					seen: true,
					description:
						"Presenting the benefits of the feature for the prospect helps them to project themselves using it and helps them to visualise the value.",
					id: "id_1",
				},
			],
			name: "Feature introduction",
			description:
				"In this section, you’ll learn about our new library feature. You’ll be given an introduction to the context: who it has been created for and why it has been created for them.",
		},
		{
			id: "section_2",
			items: [
				{
					type: "pdf",
					fileName: "File Placeholder",
					fileId: "file_placeholder",
					seen: false,
					description:
						"Presenting the benefits of the feature for the prospect helps them to project themselves using it and helps them to visualise the value.",
					id: "id_3",
					fileUrl: "https://pdfobject.com/pdf/sample.pdf",
				},
				// {
				// 	type: "video",
				// 	fileName: "Video Placeholder",
				// 	fileId: "video_placeholder",
				// 	seen: false,
				// 	description:
				// 		"Presenting the benefits of the feature for the prospect helps them to project themselves using it and helps them to visualise the value.",
				// 	id: "id_4",
				// 	fileUrl: "https://www.w3schools.com/html/mov_bbb.mp4",
				// },
			],
			name: "Feature demo",
			description: "Section 2 Description",
		},
	],
}
