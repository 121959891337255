import { ChartBarIcon, FunnelIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import React from "react"

import { TrackingButton } from "../../../../../components/design-system/TrackingButton.component"

export type SalesMotionView = "timeline" | "graphs"

type ISalesMotionTabMenuProps = {
	currentView: SalesMotionView
	handleViewChange: (tab: SalesMotionView) => void
}

export function SalesMotionViewSelectorMenu({ currentView, handleViewChange }: ISalesMotionTabMenuProps) {
	return (
		<div className="border rounded-md border-gray-100 p-1 flex flex-col gap-0.5">
			<TrackingButton
				eventName="Sales Motion: change view"
				eventProperties={{ viewType: "graphs" }}
				title="Graph view"
				onClick={() => handleViewChange("graphs")}
			>
				<ChartBarIcon
					className={clsx(
						"h-7 w-7 text-gray-400",
						currentView === "graphs" && "border-2 rounded-md border-indigo-600",
					)}
				/>
			</TrackingButton>
			<TrackingButton
				eventName="Sales Motion: change view"
				eventProperties={{ viewType: "timeline" }}
				title="Timeline view"
				onClick={() => handleViewChange("timeline")}
			>
				<FunnelIcon
					className={clsx(
						"h-7 w-7 text-gray-400",
						currentView === "timeline" && "border-2 rounded-md border-indigo-600",
					)}
				/>
			</TrackingButton>
		</div>
	)
}
