import React from "react"

import { type AnalyticsProps, useAnalytics } from "../../contexts/analytics.context"

type TrackingButtonProps = {
	onClick?: (e: React.MouseEvent<HTMLDivElement>) => void
	className?: string
	children: React.ReactNode
} & AnalyticsProps

export function TrackingDiv({ onClick, children, eventName, eventProperties, ...props }: TrackingButtonProps) {
	const { setupEventTracking } = useAnalytics()
	const { trackEvent: trackButtonUsage, debugDOMProps } = setupEventTracking({ eventName, eventProperties })

	const handleTrackingClick = (e: React.MouseEvent<HTMLDivElement>) => {
		console.debug("[TrackingButton] Tracking event:", eventName, eventProperties)
		trackButtonUsage()
		onClick?.(e)
	}

	return (
		<div role="button" {...props} onClick={handleTrackingClick} {...debugDOMProps}>
			{children}
		</div>
	)
}
