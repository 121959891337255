import type { Team } from "../../../core/domain/Team.entity"
import type { User } from "../../../core/domain/User.entity"

export type FilterOptions = {
	teams: {
		show: boolean
		options: Team[]
		canFilterByAnyTeam: boolean
	}
	members: {
		show: boolean
		options: User[]
		canFilterByAnyMember: boolean
	}
}

export function computeFilterOptions(
	user: User,
	teams: Team[],
	workspaceUsers: User[],
	selectedTeam: Team | null,
): FilterOptions {
	if (user.isWorkspaceOwner()) {
		return computeFilterOptionsForOwner(user, teams, workspaceUsers, selectedTeam)
	}
	if (user.isWorkspaceManager()) {
		return computeFilterOptionsForManager(user, teams, workspaceUsers, selectedTeam)
	}
	return computeFilterOptionsForMember(user, teams, workspaceUsers, selectedTeam)
}

function computeFilterOptionsForMember(
	user: User,
	teams: Team[],
	workspaceUsers: User[],
	selectedTeam: Team | null,
): FilterOptions {
	const userTeams = teams.filter((team) => team.memberIds.includes(user.id))
	const userTeammates = Array.from(
		new Set(
			userTeams
				.flatMap((team) => team.memberIds)
				.map((id) => workspaceUsers.find((user) => user.id === id))
				.filter((user): user is User => user !== undefined),
		),
	)
	const filterableTeammates = selectedTeam
		? userTeammates.filter((user) => selectedTeam.memberIds.includes(user.id))
		: userTeammates

	return {
		teams: {
			show: userTeams.length > 0,
			options: userTeams.toSorted((a, b) => a.name.localeCompare(b.name)),
			canFilterByAnyTeam: false,
		},
		members: {
			show: userTeammates.length > 0,
			options: filterableTeammates.toSorted((a, b) => a.firstName.localeCompare(b.firstName)),
			canFilterByAnyMember: false,
		},
	}
}

function computeFilterOptionsForManager(
	user: User,
	teams: Team[],
	workspaceUsers: User[],
	selectedTeam: Team | null,
): FilterOptions {
	const userTeams = teams.filter((team) => team.memberIds.includes(user.id))
	const userTeammates = Array.from(
		new Set(
			userTeams
				.flatMap((team) => team.memberIds)
				.map((id) => workspaceUsers.find((user) => user.id === id))
				.filter((user): user is User => user !== undefined),
		),
	)

	const filterableTeammates = selectedTeam
		? userTeammates.filter((user) => selectedTeam.memberIds.includes(user.id))
		: userTeammates

	return {
		teams: {
			show: userTeams.length > 0,
			options: userTeams.toSorted((a, b) => a.name.localeCompare(b.name)),
			canFilterByAnyTeam: false,
		},
		members: {
			show: userTeammates.length > 0,
			options: filterableTeammates.toSorted((a, b) => a.firstName.localeCompare(b.firstName)),
			canFilterByAnyMember: false,
		},
	}
}

function computeFilterOptionsForOwner(
	user: User,
	teams: Team[],
	workspaceUsers: User[],
	selectedTeam: Team | null,
): FilterOptions {
	const filterableUsers = selectedTeam
		? workspaceUsers.filter((user) => selectedTeam.memberIds.includes(user.id))
		: workspaceUsers
	return {
		teams: {
			show: true,
			options: teams.toSorted((a, b) => a.name.localeCompare(b.name)),
			canFilterByAnyTeam: true,
		},
		members: {
			show: true,
			options: filterableUsers.toSorted((a, b) => a.firstName.localeCompare(b.firstName)),
			canFilterByAnyMember: true,
		},
	}
}
