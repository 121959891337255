import { TrashIcon } from "@heroicons/react/24/outline"
import React from "react"

import { TrackingButton } from "../../../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../../../contexts/language.context"

type TrainingSectionEditMenuProps = {
	onDelete: () => void
}

export default function TrainingSectionEditMenu({ onDelete }: TrainingSectionEditMenuProps) {
	const { t } = useLanguage()

	const handleDelete = () => {
		if (confirm(t("Are you sure you want to delete this item?"))) {
			onDelete()
		}
	}

	return (
		<div className="relative inline-block text-left">
			<TrackingButton
				type="button"
				eventName="Training section edit: delete clicked"
				className="w-full text-center block text-sm text-red-500 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
				onClick={handleDelete}
			>
				<TrashIcon className="h-4 w-4 text-red-500" />
			</TrackingButton>
		</div>
	)
}
