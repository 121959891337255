import type { LeadKnowledgeV2 } from "../../../../../../../core/domain/Call.entity"
import { useLanguage } from "../../../../../../contexts/language.context"
import { KeyValueList } from "./KeyValueList.component"

export type IOpportunitySectionProps = {
	opportunity: NonNullable<LeadKnowledgeV2["opportunity"]>
}

export function OpportunitySection({ opportunity }: IOpportunitySectionProps) {
	const { t } = useLanguage()
	const { currentProvider, deal, ...rest } = opportunity
	return (
		<div>
			<h4 className="whitespace-nowrap mb-2 pl-4 pr-3 text-lg font-bold text-gray-900 sm:pl-0">
				{t("Opportunity")}
			</h4>

			<div className="flex-auto">
				{currentProvider && <KeyValueList entries={currentProvider} prefix={t("Current provider")} />}
				{deal && <KeyValueList entries={deal} prefix={t("Deal")} />}
				<KeyValueList entries={rest} prefix={t("Opportunity")} />
			</div>
		</div>
	)
}
