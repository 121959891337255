import type { BaseCall } from "../../../../../core/domain/BaseCall.entity"
import { Call } from "../../../../../core/domain/Call.entity"
import type { CallExcerpt } from "../../../../../core/domain/CallExcerpt.entity"
import type { CallHighlight } from "../../../../../core/domain/Library.entity"
import { PublicCall } from "../../../../../core/domain/PublicCall.entity"
import { useCallVideoPlayer } from "../../../../contexts/callVideoPlayer.context"
import { SpeakerTimelines } from "../_SpeakerTimelines.component"
import { _AudioFallback } from "./_AudioFallback.component"
import { CallVideoPlayer } from "./CallVideoPlayer.component"

export type _MainPanelProps = {
	call: BaseCall
	highlights: CallHighlight[] | "loading"
	excerpts?: CallExcerpt[] | "loading"
	refreshHighlights?: () => void
	className?: string
	childrenBetweenPlayerAndTimeline?: React.ReactNode
} & TimelineProps

type TimelineProps =
	| {
			timeline: false
			timelineVerticalSpacing?: never
	  }
	| {
			timeline: true
			/**
			 * Requires fixed numbers because of how tailwind works
			 * Allows to have less spacing in the library player because it's already cramped
			 */
			timelineVerticalSpacing: 2 | 4
	  }

export function Call_MainPanel({
	call,
	highlights,
	excerpts,
	refreshHighlights,
	className,
	childrenBetweenPlayerAndTimeline,
	timeline,
	timelineVerticalSpacing,
}: _MainPanelProps) {
	const { transcription, currentTimeSeconds, totalDurationSeconds, seekPlayerTo } = useCallVideoPlayer()

	return (
		<section className={className}>
			{call.props.videoFileUrl ? (
				<CallVideoPlayer url={call.props.videoFileUrl} thumbnailImageUrl={call.props.video?.thumbnail?.url}>
					{childrenBetweenPlayerAndTimeline}
					{timeline ? (
						<SpeakerTimelines
							callId={call instanceof Call ? call.props.id : undefined}
							transcription={transcription}
							totalDurationSeconds={totalDurationSeconds}
							onPlayerTimeChange={(newPlayerTime) => {
								seekPlayerTo(newPlayerTime, "timeline")
							}}
							currentTimeSeconds={currentTimeSeconds}
							highlights={highlights}
							refreshHighlights={refreshHighlights}
							shouldHideTimelineActions={
								call instanceof PublicCall || (call instanceof Call && call.isDemoCall())
							}
							verticalSpacing={timelineVerticalSpacing}
							canShareCall={call instanceof Call && call.props.publicAccessToken !== undefined}
							callExcerpts={excerpts ?? []}
						/>
					) : null}
				</CallVideoPlayer>
			) : call instanceof Call ? (
				<_AudioFallback call={call} />
			) : null}
		</section>
	)
}
