import clsx from "clsx"

export type StatusBadgeVariant = "error" | "neutral" | "success" | "info" | "warning"

export type StatusBadgeProps = {
	variant: StatusBadgeVariant
	className?: string
}

const classNameByVariant = {
	error: ["bg-red-500", "bg-red-500/20"],
	neutral: ["bg-gray-500", "bg-gray-500/20"],
	success: ["bg-emerald-500", "bg-emerald-500/20"],
	info: ["bg-indigo-500", "bg-indigo-500/20"],
	warning: ["bg-yellow-500", "bg-yellow-500/20"],
} as const

export function StatusBadge({ variant, className }: StatusBadgeProps) {
	const [innerCircleClassName, outerCircleClassName] = classNameByVariant[variant]

	return (
		<div className={clsx("h-4 w-4 flex items-center justify-center rounded-full", outerCircleClassName, className)}>
			<div className={clsx("h-2 w-2 rounded-full", innerCircleClassName)} />
		</div>
	)
}
