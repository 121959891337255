import assertNever from "assert-never"
import { toast } from "react-toastify"

import { baseToastConfig } from "../../../../config"
import type {
	ICalendarEventsGateway,
	NotFoundCalendar,
} from "../../../../core/application/gateways/calendar-events.gateway"
import { Alert } from "../../../components/design-system/Alert.component"
import { SpinnerIcon } from "../../../components/design-system/SpinnerIcon.component"
import { CalendarSyncButtons } from "../../../components/shared/CalendarSyncButtons.component"
import { useCalendarContext } from "../../../contexts/calendar.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { type TFunction, useLanguage } from "../../../contexts/language.context"
import { getCalendarPlatformDisplayName } from "../../../utils/calendarName"
import { Card } from "../components/Card.component"
import { DailyEventList } from "../components/DailyEventList/DailyEventList.component"

function ProspectingScheduleCalendarDisconnected({ calendar }: { calendar: NotFoundCalendar }) {
	const { t } = useLanguage()
	return (
		<div className="text-center">
			<h3 className="font-semibold text-lg text-gray-900">
				{t("Sync your calendar now to start recording with Rippletide")}
			</h3>
			<p className="text-gray-600 mt-4">
				{t(
					"Kickstart your efficiency and power your sales process with in-depth call analysis and actionable insights.",
				)}
			</p>
			<CalendarSyncButtons calendar={calendar} />
			<span className="text-sm text-gray-600 italic">
				{t(
					"To record a singular call without connecting your calendar, invite coach@rippletide.com to the calendar event (not compatible with Zoom).",
				)}
			</span>
		</div>
	)
}

function ProspectingScheduleInner() {
	const { t } = useLanguage()
	const { calendarAndEvents } = useCalendarContext()
	const { fetchEvents } = useCalendarContext()
	const { calendarEventsGateway } = useDependencies()

	const handleCalendarEventAction = async (
		calendarEventId: string,
		eventAction: "send-bot-to-meeting-now" | "remove-bot-from-call",
	): Promise<void> => {
		switch (eventAction) {
			case "send-bot-to-meeting-now":
				await sendBotToMeetingNow(calendarEventsGateway, calendarEventId, t)
				break

			case "remove-bot-from-call":
				await removeBotFromCall(calendarEventsGateway, calendarEventId, t)
				break

			default:
				assertNever(eventAction)
		}

		await fetchEvents("did-update-bot")
	}

	if (calendarAndEvents.error) {
		return (
			<Alert
				title={t("Something went wrong")}
				description={t("Couldn't load your calendar events. Please try again later.")}
				className="m-4"
			/>
		)
	}

	const isCalendarBeingSynchronized = Boolean(
		!calendarAndEvents.data ||
			(calendarAndEvents.data.calendar.status === "connected" &&
				!calendarAndEvents.data.calendar.wasEverSynced) ||
			calendarAndEvents.data.calendar.status === "connecting",
	)

	if (!calendarAndEvents.data || isCalendarBeingSynchronized) {
		return (
			<div className="p-6">
				<SpinnerIcon className="h-8 w-8 mx-auto" />
				<p className="font-medium mt-2 text-gray-500">
					{t("Syncing your calendar, your upcoming meetings will be displayed here momentarily…")}
				</p>
			</div>
		)
	}

	const { calendar, calendarEvents } = calendarAndEvents.data
	if (calendar.status === "disconnected") {
		const description = t("Your {{calendarPlatform}} integration was disconnected, please reconnect it below.", {
			calendarPlatform: getCalendarPlatformDisplayName(calendar.platform),
		})

		return (
			<div className="p-6">
				<Alert description={description} className="m-4" />
				<CalendarSyncButtons
					calendar={calendar}
					reconnect={calendar.status === "disconnected" ? calendar.platform : undefined}
				/>
			</div>
		)
	}

	if (calendar.status === "not-found") {
		return (
			<div className="p-6">
				<ProspectingScheduleCalendarDisconnected calendar={calendar} />
			</div>
		)
	}

	return <DailyEventList calendarEvents={calendarEvents} handleCalendarEventAction={handleCalendarEventAction} />
}

export function ProspectingSchedule() {
	const { t } = useLanguage()

	return (
		<Card className="h-full">
			<h2 className="text-lg font-semibold text-gray-900 border-b border-gray-200 py-2 px-4">
				{t("My prospecting schedule")}
			</h2>
			<ProspectingScheduleInner />
		</Card>
	)
}

async function removeBotFromCall(
	calendarEventsGateway: ICalendarEventsGateway,
	calendarEventId: string,
	t: TFunction,
): Promise<void> {
	try {
		const result = await calendarEventsGateway.removeBotFromCall(calendarEventId)
		if (result.success) {
			toast.success(t("Removed recorder from call"), baseToastConfig)
		} else {
			console.error("Failed to remove the bot because:", result)
			switch (result.reason) {
				case "bot-not-found":
					toast.error(t("Failed to remove the recorder"), baseToastConfig)
					break

				case "bot-not-started":
					toast.error(t("No recorder in the call"), baseToastConfig)
					break

				case "failure":
					toast.error(t("Failed to remove the recorder"), baseToastConfig)
					break

				default:
					assertNever(result.reason)
			}
		}
	} catch (e) {
		console.error("Failed to remove the bot because:", e)
		toast.error(t("Failed to remove the recorder"), baseToastConfig)
	}
}

async function sendBotToMeetingNow(
	calendarEventsGateway: ICalendarEventsGateway,
	calendarEventId: string,
	t: TFunction,
): Promise<void> {
	try {
		await calendarEventsGateway.sendBotToMeetingNow(calendarEventId)
		toast.success(t("Recorder is on the way to the meeting"), baseToastConfig)
	} catch (e) {
		console.error("Failed to send the bot because:", e)
		toast.error(t("Failed to send the recorder"), baseToastConfig)
	}
}
