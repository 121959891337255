export type _MouseDragRangeIndicatorProps = {
	mouseDragStartTimeSeconds: number
	currentHoverTimeSeconds: number
	totalDurationSeconds: number
	containerRef: React.RefObject<HTMLDivElement>
	children?: React.ReactNode
}

export function MouseDragRangeIndicator({
	mouseDragStartTimeSeconds,
	currentHoverTimeSeconds,
	totalDurationSeconds,
	containerRef,
	children,
}: _MouseDragRangeIndicatorProps) {
	const rangeStartTimeSeconds = Math.min(mouseDragStartTimeSeconds, currentHoverTimeSeconds)
	const rangeEndTimeSeconds = Math.max(mouseDragStartTimeSeconds, currentHoverTimeSeconds)

	const boundingWidth = containerRef.current?.getBoundingClientRect()?.width ?? 0
	const boundingHeight = containerRef.current?.getBoundingClientRect()?.height ?? 0

	const rangeStartLeft = boundingWidth * (rangeStartTimeSeconds / totalDurationSeconds)
	const rangeEndLeft = boundingWidth * (rangeEndTimeSeconds / totalDurationSeconds)
	const rangeWidth = rangeEndLeft - rangeStartLeft

	return (
		<div
			className="absolute pointer-events-none z-20"
			style={{
				height: boundingHeight,
				width: boundingWidth,
			}}
		>
			<div
				className="absolute h-[calc(100%-theme(space.6))] z-20 bg-opacity-50 bg-indigo-500/20"
				style={{
					top: 24,
					width: rangeWidth,
					left: rangeStartLeft,
				}}
			>
				{children}
			</div>
		</div>
	)
}
