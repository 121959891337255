import assertNever from "assert-never"

import { Modal } from "../../../../components/design-system/Modal.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import {
	LibraryFolderSelect,
	type LibraryFolderSelectProps,
} from "../../../../components/shared/LibraryFolderSelect.component"
import { useLanguage } from "../../../../contexts/language.context"

export type Module =
	| {
			type: "library-select"
			name: string
			libraryFolderSelectProps: Omit<LibraryFolderSelectProps, "htmlName">
	  }
	| {
			type: "text" | "text-multiline"
			name: string
			title: string
			defaultValue: string | undefined
			placeholder: string
			required: boolean
	  }

type EntityAgnosticModalUIProps<T extends Module[]> = {
	isOpen: boolean

	title: string
	submitLabel: string
	submitEventName: string
	onClose: () => void
	onSubmit: (formData: ModuleData<T>) => void

	modules: T
}

export type ModuleData<T extends Module[]> = {
	[K in T[number] as K["name"]]: string
}

export function EntityAgnosticModalUI<T extends Module[]>({
	isOpen,
	title,
	submitLabel,
	submitEventName,
	onClose,
	onSubmit,
	modules,
}: EntityAgnosticModalUIProps<T>) {
	const { t } = useLanguage()

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		const formData = new FormData(event.currentTarget)

		const moduleData: Record<string, string> = {}
		for (const module of modules) {
			const value = formData.get(module.name)?.valueOf()
			if (typeof value === "string") {
				moduleData[module.name] = value
			} else {
				console.error(`Module ${module.name} has no value`)
			}
		}

		onSubmit(moduleData as ModuleData<T>)
	}

	return (
		<Modal open={isOpen} onClose={onClose}>
			<h3 className="text-lg font-semibold leading-6 text-gray-900" id="modal-title">
				{title}
			</h3>
			<form className="flex flex-col space-y-4" onSubmit={handleSubmit}>
				{modules.map((formModule, index) => {
					switch (formModule.type) {
						case "library-select":
							return (
								<LibraryFolderSelect
									key={`fm-${index}`}
									htmlName={formModule.name}
									{...formModule.libraryFolderSelectProps}
								/>
							)

						case "text":
							return (
								<div key={`fm-${index}`}>
									<label
										htmlFor={formModule.name}
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										{formModule.title}
									</label>
									<div className="mt-2">
										<input
											type="text"
											name={formModule.name}
											id={formModule.name}
											className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
											placeholder={formModule.placeholder}
											defaultValue={formModule.defaultValue}
											required={formModule.required}
										/>
									</div>
								</div>
							)

						case "text-multiline":
							return (
								<div key={`fm-${index}`}>
									<label
										htmlFor={formModule.name}
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										{formModule.title}
									</label>
									<div className="mt-2">
										<textarea
											rows={4}
											name={formModule.name}
											id={formModule.name}
											className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
											placeholder={formModule.placeholder}
											defaultValue={formModule.defaultValue}
											required={formModule.required}
										/>
									</div>
								</div>
							)

						default:
							assertNever(formModule)
					}
				})}

				<div className="flex items-center justify-end space-x-4 mt-4">
					<TrackingButton
						type="button"
						onClick={onClose}
						className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
						eventName="Cancel library folder creation"
					>
						{t("Cancel")}
					</TrackingButton>
					<TrackingButton
						type="submit"
						className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						eventName={submitEventName}
					>
						{submitLabel}
					</TrackingButton>
				</div>
			</form>
		</Modal>
	)
}
