import { CalendarDaysIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import _ from "lodash"
import * as React from "react"

import type { User } from "../../../core/domain/User.entity"
import { useAnalytics } from "../../contexts/analytics.context"
import { useLanguage } from "../../contexts/language.context"

function sortUsersByLastLoginAtAndFullName(users: User[]) {
	return _.orderBy(
		users,
		[(user) => user.lastLoginAt, (user) => user.getFullName()],
		[
			"desc", // Descending order for lastLoginAt
			"asc", // Ascending order for getFullName()
		],
	)
}

export type UserListProps = {
	users: User[]
	actionMenuContent?: (user: User) => React.ReactNode
	className?: string
	onUserRowClick?: (user: User) => void
}

export function UserList({ users, actionMenuContent, className, onUserRowClick }: UserListProps) {
	const { t } = useLanguage()

	const [search, setSearch] = React.useState("")
	const { setupEventTracking } = useAnalytics()

	const handleSearchChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.currentTarget.value.trim())
	}, [])

	const handleSearchBlur = React.useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			const searchValue = event.target.value
			if (!searchValue) {
				return
			}

			const { trackEvent: trackedUserSearch } = setupEventTracking({
				eventName: "Workspace settings: User searched",
				eventProperties: { searchValue },
			})
			trackedUserSearch()
		},
		[setupEventTracking],
	)

	const [usersWithLastLogin, usersWithoutLastLogin] = _.partition(users, (user) => user.lastLoginAt)

	const sortedWorkspaceUsers = [
		...sortUsersByLastLoginAtAndFullName(usersWithLastLogin),
		...sortUsersByLastLoginAtAndFullName(usersWithoutLastLogin),
	]

	const filteredWorkspaceUsers = search
		? sortedWorkspaceUsers.filter(
				(user) =>
					user.getFullName().toLowerCase().includes(search.toLowerCase()) ||
					user.email.toLowerCase().includes(search.toLowerCase()),
		  )
		: sortedWorkspaceUsers

	return (
		<>
			<input
				id="search"
				name="search"
				type="search"
				value={search}
				onChange={handleSearchChange}
				placeholder={t("Search users")}
				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-4"
				onBlur={handleSearchBlur}
			/>
			{filteredWorkspaceUsers.length > 0 ? (
				<ul
					role="list"
					className={clsx(
						"divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl overflow-y-auto",
						className,
					)}
				>
					{filteredWorkspaceUsers.map((user) => (
						<li
							key={user.email}
							className={clsx(
								"relative flex justify-between gap-x-6 p-4 hover:bg-gray-50",
								onUserRowClick && "cursor-pointer",
							)}
							onClick={onUserRowClick ? () => onUserRowClick(user) : undefined}
						>
							<div className="flex min-w-0 gap-x-4">
								<div className="min-w-0 flex-auto">
									<p className="text-sm font-semibold leading-6 text-gray-900 flex items-center gap-1">
										{user.getFullName()}
										{user.hasCalendarIntegration() && (
											<CalendarDaysIcon
												className={clsx(
													"h-4 w-4 inline-block",
													user.calendarStatus === "connected" && "text-navy-500",
													user.calendarStatus === "connecting" && "text-gray-400",
													user.calendarStatus === "disconnected" && "text-red-600",
												)}
												title={t("Calendar integration status: {{status}}", {
													status: t(user.calendarStatus ?? "unknown"),
												})}
											/>
										)}
									</p>
									<p className="mt-1 truncate text-xs leading-5 text-gray-500">
										{user.email}
										{user.isUnclaimed() && ` (${t("invite sent")})`}
									</p>
								</div>
							</div>
							{actionMenuContent?.(user)}
						</li>
					))}
				</ul>
			) : (
				<div className="p-4 text-center text-gray-500">{t("No users found")}</div>
			)}
		</>
	)
}
