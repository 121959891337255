import { DragDropContext, Droppable, type DropResult } from "@hello-pangea/dnd"
import clsx from "clsx"
import React from "react"

import type { CallHighlight } from "../../../../../../core/domain/Library.entity"
import { reorder } from "../../../../../utils/array"
import { EditContentCard } from "../../components/EditContentCard.component"

type ContentListProps = {
	highlights: CallHighlight[]
	onRemoveHighlight: (highlight: CallHighlight) => void
	onReorderHighlights: (highlights: CallHighlight[]) => void
}

export function ContentList({ onRemoveHighlight, highlights, onReorderHighlights }: ContentListProps) {
	const handleDragEnd = React.useCallback(
		(result: DropResult) => {
			if (!result.destination) {
				return
			}

			const items = reorder(highlights, result.source.index, result.destination.index)

			onReorderHighlights(items)
		},
		[highlights, onReorderHighlights],
	)

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId="droppable" type="group">
				{(provided, snapshot) => (
					<div
						className={clsx(
							"bg-gray-50 p-2 flex-1",
							snapshot.isDraggingOver && "bg-indigo-50 bg-opacity-60",
						)}
						{...provided.droppableProps}
						ref={provided.innerRef}
					>
						{highlights.map((highlight, i) => {
							return (
								<EditContentCard
									key={highlight.id}
									highlight={highlight}
									onRemoveHighlight={onRemoveHighlight}
									index={i}
								/>
							)
						})}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	)
}
