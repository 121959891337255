import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { EllipsisVerticalIcon, PlusIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import _ from "lodash"
import React from "react"
import { toast } from "react-toastify"

import type { EditTagTypePayload } from "../../../../../core/application/gateways/tag-types.gateway"
import type { TagType } from "../../../../../core/domain/TagType.entity"
import { Badge } from "../../../../components/design-system/Badge.component"
import { Button } from "../../../../components/design-system/Button.component"
import { Card } from "../../../../components/design-system/Card.component"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TagTypeBadge } from "../../../../components/shared/TagTypeBadge.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { useTagTypes } from "../../../../hooks/useTagTypes"
import { CreateTagModal, type CreateTagModalPayload } from "./CreateTagModal.component"
import { EditTagModal } from "./EditTagModal.component"

export function TagSettings() {
	const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false)
	const { t } = useLanguage()
	const { tags, refetchTagTypes } = useTagTypes()
	const { workspace, refetchMe, user } = useAuthenticatedSession()
	const { workspacesGateway, tagTypesGateway } = useDependencies()
	const hiddenTagTypeIds = React.useMemo(() => workspace.props.preferences?.tagIdsToHide ?? [], [workspace])
	const [defaultTags, customTags] = _.partition(tags === "loading" ? [] : tags, (tagType) => tagType.isDefaultTag())
	const sortedTags = [...customTags, ...defaultTags]
	const [editingTagType, setEditingTagType] = React.useState<TagType | null>(null)

	const isTagVisible = React.useCallback(
		(tagType: TagType) => {
			return !hiddenTagTypeIds.includes(tagType.properties.id)
		},
		[hiddenTagTypeIds],
	)

	const handleToggleTagType = React.useCallback(
		async (tagType: TagType) => {
			const currentHiddenTagTypeIds = workspace.props.preferences?.tagIdsToHide ?? []
			const shouldHide = !currentHiddenTagTypeIds.includes(tagType.properties.id)
			const newHiddenTagTypeIds = shouldHide
				? [...currentHiddenTagTypeIds, tagType.properties.id]
				: currentHiddenTagTypeIds.filter((id) => id !== tagType.properties.id)

			await workspacesGateway.updatePreferences({
				workspacePreferences: {
					tagIdsToHide: newHiddenTagTypeIds,
				},
			})

			await refetchMe()
		},
		[refetchMe, workspace.props.preferences?.tagIdsToHide, workspacesGateway],
	)

	const handleOpenEditModal = React.useCallback(async (tagType: TagType) => {
		setEditingTagType(tagType)
	}, [])

	const handleDeleteTagType = React.useCallback(
		async (tagType: TagType) => {
			if (tagType.isDefaultTag()) {
				return
			}

			if (!confirm(t("Are you sure you want to delete this item?"))) {
				return
			}

			await tagTypesGateway.deleteTagType(tagType)
			await refetchMe()
			refetchTagTypes()
		},
		[refetchMe, refetchTagTypes, t, tagTypesGateway],
	)

	const handleCreateTagType = React.useCallback(
		async (tagTypePayoad: CreateTagModalPayload) => {
			setIsCreateModalOpen(false)
			await tagTypesGateway.createTagType(tagTypePayoad)
			await refetchMe()
			refetchTagTypes()
		},
		[refetchMe, refetchTagTypes, tagTypesGateway],
	)

	const handleEditModalClose = React.useCallback(() => {
		setEditingTagType(null)
	}, [])

	const handleEditTagType = React.useCallback(
		async (payload: EditTagTypePayload) => {
			if (!editingTagType) {
				return
			}
			await tagTypesGateway.editTagType(editingTagType, payload)
			await refetchMe()
			refetchTagTypes()
			handleEditModalClose()
			toast(t("Tag updated successfully"), {
				type: "success",
			})
		},
		[editingTagType, handleEditModalClose, refetchMe, refetchTagTypes, t, tagTypesGateway],
	)

	return (
		<Card
			title={t("Tags")}
			CTA={
				user.isWorkspaceManager() ? (
					<Button
						type="button"
						onClick={() => setIsCreateModalOpen(true)}
						eventName="Create new tag clicked"
						variant="primary"
					>
						<PlusIcon className="h-5 w-5" aria-hidden="true" />
						{t("Create a new tag")}
					</Button>
				) : undefined
			}
		>
			<div className="shadow-md sm:rounded-md text-gray-600 mx-8 my-4 p-4 text-sm">
				<h3 className="py-3 text-md font-medium leading-4 text-gray-900">{t("What are tags?")}</h3>
				<p>
					{t(
						"Tags allow you to identify key topics discussed during your calls to improve tracking and visibility of trending subjects, or to categorise your calls for better organisation. They are assigned through automatic detection of relevant words, phrases, or themes, though you also have full control to add or remove tags manually.",
					)}
				</p>
				<p className="mt-2">{t("Tags can be applied to:")}</p>
				<ul className="list-disc pl-6 font-medium">
					<li>{t("The entire call")}</li>
					<li>{t("Specific snippets within a call")}</li>
				</ul>
			</div>
			<CreateTagModal
				open={isCreateModalOpen}
				onClose={() => setIsCreateModalOpen(false)}
				onSubmit={handleCreateTagType}
			/>
			{editingTagType && (
				<EditTagModal editingTag={editingTagType} onClose={handleEditModalClose} onSubmit={handleEditTagType} />
			)}
			{tags === "loading" ? (
				<SpinnerIcon className="h-8 w-8 mx-auto" />
			) : (
				<div className="px-8">
					<ul role="list" className="divide-y divide-gray-100">
						{sortedTags.map((tagType) => (
							<li
								key={tagType.properties.id}
								className={clsx("flex items-center justify-between gap-x-6 py-5")}
							>
								<div className={clsx("min-w-0", !isTagVisible(tagType) && "opacity-50")}>
									<div className={clsx("flex items-start gap-x-3")}>
										<TagTypeBadge tagType={tagType} />
										{tagType.isDefaultTag() && <Badge label={t("Default")} variant="neutral" />}
									</div>
									<div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
										<p>
											{t("Used in: ")}
											<span className="font-semibold">
												{tagType.properties.scopes
													.map((scope) => t(scope === "call" ? "Calls" : "Snippets"))
													.join(", ")}
											</span>
										</p>
										<svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
											<circle r={1} cx={1} cy={1} />
										</svg>
										<p className="truncate max-w-lg" title={tagType.properties.description}>
											{tagType.properties.description}
										</p>
									</div>
								</div>
								{user.isWorkspaceManager() && (
									<div className="flex flex-none items-center gap-x-4">
										<Menu as="div" className="relative flex-none">
											<MenuButton className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
												<span className="sr-only">Open options</span>
												<EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
											</MenuButton>
											<MenuItems
												transition
												className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
											>
												<MenuItem>
													<button
														onClick={() => handleOpenEditModal(tagType)}
														className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50 w-full text-left"
													>
														{t("Edit…")}
														<span className="sr-only">, {tagType.properties.name}</span>
													</button>
												</MenuItem>
												<MenuItem>
													<button
														onClick={() => handleToggleTagType(tagType)}
														className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50 w-full text-left"
													>
														{t(isTagVisible(tagType) ? "Hide" : "Show")}
														<span className="sr-only">, {tagType.properties.name}</span>
													</button>
												</MenuItem>
												{!tagType.isDefaultTag() && (
													<MenuItem>
														<button
															onClick={() => handleDeleteTagType(tagType)}
															className="block px-3 py-1 text-sm leading-6 data-[focus]:bg-gray-50 text-red-500 w-full text-left"
														>
															{t("Delete…")}
															<span className="sr-only">, {tagType.properties.name}</span>
														</button>
													</MenuItem>
												)}
											</MenuItems>
										</Menu>
									</div>
								)}
							</li>
						))}
					</ul>
				</div>
			)}
		</Card>
	)
}
