import clsx from "clsx"

import { TrackingButton } from "../design-system/TrackingButton.component"
import { TrackingLink } from "../design-system/TrackingLink.component"

type EmptyStateCTA =
	| {
			type: "link"
			label: string
			to: string
			state?: unknown
			analyticsEventName: string
			analyticsEventProperties?: Record<string, unknown>
	  }
	| {
			type: "button"
			label: string
			onClick: () => void
			analyticsEventName: string
			analyticsEventProperties?: Record<string, unknown>
	  }

type EmptyStateProps = {
	title: string
	description: string
	cta?: EmptyStateCTA
	className?: string
}

export function EmptyState({ title, description, cta, className }: EmptyStateProps) {
	return (
		<div className={clsx("flex flex-col items-center justify-center space-y-4", className)}>
			<h2 className="text-xl font-semibold text-center">{title}</h2>
			<p className="text-md text-center text-gray-500">{description}</p>
			{cta && (
				<div>
					{cta.type === "link" && (
						<TrackingLink
							to={cta.to}
							state={cta.state}
							eventName={cta.analyticsEventName}
							eventProperties={cta.analyticsEventProperties}
							className="flex items-center rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							{cta.label}
						</TrackingLink>
					)}
					{cta.type === "button" && (
						<TrackingButton
							eventName={cta.analyticsEventName}
							onClick={cta.onClick}
							eventProperties={cta.analyticsEventProperties}
							className="flex items-center rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							{cta.label}
						</TrackingButton>
					)}
				</div>
			)}
		</div>
	)
}
