import clsx from "clsx"
import React from "react"

import type { GetDealsReportSuccessResponse } from "../../../../../core/application/gateways/deals.gateway/schemas/report"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { InsightCard } from "../../../../components/shared/InsightCard.component"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { useQueryParamsState } from "../../../../hooks/useQueryParamsState"
import { useDealOwners } from "../../hooks/useDealOwners"
import { type FunnelGroupData, SalesMotionFunnel } from "./components/SalesMotionFunnel.component"
import {
	SalesMotionPipelineFilters,
	type SalesMotionSelectFilter,
} from "./components/SalesMotionPipelineFilters.component"
import { SalesMotionPipelineGraphs } from "./components/SalesMotionPipelineGraphs.component"
import { type SalesMotionView, SalesMotionViewSelectorMenu } from "./components/SalesMotionViewSelectorMenu.component"

function useSalesMotionFilters(
	filterId: string,
	defaultComparisonType: SalesMotionSelectFilter,
	defaultPeriodStartMonthsAgo = 1,
) {
	const [ownerId, setOwnerId] = useQueryParamsState<string | null>(`${filterId}-ownerId`, null)
	const [comparisonType, setComparisonType] = useQueryParamsState<SalesMotionSelectFilter>(
		`${filterId}-type`,
		defaultComparisonType,
	)

	const [_period, _setPeriod] = useQueryParamsState<{ startDate: string; endDate: string }>(
		`${filterId}-period`,
		(() => {
			const startMonth = new Date()
			startMonth.setMonth(startMonth.getMonth() - defaultPeriodStartMonthsAgo)
			startMonth.setHours(23, 59, 59, 999)

			const endMonth = new Date()
			endMonth.setHours(23, 59, 59, 999)
			endMonth.setMonth(startMonth.getMonth() + 1)

			return {
				startDate: startMonth.toISOString().split("T")[0],
				endDate: endMonth.toISOString().split("T")[0],
			}
		})(),
	)

	const period = React.useMemo(() => {
		return {
			startDate: new Date(_period.startDate),
			endDate: new Date(_period.endDate),
		}
	}, [_period])

	const setPeriod = React.useCallback(
		(period: { startDate: Date; endDate: Date }) => {
			_setPeriod({
				startDate: period.startDate.toISOString().split("T")[0],
				endDate: period.endDate.toISOString().split("T")[0],
			})
		},
		[_setPeriod],
	)

	return {
		period,
		setPeriod,
		ownerId,
		setOwnerId,
		comparisonType,
		setComparisonType,
	}
}

export function SalesMotionPipelineTabView() {
	const { dealsGateway } = useDependencies()
	const [isFetching, setIsFetching] = React.useState(true)
	const [dealsReport, setDealsReport] = React.useState<GetDealsReportSuccessResponse | null>(null)
	const [viewType, setViewType] = useQueryParamsState<SalesMotionView>("view", "graphs")
	const { t } = useLanguage()
	const groupAFilter = useSalesMotionFilters("a", "all", 1)
	const groupBFilter = useSalesMotionFilters("b", "all", 2)
	const { isLoading: areOwnersLoading, owners } = useDealOwners(false) // no fake data for now

	React.useEffect(() => {
		async function fetchDealsReport() {
			const deals = await dealsGateway.getDealsReport({
				dealGroups: [
					{
						category: groupAFilter.comparisonType,
						identifier: "a",
						periodStart: groupAFilter.period.startDate,
						ownerId: groupAFilter.ownerId,
						periodEnd: groupAFilter.period.endDate,
					},
					{
						category: groupBFilter.comparisonType,
						identifier: "b",
						periodStart: groupBFilter.period.startDate,
						ownerId: groupBFilter.ownerId,
						periodEnd: groupBFilter.period.endDate,
					},
				],
			})
			setDealsReport(deals)
		}

		setIsFetching(true)
		fetchDealsReport().finally(() => setIsFetching(false))
	}, [
		dealsGateway,
		groupAFilter.comparisonType,
		groupAFilter.ownerId,
		groupAFilter.period.endDate,
		groupAFilter.period.startDate,
		groupBFilter.comparisonType,
		groupBFilter.ownerId,
		groupBFilter.period.endDate,
		groupBFilter.period.startDate,
	])

	const dealReportA = dealsReport?.dealGroups.find((group) => group.identifier === "a")
	const dealReportB = dealsReport?.dealGroups.find((group) => group.identifier === "b")

	const groupA: FunnelGroupData[] = React.useMemo(() => {
		if (!dealReportA) return []

		return dealReportA.dealStages
			.filter((stage) => !stage.isLostStage)
			.map((stage) => ({
				step: stage.stage,
				numberOfDaysInStage: stage.isWonStage
					? Math.max(2, Math.ceil(stage.averageDurationDaysPerDeal))
					: Math.ceil(stage.averageDurationDaysPerDeal),
				percentOfDeals: dealReportA.totalDeals
					? Math.round((stage.totalDealsReachedStage / dealReportA.totalDeals) * 100)
					: 0,
			}))
	}, [dealReportA])

	const groupB: FunnelGroupData[] = React.useMemo(() => {
		if (!dealReportB) return []

		return dealReportB.dealStages
			.filter((stage) => !stage.isLostStage)
			.map((stage) => ({
				step: stage.stage,
				numberOfDaysInStage: stage.isWonStage
					? Math.max(2, Math.ceil(stage.averageDurationDaysPerDeal))
					: Math.ceil(stage.averageDurationDaysPerDeal),
				percentOfDeals: dealReportB.totalDeals
					? Math.round((stage.totalDealsReachedStage / dealReportB.totalDeals) * 100)
					: 0,
			}))
	}, [dealReportB])

	if (!groupA || !groupB || !dealReportA || !dealReportB || !dealsReport || areOwnersLoading || !owners) {
		return (
			<div className="flex items-center justify-center flex-1 mt-6">
				<SpinnerIcon className="h-8 max-h-8 max-w-8 w-8 mr-2 flex-initial" />
			</div>
		)
	}

	const groupAWinRatePercentage = dealReportA.totalDeals
		? Math.round((dealReportA.totalWonDeals / dealReportA.totalDeals) * 100)
		: 0
	const groupBWinRatePercentage = dealReportB.totalDeals
		? Math.round((dealReportB.totalWonDeals / dealReportB.totalDeals) * 100)
		: 0

	return (
		<>
			<div className="flex justify-between flex-col gap-4 lg:gap-0">
				<div className="flex lg:flex-row flex-col my-5 gap-2">
					<SalesMotionPipelineFilters
						dealOwners={owners.users.map((owner) => ({
							id: owner.crmId,
							name: owner.fullName ?? owner.username ?? owner.email ?? "",
						}))}
						onSelectDeals={groupAFilter.setComparisonType}
						onSelectOwner={groupAFilter.setOwnerId}
						selectedDeals={groupAFilter.comparisonType}
						selectedOwner={groupAFilter.ownerId}
						period={groupAFilter.period}
						onPeriodChange={groupAFilter.setPeriod}
						comparisonLabel="compare"
					/>
					<SalesMotionPipelineFilters
						dealOwners={owners.users.map((owner) => ({
							id: owner.crmId,
							name: owner.fullName ?? owner.username ?? owner.email ?? "",
						}))}
						onSelectDeals={groupBFilter.setComparisonType}
						onSelectOwner={groupBFilter.setOwnerId}
						selectedDeals={groupBFilter.comparisonType}
						selectedOwner={groupBFilter.ownerId}
						period={groupBFilter.period}
						onPeriodChange={groupBFilter.setPeriod}
						comparisonLabel="with"
					/>
				</div>
				<div className="flex gap-3 flex-row flex-wrap sm:flex-nowrap mb-6 sm:mb-0 justify-center md:justify-normal">
					<InsightCard
						title={t("Win rate")}
						values={[
							{
								value: `${groupAWinRatePercentage}%`,
								label: `${dealReportA.totalWonDeals}/${dealReportA.totalDeals}`,
								valueClassName: "!text-navy-500",
								key: "a-win-rate",
							},
							{
								value: `${groupBWinRatePercentage}%`,
								label: `${dealReportB.totalWonDeals}/${dealReportB.totalDeals}`,
								valueClassName: "!text-pink",
								key: "b-win-rate",
							},
						]}
						className="min-w-[230px]"
					/>
					<InsightCard
						title={t("Average pipeline duration")}
						values={[
							{
								value: t("{{count}} days", {
									count: Math.ceil(
										dealReportA.dealStages.reduce(
											(acc, stage) => acc + stage.averageDurationDaysPerDeal,
											0,
										),
									),
								}),
								key: "a-duration",
								valueClassName: "!text-navy-500",
							},
							{
								value: t("{{count}} days", {
									count: Math.ceil(
										dealReportB.dealStages.reduce(
											(acc, stage) => acc + stage.averageDurationDaysPerDeal,
											0,
										),
									),
								}),
								key: "b-duration",
								valueClassName: "!text-pink",
							},
						]}
						className="min-w-[230px]"
					/>
					<InsightCard
						title={t("Average deal value")}
						values={[
							{
								value: dealReportA.averageDealAmount.toLocaleString("en-EN", {
									style: "currency",
									currency: "EUR",
								}),
								key: "a-amount",
								valueClassName: "!text-navy-500",
							},
							{
								value: dealReportB.averageDealAmount.toLocaleString("en-EN", {
									style: "currency",
									currency: "EUR",
								}),
								key: "b-amount",
								valueClassName: "!text-pink",
							},
						]}
						className="min-w-[230px]"
					/>
				</div>
			</div>
			<div className="relative mt-4">
				{isFetching && (
					<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
						<SpinnerIcon className="h-8 max-h-8 max-w-8 w-8 mr-2 flex-initial" />
					</div>
				)}

				<div className="flex items-baseline gap-4">
					<SalesMotionViewSelectorMenu currentView={viewType} handleViewChange={setViewType} />
					<div className={clsx("flex-1", isFetching && "opacity-50")}>
						{viewType === "timeline" && <SalesMotionFunnel groupA={groupA} groupB={groupB} />}
						{viewType === "graphs" && <SalesMotionPipelineGraphs dealsReport={dealsReport} />}
					</div>
				</div>
			</div>
		</>
	)
}
