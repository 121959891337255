import { BookmarkSlashIcon } from "@heroicons/react/20/solid"
import assertNever from "assert-never"
import _ from "lodash"
import { useState } from "react"

import type { CallHighlight } from "../../../../../core/domain/Library.entity"
import type { CoachingSession } from "../../../../../core/infra/gateways/http.library.gateway/getCoachingSessionsResponse.schema"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { type TranslationKey, useLanguage } from "../../../../contexts/language.context"
import { CoachingSessionsGrid } from "./CurrentLibraryFolderHighlightsGrid.component"
import { CurrentLibraryFolderPlayerPanel } from "./CurrentLibraryFolderPlayerPanel.component"
import { sortCoachingSessionItems } from "./utils"

/**
 * This file is a copy-paste of CurrentLibraryFolder.component.tsx but adapted to coaching sessions.
 */

export type CoachingSessionsViewProps = {
	coachingSessions: CoachingSession[]
}

export type CurrentHighlight = {
	sessionIndex: number
	itemIndex: number
	highlightIndex: number // an item can one one or two highlights depending on if it's a well-played or improvement
}

export function CoachingSessionsView({ coachingSessions }: CoachingSessionsViewProps) {
	const { t } = useLanguage()
	const { libraryFoldersGateway } = useDependencies()

	coachingSessions.forEach((session) => {
		session.items = sortCoachingSessionItems(session.items)
	})

	const [currentHighlight, setCurrentHighlight] = useState<CurrentHighlight>({
		sessionIndex: 0,
		itemIndex: 0,
		highlightIndex: 0,
	})

	const totalUnseenHighlightsCount = countUnseenHighlights(coachingSessions)

	const prev = lessOne(currentHighlight, coachingSessions)
	const next = plusOne(currentHighlight, coachingSessions)

	const goToPreviousHighlight = () => {
		if (prev) {
			setCurrentHighlight(prev)
		}
	}

	const goToNextHighlight = () => {
		const currentItem = pickItem(currentHighlight, coachingSessions)
		if (
			currentItem.type === "well-played" ||
			(currentItem.type === "improvement" && currentHighlight.highlightIndex === 1)
		) {
			void libraryFoldersGateway.markHighlightAsSeen(pickHighlight(currentHighlight, coachingSessions))
		}

		if (next) {
			setCurrentHighlight(next)
		}
	}

	if (totalUnseenHighlightsCount === 0) {
		return (
			<div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center">
				<BookmarkSlashIcon className="mx-auto h-12 w-12 text-gray-400" />
				<span className="mt-2 block text-sm font-semibold text-gray-900">
					{t(
						"Congrats, you've completed your training session for the week!\nCome back next week to continue refining your skills.",
					)}
				</span>
			</div>
		)
	}

	const goToNextHighlightLabel = ((): TranslationKey => {
		const currentItem = pickItem(currentHighlight, coachingSessions)

		switch (currentItem.type) {
			case "well-played":
				return next ? "Mark as seen, view next" : "Mark as seen"

			case "improvement":
				if (currentHighlight.highlightIndex === 1) {
					return next ? "Mark as seen, view next" : "Mark as seen"
				} else {
					return "See best practice"
				}
		}
	})()

	return (
		<>
			<div className="flex">
				<div className="w-1/2">
					<CoachingSessionsGrid
						coachingSessions={coachingSessions}
						currentHighlight={currentHighlight}
						setCurrentHighlight={setCurrentHighlight}
					/>
				</div>
				<div className="w-1/2">
					<CurrentLibraryFolderPlayerPanel
						/**
						 * shortcut: do not show other highlights here
						 * BUT we need to feed at least the current one, because it feeds "allowedTimeRanges" in the player
						 */
						highlightsOfCurrentCall={[pickHighlight(currentHighlight, coachingSessions)]}
						callHighlight={pickHighlight(currentHighlight, coachingSessions)}
						canGoToPreviousHighlight={Boolean(prev)}
						canGoToNextHighlight={true} /* must be able to click 'Mark as seen' */
						goToPreviousHighlightLabel="Previous"
						goToNextHighlightLabel={goToNextHighlightLabel}
						goToPreviousHighlight={goToPreviousHighlight}
						goToNextHighlight={goToNextHighlight}
					/>
				</div>
			</div>
		</>
	)
}

function countUnseenHighlights(coachingSessions: CoachingSession[]): number {
	const items = coachingSessions.flatMap((session) => session.items)

	return _.sum(
		items.map((item): number => {
			switch (item.type) {
				case "well-played":
					return item.seenAt ? 0 : 1
				case "improvement":
					return item.seenAt ? 0 : 2
				default:
					assertNever(item)
			}
		}),
	)
}

function lessOne(
	currentHighlight: CurrentHighlight,
	coachingSessions: CoachingSession[],
): CurrentHighlight | undefined {
	if (currentHighlight.highlightIndex > 0) {
		return {
			sessionIndex: currentHighlight.sessionIndex,
			itemIndex: currentHighlight.itemIndex,
			highlightIndex: currentHighlight.highlightIndex - 1,
		}
	}

	if (currentHighlight.itemIndex > 0) {
		const item = coachingSessions[currentHighlight.sessionIndex].items[currentHighlight.itemIndex - 1]
		return {
			sessionIndex: currentHighlight.sessionIndex,
			itemIndex: currentHighlight.itemIndex - 1,
			highlightIndex: item.type === "improvement" ? 1 : 0,
		}
	}

	if (currentHighlight.sessionIndex > 0) {
		const session = coachingSessions[currentHighlight.sessionIndex - 1]
		const item = session.items[session.items.length - 1]
		return {
			sessionIndex: currentHighlight.sessionIndex - 1,
			itemIndex: session.items.length - 1,
			highlightIndex: item.type === "improvement" ? 1 : 0,
		}
	}

	return undefined
}

function plusOne(
	currentHighlight: CurrentHighlight,
	coachingSessions: CoachingSession[],
): CurrentHighlight | undefined {
	const session = coachingSessions[currentHighlight.sessionIndex]
	if (!session) return

	const item = session.items[currentHighlight.itemIndex]
	if (!item) return

	if (currentHighlight.highlightIndex < (item.type === "improvement" ? 1 : 0)) {
		return {
			sessionIndex: currentHighlight.sessionIndex,
			itemIndex: currentHighlight.itemIndex,
			highlightIndex: currentHighlight.highlightIndex + 1,
		}
	}

	if (currentHighlight.itemIndex < session.items.length - 1) {
		return {
			sessionIndex: currentHighlight.sessionIndex,
			itemIndex: currentHighlight.itemIndex + 1,
			highlightIndex: 0,
		}
	}

	if (currentHighlight.sessionIndex < coachingSessions.length - 1) {
		return {
			sessionIndex: currentHighlight.sessionIndex + 1,
			itemIndex: 0,
			highlightIndex: 0,
		}
	}

	return undefined
}

function pickItem(currentHighlight: CurrentHighlight, coachingSessions: CoachingSession[]) {
	const session = coachingSessions[currentHighlight.sessionIndex]
	return session.items[currentHighlight.itemIndex]
}

function pickHighlight(currentHighlight: CurrentHighlight, coachingSessions: CoachingSession[]): CallHighlight {
	const item = pickItem(currentHighlight, coachingSessions)

	switch (item.type) {
		case "well-played":
			return item.callHighlight

		case "improvement":
			if (currentHighlight.highlightIndex === 0) {
				return item.callHighlight
			} else {
				return item.bestPractice
			}

		default:
			assertNever(item)
	}
}
