import React from "react"

import { SalesMotionSection } from "./components/SalesMotionSection.component"
import { WinRateSection } from "./components/WinRateSection.component"

export type SalesPerformanceTabViewProps = {
	useFakeData: boolean
}

export function SalesPerformanceTabView({ useFakeData }: SalesPerformanceTabViewProps) {
	return (
		<div className="mt-2 flex flex-col gap-4">
			<WinRateSection useFakeData={useFakeData} />
			<SalesMotionSection useFakeData={useFakeData} />
		</div>
	)
}
