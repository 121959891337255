import React from "react"

import { Alert } from "../../../components/design-system/Alert.component"
import { TrackingButton } from "../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../contexts/language.context"
import { type CallTimeRange, makePublicSharedCallUrl } from "../../../utils/callSharing"

type IShareCallModalContentProps = {
	publicAccessToken: string
	callId: string
	allowedTimeRanges?: CallTimeRange[]
}

export function ShareCallModalContent({ publicAccessToken, callId, allowedTimeRanges }: IShareCallModalContentProps) {
	const { t } = useLanguage()
	const [isLinkCopied, setIsLinkCopied] = React.useState(false)

	const inputRef = React.useRef<HTMLInputElement>(null)

	const selectText = React.useCallback(() => {
		inputRef.current?.select()
	}, [])

	const handleCopyToClipboard = React.useCallback(() => {
		if (inputRef.current) {
			inputRef.current.select()
			navigator.clipboard.writeText(inputRef.current.value)
			setIsLinkCopied(true)
		}
	}, [])

	const publicSharedCallPath = makePublicSharedCallUrl(publicAccessToken, allowedTimeRanges ?? null)

	return (
		<div className="mt-2">
			{allowedTimeRanges?.length ? (
				<Alert
					description={t("Only a specific part of the call will be shared.")}
					className="mb-2"
					variant="warning"
				/>
			) : (
				<Alert
					description={t(
						"If you want to share only a portion of the call, select the desired time range on the call's timeline.",
					)}
					className="mb-2"
					variant="info"
				/>
			)}
			<p className="text-sm text-gray-600">
				{t("Sharing this link will allow others to view this call. The following information will be shared:")}
				<ul className="list-disc ml-6">
					<li>{t("Video recording with timeline")}</li>
					<li>{t("Speaker names")}</li>
					<li>{t("Date and time")}</li>
					<li>{t("Overview")}</li>
					<li>{t("Transcript")}</li>
				</ul>
			</p>
			<p className="text-sm mb-2 mt-4 text-gray-600 font-medium">
				{t("Copy the link below and share it with anyone you want to give access to this call.")}
			</p>
			{isLinkCopied && (
				<Alert variant="success" description={t("Link successfully copied to clipboard")} className="mb-2" />
			)}
			<div className="flex items-center gap-2 items-stretch">
				<input
					id="share-link"
					name="share-link"
					type="text"
					readOnly
					className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					value={publicSharedCallPath}
					onClick={selectText}
					ref={inputRef}
				/>
				<TrackingButton
					eventName="Share call copy button clicked"
					eventProperties={{
						callId,
						allowedTimeRangesCount: allowedTimeRanges?.length ?? 0,
					}}
					onClick={handleCopyToClipboard}
					type="button"
					className="whitespace-nowrap rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
				>
					{t("Copy link")}
				</TrackingButton>
			</div>
		</div>
	)
}
