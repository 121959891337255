import { createContext, useContext } from "react"

import config from "../../config"
import type { IAuthenticationGateway } from "../../core/application/gateways/authentication.gateway"
import type { IBillingGateway } from "../../core/application/gateways/billing.gateway"
import type { ICalendarEventsGateway } from "../../core/application/gateways/calendar-events.gateway"
import type { ICallExcerptsGateway } from "../../core/application/gateways/call-excerpts.gateway"
import type { ICallsGateway } from "../../core/application/gateways/calls.gateway"
import type { ICouponsGateway } from "../../core/application/gateways/coupons.gateway"
import type { IDealsGateway } from "../../core/application/gateways/deals.gateway/deals.gateway"
import type { IInsightsGateway } from "../../core/application/gateways/insights.gateway"
import type { ILibraryGateway } from "../../core/application/gateways/library.gateway"
import type { IMeetingNotesTemplatesGateway } from "../../core/application/gateways/meeting-notes-templates.gateway"
import type { IPlatformAdminGateway } from "../../core/application/gateways/platform-admin.gateway"
import type { IRelationshipsGateway } from "../../core/application/gateways/relationships.gateway"
import type { IStorageGateway } from "../../core/application/gateways/storage.gateway"
import type { ITagTypesGateway } from "../../core/application/gateways/tag-types.gateway"
import type { ITeamsGateway } from "../../core/application/gateways/teams.gateway"
import type { ITrainingGateway } from "../../core/application/gateways/training.gateway/training.gateway"
import type { IUsersGateway } from "../../core/application/gateways/users.gateway/users.gateway"
import type { IWorkspacesGateway } from "../../core/application/gateways/workspaces.gateway"
import { HttpAuthenticationGateway } from "../../core/infra/gateways/http.authentication.gateway/http.authentication.gateway"
import { HttpBillingGateway } from "../../core/infra/gateways/http.billing.gateway"
import { HttpCalendarEventsGateway } from "../../core/infra/gateways/http.calendar-events.gateway"
import { HttpCallExcerptGateway } from "../../core/infra/gateways/http.call-excerpt.gateway"
import { HttpCallsGateway } from "../../core/infra/gateways/http.calls.gateway"
import { HttpCouponsGateway } from "../../core/infra/gateways/http.coupons.gateway"
import { HttpDealsGateway } from "../../core/infra/gateways/http.deals.gateway"
import { HttpInsightsGateway } from "../../core/infra/gateways/http.insights.gateway"
import { HttpLibraryGateway } from "../../core/infra/gateways/http.library.gateway/http.library.gateway"
import { HttpMeetingNotesTemplatesGateway } from "../../core/infra/gateways/http.meeting-notes-templates.gateway"
import { HttpPlatformAdminGateway } from "../../core/infra/gateways/http.platform-admin.gateway"
import { HttpRelationshipsGateway } from "../../core/infra/gateways/http.relationships.gateway"
import { HttpStorageGateway } from "../../core/infra/gateways/http.storage.gateway"
import { HttpTagTypesGateway } from "../../core/infra/gateways/http.tag-types.gateway"
import { HttpTeamsGateway } from "../../core/infra/gateways/http.teams.gateway"
import { HttpTrainingGateway } from "../../core/infra/gateways/http.training.gateway"
import { HttpUsersGateway } from "../../core/infra/gateways/http.users.gateway"
import { HttpWorkspacesGateway } from "../../core/infra/gateways/http.workspaces.gateway"

export type AppDependencies = {
	callsGateway: ICallsGateway
	calendarEventsGateway: ICalendarEventsGateway
	authenticationGateway: IAuthenticationGateway
	usersGateway: IUsersGateway
	workspacesGateway: IWorkspacesGateway
	libraryFoldersGateway: ILibraryGateway
	platformAdminGateway: IPlatformAdminGateway
	billingGateway: IBillingGateway
	couponsGateway: ICouponsGateway
	insightsGateway: IInsightsGateway
	dealsGateway: IDealsGateway
	relationshipsGateway: IRelationshipsGateway
	trainingGateway: ITrainingGateway
	storageGateway: IStorageGateway
	teamsGateway: ITeamsGateway
	tagTypesGateway: ITagTypesGateway
	callExcerptsGateway: ICallExcerptsGateway
	meetingNotesTemplatesGateway: IMeetingNotesTemplatesGateway
}

const appDependencies: AppDependencies = {
	callsGateway: new HttpCallsGateway(config.apiUrl),
	calendarEventsGateway: new HttpCalendarEventsGateway(config.apiUrl),
	authenticationGateway: new HttpAuthenticationGateway(config.apiUrl),
	usersGateway: new HttpUsersGateway(config.apiUrl),
	workspacesGateway: new HttpWorkspacesGateway(config.apiUrl),
	libraryFoldersGateway: new HttpLibraryGateway(config.apiUrl),
	platformAdminGateway: new HttpPlatformAdminGateway(config.apiUrl),
	billingGateway: new HttpBillingGateway(config.apiUrl),
	couponsGateway: new HttpCouponsGateway(config.apiUrl),
	insightsGateway: new HttpInsightsGateway(config.apiUrl),
	dealsGateway: new HttpDealsGateway(config.apiUrl),
	relationshipsGateway: new HttpRelationshipsGateway(config.apiUrl),
	trainingGateway: new HttpTrainingGateway(config.apiUrl),
	storageGateway: new HttpStorageGateway(config.apiUrl),
	teamsGateway: new HttpTeamsGateway(config.apiUrl),
	tagTypesGateway: new HttpTagTypesGateway(config.apiUrl),
	callExcerptsGateway: new HttpCallExcerptGateway(config.apiUrl),
	meetingNotesTemplatesGateway: new HttpMeetingNotesTemplatesGateway(config.apiUrl),
}

const AppDependenciesContext = createContext<AppDependencies>(appDependencies)

type AppDependenciesProviderProps = {
	children: React.ReactNode
}

export function DependenciesProvider({ children }: AppDependenciesProviderProps) {
	return <AppDependenciesContext.Provider value={appDependencies}>{children}</AppDependenciesContext.Provider>
}

export function useDependencies() {
	const context = useContext(AppDependenciesContext)
	if (!context) {
		throw new Error("useDependencies must be used within a DependenciesProvider")
	}
	return context
}
