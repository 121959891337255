import assertNever from "assert-never"
import React from "react"
import { Trans } from "react-i18next"
import { Navigate, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useSearchParam } from "react-use"

import { Alert } from "../../components/design-system/Alert.component"
import { PublicLayout } from "../../components/shared/PublicLayout/PublicLayout.component"
import { useSession } from "../../contexts/authentication.context"
import { useDependencies } from "../../contexts/dependencies.context"
import { useLanguage } from "../../contexts/language.context"
import { makeDashboardPath, makeLoginPath } from "../../router/Router"
import { ResetPasswordForm } from "./ResetPasswordForm.component"

type ResetState =
	| {
			status: "resetting" | "reset" | "idle"
	  }
	| {
			status: "error"
			error: Error
	  }

export function ResetPasswordPage() {
	const { user } = useSession()
	const { t } = useLanguage()
	const navigate = useNavigate()
	const resetToken = useSearchParam("token")

	const { authenticationGateway } = useDependencies()
	const [resetState, setResetState] = React.useState<ResetState>({ status: "idle" })

	const handleSubmit = React.useCallback(
		async (plainPassword: string) => {
			if (!resetToken) {
				return
			}

			try {
				setResetState({
					status: "resetting",
				})
				const result = await authenticationGateway.resetPassword(resetToken, plainPassword)
				if (!result.success) {
					switch (result.reason) {
						case "token-expired":
							throw new Error("Token expired")
						default:
							assertNever(result.reason)
					}
				}
				toast(t("Your password has been reset. You can now log in with your new password."), {
					type: "success",
				})
				navigate(makeLoginPath())
			} catch (e) {
				if (e instanceof Error) {
					setResetState({
						status: "error",
						error: e,
					})
				}
				console.error("Error:", e)
			}
		},
		[authenticationGateway, navigate, resetToken, t],
	)

	if (!resetToken) {
		return <Navigate to={makeLoginPath()} replace={true} />
	}

	if (user) {
		return <Navigate to={makeDashboardPath()} replace={true} />
	}

	return (
		<PublicLayout pageName="Reset password" isFluid className="flex justify-center" isSignupAllowed={false}>
			{resetState.status === "error" && (
				<Alert
					description={
						<Trans
							i18nKey="An error occurred while resetting your password. Please try again or contact support at <1>{{email}}</1>"
							components={{
								1: (
									<a className=" text-sky font-semibold" href="mailto:support@rippletide.com">
										support@rippletide.com
									</a>
								),
							}}
							values={{ email: "support@rippletide.com" }}
						/>
					}
					variant="error"
				/>
			)}
			<div className="flex flex-col max-w-2xl w-full">
				<ResetPasswordForm handleSubmit={handleSubmit} isBusy={resetState.status === "resetting"} />
			</div>
		</PublicLayout>
	)
}
