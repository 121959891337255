import { PencilSquareIcon, QuestionMarkCircleIcon, TrashIcon } from "@heroicons/react/24/outline"
import assertNever from "assert-never"
import { useState } from "react"

import { HELP_CENTER_URLS } from "../../../../../config"
import {
	SUPPORTED_WORKSPACE_LANGUAGES,
	type WorkspaceLanguage,
} from "../../../../../core/infra/gateways/http.authentication.gateway/response-schemas"
import { Alert } from "../../../../components/design-system/Alert.component"
import { Button } from "../../../../components/design-system/Button.component"
import { Card } from "../../../../components/design-system/Card.component"
import { Input } from "../../../../components/design-system/Input"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { FileUploadModal } from "../../../../components/shared/FileUploadModal.component"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { useWorkspacePreferencesSettings } from "./useWorkspacePreferencesSettings"

const nativeLanguageLabelByLanguage: Record<WorkspaceLanguage, string> = {
	en: "English",
	fr: "Français",
	es: "Español",
	it: "Italiano",
}

export function PreferencesSettings() {
	const { t } = useLanguage()
	const { workspacesGateway } = useDependencies()
	const {
		language,
		handleLanguageChange,

		companyName,
		handleCompanyNameChange,

		companyLogoImageId,
		handleCompanyLogoUploaded,
		handleCompanyLogoDelete,

		formStatus,
		handleSubmit,

		isMeddicScoringEnabled,
		handleMeddicScoringToggle,

		meddicSections,
		handleHideMeddicSectionToggle,
		meddicSectionsToHide,
		hasUnsavedChanges,
	} = useWorkspacePreferencesSettings()
	const [uploadModalOpen, setUploadModalOpen] = useState(false)

	return (
		<Card
			title={t("Workspace preferences")}
			className="col-span-2 px-6 py-4"
			CTA={
				<Button
					eventName="Settings.Workspace.Preferences form submitted"
					eventProperties={{ provider: "salesforce", credentialType: "password" }}
					type="button"
					variant="primary"
					disabled={formStatus.status === "submitting" || !hasUnsavedChanges}
					onClick={handleSubmit}
				>
					<span className="flex gap-2 items-center">
						{formStatus.status === "submitting" && <SpinnerIcon className="h-5 w-5 mx-auto" />}
						{formStatus.status === "submitting" ? t("Saving preferences…") : t("Save preferences")}
					</span>
				</Button>
			}
		>
			<div className="mt-4">
				<dl className="px-6 py-4 space-y-6 divide-y divide-gray-100 text-sm leading-6">
					<div className="pt-6 sm:flex">
						<dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6 flex items-center">
							{t("Language")}{" "}
							<TrackingLink
								to={HELP_CENTER_URLS.Settings.Language[language]}
								eventName="Settings: open language help center"
								target="_blank"
							>
								<QuestionMarkCircleIcon className="ml-2 h-5 w-5 text-gray-400" />
							</TrackingLink>
						</dt>
						<dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto items-center">
							<select
								id="language"
								name="language"
								defaultValue={language}
								className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
								onChange={(event) => handleLanguageChange(event.target.value as WorkspaceLanguage)}
							>
								{SUPPORTED_WORKSPACE_LANGUAGES.map((lang) => (
									<option key={lang} value={lang}>
										{nativeLanguageLabelByLanguage[lang]}
									</option>
								))}
							</select>
						</dd>
					</div>
					<div className="pt-6 sm:flex">
						<dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6 flex items-center">
							{t("Company name")}
						</dt>
						<dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto items-center">
							<div>
								<Input.Input
									id="companyName"
									name="companyName"
									type="text"
									value={companyName}
									onChange={(event) => handleCompanyNameChange(event.target.value)}
									required
									disabled={false}
								/>
								<div className="mt-1 italic text-gray-400">
									{t("Used to name your recorder:")}{" "}
									<span className="text-gray-600">{getBotName(companyName, language)}</span>
								</div>
							</div>
						</dd>
					</div>
					<div className="pt-6 sm:flex">
						<dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6 flex items-center">
							{t("Recorder appearance")}
						</dt>
						<dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto items-center">
							<div>
								<div>
									{t(
										"Upload your company logo to customise the appearance of your Rippletide recorder within calls.",
									)}
								</div>
								<div>
									<div className="flex justify-between">
										<div>
											<img
												src={workspacesGateway.makeBotBackgroundImagePreviewURL(
													companyLogoImageId ?? undefined,
													language,
												)}
												alt="Recorder background preview"
											/>
										</div>
										<div className="flex items-start gap-2">
											<TrackingButton
												className="flex text-gray-900 hover:text-navy-500 ml-2 p-1 rounded-lg border border-[#4C4C4C80]"
												eventName="Edit company logo"
												onClick={() => setUploadModalOpen(true)}
												type="button"
											>
												<PencilSquareIcon className="h-5 w-5" />
											</TrackingButton>
											{companyLogoImageId && (
												<TrackingButton
													eventName="Delete company logo"
													onClick={() => handleCompanyLogoDelete()}
													type="button"
													className="flex text-red-600 hover:text-red-500 hover:bg-gray-50 p-1 rounded-lg border border-[#D35F3D]"
												>
													<TrashIcon className="h-5 w-5" />
												</TrackingButton>
											)}
										</div>
									</div>
								</div>
							</div>
						</dd>
					</div>
					<div className="pt-6 sm:flex">
						<dt className="font-medium text-gray-900 sm:w-48 sm:flex-none sm:pr-6 flex items-center">
							{t("MEDDIC scoring")}
						</dt>
						<dd className="mt-1 flex flex-col justify-between gap-x-6 sm:mt-0 sm:flex-auto gap-4 divide-y">
							<div className="flex justify-between items-center">
								<div className="flex flex-col">
									<p className="text-gray-900">
										{t("Display the MEDDIC score for calls recorded with Rippletide.")}
									</p>
									<p className="text-sm text-gray-400 italic">
										{t(
											"Note: This will only hide the score. The MEDDIC sections will still be visible.",
										)}
									</p>
								</div>
								<Input.Toggle value={isMeddicScoringEnabled} onChange={handleMeddicScoringToggle} />
							</div>

							<div>
								<p className="text-gray-900 my-2">{t("Choose which MEDDIC sections to display:")}</p>

								{meddicSections.map((section) => (
									<div key={section} className="flex items-center">
										<Input.CheckboxGroup
											id={section}
											mainText={section}
											checked={!meddicSectionsToHide.includes(section)}
											onChange={() => handleHideMeddicSectionToggle(section)}
										/>
									</div>
								))}
							</div>
						</dd>
					</div>
				</dl>

				{formStatus.status === "error" && (
					<div className="mt-4">
						<Alert
							title={t("An error occurred")}
							description={formStatus.error.message}
							variant="error"
							className="mt-4"
						/>
					</div>
				)}
				{formStatus.status === "success" && (
					<div className="mt-4">
						<Alert
							title={t("Your preferences were saved!")}
							description={undefined}
							variant="success"
							className="mt-4"
						/>
					</div>
				)}
			</div>
			<FileUploadModal
				title={t("Upload your company logo")}
				mimeType="image/png,image/jpeg"
				onFileUploaded={(file) => {
					setUploadModalOpen(false)
					handleCompanyLogoUploaded(file)
				}}
				onClose={() => setUploadModalOpen(false)}
				open={uploadModalOpen}
				comment={t(
					"Must be a PNG or JPEG file with a maximum size of 5 MB.\nThe logo will be displayed on a dark background.",
				)}
			/>
		</Card>
	)
}

const defaultBotName = "Rippletide"

function getBotName(companyName: string | undefined, language: WorkspaceLanguage): string {
	if (!companyName) {
		return defaultBotName
	}

	switch (language) {
		case "en":
			return `${companyName}'s Recorder`

		case "es":
			return `Grabadora de ${companyName}`

		case "fr":
			return `Enregistreur de ${companyName}`

		case "it":
			return `Registratore di ${companyName}`

		default:
			assertNever(language)
	}
}
