import clsx from "clsx"
import React, { useRef, useState } from "react"

import { Badge } from "./Badge.component"

type BadgeCollectionItem = {
	text: string
	color: string
	icon?: React.ReactNode
}

type BadgeCollectionProps = {
	items: BadgeCollectionItem[]
	variant?: "condensed" | "expanded"
	canExpand?: boolean
}

export function BadgeCollection({ items, variant, canExpand = true }: BadgeCollectionProps) {
	const [isHovered, setHovered] = useState(false)
	const hoverTimeoutRef = useRef<NodeJS.Timeout | null>(null)

	const handleMouseEnter = () => {
		if (!canExpand) return

		hoverTimeoutRef.current = setTimeout(() => {
			setHovered(true)
		}, 300)
	}

	const handleMouseLeave = () => {
		if (!canExpand) return

		if (hoverTimeoutRef.current) {
			clearTimeout(hoverTimeoutRef.current)
		}
		setHovered(false)
	}

	const isExpanded = variant === "expanded" || isHovered

	return (
		<div className="relative flex flex-wrap" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			{items.map((item, index) => {
				const isFirst = index === 0
				const offsetLeft = isFirst || isExpanded ? 0 : 42

				const style = {
					marginLeft: `-${offsetLeft}px`,
					zIndex: isFirst ? 10 : 10 - index,
					backgroundColor: item.color,
				}

				const label = item.icon ? (
					<span className="flex items-center gap-1">
						{item.text} {item.icon}
					</span>
				) : (
					item.text
				)

				return (
					<div key={item.text} className="px-0.5 py-0.5">
						<Badge
							label={label}
							variant="custom"
							color={item.color}
							style={style}
							className={clsx(
								"transition-all sticky truncate inline-block cursor-default",
								isFirst || isExpanded ? "" : "!justify-end w-[70px]",
							)}
						/>
					</div>
				)
			})}
		</div>
	)
}
