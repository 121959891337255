import { z } from "zod"

import type { TranslationKey } from "../../../app/contexts/language.context"

// todo: https://trello.com/c/bciAnIGO/163-fix-error-management
export const errorMessageTranslationKeyByApiErrorCode: Record<string, TranslationKey> = {
	NoMatchingCallError: "This call could not be found.",
	InvalidCrmIntegrationError:
		"A connection to the CRM could not be established. Please check your credentials and try again.",
}

export function ensureNotAnApiError(json: unknown) {
	const errorSchema = z.object({
		error: z.object({
			code: z.string(),
			message: z.string().nullish(),
		}),
	})

	const result = errorSchema.safeParse(json)
	if (result.success) {
		throw new ApiError(result.data.error.code)
	}
}

export class ApiError extends Error {
	constructor(
		public readonly code: keyof typeof errorMessageTranslationKeyByApiErrorCode,
		public readonly message = errorMessageTranslationKeyByApiErrorCode[code],
	) {
		super(code)
	}
}
