import { BookmarkIcon, ChatBubbleBottomCenterTextIcon, ShareIcon, XMarkIcon } from "@heroicons/react/24/outline"
import React, { useCallback } from "react"

import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useLanguage } from "../../../../contexts/language.context"

type ICallTimeRangeOptionsMenuProps = {
	onCancel: () => void
	onCreateCallHighlight: () => void
	onCreateExcerpt: () => void
	onShareCall: () => void
	canShareCall: boolean
	startTimeSeconds: number
	endTimeSeconds: number
	totalDurationSeconds: number
	onStartTimeChange: (newStartTime: number) => void
	onEndTimeChange: (newEndTime: number) => void
}

function secondsToTime(seconds: number) {
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)
	const secondsLeft = Math.floor(seconds % 60)
	return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${secondsLeft
		.toString()
		.padStart(2, "0")}`
}

export function CallTimeRangeOptionsMenu({
	onCancel,
	onCreateCallHighlight,
	onShareCall,
	canShareCall,
	onEndTimeChange,
	onStartTimeChange,
	totalDurationSeconds,
	endTimeSeconds,
	startTimeSeconds,
	onCreateExcerpt,
}: ICallTimeRangeOptionsMenuProps) {
	const { t } = useLanguage()
	const { workspace } = useAuthenticatedSession()
	const isLibraryEnabled = workspace.isPlanFeatureEnabled("library")

	const handleStartTimeChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const startTime = event.target.value
			const [hours, minutes, seconds] = startTime.split(":").map(Number)
			const newStartTime = hours * 3600 + minutes * 60 + seconds
			onStartTimeChange(newStartTime)
		},
		[onStartTimeChange],
	)

	const handleEndTimeChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const endTime = event.target.value
			const [hours, minutes, seconds] = endTime.split(":").map(Number)
			const newEndTime = hours * 3600 + minutes * 60 + seconds
			onEndTimeChange(newEndTime)
		},
		[onEndTimeChange],
	)

	return (
		<div className="bg-white rounded-md shadow-md p-2 mt-6 border border-gray-200">
			<div className="flex flex-col gap-2 items-center justify-between">
				<div className="flex gap-2 items-center">
					<label className="text-xs text-gray-900">{t("from")}</label>
					<input
						type="time"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-xs sm:leading-6"
						value={secondsToTime(startTimeSeconds)}
						onChange={handleStartTimeChange}
						step={1}
						max={secondsToTime(endTimeSeconds)}
						min="00:00:00"
					/>
					<label className="text-xs text-gray-900">{t("to")}</label>
					<input
						type="time"
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 text-xs sm:leading-6"
						value={secondsToTime(endTimeSeconds)}
						onChange={handleEndTimeChange}
						step={1}
						max={secondsToTime(totalDurationSeconds)}
						min="00:00:00"
					/>

					<TrackingButton
						eventName={"share call cancel clicked"}
						className="absolute right-4 -mt-4"
						onClick={onCancel}
					>
						<XMarkIcon className="h-4 w-4 text-red-500 group-hover:text-red-400" />
					</TrackingButton>
				</div>

				<div className="flex gap-2 flex-row">
					<TrackingButton
						eventName="create call highlight clicked"
						className="flex items-center rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-60 disabled:cursor-not-allowed"
						disabled={!isLibraryEnabled}
						onClick={onCreateCallHighlight}
					>
						<BookmarkIcon className="h-4 w-4 mr-1" />
						{t("Create highlight")}
					</TrackingButton>

					<TrackingButton
						eventName="create call highlight clicked"
						className="flex items-center rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-60 disabled:cursor-not-allowed"
						onClick={onCreateExcerpt}
					>
						<ChatBubbleBottomCenterTextIcon className="h-4 w-4 mr-1" />
						{t("Create tag")}
					</TrackingButton>

					{canShareCall && (
						<TrackingButton
							eventName="share call clicked"
							className="flex items-center rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							onClick={onShareCall}
						>
							<ShareIcon className="h-4 w-4 mr-1" />
							{t("Share snippet")}
						</TrackingButton>
					)}
				</div>
			</div>
		</div>
	)
}
