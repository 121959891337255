import { PlusIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import React from "react"

import type { CallHighlight, LibraryFolder } from "../../../../../../core/domain/Library.entity"
import { Alert } from "../../../../../components/design-system/Alert.component"
import { TrackingButton } from "../../../../../components/design-system/TrackingButton.component"
import { EmptyState } from "../../../../../components/shared/EmptyState.component"
import { useLanguage } from "../../../../../contexts/language.context"
import { useLibraryWithHighlights } from "../../../../../hooks/useLibraryWithHighlights"
import { makeRecentCallsPath } from "../../../../../router/Router"
import { COACHING_SESSIONS_FOLDER_ID } from "../../../../Library/components/CoachingSession.util"
import { CallHighlightCardContents } from "../../../../Library/components/CurrentLibraryFolder/CurrentLibraryFolderHighlightsGrid.component"
import { LibraryFoldersBreadcrumb } from "../../../../Library/components/LibraryFoldersBreadcrumb.component"
import { SideLibraryFoldersNavigation } from "../../../../Library/components/SideLibraryFoldersNavigation/SideLibraryFoldersNavigation.component"
import { HighlightFloatingVideoPreview } from "../../components/HighlightFloatingVideoPreview.component"
import { ContentList } from "./ContentList.component"

type ICurrentLibraryFolderHighlightsListProps = {
	highlights: CallHighlight[]
	currentHighlightIndex: number
	onAddHighlight: (highlight: CallHighlight) => void
	onSelectHighlight: (index: number) => void
	selectedHighlights: CallHighlight[]
}

function CurrentLibraryFolderHighlightsList({
	currentHighlightIndex,
	highlights,
	onSelectHighlight,
	onAddHighlight,
	selectedHighlights,
}: ICurrentLibraryFolderHighlightsListProps) {
	return (
		<div className="grid grid-cols-1 gap-4">
			{highlights.map((highlight, i) => {
				const isSelected = i === currentHighlightIndex
				const isAddDisabled = selectedHighlights.some((x) => x.id === highlight.id)
				return (
					<TrackingButton
						key={highlight.id}
						className={clsx(
							"group relative flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-6 py-4",
							isSelected && "!bg-indigo-50 !border-indigo-300",
							!isSelected && "opacity-80",
						)}
						eventName="Highlight clicked in grid"
						onClick={() => onSelectHighlight(i)}
					>
						<CallHighlightCardContents
							key={highlight.id}
							contents={{
								highlight,
								isSelected,
							}}
						/>
						<div className="flex-shrink-0 mb-auto mt-2 flex-col items-end flex justify-between h-full">
							<TrackingButton
								onClick={(e) => {
									e.stopPropagation()
									onAddHighlight(highlight)
								}}
								eventName="Call highlight: add button clicked"
								disabled={isAddDisabled}
								className={clsx(isAddDisabled && "opacity-50")}
							>
								<PlusIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
							</TrackingButton>
						</div>
					</TrackingButton>
				)
			})}
		</div>
	)
}

type EditHighlightContentProps = {
	onSaveHighlights: (highlights: CallHighlight[]) => void
	alreadySelectedHighlights: CallHighlight[]
}

export function EditHighlightContent({ onSaveHighlights, alreadySelectedHighlights }: EditHighlightContentProps) {
	const [currentFolder, setCurrentFolder] = React.useState<LibraryFolder | undefined>()
	const { library, currentFolderHighlights } = useLibraryWithHighlights(currentFolder?.id)
	const [currentHighlightIndex, setCurrentHighlightIndex] = React.useState<number>(0)
	const [addedHighlights, setAddedHighlights] = React.useState<CallHighlight[]>([])
	const { t } = useLanguage()

	React.useEffect(() => {
		if (library !== "loading" && !currentFolder) {
			setCurrentFolder(library.workspaceRoot)
		}
	}, [currentFolder, library])

	React.useEffect(() => {
		setAddedHighlights((prevAdded) => {
			const existingHighlightIds = prevAdded.map((x) => x.id)
			const newHighlights =
				alreadySelectedHighlights?.filter((highlight) => !existingHighlightIds.includes(highlight.id)) ?? []
			return [...prevAdded, ...newHighlights]
		})
	}, [alreadySelectedHighlights])

	const handleFolderClick = React.useCallback((folder: LibraryFolder) => {
		setCurrentFolder(folder)
	}, [])

	const handleAddHighlight = React.useCallback(
		(highlight: CallHighlight) => {
			if (addedHighlights.some((x) => x.id === highlight.id)) return

			setAddedHighlights((prevAdded) => [...prevAdded, highlight])
		},
		[addedHighlights],
	)

	const handleRemoveHighlight = React.useCallback((highlight: CallHighlight) => {
		setAddedHighlights((prevAdded) => prevAdded.filter((x) => x.id !== highlight.id))
	}, [])

	const handleReorderAddedHighlights = React.useCallback((highlights: CallHighlight[]) => {
		setAddedHighlights(highlights)
	}, [])

	const handleSaveClick = React.useCallback(() => {
		onSaveHighlights(addedHighlights)
	}, [addedHighlights, onSaveHighlights])

	if (library !== "loading" && library.getTotalCallHighlightCount() === 0) {
		return (
			<div className="max-w-xl m-auto">
				<EmptyState
					title={t("Your library is empty")}
					description={t(
						"Create library highlights from a call page by selecting the part of the call you would like to save in your library. These highlights can then be used to create your training programs.",
					)}
					cta={{
						analyticsEventName: "Empty library CTA clicked",
						type: "link",
						to: makeRecentCallsPath(),
						label: t("View my calls"),
					}}
				/>
			</div>
		)
	}

	return (
		<div className="h-full flex-1 flex flex-col">
			<div className="flex justify-between items-center py-4">
				<h2 className="text-md font-medium text-gray-900">
					{t("Select the highlights from your library that you would like to include in your training")}
				</h2>
			</div>
			<div className="flex flex-row mt-6 flex-1 justify-between">
				<SideLibraryFoldersNavigation
					library={library}
					disabled={false}
					onFolderClick={handleFolderClick}
					className="xl:!w-72"
					sideNavigationFolderClassName="w-60 [&>button]:truncate"
					currentFolderId={currentFolder?.id}
					showCreateTrainingProgramButton={false}
				/>

				<div className="grid grid-cols-2 flex-1">
					<div className={clsx("flex-1 min-w-0 xl:border-r xl:border-gray-200 h-[80vh] overflow-y-auto")}>
						{currentFolder && library !== "loading" && currentFolderHighlights !== "loading" && (
							<div className="m-6 space-y-4">
								<LibraryFoldersBreadcrumb
									currentFolder={currentFolder}
									onFolderClick={handleFolderClick}
									library={library}
									compact
								/>
								{currentFolderHighlights !== "no-folder-selected" &&
									currentFolder?.id !== COACHING_SESSIONS_FOLDER_ID && (
										<>
											{currentFolderHighlights.highlights[currentHighlightIndex] && (
												<HighlightFloatingVideoPreview
													highlight={
														currentFolderHighlights.highlights[currentHighlightIndex]
													}
												/>
											)}
											<CurrentLibraryFolderHighlightsList
												currentHighlightIndex={currentHighlightIndex}
												highlights={currentFolderHighlights.highlights}
												onAddHighlight={handleAddHighlight}
												onSelectHighlight={setCurrentHighlightIndex}
												selectedHighlights={addedHighlights}
											/>
											{currentFolderHighlights.highlights.length === 0 && (
												<Alert
													description={t(
														currentFolder.id === library.workspaceRoot.id
															? "Find highlights by selecting a folder on the left"
															: "This folder is empty",
													)}
													variant="info"
												/>
											)}
										</>
									)}
							</div>
						)}
					</div>

					<div className="flex-1 ml-6 flex flex-col h-[80vh] overflow-y-auto">
						<div className="flex items-baseline gap-2 justify-between sticky top-0 bg-white">
							<h2 className="text-xl font-bold mb-4">{t("Your training content")}</h2>
							<TrackingButton
								type="button"
								onClick={handleSaveClick}
								className="mr-4 flex items-center justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-500 disabled:ring-gray-200"
								eventName="Edit highlight content: save highlights"
								disabled={addedHighlights.length === 0}
							>
								{t("Add highlights")}
							</TrackingButton>
						</div>

						<ContentList
							highlights={addedHighlights}
							onRemoveHighlight={handleRemoveHighlight}
							onReorderHighlights={handleReorderAddedHighlights}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
