import { Skeleton } from "../../design-system/Skeleton.component"

export function _CallRowSkeleton() {
	return (
		<tr className="py-4 px-2">
			{[...Array(6).keys()].map((_, index) => (
				<td key={index} className="px-3 py-6">
					<Skeleton size="M" />
				</td>
			))}
		</tr>
	)
}
