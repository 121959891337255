import type { Relationship } from "../../core/application/gateways/relationships.gateway"
import type { TFunction } from "../contexts/language.context"

function removeTld(domain: string): string {
	return domain.replace(/\.[a-z]+$/, "")
}

function getRelationshipDisplayName(
	relationship: Pick<Relationship, "account" | "contacts" | "accountDomains">,
): string | null {
	const relationshipAccountName = relationship.account?.name
	if (relationshipAccountName) {
		return relationshipAccountName
	}

	if (relationship.accountDomains?.length) {
		return removeTld(relationship.accountDomains[0])
	}

	const firstContactWithName = relationship.contacts?.find((x) => x.firstName && x.lastName)
	const firstContactWithEmail = relationship.contacts?.find((x) => x.email)
	if (firstContactWithName) {
		return `${firstContactWithName.firstName} ${firstContactWithName.lastName}`
	}

	if (firstContactWithEmail) {
		return firstContactWithEmail.email
	}

	return null
}

export function isUnknownRelationship(
	relationship: Pick<Relationship, "account" | "contacts" | "accountDomains">,
): boolean {
	const displayName = getRelationshipDisplayName(relationship)
	return displayName === null
}

export function getRelationshipAccountDisplayName(
	relationship: Pick<Relationship, "account" | "contacts" | "accountDomains">,
	t: TFunction,
): string {
	const displayName = getRelationshipDisplayName(relationship)
	if (displayName) {
		return displayName
	}

	return t("Unknown")
}
