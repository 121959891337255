import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import assertNever from "assert-never"
import React from "react"

import type {
	Relationship,
	SalesMotionBlockerSlug,
} from "../../../../../core/application/gateways/relationships.gateway"
import { type TFunction, useLanguage } from "../../../../contexts/language.context"
import { Alert } from "../../../design-system/Alert.component"

type RelationshipRisksPanelProps = {
	relationship: Relationship
}

export function RelationshipRisksPanel({ relationship }: RelationshipRisksPanelProps) {
	const { t } = useLanguage()
	const blockers = relationship.salesMotionBlockers

	if (!blockers?.length) {
		return (
			<div className="flex flex-col gap-2 mt-4">
				<Alert description={t("No risks have been identified for this deal")} variant="success" />
			</div>
		)
	}

	return (
		<div className="flex flex-col gap-2 mt-4">
			{blockers.map((blocker, blockerIndex) => {
				if (blocker === "not-moved-from-stage") {
					return (
						<SalesMotionBlockerCard
							id={"not-moved-from-stage" + blockerIndex}
							title={t("This deal has been stuck in the current stage")}
							rationale={t(
								"Reconnect with the prospect to reignite interest and drive the deal forward.",
							)}
							actions={[t("Log an update in the CRM.")]}
						/>
					)
				}

				if (blocker === "close-date-in-past") {
					return (
						<SalesMotionBlockerCard
							id={"close-date-in-past" + blockerIndex}
							title={t("The closing date has passed")}
							rationale={t(
								"The closing date for this deal is in the past and needs immediate attention. Consider whether the deal has gone cold and if it's worth continuing to pursue.",
							)}
							actions={[
								t("Update the closing date in the CRM."),
								t("Contact the prospect to close the deal quickly."),
								t("Evaluate if the deal is worth pursuing or deprioritise it."),
							]}
						/>
					)
				}

				return (
					<SalesMotionBlockerCard
						id={blocker.id}
						title={getTitleForSlug(blocker.slug, t)}
						rationale={blocker.rationale}
						actions={blocker.actions}
					/>
				)
			})}
		</div>
	)
}

function getTitleForSlug(slug: SalesMotionBlockerSlug, t: TFunction): string {
	switch (slug) {
		case "INTERNAL_VALIDATION_REQUIRED":
			return t("Internal validation required")
		case "CLARIFICATIONS_REQUIRED":
			return t("Clarifications required")
		case "NO_DECISION_MAKER_INVOLVED":
			return t("No Decision Maker involved")
		case "NO_NEXT_STEP_PLANNED":
			return t("No next step planned")
		case "BLOCKING_TASK_NOT_ADDRESSED":
			return t("Blocking task not addressed")
		default:
			assertNever(slug)
			return slug
	}
}

type SalesMotionBlockerCardProps = {
	id: string
	title: string
	rationale: string
	actions: string[]
}

function SalesMotionBlockerCard({ actions, id, rationale, title }: SalesMotionBlockerCardProps) {
	return (
		<React.Fragment key={id}>
			<div className="flex items-center rounded-xl bg-red-50 p-2 border-red-200 border">
				<ExclamationTriangleIcon className="h-5 w-5 text-red-400" />
				<span className="ml-2 font-medium text-sm">{title}</span>
			</div>
			<div className="px-1">
				<div className="px-1 pt-2 -mt-4 overflow-hidden rounded-b-xl border-gray-100 border">
					<div className="p-2">
						<p className="text-sm italic text-gray-500">{rationale}</p>
						<ul className="list-disc list-inside mt-2 text-sm text-gray-700">
							{actions.map((action, index) => (
								<li key={action}>
									<span className="font-medium">Action {index + 1}:</span> {action}
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}
