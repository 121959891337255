import clsx from "clsx"
import { useEffect } from "react"

import type { Call } from "../../../../../core/domain/Call.entity"
import type { CallHighlight } from "../../../../../core/domain/Library.entity"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingExternalHref } from "../../../../components/design-system/TrackingExternalHref.component"
import {
	CallVideoPlayerProvider,
	type ICallVideoPlayerProviderProps,
	useCallVideoPlayer,
} from "../../../../contexts/callVideoPlayer.context"
import { type TranslationKey, useLanguage } from "../../../../contexts/language.context"
import { useCall } from "../../../../hooks/useCall"
import { makeCallPath } from "../../../../router/Router"
import type { CallTimeRange } from "../../../../utils/callSharing"
import { Call_MainPanel } from "../../../Call/components/_MainPanel/_MainPanel.component"

export type CurrentLibraryFolderPlayerPanelProps = {
	callHighlight: CallHighlight
	highlightsOfCurrentCall: CallHighlight[]

	canGoToPreviousHighlight: boolean
	canGoToNextHighlight: boolean

	goToPreviousHighlightLabel: TranslationKey
	goToNextHighlightLabel: TranslationKey

	goToPreviousHighlight: () => void
	goToNextHighlight: () => void
}

export function CurrentLibraryFolderPlayerPanel({
	highlightsOfCurrentCall,
	callHighlight,
	canGoToPreviousHighlight,
	canGoToNextHighlight,
	goToPreviousHighlightLabel,
	goToNextHighlightLabel,
	goToPreviousHighlight,
	goToNextHighlight,
}: CurrentLibraryFolderPlayerPanelProps) {
	const { call, error, isLoading } = useCall(callHighlight.callId)

	if (isLoading) {
		return <p>Loading...</p>
	}
	if (error) {
		return <p>Error: {error.message}</p>
	}
	if (!call) {
		return <p>Call not found</p>
	}

	const allowedTimeRanges = highlightsOfCurrentCall.map(
		(highlight): CallTimeRange => ({
			startSec: Math.round(highlight.startsAtMs / 1000),
			endSec: Math.round(highlight.endsAtMs / 1000),
		}),
	)

	const progressHandler: ICallVideoPlayerProviderProps["progressHandler"] = (progressState, context) => {
		const { handlePause, handlePlay, seekPlayerTo } = context

		const isBeforeCurrentHighlight = progressState.playedSeconds < Math.floor(callHighlight.startsAtMs / 1000)
		const isAftercurrentHighlight = progressState.playedSeconds > Math.ceil(callHighlight.endsAtMs / 1000)

		if (isBeforeCurrentHighlight) {
			console.log("Current time is before current highlight, seeking to highlight")
			seekPlayerTo(callHighlight.startsAtMs / 1000)
			handlePlay()
		} else if (isAftercurrentHighlight) {
			handlePause()
			goToNextHighlight()
		}
	}

	return (
		<div className="">
			<CallVideoPlayerProvider
				call={call}
				allowedTimeRanges={allowedTimeRanges}
				progressHandler={progressHandler}
			>
				<p>
					<TrackingExternalHref
						eventName={"Open call from highlight library"}
						href={makeCallPath(callHighlight.callId, undefined, {
							startVideoAtSeconds: Math.round(callHighlight.startsAtMs / 1000),
						})}
						className="text-center text-gray-500 mb-2 text-ellipsis overflow-hidden whitespace-nowrap"
						displayExternalIcon={true}
					>
						{/* ugly code to avoid overflow */}
						{callHighlight.callName.slice(0, 80)}
					</TrackingExternalHref>
				</p>
				<PlayerWrapper
					call={call}
					highlightsOfCurrentCall={highlightsOfCurrentCall}
					currentHighlight={callHighlight}
					canGoToPreviousHighlight={canGoToPreviousHighlight}
					canGoToNextHighlight={canGoToNextHighlight}
					goToPreviousHighlightLabel={goToPreviousHighlightLabel}
					goToNextHighlightLabel={goToNextHighlightLabel}
					goToPreviousHighlight={goToPreviousHighlight}
					goToNextHighlight={goToNextHighlight}
				/>
			</CallVideoPlayerProvider>
		</div>
	)
}

type PlayerWrapperProps = {
	call: Call
	highlightsOfCurrentCall: CallHighlight[]
	currentHighlight: CallHighlight

	canGoToPreviousHighlight: boolean
	canGoToNextHighlight: boolean

	goToPreviousHighlightLabel: TranslationKey
	goToNextHighlightLabel: TranslationKey

	goToPreviousHighlight: () => void
	goToNextHighlight: () => void
}

function PlayerWrapper({
	call,
	highlightsOfCurrentCall,
	currentHighlight,
	canGoToPreviousHighlight,
	canGoToNextHighlight,
	goToPreviousHighlightLabel,
	goToNextHighlightLabel,
	goToPreviousHighlight,
	goToNextHighlight,
}: PlayerWrapperProps) {
	const { seekPlayerTo } = useCallVideoPlayer()

	useEffect(() => {
		console.log("Seeking player to", currentHighlight.startsAtMs / 1000, "seconds")
		seekPlayerTo(Math.round(currentHighlight.startsAtMs / 1000), undefined, false) // do not autoplay
		//eslint-disable-next-line
	}, [currentHighlight]) // @val help, when I add seekPlayerTo it seems to be causing an infinite loop

	return (
		<PlayerComponent
			call={call}
			highlightsOfCurrentCall={highlightsOfCurrentCall}
			canGoToPreviousHighlight={canGoToPreviousHighlight}
			canGoToNextHighlight={canGoToNextHighlight}
			goToPreviousHighlightLabel={goToPreviousHighlightLabel}
			goToNextHighlightLabel={goToNextHighlightLabel}
			goToPreviousHighlight={goToPreviousHighlight}
			goToNextHighlight={goToNextHighlight}
		/>
	)
}

type PlayerComponentProps = {
	call: Call
	highlightsOfCurrentCall: CallHighlight[]

	canGoToPreviousHighlight: boolean
	canGoToNextHighlight: boolean

	goToPreviousHighlightLabel: TranslationKey
	goToNextHighlightLabel: TranslationKey

	goToPreviousHighlight: () => void
	goToNextHighlight: () => void
}

function PlayerComponent({
	call,
	highlightsOfCurrentCall,
	canGoToPreviousHighlight,
	canGoToNextHighlight,
	goToPreviousHighlightLabel,
	goToNextHighlightLabel,
	goToPreviousHighlight,
	goToNextHighlight,
}: PlayerComponentProps) {
	const { t } = useLanguage()

	return (
		<Call_MainPanel
			call={call}
			highlights={highlightsOfCurrentCall}
			timeline={true /* useful to enable when debugging */}
			timelineVerticalSpacing={2}
			childrenBetweenPlayerAndTimeline={
				<div className="flex justify-between mt-2 mb-2">
					<TrackingButton
						onClick={goToPreviousHighlight}
						className={clsx(
							"flex justify-center rounded-full bg-navy-500 px-5 min-w-[124px] py-1.5 text-sm font-semibold leading-6 text-white shadow-sm",
							"bg-gradient-to-r hover:from-navy-500 hover:to-pink transition duration-500 ease-in-out",
							"disabled:hover:bg-none disabled:cursor-not-allowed  disabled:text-gray-500 disabled:bg-gray-300 disabled:ring-gray-200",
						)}
						eventName={"Previous"}
						disabled={!canGoToPreviousHighlight}
					>
						{t(goToPreviousHighlightLabel)}
					</TrackingButton>
					<TrackingButton
						onClick={goToNextHighlight}
						className={clsx(
							"flex justify-center rounded-full bg-navy-500 px-5 min-w-[124px] py-1.5 text-sm font-semibold leading-6 text-white shadow-sm",
							"bg-gradient-to-r hover:from-navy-500 hover:to-pink transition duration-500 ease-in-out",
							"disabled:hover:bg-none disabled:cursor-not-allowed  disabled:text-gray-500 disabled:bg-gray-300 disabled:ring-gray-200",
						)}
						eventName={"Next"}
						disabled={!canGoToNextHighlight}
					>
						{t(goToNextHighlightLabel)}
					</TrackingButton>
				</div>
			}
		/>
	)
}
