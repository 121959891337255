import clsx from "clsx"
import React from "react"

type IPlanCardProps = React.PropsWithChildren<{
	title: React.ReactNode
	subtitle: React.ReactNode
	subtitleDetails?: React.ReactNode
	state?: "highlight" | "error"
}>

export function PlanCard({ subtitleDetails, subtitle, title, children, state }: IPlanCardProps) {
	return (
		<div
			className={clsx(
				"lg:min-w-0 lg:flex-1 border border-gray-200 rounded-xl py-6 px-5 flex-col flex justify-between",
				state === "highlight" && "bg-gradient-to-bl from-pink to-salmon",
				state === "error" && "bg-red-200",
			)}
		>
			<div className="flex-col flex">
				<span
					className={clsx(
						"font-medium",
						state === "error" ? "text-red-500" : "text-navy-700 text-opacity-50",
					)}
				>
					{title}
				</span>
				<span className={clsx("mt-6 font-bold text-2xl", state === "error" ? "text-red-500" : "text-navy-700")}>
					{subtitle}
				</span>
				{subtitleDetails && (
					<span
						className={clsx(
							"text-opacity-50 mt-1 font-medium",
							state === "error" ? "text-red-500" : "text-navy-700",
						)}
					>
						{subtitleDetails}
					</span>
				)}
			</div>

			<div className={clsx("flex flex-col", state === "error" ? "text-red-500" : "text-navy-700")}>
				{children}
			</div>
		</div>
	)
}
