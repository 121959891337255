import React from "react"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { useMount } from "react-use"

import VerticalStepper, { type Step } from "../../components/shared/VerticalStepper.component"
import { useAnalytics } from "../../contexts/analytics.context"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { type TranslationKey, useLanguage } from "../../contexts/language.context"
import {
	makeDashboardPath,
	makeOnboardingPath,
	type OnboardingPathParams,
	type OnboardingStep,
} from "../../router/Router"
import { AccountSetup } from "./steps/AccountSetup.component"
import { CalendarSyncStep } from "./steps/CalendarSyncStep.component"
import { CrmSyncStep } from "./steps/CrmSyncStep.component"
import { InviteTeamStep } from "./steps/InviteTeamStep.component"
import { ReadyStep } from "./steps/ReadyStep.component"

const customStepClasses = {
	stepIconComplete: "bg-green-400 group-hover:bg-green-500", // Green icon for completed steps
	stepIconCurrent: "border-blue-300", // Light blue border for the current step
	stepIconUpcoming: "border-gray-400 group-hover:border-gray-500", // Gray border for upcoming steps
	stepLine: "bg-gray-500", // Gray line connecting steps to complement the navy background
	stepTextComplete: "text-green-400", // Green text for completed step names
	stepTextCurrent: "text-blue-300", // Light blue text for the current step name
	stepTextUpcoming: "text-gray-300", // Lighter gray for upcoming steps to ensure readability on navy
	descriptionText: "text-gray-400", // Gray text for descriptions to ensure readability
}

const stepDescriptions: Record<OnboardingStep, TranslationKey> = {
	"account-setup": "Set up your account details.",
	"calendar-sync": "Sync your calendar.",
	"crm-integration": "Integrate your CRM.",
	"invite-team": "Invite your team members.",
	ready: "You're all set!",
}

const stepTitles: Record<OnboardingStep, TranslationKey> = {
	"account-setup": "Account Setup",
	"calendar-sync": "Calendar Sync",
	"crm-integration": "CRM Integration",
	"invite-team": "Invite Team",
	ready: "Ready",
}

const workspaceOnboardingSteps: OnboardingStep[] = [
	"account-setup",
	"calendar-sync",
	"crm-integration",
	"invite-team",
	"ready",
]
const userOnboardingSteps: OnboardingStep[] = ["calendar-sync", "ready"]

export function OnboardingPage() {
	const { trackPageView } = useAnalytics()
	const { step, type: onboardingType } = useParams<OnboardingPathParams>()
	const navigate = useNavigate()
	const { refetchMe } = useAuthenticatedSession()
	const { t } = useLanguage()

	useMount(() => {
		trackPageView("onboarding", window.location.pathname)
	})

	const steps = onboardingType === "workspace" ? workspaceOnboardingSteps : userOnboardingSteps

	if (!onboardingType) {
		return null // not actually possible
	}

	if (!step) {
		return <Navigate to={makeOnboardingPath(onboardingType, steps[0])} />
	}

	const currentStep = step
	const onStepChange = (step: OnboardingStep) => {
		refetchMe()
		navigate(makeOnboardingPath(onboardingType, step))
	}

	const handleCompleteStep = () => {
		const currentStepIndex = steps.indexOf(currentStep)
		if (currentStepIndex === steps.length - 1) {
			return navigate(makeDashboardPath())
		}

		onStepChange(steps[currentStepIndex + 1])
	}

	const stepComponents: Record<OnboardingStep, React.ReactNode> = {
		"account-setup": <AccountSetup onComplete={handleCompleteStep} />,
		"calendar-sync": <CalendarSyncStep onComplete={handleCompleteStep} />,
		"crm-integration": <CrmSyncStep onComplete={handleCompleteStep} />,
		"invite-team": <InviteTeamStep onComplete={handleCompleteStep} />,
		ready: <ReadyStep onReady={handleCompleteStep} />,
	}

	const currentStepComponent = stepComponents[currentStep]

	const verticalStepperSteps: Step[] = steps.map((step, index) => ({
		name: t(stepTitles[step]),
		description: t(stepDescriptions[step]),
		linkTo: makeOnboardingPath(onboardingType, step),
		status:
			index < steps.indexOf(currentStep)
				? "complete"
				: index === steps.indexOf(currentStep)
				? "current"
				: "upcoming",
	}))

	return (
		<div className="flex min-h-screen flex-1 flex-row">
			<div className="flex-[2] bg-navy-500 m-2 rounded-md shadow-sm p-4 hidden sm:block">
				<img
					className="h-12 w-auto mb-10"
					src={process.env.PUBLIC_URL + "/rippletide_logo_text_transparent_bg.svg"}
					alt="Rippletide's logo"
				/>
				<VerticalStepper steps={verticalStepperSteps} stepClassNames={customStepClasses} />
			</div>
			<div className="flex-[5] flex flex-col items-center justify-center bg-gray-50">
				<div className="relative md:w-[80%] lg:w-[70%] w-full p-4 md:p-0">
					<img
						className="h-20 w-full mb-10 absolute -top-[5rem] left-0 right-0"
						src={process.env.PUBLIC_URL + "/rippletide_logo_navy_text_bg.svg"}
						alt="Rippletide's logo"
					/>
					{currentStepComponent}
					<nav aria-label="Progress" className="flex items-center justify-center mt-4">
						<ol role="list" className="ml-8 flex items-center space-x-5">
							{verticalStepperSteps.map((step) => (
								<li key={step.name}>
									{step.status === "complete" ? (
										<div className="block h-2.5 w-2.5 rounded-full bg-navy-500">
											<span className="sr-only">{step.name}</span>
										</div>
									) : step.status === "current" ? (
										<div aria-current="step" className="relative flex items-center justify-center">
											<span aria-hidden="true" className="absolute flex h-5 w-5 p-px">
												<span className="h-full w-full rounded-full bg-navy-300" />
											</span>
											<span
												aria-hidden="true"
												className="relative block h-2.5 w-2.5 rounded-full bg-navy-500"
											/>
											<span className="sr-only">{step.name}</span>
										</div>
									) : (
										<div className="block h-2.5 w-2.5 rounded-full bg-gray-200">
											<span className="sr-only">{step.name}</span>
										</div>
									)}
								</li>
							))}
						</ol>
					</nav>
				</div>
			</div>
		</div>
	)
}
