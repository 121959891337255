import type {
	DisconnectedCalendar,
	NotFoundCalendar,
} from "../../../../../core/application/gateways/calendar-events.gateway"
import { CalendarSyncButtons } from "../../../../components/shared/CalendarSyncButtons.component"
import { useLanguage } from "../../../../contexts/language.context"

export type CalendarEmptyStateProps = {
	calendar: NotFoundCalendar | DisconnectedCalendar
}

function ListItem({ title, subtitle }: { title: string; subtitle: string }) {
	return (
		<li className="py-1">
			<p className="font-semibold">{title}</p>
			<p className="text-gray-500 text-sm">{subtitle}</p>
		</li>
	)
}

export function CalendarEmptyState({ calendar }: CalendarEmptyStateProps) {
	const { t } = useLanguage()

	return (
		<div className="text-left">
			<h3 className="mt-2 font-bold text-gray-900 mb-1 text-center">
				{t("Kickstart your efficiency: Sync your calendar now 🚀")}
			</h3>

			<div className="my-4 flex items-center justify-center gap-6 max-w-md m-auto">
				<CalendarSyncButtons
					calendar={calendar}
					reconnect={calendar.status === "disconnected" ? calendar.platform : undefined}
				/>
			</div>

			<p className="mb-2 text-gray-500 text-sm">
				{t(
					"Start with a crucial step — sync your calendar to unlock Rippletide's full capabilities. Without syncing, you won't be able to access the features that empower your sales process:",
				)}
			</p>
			<ul>
				<ListItem
					title={t("📅 Upcoming meeting prep")}
					subtitle={t("Get personalized briefs to enter each meeting informed and ready to engage.")}
				/>
				<ListItem
					title={t("🎙️️ Enhanced call recording & analysis")}
					subtitle={t("Capture every interaction and glean deep insights from each conversation.")}
				/>
				<ListItem
					title={t("💡 Actionable next steps")}
					subtitle={t("Receive customized suggestions for follow-up actions to keep the momentum going.")}
				/>
				<ListItem
					title={t("🤝 Client intelligence")}
					subtitle={t("Gain valuable insights into your clients' behavior, interests, and needs.")}
				/>
				<ListItem
					title={t("🔗 Hands-Free CRM integration")}
					subtitle={t("Streamline your sales journey with automated CRM updates.")}
				/>
			</ul>
		</div>
	)
}
