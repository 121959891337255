import clsx from "clsx"
import React from "react"

import { Tooltip } from "./Tooltip.component"

type InitialsAvatarProps = {
	value: string
	className?: string
	title?: string
}

export function TextAvatar({ value, className, title }: InitialsAvatarProps) {
	return (
		<Tooltip content={title}>
			<span
				className={clsx(
					"inline-flex h-5 w-5 items-center justify-center rounded-full bg-gray-500 cursor-default",
					className,
				)}
			>
				<span className="text-2xs font-medium leading-none text-white">{value}</span>
			</span>
		</Tooltip>
	)
}
