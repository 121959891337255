import "chartjs-plugin-datalabels"
import "chartjs-plugin-annotation"

import {
	CategoryScale,
	Chart as ChartJS,
	type ChartData,
	type ChartOptions,
	Filler,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import zoomPlugin from "chartjs-plugin-zoom"
import React from "react"
import { Bar } from "react-chartjs-2"

import type { GetDealsReportSuccessResponse } from "../../../../../../core/application/gateways/deals.gateway/schemas/report"
import { SectionCard } from "../../../../../components/shared/GraphSection/SectionCard.component"
import { useLanguage } from "../../../../../contexts/language.context"

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	ChartDataLabels,
	zoomPlugin,
)

type ISalesMotionPipelineConversionGraphProps = {
	dealsReport: GetDealsReportSuccessResponse
}

function makeConversionDataForGroup(groupData: GetDealsReportSuccessResponse["dealGroups"][0]) {
	return groupData.dealStages.map((stage) => {
		return stage.totalDealsReachedStage
	})
}

function makeDurationDataForGroup(groupData: GetDealsReportSuccessResponse["dealGroups"][0]) {
	return groupData.dealStages.map((stage) => {
		const averageDuration = Math.round(stage.averageDurationDaysPerDeal)
		return averageDuration
	})
}

export function SalesMotionPipelineGraphs({ dealsReport }: ISalesMotionPipelineConversionGraphProps) {
	const { t } = useLanguage()
	const chartOptions: ChartOptions<"bar"> = React.useMemo(
		() => ({
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				legend: {
					position: "bottom",
				},
				datalabels: {
					align: "center",
					color: "#000000",
				},
			},
		}),
		[],
	)

	const conversionData: ChartData<"bar"> = React.useMemo(() => {
		const groupA = dealsReport.dealGroups.find((group) => group.identifier === "a")
		const groupB = dealsReport.dealGroups.find((group) => group.identifier === "b")
		if (!groupA || !groupB) return { labels: [], datasets: [] }

		const allStageNames = groupA.dealStages
			.sort((a, b) => a.order - b.order)
			.filter((x) => !x.isLostStage)
			.map((stage) => stage.stage)

		return {
			labels: allStageNames,
			datasets: [
				{
					label: "Group A",
					data: makeConversionDataForGroup(groupA),
					backgroundColor: "rgba(69,69,128,0.75)",
				},
				{
					label: "Group B",
					data: makeConversionDataForGroup(groupB),
					backgroundColor: "#EA97AF",
				},
			],
		}
	}, [dealsReport])

	const durationData: ChartData<"bar"> = React.useMemo(() => {
		const groupA = dealsReport.dealGroups.find((group) => group.identifier === "a")
		const groupB = dealsReport.dealGroups.find((group) => group.identifier === "b")
		if (!groupA || !groupB) return { labels: [], datasets: [] }

		const allStageNames = groupA.dealStages
			.sort((a, b) => a.order - b.order)
			.filter((x) => !x.isLostStage && !x.isWonStage)
			.map((stage) => stage.stage)

		return {
			labels: allStageNames,
			datasets: [
				{
					label: "Group A",
					data: makeDurationDataForGroup(groupA),
					backgroundColor: "rgba(69,69,128,0.75)",
				},
				{
					label: "Group B",
					data: makeDurationDataForGroup(groupB),
					backgroundColor: "#EA97AF",
					borderColor: "transparent",
				},
			],
		}
	}, [dealsReport])

	return (
		<div className="grid lg:grid-cols-2 gap-4 grid-cols-1">
			<SectionCard className="gap-6 flex flex-col">
				<div>
					<h3 className="text-lg font-semibold text-gray-900">{t("Pipeline conversion")}</h3>

					<span className="text-md font-medium text-gray-500">
						{t("Conversion of deals at each stage of the pipeline over time")}
					</span>
				</div>
				<div className="h-[350px]">
					<Bar options={chartOptions} data={conversionData} />
				</div>
			</SectionCard>

			<SectionCard className="gap-6 flex flex-col">
				<div>
					<h3 className="text-lg font-semibold text-gray-900">{t("Pipeline duration")}</h3>

					<span className="text-md font-medium text-gray-500">
						{t("Average time spent by deals at each stage of the pipeline")}
					</span>
				</div>
				<div className="h-[350px]">
					<Bar options={chartOptions} data={durationData} />
				</div>
			</SectionCard>
		</div>
	)
}
