import clsx from "clsx"
import React from "react"

import { Duration } from "../Duration.component"

export type TimelineCursorProps = {
	variant: "active" | "default"
	currentTimeSeconds: number
	totalDurationSeconds: number
	containerRef: React.RefObject<HTMLDivElement>
	tooltipContent?: React.ReactNode
	onDrag?: (event: MouseEvent) => void
}

export function TimelineCursor({
	variant,
	currentTimeSeconds,
	totalDurationSeconds,
	containerRef,
	tooltipContent,
	onDrag,
}: TimelineCursorProps) {
	const relativePositionPx =
		(containerRef.current?.getBoundingClientRect()?.width ?? 0) * (currentTimeSeconds / totalDurationSeconds)
	const [mouseY, setMouseY] = React.useState(0)
	const [isGrabbing, setIsGrabbing] = React.useState(false)

	React.useEffect(() => {
		const onMouseMove = (event: MouseEvent) => {
			setMouseY(event.clientY)
			if (!isGrabbing) return

			onDrag?.(event)
		}

		const onMouseUp = () => {
			setIsGrabbing(false)
		}

		document.addEventListener("mousemove", onMouseMove)
		document.addEventListener("mouseup", onMouseUp)
		return () => {
			document.removeEventListener("mousemove", onMouseMove)
			document.removeEventListener("mouseup", onMouseUp)
		}
	}, [isGrabbing, onDrag])

	const relativeMouseY = mouseY - (containerRef.current?.getBoundingClientRect()?.top ?? 0)
	const clampedMouseY = Math.min(
		containerRef.current?.getBoundingClientRect()?.height ?? 0,
		Math.max(0, relativeMouseY - 12),
	)

	const handleGrab = React.useCallback(() => {
		setIsGrabbing(true)
	}, [])

	return (
		<div
			className={clsx("absolute", isGrabbing ? "pointer-events-none z-30" : "z-20")}
			style={{
				height: containerRef.current?.getBoundingClientRect()?.height,
			}}
		>
			<div
				className={clsx(
					"z-10",
					onDrag && "cursor-grab",
					"absolute w-[4px] h-[calc(100%-18px)] bottom-0 rounded-full",
					variant === "active" ? "bg-blue-500" : "bg-gray-400",
				)}
				style={{
					left: `${relativePositionPx}px`,
				}}
				onMouseDown={handleGrab}
			/>
			{onDrag && (
				<div
					className="cursor-grab absolute w-[4px] px-[1px] h-[32px] z-10"
					style={{
						left: `${relativePositionPx}px`,
						top: containerRef.current?.getBoundingClientRect()?.height
							? containerRef.current?.getBoundingClientRect().height / 2 - 16
							: 0,
					}}
					onMouseDown={handleGrab}
				>
					<div className="bg-white rounded-full h-full w-full" />
				</div>
			)}
			{tooltipContent && (
				<div
					className={clsx(
						"absolute overflow-hidden bg-gray-600 bg-opacity-60 text-white text-xs px-1.5 py-0.5 rounded-md z-20 pointer-events-none whitespace-nowrap",
					)}
					style={{
						left: `${relativePositionPx + 16}px`,
						top: `${clampedMouseY}px`,
					}}
				>
					{tooltipContent}
				</div>
			)}

			<Duration
				className={clsx(
					variant === "active" && (isGrabbing ? "opacity-100" : "opacity-50"),
					"transition-opacity duration-200",
					onDrag && "cursor-grab",
					variant === "active" ? "bg-blue-500" : "bg-gray-400",
					"absolute text-white rounded-full px-1.5 text-sm py-1 top-[-12px] -translate-x-1/2 z-[22]",
				)}
				style={{
					left: `${relativePositionPx + 1}px`,
				}}
				seconds={currentTimeSeconds}
				onMouseDown={handleGrab}
			/>
		</div>
	)
}
