import {
	ArrowsPointingInIcon,
	ArrowsPointingOutIcon,
	PauseIcon,
	PlayIcon,
	SpeakerWaveIcon,
} from "@heroicons/react/24/outline"
import React from "react"
import screenfull from "screenfull"

import { useLanguage } from "../../../../contexts/language.context"
import { useFullscreenStatus } from "../../../../hooks/useFullscreenStatus"
import { DateFormatter, DateProvider } from "../../../../utils/time"
import { RangeInput } from "../../RangeInput.component"
import { TrackingButton } from "../../TrackingButton.component"
import { SpeedControls } from "./SpeedControls.component"

type IBottomControlsProps = {
	isPlaying: boolean
	onPlayPauseClick: () => void
	onVolumeChange: React.ChangeEventHandler<HTMLInputElement>
	currentTimeSeconds: number
	totalDurationSeconds: number
	volumePercentage: number
	playBackSpeed: number
	onPlayBackSpeedChange: (newSpeed: number) => void
	onFullScreenToggle: () => void
}

export function BottomControls({
	onPlayPauseClick,
	isPlaying,
	currentTimeSeconds,
	totalDurationSeconds,
	volumePercentage,
	onVolumeChange,
	playBackSpeed,
	onPlayBackSpeedChange,
	onFullScreenToggle,
}: IBottomControlsProps) {
	const { t } = useLanguage()
	const dateFormatter = new DateFormatter(new DateProvider(), t)
	const isFullScreen = useFullscreenStatus()

	return (
		<div className="absolute bottom-0 left-0 w-full flex justify-between items-center p-2 bg-opacity-50 bg-black text-white">
			<TrackingButton
				onClick={onPlayPauseClick}
				eventName="Call video interaction"
				eventProperties={{ type: isPlaying ? "pause" : "play" }}
				className="p-1 hover:bg-gray-900 hover:opacity-90 rounded-full"
			>
				{isPlaying ? (
					<PauseIcon className="w-5 h-5 min-h-5 min-w-5" />
				) : (
					<PlayIcon className="w-5 h-5 min-h-5 min-w-5" />
				)}
			</TrackingButton>
			<span className="">
				<span className="font-medium">{dateFormatter.formatTime(currentTimeSeconds)}</span> /{" "}
				{dateFormatter.formatTime(totalDurationSeconds)}
			</span>
			<div className="flex items-center justify-center">
				<div className="pr-2">
					<SpeedControls currentSpeed={playBackSpeed} onSpeedChange={onPlayBackSpeedChange} />
				</div>
				<SpeakerWaveIcon className="w-5 h-5 min-h-5 min-w-5 mr-2" />
				<RangeInput volumePercentage={volumePercentage} onVolumeChange={onVolumeChange} />
				{screenfull.isEnabled && (
					<TrackingButton
						eventName="Call video interaction"
						eventProperties={{ type: "fullscreen", isFullScreen: !isFullScreen }}
						onClick={onFullScreenToggle}
					>
						{isFullScreen ? (
							<ArrowsPointingInIcon className="w-5 h-5 min-h-5 min-w-5 mr-2" />
						) : (
							<ArrowsPointingOutIcon className="w-5 h-5 min-h-5 min-w-5 mr-2" />
						)}
					</TrackingButton>
				)}
			</div>
		</div>
	)
}
