import React from "react"

import { type AnalyticsProps, useAnalytics } from "../../contexts/analytics.context"

type TrackingButtonProps = {
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
	AnalyticsProps

export function TrackingButton({ onClick, children, eventName, eventProperties, ...props }: TrackingButtonProps) {
	const { setupEventTracking } = useAnalytics()
	const { trackEvent: trackButtonUsage, debugDOMProps } = setupEventTracking({ eventName, eventProperties })

	const handleTrackingClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		console.debug("[TrackingButton] Tracking event:", eventName, eventProperties)
		trackButtonUsage()
		onClick?.(e)
	}

	return (
		<button {...props} onClick={handleTrackingClick} {...debugDOMProps}>
			{children}
		</button>
	)
}
