import { ArrowRightIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import _ from "lodash"

import type { MyTrainingProgramSummary } from "../../../../core/application/gateways/training.gateway/schemas/my-training-program-summary.schema"
import { SpinnerIcon } from "../../../components/design-system/SpinnerIcon.component"
import { TrackingLink } from "../../../components/design-system/TrackingLink.component"
import { useLanguage } from "../../../contexts/language.context"
import { makeMyTrainingProgramPath, makeMyTrainingProgramsPath } from "../../../router/Router"
import { DateFormatter, DateProvider } from "../../../utils/time"
import { type MyProgramsState } from "../../Training/Programs/TrainingPrograms.page"
import { Card } from "../components/Card.component"

type TrainingItemProps = {
	to: string
	isActive: boolean
	summary: MyTrainingProgramSummary
}

function TrainingItem({ summary, to, isActive }: TrainingItemProps) {
	const { t, language } = useLanguage()
	const dateProvider = new DateProvider()
	const dateFormatter = new DateFormatter(dateProvider, t)
	const deadline = summary.deadline
		? dateFormatter.formatDate(summary.deadline, language, {
				day: "2-digit",
				month: "2-digit",
				year: "2-digit",
		  })
		: undefined
	const isOverdue = summary.deadline ? summary.deadline < dateProvider.now() : false
	const completionPercent = summary.items.total === 0 ? 0 : (summary.items.seen / summary.items.total) * 100

	return (
		<TrackingLink
			eventName="my training program navigation"
			to={to}
			className={clsx(
				isActive ? "bg-gray-100 text-navy-500" : "text-gray-700 hover:text-navy-500 hover:bg-indigo-50",
				"group flex flex-col rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold",
			)}
		>
			<div className={clsx("flex")}>
				<div className="flex flex-1 justify-between">
					<div className="flex gap-x-3 items-center">
						<div className="relative flex items-center gap-1">
							<span className="mr-1">{summary.trainingProgram.name}</span>
							{!summary.wasOpened && (
								<span className="flex relative h-2 w-2 items-center">
									<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-400 opacity-75"></span>
									<span className="relative inline-flex rounded-full h-2 w-2 bg-emerald-400"></span>
								</span>
							)}
						</div>
						{deadline && (
							<>
								<span className={clsx(isOverdue ? "text-[#D35F3D]" : "text-gray-500", "text-xs")}>
									{t("Due: {{date}}", {
										date: deadline,
									})}
								</span>
							</>
						)}
					</div>
					<div>
						{t("{{percent}}% completed", {
							percent: completionPercent.toFixed(0),
						})}
					</div>
				</div>
			</div>
		</TrackingLink>
	)
}

function TrainingList({ summaries, className }: { summaries: MyTrainingProgramSummary[]; className?: string }) {
	const { t } = useLanguage()
	const [completedPrograms, uncompletedPrograms] = _.partition(
		summaries,
		(summary) => summary.items.seen === summary.items.total,
	)

	if (uncompletedPrograms.length === 0 && completedPrograms.length === 0) {
		return (
			<div className="items-center text-center p-4">
				<p className="px-10 pb-2 text-lg font-semibold text-gray-900">
					{t("You have not yet been enrolled in any training programs.")}
				</p>
				<p className="text-gray-500">{t("Once enrolled, you will be notified here.")}</p>
			</div>
		)
	}

	if (uncompletedPrograms.length === 0 && completedPrograms.length !== 0) {
		return (
			<div className="items-center text-center p-4">
				<p className="px-10 pb-2 text-lg font-semibold text-gray-900">
					{t("Congratulations, you’re up to date with your training!")}
				</p>
				<TrackingLink
					className="flex flex-row items-center justify-center gap-1 text-gray-500 font-medium"
					to={makeMyTrainingProgramsPath()}
					eventName="Dashboard: View completed programs"
				>
					{t("See my completed training programs")}
					<ArrowRightIcon className="h-4 w-4" />
				</TrackingLink>
			</div>
		)
	}

	return (
		<div className={clsx("overflow-y-auto", className)}>
			<ul role="list" className="divide-y divide-gray-200">
				{uncompletedPrograms.map((item) => (
					<li key={item.trainingProgram.id} className="p-1">
						<TrainingItem
							summary={item}
							to={makeMyTrainingProgramPath(item.trainingProgram.id)}
							isActive={false}
						/>
					</li>
				))}
			</ul>
		</div>
	)
}

type MyTrainingProps = {
	className?: string
	listClassName?: string
	programState: MyProgramsState
}

export function MyTraining({ className, programState, listClassName }: MyTrainingProps) {
	const { t } = useLanguage()

	if (programState.status === "error") {
		return null
	}

	return (
		<Card className={className}>
			<h2 className="text-lg font-semibold text-gray-900 border-b border-gray-200 py-2 px-4">
				{t("My training")}
			</h2>
			{programState.status === "loading" ? (
				<div className="flex items-center justify-center p-4">
					<SpinnerIcon className="h-8 w-8 text-gray-500" />
				</div>
			) : (
				<TrainingList summaries={programState.data} className={listClassName} />
			)}
		</Card>
	)
}
