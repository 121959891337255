import { ClockIcon, VideoCameraIcon } from "@heroicons/react/24/outline"
import React from "react"
import { useParams } from "react-router-dom"

import type { BaseCall } from "../../../core/domain/BaseCall.entity"
import { Alert } from "../../components/design-system/Alert.component"
import { SpinnerIcon } from "../../components/design-system/SpinnerIcon.component"
import { TrackingLink } from "../../components/design-system/TrackingLink.component"
import { Layout } from "../../components/shared/Layout/Layout.component"
import { PublicLayout } from "../../components/shared/PublicLayout/PublicLayout.component"
import { useSession } from "../../contexts/authentication.context"
import { CallVideoPlayerProvider } from "../../contexts/callVideoPlayer.context"
import { type TFunction, useLanguage } from "../../contexts/language.context"
import { usePublicCall } from "../../hooks/usePublicCall"
import { makeCallPath } from "../../router/Router"
import { extractTokenFromUrlParam } from "../../utils/callSharing"
import { DateProvider } from "../../utils/time"
import { Call_MainPanel } from "../Call/components/_MainPanel/_MainPanel.component"
import { _SidePanel } from "../Call/components/SidePanel/_SidePanel.component"

function useCallAccessTokenFromUrlParams() {
	const { accessTokenOrJson = "should never happen - checked at router level" } = useParams<{
		accessTokenOrJson: string
	}>()
	const { publicAccessToken, allowedTimeRanges } = extractTokenFromUrlParam(accessTokenOrJson)

	const formattedTimeRanges = React.useMemo(
		() => allowedTimeRanges?.map(([startSec, endSec]) => ({ startSec, endSec })),
		[allowedTimeRanges],
	)

	return {
		publicAccessToken,
		allowedTimeRanges: formattedTimeRanges,
		encodedAccessToken: accessTokenOrJson,
	}
}

export function PublicSharedCall() {
	const { t } = useLanguage()
	const { workspace } = useSession()
	const { publicAccessToken, encodedAccessToken, allowedTimeRanges } = useCallAccessTokenFromUrlParams()
	const { publicCall, isLoading, error } = usePublicCall(publicAccessToken)

	if (error) {
		return (
			<PublicLayout
				pageName="Call not found"
				isFluid
				PageTitle={<Layout.PageTitle title={t("Call not found")} className="text-center" />}
				className="text-center"
				isSignupAllowed
			>
				<p className="mt-4 text-base leading-7 text-gray-600 sm:mt-6 sm:text-lg sm:leading-8">
					{t("Sorry, we couldn’t find the call you were looking for.")}
				</p>
				<p className="text-base leading-7 text-gray-600 sm:text-lg sm:leading-8">
					{t("The link may have expired or the call may have been deleted.")}
				</p>
			</PublicLayout>
		)
	}

	if (!publicCall || isLoading) {
		return (
			<PublicLayout pageName="Public call" isFluid className="text-center" isSignupAllowed>
				<SpinnerIcon className={"h-8 w-8 mx-auto"} />
			</PublicLayout>
		)
	}

	const isBrowsingCallFromOwnWorkspace = workspace?.id === publicCall.props.workspaceId

	const recordedOn = getFormattedRecordedOnDate(publicCall, t)
	const durationLabel = publicCall.getFormattedDuration()

	return (
		<CallVideoPlayerProvider call={publicCall} allowedTimeRanges={allowedTimeRanges}>
			<PublicLayout
				pageName="Public call"
				isFluid
				PageTitle={<Layout.PageTitle title={publicCall.props.name} />}
				PageMeta={
					<Layout.PageMeta>
						<>
							<Layout.PageMetaItem label={recordedOn} Icon={VideoCameraIcon} />
							{durationLabel && <Layout.PageMetaItem label={durationLabel} Icon={ClockIcon} />}
							{publicCall.props.countryCode && (
								<Layout.PageMetaItem>
									<img
										src={`https://flagsapi.com/${publicCall.props.countryCode}/flat/64.png`}
										alt={publicCall.props.countryCode}
										className="mr-1.5 h-6 w-6 flex-shrink-0 text-gray-400"
									/>
								</Layout.PageMetaItem>
							)}
						</>
					</Layout.PageMeta>
				}
				isSignupAllowed
			>
				{isBrowsingCallFromOwnWorkspace && (
					<Alert
						className="mt-4"
						description={
							<div className="flex justify-center items-center gap-2">
								<span>
									{t(
										"You are viewing the public version of this call. Since you are a member of the workspace, you can view the full call.",
									)}
								</span>
								<TrackingLink
									to={makeCallPath(publicCall.props.id, undefined)}
									eventName="Public call: view full call clicked"
									className="rounded-md bg-navy-700 px-1.5 py-1 text-xs font-semibold text-white shadow-sm hover:bg-navy-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-500"
								>
									{t("View full call")}
								</TrackingLink>
							</div>
						}
						variant="info"
					/>
				)}
				<div className="flex flex-col md:flex-row md:space-x-6 mt-8">
					<Call_MainPanel
						call={publicCall}
						highlights={[]}
						timeline={true}
						timelineVerticalSpacing={4}
						className="md:w-6/12"
					/>
					<_SidePanel call={publicCall} encodedAccessToken={encodedAccessToken} />
				</div>
			</PublicLayout>
		</CallVideoPlayerProvider>
	)
}

// TODO: duplicated from Call.page.tsx
function getFormattedRecordedOnDate(call: BaseCall, t: TFunction) {
	const createdToday = new DateProvider().isToday(call.props.createdAt)

	if (createdToday) {
		return t("Recorded today at {{time}}", {
			time: call.getCreatedAtDayAndTime(t).time,
		})
	}

	return t("Recorded on {{date}} at {{time}}", {
		date: call.getCreatedAtDayAndTime(t).day,
		time: call.getCreatedAtDayAndTime(t).time,
	})
}
