import { addDays, isWeekend } from "date-fns"
import _ from "lodash"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useMount } from "react-use"

import type { ListAllWorkspacesRouteResponse } from "../../../../core/application/gateways/platform-admin.gateway"
import { AdminLayout } from "../../../components/shared/AdminLayout/AdminLayout.component"
import { useDependencies } from "../../../contexts/dependencies.context"
import { WorkspacesTable } from "./WorkspacesTable.component"

const subscriptionOptions = [
	{ value: "all", label: "All" },
	{ value: "paying", label: "Paying" },
	{ value: "trialing", label: "Trialing" },
	{ value: "readonly", label: "Read Only" },
] as const

const activityOptions = [
	{ value: "all", label: "All" },
	{ value: "no_activity", label: "No activity" },
	{ value: "no_activity_last_3_working_days", label: "No activity in the last 3 working days" },
	{ value: "no_activity_last_7_working_days", label: "No activity in the last 7 working days" },
	{ value: "activity_last_3_working_days", label: "Activity in the last 3 working days" },
	{ value: "activity_last_7_working_days", label: "Activity in the last 7 working days" },
] as const

function useWorkspaces() {
	const { platformAdminGateway } = useDependencies()
	const [workspaces, setWorkspaces] = useState<ListAllWorkspacesRouteResponse | null>(null)
	const [searchParams, setSearchParams] = useSearchParams()

	const subscriptionFilter = searchParams.get("subscription") || "all"
	const activityFilter = searchParams.get("activity") || "all"

	useMount(async () => {
		const _workspaces = await platformAdminGateway.listWorkspaces()
		setWorkspaces(_workspaces)
	})

	// Update the query parameters
	const handleSubscriptionFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		searchParams.set("subscription", e.target.value)
		setSearchParams(searchParams)
	}

	const handleActivityFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		searchParams.set("activity", e.target.value)
		setSearchParams(searchParams)
	}

	return {
		workspaces,
		subscriptionFilter,
		handleSubscriptionFilterChange,
		activityFilter,
		handleActivityFilterChange,
	}
}

// doesn't use i18n as this is an admin page
export function WorkspacesPage() {
	const {
		workspaces,
		subscriptionFilter,
		handleSubscriptionFilterChange,
		activityFilter,
		handleActivityFilterChange,
	} = useWorkspaces()

	const filteredAndSortedWorkspaces = workspaces
		? _.orderBy(workspaces, ["createdAt"], ["desc"])
				.filter((workspace) => {
					// Filter by subscription status
					if (subscriptionFilter === "paying") {
						return (
							workspace.planId && (!workspace.subscription || workspace.subscription?.status === "active")
						)
					} else if (subscriptionFilter === "trialing") {
						return workspace.subscription && workspace.subscription.status === "trialing"
					} else if (subscriptionFilter === "readonly") {
						return !workspace.planId
					}
					return true
				})
				.filter((workspace) => {
					// Filter by recent call activity
					const recentCall = _.maxBy(
						workspace.users.flatMap((user) => user.recentCalls),
						"createdAt",
					)
					const now = new Date()

					if (activityFilter === "no_activity") {
						return !recentCall
					} else if (activityFilter === "no_activity_last_3_working_days") {
						const cutoffDate = addWorkingDays(now, -3)
						return !recentCall?.createdAt || new Date(recentCall.createdAt) < cutoffDate
					} else if (activityFilter === "no_activity_last_7_working_days") {
						const cutoffDate = addWorkingDays(now, -7)
						return !recentCall?.createdAt || new Date(recentCall.createdAt) < cutoffDate
					} else if (activityFilter === "activity_last_3_working_days") {
						const cutoffDate = addWorkingDays(now, -3) // 3 working days back
						return recentCall?.createdAt && new Date(recentCall.createdAt) >= cutoffDate
					} else if (activityFilter === "activity_last_7_working_days") {
						const cutoffDate = addWorkingDays(now, -7) // 7 working days back
						return recentCall?.createdAt && new Date(recentCall.createdAt) >= cutoffDate
					}

					return true
				})
		: []

	return (
		<AdminLayout origin="workspaces" pageName="Back office: workspaces list">
			<div className="flex items-center space-x-6 mb-4">
				<label className="flex items-center space-x-2">
					<span className="whitespace-nowrap">Subscription status:</span>
					<select
						value={subscriptionFilter}
						onChange={handleSubscriptionFilterChange}
						className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
					>
						{subscriptionOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</label>
				<label className="flex items-center space-x-2">
					<span className="whitespace-nowrap">Activity:</span>
					<select
						value={activityFilter}
						onChange={handleActivityFilterChange}
						className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
					>
						{activityOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</select>
				</label>
			</div>

			{workspaces && <WorkspacesTable workspaces={filteredAndSortedWorkspaces} />}
		</AdminLayout>
	)
}

function addWorkingDays(date: Date, days: number): Date {
	let result = new Date(date)
	let addedDays = 0

	const direction = Math.sign(days) // 1 for positive, -1 for negative
	const absDays = Math.abs(days) // Get absolute value of the number of days

	while (addedDays < absDays) {
		result = addDays(result, direction) // Move forward or backward
		// Skip weekends
		if (!isWeekend(result)) {
			addedDays++
		}
	}

	return result
}
