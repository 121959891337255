import clsx from "clsx"
import React from "react"

import type { GetSalesMotionPerformanceStatsResponse } from "../../../../../../core/application/gateways/deals.gateway/schemas/listDeals"
import { SpinnerIcon } from "../../../../../components/design-system/SpinnerIcon.component"
import { TextToggle } from "../../../../../components/design-system/TextToggle.component"
import { SectionCard } from "../../../../../components/shared/GraphSection/SectionCard.component"
import { useLanguage } from "../../../../../contexts/language.context"
import { ActiveDealValueGraph } from "./ActiveDealValueGraph.component"

type IActiveDealValueSectionProps = {
	stats: GetSalesMotionPerformanceStatsResponse
	isLoading: boolean
}

export function ActiveDealValueSection({ stats, isLoading }: IActiveDealValueSectionProps) {
	const { t } = useLanguage()
	const [mode, setMode] = React.useState<"absolute" | "relative">("absolute")

	return (
		<SectionCard className="h-full gap-6 flex flex-col">
			<div className="flex justify-between items-center">
				<div>
					<h3 className="text-md font-semibold text-gray-900">{t("Changes in active deal value")}</h3>

					<span className="text-sm font-medium text-gray-500">
						{t(
							mode === "relative"
								? "% difference in total deal value at each stage of the pipeline"
								: "Difference in total deal value at each stage of the pipeline",
						)}
					</span>
				</div>
				<TextToggle
					value={mode}
					a={{ label: t("Absolute amount"), value: "absolute" }}
					b={{ label: t("Relative amount"), value: "relative" }}
					onChange={setMode}
					disabled={isLoading}
				/>
			</div>

			<div className="h-[30vh]">
				<div className="relative h-full">
					{isLoading && (
						<>
							<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
								<SpinnerIcon className="w-8 h-8 " />
							</div>
							<div className="absolute inset-0 flex items-center justify-center" />
						</>
					)}
					<div className={clsx(isLoading && "opacity-50 h-full pointer-events-none", "h-full")}>
						<ActiveDealValueGraph stats={stats} mode={mode} />
					</div>
				</div>
			</div>
		</SectionCard>
	)
}
