import { CheckCircleIcon, ExclamationTriangleIcon, InformationCircleIcon, XCircleIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import React from "react"

export type AlertProps = {
	title?: React.ReactNode
	description: React.ReactNode
	variant?: "success" | "error" | "warning" | "info"
	className?: string
}

export function Alert({ description, title, className, variant = "warning" }: AlertProps) {
	const Icon = {
		success: CheckCircleIcon,
		error: XCircleIcon,
		warning: ExclamationTriangleIcon,
		info: InformationCircleIcon,
	}[variant]

	return (
		<div
			className={clsx(
				"rounded-md p-2",
				variant === "warning" && "bg-yellow-50",
				variant === "error" && "bg-rose-50",
				variant === "success" && "bg-emerald-50",
				variant === "info" && "bg-blue-50",
				className,
			)}
		>
			<div className="flex items-center">
				<div className="flex-shrink-0">
					<Icon
						className={clsx(
							"h-5 w-5",
							variant === "warning" && "text-yellow-400",
							variant === "error" && "text-red-500",
							variant === "success" && "text-green-500",
							variant === "info" && "text-blue-500",
						)}
						aria-hidden="true"
					/>
				</div>
				<div className="ml-1.5 flex gap-1 flex-col">
					{title && (
						<h3
							className={clsx(
								"text-sm font-medium",
								variant === "warning" && "text-yellow-800",
								variant === "error" && "text-red-800",
								variant === "success" && "text-green-800",
								variant === "info" && "text-blue-800",
							)}
						>
							{title}
						</h3>
					)}

					<div
						className={clsx(
							"text-sm",
							variant === "warning" && "text-yellow-700",
							variant === "error" && "text-red-700",
							variant === "success" && "text-green-700",
							variant === "info" && "text-blue-700",
						)}
					>
						{description}
					</div>
				</div>
			</div>
		</div>
	)
}
