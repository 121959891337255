import React, { useRef } from "react"
import Markdown from "react-markdown"

import type { BaseCall, CallMeetingNotes } from "../../../../../core/domain/BaseCall.entity"
import type { MeetingNotesTemplateWithSections } from "../../../../../core/domain/MeetingNotesTemplate.entity"
import { Badge } from "../../../../components/design-system/Badge.component"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { DocElementRenderer } from "../../../../components/shared/DocElementRenderer.component"
import { MarkdownAnchorRenderer } from "../../../../components/shared/MarkdownLinkRenderer.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { makeMeetingNotesTemplatePath, makeMeetingNotesTemplatesPath } from "../../../../router/Router"

export type _OverviewPanelProps = {
	call: BaseCall
}

export function TodoListItemRenderer({ item }: { item: string }) {
	return (
		<span className="prose-sm prose-headings:my-2 prose-a:text-sky hover:prose-a:text-blue-500">
			<Markdown
				components={{
					a: MarkdownAnchorRenderer,
					p: "span",
				}}
			>
				{item}
			</Markdown>
		</span>
	)
}

function MeetingNoteTemplateBadge({ template }: { template: MeetingNotesTemplateWithSections }) {
	const { user } = useAuthenticatedSession()
	const { t } = useLanguage()
	const badge = <Badge label={template.isDefault ? t("Default template") : template.title} variant="info" />
	if (!user.isWorkspaceManager()) {
		return badge
	}

	return (
		<TrackingLink
			to={template.isDefault ? makeMeetingNotesTemplatesPath() : makeMeetingNotesTemplatePath(template.id)}
			eventName="Meeting notes template clicked from call"
		>
			{badge}
		</TrackingLink>
	)
}

function MeetingNotesRenderer({ meetingNotes }: { meetingNotes: NonNullable<CallMeetingNotes> }) {
	const { t } = useLanguage()
	const [templates, setTemplates] = React.useState<MeetingNotesTemplateWithSections[] | null>(null)
	const { meetingNotesTemplatesGateway } = useDependencies()
	React.useEffect(() => {
		if (meetingNotes.templateId && !templates) {
			meetingNotesTemplatesGateway.listMeetingNotesTemplates().then(setTemplates).catch(console.error)
		}
	}, [meetingNotes, meetingNotesTemplatesGateway, templates])

	const usedTemplate = templates?.find((template) => template.id === meetingNotes.templateId)

	return (
		<>
			<h3 className="text-lg leading-6 font-medium text-gray-900 flex gap-2 mb-4">
				{t("Meeting notes")}
				{usedTemplate && <MeetingNoteTemplateBadge template={usedTemplate} />}
			</h3>
			<DocElementRenderer docElement={meetingNotes.docElement} />
		</>
	)
}

export function _OverviewPanel({ call }: _OverviewPanelProps) {
	const { t } = useLanguage()

	if (!call.props.meetingNotes && !call.props.nextBestActionSuggestion) {
		return <SpinnerIcon className="h-8 w-8 text-gray-400" aria-hidden="true" />
	}

	const email = call.props.nextBestActionSuggestion?.email

	return (
		<div className="">
			{call.props.meetingNotes ? (
				<MeetingNotesRenderer meetingNotes={call.props.meetingNotes} />
			) : call.props.nextBestActionSuggestion ? (
				<>
					<h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">{t("Key points")}</h3>
					<ul className="list-disc list-inside">
						{call.props.nextBestActionSuggestion.keyPoints.map((keyPoint) => (
							<li key={keyPoint} className="mt-2 text-sm text-gray-600">
								{keyPoint}
							</li>
						))}
					</ul>
					<h3 className="text-lg leading-6 font-medium text-gray-900 mt-6">{t("To-do list")}</h3>
					<ul className="list-disc list-inside mt-3">
						{call.props.nextBestActionSuggestion.notes.map((note) => (
							<li key={note} className="mt-2 text-sm text-gray-600">
								<TodoListItemRenderer item={note} />
							</li>
						))}
					</ul>
				</>
			) : null}

			{email && (
				<>
					<h3 className="text-lg leading-6 font-medium text-gray-900 mt-6">{t("Suggested email")}</h3>
					<div className="text-sm text-gray-500 mt-3">
						<ReadOnlyTextArea callId={call.props.id} text={email} />
					</div>
				</>
			)}
		</div>
	)
}

type ReadOnlyTextAreaProps = {
	callId: string
	text: string
}

const ReadOnlyTextArea: React.FC<ReadOnlyTextAreaProps> = ({ text, callId }) => {
	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const { t } = useLanguage()
	const handleCopy = () => {
		if (textareaRef.current) {
			textareaRef.current.select()
			document.execCommand("copy")
		}
	}

	return (
		<div className="flex flex-col space-y-2">
			<textarea
				ref={textareaRef}
				readOnly
				value={text}
				className="p-2 border border-gray-300 rounded-md w-full resize-none"
				rows={20}
			/>
			<TrackingButton
				eventName="NextBestAction email copied"
				eventProperties={{
					callId,
					textLength: text.length,
				}}
				onClick={handleCopy}
				className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
			>
				{t("Copy email")}
			</TrackingButton>
		</div>
	)
}
