import { CheckCircleIcon } from "@heroicons/react/20/solid"
import { ChevronDownIcon } from "@heroicons/react/24/outline"
import assertNever from "assert-never"
import clsx from "clsx"
import React from "react"

import type {
	MyTrainingProgramItem,
	MyTrainingProgramSection,
} from "../../../../../../core/application/gateways/training.gateway/schemas/my-training-program-content.schema"
import { TrackingButton } from "../../../../../components/design-system/TrackingButton.component"
import { getPlainTextFromHtml } from "../../../../../utils/htmlParsing"
import { ContentTypeIcon } from "../../components/ContentTypeIcon.component"

type TrainingSectionCardProps = {
	trainingSection: MyTrainingProgramSection
	index: number
	onItemClick: (item: MyTrainingProgramItem) => void
	selectedItem?: MyTrainingProgramItem
}

export function TrainingSectionCard({ trainingSection, index, onItemClick, selectedItem }: TrainingSectionCardProps) {
	const [isCollapsed, setIsCollapsed] = React.useState(false)
	const seenItemsCount = trainingSection.items.filter((item) => item.seen).length

	React.useEffect(() => {
		if (selectedItem && trainingSection.items.some((x) => x.id === selectedItem.id)) {
			setIsCollapsed(true)
		}
	}, [selectedItem, trainingSection.items])

	return (
		<div
			className={clsx(
				"border hover:border-gray-200 p-2 mb-4 rounded-md group border-gray-100 shadow-sm bg-white flex items-center justify-center",
			)}
		>
			<div className="flex-1">
				<div className="flex flex-1">
					<div className="flex flex-row flex-1 gap-2">
						<div
							className="flex flex-row gap-2 flex-1 items-center cursor-pointer"
							onClick={() => setIsCollapsed((prev) => !prev)}
						>
							<div className="flex items-center justify-between flex-1">
								<div className="ml-2 flex flex-row items-center gap-4">
									<p className="text-md font-semibold text-navy-900">{index + 1}</p>
									<p className="text-md font-semibold text-navy-900">{trainingSection.name}</p>
									<span className="text-xs text-gray-500">
										{seenItemsCount}/{trainingSection.items.length}
									</span>
									<div className="flex items-center justify-center">
										{seenItemsCount === trainingSection.items.length && (
											<CheckCircleIcon className="h-4 w-4 text-emerald-400" />
										)}
									</div>
								</div>
							</div>

							<ChevronDownIcon
								className={clsx("h-5 w-5 text-gray-500 ", {
									"transform -rotate-90": !isCollapsed,
								})}
							/>
						</div>
					</div>
				</div>

				{isCollapsed && (
					<div className="mt-2">
						{trainingSection.description && (
							<span className="text-sm text-gray-500">{trainingSection.description}</span>
						)}
						<div className="mt-2 flex flex-col gap-1">
							{trainingSection.items.map((item, idx) => {
								const isItemSelected = selectedItem?.id === item.id
								let itemTitle: string
								switch (item.type) {
									case "highlight":
										itemTitle = item.highlight.comment
										break
									case "video":
									case "pdf":
										itemTitle = item.fileName
										break
									case "text":
										itemTitle = getPlainTextFromHtml(item.text)
										break
									default:
										assertNever(item)
								}

								return (
									<TrackingButton
										eventName="training section item clicked"
										eventProperties={{
											sectionName: trainingSection.name,
											itemType: item.type,
										}}
										key={idx}
										className={clsx(
											"flex flex-row items-center rounded-md hover:bg-indigo-50 p-2 justify-between",
											isItemSelected && "bg-indigo-50",
										)}
										onClick={() => onItemClick(item)}
									>
										<div className="grid grid-cols-8 gap-2 flex-1">
											<div className="flex items-center justify-center">
												<ContentTypeIcon
													className={clsx(
														item.seen && !isItemSelected
															? "text-gray-400"
															: "text-navy-900",
													)}
													contentType={item.type}
												/>
											</div>

											<p
												key={idx}
												className={clsx(
													"text-sm text-left col-span-6",
													(!isItemSelected || !item.description) && "truncate",
													item.seen && !isItemSelected ? "text-gray-400" : "text-navy-900",
												)}
												title={item.description || itemTitle}
											>
												{item.description || itemTitle}
											</p>
											<div className="flex items-center justify-center">
												{item.seen && <CheckCircleIcon className="h-4 w-4 text-emerald-400" />}
											</div>
										</div>
									</TrackingButton>
								)
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
