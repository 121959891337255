export type RangeInputProps = {
	volumePercentage: number
	onVolumeChange: React.ChangeEventHandler<HTMLInputElement>
}

export function RangeInput({ volumePercentage, onVolumeChange }: RangeInputProps) {
	return (
		<input
			type="range"
			className="volume-slider mx-2 appearance-none w-24 h-1 rounded"
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			style={{ "--volume-level": `${volumePercentage * 100}%` }}
			min="0"
			max="1"
			step="0.005"
			onChange={onVolumeChange}
			value={volumePercentage}
		/>
	)
}
