import { Link, type LinkProps } from "react-router-dom"

import { type AnalyticsProps, useAnalytics } from "../../contexts/analytics.context"

export type TrackingLinkProps = LinkProps & AnalyticsProps

export function TrackingLink({ to, children, eventName, eventProperties, ...props }: TrackingLinkProps) {
	const { setupEventTracking } = useAnalytics()

	const { trackEvent: trackLinkUsage, debugDOMProps } = setupEventTracking({ eventName, eventProperties })

	const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
		const _eventProperties = {
			...eventProperties,
			to,
		}
		console.debug("[TrackingLink] Tracking event:", eventName, _eventProperties)

		trackLinkUsage()
		props.onClick?.(event)
	}

	return (
		<Link to={to} {...props} onClick={handleClick} {...debugDOMProps}>
			{children}
		</Link>
	)
}
