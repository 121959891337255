import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import React from "react"

import type { TagType } from "../../../core/domain/TagType.entity"
import { type TranslationKey, useLanguage } from "../../contexts/language.context"
import { Badge } from "../design-system/Badge.component"

type TagTypeBadgeProps = {
	tagType: TagType
	className?: string
	style?: React.CSSProperties
	onClick?: () => void
}

export function TagTypeBadge({ tagType, className, style, onClick }: TagTypeBadgeProps) {
	const { t, exists: translationKeyExists } = useLanguage()
	const tagName =
		tagType.isDefaultTag() && tagType.properties.slug && translationKeyExists(tagType.properties.slug)
			? t(tagType.properties.slug as TranslationKey)
			: tagType.properties.name

	const label = tagType.isExcerptTag() ? (
		<span className="flex items-center gap-1">
			{tagName} <ChatBubbleBottomCenterTextIcon className="h-4 w-4" />
		</span>
	) : (
		tagName
	)

	return (
		<Badge
			label={label}
			variant="custom"
			color={tagType.properties.color}
			onClick={onClick}
			className={clsx(className, "cursor-pointer")}
			style={style}
		/>
	)
}
