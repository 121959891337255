import { z } from "zod"

import { apiCallHighlightSchema } from "./apiCallHighlights.schema"

const coachingSessionItemSchema_wellPlayed = z.object({
	type: z.literal("well-played"),
	themeName: z.string(),
	callHighlight: apiCallHighlightSchema,
	seenAt: z.coerce.date().optional(),
})

const coachingSessionItemSchema_improvement = z.object({
	type: z.literal("improvement"),
	themeName: z.string(),
	callHighlight: apiCallHighlightSchema,
	bestPractice: apiCallHighlightSchema,
	seenAt: z.coerce.date().optional(),
})

const coachingSessionItemSchema = z.union([coachingSessionItemSchema_wellPlayed, coachingSessionItemSchema_improvement])
export type CoachingSessionItem = z.infer<typeof coachingSessionItemSchema>

const coachingSessionStatisticsSchema = z.object({
	aiHighlights: z.object({
		wellPlayed: z.object({
			overall: z.number(),
		}),
		notWellPlayed: z.object({
			overall: z.number(),
		}),
	}),
})

export const coachingSessionSchema = z.object({
	weekStart: z.coerce.date(),
	items: z.array(coachingSessionItemSchema),
	statistics: coachingSessionStatisticsSchema,
})
export type CoachingSession = z.infer<typeof coachingSessionSchema>

export const getCoachingSessionsReponseSchema = z.object({
	sessions: z.array(coachingSessionSchema),
})
export type GetCoachingSessionsResponse = z.infer<typeof getCoachingSessionsReponseSchema>

export const getUnseenCoachingSessionItemsResponseSchema = z.object({
	hasUnseenItems: z.boolean(),
	totalDurationMs: z.number(),
})
export type GetUnseenCoachingSessionItemsResponse = z.infer<typeof getUnseenCoachingSessionItemsResponseSchema>
