import clsx from "clsx"

import { useLanguage } from "../../../../../contexts/language.context"

export function ContactUsOverlay() {
	const { t } = useLanguage()

	return (
		<>
			<div
				className={clsx(
					"absolute inset-0 bg-red bg-gradient-to-t from-white to-white/20 z-50 pointer-events-none",
					"top-36 mt-4",
				)}
			/>
			<div
				className={clsx(
					"absolute inset-0 z-50 flex items-center justify-center overflow-y-auto",
					"top-36 mt-4",
				)}
			>
				<div className="relative bg-white rounded-lg shadow-lg p-6 max-w-lg w-full">
					<div className="flex items-center justify-start gap-x-4 mb-4">
						<h3 className="text-lg font-semibold leading-6 text-gray-900">
							{t("Contact us to set up your forecast")}
						</h3>
					</div>
					<p className="text-gray-600 mb-2">
						{t(
							"Be precise and consistent in your revenue planning with accurate and reliable forecast calculations based on data from your CRM and call recordings, as well as suggestions of impactful actions to increase closing probabilities and achieve your best case.",
						)}
					</p>
					<p className="text-gray-600 mb-2 font-bold">
						{t(
							"Contact us at support@rippletide.com, or via the widget in the bottom right corner of your screen, to activate your sales forecasting now.",
						)}
					</p>
				</div>
			</div>
		</>
	)
}
