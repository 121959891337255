import React from "react"
import { useLocalStorage } from "react-use"

import type { CalendarEvent } from "../../../../../core/domain/CalendarEvent.entity/CalendarEvent.entity"

export function useCalendarEventBriefing(calendarEvent: CalendarEvent) {
	const storageKey = `${calendarEvent.id}-briefing-read`

	const [isBriefingAlreadyRead, setIsBriefingAlreadyRead] = useLocalStorage(storageKey, false)

	const markBriefingAsRead = React.useCallback(() => {
		setIsBriefingAlreadyRead(true)
	}, [setIsBriefingAlreadyRead])

	return {
		isBriefingAlreadyRead,
		markBriefingAsRead,
	}
}
