export type TabsWrapperProps = {
	children: React.ReactNode
}

export function TabsWrapper({ children }: TabsWrapperProps) {
	return (
		<div className="border-b border-gray-200">
			<nav className="flex flex-wrap -mb-px " aria-label="Tabs">
				{children}
			</nav>
		</div>
	)
}
