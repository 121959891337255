import React from "react"

import { Input } from "../../components/design-system/Input"
import { CallSearchInput } from "../../components/shared/CallSearchInput.component"
import { CallsTable } from "../../components/shared/CallsTable/CallsTable.component"
import { CallTagFilter } from "../../components/shared/CallTagFilter"
import { Layout } from "../../components/shared/Layout/Layout.component"
import PaginationFooter from "../../components/shared/PaginationFooter/PaginationFooter.component"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useLanguage } from "../../contexts/language.context"
import { useAvailableCallTags } from "../../hooks/useAvailableCallTags"
import { useCalls } from "../../hooks/useCalls/useCalls"
import { getDemoCall } from "../../utils/onboardingDemo/demoCall"
import { DateProvider } from "../../utils/time"

export function CallsPage() {
	const {
		handleSearchFilterChange,
		searchFilter,
		calls,
		totalCalls,
		pageNumber,
		pageSize,
		totalPages,
		handlePageNavigation,
		handleSelectedAssignedUserFilterChange,
		handleSelectedTeamFilterChange,
		refreshCalls,
		selectedTeam,
		selectedTeamId,
		selectedAssignedUserId,
		allMembers,
		filters,
		shouldDisplayDemoCall,
		areCallsLoading,
		selectedTagIds,
		handleTagIdsChange,
	} = useCalls()

	const { user, teams } = useAuthenticatedSession()
	const { t, language } = useLanguage()
	const { availableTags } = useAvailableCallTags()

	const demoCalls = React.useMemo(() => {
		const dateProvider = new DateProvider()
		return [getDemoCall(user, dateProvider, t, language)]
	}, [t, user, language])

	const renderTeamFilter = () => {
		const teamFilterOptions = filters.filterOptions.teams
		if (!teamFilterOptions.show) {
			return null
		}

		return (
			<Input.Group>
				<Input.Label htmlFor="team-filter">{t("Teams")}</Input.Label>
				<Input.Select
					id="team-filter"
					name="team-filter"
					onChange={(event) => {
						const value = event.target.value
						const team = teams.find((team) => team.id === value)
						if (!team && value !== "#any#") {
							console.error("Team not found")
							return
						}
						handleSelectedTeamFilterChange(team || null)
					}}
					value={selectedTeamId}
				>
					<Input.SelectOption value="#any#">
						{teamFilterOptions.canFilterByAnyTeam ? t("All teams") : t("All my teams")}
					</Input.SelectOption>
					{filters.filterOptions.teams.options.map((team) => (
						<Input.SelectOption key={team.id} value={team.id}>
							{team.name}
						</Input.SelectOption>
					))}
				</Input.Select>
			</Input.Group>
		)
	}

	const renderMemberFilter = () => {
		const memberFilterOptions = filters.filterOptions.members
		if (!memberFilterOptions.show) {
			return null
		}

		const memberDropdownOptions = memberFilterOptions.options.filter((x) => x.id !== user.id)

		return (
			<Input.Group className="max-w-xs">
				<Input.Label htmlFor="member-filter">{t("Members")}</Input.Label>
				<Input.Select
					id="member-filter"
					name="member-filter"
					onChange={handleSelectedAssignedUserFilterChange}
					value={selectedAssignedUserId}
				>
					<Input.SelectOption value={user.id}>{t("My calls")}</Input.SelectOption>
					<Input.SelectOption value="#any#">
						{selectedTeam
							? t("All members of {{teamName}}", {
									teamName: selectedTeam.name,
							  })
							: memberFilterOptions.canFilterByAnyMember
							? t("All members")
							: t("All members of my teams")}
					</Input.SelectOption>
					{!selectedTeam && memberFilterOptions.canFilterByAnyMember && (
						<Input.SelectOption value="#unassigned#">{t("Unassigned calls")}</Input.SelectOption>
					)}
					{memberDropdownOptions.map((user) => (
						<Input.SelectOption key={user.id} value={user.id}>
							{user.isUnclaimed() ? user.email : user.getFullName() + ` <${user.email}>`}
						</Input.SelectOption>
					))}
				</Input.Select>
			</Input.Group>
		)
	}

	return (
		<Layout pageName="Calls" PageTitle={<Layout.PageTitle title={t("Calls")} />}>
			<div className="flex items-end mt-4 gap-4">
				{renderTeamFilter()}
				{renderMemberFilter()}
				<CallSearchInput search={searchFilter ?? ""} onChange={handleSearchFilterChange} />
				<CallTagFilter
					availableTags={availableTags}
					onTagSelectionChange={handleTagIdsChange}
					selectedTagIds={selectedTagIds}
				/>
			</div>
			<CallsTable
				calls={shouldDisplayDemoCall ? demoCalls : calls}
				displayAssignedUser={true}
				users={allMembers}
				hideOverallScore={shouldDisplayDemoCall}
				refreshCalls={refreshCalls}
				showLoadingSkeleton={areCallsLoading}
				availableTags={availableTags}
			/>
			<PaginationFooter
				navigatePrev={() => handlePageNavigation((page) => Math.max(1, page - 1))}
				navigateNext={() => handlePageNavigation((page) => Math.min(totalPages, page + 1))}
				navigateToPage={(page) => handlePageNavigation(page)}
				currentPage={pageNumber}
				pageSize={pageSize}
				totalItems={totalCalls}
			/>
		</Layout>
	)
}
