import clsx from "clsx"
import React from "react"

export function TrainingProgramsSideBar({ children }: { children: React.ReactNode }) {
	return (
		<div className={clsx("bg-white xl:w-60 xl:flex-shrink-0 xl:border-r xl:border-gray-200")}>
			<div className="py-6 px-2 sticky top-0">{children}</div>
		</div>
	)
}
