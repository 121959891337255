import React from "react"

import { Alert } from "../../../../components/design-system/Alert.component"
import { useLanguage } from "../../../../contexts/language.context"

export function CallTooShortDisclaimer() {
	const { t } = useLanguage()
	return (
		<Alert
			description={t(
				"This call is too short to provide an in-depth analysis. The analysis shown here may not be fully accurate.",
			)}
			variant="warning"
			className="p-4"
		/>
	)
}
