import clsx from "clsx"
import React from "react"

import { CallSearchInput } from "../../../components/shared/CallSearchInput.component"
import { _CallRow } from "../../../components/shared/CallsTable/_CallRow.component"
import { _CallRowSkeleton } from "../../../components/shared/CallsTable/_CallRowSkeleton.component"
import PaginationFooter from "../../../components/shared/PaginationFooter/PaginationFooter.component"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useLanguage } from "../../../contexts/language.context"
import { useAvailableCallTags } from "../../../hooks/useAvailableCallTags"
import { useCalls } from "../../../hooks/useCalls/useCalls"
import { getDemoCall } from "../../../utils/onboardingDemo/demoCall"
import { DateProvider } from "../../../utils/time"
import { Card } from "../components/Card.component"

type RecentCallsProps = {
	className?: string
	tableContainerClassName?: string
}

export function RecentCalls({ className, tableContainerClassName }: RecentCallsProps) {
	const {
		handleSearchFilterChange,
		searchFilter,
		calls,
		totalCalls,
		pageNumber,
		pageSize,
		totalPages,
		handlePageNavigation,
		refreshCalls,
		allMembers,
		shouldDisplayDemoCall,
		areCallsLoading,
	} = useCalls({
		onlyCurrentUser: true,
	})

	const { availableTags } = useAvailableCallTags()

	const { user } = useAuthenticatedSession()
	const { t, language } = useLanguage()

	const demoCalls = React.useMemo(() => {
		const dateProvider = new DateProvider()
		return [getDemoCall(user, dateProvider, t, language)]
	}, [t, user, language])

	const callsToDisplay = shouldDisplayDemoCall ? demoCalls : calls

	return (
		<Card className={clsx("py-2 px-4", className)}>
			<div className="flex items-center gap-4">
				<h3 className="text-lg font-semibold mr-2">{t("My recent calls")}</h3>
				<div className="flex items-end gap-4">
					{!shouldDisplayDemoCall && (
						<>
							<CallSearchInput
								classname="!mt-0 !w-[280px]"
								search={searchFilter ?? ""}
								onChange={handleSearchFilterChange}
								hideLabel={true}
							/>
						</>
					)}
				</div>
			</div>

			<div className={clsx("mt-4 flow-root")}>
				<div className="overflow-x-auto">
					<div className="inline-block min-w-full py-2 align-middle px-0.5">
						<div
							className={clsx(
								"overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg mb-1 overflow-y-auto",
								tableContainerClassName,
							)}
						>
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900"
										>
											{t("Name")}
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											{t("Company")}
										</th>

										{user.isWorkspaceManager() && <th />}
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{areCallsLoading
										? [...Array(5).keys()].map((_, index) => <_CallRowSkeleton key={index} />)
										: callsToDisplay.map((call) => (
												<_CallRow
													key={call.props.id}
													call={call}
													displayAssignedUser={false}
													displayScore={undefined}
													users={allMembers}
													refreshCalls={refreshCalls}
													availableTags={availableTags}
													displayTags={false}
													nameRowClassName="!max-w-[42vw]"
												/>
										  ))}
								</tbody>
							</table>
						</div>
					</div>
					<PaginationFooter
						navigatePrev={() => handlePageNavigation((page) => Math.max(1, page - 1))}
						navigateNext={() => handlePageNavigation((page) => Math.min(totalPages, page + 1))}
						navigateToPage={(page) => handlePageNavigation(page)}
						currentPage={pageNumber}
						pageSize={pageSize}
						totalItems={totalCalls}
					/>
				</div>
			</div>
		</Card>
	)
}
