import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import { CheckIcon, PlusIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import React from "react"

import type { TagType } from "../../../core/domain/TagType.entity"
import { type TranslationKey, useLanguage } from "../../contexts/language.context"
import { Button } from "../design-system/Button.component"
import { SpinnerIcon } from "../design-system/SpinnerIcon.component"
import { Tooltip } from "../design-system/Tooltip.component"
import { TrackingButton } from "../design-system/TrackingButton.component"
import { TagTypeBadge } from "./TagTypeBadge.component"

export type ITagSelectionMenuProps = {
	tags: TagType[] | "loading"
	isSaveDisabled?: boolean
	isSaving?: boolean
	onSave?: (closeCallback: () => void) => void
	selectedTagIds: string[]
	onTagSelectionChange: (tag: TagType, selected: boolean) => void
	popoverButton?: React.ReactNode
	tooltipContent?: TranslationKey
}

export function TagSelectionMenu({
	tags,
	onSave,
	isSaveDisabled,
	isSaving,
	selectedTagIds,
	onTagSelectionChange,
	popoverButton,
	tooltipContent,
}: ITagSelectionMenuProps) {
	const { t } = useLanguage()

	return (
		<Popover className="relative">
			<Tooltip content={t(tooltipContent ?? "Add or remove tags")}>
				{popoverButton ?? (
					<PopoverButton className="border border-gray-400 rounded-full p-1 hover:bg-indigo-50 hover:text-indigo-500">
						<PlusIcon className="h-3 w-3 text-gray-600" aria-hidden="true" />
					</PopoverButton>
				)}
			</Tooltip>

			<PopoverPanel
				transition
				className="absolute left-1/2 z-30 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
			>
				{({ close }) => (
					<>
						<div className="w-screen max-w-[18rem] flex-auto rounded-3xl bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
							{tags === "loading" ? (
								<SpinnerIcon className={"h-6 w-6 text-gray-400"} />
							) : (
								<div className="flex flex-col items-start justify-start gap-1">
									<div className="max-h-40 overflow-y-auto flex flex-col gap-1 flex-1 w-full">
										{tags.map((tag) => {
											return (
												<div className="flex gap-1 items-center" key={tag.properties.id}>
													<TrackingButton
														eventName="Tag toggled"
														className={clsx(
															"rounded-full w-4 h-4 flex items-center justify-center border hover:bg-indigo-50 hover:text-indigo-500",
															selectedTagIds.includes(tag.properties.id)
																? "border-emerald-500 bg-emerald-100"
																: "border-gray-400",
														)}
														onClick={() =>
															onTagSelectionChange(
																tag,
																!selectedTagIds.includes(tag.properties.id),
															)
														}
													>
														{selectedTagIds.includes(tag.properties.id) && (
															<CheckIcon
																className="h-2 w-2 text-emerald-600"
																aria-hidden="true"
															/>
														)}
													</TrackingButton>
													<TagTypeBadge
														onClick={() =>
															onTagSelectionChange(
																tag,
																!selectedTagIds.includes(tag.properties.id),
															)
														}
														tagType={tag}
													/>
												</div>
											)
										})}
									</div>

									{onSave && (
										<div className="flex items-center justify-end flex-1 w-full mt-2">
											<Button
												eventName="Save tags"
												className="w-full justify-center"
												variant="default"
												disabled={isSaveDisabled || isSaving}
												onClick={() => onSave(close)}
											>
												{isSaving ? <SpinnerIcon className="h-4 w-4" /> : t("Save")}
											</Button>
										</div>
									)}
								</div>
							)}
						</div>
					</>
				)}
			</PopoverPanel>
		</Popover>
	)
}
