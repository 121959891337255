import React from "react"

import type { TrainingSectionContentType } from "../../../../../../core/application/gateways/training.gateway/schemas/training-program-edit-view-model.schema"
import { Modal } from "../../../../../components/design-system/Modal.component"
import { useLanguage } from "../../../../../contexts/language.context"
import { AddContentSection } from "./AddContentSection.component"

type AddContentModalProps = {
	open: boolean
	onContentSelect: (contentType: TrainingSectionContentType) => void
	onClose: () => void
}

export function AddContentModal({ open, onContentSelect, onClose }: AddContentModalProps) {
	const { t } = useLanguage()
	return (
		<Modal open={open} onClose={onClose}>
			<p className="text-lg font-semibold text-navy-900 mb-6">{t("Add content")}</p>
			<AddContentSection onContentSelect={onContentSelect} />
		</Modal>
	)
}
