import { ExclamationCircleIcon } from "@heroicons/react/24/solid"
import assertNever from "assert-never"
import React, { useState } from "react"

import type { NotBriefableReason } from "../../../../../core/domain/CalendarEvent.entity/BriefableState"
import type { CalendarEvent } from "../../../../../core/domain/CalendarEvent.entity/CalendarEvent.entity"
import { Button } from "../../../../components/design-system/Button.component"
import { Input } from "../../../../components/design-system/Input"
import { Tooltip } from "../../../../components/design-system/Tooltip.component"
import { useAnalytics } from "../../../../contexts/analytics.context"
import { useCalendarContext } from "../../../../contexts/calendar.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { type TranslationKey, useLanguage } from "../../../../contexts/language.context"
import { DateProvider } from "../../../../utils/time"

type EventBriefStatusProps = {
	calendarEvent: CalendarEvent
	onOpenBriefingModal: (calendarEventId: string) => void
}

function notBriefableReasonToTranslationKey(reason: NotBriefableReason): TranslationKey {
	switch (reason.code) {
		case "no-external-attendees":
			return "This meeting does not have any external attendees"
		case "event-has-started":
			return "This meeting has started"
		case "unknown-inviter":
			return "This meeting was created by an unknown user"
		case "not-enough-context":
			return "This meeting does not have enough context to generate a briefing"
		default:
			assertNever(reason)
	}
}

export function EventBriefStatus({ calendarEvent, onOpenBriefingModal }: EventBriefStatusProps) {
	const { t } = useLanguage()
	const dateProvider = new DateProvider()
	const now = dateProvider.now()
	const eventHasStarted = now > calendarEvent.startTime
	const { calendarEventsGateway } = useDependencies()
	const { fetchEvents } = useCalendarContext()
	const [isLoading, setIsLoading] = useState(false)
	const { setupEventTracking } = useAnalytics()

	const handleOpenBriefingModal = () => {
		onOpenBriefingModal(calendarEvent.id)
	}

	const handleToggleBriefing = React.useCallback(async () => {
		if (!calendarEvent._briefing || calendarEvent._briefing.type !== "briefable") {
			return
		}

		try {
			const isBriefEnabled = calendarEvent._briefing.toggled
			const newBriefingStatus = !isBriefEnabled
			const { trackEvent } = setupEventTracking({
				eventName: "Brief status toggled",
				eventProperties: {
					calendarEventId: calendarEvent.id,
					newValue: newBriefingStatus ? "on" : "off",
				},
			})

			trackEvent()
			setIsLoading(true)
			await calendarEventsGateway.toggleBriefing(calendarEvent.id, newBriefingStatus)
			await fetchEvents("Briefing status was toggled")
		} catch (e) {
			console.error("Failed to toggle briefing status", e)
		} finally {
			setIsLoading(false)
		}
	}, [calendarEvent._briefing, calendarEvent.id, calendarEventsGateway, fetchEvents, setupEventTracking])

	if (!calendarEvent._briefing) {
		return null
	}

	if (calendarEvent._briefing.type === "available") {
		return (
			<Tooltip content={t("View brief")}>
				<Button
					eventName={"Open briefing"}
					eventProperties={{ calendarEventId: calendarEvent.id }}
					onClick={handleOpenBriefingModal}
					size="xs"
					variant="success"
				>
					{t("Brief")}
				</Button>
			</Tooltip>
		)
	}

	if (eventHasStarted) {
		return (
			<Tooltip content={t("This meeting has started")}>
				<ExclamationCircleIcon className="h-4 w-4 text-gray-300" />
			</Tooltip>
		)
	}

	if (calendarEvent._briefing.type !== "briefable") {
		return (
			<Tooltip content={t(notBriefableReasonToTranslationKey(calendarEvent._briefing.reason))}>
				<ExclamationCircleIcon className="h-4 w-4 text-gray-300" />
			</Tooltip>
		)
	}

	const isBriefEnabled = calendarEvent._briefing.toggled
	return (
		<Tooltip content={t(isBriefEnabled ? "Do not brief this meeting" : "Brief this meeting")}>
			<Input.Toggle
				value={isLoading ? !isBriefEnabled : isBriefEnabled}
				onChange={handleToggleBriefing}
				size="small"
				disabled={isLoading}
			/>
		</Tooltip>
	)
}
