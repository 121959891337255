import React from "react"

import type { Relationship } from "../../core/application/gateways/relationships.gateway"
import { useQueryParamsState } from "../hooks/useQueryParamsState"
import { useAnalytics } from "./analytics.context"
import { useDependencies } from "./dependencies.context"

type IRelationshipsContext = {
	showRelationshipDetailsById: (relationshipId: string, tabToOpen?: "risks" | "call-activity" | null) => void
	hideRelationshipDetails: () => void
	currentRelationship: Relationship | null
	isLoading: boolean
	defaultTabToOpen: "risks" | "call-activity" | null
}

const RelationshipsContext = React.createContext<IRelationshipsContext | undefined>(undefined)

export function RelationshipsProvider({ children }: { children: React.ReactNode }) {
	const [currentRelationship, setCurrentRelationship] = React.useState<Relationship | null>(null)
	const [isLoading, setIsLoading] = React.useState(false)
	const [relationshipId, setRelationshipId] = useQueryParamsState<string | null>("relationship-id", null)
	const [defaultTabToOpen, setDefaultTabToOpen] = React.useState<"risks" | "call-activity" | null>(null)
	const { setupEventTracking } = useAnalytics()

	const { relationshipsGateway } = useDependencies()

	React.useEffect(() => {
		if (!relationshipId) {
			return
		}

		setIsLoading(true)
		relationshipsGateway
			.getRelationshipById(relationshipId)
			.then((r) => setCurrentRelationship(r))
			.finally(() => setIsLoading(false))
	}, [relationshipId, relationshipsGateway])

	const hideRelationshipDetails = React.useCallback(() => {
		setIsLoading(false)
		setCurrentRelationship(null)
		setRelationshipId(null)
	}, [setRelationshipId])

	const showRelationshipDetailsById = React.useCallback(
		(newRelationshipId: string, tabToOpen: "risks" | "call-activity" | null = null) => {
			setRelationshipId(newRelationshipId)
			setDefaultTabToOpen(tabToOpen)
			setupEventTracking({
				eventName: "Relationship details opened",
				eventProperties: {
					relationshipId,
					clickedTab: tabToOpen,
				},
			}).trackEvent()
		},
		[relationshipId, setRelationshipId, setupEventTracking],
	)

	return (
		<RelationshipsContext.Provider
			value={{
				showRelationshipDetailsById,
				currentRelationship,
				isLoading,
				hideRelationshipDetails,
				defaultTabToOpen,
			}}
		>
			{children}
		</RelationshipsContext.Provider>
	)
}

export function useRelationshipsContext() {
	const context = React.useContext(RelationshipsContext)
	if (!context) {
		throw new Error("useRelationshipsContext must be used within a RelationshipsProvider")
	}
	return context
}
