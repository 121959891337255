import type {
	CreateFolderParams,
	CreateHighlightParams,
	ILibraryGateway,
	ReactToHighlightParams,
	UpdateHighlightParams,
} from "../../../application/gateways/library.gateway"
import type { Call } from "../../../domain/Call.entity"
import type { LibraryFolder } from "../../../domain/Library.entity"
import { type CallHighlight, Library } from "../../../domain/Library.entity"
import { apiCallHighlightsSchema } from "./apiCallHighlights.schema"
import {
	getCoachingSessionsReponseSchema,
	type GetCoachingSessionsResponse,
	type GetUnseenCoachingSessionItemsResponse,
	getUnseenCoachingSessionItemsResponseSchema,
} from "./getCoachingSessionsResponse.schema"
import { listLibraryFoldersResponseSchema } from "./listLibraryFoldersResponse.schema"

export class HttpLibraryGateway implements ILibraryGateway {
	constructor(private readonly baseApiUrl: string) {}

	//////////////////////////
	// Library Folders
	//////////////////////////

	public async get(): Promise<Library> {
		const endpointUrl = `${this.baseApiUrl}/library/folders`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		const libraryFolders = listLibraryFoldersResponseSchema.parse(json)
		return Library.create(libraryFolders)
	}

	public async createFolder(params: CreateFolderParams): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/library/folders/create`

		const res = await fetch(endpointUrl, {
			method: "POST",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to create folder")
		}
	}

	public async editFolder(editedFolder: LibraryFolder): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/library/folders/${editedFolder.id}`

		const res = await fetch(endpointUrl, {
			method: "PUT",
			body: JSON.stringify({
				newName: editedFolder.name,
				newParentLibraryFolderId: editedFolder.parent?.id,
			}),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to edit folder")
		}
	}

	public async deleteFolder(toBeDeletedFolder: LibraryFolder): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/library/folders/${toBeDeletedFolder.id}`

		const res = await fetch(endpointUrl, {
			method: "DELETE",
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to delete folder")
		}
	}

	//////////////////////////
	// Call Highlights
	//////////////////////////

	public async getFolderHighlights(libraryFolder: LibraryFolder): Promise<CallHighlight[]> {
		const endpointUrl = `${this.baseApiUrl}/library/highlights/by-folder/${libraryFolder.id}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		const callHighlights = apiCallHighlightsSchema.parse(json)
		return callHighlights
	}

	public async getCallHighlights(call: Call): Promise<CallHighlight[]> {
		const endpointUrl = `${this.baseApiUrl}/library/highlights/by-call/${call.props.id}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		const callHighlights = apiCallHighlightsSchema.parse(json)
		return callHighlights
	}

	public async createHighlight(params: CreateHighlightParams): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/library/highlights/create`

		const res = await fetch(endpointUrl, {
			method: "POST",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to create highlight")
		}
	}

	public async updateHighlight(callHighlightId: string, updateHighlightParams: UpdateHighlightParams): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/library/highlights/${callHighlightId}`

		const res = await fetch(endpointUrl, {
			method: "PATCH",
			body: JSON.stringify(updateHighlightParams),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to update highlight")
		}
	}

	public async deleteHighlight(highlight: CallHighlight): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/library/highlights/${highlight.id}`

		const res = await fetch(endpointUrl, {
			method: "DELETE",
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to delete highlight")
		}
	}

	public async reactToHighlight(params: ReactToHighlightParams): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/library/highlights/react`

		const res = await fetch(endpointUrl, {
			method: "POST",
			body: JSON.stringify(params),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to react to highlight")
		}
	}

	//////////////////////////
	// Training
	//////////////////////////

	public async getCoachingSessions(): Promise<GetCoachingSessionsResponse> {
		// return placeholderCoachingSessions
		const endpointUrl = `${this.baseApiUrl}/library/training/coaching-sessions`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		const response = getCoachingSessionsReponseSchema.parse(json)
		return response
	}

	public async getUnseenCoachingSessionItems(): Promise<GetUnseenCoachingSessionItemsResponse> {
		const endpointUrl = `${this.baseApiUrl}/library/training/coaching-sessions/unseen-items`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		const response = getUnseenCoachingSessionItemsResponseSchema.parse(json)
		return response
	}

	public async markHighlightAsSeen(highlight: CallHighlight): Promise<void> {
		const endpointUrl = `${this.baseApiUrl}/library/training/highlights/seen`

		const res = await fetch(endpointUrl, {
			method: "PUT",
			body: JSON.stringify({ highlightId: highlight.id }),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})

		if (!res.ok) {
			throw new Error("Failed to mark highlight as seen")
		}
	}
}
