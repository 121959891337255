import { PlayIcon } from "@heroicons/react/24/outline"

import type { Call } from "../../../core/domain/Call.entity"
import { type SeekPlayerToTrackingComponent, useCallVideoPlayer } from "../../contexts/callVideoPlayer.context"
import { Duration } from "../design-system/Duration.component"
import { TrackingButton } from "../design-system/TrackingButton.component"

export type TranscriptionQuoteProps = {
	/** Quote might be empty string if LLM found nothing to quote */
	rawQuote: string
	call: Call
	trackingComponent: SeekPlayerToTrackingComponent
}

export function TranscriptionQuote({ rawQuote, call, trackingComponent }: TranscriptionQuoteProps) {
	const { seekPlayerTo } = useCallVideoPlayer()
	const handleSeekPlayerTo = (seconds: number) => {
		seekPlayerTo(seconds, trackingComponent)
	}

	const quoteContent = removeMetadataFromQuote(rawQuote)
	const turn = quoteContent
		? call.props.transcription?.turns.find((turn) =>
				turn.content.toLowerCase().includes(quoteContent.toLowerCase()),
		  )
		: undefined

	if (!turn) {
		return null
	}

	return (
		<div className="mt-2 rounded-r-md bg-gray-100 text-gray-800 px-2 py-2 text-sm border-l-2 border-gray-400">
			<div className="text-sm font-medium text-gray-900 flex items-center">
				<TrackingButton
					onClick={() => handleSeekPlayerTo(turn.startTimeSec)}
					className="bg-gray-100 text-indigo-600 p-1.5 rounded-sm inline-flex text-xs mr-2 gap-1.5"
					eventName="Seek video player to transcription quote"
					eventProperties={{
						seekPlayerToSeconds: turn.startTimeSec,
					}}
				>
					<PlayIcon className="w-4 h-4" />
					<span>
						<Duration className="cursor-pointer" seconds={turn.startTimeSec} /> -{" "}
						<Duration seconds={turn.endTimeSec} />
					</span>
				</TrackingButton>
				{turn.attendeeLabel}
			</div>
			{quoteContent}
		</div>
	)
}

// assumes the metadata is enclosed in square brackets and followed by a space
// example: "[0.0 - Speaker 1] Hello world" => "Hello world"
function removeMetadataFromQuote(rawQuote: string): string {
	const metadataPattern = /\[\d+\.\d+ - [^\]]+\]\s*/
	return rawQuote.replace(metadataPattern, "")
}
