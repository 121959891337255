import React from "react"

import type { Relationship, RelationshipContact } from "../../../../../core/application/gateways/relationships.gateway"
import { useLanguage } from "../../../../contexts/language.context"
import { TrackingExternalHref } from "../../../design-system/TrackingExternalHref.component"
import { DecisionMakingAuthorityBadge } from "../../DecisionMakingAuthorityBadge"
import { PanelEntry } from "./PanelEntry.component"

type RelationshipInfoPanelProps = {
	relationship: Relationship
}

function ContactEntry({ contact }: { contact: RelationshipContact }) {
	const contactUrl = contact.enrichment?.linkedin?.profile?.publicIdentifier
		? `https://linkedin.com/in/${contact.enrichment.linkedin.profile.publicIdentifier}`
		: null
	const decisionMakingAuthority = contact.leadKnowledge?.decisionMakingAuthority
	const email = contact.email ?? contact.enrichment?.email
	const role = contact.enrichment?.linkedin?.profile?.headline
	const fullName = contact.firstName && contact.lastName ? `${contact.firstName} ${contact.lastName}` : null

	return (
		<div>
			<div className="flex gap-2">
				{contactUrl ? (
					<TrackingExternalHref
						eventName="Relationship side panel: contact profile click"
						eventProperties={{ contactId: contact.id, url: contactUrl }}
						className="text-md text-gray-900"
						href={contactUrl}
					>
						{fullName ?? email}
					</TrackingExternalHref>
				) : (
					<h2 className="text-md font-semibold text-gray-900">{fullName ?? email}</h2>
				)}
				{decisionMakingAuthority && (
					<DecisionMakingAuthorityBadge decisionMakingAuthority={decisionMakingAuthority} />
				)}
			</div>

			{role && <p className="text-sm text-gray-900">{role}</p>}
			{email && <p className="text-sm text-gray-900">{email}</p>}
		</div>
	)
}

export function RelationshipInfoPanel({ relationship }: RelationshipInfoPanelProps) {
	const { t } = useLanguage()

	return (
		<div>
			{relationship.account?.enrichment?.businessModel && (
				<div className="border-l-2 border-gray-500 bg-gray-50 p-4 mt-4">
					<div className="flex">
						<div className="ml-3">
							<p className="text-sm text-gray-900">{relationship.account.enrichment.businessModel}</p>
						</div>
					</div>
				</div>
			)}
			<div className="grid grid-cols-1 gap-8 mt-4">
				{relationship.account?.enrichment && (
					<div>
						<h2 className="text-xl font-semibold text-gray-900 mb-2">{t("Company")}</h2>
						<div className="flex flex-col gap-4">
							<PanelEntry
								title="Website"
								value={
									relationship.account?.enrichment?.websiteUrl ? (
										<TrackingExternalHref
											eventName="Relationship side panel: company website click"
											className="text-sm text-gray-900 truncate"
											href={relationship.account.enrichment.websiteUrl}
										>
											{relationship.account?.enrichment?.websiteUrl}
										</TrackingExternalHref>
									) : null
								}
							/>
							<PanelEntry
								title="LinkedIn"
								value={
									relationship.account?.enrichment?.linkedinCompanyUrl ? (
										<TrackingExternalHref
											eventName="Relationship side panel: company website click"
											className="text-sm text-gray-900 truncate"
											href={relationship.account.enrichment.linkedinCompanyUrl}
										>
											{relationship.account?.enrichment?.linkedinCompanyUrl}
										</TrackingExternalHref>
									) : null
								}
							/>
							<PanelEntry title="Industry" value={relationship.account?.enrichment?.industry} />
							<PanelEntry
								title="Employee count"
								value={relationship.account?.enrichment?.employeeCount}
							/>
							<PanelEntry
								title="Annual revenue"
								value={relationship.account?.enrichment?.annualRevenue}
							/>
						</div>
					</div>
				)}

				{relationship.contacts && relationship.contacts.length > 0 && (
					<div>
						<h2 className="text-xl font-semibold text-gray-900 mb-2">{t("Contacts")}</h2>
						<div className="flex flex-col gap-4">
							{relationship.contacts?.map((contact) => (
								<ContactEntry contact={contact} key={contact.id} />
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
