import { z } from "zod"

import { makePublicSharedCallPath } from "../router/Router"

export type CallTimeRange = { startSec: number; endSec: number }

const publicCallMetadata = z
	.object({
		publicAccessToken: z.string(),
		allowedTimeRanges: z.array(z.array(z.number()).length(2)).nullable().optional(),
	})
	.strict()

type PublicCallMetadata = z.infer<typeof publicCallMetadata>

function validateTimeRanges(ranges: Array<number[]>) {
	const flattenedRange = ranges.reduce((acc, val) => acc.concat(val), [])
	// make sure that every element is positive and greater or equal to the previous one
	const isValid = flattenedRange.every((val, index) => val >= 0 && (index === 0 || val >= flattenedRange[index - 1]))
	return isValid ? ranges : null
}

export function extractTokenFromUrlParam(urlParam: string | undefined): {
	publicAccessToken: string
	allowedTimeRanges: Array<number[]> | null
} {
	if (!urlParam) {
		throw new Error("Invalid URL parameter")
	}

	try {
		const parseResult = publicCallMetadata.parse(JSON.parse(atob(urlParam)))
		return {
			publicAccessToken: parseResult.publicAccessToken,
			allowedTimeRanges: parseResult.allowedTimeRanges ? validateTimeRanges(parseResult.allowedTimeRanges) : null,
		}
	} catch (e) {
		return { publicAccessToken: urlParam, allowedTimeRanges: null }
	}
}

export function makePublicSharedCallUrl(publicAccessToken: string, allowedTimeRanges: CallTimeRange[] | null) {
	const formattedTimeRange: PublicCallMetadata["allowedTimeRanges"] = allowedTimeRanges?.map((range) => [
		Math.round(range.startSec),
		Math.round(range.endSec),
	])

	const shareCallToken = formattedTimeRange
		? btoa(
				JSON.stringify({
					publicAccessToken,
					allowedTimeRanges: formattedTimeRange,
				}),
		  )
		: publicAccessToken

	return `${location.protocol}//${document.location.host}${makePublicSharedCallPath(shareCallToken)}`
}
