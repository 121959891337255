import _ from "lodash"
import type { Dispatch, SetStateAction } from "react"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

type UseQueryParamsStateReturnType<T> = [T, Dispatch<SetStateAction<T>>]

export const useQueryParamsState = <T>(
	param: string,
	initialState: T | (() => T),
): UseQueryParamsStateReturnType<T> => {
	const location = useLocation()
	const initialStateValue = typeof initialState === "function" ? (initialState as () => T)() : initialState

	// State for managing the value derived from the query parameter
	const [value, setValue] = useState<T>(() => {
		if (typeof window === "undefined") return initialStateValue

		// Parse query parameter value from the URL
		const { search } = window.location
		const searchParams = new URLSearchParams(search)
		const paramValue = searchParams.get(param)

		return paramValue !== null ? (tryParseJSON(paramValue) as T) : initialStateValue
	})

	useEffect(() => {
		const currentSearchParams = new URLSearchParams(window.location.search)

		// Check if the current query param value is different from the initialState
		const currentParamValue = currentSearchParams.get(param)
		const isAtInitialState = _.isEqual(value, initialStateValue)
		const paramExists = currentParamValue !== null

		// If we're at the initial state or value is empty/null, remove the param if it exists
		if (isAtInitialState || value === null || value === "") {
			if (paramExists) {
				currentSearchParams.delete(param)
			}
		} else {
			// If we're not at the initial state, set the param in the URL
			if (typeof value === "object") {
				currentSearchParams.set(param, JSON.stringify(value))
			} else {
				currentSearchParams.set(param, String(value))
			}
		}

		// Build the new URL with the updated search parameters
		const newSearchParams = currentSearchParams.toString()
		const newUrl = `${window.location.pathname}${newSearchParams ? `?${newSearchParams}` : ""}`

		// Update the browser's history without triggering a page reload
		if (newUrl !== window.location.href) {
			window.history.replaceState(window.history.state, "", newUrl)
		}
	}, [param, value, location.pathname, initialStateValue])

	return [value, setValue]
}

// Helper function to safely parse JSON
function tryParseJSON<T>(value: string): T | string {
	try {
		return JSON.parse(value)
	} catch {
		return value
	}
}
