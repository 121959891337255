export function roundToTwoDecimals(num: number): number {
	return Math.round((num + Number.EPSILON) * 100) / 100
}

export function secondsToMinutes(seconds: number) {
	const minutes = Math.floor(seconds / 60)
	const remainingSeconds = Math.floor(seconds % 60)
	return {
		minutes,
		remainingSeconds,
	}
}
