import React from "react"

import type { GetDealsForecastsAggregateRouteResponse } from "../../../../../core/application/gateways/deals.gateway/schemas/forecast"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { ContactUsOverlay } from "./components/ContactUsOverlay.component"
import { ForecastBoost } from "./ForecastBoost.component"
import { ForecastFilters } from "./ForecastFilters.component"
import { ForecastTable } from "./ForecastTable.component"
import { ForecastVersusActual } from "./ForecastVersusActual.component"
import { useForecast } from "./useForecast"

export function ForecastTabView() {
	let displayOverlay = false
	const { data, timePeriod, setTimePeriod } = useForecast()

	let dataToDisplay = data.data

	if (data.error) {
		switch (data.error.type) {
			case "fetching":
				return (
					<div className="flex items-center justify-center flex-1">
						<SpinnerIcon className="h-8 max-h-8 max-w-8 w-8 mr-2 flex-initial" />
					</div>
				)

			case "requires-enterprise-plan":
			case "requires-crm-connection":
			case "requires-forecasting-feature":
				displayOverlay = true
				dataToDisplay = DEMO_PLACEHOLDER
				break

			case "fetch-error":
			default:
				return (
					<div className="flex items-center justify-center flex-1">
						<span>An error happened.</span>
					</div>
				)
		}
	}

	return (
		<div>
			<div className="flex flex-row-reverse mt-4">
				<ForecastFilters timePeriod={timePeriod} setTimePeriod={setTimePeriod} />
			</div>
			{displayOverlay && <ContactUsOverlay />}
			<div className="flex-1 relative flex flex-col mt-4 h-full">
				<div className="flex flex-1 flex-col xl:flex-row space-y-2 xl:space-y-0 xl:space-x-2">
					<div className="w-full xl:w-3/5 xl:h-auto relative">
						{/**
						 * The 'absolute' positioning here allows the ForecastTable to overflow beyond
						 * the bottom of its container without causing the parent div to expand.
						 * This prevents the "right div" from growing further, keeping the layout intact.
						 */}
						<div className="xl:absolute top-0 left-0 right-0">
							<ForecastTable data={dataToDisplay} />
						</div>
					</div>

					<div className="w-full xl:w-2/5 xl:h-auto flex flex-col space-y-2">
						<div className="flex-[5]">
							<ForecastVersusActual />
						</div>
						<div className="flex-[3]">
							<ForecastBoost />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const DEMO_PLACEHOLDER: GetDealsForecastsAggregateRouteResponse["data"] = [
	{
		opportunityOwner: { crmId: "", firstName: "Antoine", lastName: "Dupont" },
		aggregate: {
			amountsInCents: {
				closedAndWon: 9354370,
				willClose: 9869090,
				commit: 11103950,
				mostLikely: 12014150,
				bestCase: 13412630,
			},
		},
	},
	{
		opportunityOwner: { crmId: "", firstName: "Julien", lastName: "Lefevre" },
		aggregate: {
			amountsInCents: {
				closedAndWon: 4018000,
				willClose: 4754100,
				commit: 6161470,
				mostLikely: 6770000,
				bestCase: 7945840,
			},
		},
	},
	{
		opportunityOwner: { crmId: "", firstName: "Marie", lastName: "Dubois" },
		aggregate: {
			amountsInCents: {
				closedAndWon: 9188330,
				willClose: 10110680,
				commit: 10949230,
				mostLikely: 11809500,
				bestCase: 12632460,
			},
		},
	},
	{
		opportunityOwner: { crmId: "", firstName: "Benoît", lastName: "Martinez" },
		aggregate: {
			amountsInCents: {
				closedAndWon: 4409970,
				willClose: 5447050,
				commit: 6416440,
				mostLikely: 7646540,
				bestCase: 8498910,
			},
		},
	},
	{
		opportunityOwner: { crmId: "", firstName: "Claire", lastName: "Bernard" },
		aggregate: {
			amountsInCents: {
				closedAndWon: 8164500,
				willClose: 9286190,
				commit: 9843030,
				mostLikely: 11047510,
				bestCase: 12233060,
			},
		},
	},
	{
		opportunityOwner: { crmId: "", firstName: "Samantha", lastName: "Adamski" },
		aggregate: {
			amountsInCents: {
				closedAndWon: 4739920,
				willClose: 5589250,
				commit: 6941660,
				mostLikely: 7579900,
				bestCase: 8879590,
			},
		},
	},
]
