import clsx from "clsx"
import React from "react"

type CallTimelineThumbnailProps = {
	imageUrl: string
	currentTimeSeconds: number
	className?: string
}

function useImageWidth(imageUrl: string): [number, number, React.RefObject<HTMLImageElement>] {
	const imgRef = React.useRef<HTMLImageElement>(null)
	const [imageWidth, setImageWidth] = React.useState<number>(0)
	const [imageHeight, setImageHeight] = React.useState<number>(0)

	React.useEffect(() => {
		const imgElement = new Image()
		imgElement.src = imageUrl

		const handleLoad = () => {
			setImageWidth(imgElement.naturalWidth)
			setImageHeight(imgElement.naturalHeight)
		}

		if (imgElement.complete) {
			// If the image is already loaded (from cache)
			handleLoad()
		} else {
			imgElement.addEventListener("load", handleLoad)
		}

		return () => {
			imgElement.removeEventListener("load", handleLoad)
		}
	}, [imageUrl])

	return [imageWidth, imageHeight, imgRef]
}

export function CallTimelineThumbnail({ imageUrl, currentTimeSeconds, className }: CallTimelineThumbnailProps) {
	const [_, imageHeight] = useImageWidth(imageUrl)
	const framePerSecond = 15 // hardcoded for now, see FfmpegScreenshotExtractionProvider in backend
	const singleFrameWidth = 160 // hardcoded for now, see FfmpegScreenshotExtractionProvider in backend
	const closestFrame = Math.floor(currentTimeSeconds / framePerSecond)

	const xStart = Math.floor(closestFrame * singleFrameWidth)
	const xEnd = Math.floor((closestFrame + 1) * singleFrameWidth)

	const cropWidth = xEnd - xStart

	return (
		<div
			style={{
				width: `${cropWidth}px`,
				height: `${imageHeight}px`,
				top: `-${imageHeight}px`,
			}}
			className={clsx("overflow-hidden absolute left-0 rounded-md opacity-90", className)}
		>
			<img
				src={imageUrl}
				alt="Cropped"
				className="object-none w-auto"
				style={{
					objectPosition: `-${xStart}px 0`,
					height: `${imageHeight}px`,
				}}
			/>
		</div>
	)
}
