import type { EditTagTypePayload, ITagTypesGateway } from "../../application/gateways/tag-types.gateway"
import { TagType, tagTypesSchema } from "../../domain/TagType.entity"

export class HttpTagTypesGateway implements ITagTypesGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async listTagTypes() {
		const endpointUrl = `${this.baseApiUrl}/tag-types`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		const tagTypes = tagTypesSchema.parse(json)

		return tagTypes.map((tagType) => TagType.fromProperties(tagType))
	}

	public async deleteTagType(tagType: TagType) {
		const endpointUrl = `${this.baseApiUrl}/tag-types/${tagType.properties.id}`
		await fetch(endpointUrl, {
			method: "DELETE",
			credentials: "include",
		})
	}

	public async createTagType(payload: {
		name: string
		description: string
		color: string
		scope: "call" | "excerpt"
	}) {
		const endpointUrl = `${this.baseApiUrl}/tag-types`
		await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload),
		})
	}

	public async editTagType(tagType: TagType, payload: EditTagTypePayload) {
		const endpointUrl = `${this.baseApiUrl}/tag-types/${tagType.properties.id}`
		await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload),
		})
	}
}
