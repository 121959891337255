import { z } from "zod"

import type {
	CalendarAndEvents,
	ICalendarEventListQuery,
	ICalendarEventsGateway,
	RemoveCalendarEventBotFromCallResponse,
} from "../../application/gateways/calendar-events.gateway"
import { RelationshipSchema } from "../../application/gateways/relationships.gateway"
import { calendarEventBriefSchema } from "../../domain/CalendarEvent.entity/CalendarEventBrief"
import { RecordingStatus } from "../../domain/CalendarEvent.entity/RecordingStatus"
import type { PublicCalendarEvent } from "../../domain/PublicCalendarEvent.entity"
import { ISODateTimeSchema } from "./ISODateTime"

const NotBriefableReasonSchema = z.union([
	z.object({ code: z.literal("no-external-attendees") }),
	z.object({ code: z.literal("unknown-inviter") }),
	z.object({ code: z.literal("event-has-started") }),
	z.object({ code: z.literal("not-enough-context") }),
])

const BriefableStateSchema = z.union([
	z.object({ type: z.literal("non-briefable"), reason: NotBriefableReasonSchema }),
	z.object({ type: z.literal("briefable"), toggled: z.literal(false) }),
	z.object({ type: z.literal("briefable"), toggled: z.literal(true) }),
	z.object({ type: z.literal("available"), briefing: calendarEventBriefSchema }),
])

const NotRecordableReasonSchema = z.union([
	z.object({ code: z.literal("no-meeting-url") }),
	z.object({ code: z.literal("unsupported-meeting-platform"), meetingPlatform: z.string() }),
	z.object({ code: z.literal("unknown-inviter") }),
	z.object({ code: z.literal("already-has-spawned-bot") }),
	z.object({ code: z.literal("event-has-ended") }),
])

const RecordableStateSchema = z.union([
	z.object({ type: z.literal("non-recordable"), reason: NotRecordableReasonSchema }),
	z.object({ type: z.literal("recordable"), toggled: z.literal(false), reason: z.object({}) }), // reason omitted because unused in frontend
	z.object({ type: z.literal("recordable"), toggled: z.literal(true) }),
])

const HttpPresentedBotStatusSchema = z.object({
	code: z.nativeEnum(RecordingStatus),
	canSendBotToMeetingNow: z.boolean(),
	botWillJoinAt: ISODateTimeSchema.optional(),
	callId: z.string().optional(),
})

const HttpPresentedCalendarEventBaseSchema = z.object({
	id: z.string(),
	startTime: ISODateTimeSchema,
	endTime: ISODateTimeSchema,
	title: z.string(),
	meetingUrl: z.string().optional(),
	botStatus: HttpPresentedBotStatusSchema,
	briefing: z
		.object({
			version: z.number(),
			result: z.string(),
		})
		.optional(),
	source: z.enum(["linked-calendar", "coach"]),
	relationship: RelationshipSchema.optional(),
	_briefing: BriefableStateSchema.optional(),
	_recording: RecordableStateSchema.optional(),
})

const HttpPresentedCalendarEventSchema = HttpPresentedCalendarEventBaseSchema.transform((out) => ({
	...out,
	meetingUrl: out.meetingUrl,
}))

const HttpPresentedPublicCalendarEventSchema = HttpPresentedCalendarEventBaseSchema.omit({
	botStatus: true,
}).transform((out) => ({
	...out,
	meetingUrl: out.meetingUrl,
}))

const CalendarPlatformSchema = z.enum(["google_calendar", "microsoft_outlook"])

const connectedSchema = z.object({
	status: z.literal("connected"),
	platform: CalendarPlatformSchema,
	wasEverSynced: z.boolean(),
})

const connectingSchema = z.object({
	status: z.literal("connecting"),
	platform: CalendarPlatformSchema,
})

const disconnectedSchema = z.object({
	status: z.literal("disconnected"),
	platform: CalendarPlatformSchema,
	microsoftOutlookOAuthUrl: z.string(),
	googleCalendarOAuthUrl: z.string(),
})

const notFoundSchema = z.object({
	status: z.literal("not-found"),
	microsoftOutlookOAuthUrl: z.string(),
	googleCalendarOAuthUrl: z.string(),
})

const ListCalendarEventsRouteResponseSchema = z.object({
	calendar: z.discriminatedUnion("status", [connectedSchema, connectingSchema, disconnectedSchema, notFoundSchema]),
	calendarEvents: z.array(HttpPresentedCalendarEventSchema),
})

const RemoveCalendarEventBotFromCallResponseSchema = z.discriminatedUnion("success", [
	z.object({
		success: z.literal(true),
		value: z.undefined().optional(),
	}),
	z.object({
		success: z.literal(false),
		reason: z.enum(["bot-not-started", "failure", "bot-not-found"]),
	}),
])

export class HttpCalendarEventsGateway implements ICalendarEventsGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async list(query: ICalendarEventListQuery): Promise<CalendarAndEvents> {
		// return _DUMMY_CALENDAR
		const queryParams = new URLSearchParams()
		if (query.filter) {
			queryParams.append("filter", query.filter)
		}

		const endpointUrl = `${this.baseApiUrl}/calendar/events?${queryParams}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()

		const result = ListCalendarEventsRouteResponseSchema.safeParse(json)
		if (!result.success) {
			console.error("Failed to list calendar events: ZodError:", result.error.issues)
			throw new Error("Failed to list calendar events")
		}

		return result.data
	}

	public async deleteCalendar(): Promise<void> {
		const res = await fetch(`${this.baseApiUrl}/calendar`, {
			method: "DELETE",
			credentials: "include",
		})
		if (!res.ok) {
			throw new Error("Failed to delete calendar")
		}
	}

	public async sendBotToMeetingNow(calendarEventId: string): Promise<void> {
		const res = await fetch(`${this.baseApiUrl}/calendar/events/${calendarEventId}`, {
			headers: {
				"Content-Type": "application/json",
			},
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ action: "send-bot-to-meeting-now" }),
		})
		if (!res.ok) {
			console.error("Failed to update bot")
			res.text().then(console.error)
			throw new Error("Failed to update bot")
		}
	}

	public async removeBotFromCall(calendarEventId: string): Promise<RemoveCalendarEventBotFromCallResponse> {
		const res = await fetch(`${this.baseApiUrl}/calendar/events/${calendarEventId}/bot`, {
			headers: {
				"Content-Type": "application/json",
			},
			method: "DELETE",
			credentials: "include",
		})
		if (!res.ok) {
			console.error("Failed to remove recorder from call")
			res.text().then(console.error)
			throw new Error("Failed to remove recorder from call")
		}
		const json = await res.json()
		return RemoveCalendarEventBotFromCallResponseSchema.parse(json)
	}

	public async getPublicCalendarEvent(calendarEventId: string, inviteToken: string): Promise<PublicCalendarEvent> {
		const endpointUrl = `${this.baseApiUrl}/share/calendar-events/${calendarEventId}?inviteToken=${inviteToken}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()

		return HttpPresentedPublicCalendarEventSchema.parse(json)
	}

	public async toggleBriefing(calendarEventId: string, enabled: boolean): Promise<void> {
		const res = await fetch(`${this.baseApiUrl}/calendar/events/${calendarEventId}/briefing`, {
			headers: {
				"Content-Type": "application/json",
			},
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ enabled }),
		})

		if (!res.ok) {
			console.error("Failed to enable or disable briefing")
			res.text().then(console.error)
			throw new Error("Failed to enable or disable briefing")
		}
	}

	public async toggleRecording(calendarEventId: string, enabled: boolean): Promise<void> {
		const res = await fetch(`${this.baseApiUrl}/calendar/events/${calendarEventId}/recording`, {
			headers: {
				"Content-Type": "application/json",
			},
			method: "POST",
			credentials: "include",
			body: JSON.stringify({ enabled }),
		})

		if (!res.ok) {
			console.error("Failed to enable or disable recording")
			res.text().then(console.error)
			throw new Error("Failed to enable or disable recording")
		}
	}
}

const _DUMMY_CALENDAR: CalendarAndEvents = {
	calendar: {
		status: "connected",
		platform: "google_calendar",
		wasEverSynced: true,
	},
	calendarEvents: [
		{
			id: "calendar_event_1",
			botStatus: {
				code: RecordingStatus.ScheduledBot,
				canSendBotToMeetingNow: true,
				botWillJoinAt: new Date(Date.now() + 1000 * 60 * 60),
			},
			title: "Dummy event 1",
			startTime: new Date(Date.now() + 1000 * 60 * 60),
			endTime: new Date(Date.now() + 1000 * 60 * 60 * 2),
			meetingUrl: "https://example.com",
		},
	],
}
