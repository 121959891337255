import { ArrowLeftIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import React from "react"
import { useMeasure } from "react-use"

import type { GetSalesRelationshipsResponse } from "../../../../core/application/gateways/relationships.gateway"
import type { Call } from "../../../../core/domain/Call.entity"
import { Tooltip } from "../../../components/design-system/Tooltip.component"
import { TrackingButton } from "../../../components/design-system/TrackingButton.component"
import { TrackingLink } from "../../../components/design-system/TrackingLink.component"
import { useLanguage } from "../../../contexts/language.context"
import { DateFormatter, DateProvider } from "../../../utils/time"

type CallActivityTimelineProps = {
	activities: GetSalesRelationshipsResponse["relationships"][0]["activities"]
	heightPx: number
	currentCall?: Call
	shouldOpenCallInNewTab?: boolean
	onSeePreviousCallsClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	periodToDisplay?: "30d" | "45d" | "90d"
	className?: string
}

function computePeriod(periodToDisplay: CallActivityTimelineProps["periodToDisplay"]): {
	startDate: Date
	endDate: Date
} {
	const dateProvider = new DateProvider()

	const startDate = dateProvider.now()
	const endDate = dateProvider.now()
	endDate.setDate(endDate.getDate() + 14)

	switch (periodToDisplay) {
		case "30d":
			startDate.setDate(startDate.getDate() - 15)
			break
		case "45d":
			startDate.setDate(startDate.getDate() - 45 - 15)
			break
		case "90d":
			startDate.setDate(startDate.getDate() - 90 - 15)
			break
	}

	return { startDate, endDate }
}

export function CallActivityTimeline({
	activities,
	heightPx,
	currentCall,
	shouldOpenCallInNewTab,
	onSeePreviousCallsClick,
	periodToDisplay,
	className,
}: CallActivityTimelineProps) {
	const [ref, { width }] = useMeasure<HTMLDivElement>()
	const dateProvider = new DateProvider()
	const { startDate, endDate } = computePeriod(periodToDisplay ?? "90d")
	const timelineWidthPx = width - 30
	const { t, language } = useLanguage()
	const dateFormatter = new DateFormatter(dateProvider, t)
	const [isHoveringOnNow, setIsHoveringOnNow] = React.useState(false)

	const now = dateProvider.now()

	const callsToDisplay = activities?.calls?.filter((call) => {
		const callDate = new Date(call.createdAt)
		return callDate >= startDate && callDate <= endDate
	})

	const upcomingCalendarEvents = activities?.calendarEvents?.filter((event) => {
		const eventDate = new Date(event.startTime)
		return eventDate >= now && eventDate <= endDate
	})

	const datesToDisplay: Date[] = []

	// Add the start date to the array
	datesToDisplay.push(startDate)

	const daysBetween = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)

	const interval = 14 // Every 2 weeks (14 days)

	for (let i = interval; i < daysBetween - interval; i += interval) {
		const date = new Date(startDate)
		date.setDate(date.getDate() + i)
		datesToDisplay.push(date)
	}

	// Add the end date to the array
	datesToDisplay.push(endDate)

	const notDisplayedCalls = activities?.calls?.filter((call) => !callsToDisplay?.includes(call))

	const nowDatePosition =
		((now.getTime() - startDate.getTime()) / (endDate.getTime() - startDate.getTime())) * timelineWidthPx + 8 // 8 is half of the width of a call circle

	return (
		<div
			className={clsx("h-14 relative", notDisplayedCalls?.length && "ml-4", className)}
			style={{ width: "100%", height: heightPx }}
			ref={ref}
		>
			<div
				className="h-[1px] bg-navy-500 opacity-60 absolute top-1/2 left-0 right-0 transform -translate-y-1/2"
				style={{ width: nowDatePosition }}
			/>
			<div
				className="h-[1px] border-dotted border-b-2 border-opacity-60 border-navy-500 opacity-60 absolute top-1/2 right-0 transform -translate-y-1/2"
				style={{ left: nowDatePosition }}
			/>
			<span
				className={clsx(
					"absolute transform top-0 text-xs text-gray-600 transition-all duration-200",
					isHoveringOnNow ? "opacity-100" : "opacity-0",
				)}
				style={{ left: nowDatePosition - 20 }}
			>
				{t("Today")}
			</span>
			<div
				className={clsx(
					"absolute transform top-0 text-xs text-gray-600 rounded-xl",
					isHoveringOnNow ? "opacity-50" : "opacity-30",
				)}
				style={{ left: nowDatePosition - 6, height: heightPx, width: 12 }}
				onMouseOver={() => setIsHoveringOnNow(true)}
				onMouseOut={() => setIsHoveringOnNow(false)}
			>
				<span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-indigo-500 h-[60%] w-[2px] rounded-lg" />
			</div>
			{notDisplayedCalls && notDisplayedCalls.length > 0 && (
				<Tooltip
					wrapperClassName="!absolute top-1/2 -left-[14px] transform -translate-y-1/2"
					tooltipClassName="!z-50"
					content={t("See {{count}} previous calls", { count: notDisplayedCalls.length })}
				>
					<TrackingButton
						eventName="Call activity: see previous calls clicked"
						onClick={onSeePreviousCallsClick}
					>
						<ArrowLeftIcon className=" text-navy-500 opacity-60 h-4 w-4" />
					</TrackingButton>
				</Tooltip>
			)}

			{datesToDisplay.map((date) => {
				if (timelineWidthPx <= 0) {
					return null
				}

				const datePosition =
					((date.getTime() - startDate.getTime()) / (endDate.getTime() - startDate.getTime())) *
					timelineWidthPx

				const formattedDate = dateFormatter.formatDate(date, language, {
					month: "2-digit",
					day: "2-digit",
				})

				const isDateInFuture = date.getTime() > now.getTime()

				return (
					<span
						key={date.getTime()}
						className={clsx(
							"absolute transform -translate-y-1/2 text-xs top-2 transition-all duration-200",
							isDateInFuture ? "text-gray-400" : "text-gray-600",
							isHoveringOnNow ? "opacity-30" : "opacity-100",
						)}
						style={{ left: datePosition }}
					>
						{formattedDate}
					</span>
				)
			})}

			{callsToDisplay?.map((activity) => {
				if (timelineWidthPx <= 0) {
					return null
				}

				const activityDate = new Date(activity.createdAt)
				const activityPosition =
					((activityDate.getTime() - startDate.getTime()) / (endDate.getTime() - startDate.getTime())) *
					timelineWidthPx

				return (
					<Tooltip
						content={`${activity.createdAt.toLocaleDateString()}: ${activity.name}`}
						wrapperClassName="absolute transform -translate-y-1/2 top-1/2 relative"
						tooltipClassName="!text-2xs !z-50 w-auto"
						key={activity.id}
						tooltipStyle={{ top: 8 }}
					>
						<TrackingLink
							to={`/calls/${activity.id}`}
							eventName={"Call activity: call clicked"}
							eventProperties={{ callId: activity.id }}
							target={shouldOpenCallInNewTab !== false ? "_blank" : undefined}
							className={clsx(
								"absolute transform -translate-y-1/2 top-1/2 w-4 h-4 border-2 rounded-full hover:bg-white",
								currentCall?.props.id === activity.id
									? "bg-indigo-500 border-indigo-500"
									: "bg-navy-500 border-navy-500",
							)}
							style={{ left: activityPosition }}
						/>
					</Tooltip>
				)
			})}

			{upcomingCalendarEvents?.map((calendarEvent) => {
				const activityDate = new Date(calendarEvent.startTime)
				const activityPosition =
					((activityDate.getTime() - startDate.getTime()) / (endDate.getTime() - startDate.getTime())) *
					timelineWidthPx

				return (
					<Tooltip
						content={`${calendarEvent.startTime.toLocaleDateString()}: ${calendarEvent.title}`}
						wrapperClassName="absolute transform -translate-y-1/2 top-1/2 relative"
						tooltipClassName="!text-2xs !z-50 w-auto"
						key={calendarEvent.id}
						tooltipStyle={{ top: 8 }}
					>
						<div
							key={calendarEvent.id}
							className="absolute transform -translate-y-1/2 top-1/2 w-4 h-4 border-2 border-navy-500 bg-white rounded-full hover:bg-gray-200"
							style={{ left: activityPosition }}
						/>
					</Tooltip>
				)
			})}
		</div>
	)
}
