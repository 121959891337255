import { XMarkIcon } from "@heroicons/react/20/solid"
import React from "react"

import type { User } from "../../../core/domain/User.entity"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useLanguage } from "../../contexts/language.context"
import { UserWorkspaceRoleDropdown } from "../../pages/Settings/Workspace/UsersSettings/UserWorkspaceRoleDropdown.component"
import { Alert } from "../design-system/Alert.component"
import { Button } from "../design-system/Button.component"
import { Input } from "../design-system/Input"

export type InviteListEntry = {
	email: string
	role: User["workspaceRole"]
}

type IInviteUserModalProps = {
	currentWorkspaceUsers: User[]
	inviteState: InviteState
	onInviteStateChange: (newState: InviteState) => void
	inviteList: InviteListEntry[]
	onInviteListChange: (newInviteList: Array<InviteListEntry>) => void
}

export type InviteState =
	| {
			status: "loading" | "done" | "idle"
	  }
	| {
			status: "error"
			error: Error
	  }

const defaultInvitedUserRole: User["workspaceRole"] = "user"

export function InviteUserForm({
	currentWorkspaceUsers,
	inviteState,
	onInviteStateChange,
	onInviteListChange,
	inviteList,
}: IInviteUserModalProps) {
	const { t } = useLanguage()
	const { workspace, subscription } = useAuthenticatedSession()

	const handleEmailChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
		const newInviteList = [...inviteList]
		newInviteList[index].email = event.target.value
		onInviteListChange(newInviteList)
		onInviteStateChange({ status: "idle" })
	}

	const handleRoleChange = (index: number) => (newRole: User["workspaceRole"]) => {
		const newInviteList = [...inviteList]
		newInviteList[index].role = newRole
		onInviteListChange(newInviteList)
		onInviteStateChange({ status: "idle" })
	}

	const handleAddInvite = () => {
		onInviteListChange([...inviteList, { email: "", role: defaultInvitedUserRole }])
	}

	const handleRemoveInvite = (index: number) => () => {
		const newInviteList = inviteList.filter((_, i) => i !== index)
		onInviteListChange(newInviteList)
	}

	const existingUsers = inviteList.map(({ email }) => currentWorkspaceUsers.find((x) => x.email === email))
	const isTrialing = workspace.isInTrial() || subscription?.isTrial()

	return (
		<>
			{!isTrialing && (
				<Alert
					className="mt-4"
					description={t("Note: You will be billed for the new user once they accept your invitation.")}
					variant="info"
				/>
			)}
			{inviteState.status === "done" && (
				<Alert description={t("Users invited successfully!")} variant="success" className="mt-4" />
			)}
			{inviteState.status === "error" && (
				<Alert description={t("Something went wrong")} variant="error" className="mt-4" />
			)}

			{inviteList.map((invite, index) => {
				const existingUser = existingUsers[index]
				return (
					<div key={index} className="flex-1 flex flex-col gap-2">
						<div className="flex gap-2 items-end">
							<div className="flex-[4]">
								<Input.Group>
									<Input.Label
										htmlFor={`email-${index}`}
										className="block text-sm font-medium leading-6 text-gray-900"
									>
										{t("Email")}
									</Input.Label>
									<Input.Input
										id={`email-${index}`}
										name={`email-${index}`}
										type="email"
										className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 read-only:bg-gray-50 read-only:text-gray-500 read-only:ring-gray-200 sm:text-sm sm:leading-6"
										minLength={2}
										value={invite.email}
										onChange={handleEmailChange(index)}
									/>
								</Input.Group>
							</div>
							<div className="flex-1">
								<Input.Label
									htmlFor={`role-${index}`}
									className="block text-sm font-medium leading-6 text-gray-900 mb-2"
								>
									{t("Role")}
								</Input.Label>
								<div className="flex items-center">
									<UserWorkspaceRoleDropdown role={invite.role} onChange={handleRoleChange(index)} />

									{index > 0 ? (
										<div className="flex items-center justify-center ml-2">
											<button
												type="button"
												onClick={handleRemoveInvite(index)}
												className="text-red-500 hover:text-red-700"
											>
												<XMarkIcon className="h-5 w-5" />
											</button>
										</div>
									) : (
										<div className="w-7" />
									)}
								</div>
							</div>
						</div>
						{existingUser && (
							<Alert
								description={t("A user with this email already exists in your workspace")}
								variant="warning"
								className="mt-2"
							/>
						)}
					</div>
				)
			})}

			{inviteList.length >= 1 && inviteList[0].email !== "" && (
				<Button
					variant="text"
					eventName="Invite user modal: add another user clicked"
					type="button"
					onClick={handleAddInvite}
					className="justify-center text-sm text-indigo-600 hover:text-indigo-500"
				>
					{t("Add another user")}
				</Button>
			)}
		</>
	)
}
