import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"

import { type AnalyticsProps, useAnalytics } from "../../contexts/analytics.context"

export type TrackingExternalHrefProps = {
	href: string
	children?: React.ReactNode
	className?: string
	displayExternalIcon?: boolean
} & React.AnchorHTMLAttributes<HTMLAnchorElement> &
	AnalyticsProps

export function TrackingExternalHref({
	eventName,
	eventProperties,
	href,
	children,
	className,
	displayExternalIcon = true,
	...props
}: TrackingExternalHrefProps) {
	const { setupEventTracking } = useAnalytics()

	const { trackEvent: trackExternalHrefUsage, debugDOMProps } = setupEventTracking({ eventName, eventProperties })

	const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
		const _eventProperties = {
			...eventProperties,
			href,
		}
		console.debug("[TrackingExternalHref] Tracking event:", eventName, _eventProperties)
		trackExternalHrefUsage()
		props.onClick?.(event)
	}

	return (
		<a
			target="_blank"
			{...props}
			href={href}
			className={clsx(
				"flex items-center font-semibold text-navy-500 hover:text-navy-700 whitespace-nowrap group",
				className,
			)}
			onClick={handleClick}
			{...debugDOMProps}
		>
			{children}
			{displayExternalIcon && (
				<ArrowTopRightOnSquareIcon className="ml-0.5 h-3 w-3 font-normal flex-shrink-0 group-hover:text-navy-700" />
			)}
		</a>
	)
}
