import assertNever from "assert-never"
import clsx from "clsx"
import React from "react"

import { getContrastingTextColor } from "../../utils/color"

type SharedBadgeProps = {
	className?: string
	label: React.ReactNode
	style?: React.CSSProperties
	onClick?: () => void
}

type CustomBadgeProps = SharedBadgeProps & {
	variant: "custom"
	color: string
}

type BadgeProps =
	| (SharedBadgeProps & {
			variant: "info" | "success" | "error" | "neutral"
			color?: never
	  })
	| CustomBadgeProps

function ErrorBadge({ className, label, style, onClick }: SharedBadgeProps) {
	return (
		<span
			className={clsx(
				"inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10",
				className,
			)}
			style={style}
			onClick={onClick}
		>
			{label}
		</span>
	)
}

function SuccessBadge({ className, label, style, onClick }: SharedBadgeProps) {
	return (
		<span
			className={clsx(
				"inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20",
				className,
			)}
			style={style}
			onClick={onClick}
		>
			{label}
		</span>
	)
}

function InfoBadge({ className, label, style, onClick }: SharedBadgeProps) {
	return (
		<span
			className={clsx(
				"inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-xs font-medium text-blue-600 ring-1 ring-inset ring-gray-500/10",
				className,
			)}
			style={style}
			onClick={onClick}
		>
			{label}
		</span>
	)
}

function NeutralBadge({ className, label, style, onClick }: SharedBadgeProps) {
	return (
		<span
			className={clsx(
				"inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10",
				className,
			)}
			style={style}
			onClick={onClick}
		>
			{label}
		</span>
	)
}

function CustomBadge({ className, label, style, color, onClick }: CustomBadgeProps) {
	const contrastingColor = getContrastingTextColor(color)

	const badgeStyle = {
		...style,
		backgroundColor: color,
		color: contrastingColor,
	}
	return (
		<span
			className={clsx(
				"inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ring-gray-500/10",
				className,
			)}
			style={badgeStyle}
			onClick={onClick}
		>
			{label}
		</span>
	)
}

export function Badge({ label, className, variant, style, color, onClick }: BadgeProps) {
	switch (variant) {
		case "success":
			return <SuccessBadge label={label} className={className} style={style} onClick={onClick} />
		case "error":
			return <ErrorBadge label={label} className={className} style={style} onClick={onClick} />
		case "info":
			return <InfoBadge label={label} className={className} style={style} onClick={onClick} />
		case "neutral":
			return <NeutralBadge label={label} className={className} style={style} onClick={onClick} />
		case "custom":
			return (
				<CustomBadge
					label={label}
					className={className}
					variant={variant}
					color={color}
					style={style}
					onClick={onClick}
				/>
			)
		default:
			assertNever(variant)
	}
}
