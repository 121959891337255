import type { LeadKnowledgeV2 } from "../../../../../../../core/domain/Call.entity"
import { useLanguage } from "../../../../../../contexts/language.context"
import { KeyValueList } from "./KeyValueList.component"

export type IAccountSectionProps = {
	account: NonNullable<LeadKnowledgeV2["account"]>
}

export function AccountSection({ account }: IAccountSectionProps) {
	const { t } = useLanguage()
	return (
		<div>
			{/* TODO: should be account name */}
			<h4 className="whitespace-nowrap mb-2 pl-4 pr-3 text-lg font-bold text-gray-900 sm:pl-0">{t("Account")}</h4>

			<div className="flex-auto">
				<KeyValueList entries={account} />
			</div>
		</div>
	)
}
