import { type CalendarEvent } from "../../../core/domain/CalendarEvent.entity/CalendarEvent.entity"
import { RecordingStatus } from "../../../core/domain/CalendarEvent.entity/RecordingStatus"
import type { User } from "../../../core/domain/User.entity"
import type { TFunction } from "../../contexts/language.context"
import type { DateProvider } from "../time"
import { DateFormatter } from "../time"

export function makeDemoBriefingContent(
	user: User,
	eventDateRange: { startTime: Date; endTime: Date },
	dateProvider: DateProvider,
	t: TFunction,
	language: string,
) {
	const dateFormatter = new DateFormatter(dateProvider, t)
	const dateRange = dateFormatter.getFormattedDateRange(eventDateRange.startTime, eventDateRange.endTime, language)
	return `
**Introduction to Rippletide, ${dateRange.startDateLabel} - ${dateRange.endDateLabel}**

### 1. Prospect Information

**Company name:** [Rippletide](https://linkedin.com/company/rippletide)

**Company website:** [rippletide.com](https://rippletide.com)

**Industry:** Information technology

**Company size:** 2-10 employees

**Location:** Paris, France

**Attendees:**

- ${user.getFullName()} (${user.email}) – *You*
- [Rippletide (contact@rippletide.com)](https://linkedin.com/company/rippletide) — *Decision maker*

### 2. Discussion points

- **Business activity:** Merging state-of-the-art artificial intelligence with insightful coaching analytics to turn every sales interaction into a rich sources of insights and learning opportunities.
- **Business needs:** Elevating sales success and shortening sales cycles.
- **Current challenges:** Boosting sales team productivity and performance.
- **Strategic impact:** Helping businesses achieve their goals by providing extensive conversation insights which allow sales teams to rapidly adapt and improve their technique, for direct results on productivity and performance.

### 3. Strategic angle

- **Historical interactions:** The prospect has previously expressed concerns about integration with their technological stack. Ask for specific details, and reassure the prospect with testimonials that demonstrate your product’s flexibility.
- **Most impactful solutions:** Emphasise the features of your product which will be the most impactful in addressing the prospect’s needs, such as seamless CRM integration for improved productivity and tailored coaching for enhanced performance precision.
- **Competitive advantage:** Highlight the areas in which your product outperforms those of your competitors, tailored to the prospect’s needs — for example, detailed pre-call briefs for unprecedented preparation.

### 4. Questions to ask

- Ask for specific details of the prospect’s technological stack — what CRM is the company currently using?
- Find out more about how inefficient productivity and performance is impacting the business — what does this mean for the company revenue?
- Discover the decision criteria that must be fulfilled in order to close the deal — what are the most important criteria they will consider when evaluating the product?

### 5. Build rapport

Don’t forget to demonstrate a genuine interest in your prospect to establish a strong relationship from the outset — discuss how things are in Paris at the moment, and try to discover their passions.

**Remember to listen actively and adapt to your prospect’s input. Good luck with your meeting, ${user.firstName}!**
	`
}

export function makeDemoCalendarEvent(
	user: User,
	dateProvider: DateProvider,
	t: TFunction,
	language: string,
): CalendarEvent {
	const fifteenMinutes = 15 * 60 * 1000
	const oneHour = 60 * 60 * 1000
	const startTime = dateProvider.now().getTime() + fifteenMinutes
	const calendarEventWithoutBrief: CalendarEvent = {
		id: "demo",
		startTime: new Date(startTime),
		endTime: new Date(startTime + oneHour),
		title: "Introduction to Rippletide",
		meetingUrl: "https://example.com",
		botStatus: {
			code: RecordingStatus.ScheduledBot,
			canSendBotToMeetingNow: false,
			botWillJoinAt: new Date(startTime),
		},
		isDemo: true,
	}

	return {
		...calendarEventWithoutBrief,
		briefing: {
			version: 1,
			result: makeDemoBriefingContent(user, calendarEventWithoutBrief, dateProvider, t, language),
		},
	}
}
