import type { UpdateHighlightParams } from "../../../../../core/application/gateways/library.gateway"
import type { CallHighlight, Library } from "../../../../../core/domain/Library.entity"
import { useLanguage } from "../../../../contexts/language.context"
import { EntityAgnosticModalUI, type Module, type ModuleData } from "./EntityAgnosticModalUI.component"

type CallHighlightParentEditionModalProps = {
	isOpen: boolean
	onClose: () => void
	callHighlightUnderEdition: CallHighlight | undefined
	updateHighlight: (callHighlight: CallHighlight, updateHighlightParams: UpdateHighlightParams) => Promise<void>
	library: Library | "loading"
}

export function CallHighlightParentEditionModal({
	isOpen,
	onClose,
	callHighlightUnderEdition,
	updateHighlight,
	library,
}: CallHighlightParentEditionModalProps) {
	const { t } = useLanguage()

	if (library === "loading") return
	if (!callHighlightUnderEdition) return

	const module = {
		type: "library-select",
		name: "containing-folder-id",
		libraryFolderSelectProps: {
			library,
			defaultValue: callHighlightUnderEdition.containingLibraryFolderId,
			onlyRoot: undefined,
			showAIFolders: false,
		},
	} as const satisfies Module

	const onSubmit = async (formData: ModuleData<[typeof module]>) => {
		const newParentFolderId = formData[module.name]

		const newParentFolder = library.getFolderById(newParentFolderId)
		if (newParentFolder === undefined) {
			window.alert("Invalid folder") // should not happen
			return
		}

		await updateHighlight(callHighlightUnderEdition, {
			newParentLibraryFolderId: newParentFolderId,
		})

		onClose()
	}

	return (
		<EntityAgnosticModalUI
			isOpen={isOpen}
			onClose={onClose}
			onSubmit={onSubmit}
			title={t("Move highlight")}
			submitLabel={t("Update")}
			submitEventName="call-highlight-parent-edition"
			modules={[module]}
		/>
	)
}
