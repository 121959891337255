import React, { type FormEvent } from "react"
import { HexColorPicker } from "react-colorful"

import type { EditTagTypePayload } from "../../../../../core/application/gateways/tag-types.gateway"
import type { TagType } from "../../../../../core/domain/TagType.entity"
import { Badge } from "../../../../components/design-system/Badge.component"
import { Button } from "../../../../components/design-system/Button.component"
import { Input } from "../../../../components/design-system/Input"
import { Modal } from "../../../../components/design-system/Modal.component"
import { useLanguage } from "../../../../contexts/language.context"

type EditTagModalProps = {
	editingTag: TagType
	onClose: () => void
	onSubmit: (payload: EditTagTypePayload) => void
}

export function EditTagModal({ editingTag, onClose, onSubmit }: EditTagModalProps) {
	const [description, setDescription] = React.useState(editingTag.properties.description)
	const [color, setColor] = React.useState(editingTag.properties.color)

	const { t } = useLanguage()

	const handleSubmit = React.useCallback(
		(e: FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			onSubmit({ description, color })
		},
		[color, description, onSubmit],
	)

	React.useEffect(() => {
		return () => {
			setDescription(editingTag.properties.description)
			setColor(editingTag.properties.color)
		}
	}, [editingTag])

	return (
		<Modal open={true} onClose={onClose} showCloseButton={false}>
			<p className="text-lg font-semibold text-navy-900 mb-6">
				{t("Edit tag")}
				<Badge label={editingTag.properties.name} variant="custom" color={color} className="ml-2" />
			</p>

			<div className="h-full flex flex-col">
				<form onSubmit={handleSubmit}>
					<Input.Group>
						<Input.Label>{t("Name")}</Input.Label>
						<Input.Input value={editingTag.properties.name} type="text" disabled placeholder={t("Name")} />
					</Input.Group>

					<Input.Group>
						<Input.Label>{t("Description")}</Input.Label>
						<Input.TextArea
							value={description}
							placeholder={t(
								"E.g., 'Discovery call focused on identifying the client's goals, pain points, and requirements to assess how our solutions can support their success.'",
							)}
							onChange={(e) => setDescription(e.target.value)}
							required
						/>
						<Input.Helper>
							{t(
								"Provide a brief description that explains when this tag should be applied. This helps our AI identify relevant moments in your calls.",
							)}
						</Input.Helper>
					</Input.Group>

					<Input.Group>
						<Input.Label>{t("Color")}</Input.Label>
						<HexColorPicker color={color} onChange={setColor} />
					</Input.Group>

					<div className="flex flex-1 justify-end items-center">
						<Button
							className="mt-4"
							eventName="Create tag button clicked"
							type="submit"
							variant="primary"
							disabled={!description || !color}
						>
							{t("Edit tag")}
						</Button>
					</div>
				</form>
			</div>
		</Modal>
	)
}
