export function makeRedirectUrl(redirectTo: string | null): string | undefined {
	if (!redirectTo) {
		return undefined
	}

	try {
		const url = new URL(redirectTo, window.location.origin)

		// Only allow redirects to the same origin to prevent phishing attacks
		if (url.origin !== window.location.origin) {
			return undefined
		}

		return url.pathname + url.search
	} catch (error) {
		return undefined
	}
}
