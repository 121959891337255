import { SectionCard } from "../../../../components/shared/GraphSection/SectionCard.component"
import { useLanguage } from "../../../../contexts/language.context"

export function ForecastBoost() {
	const { t } = useLanguage()

	return (
		<SectionCard className="h-full gap-6 flex flex-col bg-gray-200">
			<div className="h-full flex flex-col justify-between">
				<h3 className="text-lg font-semibold text-gray-500">
					{t("Boost your forecast")} <i>{t("(coming soon)")}</i>
				</h3>

				<div className="flex-[10] flex items-center">
					<div className="text-md text-gray-500 text-center mx-[10%]">
						{t(
							"Discover what actions you can take to increase closing probabilities, resolve risks, and achieve your best case forecast.",
						)}
					</div>
				</div>
				<div className="flex-1">{/*margin in order to vertically center the description*/}</div>
			</div>
		</SectionCard>
	)
}
