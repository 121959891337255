import { faHubspot, faSalesforce } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import assertNever from "assert-never"
import React from "react"

import type { CrmIntegration, SalesforceIntegration } from "../../../../../core/domain/Workspace.entity"
import { ApiError } from "../../../../../core/infra/gateways/api-errors"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useDependencies } from "../../../../contexts/dependencies.context"

const defaultSalesforcePasswordCrmIntegration: CrmIntegration = {
	provider: "salesforce",
	instanceUrl: "",
	credentials: {
		type: "password",
		username: "",
		password: "",
		securityToken: "",
		loginUrl: "https://login.salesforce.com",
	},
}

const defaultSalesforceCrmIntegration: CrmIntegration = defaultSalesforcePasswordCrmIntegration

const defaultCrmIntegration: CrmIntegration = defaultSalesforcePasswordCrmIntegration

const defaultSalesforceOAuthCrmIntegration: CrmIntegration = {
	provider: "salesforce",
	instanceUrl: "",
	credentials: {
		type: "oauth",
		clientId: "",
		clientSecret: "",
		loginUrl: "https://login.salesforce.com",
	},
}

type CrmSetupFormState =
	| { status: "idle" }
	| { status: "submitting" }
	| { status: "success" }
	| { status: "error"; error: ApiError }

export function useCrmSettings() {
	const { workspace, refetchMe } = useAuthenticatedSession()
	const { usersGateway } = useDependencies()
	const [formStatus, setFormStatus] = React.useState<CrmSetupFormState>({ status: "idle" })
	const [crmIntegration, _setCrmIntegration] = React.useState<CrmIntegration>(
		() => workspace.crmIntegration ?? defaultCrmIntegration,
	)

	const setCrmIntegration = (newCrmIntegration: CrmIntegration) => {
		setFormStatus({ status: "idle" })
		_setCrmIntegration(newCrmIntegration)
	}

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()
		try {
			setFormStatus({ status: "submitting" })
			await usersGateway.setupCrmIntegration(crmIntegration)
			await refetchMe()
			setFormStatus({ status: "success" })
		} catch (error) {
			if (error instanceof ApiError) {
				setFormStatus({
					status: "error",
					error,
				})
				return
			}
			console.error(error)
		}
	}

	const handleSelectedProviderChange = (provider: CrmIntegration["provider"]) => {
		// avoid resetting the form if the provider is the same
		if (provider === crmIntegration.provider) {
			return
		}

		switch (provider) {
			case "salesforce":
				setCrmIntegration(defaultSalesforceCrmIntegration)
				return
			case "hubspot":
				setCrmIntegration({
					provider,
					instanceUrl: "https://app.hubspot.com", // Hubspot instance URL is always the same
					credentials: { type: "accessToken", accessToken: "" },
				})
				return
			default:
				assertNever(provider)
		}
	}

	const handleInstanceUrlChange = (instanceUrl: string) => {
		setCrmIntegration({ ...crmIntegration, instanceUrl })
	}

	const handleCredentialsChange = (credentials: CrmIntegration["credentials"]) => {
		switch (crmIntegration.provider) {
			case "salesforce":
				if (credentials.type === "password") {
					setCrmIntegration({
						...crmIntegration,
						credentials: { ...credentials, loginUrl: "https://login.salesforce.com" },
					})
					return
				}
				if (credentials.type === "oauth") {
					setCrmIntegration({
						...crmIntegration,
						credentials: { ...credentials, loginUrl: "https://login.salesforce.com" },
					})
					return
				}
				return
			case "hubspot":
				if (credentials.type === "accessToken") {
					setCrmIntegration({ ...crmIntegration, credentials })
					return
				}
				return
			default:
				assertNever(crmIntegration)
		}
	}

	const salesforceCredentialTypes: Array<{
		label: string
		type: SalesforceIntegration["credentials"]["type"]
		select: () => void
	}> = [
		{
			label: "Password",
			type: "password",
			select: () => setCrmIntegration(defaultSalesforcePasswordCrmIntegration),
		},
		{
			label: "OAuth",
			type: "oauth",
			select: () => setCrmIntegration(defaultSalesforceOAuthCrmIntegration),
		},
	]

	const providers: Array<{
		label: string
		value?: CrmIntegration["provider"]
		icon?: React.ReactNode
	}> = [
		{ label: "Salesforce", value: "salesforce", icon: <FontAwesomeIcon icon={faSalesforce} color="#00A1E0" /> },
		{ label: "Hubspot", value: "hubspot", icon: <FontAwesomeIcon icon={faHubspot} color="#FF7A59" /> },
		{ label: "Pipedrive" },
	]

	const isFormStateSubmittable = formStatus.status !== "submitting" && formStatus.status !== "error"
	const areSharedPropertiesFilled =
		crmIntegration.instanceUrl && crmIntegration.provider && crmIntegration.credentials.type
	const arePasswordCredentialsFilled =
		crmIntegration.credentials.type === "password" &&
		crmIntegration.credentials.username &&
		crmIntegration.credentials.password &&
		crmIntegration.credentials.securityToken
	const areOAuthCredentialsFilled =
		crmIntegration.credentials.type === "oauth" &&
		crmIntegration.credentials.clientId &&
		crmIntegration.credentials.clientSecret
	const areHubspotCredentialsFilled =
		crmIntegration.provider === "hubspot" &&
		crmIntegration.credentials.type === "accessToken" &&
		crmIntegration.credentials.accessToken

	const isFormSubmittable =
		isFormStateSubmittable &&
		areSharedPropertiesFilled &&
		(arePasswordCredentialsFilled || areOAuthCredentialsFilled || areHubspotCredentialsFilled)

	return {
		isFormSubmittable,
		formStatus,
		crmIntegration,
		onSubmit: handleSubmit,
		salesforceCredentialTypes,
		providers,
		onCredentialsChange: handleCredentialsChange,
		onSelectedProviderChange: handleSelectedProviderChange,
		onInstanceUrlChange: handleInstanceUrlChange,
		savedCrmIntegration: workspace.crmIntegration,
	}
}
