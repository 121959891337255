import clsx from "clsx"
import Markdown from "react-markdown"

import type { CalendarEventBrief } from "../../../core/domain/CalendarEvent.entity/CalendarEventBrief"
import { MarkdownAnchorRenderer } from "./MarkdownLinkRenderer.component"

type IBriefRendererProps = {
	briefing: CalendarEventBrief
	className?: string
}

function fixUsualBriefingMarkdownFormattingErrors(briefing: string) {
	// Some briefings have a markdown code block at the beginning that ends up being rendered as a code block so we remove it
	// Also, some briefings start with "---\n" which is a YAML front matter, we need to remove it to avoid displaying an "hr" element
	return briefing.replace(/^```markdown/, "").replace(/^---\n/, "")
}

export function BriefRenderer({ briefing, className }: IBriefRendererProps) {
	return (
		<Markdown
			className={clsx("prose-sm prose-headings:my-2 prose-a:text-sky hover:prose-a:text-blue-500", className)}
			components={{ a: MarkdownAnchorRenderer }}
		>
			{fixUsualBriefingMarkdownFormattingErrors(briefing.result)}
		</Markdown>
	)
}
