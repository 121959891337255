import clsx from "clsx"

export type _PageTitleProps = {
	title: string
	className?: string
}

export function _PageTitle({ title, className }: _PageTitleProps) {
	return (
		<h2
			className={clsx(
				"text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight",
				className,
			)}
		>
			{title}
		</h2>
	)
}
