import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline"
import React, { Fragment } from "react"

import type { MeetingNotesTemplateWithSections } from "../../../../../core/domain/MeetingNotesTemplate.entity"
import { Badge } from "../../../../components/design-system/Badge.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { useLanguage } from "../../../../contexts/language.context"
import { useWorkspaceUsers } from "../../../../contexts/workspace-users.context"
import { DateFormatter, DateProvider } from "../../../../utils/time"
import { makeWorkspaceSettingsPath } from "../../config"

type TemplateListProps = {
	templates: MeetingNotesTemplateWithSections[]
	onDeleteTemplate: (templateId: string) => void
	onApplyTemplate: (template: MeetingNotesTemplateWithSections) => void
	onDuplicateTemplate: (template: MeetingNotesTemplateWithSections) => void
	onPreviewTemplate: (template: MeetingNotesTemplateWithSections) => void
}

export function TemplateList({
	templates,
	onDeleteTemplate,
	onApplyTemplate,
	onDuplicateTemplate,
	onPreviewTemplate,
}: TemplateListProps) {
	const { t } = useLanguage()
	const { workspaceUsers } = useWorkspaceUsers()
	const dateProvider = new DateProvider()
	const dateFormatter = new DateFormatter(dateProvider, t)
	return (
		<div className="px-4 sm:px-6 lg:px-8">
			<div className="mt-8 flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<table className="min-w-full divide-y divide-gray-300">
							<thead>
								<tr>
									<th
										scope="col"
										className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
									>
										{t("Title")}
									</th>
									<th
										scope="col"
										className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
									>
										{t("Created by")}
									</th>
									<th
										scope="col"
										className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
									>
										{t("Created date")}
									</th>
									<th
										scope="col"
										className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
									>
										{t("Last updated")}
									</th>
									<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
										<span className="sr-only">Edit</span>
									</th>
								</tr>
							</thead>
							<tbody className="divide-y divide-gray-200">
								{templates.map((template) => {
									const createdByUser = workspaceUsers?.find(
										(user) => user.id === template.createdByUserId,
									)
									const Wrapper = template.isDefault
										? ({ children }: { children: React.ReactNode }) => <>{children}</>
										: ({ children }: { children: React.ReactNode }) => (
												<TrackingLink
													eventName="Edit template settings clicked"
													eventProperties={{
														templateId: template.id,
													}}
													to={makeWorkspaceSettingsPath("templates", template.id)}
													className="flex gap-2"
												>
													{children}
												</TrackingLink>
										  )

									return (
										<tr key={template.id}>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 flex gap-3">
												<Wrapper>
													{template.isDefault ? t("Default template") : template.title}
													{template.isDefault && <Badge label="Rippletide" variant="info" />}

													{template.isCurrentWorkspaceTemplate && (
														<div className="flex items-center gap-1">
															<span className="rounded-full bg-emerald-500 w-2 h-2" />
															<span className="text-emerald-600 font-semibold">
																{t("Current")}
															</span>
														</div>
													)}
												</Wrapper>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{createdByUser ? (
													<span>{createdByUser.getFullName()}</span>
												) : (
													<span className="text-gray-500">
														{template.isDefault ? "Rippletide" : t("Unknown")}
													</span>
												)}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{dateFormatter.getFormattedDate(template.createdAt).day}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{dateFormatter.getFormattedDate(template.updatedAt).day}
											</td>

											<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
												<Menu as="div" className="relative inline-block text-left">
													<div>
														<MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
															<EllipsisVerticalIcon
																className="h-6 w-6"
																aria-hidden="true"
															/>
														</MenuButton>
													</div>

													<Transition
														as={Fragment}
														enter="transition ease-out duration-100"
														enterFrom="transform opacity-0 scale-95"
														enterTo="transform opacity-100 scale-100"
														leave="transition ease-in duration-75"
														leaveFrom="transform opacity-100 scale-100"
														leaveTo="transform opacity-0 scale-95"
													>
														<MenuItems
															anchor={{
																to: "bottom start",
															}}
															className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
														>
															<div className="py-1 text-sm">
																{!template.isDefault && (
																	<MenuItem>
																		<TrackingLink
																			to={makeWorkspaceSettingsPath(
																				"templates",
																				template.id,
																			)}
																			eventName="Edit template clicked"
																			eventProperties={{
																				templateId: template.id,
																			}}
																			className="text-gray-600 hover:text-gray-500 hover:bg-gray-50 px-4 py-2 group w-full flex text-left"
																		>
																			<span className="text-medium">
																				{t("Edit…")}
																			</span>
																		</TrackingLink>
																	</MenuItem>
																)}

																<MenuItem>
																	<TrackingButton
																		onClick={() => onPreviewTemplate(template)}
																		eventName="Duplicate template clicked"
																		eventProperties={{
																			templateId: template.id,
																		}}
																		className="text-gray-600 hover:text-gray-500 hover:bg-gray-50 px-4 py-2 group w-full flex text-left"
																	>
																		<span className="text-medium">
																			{t("Preview...")}
																		</span>
																	</TrackingButton>
																</MenuItem>

																<MenuItem>
																	<TrackingButton
																		onClick={() => onDuplicateTemplate(template)}
																		eventName="Duplicate template clicked"
																		eventProperties={{
																			templateId: template.id,
																		}}
																		className="text-gray-600 hover:text-gray-500 hover:bg-gray-50 px-4 py-2 group w-full flex text-left"
																	>
																		<span className="text-medium">
																			{t("Duplicate...")}
																		</span>
																	</TrackingButton>
																</MenuItem>
																{!template.isCurrentWorkspaceTemplate && (
																	<MenuItem>
																		<TrackingButton
																			onClick={() => onApplyTemplate(template)}
																			eventName="Set as default template clicked"
																			eventProperties={{
																				templateId: template.id,
																			}}
																			className="text-gray-600 hover:text-gray-500 hover:bg-gray-50 px-4 py-2 group w-full flex text-left"
																		>
																			{t("Set as workspace template...")}
																		</TrackingButton>
																	</MenuItem>
																)}

																{!template.isDefault && (
																	<MenuItem>
																		<TrackingButton
																			eventName="Delete template clicked"
																			eventProperties={{
																				templateId: template.id,
																			}}
																			onClick={() =>
																				onDeleteTemplate(template.id)
																			}
																			type="button"
																			className="text-red-600 hover:text-red-500 hover:bg-gray-50 px-4 py-2 group w-full flex"
																		>
																			<span className="text-medium">
																				{t("Delete…")}
																			</span>
																		</TrackingButton>
																	</MenuItem>
																)}
															</div>
														</MenuItems>
													</Transition>
												</Menu>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}
