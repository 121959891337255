export type PublicWebsitePath =
	| "/"
	| "/about"
	| "/terms-of-service"
	| "/blog"
	| "/privacy-policy"
	| "/contact"
	| "/cookie-policy"

export function makePublicWebsiteUrl(path?: PublicWebsitePath) {
	const formattedPath = !path ? "" : path.startsWith("/") ? path : `/${path}`
	return `https://www.rippletide.com${formattedPath}?ref=app.rippletide.com`
}
