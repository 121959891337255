import { InformationCircleIcon } from "@heroicons/react/20/solid"
import React from "react"
import { Trans } from "react-i18next"

import type { BillingInfo } from "../../../../../core/application/gateways/billing.gateway"
import { Tooltip } from "../../../../components/design-system/Tooltip.component"
import { TrackingExternalHref } from "../../../../components/design-system/TrackingExternalHref.component"
import { useAuthenticatedSession } from "../../../../contexts/authentication.context"
import { useLanguage } from "../../../../contexts/language.context"
import { DateFormatter, DateProvider } from "../../../../utils/time"
import { PlanCard } from "./PlanCard.component"

type IYourPlanCardProps = {
	billing: BillingInfo
}

export function YourPlanCard({ billing }: IYourPlanCardProps) {
	const { t } = useLanguage()
	const { user, workspace } = useAuthenticatedSession()
	const isWorkspaceManager = user.isWorkspaceManager()
	const isTrial = billing.subscription?.isTrial
	const dateFormatter = new DateFormatter(new DateProvider(), t)

	if (!billing.plan) {
		return null
	}

	return (
		<PlanCard
			state={isTrial ? "highlight" : undefined}
			title={t("Your plan")}
			subtitle={billing.subscription?.isTrial ? t("Free trial") : billing.plan.name}
			subtitleDetails={
				billing.subscription ? (
					<div className="flex flex-col">
						<span className="text-navy-700 text-opacity-50 mt-1">
							{billing.subscription?.isTrial
								? billing.plan.name
								: t("Plan active since {{date}}", {
										date: dateFormatter.getFormattedDate(billing.subscription.startedAt).day,
								  })}
						</span>
						{billing.subscription.billingPeriod && (
							<span className="text-navy-700 text-opacity-50 mt-1">
								{billing.subscription.billingPeriod === "monthly"
									? t("Billed monthly")
									: t("Billed annually")}
							</span>
						)}

						{billing.subscription.cancelAt && (
							<div className="flex items-center">
								<span className="text-red-700 text-opacity-70 mt-0.5 mb-1">
									{t("Plan will be canceled on {{date}}", {
										date: dateFormatter.getFormattedDate(billing.subscription.cancelAt).day,
									})}
								</span>
								{billing.actionUrls?.customerPortalUrl && (
									<TrackingExternalHref
										eventName="Billing settings: reactivate subscription clicked"
										href={billing.actionUrls.customerPortalUrl}
										displayExternalIcon={false}
										className="hover:!text-gray-100 rounded bg-navy-700 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-navy-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-500"
									>
										{t("Reactivate subscription")}
									</TrackingExternalHref>
								)}
							</div>
						)}
					</div>
				) : undefined
			}
		>
			<span className="text-navy-700 font-bold mt-1">
				{billing.subscription?.isTrial ? (
					<>
						<div className="flex items-center">
							{t("daysLeft", {
								count: Math.ceil(
									(billing.subscription.endAt.getTime() - Date.now()) / (1000 * 60 * 60 * 24),
								),
							})}
							<Tooltip
								content={t("Your subscription will end on {{date}}", {
									date: `${dateFormatter.getFormattedDate(billing.subscription.endAt).day} ${
										dateFormatter.getFormattedDate(billing.subscription.endAt).time
									}`,
								})}
							>
								<InformationCircleIcon className="h-5 w-5 ml-1 text-navy-500" />
							</Tooltip>
						</div>
						{workspace.props.couponId && (
							<span className="text-xs ml-1 font-medium">
								<Trans
									i18nKey="A <1>{{couponId}}</1> coupon has been applied to your subscription."
									values={{ couponId: workspace.props.couponId }}
									components={{ 1: <span className="font-bold" /> }}
								/>
							</span>
						)}
						{billing.subscription.hasPaymentMethod && (
							<span className="text-xs">
								{t("You will automatically be charged at the end of the trial period.")}
							</span>
						)}
					</>
				) : isWorkspaceManager ? (
					t("activeLicenses", {
						count: billing.activeLicenses,
					})
				) : null}
			</span>
			<TrackingExternalHref
				eventName="Billing settings: view all features clicked"
				href="https://rippletide.com/pricing"
				displayExternalIcon={false}
				className="mt-2.5 rounded-md px-3.5 py-2.5 text-md font-semibold text-navy-700 shadow-sm ring-1 ring-inset ring-navy-700 hover:bg-opacity-10 bg-white bg-opacity-0 flex justify-center"
			>
				{t("View all features")}
			</TrackingExternalHref>
			{!isWorkspaceManager && (
				<span className="text-navy-700 text-opacity-50 font-medium mt-4">
					{t("Plan changes can only be made by your workspace owner.")}
				</span>
			)}
		</PlanCard>
	)
}
