export function MoneyAmount(amountInCents: number, showCents: "always" | "auto" | "never" = "auto") {
	const amount = amountInCents / 100

	const hasCents = amountInCents % 100 !== 0
	const doShowCents = showCents === "always" || (showCents === "auto" && hasCents)

	const formattedAmount = new Intl.NumberFormat("en-EN", {
		style: "currency",
		currency: "EUR",
		minimumFractionDigits: doShowCents ? 2 : 0,
		maximumFractionDigits: doShowCents ? 2 : 0,
	}).format(amount)

	return formattedAmount
}
