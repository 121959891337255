import {
	billingInfoSchema,
	type BillingPeriod,
	getPlansResponseSchema,
	type IBillingGateway,
	sessionResponseSchema,
} from "../../application/gateways/billing.gateway"

export class HttpBillingGateway implements IBillingGateway {
	constructor(private readonly baseApiUrl: string) {}

	async getBillingInfo() {
		const response = await fetch(`${this.baseApiUrl}/billing/me`, {
			credentials: "include",
			method: "GET",
		})
		const json = await response.json()
		return billingInfoSchema.parse(json)
	}

	async createCheckoutSession(planId: string, billingPeriod: BillingPeriod) {
		const response = await fetch(`${this.baseApiUrl}/billing/create-checkout-session`, {
			credentials: "include",
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ planId, billingPeriod }),
		})
		const json = await response.json()
		return sessionResponseSchema.parse(json)
	}

	async createPaymentMethodUpdatePortalSession(planId: string, billingPeriod: BillingPeriod) {
		const response = await fetch(`${this.baseApiUrl}/billing/create-payment-method-update-portal`, {
			credentials: "include",
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ planId, billingPeriod }),
		})
		const json = await response.json()
		return sessionResponseSchema.parse(json)
	}

	async createSubscriptionUpdateConfirmPortalSession(planId: string, billingPeriod: BillingPeriod) {
		const response = await fetch(`${this.baseApiUrl}/billing/create-upgrade-confirm-portal`, {
			credentials: "include",
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ planId, billingPeriod }),
		})

		const json = await response.json()
		return sessionResponseSchema.parse(json)
	}

	async getPlans() {
		const response = await fetch(`${this.baseApiUrl}/billing/plans`, {
			credentials: "include",
			method: "GET",
		})

		const json = await response.json()
		return getPlansResponseSchema.parse(json)
	}
}
