import { z } from "zod"

const UpdateTrainingProgram_Name = z.object({
	type: z.literal("name"),
	name: z.string(),
	description: z.string(),
})

const TrainingProgramItemContent_Text = z.object({
	type: z.literal("text"),
	text: z.string(),
})

const TrainingProgramItemContent_Highlight = z.object({
	type: z.literal("highlight"),
	highlightId: z.string(),
})

const TrainingProgramItemContent_Video = z.object({
	type: z.literal("video"),
	fileId: z.string(),
})

const UpdateTrainingProgram_Pdf = z.object({
	type: z.literal("pdf"),
	fileId: z.string(),
})

export const TrainingProgramItemContentSchema = z.discriminatedUnion("type", [
	TrainingProgramItemContent_Text,
	TrainingProgramItemContent_Highlight,
	TrainingProgramItemContent_Video,
	UpdateTrainingProgram_Pdf,
])

export const UpdateTrainingProgramSectionSchema = z
	.object({
		id: z.string().optional(),
		name: z.string(),
		description: z.string(),
		items: z.array(
			z.union([
				z.object({
					id: z.string(),
					description: z.string(),
					content: z.union([z.literal("unchanged"), TrainingProgramItemContentSchema]),
				}),
				z.object({
					id: z.never().optional(),
					description: z.string(),
					content: TrainingProgramItemContentSchema,
				}),
			]),
		),
	})
	.transform((out) => ({ ...out, id: out.id }))

export type UpdateTrainingProgramSection = z.infer<typeof UpdateTrainingProgramSectionSchema>

const UpdateTrainingProgram_Content = z.object({
	type: z.literal("content"),
	sections: z.array(UpdateTrainingProgramSectionSchema),
})

const UpdateTrainingProgram_Enrolments = z.object({
	type: z.literal("enrolments"),
	/**
	 * Exhaustive list of users that should be enrolled in the program
	 *
	 * Already enrolled users that are not in this list will be unenrolled
	 */
	users: z.array(
		z.object({
			id: z.string(),
			deadline: z.coerce.date().nullish(),
		}),
	),
})

export const UpdateTrainingProgramPayloadSchema = z.discriminatedUnion("type", [
	UpdateTrainingProgram_Name,
	UpdateTrainingProgram_Content,
	UpdateTrainingProgram_Enrolments,
])
export type UpdateTrainingProgramPayload = z.infer<typeof UpdateTrainingProgramPayloadSchema>

export const UpdateTrainingProgramUrlParamsSchema = z.object({
	programId: z.string(),
})

export const UpdateTrainingProgramResponseSchema = z.strictObject({
	programId: z.string(),
})
export type UpdateTrainingProgramResponse = z.infer<typeof UpdateTrainingProgramResponseSchema>
