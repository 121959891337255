import { ExclamationCircleIcon } from "@heroicons/react/20/solid"
import assertNever from "assert-never"
import React, { useState } from "react"

import type { CalendarEvent } from "../../../../../core/domain/CalendarEvent.entity/CalendarEvent.entity"
import type { NotRecordableReason } from "../../../../../core/domain/CalendarEvent.entity/RecordableState"
import { RecordingStatus } from "../../../../../core/domain/CalendarEvent.entity/RecordingStatus"
import { Input } from "../../../../components/design-system/Input"
import { LinkButton } from "../../../../components/design-system/LinkButton.component"
import { StatusBadge } from "../../../../components/design-system/StatusBadge.component"
import { Tooltip } from "../../../../components/design-system/Tooltip.component"
import { useAnalytics } from "../../../../contexts/analytics.context"
import { useCalendarContext } from "../../../../contexts/calendar.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { type TFunction, useLanguage } from "../../../../contexts/language.context"
import { makeCallPath } from "../../../../router/Router"

function notRecordableStatusToTranslationKey(status: NotRecordableReason, t: TFunction): string {
	switch (status.code) {
		// case "restricted-access-plan":
		// 	return t("Upgrade your plan to record this meeting")
		// case "restricted-access-usage-limit":
		// 	return t("You have reached your recording limit")
		case "unknown-inviter":
			return t("The inviter of this meeting cannot be identified")
		case "unsupported-meeting-platform":
			return t("Unsupported meeting platform: {{meetingPlatform}}", {
				meetingPlatform: status.meetingPlatform,
			})
		case "no-meeting-url":
			return t("This meeting does not have a URL")
		case "already-has-spawned-bot":
			return t("This meeting was already recorded")
		case "event-has-ended":
			return t("This meeting has ended")
		default:
			assertNever(status, true)
	}
}

function TooltipWrapper({ content, children }: { content: React.ReactNode; children: React.ReactNode }) {
	return (
		<Tooltip content={content} wrapperClassName="flex-1 justify-center">
			{children}
		</Tooltip>
	)
}

/**
 * Possible improvement: consume getRecordingStatusDetails
 */
export function EventRecordingStatus({ calendarEvent }: { calendarEvent: CalendarEvent }) {
	const recordingIcon = <StatusBadge variant="error" />
	const waitingIcon = <StatusBadge variant="warning" />
	const errorIcon = <ExclamationCircleIcon className="h-4 w-4 text-red-500" />
	const recordingAvailableIcon = <StatusBadge variant="success" />
	const recordingNotAvailableIcon = <ExclamationCircleIcon className="h-4 w-4 text-gray-300" />
	const { t } = useLanguage()
	const { calendarEventsGateway } = useDependencies()
	const { fetchEvents } = useCalendarContext()
	const [isLoading, setIsLoading] = useState(false)
	const { setupEventTracking } = useAnalytics()

	const shouldShowRecordingStatusToggle = [RecordingStatus.NoBot, RecordingStatus.ScheduledBot].includes(
		calendarEvent.botStatus.code,
	)

	const handleToggleRecording = React.useCallback(
		async (newValue: boolean) => {
			try {
				const { trackEvent } = setupEventTracking({
					eventName: "Recording status toggled",
					eventProperties: {
						calendarEventId: calendarEvent.id,
						newValue: newValue ? "on" : "off",
					},
				})
				trackEvent()
				setIsLoading(true)
				await calendarEventsGateway.toggleRecording(calendarEvent.id, newValue)
				await fetchEvents("Recording status was toggled")
			} catch (e) {
				console.error("Failed to toggle recording status", e)
			} finally {
				setIsLoading(false)
			}
		},
		[calendarEvent.id, calendarEventsGateway, fetchEvents, setupEventTracking],
	)

	if (shouldShowRecordingStatusToggle && calendarEvent._recording) {
		if (calendarEvent._recording.type === "non-recordable") {
			return (
				<TooltipWrapper content={notRecordableStatusToTranslationKey(calendarEvent._recording.reason, t)}>
					<ExclamationCircleIcon className="h-4 w-4 text-gray-300" />
				</TooltipWrapper>
			)
		}

		const isRecordingEnabled = calendarEvent._recording.type === "recordable" && calendarEvent._recording.toggled
		const tooltipContent = isRecordingEnabled ? t("Do not record this meeting") : t("Record this meeting")

		return (
			<TooltipWrapper content={tooltipContent}>
				<Input.Toggle
					value={isLoading ? !isRecordingEnabled : isRecordingEnabled}
					onChange={() => handleToggleRecording(!isRecordingEnabled)}
					size="small"
					disabled={isLoading}
				/>
			</TooltipWrapper>
		)
	}

	switch (calendarEvent.botStatus.code) {
		case RecordingStatus.Recording:
			return <TooltipWrapper content={t("Rippletide is recording")}>{recordingIcon}</TooltipWrapper>
		case RecordingStatus.Done_ProcessingRecording:
			return <TooltipWrapper content={t("Rippletide is processing the meeting…")}>{waitingIcon}</TooltipWrapper>
		case RecordingStatus.Done_RecordingAvailable:
			if (calendarEvent.botStatus.callId) {
				return (
					<Tooltip content={t("View full call")}>
						<LinkButton
							eventName="Navigate to call from event"
							eventProperties={{ calendarEventId: calendarEvent.id }}
							to={makeCallPath(calendarEvent.botStatus.callId, undefined)}
							size="xs"
							variant="primary"
						>
							{t("Call")}
						</LinkButton>
					</Tooltip>
				)
			}

			return <TooltipWrapper content={t("Recording available")}>{recordingAvailableIcon}</TooltipWrapper>
		case RecordingStatus.Connecting:
			return (
				<TooltipWrapper content={t("Rippletide is connecting to the meeting…")}>{waitingIcon}</TooltipWrapper>
			)
		case RecordingStatus.WillRecord:
			return <TooltipWrapper content={t("Rippletide will start recording soon…")}>{waitingIcon}</TooltipWrapper>
		case RecordingStatus.WaitingRoom:
			return (
				<TooltipWrapper content={t("Rippletide is waiting to be accepted into the meeting…")}>
					{waitingIcon}
				</TooltipWrapper>
			)
		case RecordingStatus.InternalError_StatusUnknown:
			return <TooltipWrapper content={t("Internal error")}>{errorIcon}</TooltipWrapper>
		case RecordingStatus.CallEnded_BotReceivedLeaveCall:
		case RecordingStatus.CallEnded_KickedFromCall:
			return <TooltipWrapper content={t("Rippletide was removed from the call")}>{errorIcon}</TooltipWrapper>
		case RecordingStatus.CallEnded_KickedFromWaitingRoom:
			return (
				<TooltipWrapper content={t("Rippletide’s request to join the meeting was rejected")}>
					{errorIcon}
				</TooltipWrapper>
			)
		case RecordingStatus.CallEnded_Timeout_WaitingRoom:
			return (
				<TooltipWrapper content={t("Rippletide was not accepted into the meeting in time")}>
					{errorIcon}
				</TooltipWrapper>
			)
		case RecordingStatus.CallEnded_Timeout_Inactivity:
		case RecordingStatus.CallEnded_Unknown:
		case RecordingStatus.CallEnded_ByHost:
			return <TooltipWrapper content={t("Rippletide is processing the meeting…")}>{errorIcon}</TooltipWrapper>
		case RecordingStatus.InternalError_BotFailure:
			return <TooltipWrapper content={t("Internal error")}>{errorIcon}</TooltipWrapper>
		case RecordingStatus.Done_WithoutVideo:
			return <TooltipWrapper content={t("No recording available")}>{recordingNotAvailableIcon}</TooltipWrapper>
		case RecordingStatus.NoBot:
		case RecordingStatus.ScheduledBot:
			// This should never happen, as the toggle should be shown instead
			return <TooltipWrapper content={t("Internal error")}>{errorIcon}</TooltipWrapper>
		case RecordingStatus.Done_VideoProcessingFailed:
			return <TooltipWrapper content={t("Video processing failed")}>{errorIcon}</TooltipWrapper>
		default:
			assertNever(calendarEvent.botStatus.code, true)
	}
}
