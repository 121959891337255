import type { TFunction } from "../../contexts/language.context"

export function getChartAnnotationForType(type: string, t: TFunction, mode: "horizontal" | "vertical") {
	const minKey = mode === "horizontal" ? "yMin" : "xMin"
	const maxKey = mode === "horizontal" ? "yMax" : "xMax"
	return {
		...(type === "average-call-duration"
			? {
					averageCallAnnotation: {
						type: "box" as const,
						[minKey]: 30,
						[maxKey]: 45,
						backgroundColor: "rgba(34, 197, 94, 0.25)",
						borderColor: "rgba(34, 197, 94, 0.5)",
						label: {
							content: `${t("Market practice:")} 30-45 min`,
							position: "center" as const,
							rotation: mode === "horizontal" ? 0 : 90,
							display: true,
							color: "rgba(0, 0, 0, 0.7)",
						},
					},
			  }
			: undefined),
		...(type === "average-speaking-ratio"
			? {
					averageSpeakingRatioAnnotation: {
						type: "line" as const,
						[minKey]: 43,
						[maxKey]: 43,
						borderWidth: 2,
						borderColor: "rgba(34, 197, 94, 0.5)",
						label: {
							content: `${t("Market practice:")} 43%`,
							position: "center" as const,
							rotation: mode === "horizontal" ? 0 : 90,
							display: true,
							backgroundColor: "rgba(34, 197, 94, 0.25)",
							color: "rgba(0, 0, 0, 0.7)",
						},
					},
			  }
			: undefined),
		...(type === "average-longest-monologue"
			? {
					averageLongestMonologueAnnotation: {
						type: "line" as const,
						[minKey]: 2.5,
						[maxKey]: 2.5,
						borderWidth: 2,
						borderColor: "rgba(34, 197, 94, 0.5)",
						label: {
							content: `${t("Market practice:")} 2.5 min max`,
							position: "center" as const,
							rotation: mode === "horizontal" ? 0 : 90,
							display: true,
							backgroundColor: "rgba(34, 197, 94, 0.25)",
							color: "rgba(0, 0, 0, 0.7)",
						},
					},
			  }
			: undefined),
	}
}
