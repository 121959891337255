import { CheckCircleIcon, ClockIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import _ from "lodash"

import type { MyTrainingProgramSummary } from "../../../../../core/application/gateways/training.gateway/schemas/my-training-program-summary.schema"
import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { useLanguage } from "../../../../contexts/language.context"
import { makeMyTrainingProgramPath } from "../../../../router/Router"
import { DateFormatter, DateProvider } from "../../../../utils/time"

type MyTrainingProgramSummariesProps = {
	summaries: MyTrainingProgramSummary[]
	selectedProgramId?: string
}

export function MyTrainingProgramSummaries({ summaries, selectedProgramId }: MyTrainingProgramSummariesProps) {
	const [completedPrograms, incompletedPrograms] = _.partition(
		summaries,
		(summary) => summary.items.seen === summary.items.total,
	)
	const sortedIncompletePrograms = [...incompletedPrograms].sort((a, b) => {
		return b.enrolledAt.getTime() - a.enrolledAt.getTime()
	})
	const sortedSummaries = [...sortedIncompletePrograms, ...completedPrograms]

	return (
		<aside className="lg:border-0 mb-4 lg:mb-0 mt-2">
			<nav className="sm:flex-none overflow-x-auto w-full">
				<ul role="list" className="gap-x-3 gap-y-1 whitespace-nowrap pb-2 sm:pb-0 justify-center">
					<>
						<ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap flex-col">
							{sortedSummaries.map((item, i) => (
								<SideNavigationItem
									key={i}
									summary={item}
									to={makeMyTrainingProgramPath(item.trainingProgram.id)}
									isActive={selectedProgramId === item.trainingProgram.id}
								/>
							))}
						</ul>
					</>
				</ul>
			</nav>
		</aside>
	)
}

type SideNavigationItemProps = {
	to: string
	isActive: boolean
	summary: MyTrainingProgramSummary
}

export function SideNavigationItem({ summary, to, isActive }: SideNavigationItemProps) {
	const { t, language } = useLanguage()
	const dateProvider = new DateProvider()
	const dateFormatter = new DateFormatter(dateProvider, t)
	const deadline = summary.deadline
		? dateFormatter.formatDate(summary.deadline, language, {
				day: "2-digit",
				month: "2-digit",
				year: "2-digit",
		  })
		: undefined
	const isOverdue = summary.deadline ? summary.deadline < dateProvider.now() : false
	const isCompleted = summary.items.seen === summary.items.total

	return (
		<li key={summary.trainingProgram.id}>
			<TrackingLink
				eventName="my training program navigation"
				to={to}
				className={clsx(
					isActive ? "bg-gray-100 text-navy-500" : "text-gray-700 hover:text-navy-500 hover:bg-indigo-50",
					"group flex flex-col rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold",
				)}
			>
				<div className={clsx("flex gap-x-3 items-center justify-between")}>
					<div className="relative">
						<span className="mr-1">{summary.trainingProgram.name}</span>
						{!summary.wasOpened && (
							<span className="absolute top-0 -right-3 flex h-2 w-2">
								<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-400 opacity-75"></span>
								<span className="relative inline-flex rounded-full h-2 w-2 bg-emerald-400 "></span>
							</span>
						)}
					</div>
					{isCompleted ? (
						<CheckCircleIcon className="h-4 w-4 text-emerald-400" />
					) : (
						<ClockIcon className="h-4 w-4 text-gray-500" />
					)}
				</div>
				<div className="flex items-center gap-x-2 font-normal">
					{deadline && (
						<div className="flex flex-row gap-2 items-center">
							<span className={clsx(isOverdue ? "text-[#D35F3D]" : "text-gray-500", "text-xs")}>
								{t("Due: {{date}}", {
									date: deadline,
								})}
							</span>
							<p className="h-0.5 w-0.5 bg-gray-500 rounded-full" />
						</div>
					)}
					{isCompleted && <span className="text-gray-500 text-xs">{t("Completed")}</span>}
				</div>
			</TrackingLink>
		</li>
	)
}
