import clsx from "clsx"
import React, { useCallback, useEffect, useMemo, useState } from "react"

import type {
	MyTrainingProgramContent,
	MyTrainingProgramItem,
} from "../../../../../core/application/gateways/training.gateway/schemas/my-training-program-content.schema"
import { SpinnerIcon } from "../../../../components/design-system/SpinnerIcon.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useAnalytics } from "../../../../contexts/analytics.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { type TranslationKey, useLanguage } from "../../../../contexts/language.context"
import { DateFormatter, DateProvider } from "../../../../utils/time"
import { TrainingCompletedSuccessModal } from "../components/TrainingCompletedSuccessModal.component"
import { TrainingSectionCard } from "./components/TrainingSectionCard.component"
import { TrainingSectionContentViewer } from "./components/TrainingSectionContentViewer.component"

type ViewTrainingProgramProps = {
	programId: string
	isPreview?: boolean
}

export function ViewTrainingProgram({ programId, isPreview }: ViewTrainingProgramProps) {
	const [trainingProgram, setTrainingProgram] = useState<MyTrainingProgramContent | null>(null)
	const { trainingGateway } = useDependencies()
	const [currentItem, setCurrentItem] = useState<MyTrainingProgramItem | null>(null)
	const { setupEventTracking } = useAnalytics()
	const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

	const dateProvider = new DateProvider()
	const { t } = useLanguage()
	const dateFormatter = new DateFormatter(dateProvider, t)

	useEffect(() => {
		setCurrentItem(null)
	}, [programId])

	const handleSelectItem = useCallback((item: MyTrainingProgramItem) => {
		setCurrentItem(item)
	}, [])

	const refreshProgram = React.useCallback(async () => {
		trainingGateway
			.getMyTrainingProgram(programId)
			.then((program) => {
				setTrainingProgram(program)
			})
			.catch((err) => {
				console.error("Failed to get training program", err)
			})
	}, [programId, trainingGateway])

	useEffect(() => {
		refreshProgram()
	}, [refreshProgram])

	const allItems = useMemo(
		() => trainingProgram?.sections.flatMap((section) => section.items) ?? [],
		[trainingProgram?.sections],
	)

	useEffect(() => {
		if (currentItem) {
			return
		}

		const firstUnseenItem = allItems.find((item) => !item.seen)
		if (firstUnseenItem) {
			setCurrentItem(firstUnseenItem)
		} else {
			setCurrentItem(allItems[0])
		}
	}, [allItems, currentItem])

	const currentItemIndex = currentItem ? allItems.findIndex((item) => item.id === currentItem.id) : null
	const nextItem = currentItemIndex !== null ? allItems[currentItemIndex + 1] : null

	const handleMarkAsSeen = React.useCallback(async () => {
		if (!trainingProgram || !currentItem?.id) {
			return
		}

		if (nextItem) {
			setCurrentItem(nextItem)
		}

		const previousCompletedItems = allItems.filter((item) => item.seen) ?? []
		await trainingGateway.markTrainingItemAsSeen(trainingProgram.trainingProgram.id, currentItem.id)

		const updatedProgram: MyTrainingProgramContent = {
			...trainingProgram,
			sections: [
				...trainingProgram.sections.map((section) => ({
					...section,
					items: section.items.map((item) => (item.id === currentItem.id ? { ...item, seen: true } : item)),
				})),
			],
		}

		const updatedAllItems = updatedProgram.sections.flatMap((section) => section.items)
		const isCompleted = updatedAllItems.every((item) => item.seen)

		const hasJustCompleted =
			isCompleted && previousCompletedItems.length !== updatedAllItems.filter((item) => item.seen).length

		setTrainingProgram(updatedProgram)
		if (hasJustCompleted) {
			setupEventTracking({
				eventName: "Training program completed",
				eventProperties: {
					trainingProgramId: programId,
				},
			}).trackEvent()
			setIsSuccessModalOpen(true)
		}
	}, [allItems, currentItem?.id, nextItem, programId, setupEventTracking, trainingGateway, trainingProgram])

	if (!trainingProgram || !allItems) {
		return <SpinnerIcon className={"h-8 w-8 mx-auto"} />
	}

	const completedItems = allItems.filter((item) => item.seen).length
	const completionRatePercentage = Math.round((completedItems / allItems.length) * 100)
	const hasExceededDeadline = trainingProgram.deadline && dateProvider.now() > trainingProgram.deadline
	const deadline = trainingProgram.deadline ? dateFormatter.getFormattedDate(trainingProgram.deadline) : null
	const hasCompletedAllItems = allItems.every((item) => item.seen)

	const isLastItemToBeSeen = Boolean(!currentItem?.seen && allItems.length - completedItems === 1)
	const goNextLabel: TranslationKey = hasCompletedAllItems
		? "View next"
		: isLastItemToBeSeen
		? "Complete training"
		: nextItem
		? "Mark as seen, view next"
		: "Mark as seen"

	const isLastItem = currentItemIndex === allItems.length - 1
	const canGoToNextItem = !(hasCompletedAllItems && isLastItem)

	return (
		<div>
			<TrainingCompletedSuccessModal onClose={() => setIsSuccessModalOpen(false)} isOpen={isSuccessModalOpen} />
			<div className="flex gap-6 overflow-visible">
				<div className="flex-[6]">
					<div className="sticky top-0 p-4 shadow-sm bg-white z-10">
						<div className="flex gap-6 items-center">
							<h2 className="text-2xl font-semibold text-navy-700">
								{trainingProgram.trainingProgram.name}
							</h2>
							{completionRatePercentage === 100 && (
								<span className="px-2 py-1 text-sm font-semibold bg-green-100 text-green-800 rounded-md">
									{t("Completed")}
								</span>
							)}
							{deadline && completionRatePercentage !== 100 && (
								<span
									className={clsx(
										"px-2 py-1 text-sm font-semibold rounded-md",
										hasExceededDeadline ? "bg-red-100 text-red-800" : "bg-green-100 text-green-800",
									)}
								>
									{t("Deadline: {{date}}", {
										date: deadline.day,
									})}
								</span>
							)}
						</div>
						<div className="flex flex-1 items-center gap-4 mt-2">
							<div className="flex-1 text-gray-900 bg-gray-100 p-2 rounded-md">
								<span className="border-l-2 border-l-gray-500 pl-2">
									{trainingProgram.trainingProgram.description}
								</span>
							</div>
							{currentItem && !isPreview && (
								<TrackingButton
									onClick={handleMarkAsSeen}
									className="flex items-center rounded bg-emerald-500 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-emerald-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed"
									eventName="Go to next item"
									disabled={!canGoToNextItem}
								>
									{t(goNextLabel)}
								</TrackingButton>
							)}
						</div>
					</div>

					{currentItem && (
						<div className="mt-4">
							<TrainingSectionContentViewer item={currentItem} />
						</div>
					)}
				</div>
				<div className="flex-[3] sticky top-0 self-start pt-4">
					{!isPreview && (
						<div className="mb-4 flex">
							<div className="h-2 bg-gray-200 rounded-lg mt-2 flex-1">
								<div
									className="h-full bg-navy-500 rounded-lg"
									style={{ width: `${completionRatePercentage}%` }}
								/>
							</div>
							<span className="text-sm font-semibold ml-2 text-navy-700">
								{completionRatePercentage}%
							</span>
						</div>
					)}

					{trainingProgram.sections.map((section, idx) => (
						<TrainingSectionCard
							trainingSection={section}
							key={idx}
							index={idx}
							onItemClick={handleSelectItem}
							selectedItem={currentItem ?? undefined}
						/>
					))}
				</div>
			</div>
		</div>
	)
}
