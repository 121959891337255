import { Draggable } from "@hello-pangea/dnd"
import { TrashIcon } from "@heroicons/react/24/outline"
import assertNever from "assert-never"
import clsx from "clsx"
import React from "react"

import { TrackingButton } from "../../../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../../../contexts/language.context"
import { getPlainTextFromHtml } from "../../../../../utils/htmlParsing"
import { ContentTypeIcon } from "../../components/ContentTypeIcon.component"
import { DragHandle } from "../../components/DragHandle.component"
import type { DraftTrainingSection } from "../EditContent.page"

type TrainingSectionCardProps = {
	trainingSection: DraftTrainingSection
	trainingIdx: number
	isSelected: boolean
	onTrainingSectionEdit: (trainingSection: DraftTrainingSection) => void
	onTrainingSectionDelete: (trainingSection: DraftTrainingSection) => void
}

export function TrainingSectionCard({
	trainingSection,
	trainingIdx,
	isSelected,
	onTrainingSectionEdit,
	onTrainingSectionDelete,
}: TrainingSectionCardProps) {
	const { t } = useLanguage()

	return (
		<Draggable key={trainingSection.name} draggableId={trainingSection.name} index={trainingIdx}>
			{(provided, snapshot) => (
				<div
					className={clsx(
						"border hover:border-gray-200 p-4 mb-4 rounded-md group",
						snapshot.isDragging ? "shadow-sm border-gray-200" : "border-gray-100",
						isSelected ? "bg-indigo-50" : "bg-white",
					)}
					ref={provided.innerRef}
					{...provided.draggableProps}
					style={provided.draggableProps.style}
				>
					<div className="flex items-center">
						<div {...provided.dragHandleProps}>
							<DragHandle />
						</div>
						<div className="flex flex-row flex-1 gap-2">
							<div className="flex flex-row gap-2 flex-1 items-center">
								<div className="flex items-center justify-between flex-1">
									<TrackingButton
										eventName="Edit training: edit section content"
										className="ml-2 flex flex-row items-center gap-4 text-left"
										onClick={() => onTrainingSectionEdit(trainingSection)}
									>
										<p className="text-md font-semibold text-navy-900">{trainingSection.name}</p>
										<span className="text-xs text-gray-500">
											{t("{{count}} items", {
												count: trainingSection.items.length,
											})}
										</span>
									</TrackingButton>
									<div>
										<TrackingButton
											className="text-sm"
											eventName="delete training section"
											onClick={() => onTrainingSectionDelete(trainingSection)}
										>
											<TrashIcon className="mr-3 h-4 w-4 text-red-500" />
										</TrackingButton>
									</div>
								</div>
							</div>
						</div>
					</div>

					{trainingSection.description && (
						<TrackingButton
							eventName="Edit training: edit section content"
							onClick={() => onTrainingSectionEdit(trainingSection)}
							className="text-left"
						>
							<span className="text-sm text-gray-500 mt-2">{trainingSection.description}</span>
						</TrackingButton>
					)}
					{isSelected && (
						<div className="mt-2 flex flex-col gap-1">
							{trainingSection.items.map((item, idx) => {
								let itemTitle: string
								switch (item.type) {
									case "highlight":
										itemTitle = item.highlight.comment
										break
									case "video":
									case "pdf":
										itemTitle = item.fileName
										break
									case "text":
										itemTitle = getPlainTextFromHtml(item.text)
										break
									default:
										assertNever(item)
								}

								return (
									<div key={idx} className="flex flex-row gap-2 items-center">
										<ContentTypeIcon className="text-navy-900" contentType={item.type} />

										<p
											key={idx}
											className="text-sm text-navy-900 max-w-[240px] truncate"
											title={item.description || itemTitle}
										>
											{item.description || itemTitle}
										</p>
									</div>
								)
							})}
						</div>
					)}
				</div>
			)}
		</Draggable>
	)
}
