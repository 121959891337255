import { CalendarDaysIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import _, { startCase } from "lodash"
import * as React from "react"

import config from "../../../../config"
import type {
	ListAllWorkspacesRouteResponse,
	ListAllWorkspacesRouteResponseUser,
} from "../../../../core/application/gateways/platform-admin.gateway"
import { Badge } from "../../../components/design-system/Badge.component"
import { TrackingExternalHref } from "../../../components/design-system/TrackingExternalHref.component"
import { UserWorkspaceRoleBadge } from "../../../components/shared/UserWorkspaceRoleBadge.component"
import { useAnalytics } from "../../../contexts/analytics.context"
import { useLanguage } from "../../../contexts/language.context"
import { DateFormatter, DateProvider } from "../../../utils/time"
import { roleTranslationKeyByRole } from "../../Settings/Workspace/UsersSettings/UserWorkspaceRoleDropdown.component"
import { UserActivityTimeline } from "./UserActivityTimeline.component"

export type WorkspaceTableProps = {
	workspaces: ListAllWorkspacesRouteResponse
}

export function WorkspacesTable({ workspaces }: WorkspaceTableProps) {
	const { t, language } = useLanguage()
	const [search, setSearch] = React.useState("")
	const dateFormatter = new DateFormatter(new DateProvider(), t)
	const { setupEventTracking } = useAnalytics()

	const handleSearchChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.currentTarget.value.trimStart())
	}, [])

	const handleSearchBlur = React.useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			const searchValue = event.target.value
			if (!searchValue) {
				return
			}

			const { trackEvent: trackedUserSearch } = setupEventTracking({
				eventName: "Workspace settings: User searched",
				eventProperties: { searchValue },
			})
			trackedUserSearch()
		},
		[setupEventTracking],
	)

	const displayedWorkspaces = search
		? workspaces.filter((workspace) => {
				const users = workspace.users.filter((user) => isUserMatchingSearch(user, search))
				const hasMatchingUsers = users.length > 0
				const isMatchingWorkspaceName =
					workspace.name?.toLowerCase().includes(search.trim().toLowerCase()) ??
					workspace.details?.companyName?.toLowerCase().includes(search.trim().toLowerCase()) ??
					false
				const isMatchingWorkspaceId = workspace.id.toLowerCase().includes(search.toLowerCase())
				const isMatchingWorkspaceEmailDomains = workspace.emailDomains.some((domain) =>
					domain.toLowerCase().includes(search.toLowerCase()),
				)
				return (
					hasMatchingUsers ||
					isMatchingWorkspaceName ||
					isMatchingWorkspaceId ||
					isMatchingWorkspaceEmailDomains
				)
		  })
		: workspaces

	return (
		<div className="pr-4">
			<input
				id="search"
				name="search"
				type="search"
				value={search}
				onChange={handleSearchChange}
				placeholder="Search users or workspaces"
				className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 mb-4"
				onBlur={handleSearchBlur}
			/>
			{displayedWorkspaces.map(({ id, name: _name, users, planId, subscription, createdAt, details }) => {
				const allUsersDomain = Array.from(new Set(users.map((user) => user.email.split("@")[1])))
				const name =
					details?.companyName ?? _name ?? (allUsersDomain.length > 0 ? allUsersDomain.join(", ") : id)
				const shouldDisplayAllUsers = _name && search.toLowerCase() === _name.toLowerCase()

				const displayedUsers =
					search && !shouldDisplayAllUsers
						? users.filter((user) => isUserMatchingSearch(user, search))
						: users

				const sortedUsers = displayedUsers.sort((a, b) => {
					return b.recentCalls.length - a.recentCalls.length
				})

				const detailsToDisplay = details
					? _.omit(
							Object.fromEntries(Object.entries(details).filter(([_key, value]) => value)),
							"companyName",
					  )
					: null

				return (
					<div key={id} className="mt-4 py-4 border-t border-gray-200">
						<div className="flex items-center mb-1">
							<p title={id} className="text-base font-semibold leading-7 text-gray-900">
								<span className="uppercase">{name}</span> ({users.length} users)
							</p>
							<Badge
								label={makePlanNameFromPlanId(planId, subscription)}
								variant="info"
								className="ml-2"
							/>
						</div>
						<p className="text-xs text-gray-500">
							Created{" "}
							{dateFormatter.formatDate(createdAt, "en", {
								day: "2-digit",
								month: "2-digit",
								year: "2-digit",
							})}{" "}
							({dateFormatter.formatToRelativeTimeLabel(createdAt, "en")})
						</p>

						{detailsToDisplay && (
							<div className="flex gap-2 items-center text-xs text-gray-500">
								{Object.entries(detailsToDisplay).map(([key, value], index) => (
									<div className="flex">
										{index !== 0 && (
											<div className="flex flex-col items-center justify-center mr-2">
												<span className="h-1 w-1 bg-gray-300 rounded-full"></span>
											</div>
										)}

										<span>{key}:</span>
										<span className="ml-1 font-semibold">{value}</span>
									</div>
								))}
							</div>
						)}

						{subscription && (
							<p className="px-4 text-sm leading-5 text-gray-500 mb-2">
								<span
									className={clsx(
										subscription.status === "trialing" && "text-blue-700",
										subscription.status === "active" && "text-green-600",
										["past_due", "canceled", "unpaid", "incomplete", "incomplete_expired"].includes(
											subscription.status,
										) && "text-red-600",
										"font-semibold uppercase",
									)}
								>
									{subscription.status}
								</span>{" "}
								{subscription.status === "trialing" ? "started" : "subscribed"}{" "}
								<time
									dateTime={subscription.startedAt.toISOString()}
									title={subscription.startedAt.toLocaleString()}
									className="font-semibold"
								>
									{dateFormatter.formatToRelativeTimeLabel(subscription.startedAt, "en")}
								</time>
								{", "}
								{subscription.status === "trialing" ? "ends on" : "renews on"}{" "}
								<time
									dateTime={subscription.endAt.toISOString()}
									title={subscription.endAt.toLocaleString()}
									className="font-semibold"
								>
									{subscription.endAt.toLocaleDateString(language)}
								</time>
								{subscription.paymentProviderSubscriptionId && (
									<div className="inline-block">
										<TrackingExternalHref
											className="bg-white border px-2 py-1 rounded-md text-xs font-semibold ml-2"
											href={makeStripeSubscriptionUrl(subscription.paymentProviderSubscriptionId)}
											eventName="Workspace admin: View subscription on Stripe"
										>
											View on Stripe
										</TrackingExternalHref>
									</div>
								)}
							</p>
						)}
						<ul
							role="list"
							className="px-4 divide-y divide-gray-100 overflow-hidden bg-white sm:rounded-xl max-h-[400px] overflow-y-auto"
						>
							{sortedUsers.map((user) => {
								const sortedRecentCalls = user.recentCalls.sort((a, b) => {
									return (b.createdAt?.getTime() ?? 0) - (a.createdAt?.getTime() ?? 0)
								})
								const fullName =
									user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.email

								return (
									<li
										key={user.email}
										className="relative flex justify-between gap-x-6 p-4 hover:bg-gray-50"
									>
										<div className="flex flex-col w-full">
											<div className="flex justify-between w-full">
												<div className="flex min-w-0 gap-x-4">
													<div className="min-w-0 flex-auto">
														<p className="text-sm  text-gray-700 flex items-center gap-1">
															<span className="leading-6 font-semibold text-gray-900">
																{fullName}
															</span>
															{"  -  "}
															<UserWorkspaceRoleBadge
																workspaceRole={user.workspaceRole}
															/>
															{t(roleTranslationKeyByRole[user.workspaceRole])}
															{user.calendarStatus && (
																<>
																	{"  -  "}
																	<CalendarDaysIcon
																		className={clsx(
																			"h-4 w-4 inline-block",
																			user.calendarStatus === "connected" &&
																				"text-navy-500",
																			user.calendarStatus === "connecting" &&
																				"text-gray-400",
																			user.calendarStatus === "disconnected" &&
																				"text-red-600",
																		)}
																	/>
																	{startCase(user.calendarStatus)}
																</>
															)}
														</p>
														<p className="mt-1 truncate text-xs leading-5 text-gray-500">
															{user.email}
															{user.isUnclaimed && ` (unclaimed)`}
														</p>
													</div>
												</div>
												<div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
													<p className="mt-1 text-xs leading-5 text-gray-500">
														{t("Last login:")}{" "}
														<span className="font-semibold">
															{user.lastLoginAt ? (
																<time
																	dateTime={user.lastLoginAt.toISOString()}
																	title={user.lastLoginAt.toLocaleString()}
																>
																	{dateFormatter.formatToRelativeTimeLabel(
																		user.lastLoginAt,
																		"en",
																	)}
																</time>
															) : (
																t("never")
															)}
														</span>
													</p>
												</div>
											</div>
											<div className="mt-2">
												<UserActivityTimeline
													recentCalls={sortedRecentCalls}
													coachingSessions={undefined} // always undefined, training is disabled
													trialStart={subscription?.startedAt}
													trialEnd={subscription?.endAt}
												/>
											</div>
										</div>
									</li>
								)
							})}
						</ul>
					</div>
				)
			})}
		</div>
	)
}

function isUserMatchingSearch(user: ListAllWorkspacesRouteResponseUser, search: string) {
	return (
		user.firstName.toLowerCase().includes(search.trim().toLowerCase()) ||
		user.lastName.toLowerCase().includes(search.trim().toLowerCase()) ||
		user.email.toLowerCase().includes(search.trim().toLowerCase())
	)
}

function makePlanNameFromPlanId(
	planId: string | null,
	subscription: ListAllWorkspacesRouteResponse[0]["subscription"],
) {
	const planName = _makePlanNameFromPlanId(planId)
	const trialMention = planId?.includes("trial") || subscription?.status === "trialing" ? " trial" : ""
	const isBilledManually = Boolean(planId && !subscription && !planId.includes("trial") && !planId.includes("coach"))
	return `${planName}${trialMention}${isBilledManually ? " (Billed manually)" : ""}`
}

function _makePlanNameFromPlanId(planId: string | null) {
	if (typeof planId !== "string") {
		return "Read only"
	}

	const planNoNumber = planId.split("-")[0]
	const planName = _.startCase(planNoNumber)
	if (planName.toLowerCase().includes("coach")) {
		// legacy plan
		return "Starter"
	}

	return planName
}

function makeStripeSubscriptionUrl(subscriptionId: string) {
	return config.env === "development"
		? `https://dashboard.stripe.com/test/subscriptions/${subscriptionId}`
		: `https://dashboard.stripe.com/subscriptions/${subscriptionId}`
}
