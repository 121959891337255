import { XCircleIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"

import { useLanguage } from "../../contexts/language.context"
import { TrackingButton } from "../design-system/TrackingButton.component"

export const LanguageSwitcher: React.FC = () => {
	const { language, handleAdminLanguageOverride, supportedLanguages, isLanguageOverridden } = useLanguage()

	return (
		<div className="text-white">
			<div className="flex space-x-2">
				{Object.entries(supportedLanguages).map(([lang, { alt, flagIsoCode }]) => (
					<TrackingButton
						eventName="Admin changed language override"
						eventProperties={{
							language: lang,
						}}
						onClick={() => handleAdminLanguageOverride(lang)}
						className="focus:outline-none"
						key={lang}
					>
						<img
							src={`https://flagsapi.com/${flagIsoCode}/flat/64.png`}
							alt={alt}
							className={clsx("w-6 h-6", language === lang && isLanguageOverridden ? "" : "opacity-30")}
						/>
					</TrackingButton>
				))}
				<TrackingButton
					eventName="Admin changed language override"
					eventProperties={{
						language: "None",
					}}
					onClick={() => handleAdminLanguageOverride(null)}
					className="focus:outline-none"
					key="None"
					disabled={!isLanguageOverridden}
				>
					<XCircleIcon
						className={clsx("w-6 h-6", isLanguageOverridden ? "text-gray-900" : "text-gray-500")}
					/>
				</TrackingButton>
			</div>
		</div>
	)
}
