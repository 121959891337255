import React from "react"

import type { CallHighlight } from "../../../../../../core/domain/Library.entity"
import { Modal } from "../../../../../components/design-system/Modal.component"
import { EditHighlightContent } from "./EditHighlightContent.component"

type HighlightSelectModalProps = {
	open: boolean
	onClose: () => void
	onSaveHighlights: (highlights: CallHighlight[]) => void
	alreadySelectedHighlights: CallHighlight[]
}

export function HighlightSelectModal({
	open,
	onClose,
	onSaveHighlights,
	alreadySelectedHighlights,
}: HighlightSelectModalProps) {
	return (
		<Modal
			open={open}
			onClose={onClose}
			className="!max-w-screen-2xl h-[calc(100vh-4rem)] m-12 !overflow-y-auto"
			showCloseButton={true}
		>
			<div className="h-full flex flex-col">
				<EditHighlightContent
					alreadySelectedHighlights={alreadySelectedHighlights}
					onSaveHighlights={onSaveHighlights}
				/>
			</div>
		</Modal>
	)
}
