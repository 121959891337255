import { CheckIcon } from "@heroicons/react/24/outline"
import React from "react"

import { Modal } from "../../../../components/design-system/Modal.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../../contexts/language.context"

type ITrainingCompletedSuccessModalProps = {
	onClose: () => void
	isOpen: boolean
}

export function TrainingCompletedSuccessModal({ onClose, isOpen }: ITrainingCompletedSuccessModalProps) {
	const { t } = useLanguage()

	return (
		<Modal open={isOpen} showCloseButton={false} onClose={onClose} className="!max-w-xl">
			<div>
				<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
					<CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
				</div>
				<div className="mt-3 text-center sm:mt-5">
					<h3 className="text-base font-semibold leading-6 text-gray-900">
						{t("Congratulations, you’ve completed your training program!")}
					</h3>
					<div className="mt-2">
						<p className="text-sm text-gray-500">
							{t(
								"You can return to this page at any time to revisit the content of the training and continuously refresh your skills.",
							)}
						</p>
					</div>
					<div className="flex items-center justify-center mt-4">
						<TrackingButton
							eventName="Training program completed: go back to training button clicked"
							onClick={onClose}
							className="flex items-center rounded bg-indigo-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						>
							{t("Go back to my training")}
						</TrackingButton>
					</div>
				</div>
			</div>
		</Modal>
	)
}
