import React from "react"

import type { TrainingProgramEditViewModel } from "../../../../../core/application/gateways/training.gateway/schemas/training-program-edit-view-model.schema"

type ITrainingProgramContext = {
	trainingProgram: TrainingProgramEditViewModel | null
	programId: string
}

const TrainingProgramContext = React.createContext<ITrainingProgramContext | undefined>(undefined)

export function TrainingProgramProvider({
	children,
	trainingProgram,
	programId,
}: {
	children: React.ReactNode
	trainingProgram: TrainingProgramEditViewModel | null
	programId: string
}) {
	return (
		<TrainingProgramContext.Provider
			value={{
				trainingProgram,
				programId,
			}}
		>
			{children}
		</TrainingProgramContext.Provider>
	)
}

export function useTrainingProgram() {
	const context = React.useContext(TrainingProgramContext)
	if (!context) {
		throw new Error("useTrainingProgram must be used within a TrainingProgramProvider")
	}
	return context
}
