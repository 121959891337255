import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import React from "react"

import type {
	GetSalesRelationshipsResponse,
	Relationship,
} from "../../../../core/application/gateways/relationships.gateway"
import PaginationFooter from "../../../components/shared/PaginationFooter/PaginationFooter.component"
import { useAnalytics } from "../../../contexts/analytics.context"
import { useLanguage } from "../../../contexts/language.context"
import { getRelationshipAccountDisplayName } from "../../../utils/relationship"
import { DateProvider } from "../../../utils/time"
import { formatCurrency } from "../utils"
import { CallActivityTimeline } from "./CallActivityTimeline.component"

export type IDealsTableProps = {
	relationships: GetSalesRelationshipsResponse["relationships"]
	onRelationshipClick: (relationshipId: string, tabToOpen?: "risks" | "call-activity" | null) => void
	onPagePrev: () => void
	onPageNext: () => void
	onPageChange: (page: number) => void
	currentPage: number
	pageSize: number
	totalRelationships: number
}

function TableHeader({ children }: { children: React.ReactNode }) {
	return (
		<th scope="col" className="px-4 py-3.5 text-left text-sm font-normal text-gray-500">
			{children}
		</th>
	)
}

function TableRow({
	children,
	className,
	onClick,
}: {
	children: React.ReactNode
	className?: string
	onClick?: () => void
}) {
	return (
		<td onClick={onClick} className={clsx("px-4 py-4 font-medium text-sm text-navy-700 text-center", className)}>
			{children}
		</td>
	)
}

function OpportunityTableRow({
	relationship,
	children,
	onRelationshipClick,
	className,
}: React.PropsWithChildren<{
	relationship: GetSalesRelationshipsResponse["relationships"][0]
	onRelationshipClick: (relationshipId: string) => void
	className?: string
}>) {
	const { setupEventTracking } = useAnalytics()
	const { trackEvent } = setupEventTracking({
		eventName: "Deals table: opportunity click",
	})

	const handleRowClick = React.useCallback(() => {
		onRelationshipClick(relationship.id)
		trackEvent()
	}, [onRelationshipClick, relationship.id, trackEvent])

	return (
		<TableRow className={clsx("cursor-pointer", className)} onClick={handleRowClick}>
			{children}
		</TableRow>
	)
}

export function DealsTable({
	relationships,
	onRelationshipClick,
	totalRelationships,
	onPageChange,
	pageSize,
	onPageNext,
	onPagePrev,
	currentPage,
}: IDealsTableProps) {
	const dateProvider = new DateProvider()
	const now = dateProvider.now()
	const { t } = useLanguage()

	const sortedRelationships = relationships.sort((a, b) => {
		return b.lastActivityDate && a.lastActivityDate
			? b.lastActivityDate.getTime() - a.lastActivityDate.getTime()
			: 0
	})

	const handleOnSeePreviousCallsClick = React.useCallback(
		(relationship: Relationship) => {
			onRelationshipClick(relationship.id, "call-activity")
		},
		[onRelationshipClick],
	)

	return (
		<div className="border rounded-md border-gray-100 p-2 mt-4">
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<table className="min-w-full divide-y divide-gray-100">
								<thead>
									<tr className="divide-x divide-gray-100">
										<TableHeader>{t("Deals with Rippletide recordings")}</TableHeader>
										<TableHeader>{t("Call activity")}</TableHeader>
										<TableHeader>{t("Risks")}</TableHeader>
										<TableHeader>{t("Stage")}</TableHeader>
										<TableHeader>{t("Value")}</TableHeader>
										<TableHeader>{t("Closing date")}</TableHeader>
										<TableHeader>{t("Probability")}</TableHeader>
										<TableHeader>{t("Last activity")}</TableHeader>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-100 bg-white">
									{sortedRelationships.map((relationship) => {
										const relationshipAccountName = getRelationshipAccountDisplayName(
											relationship,
											t,
										)

										return (
											<tr
												key={relationship.id}
												className="divide-x divide-gray-100 hover:bg-slate-50"
											>
												<OpportunityTableRow
													className="!text-left flex flex-col"
													onRelationshipClick={onRelationshipClick}
													relationship={relationship}
												>
													{relationship.opportunity?.name && (
														<span
															className="truncate max-w-[250px]"
															title={relationship.opportunity.name ?? ""}
														>
															{relationship.opportunity.name}
														</span>
													)}

													<span
														className="text-sm truncate max-w-[250px]"
														title={relationshipAccountName}
													>
														{relationshipAccountName}
													</span>
												</OpportunityTableRow>
												<OpportunityTableRow
													onRelationshipClick={onRelationshipClick}
													relationship={relationship}
													className="w-96 min-w-96"
												>
													<div className="pl-2">
														<CallActivityTimeline
															activities={relationship.activities}
															onSeePreviousCallsClick={(e) => {
																e.stopPropagation()
																handleOnSeePreviousCallsClick(relationship)
															}}
															heightPx={56}
															periodToDisplay="45d"
															className="!ml-0"
														/>
													</div>
												</OpportunityTableRow>
												<OpportunityTableRow
													onRelationshipClick={(opp) => onRelationshipClick(opp, "risks")}
													relationship={relationship}
												>
													{relationship.salesMotionBlockers?.length ? (
														<div className="text-red-500 flex gap-1 items-center justify-center">
															<ExclamationTriangleIcon className="h-4 w-4" />
															{relationship.salesMotionBlockers.length}
														</div>
													) : (
														"-"
													)}
												</OpportunityTableRow>

												<OpportunityTableRow
													onRelationshipClick={onRelationshipClick}
													relationship={relationship}
												>
													{relationship.opportunity?.stageName}
												</OpportunityTableRow>
												<OpportunityTableRow
													onRelationshipClick={onRelationshipClick}
													relationship={relationship}
												>
													{relationship.opportunity?.amount
														? formatCurrency(relationship.opportunity?.amount)
														: "-"}
												</OpportunityTableRow>
												<OpportunityTableRow
													onRelationshipClick={onRelationshipClick}
													relationship={relationship}
													className={clsx(
														relationship.opportunity?.closeDate &&
															relationship.opportunity.closeDate < now
															? "text-red-500"
															: "",
													)}
												>
													{relationship.opportunity?.closeDate?.toLocaleDateString()}
												</OpportunityTableRow>
												<OpportunityTableRow
													onRelationshipClick={onRelationshipClick}
													relationship={relationship}
												>
													{relationship.opportunity?.probability
														? `${relationship.opportunity.probability}%`
														: "-"}
												</OpportunityTableRow>
												<OpportunityTableRow
													onRelationshipClick={onRelationshipClick}
													relationship={relationship}
												>
													{relationship.lastActivityDate
														? relationship.lastActivityDate.toLocaleDateString()
														: "-"}
												</OpportunityTableRow>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<PaginationFooter
				navigatePrev={onPagePrev}
				navigateNext={onPageNext}
				navigateToPage={onPageChange}
				currentPage={currentPage}
				pageSize={pageSize}
				totalItems={totalRelationships}
			/>
		</div>
	)
}
