import {
	AdjustmentsHorizontalIcon,
	BanknotesIcon,
	CalendarIcon,
	CubeIcon,
	DocumentTextIcon,
	TagIcon,
	UserCircleIcon,
	UserGroupIcon,
	UsersIcon,
} from "@heroicons/react/24/outline"

import { PlanBadge } from "../../../components/shared/PlanBadge.component"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useLanguage } from "../../../contexts/language.context"
import { makeUserSettingsPath, makeWorkspaceSettingsPath, type SettingsScope, type SettingsType } from "../config"
import { SideNavigationSection, type SideNavigationSectionProps } from "./SideNavigationSection.component"

export type SettingsSideNavigationPanelProps = {
	settingsScope: SettingsScope
	settingsType: SettingsType
}

export function SettingsSideNavigationPanel({ settingsScope, settingsType }: SettingsSideNavigationPanelProps) {
	const { user, workspace } = useAuthenticatedSession()
	const { t } = useLanguage()

	const isCalendarIntegrationEnabled = workspace.isPlanFeatureEnabled("calendar-integration")
	const isCrmIntegrationEnabled = workspace.isPlanFeatureEnabled("crm-integration")

	const navigationItems: Array<SideNavigationSectionProps> = [
		{
			title: t("Personal"),
			items: [
				{
					name: t("Profile"),
					to: makeUserSettingsPath("profile"),
					Icon: UserCircleIcon,
					isActive: settingsScope === "user" && settingsType === "profile",
				},
				{
					name: t("Calendar"),
					to: makeUserSettingsPath("calendar"),
					Icon: CalendarIcon,
					isActive: settingsScope === "user" && settingsType === "calendar",
					Badge: !isCalendarIntegrationEnabled ? (
						<PlanBadge source="settings: calendar sidebar" minimumPlanName="Flex" />
					) : undefined,
				},
				// {
				// 	name: t("Recording"),
				// 	to: makeUserSettingsPath("recording"),
				// 	Icon: VideoCameraIcon,
				// 	isActive: settingsScope === "user" && settingsType === "recording",
				// },
			],
		},
		{
			title: t("Workspace"),
			items: [
				...(user.isWorkspaceManager()
					? [
							{
								name: t("Preferences"),
								to: makeWorkspaceSettingsPath("preferences"),
								Icon: AdjustmentsHorizontalIcon,
								isActive: settingsScope === "workspace" && settingsType === "preferences",
							},
							{
								name: t("Users"),
								to: makeWorkspaceSettingsPath("users"),
								Icon: UsersIcon,
								isActive: settingsScope === "workspace" && settingsType === "users",
							},
							{
								name: t("Teams"),
								to: makeWorkspaceSettingsPath("teams"),
								Icon: UserGroupIcon,
								isActive: settingsScope === "workspace" && settingsType === "teams",
							},
							{
								name: t("Tags"),
								to: makeWorkspaceSettingsPath("tags"),
								Icon: TagIcon,
								isActive: settingsScope === "workspace" && settingsType === "tags",
							},
							{
								name: t("Meeting notes templates"),
								to: makeWorkspaceSettingsPath("templates"),
								Icon: DocumentTextIcon,
								isActive: settingsScope === "workspace" && settingsType === "templates",
							},
					  ]
					: []),
				...(user.isWorkspaceOwner()
					? [
							{
								name: t("CRM"),
								to: makeWorkspaceSettingsPath("crm"),
								Icon: CubeIcon,
								isActive: settingsScope === "workspace" && settingsType === "crm",
								Badge: !isCrmIntegrationEnabled ? (
									<PlanBadge source="settings: crm sidebar" minimumPlanName="Flex" />
								) : undefined,
							},
					  ]
					: []),
				{
					name: t("Billing"),
					to: makeWorkspaceSettingsPath("billing"),
					Icon: BanknotesIcon,
					isActive: settingsScope === "workspace" && settingsType === "billing",
				},
			],
		},
	]

	return (
		<aside className="flex lg:block lg:w-64 lg:flex-none lg:border-0 mb-4 lg:mb-0">
			<nav className="sm:block flex sm:flex-none px-4 sm:px-6 lg:px-0 overflow-x-auto w-full">
				<ul
					role="list"
					className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col pb-2 sm:pb-0 justify-center"
				>
					{navigationItems.map((section) => (
						<SideNavigationSection key={section.title} {...section} />
					))}
				</ul>
			</nav>
		</aside>
	)
}
