import React, { useEffect, useState } from "react"

import type { Workspace } from "../../../../core/domain/Workspace.entity"
import { Input } from "../../../components/design-system/Input"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { type TranslationKey, useLanguage } from "../../../contexts/language.context"
import { StepCard } from "../components/StepCard.component"

type AccountSetupProps = {
	onComplete: () => void
}

const roleOptionsMap: Record<string, TranslationKey> = {
	"Chief Revenue Officer": "Chief Revenue Officer",
	"Head of Sales": "Head of Sales",
	"Sales Manager": "Sales Manager",
	"Sales Enablement": "Sales Enablement",
	"Account Executive": "Account Executive",
	"Business Development Representative": "Business Development Representative",
	"Sales Development Representative": "Sales Development Representative",
	"Customer Success Manager": "Customer Success Manager",
	Other: "Other",
}

const roleOptions = Object.keys(roleOptionsMap)

const goalOptionsMap: Record<string, TranslationKey> = {
	"Call recording & note taking": "Call recording & note taking",
	"Sales training & skill development": "Sales training & skill development",
	"Pipeline management & insights": "Pipeline management & insights",
	Other: "Other",
}

const goalOptions = Object.keys(goalOptionsMap)

function getFormData(workspace: Workspace) {
	let role = ""
	let customRole = ""
	if (workspace.props.details?.role) {
		role = roleOptions.includes(workspace.props.details.role) ? workspace.props.details.role : "Other"
		if (role === "Other") {
			customRole = workspace.props.details.role
		}
	}

	let goal = ""
	let customGoal
	if (workspace.props.details?.goal) {
		goal = goalOptions.includes(workspace.props.details.goal) ? workspace.props.details.goal : "Other"
		if (goal === "Other") {
			customGoal = workspace.props.details.goal
		}
	}

	return {
		companyName: workspace.props.details?.companyName || "",
		companySize: workspace.props.details?.companySize || "",
		role,
		customRole,
		goal,
		customGoal,
	}
}

export function AccountSetup({ onComplete }: AccountSetupProps) {
	const { t } = useLanguage()
	const { workspacesGateway } = useDependencies()
	const { workspace, refetchMe } = useAuthenticatedSession()

	useEffect(() => {
		refetchMe()
	}, [refetchMe])

	const [formData, setFormData] = useState(getFormData(workspace))

	useEffect(() => {
		setFormData(getFormData(workspace))
	}, [workspace])

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}))
	}

	const handleSubmit = async () => {
		await workspacesGateway.updatePreferences({
			companyDetails: {
				companyName: formData.companyName,
				companySize: formData.companySize,
				goal: formData.goal === "Other" ? formData.customGoal : formData.goal,
				role: formData.role === "Other" ? formData.customRole : formData.role,
			},
		})
		onComplete()
	}

	const canContinue = Boolean(formData.companyName && formData.companySize && formData.role && formData.goal)

	return (
		<StepCard
			title={t("Set up your account")}
			onSkip={onComplete}
			canContinue={canContinue}
			onContinue={handleSubmit}
			stepName="account-setup"
		>
			<Input.Group>
				<Input.Label htmlFor="companyName">{t("Company name")}</Input.Label>
				<Input.Input
					id="companyName"
					name="companyName"
					type="text"
					required
					value={formData.companyName}
					onChange={handleInputChange}
				/>
			</Input.Group>

			<Input.Group>
				<Input.Label htmlFor="companySize">{t("Company size")}</Input.Label>
				<Input.Select
					id="companySize"
					name="companySize"
					value={formData.companySize}
					onChange={handleInputChange}
				>
					<Input.SelectOption value="" disabled>
						{t("Select company size")}
					</Input.SelectOption>
					<Input.SelectOption value="1-10">1-10</Input.SelectOption>
					<Input.SelectOption value="11-50">11-50</Input.SelectOption>
					<Input.SelectOption value="51-250">51-250</Input.SelectOption>
					<Input.SelectOption value="251-500">251-500</Input.SelectOption>
					<Input.SelectOption value="500+">500+</Input.SelectOption>
				</Input.Select>
			</Input.Group>

			<Input.Group>
				<Input.Label htmlFor="role">{t("Role")}</Input.Label>
				<Input.Select id="role" name="role" value={formData.role} onChange={handleInputChange}>
					<Input.SelectOption value="" disabled>
						{t("Select your role")}
					</Input.SelectOption>
					{Object.entries(roleOptionsMap).map(([value, label]) => (
						<Input.SelectOption key={value} value={value}>
							{t(label)}
						</Input.SelectOption>
					))}
				</Input.Select>
			</Input.Group>

			{formData.role === "Other" && (
				<Input.Group>
					<Input.Label htmlFor="customRole">{t("Please specify")}</Input.Label>
					<Input.Input
						id="customRole"
						name="customRole"
						type="text"
						value={formData.customRole}
						onChange={handleInputChange}
					/>
				</Input.Group>
			)}

			<Input.Group>
				<Input.Label htmlFor="goal">{t("What do you want to achieve with Rippletide?")}</Input.Label>
				<Input.Select id="goal" name="goal" value={formData.goal} onChange={handleInputChange}>
					<Input.SelectOption value="" disabled>
						{t("Select your goal")}
					</Input.SelectOption>
					{Object.entries(goalOptionsMap).map(([value, label]) => (
						<Input.SelectOption key={value} value={value}>
							{t(label)}
						</Input.SelectOption>
					))}
				</Input.Select>
			</Input.Group>

			{formData.goal === "Other" && (
				<Input.Group>
					<Input.Label htmlFor="customGoal">{t("Please specify")}</Input.Label>
					<Input.Input
						id="customGoal"
						name="customGoal"
						type="text"
						value={formData.customGoal}
						onChange={handleInputChange}
					/>
				</Input.Group>
			)}
		</StepCard>
	)
}
