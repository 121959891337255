import { BookmarkIcon, TrashIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import { useState } from "react"

import type { CallExcerpt } from "../../../../core/domain/CallExcerpt.entity"
import type { CallHighlight } from "../../../../core/domain/Library.entity"
import { useLanguage } from "../../../contexts/language.context"
import { makeLibraryFolderPath, makeLibraryPath } from "../../../router/Router"
import { getContrastingTextColor } from "../../../utils/color"
import { TagTypeBadge } from "../../shared/TagTypeBadge.component"
import { Duration } from "../Duration.component"
import { TrackingButton } from "../TrackingButton.component"
import { TrackingLink } from "../TrackingLink.component"

export type HighlightsTimelineProps = {
	highlights: CallHighlight[]
	totalDurationSeconds: number
	currentHoverTimeSeconds: number | null
	currentTimeSeconds: number
	isDragging: boolean
	callExcerpts: CallExcerpt[] | "loading"
	onExcerptHover: (excerpt: CallExcerpt) => void
	onExcerptUnhover: () => void
	onExcerptDeleteRequest: (excerpt: CallExcerpt) => void
}

export function HighlightsTimeline({
	highlights,
	totalDurationSeconds,
	currentTimeSeconds,
	currentHoverTimeSeconds,
	callExcerpts,
	isDragging,
	onExcerptUnhover,
	onExcerptHover,
	onExcerptDeleteRequest,
}: HighlightsTimelineProps) {
	const { t } = useLanguage()
	const [currentHoveringExcerpt, setCurrentHoveringExcerpt] = useState<CallExcerpt | null>(null)

	const handleExcerptHover = (excerpt: CallExcerpt) => {
		setCurrentHoveringExcerpt(excerpt)
		onExcerptHover(excerpt)
	}

	const handleExcerptUnhover = () => {
		setCurrentHoveringExcerpt(null)
		onExcerptUnhover()
	}

	if (highlights.length === 0) {
		return null
	}

	return (
		<div className="space-y-1 mt-4 mb-4">
			<div className="flex items-center text-blue-500 text-sm font-medium">
				<BookmarkIcon className="w-4 h-4 mr-1" />
				{t("Highlights & tags")}
			</div>
			<div className="relative h-2 bg-blue-100 rounded-full">
				{callExcerpts === "loading"
					? null
					: callExcerpts.map((excerpt, index) => {
							const isFirst = index === 0
							const isLast = index === highlights.length - 1
							const leftOffset = isFirst ? 0 : 4
							const widthOffset = isLast ? 0 : 4

							const excerptDurationSeconds =
								(excerpt.properties.endsAtMs - excerpt.properties.startsAtMs) / 1000
							const excerptDurationPercentComparedToTotal =
								(excerptDurationSeconds / totalDurationSeconds) * 100

							const isShortExcerpt = excerptDurationPercentComparedToTotal <= 10
							const onePercentSeconds = totalDurationSeconds * 0.01

							const adjustedStartTimeSeconds = isShortExcerpt
								? excerpt.properties.startsAtMs / 1000 - onePercentSeconds
								: excerpt.properties.startsAtMs / 1000
							const adjustedEndTimeSeconds = isShortExcerpt
								? excerpt.properties.endsAtMs / 1000 + onePercentSeconds
								: excerpt.properties.endsAtMs / 1000

							const isHighlightPlaying =
								currentTimeSeconds >= adjustedStartTimeSeconds &&
								currentTimeSeconds <= adjustedEndTimeSeconds

							const isHighlightHovered =
								typeof currentHoverTimeSeconds === "number" &&
								currentHoverTimeSeconds >= adjustedStartTimeSeconds &&
								currentHoverTimeSeconds <= adjustedEndTimeSeconds

							const isActive =
								((typeof currentHoverTimeSeconds === "number"
									? isHighlightHovered
									: isHighlightPlaying) &&
									!currentHoveringExcerpt) ||
								currentHoveringExcerpt?.properties.id === excerpt.properties.id

							return (
								<div key={`HighlightsTimeline-excerpt-${index}`}>
									<div
										key={excerpt.properties.id + "excerpt-bar" + index}
										className={clsx(
											`absolute h-full rounded-full px-1 pt-1`,
											isActive ? "opacity-100" : "opacity-50",
										)}
										style={{
											left: `calc(${calculateSegmentOffset(
												{
													startsAtMs: adjustedStartTimeSeconds * 1000,
												},
												totalDurationSeconds,
											)}% + ${leftOffset}px)`,
											width: `calc(${calculateSegmentWidth(
												{
													startsAtMs: adjustedStartTimeSeconds * 1000,
													endsAtMs: adjustedEndTimeSeconds * 1000,
												},
												totalDurationSeconds,
											)}% - ${widthOffset}px)`,
											backgroundColor: excerpt.properties.tagType.color,
											color: getContrastingTextColor(excerpt.properties.tagType.color),
										}}
									/>
									{isActive && !isDragging && (
										<div
											className={clsx(
												`mt-1 pt-2 absolute flex flex-col items-center justify-center text-blue-500 text-xs font-medium bg-transparent rounded-full w-full`,
											)}
											onMouseEnter={() => handleExcerptHover(excerpt)}
											onMouseLeave={handleExcerptUnhover}
										>
											{isActive &&
												(!currentHoveringExcerpt ||
													currentHoveringExcerpt.properties.id === excerpt.properties.id) && (
													<div
														className="absolute top-2 w-0 h-0 border-l-8 border-r-8 border-b-8 border-l-transparent border-r-transparent border-b-blue-100"
														style={{
															left: `calc(${calculateMiddleOffset(
																excerpt.properties,
																totalDurationSeconds,
															)}% + ${
																leftOffset - 8 // 8 is half of the border width
															}px)`,
														}}
													/>
												)}
											<div className="text-left rounded-lg p-2 mt-2 w-full text-blue-500 bg-blue-100 flex justify-between !cursor-default">
												<div>
													<TagTypeBadge tagType={excerpt.tagType} />
													<span className="font-medium ml-2">
														{t("AI extract")} {t("at")}{" "}
														<Duration seconds={adjustedStartTimeSeconds} />:
													</span>
													<p className="font-normal">
														{excerpt.properties.tagType.description}
													</p>
												</div>
												<div>
													<TrackingButton
														eventName={"Call highlights: delete excerpt clicked"}
														onClick={onExcerptDeleteRequest.bind(null, excerpt)}
													>
														<TrashIcon className="w-4 h-4 text-gray-400" />
													</TrackingButton>
												</div>
											</div>
										</div>
									)}
								</div>
							)
					  })}

				{highlights.map((highlight, index) => {
					const isFirst = index === 0
					const isLast = index === highlights.length - 1
					const leftOffset = isFirst ? 0 : 4
					const widthOffset = isLast ? 0 : 4
					const isHighlightPlaying =
						currentTimeSeconds >= highlight.startsAtMs / 1000 &&
						currentTimeSeconds <= highlight.endsAtMs / 1000
					const isHighlightHovered =
						typeof currentHoverTimeSeconds === "number" &&
						currentHoverTimeSeconds >= highlight.startsAtMs / 1000 &&
						currentHoverTimeSeconds <= highlight.endsAtMs / 1000

					const isActive =
						(typeof currentHoverTimeSeconds === "number" ? isHighlightHovered : isHighlightPlaying) &&
						!currentHoveringExcerpt

					return (
						<div key={`HighlightsTimeline-fragment-${index}`}>
							<div
								key={highlight.id + "bar" + index}
								className={clsx(
									`absolute h-full rounded-full bg-blue-600 px-1 pt-1`,
									isActive ? "opacity-100" : "opacity-50",
								)}
								style={{
									left: `calc(${calculateSegmentOffset(
										highlight,
										totalDurationSeconds,
									)}% + ${leftOffset}px)`,
									width: `calc(${calculateSegmentWidth(
										highlight,
										totalDurationSeconds,
									)}% - ${widthOffset}px)`,
								}}
							/>
							{isActive && !isDragging && (
								<TrackingLink
									to={
										highlight.containingLibraryFolderId
											? makeLibraryFolderPath(highlight.containingLibraryFolderId)
											: makeLibraryPath()
									}
									eventName="Call highlights: highlight clicked"
									key={highlight.id + "label" + index}
									className={clsx(
										`mt-1 pt-2 absolute flex flex-col items-center justify-center text-blue-500 text-xs font-medium bg-transparent rounded-full w-full`,
									)}
								>
									<div
										className="absolute top-2 w-0 h-0 border-l-8 border-r-8 border-b-8 border-l-transparent border-r-transparent border-b-blue-100"
										style={{
											left: `calc(${calculateMiddleOffset(highlight, totalDurationSeconds)}% + ${
												leftOffset - 8 // 8 is half of the border width
											}px)`,
										}}
									></div>
									<div className="text-left bg-blue-100 rounded-lg p-2 mt-2 w-full">
										<span className="font-medium">
											{highlight.creatorFullName ?? t("Deleted user")} {t("at")}{" "}
											<Duration seconds={highlight.startsAtMs / 1000} />:
										</span>
										<p className="font-normal">{highlight.comment}</p>
									</div>
								</TrackingLink>
							)}
						</div>
					)
				})}
			</div>
		</div>
	)
}

const calculateSegmentWidth = (highlight: { startsAtMs: number; endsAtMs: number }, totalDurationSeconds: number) => {
	const width = ((highlight.endsAtMs / 1000 - highlight.startsAtMs / 1000) / totalDurationSeconds) * 100
	return width
}

const calculateSegmentOffset = (highlight: { startsAtMs: number }, totalDurationSeconds: number) => {
	return (highlight.startsAtMs / 1000 / totalDurationSeconds) * 100
}

const calculateMiddleOffset = (highlight: { startsAtMs: number; endsAtMs: number }, totalDurationSeconds: number) => {
	const middleSec = (highlight.startsAtMs + highlight.endsAtMs) / 2 / 1000
	return (middleSec / totalDurationSeconds) * 100
}
