import clsx from "clsx"
import React from "react"
import { Trans } from "react-i18next"
import { useLocalStorage } from "react-use"

import type {
	GetRelationshipsResponse,
	IGetRelationshipsFilter,
} from "../../../../core/application/gateways/relationships.gateway"
import Banner from "../../../components/design-system/Banner.component"
import { SpinnerIcon } from "../../../components/design-system/SpinnerIcon.component"
import { TrackingLink } from "../../../components/design-system/TrackingLink.component"
import { InsightCard } from "../../../components/shared/InsightCard.component"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { useLanguage } from "../../../contexts/language.context"
import { useRelationshipsContext } from "../../../contexts/relationships.context"
import { useWorkspaceUsers } from "../../../contexts/workspace-users.context"
import { useQueryParamsState } from "../../../hooks/useQueryParamsState"
import { DateProvider } from "../../../utils/time"
import { makeWorkspaceSettingsPath } from "../../Settings/config"
import { type DealClosingDateFilter, DealFilters, type DealSelectFilter } from "../components/DealFilters.component"
import { DealListEmptyStateOverlay } from "../components/DealListEmptyStateOverlay.component"
import { DealsTable } from "../components/DealsTable.component"
import { DealUpgradePlanOverlay } from "../components/DealUpgradePlanOverlay.component"
import { makeListViewDemoData } from "../demo"
import { computeClosingDateFromFilter } from "../useDealFilters"
import { formatCurrency } from "../utils"

const PAGE_SIZE = 15

export function useRelationshipFilters() {
	const [selectedDeals, setSelectedDeals] = useQueryParamsState<DealSelectFilter>("deal-type", "all")
	const [selectedUserId, setSelectedUserId] = useQueryParamsState<string | null>("user", null)
	const [selectedClosingDate, setSelectedClosingDate] = useQueryParamsState<DealClosingDateFilter>(
		"closing-date",
		"3-months",
	)
	const [page, setPage] = useQueryParamsState<number>("page", 1)

	const filter: IGetRelationshipsFilter = React.useMemo(
		() => ({
			ownerUserId: selectedUserId ?? undefined,
			closingDateEnd: computeClosingDateFromFilter(selectedClosingDate, new DateProvider()),
			pageSize: PAGE_SIZE,
			pageNumber: page,
			onlyShowAtRisk: selectedDeals === "at-risk",
		}),
		[selectedUserId, selectedClosingDate, page, selectedDeals],
	)

	const handlePageChange = React.useCallback(
		(newPage: number) => {
			setPage(newPage)
		},
		[setPage],
	)

	const handlePageNext = React.useCallback(() => {
		setPage((prevPage) => prevPage + 1)
	}, [setPage])

	const handlePagePrevious = React.useCallback(() => {
		setPage((prevPage) => prevPage - 1)
	}, [setPage])

	const handleSelectClosingDate = React.useCallback(
		(closingDate: DealClosingDateFilter) => {
			setSelectedClosingDate(closingDate)
			setPage(1)
		},
		[setSelectedClosingDate, setPage],
	)

	const handleSelectUser = React.useCallback(
		(userId: string | null) => {
			setSelectedUserId(userId)
			setPage(1)
		},
		[setSelectedUserId, setPage],
	)

	const handleSelectDeals = React.useCallback(
		(deals: DealSelectFilter) => {
			setSelectedDeals(deals)
			setPage(1)
		},
		[setSelectedDeals, setPage],
	)

	const hasUpdatedFilter = React.useMemo(() => {
		return selectedDeals !== "all" || selectedUserId !== null || selectedClosingDate !== "3-months" || page !== 1
	}, [selectedDeals, selectedUserId, selectedClosingDate, page])

	return {
		filter,
		dealsToShow: selectedDeals,
		onSelectDeals: handleSelectDeals,
		onSelectUser: handleSelectUser,
		onSelectClosingDate: handleSelectClosingDate,
		selectedClosingDate,
		selectedUserId,
		selectedDeals,
		onPageChange: handlePageChange,
		page,
		pageSize: PAGE_SIZE,
		onPageNext: handlePageNext,
		onPagePrevious: handlePagePrevious,
		hasUpdatedFilter,
	}
}

export function DealsListView() {
	const { relationshipsGateway } = useDependencies()
	const { t } = useLanguage()
	const [relationshipsResponse, setRelationshipsResponse] = React.useState<GetRelationshipsResponse | null>(null)
	const [isLoading, setIsLoading] = React.useState(true)
	const { user, workspace } = useAuthenticatedSession()
	const { showRelationshipDetailsById } = useRelationshipsContext()
	const { workspaceUsers } = useWorkspaceUsers()
	const hasCrmIntegration = workspace.hasCrmIntegrationSetup
	const [isCrmBannerDismissed, setIsCrmBannerDismissed] = useLocalStorage<boolean>("crm-banner-dismissed", false)
	const [showEmptyState, setShowEmptyState] = React.useState(false)
	const isUpgradeRequired = !workspace.isPlanFeatureEnabled("deals")

	const {
		selectedClosingDate,
		selectedDeals,
		selectedUserId,
		onSelectClosingDate,
		onSelectDeals,
		onSelectUser,
		filter,
		onPageChange,
		onPageNext,
		onPagePrevious,
		hasUpdatedFilter,
	} = useRelationshipFilters()

	React.useEffect(() => {
		const fetchRelationships = async () => {
			setShowEmptyState(false)
			if (isUpgradeRequired) {
				return makeListViewDemoData()
			}

			const result = await relationshipsGateway.getRelationships(filter)
			if (result.total === 0 && !hasUpdatedFilter) {
				setShowEmptyState(true)
				return makeListViewDemoData()
			}

			return result
		}

		setIsLoading(true)
		fetchRelationships()
			.then((r) => setRelationshipsResponse(r))
			.finally(() => setIsLoading(false))
	}, [isUpgradeRequired, filter, relationshipsGateway, hasUpdatedFilter])

	const handleOpportunityClick = React.useCallback(
		(relationshipId: string, tabToOpen?: "risks" | "call-activity" | null) => {
			showRelationshipDetailsById(relationshipId, tabToOpen ?? null)
		},
		[showRelationshipDetailsById],
	)

	if (!relationshipsResponse) {
		return (
			<div className="flex items-center justify-center flex-1">
				<SpinnerIcon className="h-8 max-h-8 max-w-8 w-8 mr-2 flex-initial" />
			</div>
		)
	}

	const totalAmountDealsAtRisk = relationshipsResponse.relationships.reduce((acc, relationship) => {
		if (relationship.salesMotionBlockers?.length) {
			return acc + (relationship.opportunity?.amount ?? 0)
		}
		return acc
	}, 0)

	const totalAmount = relationshipsResponse.relationships.reduce((acc, relationship) => {
		return acc + (relationship.opportunity?.amount ?? 0)
	}, 0)

	const totalDealsCount = relationshipsResponse.relationships.length
	const averageDealSize = totalDealsCount ? totalAmount / totalDealsCount : 0

	return (
		<div
			className={clsx(
				"relative",
				(isUpgradeRequired || showEmptyState) && "max-h-[calc(100vh-184px)] overflow-y-hidden",
			)}
		>
			{isUpgradeRequired && <DealUpgradePlanOverlay />}
			{showEmptyState && <DealListEmptyStateOverlay />}

			<div className="relative">
				{isLoading && (
					<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
						<SpinnerIcon className="h-8 max-h-8 max-w-8 w-8 mr-2 flex-initial" />
					</div>
				)}

				<div className={clsx(isLoading && "opacity-50")}>
					{!hasCrmIntegration &&
						workspace.isPlanFeatureEnabled("crm-integration") &&
						workspace.isPlanFeatureEnabled("deals") &&
						!showEmptyState &&
						!isCrmBannerDismissed && (
							<Banner variant="info" onDismiss={() => setIsCrmBannerDismissed(true)} className="my-2">
								<div className="flex flex-1 items-center">
									<p className="flex-[4]">
										<Trans
											i18nKey="<1>Connect your CRM now</1> to enrich your deals - get more accurate value, closing date, and probability predictions, and more reliable and timely risk identification."
											components={{
												1: <strong />,
											}}
										/>
									</p>

									<div className="flex items-center justify-end">
										<TrackingLink
											to={makeWorkspaceSettingsPath("crm")}
											eventName="Deals list view: connect CRM"
											className="ml-2 border border-white rounded-md px-2 py-1 text-white"
										>
											{t("Go to CRM settings")} &rarr;
										</TrackingLink>
									</div>
								</div>
							</Banner>
						)}
					<div className="mt-6 flex mb-6 sm:mb-0 justify-between">
						{hasCrmIntegration && (
							<InsightCard
								title={t("Active deals")}
								subtitle={
									totalAmountDealsAtRisk ? `${formatCurrency(totalAmountDealsAtRisk)} at risk` : ""
								}
								subtitleClassName="text-red-500 font-medium"
								tooltip={t(
									"Only deals with call recordings in Rippletide are counted and displayed in this view",
								)}
								values={[
									{
										value: `${formatCurrency(totalAmount)} (${totalDealsCount})`,
										label: `${formatCurrency(averageDealSize)} ${t("avg")}`,
										key: "total-deals",
									},
								]}
							/>
						)}

						<DealFilters
							dealOwners={
								workspaceUsers
									?.map((owner) => ({
										id: owner.id,
										name:
											owner.firstName && owner.lastName
												? `${owner.firstName} ${owner.lastName}`
												: owner.email,
									}))
									.filter((x) => Boolean(x.name)) ?? []
							}
							selectedDeals={selectedDeals}
							onSelectDeals={onSelectDeals}
							selectedOwner={selectedUserId}
							onSelectOwner={onSelectUser}
							selectedClosingDate={selectedClosingDate}
							onSelectClosingDate={onSelectClosingDate}
							disabled={isLoading}
							canSelectOwner={user.isWorkspaceManager()}
							canSelectClosingDate={hasCrmIntegration}
						/>
					</div>

					<DealsTable
						relationships={relationshipsResponse.relationships}
						onRelationshipClick={handleOpportunityClick}
						pageSize={filter.pageSize}
						totalRelationships={relationshipsResponse.total}
						currentPage={filter.pageNumber}
						onPageChange={onPageChange}
						onPageNext={onPageNext}
						onPagePrev={onPagePrevious}
					/>
				</div>
			</div>
		</div>
	)
}
