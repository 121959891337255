import { autoUpdate, flip, offset, shift, useFloating, useMergeRefs } from "@floating-ui/react"
import clsx from "clsx"
import React, { useRef, useState } from "react"

type FollowCursorTooltipProps = React.PropsWithChildren<{
	tooltipContent: React.ReactNode
	wrapperClassName?: string
	tooltipClassName?: string
}>

export function FollowCursorTooltip({
	tooltipContent,
	children,
	wrapperClassName,
	tooltipClassName,
}: FollowCursorTooltipProps) {
	const [open, setOpen] = useState(false)
	const [position, setPosition] = useState<{ x: number; y: number } | null>(null)
	const wrapperRef = useRef<HTMLDivElement | null>(null)

	const { refs, floatingStyles, update } = useFloating({
		open,
		onOpenChange: setOpen,
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(8),
			flip({
				fallbackPlacements: ["top", "bottom", "left", "right"],
				padding: 8, // This ensures that the tooltip doesn't go offscreen
			}),
			shift({ padding: 8 }),
		],
		placement: "top", // Default placement
	})

	const mergedReferenceRef = useMergeRefs([refs.setReference, wrapperRef])
	const mergedFloatingRef = useMergeRefs([refs.setFloating])

	const handleMouseMove = (e: React.MouseEvent) => {
		setPosition({ x: e.clientX, y: e.clientY })
		update()
	}

	const handleMouseEnter = () => {
		setOpen(true)
	}

	const handleMouseLeave = () => {
		setOpen(false)
	}

	return (
		<div
			ref={mergedReferenceRef}
			className={clsx("relative", wrapperClassName)}
			onMouseMove={handleMouseMove}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{children}
			{open && position && tooltipContent && (
				<div
					ref={mergedFloatingRef}
					style={{
						...floatingStyles,
						position: "fixed",
						left: `${position.x + 16}px`,
						top: `${position.y + 8}px`,
						transform: "translateY(-100%)",
					}}
					className={clsx(
						"overflow-hidden bg-gray-600 bg-opacity-60 text-white text-xs px-1.5 py-0.5 rounded-md z-20 pointer-events-none",
						tooltipClassName,
					)}
				>
					{tooltipContent}
				</div>
			)}
		</div>
	)
}
