import React from "react"

import { TrackingExternalHref } from "../design-system/TrackingExternalHref.component"
import { TrackingLink } from "../design-system/TrackingLink.component"

export function MarkdownAnchorRenderer(
	props: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
) {
	if (!props.href) {
		return (
			<a {...props} target="_blank" rel="noreferrer">
				{props.children}
			</a>
		)
	}

	if (props.href.startsWith("/")) {
		return (
			<TrackingLink
				to={props.href}
				eventName="Brief: internal link clicked"
				eventProperties={{
					href: props.href,
				}}
			>
				{props.children}
			</TrackingLink>
		)
	}

	return (
		<div className="inline-block">
			<TrackingExternalHref
				eventName="Brief: external link clicked"
				eventProperties={{
					href: props.href,
				}}
				href={props.href}
				target="_blank"
				rel="noreferrer"
			>
				{props.children}
			</TrackingExternalHref>
		</div>
	)
}
