import { Draggable } from "@hello-pangea/dnd"
import clsx from "clsx"
import React from "react"

import type { TrainingSectionContentType } from "../../../../../../core/application/gateways/training.gateway/schemas/training-program-edit-view-model.schema"
import { useLanguage } from "../../../../../contexts/language.context"
import { ContentTypeIcon } from "../../components/ContentTypeIcon.component"
import { DragHandle } from "../../components/DragHandle.component"
import TrainingSectionEditMenu from "./TrainingSectionEditMenu.component"

type SectionContentCardProps = {
	contentType: TrainingSectionContentType
	title: string
	comment?: string
	onCommentChange: (comment: string) => void
	onDelete: () => void
	index: number
	thumbnail?: { url: string; alt: string } | { title: string }
}

export function SectionContentCard({
	contentType,
	comment,
	title,
	onCommentChange,
	onDelete,
	index,
	thumbnail,
}: SectionContentCardProps) {
	const key = `${contentType}-${title}`
	const { t } = useLanguage()
	return (
		<Draggable key={key} draggableId={key} index={index}>
			{(provided, snapshot) => (
				<div
					className={clsx(
						"border p-4 rounded-md group bg-white flex hover:border-gray-300 hover:shadow-sm mb-4",
						snapshot.isDragging ? "shadow-sm border-gray-300" : "border-gray-200",
					)}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					style={provided.draggableProps.style}
				>
					<DragHandle />
					<div className="flex flex-row flex-1 gap-2 items-center">
						<ContentTypeIcon contentType={contentType} />
						{thumbnail && "title" in thumbnail && (
							<div className="w-12 h-12 rounded-md border border-gray-300 bg-gray-50 flex items-center justify-center text-xs font-medium">
								{thumbnail.title.toUpperCase()}
							</div>
						)}

						{thumbnail && "url" in thumbnail && (
							<img
								src={thumbnail.url}
								alt={thumbnail.alt}
								className="w-12 h-12 rounded-md border border-gray-300"
							/>
						)}

						<div className="flex flex-col flex-1 ml-2">
							<span className="border-l-[3px] bg-gray-50 mb-2 text-gray-900 py-0.5 px-2 border-gray-300">
								{title}
							</span>
							<label htmlFor="ownerLastName" className="block text-sm text-gray-600 mt-2">
								{t("Add description (optional)")}
							</label>
							<div className="mt-2">
								<input
									type="text"
									value={comment}
									onChange={(event) => onCommentChange(event.target.value)}
									className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>
						<TrainingSectionEditMenu onDelete={onDelete} />
					</div>
				</div>
			)}
		</Draggable>
	)
}
