import type { CreateCallExcerptPayload, ICallExcerptsGateway } from "../../application/gateways/call-excerpts.gateway"
import { CallExcerpt, callExcerptListSchema } from "../../domain/CallExcerpt.entity"

export class HttpCallExcerptGateway implements ICallExcerptsGateway {
	public constructor(private readonly baseApiUrl: string) {}

	public async getByCall(callId: string) {
		const endpointUrl = `${this.baseApiUrl}/call-excerpts/by-call/${callId}`
		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})
		const json = await res.json()
		const excerpts = callExcerptListSchema.parse(json)
		return excerpts.map((excerpt) => CallExcerpt.fromValidProperties(excerpt))
	}

	public async deleteById(callExcerptId: string) {
		const endpointUrl = `${this.baseApiUrl}/call-excerpts/${callExcerptId}`
		await fetch(endpointUrl, {
			method: "DELETE",
			credentials: "include",
		})
	}

	public async create(payload: CreateCallExcerptPayload) {
		const endpointUrl = `${this.baseApiUrl}/call-excerpts`
		await fetch(endpointUrl, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload),
		})
	}
}
