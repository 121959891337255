import React from "react"

import type { BillingInfo, PlanDetails } from "../../../../../core/application/gateways/billing.gateway"
import { useDependencies } from "../../../../contexts/dependencies.context"

type BillingState =
	| {
			status: "loading" | "idle"
	  }
	| {
			status: "loaded"
			billing: BillingInfo
			plans: PlanDetails[]
	  }
	| {
			status: "error"
			error: Error
	  }

export function useBillingSettings() {
	const [billingState, setBillingState] = React.useState<BillingState>({
		status: "idle",
	})
	const { billingGateway } = useDependencies()

	React.useEffect(() => {
		async function fetchBilling() {
			setBillingState({ status: "loading" })
			try {
				const billingRes = await billingGateway.getBillingInfo()
				const plans = await billingGateway.getPlans()
				setBillingState({
					status: "loaded",
					billing: billingRes,
					plans: plans.filter((x) => !x.id.includes("trial")),
				})
			} catch (error) {
				console.error(error)
				setBillingState({
					status: "error",
					error: error instanceof Error ? error : new Error("An error occurred while fetching billing info."),
				})
			}
		}

		fetchBilling()
	}, [billingGateway])

	return {
		billing: billingState,
	}
}
