import _ from "lodash"

import type { CallHighlight } from "../../../../../core/domain/Library.entity"
import type { CoachingSessionItem } from "../../../../../core/infra/gateways/http.library.gateway/getCoachingSessionsResponse.schema"

// Group highlights by calls, in order to limit the number of times the player has to laod a new video
export function sortHighlights(highlights: CallHighlight[]): CallHighlight[] {
	return _(highlights)
		.groupBy("callId")
		.flatMap((group) => _.sortBy(group, "startsAtMs"))
		.value()
}

// We group highlights by call for the same reason, but we could consider sorting them by theme and ignore the player switching optimization
export function sortCoachingSessionItems(items: CoachingSessionItem[]): CoachingSessionItem[] {
	return _(items)
		.groupBy((item) => item.callHighlight.callId)
		.flatMap((group) => _.sortBy(group, (group) => group.callHighlight.startsAtMs))
		.value()
}
