import type { UpdateHighlightParams } from "../../../../../core/application/gateways/library.gateway"
import type { CallHighlight } from "../../../../../core/domain/Library.entity"
import { useLanguage } from "../../../../contexts/language.context"
import { EntityAgnosticModalUI, type Module, type ModuleData } from "./EntityAgnosticModalUI.component"

type CallHighlightCommentEditionModalProps = {
	isOpen: boolean
	onClose: () => void
	callHighlightUnderEdition: CallHighlight | undefined
	updateHighlight: (callHighlight: CallHighlight, updateHighlightParams: UpdateHighlightParams) => Promise<void>
}

export function CallHighlightCommentEditionModal({
	isOpen,
	onClose,
	callHighlightUnderEdition,
	updateHighlight,
}: CallHighlightCommentEditionModalProps) {
	const { t } = useLanguage()

	if (!callHighlightUnderEdition) return

	const module = {
		type: "text-multiline",
		name: "call-highlight-comment",
		title: t("Comment"),
		defaultValue: callHighlightUnderEdition.comment,
		placeholder: t("What's interesting about this part of the call?"),
		required: true,
	} as const satisfies Module

	const onSubmit = async (formData: ModuleData<[typeof module]>) => {
		await updateHighlight(callHighlightUnderEdition, {
			newComment: formData[module.name],
		})
		onClose()
	}

	return (
		<EntityAgnosticModalUI
			isOpen={isOpen}
			onClose={onClose}
			onSubmit={onSubmit}
			title={t("Edit comment")}
			submitLabel={t("Update")}
			submitEventName="call-highlight-comment-edition"
			modules={[module]}
		/>
	)
}
