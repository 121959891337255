import { z } from "zod"

import { TrainingProgramSchema } from "./training-program.schema"

export const TrainingProgramWithStatsSchema = z.object({
	trainingProgram: TrainingProgramSchema,
	creatorFullName: z.string(),
	enrolments: z.object({
		enrolled: z.number(),
		completed: z.number(),
		late: z.number(),
	}),
})
export type TrainingProgramWithStats = z.infer<typeof TrainingProgramWithStatsSchema>
