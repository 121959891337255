import React, { useEffect } from "react"

import type { ICallWithRelationship } from "../../core/application/gateways/calls.gateway"
import type { Relationship } from "../../core/application/gateways/relationships.gateway"
import type { Call } from "../../core/domain/Call.entity"
import { DEMO_CALL_ID } from "../../core/domain/Call.entity"
import { ApiError } from "../../core/infra/gateways/api-errors"
import { useSession } from "../contexts/authentication.context"
import { useDependencies } from "../contexts/dependencies.context"
import { useLanguage } from "../contexts/language.context"
import { getDemoCall } from "../utils/onboardingDemo/demoCall"
import { DateProvider } from "../utils/time"
import { useInviteToken } from "./useInviteToken"

export type IUseCallOptions = {
	withRelationship?: boolean
}

export function useCall(
	callId: string,
	options?: IUseCallOptions,
): {
	call: Call | undefined
	relationship: Relationship | undefined
	isLoading: boolean
	error: ApiError | undefined
	refetchCall: () => Promise<void>
} {
	const { callsGateway } = useDependencies()
	const [callState, setCallState] = React.useState<
		{ callId: string; state: ICallWithRelationship | ApiError | "loading" } | "idle"
	>("idle")
	const inviteToken = useInviteToken()
	const { user } = useSession()
	const { t, language } = useLanguage()

	const getCall = React.useCallback(async () => {
		const dateProvider = new DateProvider()

		if (callId === DEMO_CALL_ID && user) {
			setCallState({
				callId: DEMO_CALL_ID,
				state: {
					call: getDemoCall(user, dateProvider, t, language),
					relationship: undefined,
				},
			})
			return
		}

		try {
			if (callState === "idle") {
				setCallState({ callId, state: "loading" })
			}
			if (inviteToken) {
				const call = await callsGateway.get(callId, inviteToken)
				setCallState({ callId, state: { call, relationship: undefined } })
			} else if (options?.withRelationship) {
				const callWithRelationship = await callsGateway.getWithRelationship(callId)
				setCallState({ callId, state: callWithRelationship })
			} else {
				const call = await callsGateway.get(callId)
				setCallState({ callId, state: { call, relationship: undefined } })
			}
		} catch (error) {
			if (error instanceof ApiError) {
				setCallState({ callId, state: error })
				return
			}
			console.error(error)
		}
	}, [callId, callState, callsGateway, inviteToken, language, options?.withRelationship, t, user])

	// possible improvement: because of this useEffect, call is fetched three times when component is mounted in LibraryPlaylist
	useEffect(() => {
		if (callState === "idle" || callState.callId !== callId) {
			console.log("Re-fetching call")
			getCall()
		} else {
			console.log("Not re-fetching call")
		}
	}, [callId, getCall, callState])

	if (callState === "idle" || callState.state === "loading") {
		return { call: undefined, relationship: undefined, isLoading: true, error: undefined, refetchCall: getCall }
	}

	if (callState.state instanceof ApiError) {
		return {
			call: undefined,
			relationship: undefined,
			isLoading: false,
			error: callState.state,
			refetchCall: getCall,
		}
	}

	return {
		call: callState.state.call,
		relationship: callState.state.relationship,
		isLoading: false,
		error: undefined,
		refetchCall: getCall,
	}
}
