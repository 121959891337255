import assertNever from "assert-never"
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	type ChartData,
	type ChartOptions,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from "chart.js"
import annotationPlugin from "chartjs-plugin-annotation"
import React from "react"
import { Bar } from "react-chartjs-2"

import type { CallInsightsResponse } from "../../../../core/application/gateways/insights.gateway"
import { SectionCard } from "../../../components/shared/GraphSection/SectionCard.component"
import { useLanguage } from "../../../contexts/language.context"
import { getChartAnnotationForType } from "../getChartAnnotationForType"
import type { InteractionChartType } from "../types"
import { roundToTwoDecimals, secondsToMinutes } from "../utils"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin)

type TeamCallsChartProps = {
	insights: CallInsightsResponse
	selectedUserId: string | undefined
	type: InteractionChartType
}

export function TeamCallsChart({ insights, selectedUserId, type }: TeamCallsChartProps) {
	const { t } = useLanguage()

	const chartData: { data: ChartData<"bar">; annotation: { label: string; value: number } } = React.useMemo(() => {
		if (type === "average-calls") {
			const sortedMembers = insights.workspaceUsers.sort((a, b) => b.totalCalls - a.totalCalls)
			return {
				data: {
					labels: insights.workspaceUsers?.map((x) => `${x.userFirstName} ${x.userLastName}`),
					datasets: [
						{
							label: t("Total recorded calls"),
							data: sortedMembers?.map((x) => x.totalCalls),
							backgroundColor: sortedMembers?.map((x) =>
								x.userId === selectedUserId ? "rgba(38, 38, 86, 0.85)" : "rgba(38, 38, 86, 0.4)",
							),
						},
					],
				},
				annotation: {
					label: t("Team average: {{count}}", {
						count: roundToTwoDecimals(insights.averageCallsPerUser),
					}),
					value: roundToTwoDecimals(insights.averageCallsPerUser),
				},
			}
		}

		if (type === "average-call-recording-rate") {
			const sortedMembers = insights.workspaceUsers.sort(
				(a, b) => b.callRecordingRatePercent - a.callRecordingRatePercent,
			)
			return {
				data: {
					labels: insights.workspaceUsers?.map((x) => `${x.userFirstName} ${x.userLastName}`),
					datasets: [
						{
							label: t("Call recording rate %"),
							data: sortedMembers?.map((x) => roundToTwoDecimals(x.callRecordingRatePercent)),
							backgroundColor: sortedMembers?.map((x) =>
								x.userId === selectedUserId ? "rgba(38, 38, 86, 0.85)" : "rgba(38, 38, 86, 0.4)",
							),
						},
					],
				},
				annotation: {
					label: t("Team average: {{count}}%", {
						count: roundToTwoDecimals(insights.averageCallRecordingRatePercent),
					}),
					value: roundToTwoDecimals(insights.averageCallRecordingRatePercent),
				},
			}
		}

		if (type === "average-call-duration") {
			const sortedMembers = insights.workspaceUsers.sort(
				(a, b) => b.averageCallDurationSec - a.averageCallDurationSec,
			)
			return {
				data: {
					labels: insights.workspaceUsers?.map((x) => `${x.userFirstName} ${x.userLastName}`),
					datasets: [
						{
							label: t("Average call duration (min)"),
							data: sortedMembers?.map((x) => roundToTwoDecimals(x.averageCallDurationSec / 60)),
							backgroundColor: sortedMembers?.map((x) =>
								x.userId === selectedUserId ? "rgba(38, 38, 86, 0.85)" : "rgba(38, 38, 86, 0.4)",
							),
						},
					],
				},
				annotation: {
					label: t("Team average: {{count}} min", {
						count: roundToTwoDecimals(insights.averageCallDurationMinutes),
					}),
					value: roundToTwoDecimals(insights.averageCallDurationMinutes),
				},
			}
		}

		if (type === "average-call-score") {
			const sortedMembers = insights.workspaceUsers.sort((a, b) => b.averageCallScore - a.averageCallScore)

			return {
				data: {
					labels: insights.workspaceUsers?.map((x) => `${x.userFirstName} ${x.userLastName}`),
					datasets: [
						{
							label: t("Average call score"),
							data: sortedMembers?.map((x) => x.averageCallScore),
							backgroundColor: sortedMembers?.map((x) =>
								x.userId === selectedUserId ? "rgba(38, 38, 86, 0.85)" : "rgba(38, 38, 86, 0.4)",
							),
						},
					],
				},
				annotation: {
					label: t("Team average: {{count}}", {
						count: roundToTwoDecimals(insights.averageCallScore),
					}),
					value: roundToTwoDecimals(insights.averageCallScore),
				},
			}
		}

		if (type === "average-speaking-ratio") {
			const sortedMembers = insights.workspaceUsers.sort(
				(a, b) => b.averageSpeakingRatioPercent - a.averageSpeakingRatioPercent,
			)

			return {
				data: {
					labels: insights.workspaceUsers?.map((x) => `${x.userFirstName} ${x.userLastName}`),
					datasets: [
						{
							label: t("Average speaking ratio %"),
							data: sortedMembers?.map((x) => roundToTwoDecimals(x.averageSpeakingRatioPercent)),
							backgroundColor: sortedMembers?.map((x) =>
								x.userId === selectedUserId ? "rgba(38, 38, 86, 0.85)" : "rgba(38, 38, 86, 0.4)",
							),
						},
					],
				},
				annotation: {
					label: t("Team average: {{count}}%", {
						count: roundToTwoDecimals(insights.averageSpeakingRatioPercent),
					}),
					value: roundToTwoDecimals(insights.averageSpeakingRatioPercent),
				},
			}
		}

		if (type === "average-longest-monologue") {
			const sortedMembers = insights.workspaceUsers.sort(
				(a, b) => b.averageLongestMonologueSec - a.averageLongestMonologueSec,
			)

			const averageLongestMonologue = insights.averageLongestMonologueSec
			const { remainingSeconds, minutes } = secondsToMinutes(averageLongestMonologue)
			const averageLongestMonologueMinutes = minutes + remainingSeconds / 60

			return {
				data: {
					labels: insights.workspaceUsers?.map((x) => `${x.userFirstName} ${x.userLastName}`),
					datasets: [
						{
							label: t("Average longest monologue (mins)"),
							data: sortedMembers?.map((x) => {
								const value = x.averageLongestMonologueSec
								const { remainingSeconds, minutes } = secondsToMinutes(value)
								return roundToTwoDecimals(minutes + remainingSeconds / 60)
							}),
							backgroundColor: sortedMembers?.map((x) =>
								x.userId === selectedUserId ? "rgba(38, 38, 86, 0.85)" : "rgba(38, 38, 86, 0.4)",
							),
						},
					],
				},
				annotation: {
					label: t("Team average: {{count}} mins", {
						count: roundToTwoDecimals(averageLongestMonologueMinutes),
					}),
					value: roundToTwoDecimals(averageLongestMonologueMinutes),
				},
			}
		}

		assertNever(type)
	}, [
		insights.averageCallDurationMinutes,
		insights.averageCallRecordingRatePercent,
		insights.averageCallScore,
		insights.averageCallsPerUser,
		insights.averageLongestMonologueSec,
		insights.averageSpeakingRatioPercent,
		insights.workspaceUsers,
		selectedUserId,
		t,
		type,
	])

	const options: ChartOptions<"bar"> = {
		indexAxis: "y",
		plugins: {
			legend: {
				display: false,
			},
			annotation: {
				annotations: {
					...getChartAnnotationForType(type, t, "vertical"),
				},
			},
		},
		scales: {
			x: {
				beginAtZero: true,
			},
		},
		maintainAspectRatio: false,
		responsive: true,
	}

	const labelByType = {
		"average-calls": t("Total calls recorded per workspace member"),
		"average-call-recording-rate": t("Average call recording rate % per user"),
		"average-call-duration": t("Average call duration per user (minutes)"),
		"average-call-score": t("Average call score per user"),
		"average-speaking-ratio": t("Average speaking ratio % per user"),
		"average-longest-monologue": t("Average longest monologue per user (minutes)"),
	}

	return (
		<SectionCard className="h-full">
			<div className="mb-4">
				<h3 className="text-lg font-semibold text-gray-900">{t("Team members")}</h3>
				<span className="text-md font-medium text-gray-500">{labelByType[type]}</span>
			</div>
			<div className="h-[400px]">
				<Bar data={chartData.data} options={options} />
			</div>
		</SectionCard>
	)
}
