import { XMarkIcon } from "@heroicons/react/20/solid"
import React from "react"

import type { Call } from "../../../../../core/domain/Call.entity"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../../contexts/language.context"

type TranscriptionSelectTextTipProps = {
	turns: NonNullable<Call["props"]["transcription"]>["turns"]
	isVisible: boolean
	onHide: () => void
}

export function TranscriptionSelectTextTip({ turns, isVisible, onHide }: TranscriptionSelectTextTipProps) {
	const { t } = useLanguage()

	if (!isVisible || !turns.length) {
		return null
	}

	const transcriptionTurnSample =
		turns.find(
			(turn) =>
				turn.content.length >= 100 &&
				turn.content.length <= 200 &&
				turn.content.includes(".") &&
				!turn.content.endsWith("."),
		) ?? turns[0]

	const endSentenceIndex = transcriptionTurnSample.content.indexOf(".")
	let [firstPart, selectedPart] = [
		transcriptionTurnSample.content.slice(0, endSentenceIndex + 1),
		transcriptionTurnSample.content.slice(endSentenceIndex + 1),
	]

	if (!firstPart || !selectedPart) {
		firstPart = t("Hey! This part is not interesting.")
		selectedPart = t("But this part is very interesting. I want to create a highlight from it!")
	}

	return (
		<div className="border border-indigo-200 rounded-lg my-2 p-2 text-sm">
			<div className="flex justify-between gap-1">
				<div className="text-indigo-500 font-medium">
					💡 {t("Did you know you can create a highlight by selecting the block of text you want to save?")}
				</div>
				<TrackingButton
					className="flex"
					eventName="Transcription: close highlight tip"
					onClick={onHide}
					title={t("Close")}
				>
					<XMarkIcon className="h-4 w-4 text-blue-500" />
				</TrackingButton>
			</div>

			<p className="mt-1">
				{firstPart.endsWith(".") ? firstPart : `${firstPart}.`}
				<span className="text-indigo-600 bg-gradient-to-r from-indigo-100 via-transparent to-indigo-100 bg-[length:200%_100%] animate-sweep">
					{selectedPart}
				</span>
			</p>
		</div>
	)
}
