import React from "react"

import type { TrainingSectionContentType } from "../../../../../../core/application/gateways/training.gateway/schemas/training-program-edit-view-model.schema"
import { useLanguage } from "../../../../../contexts/language.context"
import { AddContentSection } from "./AddContentSection.component"

type EditSectionSelectContentProps = {
	onContentSelect: (contentType: TrainingSectionContentType) => void
}

export function EditSectionSelectContent({ onContentSelect }: EditSectionSelectContentProps) {
	const { t } = useLanguage()
	return (
		<div className="relative block w-full rounded-lg border border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
			<svg
				fill="none"
				stroke="currentColor"
				viewBox="0 0 24 24"
				aria-hidden="true"
				className="mx-auto h-12 w-12 text-gray-400"
			>
				<path
					d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
					strokeWidth={2}
					vectorEffect="non-scaling-stroke"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
			<h3 className="mt-2 text-sm font-semibold text-gray-900">{t("Your section is empty")}</h3>
			<p className="mt-1 text-sm text-gray-500">{t("Get started by adding content.")}</p>
			<div className="mt-6 w-full px-12 m-auto">
				<AddContentSection onContentSelect={onContentSelect} />
			</div>
		</div>
	)
}
