import type { z } from "zod"

import { BaseCall, type BaseCallProperties, publicCallResponseSchema } from "./BaseCall.entity"

type PublicCallApiResponse = z.infer<typeof publicCallResponseSchema>
type PublicCallProperties = Omit<PublicCallApiResponse, "createdAt"> & BaseCallProperties

export class PublicCall extends BaseCall<PublicCallProperties> {
	constructor(_props: PublicCallProperties) {
		super(_props)
	}

	public static fromApiCall(apiPublicCall: unknown): PublicCall {
		const parsedApiPublicCall = publicCallResponseSchema.safeParse(apiPublicCall)
		if (!parsedApiPublicCall.success) {
			console.error(parsedApiPublicCall.error)
			throw new Error("Schema validation failed for PublicCall.fromApiCall")
		}

		const publicCall = parsedApiPublicCall.data
		const createdAt = new Date(publicCall.createdAt)
		const videoFileUrl = publicCall.video?.url

		const upperLanguage = publicCall.language?.toUpperCase()
		// flag "EN" doesn't exist in the country flags, so we use "US" instead
		// all other languages are the same as the country code
		const countryCode = upperLanguage === "EN" ? "US" : upperLanguage

		return new PublicCall({
			...publicCall,
			createdAt,
			countryCode,
			videoFileUrl,
			language: "en",
		})
	}
}
