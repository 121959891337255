import clsx from "clsx"
import { format, isSameDay, startOfDay, subDays } from "date-fns"
import type { ReactNode } from "react"

import type { CoachingSession } from "../../../../core/infra/gateways/http.library.gateway/getCoachingSessionsResponse.schema"

type RecentCall = {
	id: string
	createdAt: Date | null
}

type UserActivityTimelineProps = {
	recentCalls: RecentCall[]
	coachingSessions: CoachingSession[] | undefined
	trialStart?: Date
	trialEnd?: Date
}

export function UserActivityTimeline({
	recentCalls,
	coachingSessions,
	trialStart,
	trialEnd,
}: UserActivityTimelineProps) {
	const today = startOfDay(new Date())
	const days = Array.from({ length: 28 }, (_, i) => subDays(today, i)).reverse()

	const callCounts = days.map((day) => {
		const sameDayCalls = recentCalls.filter((call) => call.createdAt && isSameDay(day, call.createdAt))
		const isWeekend = day.getDay() === 0 || day.getDay() === 6
		const isDayInTrial =
			trialStart && trialEnd && day.getTime() >= trialStart.getTime() && day.getTime() <= trialEnd.getTime()
		return {
			date: day,
			count: sameDayCalls.length,
			isWeekend,
			isDayInTrial,
		}
	})

	return (
		<>
			<div className="flex space-x-1 overflow-x-auto">
				{callCounts.map(({ date, count, isWeekend, isDayInTrial }) => {
					return (
						<div key={date.toISOString()} className="flex flex-col items-center text-center">
							<div
								key={format(date, "yyyy-MM-dd")}
								className={clsx(
									"w-4 h-4",
									getColor(count, isWeekend),
									"rounded-sm text-xs text-center font-semibold",
								)}
							>
								{count > 0 && count}
							</div>
							<div className={clsx("text-xs", isDayInTrial ? "text-blue-700" : "text-gray-500")}>
								<span className="font-medium">{format(date, "MM")}</span>
								<br />
								{format(date, "dd")}
							</div>
						</div>
					)
				})}
			</div>
			{coachingSessions && <UserTrainingStatistics days={days} coachingSessions={coachingSessions} />}
		</>
	)
}

function getColor(count: number, isWeekend: boolean) {
	if (isWeekend && count === 0) return "bg-gray-400"
	if (count === 0) return "bg-gray-200"
	if (count <= 2) return "bg-green-300"
	if (count <= 4) return "bg-green-500"
	return "bg-gray-200"
}

type UserTrainingStatisticsProps = {
	days: Date[]
	coachingSessions: CoachingSession[]
}

function UserTrainingStatistics({ days, coachingSessions }: UserTrainingStatisticsProps) {
	const items: ReactNode[] = []

	const firstMondayIndex = days.findIndex((day) => day.getDay() === 1)
	for (let i = 0; i < days.length; i++) {
		if (i < firstMondayIndex) {
			items.push(
				<div key={i} className="flex flex-col items-center text-center">
					<div className={clsx("w-4 h-4", "rounded-sm text-xs text-center font-semibold")} />
				</div>,
			)
			continue
		}

		const day = days[i]
		if (day.getDay() === 1 && i + 7 <= days.length) {
			const coachingSession = coachingSessions.find((session) => {
				const sessionDate = new Date(session.weekStart)
				return isSameDay(day, sessionDate)
			})

			const type = (() => {
				if (!coachingSession) return "No data"
				if (!coachingSession.items.length) return "No training"
				return "Training"
			})()

			let trainingSeenPct = 0
			if (coachingSession) {
				const seenItems = coachingSession.items.filter((item) => item.seenAt).length
				const totalItems = coachingSession.items.length
				trainingSeenPct = Math.round((seenItems / totalItems) * 100)
			}

			items.push(
				<div key={i} className="flex flex-col items-center text-center text-[0.7rem]">
					{type === "Training" && (
						<div className={clsx("w-[8.5rem] h-4", "bg-purple-100", "rounded-sm")}>
							{type} <span className="text-gray-500 text-[0.5rem]">({trainingSeenPct}% seen)</span>
						</div>
					)}
					{type !== "Training" && (
						<div className={clsx("w-[8.5rem] h-4", "border border-purple-200", "rounded-sm")}>{type}</div>
					)}
					{coachingSession && (
						<div className={clsx("w-[8.5rem] h-8", "rounded-sm text-xs")}>
							{coachingSession && <StatsTable stats={coachingSession} />}
						</div>
					)}
				</div>,
			)
			continue
		}
	}

	return <div className="flex space-x-1">{items}</div>
}

type StatsTableProps = {
	stats: CoachingSession
}

function StatsTable({ stats }: StatsTableProps) {
	return (
		<div className="overflow-auto text-[0.4rem] text-left w-full">
			<table className="table-auto w-full">
				<tbody className="">
					<tr>
						<td className="">AI Highlights</td>
						<td className="">New</td>
						<td className="">In training</td>
					</tr>
					<tr>
						<td className="">Well played</td>
						<td className="">{stats.statistics.aiHighlights.wellPlayed.overall}</td>
						<td className="">{stats.items.filter((item) => item.type === "well-played").length}</td>
					</tr>
					<tr>
						<td className="">Not well played</td>
						<td className="">{stats.statistics.aiHighlights.notWellPlayed.overall}</td>
						<td className="">{stats.items.filter((item) => item.type === "improvement").length}</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}
