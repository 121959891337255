import { z } from "zod"

import { TrainingProgramSchema } from "./training-program.schema"
import { TrainingProgramItemContentSchema } from "./training-program-items.schema"

export type TrainingSectionContentType = z.infer<typeof TrainingProgramItemContentSchema>["type"]

export const TrainingProgramSectionSchema = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string(),
	items: z.array(
		z.object({
			id: z.string(),
			description: z.string(),
			content: TrainingProgramItemContentSchema,
		}),
	),
})

export type TrainingProgramSection = z.infer<typeof TrainingProgramSectionSchema>

export const TrainingProgramEditViewModelSchema = z.object({
	trainingProgram: TrainingProgramSchema,
	sections: z.array(TrainingProgramSectionSchema),
	enrolments: z.array(
		z.object({
			enrolmentId: z.string(),
			userId: z.string(),
			userFullName: z.string(),
			deadline: z.coerce.date().nullish(),
			itemsSeen: z.number(),
		}),
	),
})
export type TrainingProgramEditViewModel = z.infer<typeof TrainingProgramEditViewModelSchema>

export const GetTrainingProgramForEditUrlParamsSchema = z.object({
	programId: z.string(),
})
