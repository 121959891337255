import tinycolor from "tinycolor2"

export function getContrastingTextColor(backgroundColor: string): string {
	const color = tinycolor(backgroundColor)
	const hsl = color.toHsl()

	// Adjust the lightness and saturation for contrast
	if (hsl.l > 0.5) {
		hsl.l -= 0.4 // Darken the color if it's too light
	} else {
		hsl.l += 0.4 // Lighten the color if it's too dark
	}

	hsl.s = Math.min(hsl.s + 0.2, 1) // Slightly increase saturation for better readability

	// Generate the new contrasting color
	return tinycolor(hsl).toHexString()
}

export function setOpacity(color: string, opacity: number): string {
	const colorObj = tinycolor(color)
	colorObj.setAlpha(opacity)
	return colorObj.toRgbString()
}
