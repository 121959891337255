import { CheckIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import _ from "lodash"
import React from "react"
import { useNavigate } from "react-router-dom"
import Datepicker from "react-tailwindcss-datepicker"

import type { User } from "../../../../../core/domain/User.entity"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { UserList } from "../../../../components/shared/UserList.component"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { useWorkspaceUsers } from "../../../../contexts/workspace-users.context"
import { makeTrainingProgramsPath } from "../../../../router/Router"
import { EditTrainingProgramLayout } from "../components/EditTrainingProgramLayout.component"
import { EnrolmentList } from "../components/EnrolmentList.component"
import { useTrainingProgram } from "../contexts/training-program.context"

function useEnrolmentList() {
	const { trainingProgram } = useTrainingProgram()
	const { workspaceUsers } = useWorkspaceUsers()
	const [hasLoadedEnrolments, setHasLoadedEnrolments] = React.useState(false)

	const [enrolledUsers, setEnrolledUsers] = React.useState<
		Array<{
			user: User
			deadline: Date | null
			completionRatePercent: number
		}>
	>([])

	const addEnrolments = React.useCallback(
		(enrolments: Array<{ userId: string; deadline: Date | null; completionRatePercent: number }>) => {
			for (const enrolment of enrolments) {
				const user = workspaceUsers?.find((user) => user.id === enrolment.userId)
				if (!user) return

				setEnrolledUsers((prevEnrolledUsers) => {
					return _.uniqBy(
						[
							...prevEnrolledUsers,
							{
								user,
								deadline: enrolment.deadline,
								completionRatePercent: enrolment.completionRatePercent,
							},
						],
						(enrolledUser) => enrolledUser.user.id,
					)
				})
			}
		},
		[workspaceUsers],
	)

	const updateEnrolment = React.useCallback((userId: string, deadline: Date | null) => {
		setEnrolledUsers((prevEnrolledUsers) =>
			prevEnrolledUsers.map((enrolledUser) =>
				enrolledUser.user.id === userId
					? {
							...enrolledUser,
							deadline,
					  }
					: enrolledUser,
			),
		)
	}, [])

	const removeEnrolment = React.useCallback((userId: string) => {
		setEnrolledUsers((prevEnrolledUsers) => prevEnrolledUsers.filter((u) => u.user.id !== userId))
	}, [])

	React.useEffect(() => {
		if (!trainingProgram || hasLoadedEnrolments || enrolledUsers.length || !workspaceUsers?.length) return

		const existingEnrolments = trainingProgram.enrolments
			.map((enrolment) => {
				const user = workspaceUsers?.find((user) => user.id === enrolment.userId)
				if (!user) {
					return null
				}

				const allItems = trainingProgram.sections?.flatMap((section) => section.items) ?? []
				return {
					user,
					deadline: enrolment.deadline,
					completionRatePercent: allItems.length
						? Math.round((enrolment.itemsSeen / allItems.length) * 100)
						: 100,
				}
			})
			.filter(
				(userWithDeadline): userWithDeadline is { user: User; deadline: Date; completionRatePercent: number } =>
					Boolean(userWithDeadline),
			)

		addEnrolments(
			existingEnrolments.map(({ user, deadline, completionRatePercent }) => ({
				userId: user.id,
				deadline,
				completionRatePercent,
			})),
		)
		setHasLoadedEnrolments(true)
	}, [addEnrolments, enrolledUsers.length, hasLoadedEnrolments, trainingProgram, workspaceUsers])

	return {
		enrolledUsers,
		addEnrolments,
		removeEnrolment,
		updateEnrolment,
	}
}

export function EditTeamMembersPage() {
	const { workspaceUsers } = useWorkspaceUsers()
	const { programId } = useTrainingProgram()
	const { addEnrolments, enrolledUsers, removeEnrolment, updateEnrolment } = useEnrolmentList()
	const { t } = useLanguage()

	const { trainingGateway } = useDependencies()
	const navigate = useNavigate()
	const [deadline, setDeadline] = React.useState<Date | null>(null)
	const [selectedUserIds, setSelectedUserIds] = React.useState<string[]>([])

	const handleUserToggleEnroll = React.useCallback(
		(userId: string) => {
			const isAlreadyEnrolled = enrolledUsers.some((enrolledUser) => enrolledUser.user.id === userId)
			if (isAlreadyEnrolled) return

			setSelectedUserIds((prevSelectedUserIds) =>
				prevSelectedUserIds.includes(userId)
					? prevSelectedUserIds.filter((id) => id !== userId)
					: [...prevSelectedUserIds, userId],
			)
		},
		[enrolledUsers],
	)

	const handleEnrollSelectedUsers = React.useCallback(() => {
		addEnrolments(
			selectedUserIds.map((userId) => ({
				userId,
				deadline: deadline,
				completionRatePercent: 0,
			})),
		)

		setSelectedUserIds([])
	}, [addEnrolments, deadline, selectedUserIds])

	const handleSave = React.useCallback(async () => {
		try {
			await trainingGateway.updateTrainingProgram(programId, {
				type: "enrolments",
				users: enrolledUsers.map(({ user, deadline }) => ({
					id: user.id,
					deadline,
				})),
			})
			navigate(makeTrainingProgramsPath())
		} catch (e) {
			alert("Failed to save")
		}
	}, [enrolledUsers, navigate, programId, trainingGateway])

	const makeActionContent = React.useCallback(
		(user: User) => {
			const isSelected = selectedUserIds.includes(user.id)
			const isEnrolled = enrolledUsers.some((enrolledUser) => enrolledUser.user.id === user.id)

			return (
				<div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
					<span className="inline-flex items-center text-xs font-medium text-gray-900 gap-x-1">
						<div
							className={clsx(
								"rounded-full h-5 w-5 p-1",
								isSelected ? "bg-emerald-200" : "border border-gray-200",
								isEnrolled && "bg-gray-200",
							)}
						>
							{isSelected && <CheckIcon />}
						</div>
					</span>
				</div>
			)
		},
		[enrolledUsers, selectedUserIds],
	)

	const handleRemoveEnrolment = React.useCallback(
		({ user }: { user: User }) => {
			removeEnrolment(user.id)
		},
		[removeEnrolment],
	)

	const handleDeadlineChange = React.useCallback(
		(user: User, newDeadline: Date | null) => {
			updateEnrolment(user.id, newDeadline)
		},
		[updateEnrolment],
	)

	return (
		<EditTrainingProgramLayout currentStep="enrolments" onSaveClick={handleSave} isLastStep>
			<div className="flex flex-row flex-1 mt-6 gap-6">
				<div className="flex-1 border border-gray-200 shadow-sm rounded-md p-6 bg-white">
					<h2 className="text-md my-6 text-gray-600">
						{t("Select the team members you would like to enrol in this training")}
					</h2>
					<UserList
						users={workspaceUsers ?? []}
						className="h-[calc(100vh-28rem)]"
						actionMenuContent={makeActionContent}
						onUserRowClick={(u) => handleUserToggleEnroll(u.id)}
					/>
					<div className="flex flex-row justify-between items-baseline">
						<div className="flex justify-end items-baseline gap-4">
							<h2 className="text-sm my-6 text-gray-600">{t("Select a deadline (optional)")}</h2>
							<Datepicker
								asSingle={true}
								popoverDirection="up"
								value={{ startDate: deadline, endDate: deadline }}
								onChange={(newValue) =>
									setDeadline(newValue?.startDate ? new Date(newValue.startDate) : null)
								}
								containerClassName={(className) => clsx(className, "lg:max-w-[180px]")}
							/>
						</div>
						<TrackingButton
							eventName={"Training program: enrol users button clicked"}
							onClick={handleEnrollSelectedUsers}
							className="text-gray-900 ring-1 ring-gray-500 flex items-center justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:text-gray-500 disabled:ring-gray-200"
							disabled={selectedUserIds.length === 0}
						>
							{t("Enrol selected users")}
						</TrackingButton>
					</div>
				</div>
				<div className="flex-1 border border-gray-200 shadow-sm rounded-md p-6 bg-white">
					<h2 className="text-md my-6 text-gray-600">{t("Enrolled team members")}</h2>
					<EnrolmentList
						users={enrolledUsers}
						onRemoveEnrolment={handleRemoveEnrolment}
						onDeadlineChange={handleDeadlineChange}
					/>
				</div>
			</div>
		</EditTrainingProgramLayout>
	)
}
