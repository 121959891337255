import { useCallback, useEffect, useState } from "react"
import { useMount } from "react-use"

import type { UpdateHighlightParams } from "../../core/application/gateways/library.gateway"
import type { CallHighlight, LibraryFolder } from "../../core/domain/Library.entity"
import type { CoachingSession } from "../../core/infra/gateways/http.library.gateway/getCoachingSessionsResponse.schema"
import { useDependencies } from "../contexts/dependencies.context"
import { useLanguage } from "../contexts/language.context"
import { getCoachingSessionFolder } from "../pages/Library/components/CoachingSession.util"
import { getFolderDisplayNameString } from "../pages/Library/components/FolderDisplayName.component"
import { useLibrary } from "./useLibrary"

export function useLibraryWithHighlights(libraryFolderId: string | undefined) {
	const { t } = useLanguage()
	const { libraryFoldersGateway } = useDependencies()
	const { library, fetchLibrary } = useLibrary()

	const [currentFolderHighlights, setCurrentFolderHighlights] = useState<
		{ highlights: CallHighlight[]; newOnesAreLoading: boolean } | "loading" | "no-folder-selected"
	>("loading")
	const [currentFolder, setCurrentFolder] = useState<LibraryFolder | undefined>()
	const [coachingSessions, setCoachingSessions] = useState<CoachingSession[] | "loading">("loading")

	const fetchCurrentFolderHighlights = useCallback(async () => {
		if (library === "loading") return

		// If no folder is selected, show the root highlights
		if (!libraryFolderId) {
			setCurrentFolderHighlights("no-folder-selected")
			setCurrentFolder(undefined)
			return
		}

		const coachingSessionFolder = getCoachingSessionFolder(t)
		if (libraryFolderId === coachingSessionFolder.id) {
			console.log("Setting current folder to coaching session folder")
			setCurrentFolder(coachingSessionFolder)
			setCurrentFolderHighlights({ highlights: [], newOnesAreLoading: true })
			const coachingSessionsResponse = await libraryFoldersGateway.getCoachingSessions()
			setCoachingSessions(coachingSessionsResponse.sessions)
			return
		}

		const currentLibraryFolder = library.getFolderById(libraryFolderId)
		if (!currentLibraryFolder) {
			console.log("No folder found with id", libraryFolderId)
			setCurrentFolder(undefined)
			setCurrentFolderHighlights({ highlights: [], newOnesAreLoading: false })
			return
		}

		setCurrentFolder(currentLibraryFolder)
		setCurrentFolderHighlights((old) => {
			if (typeof old === "string") return old // don't do anything
			return { highlights: old.highlights, newOnesAreLoading: true } // flag that new ones are loading, this optimizes the UX when browsing from a library folder to another
		})
		const highlights = await libraryFoldersGateway.getFolderHighlights(currentLibraryFolder)
		setCurrentFolderHighlights({ highlights, newOnesAreLoading: false })
	}, [library, libraryFolderId, libraryFoldersGateway, t])

	const createLibraryFolder = useCallback(
		async (folderName: string, parentLibraryFolderId: string) => {
			await libraryFoldersGateway.createFolder({ folderName, parentLibraryFolderId })
			await fetchLibrary()
		},
		[fetchLibrary, libraryFoldersGateway],
	)

	const editLibraryFolder = useCallback(
		async (editedFolder: LibraryFolder) => {
			await libraryFoldersGateway.editFolder(editedFolder)
			await fetchLibrary()
		},
		[fetchLibrary, libraryFoldersGateway],
	)

	const deleteLibraryFolder = useCallback(
		async (toBeDeletedFolder: LibraryFolder) => {
			if (
				!window.confirm(
					t(
						`Are you sure you want to delete the folder "{{name}}" along with all its subfolders and highlights?`,
						{
							name: getFolderDisplayNameString(toBeDeletedFolder, t),
						},
					),
				)
			) {
				return
			}
			await libraryFoldersGateway.deleteFolder(toBeDeletedFolder)
			await fetchLibrary()
		},
		[fetchLibrary, libraryFoldersGateway, t],
	)

	const updateHighlight = useCallback(
		async (highlight: CallHighlight, updatedFields: UpdateHighlightParams) => {
			await libraryFoldersGateway.updateHighlight(highlight.id, updatedFields)
			await Promise.all([
				fetchCurrentFolderHighlights(),
				fetchLibrary(), // update callHighlightCount
			])
		},
		[fetchCurrentFolderHighlights, libraryFoldersGateway, fetchLibrary],
	)

	const deleteHighlight = useCallback(
		async (highlight: CallHighlight) => {
			if (
				!window.confirm(
					t(`Are you sure you want to delete "{{name}}"?`, {
						name: highlight.callName,
					}),
				)
			)
				return

			await libraryFoldersGateway.deleteHighlight(highlight)
			await fetchCurrentFolderHighlights()
		},
		[fetchCurrentFolderHighlights, libraryFoldersGateway, t],
	)

	useEffect(() => {
		fetchCurrentFolderHighlights()
	}, [fetchCurrentFolderHighlights, libraryFolderId])

	useMount(async () => {
		await fetchLibrary()
		await fetchCurrentFolderHighlights()
	})

	return {
		library,
		currentFolderHighlights,
		currentFolder,
		coachingSessions,
		createLibraryFolder,
		editLibraryFolder,
		deleteLibraryFolder,
		updateHighlight,
		deleteHighlight,
	}
}
