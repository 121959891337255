import type { ListAllDealsResponse } from "../../../core/application/gateways/deals.gateway/schemas/listDeals"
import type { DateProvider } from "../../utils/time"

export function computeDealLastStageMovementAt(deal: ListAllDealsResponse["deals"][0]): Date | null {
	const history = deal.history || []
	const stageChanges = history
		.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())
		.filter((change) => change.updatedProperty === "stage")
	return stageChanges[0]?.updatedAt ?? null
}

export function computeDealUpdateInLastNDays(
	deal: ListAllDealsResponse["deals"][0],
	dateProvider: DateProvider,
	numberOfDays: number,
): Required<NonNullable<ListAllDealsResponse["deals"][0]["history"]>>[0] | null {
	const history = deal.history || []
	const targetDate = dateProvider.now().getTime() - numberOfDays * 24 * 60 * 60 * 1000
	const stageChanges = history
		.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime())
		.filter((change) => change.updatedAt.getTime() > targetDate)
	const lastChange = stageChanges[0]

	if (!lastChange) {
		return null
	}

	return lastChange
}

export function formatCurrency(
	amount: number,
	currency: "EUR" | "USD" = "EUR",
	options?: Intl.NumberFormatOptions,
): string {
	const currentLocale = navigator.language
	return new Intl.NumberFormat(currentLocale, {
		style: "currency",
		currency: currency,
		...options,
	}).format(amount)
}
