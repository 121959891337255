import React from "react"

import type { GetHistoricalWinRateResponse } from "../../../../../../core/application/gateways/deals.gateway/schemas/listDeals"
import { SpinnerIcon } from "../../../../../components/design-system/SpinnerIcon.component"
import { TextToggle } from "../../../../../components/design-system/TextToggle.component"
import { SectionCard } from "../../../../../components/shared/GraphSection/SectionCard.component"
import { useDependencies } from "../../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../../contexts/language.context"
import { makeFakeWinRateEvolution } from "../fakeData"
import { WinRateEvolutionGraph } from "./WinRateEvolutionGraph.component"

function useHistoricalWinRate(useFakeData: boolean) {
	const [historicalWinRate, setHistoricalWinRate] = React.useState<GetHistoricalWinRateResponse | null>(null)
	const [loading, setLoading] = React.useState(!useFakeData)
	const { dealsGateway } = useDependencies()
	const [mode, setMode] = React.useState<"overall" | "month-over-month">("overall")

	React.useEffect(() => {
		if (useFakeData) {
			setHistoricalWinRate(makeFakeWinRateEvolution())
			return
		}

		dealsGateway.getHistoricalWinRate().then((response) => {
			setHistoricalWinRate(response)
			setLoading(false)
		})
	}, [dealsGateway, useFakeData])

	return {
		isLoading: loading,
		historicalWinRate,
		mode,
		setMode,
	}
}

type WinRateSectionProps = {
	useFakeData: boolean
}

export function WinRateSection({ useFakeData }: WinRateSectionProps) {
	const { isLoading: isWinRateLoading, historicalWinRate, mode, setMode } = useHistoricalWinRate(useFakeData)
	const { t } = useLanguage()

	return (
		<SectionCard className="h-full gap-6 flex flex-row">
			<div>
				<h3 className="text-md font-semibold text-gray-900">{t("Win rate evolution")}</h3>

				<span className="text-sm font-medium text-gray-500">
					{t("Percentage of all team deals won over time")}
				</span>
			</div>
			{isWinRateLoading || !historicalWinRate ? (
				<SpinnerIcon className={"w-10 h-10"} />
			) : (
				<div className="h-[12vh] w-full">
					<WinRateEvolutionGraph historicalWinRate={historicalWinRate} mode={mode} />
				</div>
			)}
			<div>
				<TextToggle
					value={mode}
					a={{ label: t("Overall"), value: "overall" }}
					b={{ label: t("Month-over-month"), value: "month-over-month" }}
					onChange={setMode}
				/>
			</div>
		</SectionCard>
	)
}
