import { Call, DEMO_CALL_ID, DEMO_CALL_NAME, DEMO_CALL_WORKSPACE_ID } from "../../../core/domain/Call.entity"
import type { User } from "../../../core/domain/User.entity"
import type { TFunction } from "../../contexts/language.context"
import { makeUserSettingsPath, makeWorkspaceSettingsPath } from "../../pages/Settings/config"
import { makeLibraryPath } from "../../router/Router"
import { type DateProvider } from "../time"
import { makeDemoBriefingContent } from "./demoCalendarEvent"

export function getDemoCall(user: User, dateProvider: DateProvider, t: TFunction, language: string) {
	const oneHourAgo = new Date(dateProvider.now().getTime() - 1000 * 60 * 60)
	const callEndTime = new Date(oneHourAgo.getTime() + 15 * 60 * 1000)

	return new Call({
		id: DEMO_CALL_ID,
		workspaceId: DEMO_CALL_WORKSPACE_ID,
		createdAt: new Date(),
		name: DEMO_CALL_NAME,
		attendees: [],
		audioFileUrl: "",
		durationSec: 185,
		hasAudio: true,
		audioMimeType: "audio/mpeg",
		scoringAnalysis: undefined,
		coachingAnalysis: [
			{
				generalComments:
					"Overall, you handled the call well, presenting your product’s key features well and clearly describing their benefits with the prospect’s needs in mind. You could improve by seeking more specific information from your prospect to allow you to adapt your approach to their needs. The call lacked a clear structure outline, which should be defined upfront to set expectations for both yourself and your prospect.",
				strengths: [
					{
						aspect: "Detailed presentation",
						suggestion:
							"Continue to give detailed explanations of the key features of your product, emphasising the benefits they offer.",
						quote: "You get all of this, as well as so many bonus features to help boost your performance and your productivity",
					},
					{
						aspect: "Good understanding of needs",
						suggestion:
							"Use your understanding of the prospect’s needs to highlight specific features that will address their pain points.",
						quote: "To begin with, make sure to sync your Google or Microsoft calendar to automatically schedule Rippletide to record your meetings so you can extract powerful insights from every prospect call you make without having to worry about forgetting to record.",
					},
				],
				areasForImprovement: [
					{
						aspect: "Outline a clear agenda",
						suggestion:
							"Begin your meeting by defining a clear agenda and setting an explicit end goal, to make sure your call follows a set structure and achieves its desired outcome.",
						quote: "Hello and welcome to Rippletide, your partner in sales excellence! Let’s take a quick tour around your account to get you started.",
					},
					{
						aspect: "Ask questions",
						suggestion:
							"Encourage more interaction and two-way dialogue with your prospect by asking more questions throughout and requesting specific feedback to ensure you’re staying on the right track.",
						quote: "Then, when it’s time to connect to your call, Rippletide will request access to the meeting. Simply accept, and navigate your call with confidence and let us do the rest.",
					},
					{
						aspect: "Define next steps",
						suggestion:
							"At the end of the meeting, define a timeline of the next steps to set clear expectations and ensure concrete engagement.",
						quote: "And now that you’ve taken the tour, you’re ready to get started with Rippletide. Why not record your first call now? We look forward to riding the waves of sales success together!",
					},
				],
			},
		],
		leadKnowledge: {
			version: 2,
			contacts: [
				{
					firstName: "Alex",
					lastName: "Duignan",
					jobTitle: "Product Manager",
					decisionMakingAuthority: "medium",
					personalityProfile: null,
					attendeeId: "",
					preferredCommunicationChannel: null,
				},
			],
			account: {
				industry: "Information technology",
				employeeCount: null,
				annualRevenue: null,
				businessModelType: "B2B",
				companyType: "Private",
				companyLocation: null,
				marketCompetitiveness: null,
				keyDecisionMakers: null,
				technologicalStack: ["Rippletide", "Google", "Microsoft"],
			},
			opportunity: {
				currentProvider: {
					name: "",
					satisfactionLevel: "low",
					satisfactionComment: null,
					monetaryValue: null,
					monetaryCurrency: null,
					renewalDate: null,
				},
				competitiveConsiderationSummary:
					"The need for a tool that saves time, allows for self-evaluation, and provides comprehensive meeting information automatically.",
				budgetApprovalProcess: null,
				buyingContext:
					"Looking for a tool that can help with onboarding and prospect follow-ups, and provide a comprehensive view of prospect exchanges and expectations.",
				specificNeeds: [
					"Tool for self-evaluation",
					"Time-saving tool for the team",
					"Comprehensive meeting information for follow-up",
					"360-degree view of prospect exchanges and expectations",
				],
				shortTermGoals: null,
				longTermGoals: null,
				decisionCriteria: [
					"Ability to self-evaluate with smart, live feedback",
					"Optimised training and onboarding capabilities for entire team",
					"CRM integration for instant sync of prospect exchanges",
				],
				deal: {
					monetaryValue: null,
					monetaryCurrency: null,
					estimatedClosingProbability: null,
					estimatedCloseDate: null,
					engagementScope: null,
				},
			},
			reactions: null,
		},
		publicAccessToken: "",
		assignedUserId: "",
		crmSync: null,
		briefing: {
			version: 1,
			result: makeDemoBriefingContent(
				user,
				{
					startTime: oneHourAgo,
					endTime: callEndTime,
				},
				dateProvider,
				t,
				language,
			),
		},
		unprocessableReason: null,
		account: undefined,
		score: null,
		countryCode: "US",
		videoFileUrl: "https://rippletide-static.s3.fr-par.scw.cloud/demo_call_english_2024_05_20.mp4", // hosted on scaleway "rippletide-static" bucket
		language: "en",
		transcription: {
			turns: [
				{
					startTimeSec: 0.9544300000000001,
					endTimeSec: 6.888,
					content:
						"Hello and welcome to Rippletide, your partner in sales excellence! Let’s take a quick tour around your account to get you started.",
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
				{
					startTimeSec: 7.48868,
					endTimeSec: 19.028,
					content:
						"To begin with, make sure to sync your Google or Microsoft calendar to automatically schedule Rippletide to record your meetings so you can extract powerful insights from every prospect call you make without having to worry about forgetting to record.",
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
				{
					startTimeSec: 19.95029,
					endTimeSec: 29.248,
					content:
						"If you're unable to sync your calendar just yet, you can test Rippletide's meeting recording by inviting coach at rippletide.com to any meeting where you are the event organizer.",
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
				{
					startTimeSec: 31.72327,
					endTimeSec: 41.5398,
					content:
						"Once your calendar is synced, you’ll be able to view all of your upcoming calls that are scheduled to be recorded in your account dashboard - that’s any meeting that has both external attendees and a video call URL.",
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
				{
					startTimeSec: 42.46845,
					endTimeSec: 57.2208,
					content:
						"Here, we’ll also provide you with a brief in advance of every meeting. It’s packed full of insights into your prospect, their company activity, and your interaction history, as well as tips and strategies you can put into place to help make your call a success. This way, you can always be fully prepared.",
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
				{
					startTimeSec: 57.845189999999995,
					endTimeSec: 66.00354,
					content:
						"Then, when it’s time to connect to your call, Rippletide will request access to the meeting. Simply accept, and navigate your call with confidence and let us do the rest.",
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
				{
					startTimeSec: 66.80819,
					endTimeSec: 75.07219,
					content:
						"Once your call has ended, you can go to your Calls page in your account, and you’ll see that you get immediate access to your call recording and transcript. Let’s take an example.",
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
				{
					startTimeSec: 76.064,
					endTimeSec: 86.97591,
					content:
						"Here, you can replay moments from your meeting. You can read them back in the transcript. You can even search for keywords within your transcript, and click on the section of text to take you to this moment in the video.",
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
				{
					startTimeSec: 88.044,
					endTimeSec: 120.744,
					content: `You get all of this, as well as so many bonus features to help boost your performance and your productivity, such as:
The key moments of the call, so no important information gets lost.
An actionable to-do list for efficient follow-up.
A follow-up email prepared on your behalf, ready to be sent straight to your prospect.
Then there’s our smart coaching, which gives you structured feedback from your call by highlighting your strengths and areas of improvement, to help you continuously refine your skill set.
And essential insights into your prospect and their company to keep you informed.`,
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
				{
					startTimeSec: 121.91733,
					endTimeSec: 135.72093,
					content:
						"And you'll be able to refer back to these call notes at any time. What's even better is that all of this information is sent directly to your CRM, enriching the account and creating tasks to save you the time doing it and allowing you to focus your energy on your prospects.",
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
				{
					startTimeSec: 137.49200000000002,
					endTimeSec: 149.59176,
					content:
						"If you’d like to share your call with your prospect, or with your team, you can do it in just a couple of clicks. Or, you can share a standout segment from your call simply by selecting the timeframe you’d like to share, and sharing it.",
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
				{
					startTimeSec: 150.43625,
					endTimeSec: 160.55151,
					content:
						"If you'd like to save this time frame as a highlight into your workspace training library, you can do that too. You just need to choose the folder you want to put it in and add a comment.",
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
				{
					startTimeSec: 163.50867,
					endTimeSec: 175.16786000000002,
					content:
						"Your sales training library is a place for you and your team to centralize and categorize all of your best moments so that you can save them for access at any time, whether for onboarding or for training to improve your technique.",
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
				{
					startTimeSec: 175.86852000000002,
					endTimeSec: 184.6671,
					content:
						"And now that you’ve taken the tour, you’re ready to get started with Rippletide. Why not record your first call now? We look forward to riding the waves of sales success together!",
					attendeeId: "200",
					attendeeLabel: "Alex Duignan",
				},
			],
		},
		nextBestActionSuggestion: {
			keyPoints: [
				"Rippletide connects to your calendar to automatically record your meetings and extract key insights.",
				"Receive a call brief full of rich prospect information before each meeting to help you prepare extensively.",
				"After each call, take advantage of immediate access to the summary, follow-up actions, and personalised coaching materials.",
				"All your important prospect and call information is automatically sent to your CRM to keep your opportunity records up-to-date.",
				"Seamlessly share your call recording and notes with your prospect or your team.",
				"Save key moments from your call to your sales training library, to create a repository of rich training materials for you and your team.",
			],
			nextBestActions: [],
			notes: [
				`[Sync your calendar](${makeUserSettingsPath("calendar")}) with your Rippletide account.`,
				...(user.isWorkspaceOwner()
					? [`[Integrate your CRM](${makeWorkspaceSettingsPath("crm")}) with your Rippletide account.`]
					: []),
				"Record your first call with Rippletide.",
				`Create your first highlight in your workspace [sales training library.](${makeLibraryPath()})`,
				...(user.isWorkspaceOwner()
					? [`[Invite your teammates](${makeWorkspaceSettingsPath("users")}) to join your workspace.`]
					: []),
			],
			email: `Hello ${user.firstName},

Thank you for taking the time to dive into Rippletide!

Now that you’ve taken the tour, start automating your process by setting up your calendar and CRM integrations in your account settings. Once this is done, you’re all set to record your first call.

We’re confident that with Rippletide, you will be able to elevate your sales performance and success to new heights. We’re looking forward to getting started.

Have a great day,

The Rippletide Team
`,
		},
		audioPath: "",
	})
}
