import assertNever from "assert-never"
import React from "react"

import type { ListAllDealsQueryParams } from "../../../core/application/gateways/deals.gateway/schemas/listDeals"
import { DateProvider } from "../../utils/time"
import type { DealChangesFilter, DealClosingDateFilter, DealSelectFilter } from "./components/DealFilters.component"

export function computeClosingDateFromFilter(selectedClosingDate: DealClosingDateFilter, dateProvider: DateProvider) {
	const days =
		selectedClosingDate === "1-month"
			? 30
			: selectedClosingDate === "3-months"
			? 90
			: selectedClosingDate === "6-months"
			? 180
			: 365

	const now = dateProvider.now()
	const closingDate = new Date(now.getTime() + days * 24 * 60 * 60 * 1000)
	return closingDate
}

function computeDealChangesFromFilter(selectedChanges: DealChangesFilter) {
	switch (selectedChanges) {
		case "7-days":
			return 7
		case "30-days":
			return 30
		case "90-days":
			return 90
	}

	assertNever(selectedChanges)
}

export function useDealFilters() {
	const [selectedDeals, setSelectedDeals] = React.useState<DealSelectFilter>("all")
	const [selectedOwner, setSelectedOwner] = React.useState<string | null>(null)
	const [selectedClosingDate, setSelectedClosingDate] = React.useState<DealClosingDateFilter>("3-months")
	const [selectedChanges, setSelectedChanges] = React.useState<DealChangesFilter>("7-days")

	const dealChangeDays = computeDealChangesFromFilter(selectedChanges)

	const filter: ListAllDealsQueryParams = React.useMemo(
		() => ({
			ownerId: selectedOwner ?? undefined,
			includeClosedDealsFromLastNDays: dealChangeDays,
			closingDateEnd: computeClosingDateFromFilter(selectedClosingDate, new DateProvider()),
		}),
		[selectedOwner, selectedClosingDate, dealChangeDays],
	)

	return {
		filter,
		displayChangesInTheLastNDays: dealChangeDays,
		dealsToShow: selectedDeals,
		onSelectDeals: setSelectedDeals,
		onSelectOwner: setSelectedOwner,
		onSelectClosingDate: setSelectedClosingDate,
		onSelectChanges: setSelectedChanges,
		selectedChanges,
		selectedClosingDate,
		selectedOwner,
		selectedDeals,
	}
}
