import { PauseIcon, PlayIcon } from "@heroicons/react/24/outline"

import { TrackingButton } from "../../TrackingButton.component"
import { ForwardIcon } from "./ForwardIcon.component"
import { RewindIcon } from "./RewindIcon.component"

type ICenterControlsProps = {
	isPlaying: boolean
	onPlayPauseClick: () => void
	onBackwardClick: () => void
	onForwardClick: () => void
}

export function CenterControls({ isPlaying, onBackwardClick, onForwardClick, onPlayPauseClick }: ICenterControlsProps) {
	return (
		<div className="absolute top-1/2 left-0 right-0 flex justify-center items-center transform -translate-y-1/2  text-white">
			<TrackingButton
				eventName="Call video interaction"
				eventProperties={{ type: "backward" }}
				onClick={onBackwardClick}
				className="p-2 hover:bg-gray-900 hover:opacity-90 rounded-full"
			>
				<RewindIcon className="w-8 min-w-8 h-8 min-h-8" />
			</TrackingButton>
			<TrackingButton
				eventName="Call video interaction"
				eventProperties={{ type: isPlaying ? "pause" : "play" }}
				onClick={onPlayPauseClick}
				className="p-2 mx-4 hover:bg-gray-900 hover:opacity-90 rounded-full"
			>
				{isPlaying ? (
					<PauseIcon className="w-8 min-w-8 h-8 min-h-8" />
				) : (
					<PlayIcon className="w-8 min-w-8 h-8 min-h-8" />
				)}
			</TrackingButton>
			<TrackingButton
				eventName="Call video interaction"
				eventProperties={{ type: "forward" }}
				onClick={onForwardClick}
				className="p-2 hover:bg-gray-900 hover:opacity-90 rounded-full"
			>
				<ForwardIcon className="w-8 min-w-8 h-8 min-h-8" />
			</TrackingButton>
		</div>
	)
}
