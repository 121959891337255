export type PasswordValidationError =
	| "passwords-dont-match"
	| "password-too-short"
	| "missing-lowercase"
	| "missing-uppercase"
	| "missing-digit"
	| "missing-special-char"

export function getPasswordValidationErrors(password: string, passwordConfirmation: string): PasswordValidationError[] {
	const validationErrors: PasswordValidationError[] = []

	if (password.length < 8) {
		validationErrors.push("password-too-short")
	}

	if (password !== passwordConfirmation) {
		validationErrors.push("passwords-dont-match")
	}

	const hasLowerCase = /[a-z]/.test(password)
	const hasUpperCase = /[A-Z]/.test(password)
	const hasDigit = /\d/.test(password)
	const hasSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password)
	if (!hasLowerCase) {
		validationErrors.push("missing-lowercase")
	}

	if (!hasUpperCase) {
		validationErrors.push("missing-uppercase")
	}

	if (!hasDigit) {
		validationErrors.push("missing-digit")
	}

	if (!hasSpecialCharacter) {
		validationErrors.push("missing-special-char")
	}

	return validationErrors
}
