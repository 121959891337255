import {
	GetRelationshipByIdResponseSchema,
	GetRelationshipByOpportunityIdResponseSchema,
	GetRelationshipsResponseSchema,
	GetSalesRelationshipsResponseSchema,
	type IGetRelationshipsFilter,
	type IGetSalesRelationshipsFilter,
	type IRelationshipsGateway,
} from "../../application/gateways/relationships.gateway"

export class HttpRelationshipsGateway implements IRelationshipsGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async getRelationshipByOpportunityId(opportunityId: string) {
		const endpointUrl = `${this.baseApiUrl}/relationships/by-opportunity/${opportunityId}`

		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		const json = await res.json()
		return GetRelationshipByOpportunityIdResponseSchema.parse(json)
	}

	public async getRelationshipById(id: string) {
		const endpointUrl = `${this.baseApiUrl}/relationships/${id}`

		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		const json = await res.json()
		return GetRelationshipByIdResponseSchema.parse(json)
	}

	public async getSalesRelationships(filter: IGetSalesRelationshipsFilter) {
		const queryParams = new URLSearchParams({})
		if (filter.ownerId) queryParams.append("ownerId", filter.ownerId)
		if (filter.closingDateStart) queryParams.append("closingDateStart", filter.closingDateStart.toISOString())
		if (filter.closingDateEnd) queryParams.append("closingDateEnd", filter.closingDateEnd.toISOString())

		const endpointUrl = `${this.baseApiUrl}/relationships/sales-deals?${queryParams.toString()}`

		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		const json = await res.json()
		return GetSalesRelationshipsResponseSchema.parse(json)
	}

	public async getRelationships(filter: IGetRelationshipsFilter) {
		const queryParams = new URLSearchParams({
			pageSize: filter.pageSize.toString(),
			pageNumber: filter.pageNumber.toString(),
		})
		if (filter.ownerUserId) queryParams.append("ownerUserId", filter.ownerUserId)
		if (filter.closingDateStart) queryParams.append("closingDateStart", filter.closingDateStart.toISOString())
		if (filter.closingDateEnd) queryParams.append("closingDateEnd", filter.closingDateEnd.toISOString())
		if (filter.onlyShowAtRisk) queryParams.append("onlyShowAtRisk", filter.onlyShowAtRisk.toString())

		const endpointUrl = `${this.baseApiUrl}/relationships?${queryParams.toString()}`

		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		const json = await res.json()
		return GetRelationshipsResponseSchema.parse(json)
	}
}
