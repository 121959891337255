import { FolderIcon } from "@heroicons/react/24/outline"

import type { LibraryFolder } from "../../../../../core/domain/Library.entity"
import { TrackingLink } from "../../../../components/design-system/TrackingLink.component"
import { makeLibraryFolderPath } from "../../../../router/Router"
import { FolderDisplayName } from "../FolderDisplayName.component"

export type CurrentLibraryFolderChildrenGridProps = {
	currentFolderChildren: LibraryFolder[] | undefined
}

export function CurrentLibraryFolderChildrenGrid({ currentFolderChildren }: CurrentLibraryFolderChildrenGridProps) {
	if (!currentFolderChildren?.length) {
		return null
	}

	return (
		<div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
			{currentFolderChildren.map((childFolder) => {
				return (
					<TrackingLink
						to={makeLibraryFolderPath(childFolder.id)}
						key={childFolder.id}
						className="relative flex items-center space-x-3 rounded-lg border border-gray-200 bg-white px-6 py-4 hover:bg-gray-50 hover:border-gray-300"
						eventName="Library grid: navigate to folder"
						eventProperties={{ libraryFolderId: childFolder.id }}
					>
						<div className="flex-shrink-0">
							<FolderIcon className="h-6 w-6 text-gray-400" />
						</div>
						<div className="min-w-0 flex-1">
							<span className="absolute inset-0" aria-hidden="true" />
							<div className="flex justify-between items-center">
								<p className="text-sm font-medium text-gray-900 text-ellipsis">
									<span className="relative">
										<FolderDisplayName folder={childFolder} />
										{childFolder.hasBadge && (
											<span className="absolute top-0 -right-3 flex h-2 w-2">
												<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-emerald-400 opacity-75"></span>
												<span className="relative inline-flex rounded-full h-2 w-2 bg-emerald-400 "></span>
											</span>
										)}
									</span>
								</p>
								<span className="text-gray-400 font-normal text-sm">
									{childFolder.getRecursiveCallHighlightCount()}
								</span>
							</div>
						</div>
					</TrackingLink>
				)
			})}
		</div>
	)
}
