import type { ITeamsGateway } from "../../application/gateways/teams.gateway"
import type { Team } from "../../domain/Team.entity"
import { ensureNotAnApiError } from "./api-errors"

export class HttpTeamsGateway implements ITeamsGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async update(team: Team): Promise<void> {
		console.log("Updating team", team)
		return this.doApiCall(`${this.baseApiUrl}/teams/update/${team.id}`, team, "POST")
	}

	public async create(team: Omit<Team, "id">): Promise<void> {
		console.log("Creating team", team)
		return this.doApiCall(`${this.baseApiUrl}/teams/create`, team, "POST")
	}

	public async delete(team: Team): Promise<void> {
		console.log("Deleting team", team.id)
		return this.doApiCall(`${this.baseApiUrl}/teams/${team.id}`, null, "DELETE")
	}

	private async doApiCall(
		endpointUrl: string,
		team: Omit<Team, "id"> | null,
		method: "POST" | "DELETE",
	): Promise<void> {
		const response = await fetch(endpointUrl, {
			method,
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: team
				? JSON.stringify({
						name: team.name,
						memberIds: team.memberIds,
				  })
				: null,
		})
		ensureNotAnApiError(response)
	}
}
