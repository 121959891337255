import { z } from "zod"

import { TrainingProgramSchema } from "./training-program.schema"
import { TrainingProgramItemContentSchema } from "./training-program-items.schema"

const MyTrainingProgramItemSchema = z.intersection(
	TrainingProgramItemContentSchema,
	z.object({
		id: z.string(),
		description: z.string(),
		seen: z.boolean(),
	}),
)
export type MyTrainingProgramItem = z.infer<typeof MyTrainingProgramItemSchema>

const MyTrainingProgramSectionSchema = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string(),
	items: z.array(MyTrainingProgramItemSchema),
})
export type MyTrainingProgramSection = z.infer<typeof MyTrainingProgramSectionSchema>

export const MyTrainingProgramContentSchema = z.object({
	trainingProgram: TrainingProgramSchema,
	deadline: z.coerce.date().optional(),
	sections: z.array(MyTrainingProgramSectionSchema),
})

export type MyTrainingProgramContent = z.infer<typeof MyTrainingProgramContentSchema>
