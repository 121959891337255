import type { Team } from "../../../core/domain/Team.entity"
import type { User } from "../../../core/domain/User.entity"
import { type ActiveFilters, computeActiveFilters } from "./compute-active-filters"
import { computeFilterOptions, type FilterOptions } from "./compute-filter-options"

export type TeamFilters = {
	activeFilters: ActiveFilters
	filterOptions: FilterOptions
}

export function computeFilters(
	user: User,
	teams: Team[],
	workspaceUsers: User[],
	selectedTeamId: string | null,
	selectedAssignedUserId: string | null,
	onlyCurrentUserId: boolean | undefined,
): TeamFilters {
	const selectedTeam = selectedTeamId ? teams.find((team) => team.id === selectedTeamId) ?? null : null
	const activeFilters = computeActiveFilters(user, teams, selectedTeamId, selectedAssignedUserId, onlyCurrentUserId)
	const filterOptions = computeFilterOptions(user, teams, workspaceUsers, selectedTeam)

	return {
		activeFilters,
		filterOptions,
	}
}
