import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/20/solid"
import { PlayIcon } from "@heroicons/react/24/outline"
import { ArrowDownIcon, ArrowUpIcon, DocumentDuplicateIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import React, { useCallback, useEffect, useRef, useState } from "react"
import Highlighter from "react-highlight-words"
import { Trans } from "react-i18next"
import { useLocalStorage } from "react-use"

import type { CreateHighlightParams } from "../../../../../core/application/gateways/library.gateway"
import type { BaseCall } from "../../../../../core/domain/BaseCall.entity"
import { Call } from "../../../../../core/domain/Call.entity"
import { Duration, formatDuration } from "../../../../components/design-system/Duration.component"
import { Tooltip } from "../../../../components/design-system/Tooltip.component"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useCallVideoPlayer } from "../../../../contexts/callVideoPlayer.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLanguage } from "../../../../contexts/language.context"
import { useLibrary } from "../../../../hooks/useLibrary"
import { DateFormatter, DateProvider } from "../../../../utils/time"
import { CreateCallHighlightModal } from "../_MainPanel/CreateCallHighlightModal.component"
import { ShareCallModal } from "../_MainPanel/ShareCallModal.component"
import { TranscriptionSelectTextTip } from "./TranscriptionSelectTextTip.component"

export type _TranscriptionPanelProps = {
	call: BaseCall
}

export function _TranscriptionPanel({ call }: _TranscriptionPanelProps) {
	const transcription = call.props.transcription
	const [selectedTimeRange, setSelectedTimeRange] = useState<{
		startTime: number
		endTime: number
		type: "highlight" | "share"
	} | null>(null)
	const { library, fetchLibrary } = useLibrary()
	const { libraryFoldersGateway } = useDependencies()

	useEffect(() => {
		fetchLibrary()
	}, [fetchLibrary])

	const onCreateHighlight = useCallback((startTime: number, endTime: number) => {
		setSelectedTimeRange({ startTime, endTime, type: "highlight" })
	}, [])

	const onShareSelectedRange = useCallback((startTime: number, endTime: number) => {
		setSelectedTimeRange({ startTime, endTime, type: "share" })
	}, [])

	const onCreateCallTimeRangeFormReset = useCallback(() => {
		setSelectedTimeRange(null)
	}, [])

	const handleMetadataSubmit = useCallback(
		async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
			if (!selectedTimeRange) {
				return
			}

			event.preventDefault()
			const formData = new FormData(event.currentTarget)
			const containingLibraryFolderId = formData.get("containingLibraryFolderId")?.valueOf()
			if (typeof containingLibraryFolderId !== "string") {
				throw new Error("Containing library folder is required") // should not happen
			}
			const comment = formData.get("comment")?.valueOf()

			if (typeof comment !== "string" || comment.trim() === "") {
				throw new Error("Comment is required")
			}

			const createHighlightParams: CreateHighlightParams = {
				callId: call.props.id,
				startsAtMs: Math.round(selectedTimeRange.startTime * 1000),
				endsAtMs: Math.round(selectedTimeRange.endTime * 1000),
				comment,
				containingLibraryFolderId,
			}

			await libraryFoldersGateway.createHighlight(createHighlightParams)
		},
		[call.props.id, libraryFoldersGateway, selectedTimeRange],
	)

	if (!transcription) {
		return null
	}

	return (
		<>
			{selectedTimeRange && (
				<>
					<CreateCallHighlightModal
						open={selectedTimeRange.type === "highlight"}
						onClose={onCreateCallTimeRangeFormReset}
						callHighlightStartTimeSeconds={selectedTimeRange.startTime}
						callHighlightEndTimeSeconds={selectedTimeRange.endTime}
						library={library}
						onSubmit={handleMetadataSubmit}
						onReset={onCreateCallTimeRangeFormReset}
					/>
					{call instanceof Call && call.props.publicAccessToken && (
						<ShareCallModal
							open={selectedTimeRange.type === "share"}
							onClose={onCreateCallTimeRangeFormReset}
							callStartTimeSeconds={selectedTimeRange.startTime}
							callEndTimeSeconds={selectedTimeRange.endTime}
							callId={call.props.id}
							publicAccessToken={call.props.publicAccessToken}
						/>
					)}
				</>
			)}
			<TranscriptionRenderer
				transcription={transcription}
				onCreateHighlight={onCreateHighlight}
				onShareSelectedRange={onShareSelectedRange}
				call={call}
			/>
		</>
	)
}

type TranscriptionRendererProps = {
	transcription: NonNullable<Call["props"]["transcription"]>
	onCreateHighlight: (startTime: number, endTime: number) => void
	onShareSelectedRange: (startTime: number, endTime: number) => void
	call: BaseCall
}

function useTranscriptionSearch(transcriptionTurns: NonNullable<Call["props"]["transcription"]>["turns"]) {
	const [search, setSearch] = useState("")
	const [searchMode, setSearchMode] = useState<"hide-non-matching" | "show-all">("hide-non-matching")

	const filteredTurns = search
		? transcriptionTurns.filter((turn) => turn.content.toLowerCase().includes(search.trim().toLowerCase()))
		: transcriptionTurns

	const handleSearch = (newSearch: string) => {
		setSearch(newSearch)
		setSearchMode("hide-non-matching")
	}

	return {
		search,
		filteredTurns: searchMode === "hide-non-matching" ? filteredTurns : transcriptionTurns,
		setSearch: handleSearch,
		searchMode,
		setSearchMode,
		matchingResultCount: filteredTurns.length,
	}
}

function formatTranscriptionToCopy(turns: TranscriptionRendererProps["transcription"]["turns"]) {
	const formattedTurns: string[] = []

	for (const turn of turns) {
		const formattedDuration = formatDuration(turn.startTimeSec) + " - " + formatDuration(turn.endTimeSec)
		formattedTurns.push(`${formattedDuration} ${turn.attendeeLabel}:\n${turn.content}`)
	}

	return formattedTurns.join("\n\n")
}
/**
 * Scroll events are fired both when user scrolls and when we trigger a scroll
 * In order to know who initiated a scroll, this variable holds true when we programmatically initiated a scroll
 */
let scrollExpected = false

function TranscriptionRenderer({
	transcription,
	onCreateHighlight,
	onShareSelectedRange,
	call,
}: TranscriptionRendererProps) {
	const { seekPlayerTo, allowedTimeRanges, currentTimeSeconds } = useCallVideoPlayer()
	const [searchFocusedTurnIdx, setSearchFocusedTurnIdx] = useState<number | null>(null)
	const [copied, setCopied] = useState(false)
	const copyTimeout = useRef<NodeJS.Timeout | null>(null)
	const canCreateHighlight = call instanceof Call

	const { t } = useLanguage()
	const transcriptionTurnsToDisplay = allowedTimeRanges
		? transcription.turns.filter((turn) =>
				allowedTimeRanges.some(
					(range) => range.startSec <= turn.startTimeSec && turn.endTimeSec <= range.endSec,
				),
		  )
		: transcription.turns

	const { search, filteredTurns, setSearch, searchMode, setSearchMode, matchingResultCount } =
		useTranscriptionSearch(transcriptionTurnsToDisplay)

	const [scrollMode, setScrollMode] = useState<"auto" | "manual">("auto")
	const [currentTurnDirection, setCurrentTurnDirection] = useState<"above" | "below" | undefined>()
	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const currentTurnRef = useRef<HTMLDivElement>(null)
	const [selectionRange, setSelectionRange] = useState<Range | null>(null)
	const [selectedTurnRange, setSelectedTurnRange] = useState<{ startIndex: number; endIndex: number } | null>(null)
	const [displayHighlightCreationTip, setDisplayHighlightCreationTip] = useLocalStorage<boolean>(
		"display-highlight-creation-tip",
		true,
	)

	const handleCloseHighlightCreationTip = useCallback(() => {
		setDisplayHighlightCreationTip(false)
	}, [setDisplayHighlightCreationTip])

	const handleSeekToTurnClicked = (seconds: number) => {
		seekPlayerTo(seconds, "transcription")
		setScrollMode("auto")
	}

	const handleCopy = useCallback(() => {
		const textToCopy = formatTranscriptionToCopy(transcriptionTurnsToDisplay)
		navigator.clipboard.writeText(textToCopy)
		setCopied(true)
		if (copyTimeout.current) clearTimeout(copyTimeout.current)
		copyTimeout.current = setTimeout(() => setCopied(false), 2000)
	}, [transcriptionTurnsToDisplay])

	useEffect(() => {
		const scrollContainer = scrollContainerRef.current

		if (searchFocusedTurnIdx === null || !scrollContainer || searchMode === "hide-non-matching") return

		const currentTurn = scrollContainer.children[0]?.children[searchFocusedTurnIdx]
		const firstTurn = scrollContainer.children[0]?.children[0]

		if (!currentTurn || !firstTurn) return

		const { targetScroll } = getScrollPositions(scrollContainerRef.current, firstTurn, currentTurn)

		scrollContainerRef.current.scrollTo({
			behavior: "instant",
			top: targetScroll,
		})
		setSearchFocusedTurnIdx(null)
	}, [searchFocusedTurnIdx, searchMode])

	const handleSearchToTurnClicked = (seconds: number) => {
		if (!search) {
			return
		}

		const turnIndex = transcription.turns.findIndex(
			(turn) => turn.startTimeSec <= seconds && seconds <= turn.endTimeSec,
		)

		if (turnIndex === -1) return

		setSearchMode("show-all")
		setTimeout(() => setSearchFocusedTurnIdx(turnIndex), 50) // hack to wait for the scroll to be updated
	}

	const dateFormatter = new DateFormatter(new DateProvider(), t)

	const currentTurnIndex = getTurnIndexAtTime(transcription, currentTimeSeconds)

	// Switch to manual mode when user scrolls
	useEffect(() => {
		const handleUserScroll = () => {
			if (scrollExpected) {
				// scroll was initiated by us, not by user
				scrollExpected = false
				return
			}
			setScrollMode("manual")
		}

		const scrollContainer = scrollContainerRef.current
		scrollContainer?.addEventListener("scroll", handleUserScroll)

		return () => {
			scrollContainer?.removeEventListener("scroll", handleUserScroll)
		}
	}, [scrollMode])

	// Keep 'currentTurnDirection' up to date
	useEffect(() => {
		const handleScroll = () => {
			if (!scrollContainerRef.current) return
			if (!currentTurnRef.current) return

			const firstTurn = currentTurnRef.current.parentElement?.children[0]
			if (!firstTurn) return

			const { currentScroll, targetScroll } = getScrollPositions(
				scrollContainerRef.current,
				firstTurn,
				currentTurnRef.current,
			)

			setCurrentTurnDirection(currentScroll > targetScroll ? "above" : "below")
		}

		const scrollContainer = scrollContainerRef.current
		scrollContainer?.addEventListener("scroll", handleScroll)

		return () => {
			scrollContainer?.removeEventListener("scroll", handleScroll)
		}
	}, [scrollMode])

	// Scroll to current turn when in auto mode
	useEffect(() => {
		if (search) return
		if (scrollMode === "manual") return
		scrollToCurrentTurn()
	}, [currentTurnIndex, scrollMode, search])

	const scrollToCurrentTurn = () => {
		if (!scrollContainerRef.current) return
		if (!currentTurnRef.current) return

		const firstTurn = currentTurnRef.current.parentElement?.children[0]
		if (!firstTurn) return

		setScrollMode("auto")
		scrollExpected = true

		const { targetScroll } = getScrollPositions(scrollContainerRef.current, firstTurn, currentTurnRef.current)

		scrollContainerRef.current.scrollTo({
			/**
			 * Why "instant":
			 *   - Product decision: do like modjo does
			 *   - Tech decision:
			 *       - With instant scroll, only one scroll event is fired
			 *       - With smooth scroll, I guess many scroll events are fired, making it difficult to know who initiated the scroll (user or us)
			 */
			behavior: "instant",
			top: targetScroll,
		})
	}

	const handleShareSelectedRange = useCallback(() => {
		if (!selectedTurnRange) return
		const startTurn = transcription.turns[selectedTurnRange.startIndex]
		const endTurn = transcription.turns[selectedTurnRange.endIndex]
		onShareSelectedRange(startTurn.startTimeSec, endTurn.endTimeSec)
	}, [onShareSelectedRange, selectedTurnRange, transcription.turns])

	const handleCreateHighlight = useCallback(() => {
		if (!selectedTurnRange) return
		const startTurn = transcription.turns[selectedTurnRange.startIndex]
		const endTurn = transcription.turns[selectedTurnRange.endIndex]
		onCreateHighlight(startTurn.startTimeSec, endTurn.endTimeSec)
	}, [onCreateHighlight, selectedTurnRange, transcription.turns])

	const handleTextSelection = useCallback(() => {
		if (!canCreateHighlight) {
			return
		}

		setTimeout(() => {
			const selection = window.getSelection()
			const rangeCount = selection?.rangeCount
			if (selection && rangeCount && rangeCount > 0) {
				const selectedRange = selection.getRangeAt(0)
				const shouldIgnore =
					selectedRange.startOffset === selectedRange.endOffset &&
					selectedRange.startContainer === selectedRange.endContainer
				if (shouldIgnore) {
					setSelectionRange(null)
					return
				}

				const ancestor = selectedRange.commonAncestorContainer

				if (!ancestor || !ancestor.parentElement) {
					setSelectionRange(null)
					return
				}

				selection.empty()
				setSelectionRange(selectedRange)
			} else {
				setSelectionRange(null)
			}
		}, 20) // wait for the selection to be updated
	}, [canCreateHighlight])

	useEffect(() => {
		if (!selectionRange || !scrollContainerRef.current) {
			setSelectedTurnRange(null)
			return
		}

		const startContainer = selectionRange.startContainer
		const endContainer = selectionRange.endContainer

		const startContainerIndex = Array.from(scrollContainerRef.current.children[0].children).findIndex((child) =>
			child.contains(startContainer),
		)

		const endContainerIndex =
			selectionRange.endOffset !== 0
				? Array.from(scrollContainerRef.current.children[0].children).findIndex((child) =>
						child.contains(endContainer),
				  )
				: startContainerIndex

		setSelectedTurnRange({
			startIndex: startContainerIndex,
			endIndex: endContainerIndex,
		})
	}, [selectionRange])

	useEffect(() => {
		const transcriptElement = scrollContainerRef.current
		if (transcriptElement) {
			transcriptElement.addEventListener("mouseup", handleTextSelection)
		}
		return () => {
			if (transcriptElement) {
				transcriptElement.removeEventListener("mouseup", handleTextSelection)
			}
		}
	}, [handleTextSelection])

	return (
		<div className="pr-4">
			<div className="mb-4">
				<div className="flex items-center mb-4">
					<input
						id="search"
						name="search"
						type="search"
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						placeholder={t("Search in transcript")}
						className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
					/>
					<Tooltip content={copied ? t("Copied!") : t("Copy transcript")}>
						<TrackingButton
							onClick={handleCopy}
							eventName="Copy transcript"
							className="ml-3 p-2 rounded-full hover:bg-gray-200"
						>
							<DocumentDuplicateIcon className="h-5 w-5 text-gray-500" />
						</TrackingButton>
					</Tooltip>
				</div>

				{search && (
					<div className="py-1">
						<Trans
							i18nKey="searchTranscriptResult"
							values={{ a: matchingResultCount, b: search }}
							components={{
								1: <span className="font-semibold" />,
							}}
							count={filteredTurns.length}
						/>
					</div>
				)}
			</div>
			<div className="relative mb-4">
				{canCreateHighlight && (
					<TranscriptionSelectTextTip
						turns={transcription.turns}
						isVisible={Boolean(displayHighlightCreationTip)}
						onHide={handleCloseHighlightCreationTip}
					/>
				)}

				<div
					ref={scrollContainerRef}
					className={clsx(
						"overflow-y-auto",
						displayHighlightCreationTip ? "max-h-[calc(60vh-100px)]" : "max-h-[60vh]",
					)}
				>
					<div className="divide-y divide-gray-200 bg-white">
						{filteredTurns.map(
							({ attendeeId, attendeeLabel, content, startTimeSec, endTimeSec }, index) => {
								const isSelected = selectedTurnRange
									? index >= selectedTurnRange.startIndex && index <= selectedTurnRange.endIndex
									: false

								const isFirstInRange = index === selectedTurnRange?.startIndex
								const isLastInRange = index === selectedTurnRange?.endIndex
								const shouldHighlight = index === currentTurnIndex && !search

								const isCurrentTurn = index === (currentTurnIndex ?? 0) && !search
								return (
									<>
										<div
											key={index + attendeeId + content.slice(1, 10)}
											className={clsx(
												"box-border",
												selectedTurnRange && !isSelected && "opacity-40",
												isSelected,
												"relative py-4",
												search && "hover:bg-gray-50 cursor-pointer",
												shouldHighlight && "bg-gray-100",
												isFirstInRange &&
													isLastInRange &&
													"rounded-md !border !border-indigo-600",
												isFirstInRange &&
													!isLastInRange &&
													"rounded-t-md !border-t border-l border-r !border-indigo-600",
												isLastInRange &&
													!isFirstInRange &&
													"rounded-b-md !border-b border-l border-r !border-indigo-600 !border-t-0",
												isSelected &&
													!isFirstInRange &&
													!isLastInRange &&
													"border-l border-r !border-indigo-600 !border-t-0",
												"selection:text-indigo-600 selection:bg-indigo-100",
											)}
											ref={isCurrentTurn ? currentTurnRef : undefined}
											onClick={() => handleSearchToTurnClicked(startTimeSec)}
										>
											<div className="text-sm font-medium text-gray-900 flex items-center select-none">
												<TrackingButton
													onClick={() => handleSeekToTurnClicked(startTimeSec)}
													className="bg-gray-100 text-indigo-600 p-1.5 rounded-sm inline-flex text-xs mr-2 gap-1.5"
													eventName="Seek video player to transcription turn"
													eventProperties={{
														seekPlayerToSeconds: startTimeSec,
													}}
												>
													<PlayIcon className="w-4 h-4" />
													<span>
														<Duration className="cursor-pointer" seconds={startTimeSec} /> -{" "}
														<Duration seconds={endTimeSec} />
													</span>
												</TrackingButton>
												{attendeeLabel}
											</div>
											<div className="mt-2 text-sm text-gray-500">
												<Highlighter
													highlightClassName="font-bold"
													searchWords={[search]}
													autoEscape={true}
													textToHighlight={content}
												/>
											</div>
										</div>
									</>
								)
							},
						)}
					</div>
					{scrollMode === "manual" && !search && !selectedTurnRange && (
						<button
							className="absolute bottom-4 right-6 z-40 flex items-center gap-x-1.5 rounded-lg bg-black px-3 py-2 text-xs font-bold text-white"
							onClick={scrollToCurrentTurn}
						>
							{currentTurnDirection === "above" && <ArrowUpIcon className="h-4 w-4" aria-hidden="true" />}
							{currentTurnDirection === "below" && (
								<ArrowDownIcon className="h-4 w-4" aria-hidden="true" />
							)}
							{t("Scroll to")} {dateFormatter.formatTime(currentTimeSeconds)}
						</button>
					)}
				</div>
				{selectedTurnRange && (
					<div className="flex left-0 right-0 justify-center absolute -bottom-10">
						<div className="inline-flex rounded-md shadow-sm z-20">
							<TrackingButton
								eventName="Transcription: create highlight clicked"
								className="relative inline-flex items-center rounded-l-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-indigo-300 hover:bg-indigo-600 focus:z-10"
								onClick={handleCreateHighlight}
							>
								{t("Create a call highlight")}
							</TrackingButton>
							<Menu as="div" className="relative -ml-px block">
								<MenuButton className="relative inline-flex items-center rounded-r-md bg-indigo-600 px-2 py-2 text-sm text-white ring-1 ring-inset ring-indigo-300 hover:bg-indigo-600 focus:z-10">
									<span className="sr-only">Open options</span>
									<ChevronDownIcon aria-hidden="true" className="h-5 w-5" />
								</MenuButton>
								<MenuItems
									transition
									className="-translate-y-[175%] absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
								>
									<div className="py-1">
										<MenuItem>
											<TrackingButton
												eventName="Transcription: share call snippet clicked"
												className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 w-full"
												onClick={handleShareSelectedRange}
											>
												{t("Share call snippet")}
											</TrackingButton>
										</MenuItem>
										<MenuItem>
											<TrackingButton
												eventName="Transcription: cancel menu clicked"
												className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 w-full"
												onClick={() => setSelectedTurnRange(null)}
											>
												{t("Cancel")}
											</TrackingButton>
										</MenuItem>
									</div>
								</MenuItems>
							</Menu>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

/**
 * Return the turn index at a given time
 *
 * The blank space between a turn and its successor is considered as belonging to the turn
 */
function getTurnIndexAtTime(
	transcription: TranscriptionRendererProps["transcription"],
	timeSec: number,
): number | undefined {
	for (let i = 0; i < transcription.turns.length; i++) {
		const current = transcription.turns[i]
		const next = transcription.turns[i + 1]

		if (current.startTimeSec > timeSec) return undefined
		if (current.startTimeSec <= timeSec && current.endTimeSec >= timeSec) return i
		if (!next) return i
		if (next.startTimeSec > timeSec) return i
	}
}

/**
 * @param container Scrollable div that contains all turns
 */
function getScrollPositions(container: Element, firstTurn: Element, currentTurn: Element) {
	const currentScroll = container.scrollTop

	const firstTurnScroll = firstTurn.getBoundingClientRect().top
	const currentTurnScroll = currentTurn.getBoundingClientRect().top
	const currentTurnOffset = currentTurnScroll - firstTurnScroll

	const currentTurnHeight = currentTurn.getBoundingClientRect().height
	const halfOfContainerHeight = container.getBoundingClientRect().height / 2

	/**
	 * | If we scroll to                                                   | Then                                                     |   |
	 * |-------------------------------------------------------------------|----------------------------------------------------------|---|
	 * | currentTurnOffset                                                 | Current turn's top is aligned with container's top       |   |
	 * | currentTurnOffset - halfOfContainerHeight                         | Current turn's top is aligned with container's center    |   |
	 * | currentTurnOffset - halfOfContainerHeight + currentTurnHeight / 2 | Current turn's center is aligned with container's center |   |
	 */
	const targetScroll = currentTurnOffset - halfOfContainerHeight + currentTurnHeight / 2

	return { currentScroll, targetScroll }
}
