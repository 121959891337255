import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react"
import { EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/20/solid"
import { FolderArrowDownIcon, FolderPlusIcon, PencilSquareIcon } from "@heroicons/react/24/outline"
import clsx from "clsx"
import { Fragment } from "react"

import type { LibraryFolder } from "../../../../../core/domain/Library.entity"
import { TrackingButton } from "../../../../components/design-system/TrackingButton.component"
import { useLanguage } from "../../../../contexts/language.context"

type LibraryFolderManagementMenuProps = {
	folder: LibraryFolder
	onLibraryFolderNameEdition?: (folder: LibraryFolder) => void
	onLibraryFolderParentEdition?: (folder: LibraryFolder) => void
	onLibrarySubfolderCreation?: (parentLibraryFolder: LibraryFolder) => void
	onLibraryFolderDeletion?: (folder: LibraryFolder) => void
	invisible?: boolean
}

export function LibraryFolderManagementMenu({
	folder,
	onLibraryFolderNameEdition,
	onLibraryFolderParentEdition,
	onLibrarySubfolderCreation,
	onLibraryFolderDeletion,
	invisible,
}: LibraryFolderManagementMenuProps) {
	const { t } = useLanguage()

	if (
		!onLibraryFolderNameEdition &&
		!onLibraryFolderParentEdition &&
		!onLibrarySubfolderCreation &&
		!onLibraryFolderDeletion
	) {
		return null
	}

	return (
		<Menu as="div" className={clsx("relative inline-block text-left", invisible && "invisible")}>
			<div>
				<MenuButton className="relative -m-2 flex items-center rounded-full p-1 text-gray-400 hover:text-gray-600 hover:bg-gray-200 mr-2">
					<span className="absolute -inset-1" />
					<span className="sr-only">Open options</span>
					<EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
				</MenuButton>
			</div>
			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<MenuItems className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						{onLibraryFolderNameEdition && (
							<MenuItem>
								{({ active }) => (
									<TrackingButton
										type="button"
										className={clsx(
											active ? "bg-gray-100 text-gray-900" : "text-gray-700",
											"flex px-4 py-2 text-sm w-full",
										)}
										onClick={() => onLibraryFolderNameEdition(folder)}
										eventName="Open library folder name edition modal"
										eventProperties={{
											editionTarget: "name",
											libraryFolderId: folder.id,
										}}
									>
										<PencilSquareIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
										<span>{t("Edit name")}</span>
									</TrackingButton>
								)}
							</MenuItem>
						)}
						{onLibraryFolderParentEdition && (
							<MenuItem>
								{({ active }) => (
									<TrackingButton
										type="button"
										className={clsx(
											active ? "bg-gray-100 text-gray-900" : "text-gray-700",
											"flex px-4 py-2 text-sm w-full",
										)}
										onClick={() => onLibraryFolderParentEdition(folder)}
										eventName="Open library folder parent edition modal"
										eventProperties={{
											editionTarget: "parent folder",
											libraryFolderId: folder.id,
										}}
									>
										<FolderArrowDownIcon
											className="mr-3 h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
										<span>{t("Move folder")}</span>
									</TrackingButton>
								)}
							</MenuItem>
						)}

						{onLibrarySubfolderCreation && (
							<MenuItem>
								{({ active }) => (
									<TrackingButton
										type="button"
										className={clsx(
											active ? "bg-gray-100 text-gray-900" : "text-gray-700",
											"flex px-4 py-2 text-sm w-full",
											"text-left",
										)}
										onClick={() => onLibrarySubfolderCreation(folder)}
										eventName="Open library subfolder creation modal"
										eventProperties={{
											editionTarget: "subfolder",
											libraryFolderId: folder.id,
										}}
									>
										<FolderPlusIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
										<span>{t("Add subfolder")}</span>
									</TrackingButton>
								)}
							</MenuItem>
						)}

						{onLibraryFolderDeletion && (
							<MenuItem>
								{({ active }) => (
									<TrackingButton
										type="button"
										className={clsx(
											active ? "bg-gray-100 text-red-600" : "text-red-500",
											"flex px-4 py-2 text-sm w-full",
										)}
										onClick={() => onLibraryFolderDeletion(folder)}
										eventName="Delete library folder"
										eventProperties={{
											libraryFolderId: folder.id,
										}}
									>
										<TrashIcon className="mr-3 h-5 w-5 text-red-400" aria-hidden="true" />
										<span className="text-left">{t("Delete folder")}</span>
									</TrackingButton>
								)}
							</MenuItem>
						)}
					</div>
				</MenuItems>
			</Transition>
		</Menu>
	)
}
