import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid"
import { Trans } from "react-i18next"

import { TrackingButton } from "../../design-system/TrackingButton.component"
import { generatePagination } from "./generatePagination"

type PaginationFooterProps = {
	navigatePrev: () => void
	navigateNext: () => void
	navigateToPage: (page: number) => void
	currentPage: number
	pageSize: number
	totalItems: number
}

// Based on https://tailwindui.com/components/application-ui/navigation/pagination
export default function PaginationFooter({
	navigatePrev,
	navigateNext,
	navigateToPage,
	currentPage,
	pageSize,
	totalItems,
}: PaginationFooterProps) {
	const totalPages = Math.ceil(totalItems / pageSize)
	const firstItemIndex = (currentPage - 1) * pageSize + 1
	const lastItemIndex = Math.min(currentPage * pageSize, totalItems)
	const buttons = generatePagination(currentPage, totalPages)

	return (
		<div className="flex items-center justify-between bg-white py-4">
			<div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
				<div>
					<p className="text-sm text-gray-700">
						<Trans
							i18nKey="showingPaginationResults"
							values={{ a: firstItemIndex - 1, b: lastItemIndex, c: totalItems }}
							components={{
								1: <span className="font-semibold" />,
								2: <span className="font-semibold" />,
								3: <span className="font-semibold" />,
							}}
						/>
					</p>
				</div>
				<div>
					<nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
						<TrackingButton
							onClick={navigatePrev}
							eventName="Call pagination interaction"
							eventProperties={{
								type: "navigatePrevious",
								pageNumber: currentPage - 1,
							}}
							disabled={currentPage === 1}
							className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
						>
							<span className="sr-only">Previous</span>
							<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
						</TrackingButton>

						{buttons.map((page, index) => {
							if (page === "...") {
								return (
									<span
										key={page + index}
										className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
									>
										...
									</span>
								)
							} else {
								return (
									<TrackingButton
										key={page + index}
										eventName="Call pagination interaction"
										eventProperties={{
											type: "navigateToPage",
											pageNumber: page,
										}}
										onClick={() => navigateToPage(page)}
										className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
											currentPage === page
												? "text-white bg-indigo-600"
												: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
										} focus:z-20 focus:outline-offset-0`}
									>
										{page}
									</TrackingButton>
								)
							}
						})}

						<TrackingButton
							eventName="Call pagination interaction"
							eventProperties={{
								type: "navigateToNext",
								nextPageNumber: currentPage + 1,
							}}
							onClick={navigateNext}
							disabled={currentPage >= totalPages}
							className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
						>
							<span className="sr-only">Next</span>
							<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
						</TrackingButton>
					</nav>
				</div>
			</div>
		</div>
	)
}
