import clsx from "clsx"
import React from "react"

import type { Relationship } from "../../../../../core/application/gateways/relationships.gateway"
import type { Call } from "../../../../../core/domain/Call.entity"
import { useLanguage } from "../../../../contexts/language.context"
import { makeCallPath } from "../../../../router/Router"
import { DateFormatter, DateProvider } from "../../../../utils/time"
import { TrackingLink } from "../../../design-system/TrackingLink.component"

type RelationshipCallActivityPanelProps = {
	relationship: Relationship
	currentCall?: Call
}

export function RelationshipCallActivityPanel({ relationship, currentCall }: RelationshipCallActivityPanelProps) {
	const { t, language } = useLanguage()
	const dateProvider = new DateProvider()
	const dateFormatter = new DateFormatter(dateProvider, t)
	const sortedCalls = relationship.activities?.calls?.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())

	return (
		<div className="flex flex-col gap-2 mt-4">
			{sortedCalls?.map((call) => {
				const formattedDate = dateFormatter.getFormattedDate(call.createdAt)
				return (
					<div
						key={call.id}
						className={clsx(
							"flex flex-row gap-2 hover:bg-slate-50 p-2",
							call.id === currentCall?.props.id && "bg-indigo-50",
						)}
					>
						<img
							src={call.thumbnailUrl ?? "/rippletide_logo_navy_background.svg"}
							alt={call.name}
							className="w-12 h-12 min-w-12 lg:max-w-12 rounded-md object-cover flex-shrink-0"
							width="48"
							height="48"
							loading="lazy"
						/>
						<div className="flex flex-col">
							<TrackingLink
								to={makeCallPath(call.id, undefined)}
								eventName="Relationship side panel: call click"
								eventProperties={{ callId: call.id }}
								className={clsx(
									"text-md font-semibold",
									call.id === currentCall?.props.id && "text-indigo-500",
								)}
								target="_blank"
							>
								{call.name}
							</TrackingLink>
							<span
								className="text-sm text-gray-600"
								title={`${call.createdAt.toLocaleDateString()} ${call.createdAt.toLocaleTimeString()}`}
							>
								{formattedDate.day} ({dateFormatter.formatToRelativeTimeLabel(call.createdAt, language)}
								)
							</span>
						</div>
					</div>
				)
			})}
		</div>
	)
}
