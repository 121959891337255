import {
	BookmarkIcon,
	ChatBubbleBottomCenterTextIcon,
	DocumentIcon,
	VideoCameraIcon,
} from "@heroicons/react/24/outline"
import assertNever from "assert-never"
import clsx from "clsx"
import React from "react"

import type { TrainingSectionContentType } from "../../../../../core/application/gateways/training.gateway/schemas/training-program-edit-view-model.schema"

type ContentTypeIconProps = {
	contentType: TrainingSectionContentType
	className?: string
}

export function ContentTypeIcon({ contentType, className }: ContentTypeIconProps) {
	let Icon
	switch (contentType) {
		case "highlight":
			Icon = BookmarkIcon
			break
		case "video":
			Icon = VideoCameraIcon
			break
		case "text":
			Icon = ChatBubbleBottomCenterTextIcon
			break
		case "pdf":
			Icon = DocumentIcon
			break
		default:
			assertNever(contentType)
	}

	return <Icon className={clsx("h-5 w-5", className)} aria-hidden="true" />
}
