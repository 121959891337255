const trackLinkedinSignupConversion = () => {
	try {
		window.lintrk?.("track", { conversion_id: 18587306 })
	} catch (e) {
		console.error("Error tracking LinkedIn signup", e)
	}
}

const trackGoogleTagManager = (action: string, data?: Record<string, unknown>) => {
	try {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({ event: action, ...data })
	} catch (e) {
		console.error("Error tracking event", e)
	}
}

export function useTrackers() {
	return { trackGoogleTagManager, trackLinkedinSignupConversion }
}
