import clsx from "clsx"
import React from "react"

import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { type TranslationKey, useLanguage } from "../../contexts/language.context"

export type SalesMotionTab = "sales-motion" | "forecast" | "pipeline"

const tabs: Array<{ name: TranslationKey; id: SalesMotionTab; disabled?: boolean }> = [
	{ name: "Sales performance", id: "sales-motion" },
	{ name: "Pipeline comparison", id: "pipeline" },
	{ name: "Forecast", id: "forecast", disabled: true },
]

type ISalesMotionTabsProps = {
	currentTab: SalesMotionTab
	handleTabChange: (tab: SalesMotionTab) => void
}

export function SalesMotionTabs({ currentTab, handleTabChange }: ISalesMotionTabsProps) {
	const { t } = useLanguage()
	const { workspace } = useAuthenticatedSession()
	return (
		<div>
			<div className="sm:hidden">
				<label htmlFor="tabs" className="sr-only">
					Select a tab
				</label>
				<select
					id="tabs"
					name="tabs"
					defaultValue={tabs[0].name}
					className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-navy-500 focus:outline-none focus:ring-navy-500 sm:text-sm"
					onChange={(e) => handleTabChange(e.target.value as SalesMotionTab)}
				>
					{tabs.map((tab) => (
						<option key={tab.name} disabled={tab.disabled}>
							{t(tab.name)} {tab.disabled ? t("(coming soon)") : ""}
						</option>
					))}
				</select>
			</div>
			<div className="hidden sm:block">
				<div className="border-b border-gray-200 flex justify-between items-end">
					<nav aria-label="Tabs" className="-mb-px flex space-x-8">
						{tabs.map((tab) => (
							<button
								key={tab.name}
								aria-current={currentTab === tab.id ? "page" : undefined}
								className={clsx(
									currentTab === tab.id
										? "border-navy-500 text-navy-600"
										: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
									"whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium",
									tab.disabled && "opacity-50 cursor-not-allowed",
								)}
								// disabled={tab.disabled}
								onClick={
									!tab.disabled || workspace.isRippletideWorkspace()
										? () => handleTabChange(tab.id)
										: undefined
								}
							>
								{t(tab.name)} {tab.disabled ? t("(coming soon)") : ""}
							</button>
						))}
					</nav>
				</div>
			</div>
		</div>
	)
}
