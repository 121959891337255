import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"

import { type Call } from "../../../../core/domain/Call.entity"
import { useLanguage } from "../../../contexts/language.context"
import { ScoreBadge } from "../ScoreBadge.component"

export type _AnalysisCellProps = {
	call: Call
	className?: string
}

export function _AnalysisCell({ call, className }: _AnalysisCellProps) {
	const { t } = useLanguage()

	if (call.props.unprocessableReason) {
		return (
			<ExclamationTriangleIcon className="w-7 h-7 text-gray-700" title={call.getUnprocessableReasonString(t)} />
		)
	}

	if (!call.props.score) {
		// return <SpinnerIcon className="h-7 w-7 text-gray-400" aria-hidden="true" />
		return "-" // tech debt: unsure how to differentiate between "loading" and "will never happen"
	}

	const { totalScore: score, totalMaxScore: maxScore } = call.props.score

	return <ScoreBadge score={score} maxScore={maxScore} className={className} />
}
