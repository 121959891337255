import { TrackingLink } from "../../../components/design-system/TrackingLink.component"
import { PlanBadge } from "../../../components/shared/PlanBadge.component"
import { useLanguage } from "../../../contexts/language.context"
import { makeWorkspaceBillingPath } from "../../Settings/config"

export function DealUpgradePlanOverlay() {
	const { t } = useLanguage()
	return (
		<>
			<div className="absolute inset-0 bg-gradient-to-t from-white z-50 pointer-events-none" />
			<div className="absolute inset-0 z-50 flex items-center justify-center overflow-y-auto">
				<div className="relative bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
					<div className="flex items-center justify-start gap-x-4 mb-4">
						<h3 className="text-lg font-semibold leading-6 text-gray-900">{t("Deals")}</h3>
						<PlanBadge source="deals: upgrade plan overlay" minimumPlanName="Scaler" />
					</div>
					<p className="text-gray-600 mb-2">
						{t(
							"Get all the important information related to your deals and calls in one place, giving you a clear overview of their movement through each stage of your pipeline and allowing you to quickly identify where there are risks or actions to be taken.",
						)}
					</p>
					<p className="text-gray-600 mb-4">{t("Upgrade to a Scaler plan to activate your deals now.")}</p>
					<TrackingLink
						to={makeWorkspaceBillingPath()}
						eventName="deals: upgrade plan"
						className="mt-4 flex justify-center px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-navy-500 rounded-full bg-gradient-to-r hover:from-navy-500 hover:to-pink transition duration-500 ease-in-out"
					>
						{t("Upgrade my plan")}
					</TrackingLink>
				</div>
			</div>
		</>
	)
}
