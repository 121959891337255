import React from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Layout } from "../../components/shared/Layout/Layout.component"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useLanguage } from "../../contexts/language.context"
import {
	makeForecastPath,
	makeSalesMotionPath,
	makeSalesMotionPipelinePath,
	type SalesMotionPathParams,
} from "../../router/Router"
import { SalesMotionConnectYourCrmOverlay } from "./components/SalesMotionConnectYourCrmOverlay.component"
import { SalesMotionUpgradePlanOverlay } from "./components/SalesMotionUpgradePlanOverlay.component"
import { type SalesMotionTab, SalesMotionTabs } from "./SalesMotionTabs.component"
import { ForecastTabView } from "./tabs/Forecast/Forecast.tab"
import { SalesMotionPipelineTabView } from "./tabs/PipelineComparison/SalesMotionPipeline.tab"
import { SalesPerformanceTabView } from "./tabs/SalesPerformance/SalesPerformance.tab"

export function SalesMotionPage() {
	const { viewType } = useParams<SalesMotionPathParams>()
	const tab: SalesMotionTab = viewType || "sales-motion"
	const { workspace } = useAuthenticatedSession()
	let overlayToDisplay: "upgrade-plan" | "connect-crm" | null = null
	if (!workspace.hasEnterprisePlan()) {
		overlayToDisplay = "upgrade-plan"
	} else if (!workspace.hasCrmIntegrationSetup) {
		overlayToDisplay = "connect-crm"
	}

	const navigate = useNavigate()

	const handleTabChange = (tab: SalesMotionTab) => {
		if (tab === "pipeline") {
			navigate(makeSalesMotionPipelinePath())
		} else if (tab === "forecast") {
			navigate(makeForecastPath())
		} else {
			navigate(makeSalesMotionPath())
		}
	}

	const { t } = useLanguage()

	return (
		<Layout pageName={t("Sales motion")} isFluid className="h-full !p-0">
			<div className="relative flex-1">
				{overlayToDisplay === "upgrade-plan" && <SalesMotionUpgradePlanOverlay />}
				{overlayToDisplay === "connect-crm" && <SalesMotionConnectYourCrmOverlay />}

				<div className="px-6">
					{overlayToDisplay !== null ? (
						<>
							<SalesMotionTabs currentTab="sales-motion" handleTabChange={handleTabChange} />
							<SalesPerformanceTabView useFakeData={true} />
						</>
					) : (
						<>
							<SalesMotionTabs currentTab={tab} handleTabChange={handleTabChange} />
							{tab === "pipeline" && <SalesMotionPipelineTabView />}
							{tab === "forecast" && <ForecastTabView />}
							{tab === "sales-motion" && <SalesPerformanceTabView useFakeData={false} />}
						</>
					)}
				</div>
			</div>
		</Layout>
	)
}
