import clsx from "clsx"
import { omit } from "lodash"
import React from "react"
import { Link } from "react-router-dom"

import { type AnalyticsProps, useAnalytics } from "../../contexts/analytics.context"

type ButtonAs = "button" | "link"

type BaseButtonProps = {
	size?: "sm" | "md" | "lg" | "xs"
	variant?: "default" | "primary" | "danger" | "text" | "success"
	disabled?: boolean
	icon?: React.ReactNode
	as?: ButtonAs
	onClick?: () => void
} & AnalyticsProps

type ButtonProps = BaseButtonProps &
	React.ButtonHTMLAttributes<HTMLButtonElement> & {
		as?: "button"
	}

export type LinkProps = BaseButtonProps &
	Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href"> & {
		as: "link"
		to: string // "to" is required when "as" is "link"
	}

export function Button({
	size = "md",
	variant = "default",
	icon,
	children,
	eventName,
	eventProperties,
	onClick,
	as = "button", // Default to "button"
	disabled,
	...props
}: ButtonProps | LinkProps) {
	const { setupEventTracking } = useAnalytics()
	const { trackEvent: trackUsage, debugDOMProps } = setupEventTracking({ eventName, eventProperties })

	const handleClick = () => {
		if (disabled) return // Prevent tracking if disabled
		console.debug("[Button] Tracking event:", eventName, eventProperties)
		trackUsage()
		onClick?.()
	}

	const sizeClasses = {
		xs: "px-2 py-1 text-xs",
		sm: "px-2.5 py-1.5 text-sm",
		md: "px-3 py-2 text-sm",
		lg: "px-3.5 py-2.5 text-sm",
		text: "", // No additional padding for text variant
	}

	const variantClasses = {
		default:
			"bg-white text-black hover:bg-gray-100 disabled:bg-gray-200 disabled:text-gray-400 ring-1 ring-inset ring-gray-300",
		primary: "bg-navy-700 text-white hover:bg-navy-500 disabled:bg-navy-300 disabled:text-white",
		danger: "bg-red-600 text-white hover:bg-red-500 disabled:bg-red-300 disabled:text-white",
		text: "bg-transparent text-navy-700 hover:text-navy-500 disabled:text-gray-400",
		success: "bg-emerald-500 text-white hover:bg-emerald-400 disabled:bg-emerald-300 disabled:text-white",
	}

	const commonClasses = clsx(
		"inline-flex items-center gap-x-1.5 rounded-md font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-navy-700 disabled:opacity-50 disabled:cursor-not-allowed",
		sizeClasses[size],
		variantClasses[variant],
		variant === "text" && "hover:underline",
		props.className,
	)

	if (as === "link") {
		// Handle "link" rendering with "to" prop
		const { to, ...linkProps } = props as LinkProps

		return (
			<Link
				to={to}
				className={commonClasses}
				onClick={handleClick}
				{...omit(linkProps, "className")}
				{...debugDOMProps}
			>
				{icon && <span className="-ml-0.5 h-5 w-5">{icon}</span>}
				{children}
			</Link>
		)
	}

	const buttonProps = props as ButtonProps

	return (
		<button
			type="button"
			className={commonClasses}
			onClick={handleClick}
			disabled={disabled}
			{...omit(buttonProps, "className")}
			{...debugDOMProps}
		>
			{icon && <span className="-ml-0.5 h-5 w-5">{icon}</span>}
			{children}
		</button>
	)
}
