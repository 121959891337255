import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react"
import { ChevronRightIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import type { CSSProperties } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import type { LibraryFolder } from "../../../../../core/domain/Library.entity"
import { TrackingLinkOrButton } from "../../../../components/design-system/TrackingLinkOrButton.component"
import { makeLibraryFolderPath } from "../../../../router/Router"
import { FolderDisplayName } from "../FolderDisplayName.component"
import { LibraryFolderManagementMenu } from "./LibraryFolderManagementMenu.component"

type SideLibraryFoldersNavigationFolderProps = {
	folder: LibraryFolder
	currentFolder: LibraryFolder | undefined
	onLibraryFolderNameEdition?: (folder: LibraryFolder) => void
	onLibraryFolderParentEdition?: (folder: LibraryFolder) => void
	onLibrarySubfolderCreation?: (parentLibraryFolder: LibraryFolder) => void
	onLibraryFolderDeletion?: (folder: LibraryFolder) => void
	depth?: number
	onFolderClick?: (folder: LibraryFolder) => void
	className?: string
}

export function SideLibraryFoldersNavigationFolder({
	folder,
	currentFolder,
	depth = 1,
	onLibraryFolderNameEdition,
	onLibraryFolderParentEdition,
	onLibraryFolderDeletion,
	onLibrarySubfolderCreation,
	onFolderClick,
	className,
}: SideLibraryFoldersNavigationFolderProps) {
	const { pathname } = useLocation()
	const navigate = useNavigate()

	const shouldBeOpen = currentFolder ? folder.isFolderOrDescendantOpen(currentFolder) : false
	const isActive = pathname === makeLibraryFolderPath(folder.id) || folder.id === currentFolder?.id

	/** Leaf folders to not appear bold, and clicking them opens them instead of opening drop-down menu */
	const isLeaf = !folder.hasChildren()
	const onLeafFolderClick = () => {
		if (onFolderClick) {
			console.log("on leaf folder click: onFolderClick()")
			onFolderClick(folder)
		} else {
			console.log("on leaf folder click: navigate")
			navigate(makeLibraryFolderPath(folder.id))
		}
	}

	return (
		<Disclosure as="div" defaultOpen={shouldBeOpen}>
			{({ open }) => (
				<>
					<div
						className={clsx(
							isActive ? "bg-gray-100" : "hover:bg-gray-100",
							"flex items-center w-full text-left rounded-md gap-x-3 text-sm leading-6 text-gray-700",
							(depth <= 1 || !isLeaf) && "font-semibold",
						)}
					>
						<DisclosureButton
							className={clsx(
								"flex items-center w-full text-left rounded-md gap-x-3 text-sm leading-6 text-gray-700",
								className,
							)}
							onClick={(e) => {
								if (isLeaf) {
									e.preventDefault()
									isLeaf && onLeafFolderClick()
								}
							}}
						>
							<div className="flex justify-between w-full p-2">
								<div className="flex items-center">
									{!folder.properties.tags.some((tag) => tag === "no-children") && (
										<ChevronRightIcon
											className={clsx(
												open ? "rotate-90 text-gray-500" : "text-gray-400",
												"h-5 w-5 shrink-0",
												folder.children.length === 0 && "invisible",
											)}
											aria-hidden="true"
										/>
									)}
									<TrackingLinkOrButton
										eventName={"Library sidebar: navigate to folder"}
										eventProperties={{ libraryFolderId: folder.id }}
										{...(onFolderClick
											? {
													onClick: () => onFolderClick(folder),
											  }
											: {
													to: makeLibraryFolderPath(folder.id),
											  })}
									>
										<FolderDisplayName folder={folder} />
									</TrackingLinkOrButton>
								</div>
								{
									<span className="text-gray-400 font-normal">
										{folder.getRecursiveCallHighlightCount()}
									</span>
								}
							</div>
						</DisclosureButton>
						<LibraryFolderManagementMenu
							folder={folder}
							onLibraryFolderNameEdition={onLibraryFolderNameEdition}
							onLibraryFolderParentEdition={onLibraryFolderParentEdition}
							onLibraryFolderDeletion={onLibraryFolderDeletion}
							onLibrarySubfolderCreation={onLibrarySubfolderCreation}
							invisible={folder.properties.tags.some((tag) => tag === "readonly")}
						/>
					</div>
					<DisclosurePanel as="ul" className="mt-1">
						{folder.children.map((subFolder) => {
							const style: CSSProperties = {
								marginLeft: `${depth ? 1 : 0}rem`,
							}
							return (
								<li key={subFolder.name} style={style}>
									<SideLibraryFoldersNavigationFolder
										key={subFolder.id}
										folder={subFolder}
										currentFolder={currentFolder}
										depth={depth + 1}
										onLibraryFolderNameEdition={onLibraryFolderNameEdition}
										onLibraryFolderParentEdition={onLibraryFolderParentEdition}
										onLibrarySubfolderCreation={onLibrarySubfolderCreation}
										onLibraryFolderDeletion={onLibraryFolderDeletion}
										className={className}
										onFolderClick={onFolderClick}
									/>
								</li>
							)
						})}
					</DisclosurePanel>
				</>
			)}
		</Disclosure>
	)
}
