import { z } from "zod"

export const leadKnowledgeV1Schema = z.object({
	version: z.literal(1),
	specificNeeds: z.array(z.string()).optional(),
	shortTermGoals: z.array(z.string()).optional(),
	longTermGoals: z.array(z.string()).optional(),
	challenges: z.array(z.string()).optional(),

	commentsOnProducts: z.array(z.string()).optional(),
	suggestionsOrRequests: z.array(z.string()).optional(),
	critiquesAndPraises: z.array(z.string()).optional(),

	preferredCommunicationStyle: z.string().optional(),
	reactionsToOffers: z.array(z.string()).optional(),
	negotiationStyle: z.string().optional(),

	salesOpportunities: z.array(z.string()).optional(),
	concernsAndObjections: z.array(z.string()).optional(),
	potentialRisks: z.array(z.string()).optional(),

	insightsDiscoveryType: z.string().optional(),
	insightsDiscoveryJustifications: z.string().optional(),
})
export const leadKnowledgeV2Contact = z.object({
	attendeeId: z.string(),
	firstName: z.string().nullish(),
	lastName: z.string().nullish(),
	jobTitle: z.string().nullish(),
	decisionMakingAuthority: z.enum(["low", "medium", "high"]).nullish(),
	preferredCommunicationChannel: z.string().nullish(), // "email", "phone", "chat", "in-person"
	personalityProfile: z
		.object({
			type: z.literal("FiveFactors"),
			traits: z.object({
				openness: z.enum(["low", "medium", "high"]),
				conscientiousness: z.enum(["low", "medium", "high"]),
				extraversion: z.enum(["low", "medium", "high"]),
				agreeableness: z.enum(["low", "medium", "high"]),
				neuroticism: z.enum(["low", "medium", "high"]),
			}),
			summary: z.string(), // how should we (as SDRs) approach this person based on their personality profile?
		})
		.nullish(),
})
export const leadKnowledgeV2OpportunitySchema = z
	.object({
		currentProvider: z
			.object({
				name: z.string(),
				satisfactionLevel: z.enum(["low", "medium", "high"]).nullish(),
				satisfactionComment: z.string().nullish(), // what do they like or dislike about their current provider?
				monetaryValue: z.number().nullish(),
				monetaryCurrency: z.string().nullish(),
				renewalDate: z.coerce.date().nullish(),
			})
			.nullish(),
		competitiveConsiderationSummary: z.string().nullish(), // what are the main reasons why they are considering switching providers?

		budgetApprovalProcess: z.string().nullish(), // how does the budget approval process look like? Who is involved?

		buyingContext: z.string().nullish(), // why are they looking for a new provider? What are the main drivers?
		specificNeeds: z.array(z.string()).nullish(),
		shortTermGoals: z.array(z.string()).nullish(),
		longTermGoals: z.array(z.string()).nullish(),

		decisionCriteria: z.array(z.string()).nullish(),

		deal: z
			.object({
				monetaryValue: z.number().nullish(),
				monetaryCurrency: z.string().nullish(),
				estimatedClosingProbability: z.number().gte(0).lte(100).nullish(),
				estimatedCloseDate: z.coerce.date().nullish(),
				engagementScope: z.string().nullish(),
			})
			.nullish(),
	})
	.nullish()

export type LeadKnowledgeV2Opportunity = z.infer<typeof leadKnowledgeV2OpportunitySchema>
export const leadKnowledgeV2Schema = z.object({
	version: z.literal(2),
	contacts: z.array(leadKnowledgeV2Contact).nullish(),
	account: z
		.object({
			industry: z.string().nullish(),
			employeeCount: z.number().nullish(),
			annualRevenue: z.number().nullish(),
			businessModelType: z.string().nullish(), // "B2B", "B2C", "B2G", etc

			companyType: z.string().nullish(), // "private", "public", "non-profit", "government", "educational", "other"
			companyLocation: z.string().nullish(), // country, city, state, etc.

			marketCompetitiveness: z.enum(["low", "medium", "high"]).nullish(), // are they a market leader, a challenger, or a niche player?

			keyDecisionMakers: z.array(z.string()).nullish(), // names of key decision makers

			technologicalStack: z.array(z.string()).nullish(),
		})
		.nullish(),
	opportunity: leadKnowledgeV2OpportunitySchema.nullish(),
	reactions: z
		.object({
			priceAnnouncement: z.enum(["positive", "neutral", "negative"]).nullish(), // how did they react to the price announcement?
			productDemo: z.enum(["positive", "neutral", "negative"]).nullish(), // how did they react to the product demo?
			missingFeatures: z.array(z.string()).nullish(), // what features are they missing?
			objections: z.array(z.string()).nullish(), // what are their main objections?
			others: z.array(z.string()).nullish(), // other reactions
		})
		.nullish(),
})

export const leadKnowledgeSchema = z
	.discriminatedUnion("version", [leadKnowledgeV1Schema, leadKnowledgeV2Schema])
	.optional()
	.nullable()
