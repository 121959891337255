import _ from "lodash"

import type {
	CallInsightsResponse,
	GetCallsInsightsQueryParams,
	IInsightsGateway,
} from "../../application/gateways/insights.gateway"

export class HttpInsightsGateway implements IInsightsGateway {
	constructor(private readonly baseApiUrl: string) {}

	public async getCallsInsights(params: GetCallsInsightsQueryParams): Promise<CallInsightsResponse> {
		const queryParams = new URLSearchParams(_.pickBy(params, _.identity))
		const endpointUrl = `${this.baseApiUrl}/insights/calls?${queryParams.toString()}`

		const res = await fetch(endpointUrl, {
			method: "GET",
			credentials: "include",
		})

		const json = await res.json()
		return json
	}
}
