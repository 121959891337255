import React from "react"

import { Button } from "../../../components/design-system/Button.component"
import { useLanguage } from "../../../contexts/language.context"

type StepCardProps = React.PropsWithChildren<{
	title: string
	canContinue?: boolean
	onContinue?: () => void
	onSkip?: () => void
	stepName: string
}>

export function StepCard({ title, children, onContinue, canContinue, onSkip, stepName }: StepCardProps) {
	const { t } = useLanguage()

	return (
		<div className="overflow-hidden bg-white rounded-lg shadow-md">
			<div className="border-b border-gray-200 px-4 py-3 sm:px-6 bg-white">
				<div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
					<div className="ml-4 mt-2">
						<h3 className="text-base font-semibold leading-6 text-gray-900">{title}</h3>
					</div>
				</div>
			</div>
			<div className="px-4 py-5 sm:p-6">
				{children}
				<div className="flex flex-row gap-2 justify-end">
					{onSkip && (
						<div className="flex items-center justify-end mt-4">
							<Button
								variant="text"
								onClick={onSkip}
								eventName="Onboarding step: skip clicked"
								eventProperties={{ stepName }}
								className="!text-gray-400"
							>
								{t("Remind me later")}
							</Button>
						</div>
					)}

					{onContinue && (
						<div className="flex items-center justify-end mt-4">
							<Button
								disabled={!canContinue}
								type="submit"
								variant="primary"
								eventName="Onboarding step: continue clicked"
								eventProperties={{ stepName }}
								onClick={onContinue}
							>
								{t("Continue")}
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
