import { InformationCircleIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"

import { Tooltip } from "../../../../../components/design-system/Tooltip.component"
import { MoneyAmount } from "./MoneyAmount"

type ForecastTableHeaderChipProps = {
	title: string
	tooltip: string
	amountsInCents: {
		current: number
		total?: number
		atRisk?: number
	}
	decoration?: { type: "progress-bar" } | { type: "evolution"; deltaComparedToSevenDaysAgo: number }
}

export function ForecastTableHeaderChip({ title, tooltip, amountsInCents, decoration }: ForecastTableHeaderChipProps) {
	return (
		<div className="border border-gray-200 shadow-sm rounded-lg p-2">
			<div className="flex">
				<span className="text-sm font-semibold text-gray-500">{title}</span>
				<Tooltip content={tooltip}>
					<InformationCircleIcon className="h-[1.125rem] w-[1.125rem] ml-1 text-gray-400" />
				</Tooltip>
			</div>

			<div className="flex">
				<div className="text-md font-bold text-gray-900 mt-2">
					<span>{MoneyAmount(amountsInCents.current, "never")}</span>
					{amountsInCents.total !== undefined && (
						<span> {"/" + MoneyAmount(amountsInCents.total, "never")}</span>
					)}
				</div>
				{amountsInCents.atRisk !== undefined && <></> /*todo: add at-risk icon and tooltip*/}
			</div>
			<div className="h-5 border-1 border-red-500">
				{decoration?.type === "progress-bar" && amountsInCents.total && (
					<ProgressBar percentage={(amountsInCents.current / amountsInCents.total) * 100} />
				)}

				{decoration?.type === "evolution" && decoration.deltaComparedToSevenDaysAgo !== 0 && (
					<div
						className={clsx(
							"text-xs font-bold",
							decoration.deltaComparedToSevenDaysAgo >= 0 ? "text-green-600" : "text-red-600",
						)}
					>
						{decoration.deltaComparedToSevenDaysAgo >= 0 ? "+" : ""}
						{decoration.deltaComparedToSevenDaysAgo.toLocaleString("en-EN")}
						{" in the last 7 days"}
					</div>
				)}
			</div>
		</div>
	)
}

type ProgressBarProps = {
	percentage: number // 0 to 100
}

function ProgressBar({ percentage }: ProgressBarProps) {
	return (
		<div className="relative h-[0.75rem] w-full bg-[#e7eefd] border-2 border-[#272758] rounded-full">
			<div
				className="absolute -top-[1px] -bottom-[1px] bg-[#272758] rounded-full"
				style={{ width: `${percentage}%` }}
			></div>
		</div>
	)
}
