import type { ElementType } from "react"

type _PageMetaItemIconProps = {
	label: string
	Icon: ElementType
}

type _PageMetaItemCustomProps = {
	children: React.ReactNode
}

export type _PageMetaItemProps = _PageMetaItemIconProps | _PageMetaItemCustomProps

export function _PageMetaItem(props: _PageMetaItemProps) {
	return (
		<div className="mt-2 flex items-center text-sm text-gray-500">
			{"children" in props ? (
				props.children
			) : (
				<>
					<props.Icon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
					{props.label}
				</>
			)}
		</div>
	)
}
