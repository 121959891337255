import clsx from "clsx"

import { TrackingLink } from "../../../components/design-system/TrackingLink.component"

export type SideNavigationItemProps = {
	name: string
	to: string
	Icon: React.ForwardRefExoticComponent<
		Omit<React.SVGProps<SVGSVGElement>, "ref"> & {
			title?: string | undefined
			titleId?: string | undefined
		} & React.RefAttributes<SVGSVGElement>
	>
	isActive: boolean
	Badge?: React.ReactNode
}

export function SideNavigationItem({ name, to, Icon, Badge, isActive }: SideNavigationItemProps) {
	return (
		<li key={name}>
			<TrackingLink
				eventName="settings navigation"
				to={to}
				className={clsx(
					isActive ? "bg-gray-50 text-navy-500" : "text-gray-700 hover:text-navy-500 hover:bg-gray-50",
					"group flex rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold items-center justify-between",
				)}
			>
				<div className={clsx("flex gap-x-3 items-center justify-between", Badge && "mr-2 md:mr-0")}>
					<Icon
						className={clsx(
							isActive ? "text-navy-500" : "text-gray-400 group-hover:text-navy-500",
							"h-6 w-6 shrink-0",
						)}
						aria-hidden="true"
					/>
					{name}
				</div>
				{Badge}
			</TrackingLink>
		</li>
	)
}
