import { useCallback, useState } from "react"

import type { CallHighlight } from "../../../../../core/domain/Library.entity"

type CallHighlightEditionState =
	| {
			type: "edit-comment"
			callHighlight: CallHighlight
	  }
	| {
			type: "edit-parent-folder"
			callHighlight: CallHighlight
	  }

export function useCallHighlightEdition() {
	const [isOpen, setIsOpen] = useState(false)
	const [callHighlightEdition, setCallHighlightEdition] = useState<CallHighlightEditionState | null>(null)

	const closeCallHighlightModal = useCallback(() => {
		setIsOpen(false)
	}, [])

	const openCallHighlightCommentEditionModal = useCallback((callHighlight: CallHighlight) => {
		setCallHighlightEdition({ type: "edit-comment", callHighlight })
		setIsOpen(true)
	}, [])

	const openCallHighlightParentEditionModal = useCallback((callHighlight: CallHighlight) => {
		setCallHighlightEdition({ type: "edit-parent-folder", callHighlight })
		setIsOpen(true)
	}, [])

	return {
		isOpen,
		callHighlightEdition,
		closeCallHighlightModal,
		openCallHighlightCommentEditionModal,
		openCallHighlightParentEditionModal,
	}
}
