import clsx from "clsx"
import React from "react"

import type {
	SalesMotionChangeComputationPeriodQueryParams,
	SalesMotionClosingPeriodQueryParams,
} from "../../../../../../core/application/gateways/deals.gateway/schemas/listDeals"
import { useLanguage } from "../../../../../contexts/language.context"

type SalesMotionFiltersProps = {
	dealOwners: Array<{ id: string; name: string }>
	comparisonPeriod: SalesMotionChangeComputationPeriodQueryParams
	onComparisonPeriodChange: (period: SalesMotionChangeComputationPeriodQueryParams) => void
	selectedOwner: string | null
	onSelectOwner: (userId: string) => void
	closingPeriod: SalesMotionClosingPeriodQueryParams
	onClosingPeriodChange: (period: SalesMotionClosingPeriodQueryParams) => void
	disabled?: boolean
	canOnlySeeOwnDeals: boolean
}

export function SalesMotionFilters({
	dealOwners,
	comparisonPeriod,
	onComparisonPeriodChange,
	onSelectOwner,
	selectedOwner,
	disabled,
	closingPeriod,
	onClosingPeriodChange,
	canOnlySeeOwnDeals,
}: SalesMotionFiltersProps) {
	const { t } = useLanguage()
	const sortedOwners = dealOwners.sort((a, b) => a.name.localeCompare(b.name))

	return (
		<div className={clsx("flex flex-1 flex-row gap-3 items-center mb-2 justify-center sm:justify-normal")}>
			<div
				className={clsx(
					"bg-indigo-500 border-navy-700 flex-1",
					"rounded-xl p-1 flex gap-2 bg-opacity-20 items-center border-2",
				)}
			>
				{!canOnlySeeOwnDeals && (
					<div className="flex flex-row items-center gap-1.5 flex-1">
						<label
							htmlFor="team-members"
							className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap"
						>
							{t("How did deals from")}
						</label>
						<select
							id="team-members"
							name="team-members"
							value={selectedOwner || ""}
							onChange={(e) => onSelectOwner(e.target.value)}
							className="block w-full rounded-md border-0 py-1.5 pl-3 pr-8 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
							disabled={disabled}
						>
							<option value="">{t("All members")}</option>
							{sortedOwners.map((owner) => (
								<option key={owner.id} value={owner.id}>
									{owner.name}
								</option>
							))}
						</select>
					</div>
				)}

				<div className="flex flex-row items-center gap-1.5 flex-1">
					<label
						htmlFor="closing-date"
						className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap"
					>
						{canOnlySeeOwnDeals ? t("How did my deals closing") : t("closing")}
					</label>
					<select
						id="closing-date"
						name="closing-date"
						value={closingPeriod}
						onChange={(e) => onClosingPeriodChange(e.target.value as SalesMotionClosingPeriodQueryParams)}
						className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
						disabled={disabled}
					>
						<option value="current-quarter">{t("This quarter")}</option>
						<option value="current-month">{t("This month")}</option>
					</select>
				</div>

				<div className="flex flex-row items-center gap-1.5 flex-1">
					<label
						htmlFor="change"
						className="block text-sm font-medium leading-6 text-gray-900 whitespace-nowrap"
					>
						{t("change in the last")}
					</label>
					<select
						id="change"
						name="change"
						value={comparisonPeriod}
						onChange={(e) =>
							onComparisonPeriodChange(e.target.value as SalesMotionChangeComputationPeriodQueryParams)
						}
						className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
						disabled={disabled}
					>
						<option value="last-7-days">{t("{{count}} days", { count: 7 })}</option>
						<option value="last-30-days">{t("{{count}} days", { count: 30 })}</option>
						<option value="last-90-days">{t("{{count}} days", { count: 90 })}</option>
					</select>
				</div>
			</div>
		</div>
	)
}
