import type { ChartOptions } from "chart.js"

import type { GetSalesMotionPerformanceStatsResponse } from "../../../../../../../core/application/gateways/deals.gateway/schemas/listDeals"
import type { TFunction } from "../../../../../../contexts/language.context"
import { formatCurrency } from "../../../../../Deals/utils"

export function getBarChartOptionsForMode(
	mode: "absolute" | "relative",
	dataType: "active-deals" | "deal-value",
	stats: GetSalesMotionPerformanceStatsResponse,
	t: TFunction,
): ChartOptions<"bar"> {
	return {
		responsive: true,
		maintainAspectRatio: false,
		layout: {
			padding: {
				top: 36,
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					callback: (value) => (mode === "absolute" ? value : `${value}%`), // Adds percentage symbol for relative mode
				},
				grid: {
					color: (context) => (context.tick.value === 0 ? "#000" : "#e5e5e5"), // Darker line for zero baseline
				},
			},
			x: {
				grid: {
					display: false,
				},
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				callbacks: {
					label: (context) => {
						const entry = stats.byStage[context.dataIndex]
						if (!entry) {
							return ""
						}

						const current =
							dataType === "active-deals" ? entry.currentActiveDeals : entry.currentTotalDealValue
						const previous =
							dataType === "active-deals" ? entry.previousActiveDeals : entry.previousTotalDealValue

						const diff = current - previous
						const currentStr =
							dataType === "deal-value"
								? formatCurrency(current, "EUR", {
										maximumFractionDigits: 0,
								  })
								: current.toFixed(0)
						const previousStr =
							dataType === "deal-value"
								? formatCurrency(previous, "EUR", {
										maximumFractionDigits: 0,
								  })
								: previous.toFixed(0)
						const diffStr =
							dataType === "deal-value"
								? formatCurrency(diff, "EUR", {
										maximumFractionDigits: 0,
								  })
								: diff.toFixed(0)

						const percentage = previous ? Math.floor((diff / previous) * 100) : 0
						if (mode === "absolute") {
							return [
								diff > 0 ? `+${diffStr}` : diffStr,
								t("Current: {{current}}", { current: currentStr }),
								t("Previous: {{previous}}", { previous: previousStr }),
							]
						}

						if (mode === "relative") {
							return [
								`${percentage > 0 ? "+" : ""}${Math.floor(percentage)}%`,
								t("Current: {{current}}", { current: currentStr }),
								t("Previous: {{previous}}", { previous: previousStr }),
							]
						}
					},
				},
			},
			datalabels: {
				display: true,
				align: "center",
				color: (context) => {
					const value = context.dataset.data[context.dataIndex]
					const entry: GetSalesMotionPerformanceStatsResponse["byStage"][0] | undefined =
						stats.byStage[context.dataIndex]

					if (entry?.isLostStage) {
						return "#ef4444"
					}

					if (typeof value === "number" && value > 0) {
						return "#363675"
					}

					return "#ef4444"
				},
				formatter: (value) => {
					if (dataType === "deal-value") {
						return `${value > 0 ? "+" : ""}${
							mode === "relative"
								? `${value}%`
								: formatCurrency(value, "EUR", {
										maximumFractionDigits: 0,
								  })
						}`
					}

					return `${value > 0 ? "+" : ""}${value}${mode === "relative" ? "%" : ""}`
				},
			},
		},
	}
}
