import clsx from "clsx"
import React from "react"

type SectionCardProps = React.PropsWithChildren<{
	className?: string
}>

export function SectionCard({ className, children }: SectionCardProps) {
	return (
		<div
			className={clsx(
				className,
				"rounded-xl border border-gray-100 p-1.5 xl:p-2 shadow-sm hover:shadow-md transition-all",
			)}
		>
			{children}
		</div>
	)
}
