import React from "react"

import { useLanguage } from "../../../contexts/language.context"

export type DealSelectFilter = "all" | "at-risk"

export type DealClosingDateFilter = "1-month" | "3-months" | "6-months" | "12-months"

export type DealChangesFilter = "7-days" | "30-days" | "90-days"

type DealFiltersProps = {
	dealOwners: Array<{ id: string; name: string }>
	selectedDeals: DealSelectFilter
	onSelectDeals: (selectedDeals: DealSelectFilter) => void
	selectedOwner: string | null
	onSelectOwner: (userId: string) => void
	selectedClosingDate: DealClosingDateFilter
	onSelectClosingDate: (closingDate: DealClosingDateFilter) => void
	selectedChanges?: DealChangesFilter
	onSelectChanges?: (changes: DealChangesFilter) => void
	disabled?: boolean
	canSelectOwner: boolean
	canSelectClosingDate: boolean
}

export function DealFilters({
	dealOwners,
	selectedDeals,
	onSelectDeals,
	onSelectChanges,
	onSelectClosingDate,
	onSelectOwner,
	selectedChanges,
	selectedClosingDate,
	selectedOwner,
	disabled,
	canSelectOwner,
	canSelectClosingDate,
}: DealFiltersProps) {
	const { t } = useLanguage()
	const sortedOwners = dealOwners.sort((a, b) => a.name.localeCompare(b.name))

	return (
		<div className="flex flex-row gap-3 items-end mb-2 justify-center sm:justify-normal flex-wrap">
			<div>
				<label htmlFor="deals" className="block text-sm font-medium leading-6 text-gray-900">
					{t("Deals")}
				</label>
				<select
					id="deals"
					name="deals"
					value={selectedDeals}
					onChange={(e) => onSelectDeals(e.target.value as DealSelectFilter)}
					className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
					disabled={disabled}
				>
					<option value="all">{t("All")}</option>
					<option value="at-risk">{t("At risk")}</option>
				</select>
			</div>
			{canSelectOwner && (
				<div>
					<label htmlFor="team-members" className="block text-sm font-medium leading-6 text-gray-900">
						{t("Team members")}
					</label>
					<select
						id="team-members"
						name="team-members"
						value={selectedOwner || ""}
						onChange={(e) => onSelectOwner(e.target.value)}
						className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
						disabled={disabled}
					>
						<option value="">{t("All members")}</option>
						{sortedOwners.map((owner) => (
							<option key={owner.id} value={owner.id}>
								{owner.name}
							</option>
						))}
					</select>
				</div>
			)}

			{canSelectClosingDate && (
				<div className="mt-4">
					<label htmlFor="closing-date" className="block text-sm font-medium leading-6 text-gray-900">
						{t("Closing in the next")}
					</label>
					<select
						id="closing-date"
						name="closing-date"
						value={selectedClosingDate}
						onChange={(e) => onSelectClosingDate(e.target.value as DealClosingDateFilter)}
						className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
						disabled={disabled}
					>
						{[1, 3, 6, 12].map((months) => (
							<option key={months} value={`${months}-month${months !== 1 ? "s" : ""}`}>
								{t("{{count}} months", { count: months })}
							</option>
						))}
					</select>
				</div>
			)}

			{selectedChanges && onSelectChanges && (
				<div className="mt-4 text-indigo-500">
					<label htmlFor="changes-last" className="block text-sm font-medium leading-6">
						{t("Changes in the last")}
					</label>
					<select
						id="changes-last"
						name="changes-last"
						value={selectedChanges}
						onChange={(e) => onSelectChanges(e.target.value as DealChangesFilter)}
						className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
						disabled={disabled}
					>
						{[7, 30, 90].map((days) => (
							<option key={days} value={`${days}-days`}>
								{t("{{count}} days", { count: days })}
							</option>
						))}
					</select>
				</div>
			)}
		</div>
	)
}
