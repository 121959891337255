import clsx from "clsx"
import React, { useCallback, useMemo, useRef, useState } from "react"
import { toast } from "react-toastify"

import type { Call } from "../../../../core/domain/Call.entity"
import type { CallExcerpt } from "../../../../core/domain/CallExcerpt.entity"
import type { CallHighlight } from "../../../../core/domain/Library.entity"
import { FollowCursorTooltip } from "../../../components/design-system/FollowCursorTooltip.component"
import { HighlightsTimeline } from "../../../components/design-system/VideoPlayer/_HighlightsTimeline.component"
import { MouseDragRangeIndicator } from "../../../components/design-system/VideoPlayer/_MouseDragRangeIndicator.component"
import { TimelineCursor } from "../../../components/design-system/VideoPlayer/_TimelineCursor.component"
import { useAnalytics } from "../../../contexts/analytics.context"
import { useSession } from "../../../contexts/authentication.context"
import { useCallVideoPlayer } from "../../../contexts/callVideoPlayer.context"
import { useDependencies } from "../../../contexts/dependencies.context"
import { type TFunction, useLanguage } from "../../../contexts/language.context"
import type { CallTimeRange } from "../../../utils/callSharing"
import { CallTimeRangeOptionsMenu } from "./_MainPanel/CallTimeRangeOptionsMenu"
import { CreateCallTimeRangeForm, useCreateCallTimeRangeForm } from "./_MainPanel/CreateCallTimeRangeForm.component"
import { CallTimelineThumbnail } from "./CallTimelineThumbnail.component"

type TimelineSegment = {
	attendeeId: string
	attendeeLabel: string
	segments: Array<{
		startTimeSec: number
		endTimeSec: number
		content: string
	}>
}

const splitTimelineByAttendeeId = (
	transcriptions: Call["props"]["transcription"],
	t: TFunction,
): Array<TimelineSegment> => {
	const timelineByAttendeeId: { [attendeeId: string]: TimelineSegment } = {}

	// Sort transcription turns by startTime
	const sortedTurns = [...(transcriptions?.turns ?? [])].sort((a, b) => a.startTimeSec - b.startTimeSec)

	// Group transcription turns by attendeeId and merge contiguous segments
	sortedTurns.forEach((turn) => {
		if (!timelineByAttendeeId[turn.attendeeId]) {
			timelineByAttendeeId[turn.attendeeId] = {
				attendeeId: turn.attendeeId,
				attendeeLabel: turn.attendeeLabel,
				segments: [
					{
						startTimeSec: turn.startTimeSec,
						endTimeSec: turn.endTimeSec,
						content: turn.content,
					},
				],
			}
		} else {
			const attendeeSegments = timelineByAttendeeId[turn.attendeeId].segments
			const lastSegment = attendeeSegments[attendeeSegments.length - 1]
			// If the current segment starts within 2 seconds of the last segment ending, merge them
			const isContiguous = turn.startTimeSec - lastSegment.endTimeSec <= 2
			if (isContiguous) {
				lastSegment.endTimeSec = turn.endTimeSec
				lastSegment.content += ` ${turn.content}`
			} else {
				// Otherwise, start a new segment
				attendeeSegments.push({
					startTimeSec: turn.startTimeSec,
					endTimeSec: turn.endTimeSec,
					content: turn.content,
				})
			}
		}
	})

	const timelines = Object.values(timelineByAttendeeId)
	if (timelines.length > 0) {
		return timelines
	}

	return [
		{
			attendeeId: "no_transcription",
			attendeeLabel: t("Timeline"),
			segments: [],
		},
	]
}

type SpeakerTimelinesProps = {
	callId: string | undefined
	transcription: Call["props"]["transcription"]
	totalDurationSeconds: number
	onPlayerTimeChange: (time: number) => void
	currentTimeSeconds: number
	highlights: CallHighlight[] | "loading"
	callExcerpts: CallExcerpt[] | "loading"
	shouldHideTimelineActions: boolean
	refreshHighlights?: () => void
	verticalSpacing: 2 | 4
	canShareCall: boolean
}

export function SpeakerTimelines({
	callId,
	transcription,
	totalDurationSeconds,
	onPlayerTimeChange,
	currentTimeSeconds,
	highlights,
	shouldHideTimelineActions,
	refreshHighlights,
	verticalSpacing,
	canShareCall,
	callExcerpts,
}: SpeakerTimelinesProps) {
	const { setupEventTracking } = useAnalytics()
	const { user, workspace } = useSession()
	const { call } = useCallVideoPlayer()
	const { callExcerptsGateway } = useDependencies()

	const { t } = useLanguage()
	const timelineSegments = splitTimelineByAttendeeId(transcription, t)
	const containerRef = useRef<HTMLDivElement>(null)
	const containerRect = containerRef.current?.getBoundingClientRect()
	const [currentHoverTimeSeconds, setCurrentHoverTimeSeconds] = useState<number | null>(null)
	const [mouseDragStartTimeSeconds, setMouseDragStartTimeSeconds] = useState<number | null>(null)
	const { allowedTimeRanges } = useCallVideoPlayer()
	const [lastDragPosition, setLastDragPosition] = useState<"start" | "end" | null>(null)
	const [isHoveringExcerpt, setIsHoveringExcerpt] = useState(false)

	const isHoverTimeInRange = allowedTimeRanges
		? allowedTimeRanges.some(
				(range) =>
					currentHoverTimeSeconds !== null &&
					currentHoverTimeSeconds >= range.startSec &&
					currentHoverTimeSeconds <= range.endSec,
		  )
		: true

	const { timeRangeState, resetTimeRangeForm, setTimeRange, confirmTimeRange, createCallHighlight } =
		useCreateCallTimeRangeForm(callId, refreshHighlights)

	const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
		const isClickingOnButton =
			e.target instanceof Element &&
			(e.target.tagName === "BUTTON" ||
				e.target.parentElement?.tagName === "BUTTON" ||
				e.target.parentElement?.parentElement?.tagName === "BUTTON")

		if (!containerRect || timeRangeState.type !== "none" || shouldHideTimelineActions || isClickingOnButton) return
		const xAxisPosition = e.clientX - containerRect.left
		const timeSeconds = (xAxisPosition / containerRect.width) * totalDurationSeconds
		setMouseDragStartTimeSeconds(timeSeconds)
	}

	const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
		if (
			!containerRect ||
			mouseDragStartTimeSeconds === null ||
			currentHoverTimeSeconds === null ||
			timeRangeState.type !== "none"
		)
			return

		const mouseDragEndTimeSeconds = currentHoverTimeSeconds

		const startTimeSeconds = Math.min(mouseDragStartTimeSeconds, mouseDragEndTimeSeconds)
		const endTimeSeconds = Math.max(mouseDragStartTimeSeconds, mouseDragEndTimeSeconds)

		// If the range is too small, don't create a range, it's probably a click
		if (endTimeSeconds - startTimeSeconds < 5) {
			const isClickingOnButton =
				e.target instanceof Element &&
				(e.target.tagName === "BUTTON" ||
					e.target.parentElement?.tagName === "BUTTON" ||
					e.target.parentElement?.parentElement?.tagName === "BUTTON")
			setMouseDragStartTimeSeconds(null)

			if (isClickingOnButton) {
				return
			}

			onPlayerTimeChange(currentHoverTimeSeconds)
			return
		}

		setTimeRange({
			startTimeSeconds,
			endTimeSeconds,
		})

		setMouseDragStartTimeSeconds(null)
	}

	const { trackEvent, debugDOMProps } = setupEventTracking({
		eventName: "Call video interaction",
		eventProperties: {
			type: "timeline range drag",
			totalDurationSeconds,
			callId,
		},
	})

	const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
		const isClickingOnButton =
			e.target instanceof Element &&
			(e.target.tagName === "BUTTON" ||
				e.target.parentElement?.tagName === "BUTTON" ||
				e.target.parentElement?.parentElement?.tagName === "BUTTON")
		if (!containerRect || timeRangeState.type !== "none" || !isHoverTimeInRange || isClickingOnButton) return

		const xAxisPosition = e.clientX - containerRect.left
		const timeSeconds = (xAxisPosition / containerRect.width) * totalDurationSeconds
		onPlayerTimeChange(timeSeconds)
		trackEvent()
	}

	const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
		if (timeRangeState.type !== "none") return

		const container = e.currentTarget.getBoundingClientRect()
		const xAxisPosition = e.clientX - container.left
		const mouseHoverTimeSeconds = (xAxisPosition / container.width) * totalDurationSeconds
		// If the mouse is outside the element, don't update the hover time
		if (mouseHoverTimeSeconds < 0 || mouseHoverTimeSeconds > totalDurationSeconds) {
			return
		}

		setCurrentHoverTimeSeconds(mouseHoverTimeSeconds)
	}

	const handleMouseLeave = () => {
		setCurrentHoverTimeSeconds(null)
		setMouseDragStartTimeSeconds(null)
	}

	const timelineContent = useMemo(
		() => (
			<>
				{currentHoverTimeSeconds !== null && isHoverTimeInRange && !isHoveringExcerpt && (
					<TimelineCursor
						variant="active"
						currentTimeSeconds={currentHoverTimeSeconds}
						totalDurationSeconds={totalDurationSeconds}
						containerRef={containerRef}
					/>
				)}
				{mouseDragStartTimeSeconds !== null && (
					<TimelineCursor
						variant="active"
						currentTimeSeconds={mouseDragStartTimeSeconds}
						totalDurationSeconds={totalDurationSeconds}
						containerRef={containerRef}
					/>
				)}
				{mouseDragStartTimeSeconds !== null && currentHoverTimeSeconds !== null && (
					<MouseDragRangeIndicator
						mouseDragStartTimeSeconds={mouseDragStartTimeSeconds}
						currentHoverTimeSeconds={currentHoverTimeSeconds}
						totalDurationSeconds={totalDurationSeconds}
						containerRef={containerRef}
					/>
				)}
			</>
		),
		[
			currentHoverTimeSeconds,
			isHoverTimeInRange,
			isHoveringExcerpt,
			mouseDragStartTimeSeconds,
			totalDurationSeconds,
		],
	)

	const handleDragStartTime = useCallback(
		(newStartTime: number) => {
			if (timeRangeState.type !== "range-dragged") return
			setLastDragPosition("start")
			setTimeRange({
				startTimeSeconds: newStartTime,
				endTimeSeconds: timeRangeState.endTimeSeconds,
			})
		},
		[setTimeRange, timeRangeState],
	)

	const handleDragEndTime = useCallback(
		(newEndTime: number) => {
			if (timeRangeState.type !== "range-dragged") return
			setLastDragPosition("end")
			setTimeRange({
				startTimeSeconds: timeRangeState.startTimeSeconds,
				endTimeSeconds: newEndTime,
			})
		},
		[setTimeRange, timeRangeState],
	)

	const handleShareCallRangeConfirm = useCallback(() => {
		if (timeRangeState.type === "range-dragged") {
			setCurrentHoverTimeSeconds(null)
			confirmTimeRange(timeRangeState, "share-call")
		}
	}, [timeRangeState, confirmTimeRange])

	const handleCreateCallHighlightRangeConfirm = useCallback(() => {
		if (timeRangeState.type === "range-dragged") {
			setCurrentHoverTimeSeconds(null)
			confirmTimeRange(timeRangeState, "create-call-highlight")
		}
	}, [timeRangeState, confirmTimeRange])

	const handleCreateExcerpt = useCallback(() => {
		if (timeRangeState.type === "range-dragged") {
			setCurrentHoverTimeSeconds(null)
			confirmTimeRange(timeRangeState, "create-call-excerpt")
		}
	}, [timeRangeState, confirmTimeRange])

	const handleExcerptHover = useCallback(() => {
		setIsHoveringExcerpt(true)
	}, [])

	const handleExcerptUnhover = useCallback(() => {
		setIsHoveringExcerpt(false)
	}, [])

	const handleExcerptDeleteRequest = useCallback(
		async (excerpt: CallExcerpt) => {
			if (window.confirm(t("Are you sure you want to delete this excerpt?"))) {
				await callExcerptsGateway.deleteById(excerpt.properties.id)
				refreshHighlights?.()
				toast(t("Excerpt deleted"), {
					type: "info",
				})
			}
		},
		[callExcerptsGateway, refreshHighlights, t],
	)

	const paddingClasses = {
		2: "md:py-6 md:px-2",
		4: "md:py-12 md:px-2",
	}

	const isDragging = Boolean(mouseDragStartTimeSeconds)

	return (
		<div
			className={clsx(
				"relative mb-6 border border-gray-200 rounded-b-lg p-2 pb-20",
				paddingClasses[verticalSpacing],
			)}
		>
			{call.props.video?.timelineImage?.url && (
				<>
					{timeRangeState.type === "range-dragged" && lastDragPosition ? (
						<CallTimelineThumbnail
							currentTimeSeconds={
								lastDragPosition === "start"
									? timeRangeState.startTimeSeconds
									: timeRangeState.endTimeSeconds
							}
							imageUrl={call.props.video.timelineImage.url}
							className="z-10"
						/>
					) : (
						<CallTimelineThumbnail
							currentTimeSeconds={currentHoverTimeSeconds ?? 0}
							imageUrl={call.props.video.timelineImage.url}
							className={clsx(currentHoverTimeSeconds !== null ? "z-10" : "hidden")}
						/>
					)}
				</>
			)}

			<FollowCursorTooltip
				tooltipContent={
					mouseDragStartTimeSeconds === null &&
					!shouldHideTimelineActions &&
					timeRangeState.type === "none" &&
					isHoverTimeInRange &&
					!isHoveringExcerpt
						? t("Click and drag to create a highlight or share a snippet of the call")
						: null
				}
			>
				<div
					className={clsx(
						"flex flex-col",
						isHoverTimeInRange ? "cursor-pointer" : "cursor-not-allowed",
						isDragging && "select-none",
					)}
					onMouseMove={handleMouseMove}
					onMouseLeave={handleMouseLeave}
					onMouseDown={handleMouseDown}
					onMouseUp={handleMouseUp}
					onClick={handleClick}
					ref={containerRef}
					{...debugDOMProps}
				>
					{timelineSegments.map((segment, index) => (
						<Timeline
							key={segment.attendeeId}
							segment={segment}
							index={index}
							totalDurationSeconds={totalDurationSeconds}
							verticalSpacing={verticalSpacing}
						/>
					))}
					{highlights !== "loading" && (
						<HighlightsTimeline
							currentHoverTimeSeconds={currentHoverTimeSeconds}
							currentTimeSeconds={currentTimeSeconds}
							highlights={highlights}
							callExcerpts={callExcerpts}
							totalDurationSeconds={totalDurationSeconds}
							isDragging={timeRangeState.type === "range-dragged"}
							onExcerptHover={handleExcerptHover}
							onExcerptUnhover={handleExcerptUnhover}
							onExcerptDeleteRequest={handleExcerptDeleteRequest}
						/>
					)}
					{timeRangeState.type !== "range-confirmed" && (
						<TimelineCursor
							variant="default"
							currentTimeSeconds={currentTimeSeconds}
							totalDurationSeconds={totalDurationSeconds}
							containerRef={containerRef}
						/>
					)}
					{shouldHideTimelineActions || !user ? (
						timelineContent
					) : (
						<CreateCallTimeRangeForm
							createCallTimeRangeForm={timeRangeState}
							totalDurationSeconds={totalDurationSeconds}
							containerRef={containerRef}
							currentTimeSeconds={currentTimeSeconds}
							onCreateCallTimeRangeFormReset={resetTimeRangeForm}
							onCreateCallHighlightRangeConfirm={() => {
								if (!workspace?.isPlanFeatureEnabled("library")) {
									return
								}
								if (timeRangeState.type === "range-dragged") {
									setCurrentHoverTimeSeconds(null)
									confirmTimeRange(timeRangeState, "create-call-highlight")
								}
							}}
							onShareCallRangeConfirm={() => {
								if (timeRangeState.type === "range-dragged") {
									setCurrentHoverTimeSeconds(null)
									confirmTimeRange(timeRangeState, "share-call")
								}
							}}
							onCallHighlightMetadataSubmit={createCallHighlight}
							onStartTimeChange={handleDragStartTime}
							onEndTimeChange={handleDragEndTime}
							refreshHighlights={refreshHighlights}
						>
							{timelineContent}
						</CreateCallTimeRangeForm>
					)}
				</div>
			</FollowCursorTooltip>
			{timeRangeState.type === "range-dragged" && (
				<CallTimeRangeOptionsMenu
					onCancel={resetTimeRangeForm}
					onCreateCallHighlight={handleCreateCallHighlightRangeConfirm}
					onShareCall={handleShareCallRangeConfirm}
					canShareCall={canShareCall}
					onEndTimeChange={handleDragEndTime}
					onStartTimeChange={handleDragStartTime}
					totalDurationSeconds={totalDurationSeconds}
					endTimeSeconds={timeRangeState.endTimeSeconds}
					startTimeSeconds={timeRangeState.startTimeSeconds}
					onCreateExcerpt={handleCreateExcerpt}
				/>
			)}
		</div>
	)
}

const calculateSegmentWidth = (segment: TimelineSegment["segments"][number], totalDurationSeconds: number) => {
	const width = ((segment.endTimeSec - segment.startTimeSec) / totalDurationSeconds) * 100
	return width
}

const calculateSegmentOffset = (segment: TimelineSegment["segments"][number], totalDurationSeconds: number) => {
	return (segment.startTimeSec / totalDurationSeconds) * 100
}

function isSegmentInAllowedRanges(
	segment: TimelineSegment["segments"][number],
	timeRanges: CallTimeRange[] | undefined,
) {
	if (!timeRanges) {
		return true
	}

	return timeRanges.some((range) => segment.startTimeSec >= range.startSec && segment.endTimeSec <= range.endSec)
}

function Timeline({
	segment,
	index,
	totalDurationSeconds,
	verticalSpacing,
}: {
	segment: TimelineSegment
	index: number
	totalDurationSeconds: number
	verticalSpacing: 2 | 4
}) {
	const totalTalkTime = Math.round(
		segment.segments.reduce((acc, segment) => acc + (segment.endTimeSec - segment.startTimeSec), 0),
	)

	const { allowedTimeRanges } = useCallVideoPlayer()
	const talkRatioPercentage = Math.round((totalTalkTime / totalDurationSeconds) * 100)
	const colorClasses = ["bg-blue-500", "bg-red-500", "bg-green-500", "bg-yellow-500", "bg-purple-500"]
	const colorClass = colorClasses[index % colorClasses.length]
	const segmentsToDisplay = segment.segments.filter((segment) => isSegmentInAllowedRanges(segment, allowedTimeRanges))
	const spacingClasses = {
		2: "my-2",
		4: "my-4",
	}

	return (
		<div
			key={segment.attendeeId}
			className={clsx(
				"space-y-1 select-none",
				spacingClasses[verticalSpacing],
				// Keep top padding on first timeline so that the timeline cursor duration doesn't overlap with the first speaker name
				index === 0 && "mt-4",
			)}
		>
			<div className="flex justify-between items-center text-gray-500 text-sm font-medium">
				<span>{segment.attendeeLabel}</span>
				{totalTalkTime > 0 && (
					<span>
						{formatDuration(totalTalkTime)} {isFinite(talkRatioPercentage) && `(${talkRatioPercentage}%)`}
					</span>
				)}
			</div>
			<div className="relative h-2 bg-gray-200 overflow-hidden rounded-full">
				{allowedTimeRanges?.map((range) => (
					<div
						key={`${range.startSec}-${range.endSec}`}
						className="absolute h-full bg-indigo-300 rounded-full opacity-50"
						style={{
							left: `${(range.startSec / totalDurationSeconds) * 100}%`,
							width: `${((range.endSec - range.startSec) / totalDurationSeconds) * 100}%`,
						}}
					/>
				))}
				{segmentsToDisplay.map((segment) => (
					<div
						key={`${segment.startTimeSec}-${segment.endTimeSec}`}
						className={`absolute h-full ${colorClass} rounded-full opacity-50`}
						style={{
							left: `${calculateSegmentOffset(segment, totalDurationSeconds)}%`,
							width: `calc(${calculateSegmentWidth(segment, totalDurationSeconds)}% + 1px)`, // Ensures at least 1px width
						}}
					/>
				))}
			</div>
		</div>
	)
}

function formatDuration(seconds: number) {
	const hours = Math.floor(seconds / 3600)
	const minutes = Math.floor((seconds % 3600) / 60)
	let formattedDuration = ""

	if (hours > 0) {
		formattedDuration += `${hours}h`
	}
	if (minutes > 0 || hours > 0) {
		formattedDuration += `${minutes}m`
	}
	formattedDuration += `${Math.floor(seconds % 60)}s`
	return formattedDuration
}
